import React from "react";

import API from "../../Global/API";
import ModalButton from "../../Components/ModalButton";
import ImageWrapper from "../../Components/ImageWrapper";
import EmployeeView from "../../Pages/Admin/Employee/EmployeeView";

import Box from "@mui/material/Box";
import VerifiedIcon from '@mui/icons-material/Verified';
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn';

/**
 * EmployeeDetailsPreview component.
 *
 * @returns {*}
 * @constructor
 */
const EmployeeDetailsPreview = (props) => {
    const {
        record              // {Object} The employee record object.
    } = props;

    return (
        <Box className={'d-flex__start'}>
            <ImageWrapper
                src={API.getFilePath(record.image)}
                width={48}
                height={48}
                className={'mr__2'}
            />

            <Box
                sx={{flexGrow: 1}}
                className={'d-flex__justify'}
            >
                <div>
                    <Box className={'d-flex__start'}>
                        <ModalButton
                            label={
                                <Box className={'d-flex__start'}>
                                    <b className={'mr__1'}>{record.lastName}, {record.firstName}</b>

                                    {record.isInvited && !!record.inviteCompletedDate && !record.isInviteAccepted && (
                                        <AssignmentTurnedInIcon
                                            fontSize={'small'}
                                        />
                                    )}

                                    {record.isInvited && record.isInviteAccepted && !!record.offerLetterDate && !!record.availabilityDate && !!record.trainingDate && !!record.hirePacketDate && (
                                        <VerifiedIcon
                                            fontSize={'small'}
                                        />
                                    )}
                                </Box>
                            }
                            component={'a'}
                            bodyStyle={{
                                paddingTop: 0
                            }}
                            children={
                                <EmployeeView id={record.id}/>
                            }
                        />
                    </Box>

                    <Box className={'d-flex__start text__small'}>
                        <Box className={'d-flex__start divider__wrapper'}>
                            <Box
                                className={'text__light d-inline__block'}
                                children={record.specialty.name}
                            />
                            {record.isInvited && (
                                <>
                                    <Box className={'divider'}/>
                                    <Box
                                        className={'text__light d-inline__block'}
                                        children={!record.isInviteAccepted ? 'Invited' : 'Unlocked'}
                                    />
                                </>
                            )}
                        </Box>
                    </Box>
                </div>
            </Box>
        </Box>
    );
};

export default EmployeeDetailsPreview;