import React from "react";

import Box from "@mui/material/Box";

/**
 * SmallButton component.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
const SmallButton = (props) => {
    const {
        onClick,        // {Function} An on-click handler for clicking on the link.
        children,       // {String} The text content of the link.
        disabled,       // {Boolean} Whether or not the link is disabled.
        className,      // {String} An optional class to include on the component.
    } = props;

    return (
        <Box className={'crumb'}>
            <a
                onClick={onClick}
                children={children}
                className={`d-flex__start generic__link ${className || ''} ${disabled ? 'disabled' : ''}`}
            />
        </Box>
    );
};

export default SmallButton;