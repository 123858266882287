import React, {useEffect, useMemo, useState} from "react";

import API from "../../../Global/API";
import Step from "../../../Components/Step";
import {useAuth} from "../../../Global/Auth";
import InputSelect from "../../../Components/Input/InputSelect";
import LogoHeading from "../../../Components/Typography/LogoHeading";
import StepControls from "../../../Components/StepControls";
import InputSignature from "../../../Components/Input/InputSignature";
import SectionHeading from "../../../Components/Typography/SectionHeading";
import withSynchronizedSettings from "../Abstract/withSynchronizedSettings";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import TableRow from "@mui/material/TableRow";
import LockIcon from '@mui/icons-material/Lock';
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import {useNavigate} from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import FormControlLabel from "@mui/material/FormControlLabel";
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

/**
 * DirectDepositStep component.
 *
 * @returns {*}
 * @constructor
 */
const DirectDepositStep = withSynchronizedSettings((props) => {
    const {
        step,                   // {Number} The current step for the user.
        index,                  // {Number} The expected step number.
        doSync,                 // {Function} The synchronization callback.
        disabled,               // {Boolean} Whether the step is disabled from input.
        readOnly,               // {Boolean} Whether the form is disabled.
        employee,               // {Object} The employee object for read only context.
        onValidate,             // {Function} The post-validation callback.
        doValidate,             // {Function} Triggers the validation event.
        getSetting,             // {Function} Retrieves a loaded setting value.
        description,            // {String} An optional page description to use other than the default.
        isSubmitted,            // {Boolean} Indicates whether the parent form has been submitted.
        onStepChange,           // {Function} The step change callback.
        addBackButton,          // {Boolean} Indicates whether we should append a back button.
        loadedSettings,         // {Object} The previously loaded settings.
    } = props;

    const {user} = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [signature, setSignature] = useState('');
    const [totalAccounts, setTotalAccounts] = useState('');
    const [accountOneType, setAccountOneType] = useState('');
    const [accountTwoType, setAccountTwoType] = useState('');
    const [accountOneAmount, setAccountOneAmount] = useState('');
    const [accountTwoAmount, setAccountTwoAmount] = useState('');
    const [accountOneRouting, setAccountOneRouting] = useState('');
    const [accountTwoRouting, setAccountTwoRouting] = useState('');
    const [accountOneAccount, setAccountOneAccount] = useState('');
    const [accountTwoAccount, setAccountTwoAccount] = useState('');
    const [accountOneRoutingC, setAccountOneRoutingC] = useState('');
    const [accountTwoRoutingC, setAccountTwoRoutingC] = useState('');
    const [accountOneAccountC, setAccountOneAccountC] = useState('');
    const [accountTwoAccountC, setAccountTwoAccountC] = useState('');
    const [accountOneInstitution, setAccountOneInstitution] = useState('');
    const [accountTwoInstitution, setAccountTwoInstitution] = useState('');

    /**
     * Returns true if the user has already completed this step.
     *
     * @type {boolean}
     */
    const hasDirectDeposit = !!employee && !!employee.hasDirectDeposit;


    /**
     * Automatically adjusts percentages based on the "total accounts" selection
     */
    useEffect(() => {
        if(readOnly){
            return;
        }

        if (totalAccounts === 'one') {
            setAccountOneAmount('100');
        } else if (totalAccounts === 'two') {
            setAccountOneAmount('50');
            setAccountTwoAmount('50');
        }
    }, [totalAccounts]);


    /**
     * Synchronizes the "Read Only" settings for administrators.
     */
    useEffect(() => {
        if (!readOnly) {
            return;
        }

        if (!Object.keys(loadedSettings).length) {
            return;
        }

        setSignature(getSetting('HP_DD_SIGNATURE', ''));
        setTotalAccounts(getSetting('HP_DD_TOTAL_ACCOUNTS', ''));
        setAccountOneType(getSetting('HP_DD_ACCT_A_TYPE', ''));
        setAccountTwoType(getSetting('HP_DD_ACCT_B_TYPE', ''));
        setAccountOneAmount(getSetting('HP_DD_ACCT_A_AMOUNT', ''));
        setAccountTwoAmount(getSetting('HP_DD_ACCT_B_AMOUNT', ''));
        setAccountOneAccount(getSetting('HP_DD_ACCT_A_ACCT', ''));
        setAccountTwoAccount(getSetting('HP_DD_ACCT_B_ACCT', ''));
        setAccountOneRouting(getSetting('HP_DD_ACCT_A_ROUTING', ''));
        setAccountTwoRouting(getSetting('HP_DD_ACCT_B_ROUTING', ''));
        setAccountOneInstitution(getSetting('HP_DD_ACCT_A_INSTITUTION', ''));
        setAccountTwoInstitution(getSetting('HP_DD_ACCT_B_INSTITUTION', ''));
    }, [loadedSettings]);


    /**
     * Saves each of the respective settings.
     *
     * @returns {Promise<void>}
     */
    const doSaveAll = () => {
        return Promise.all([
            doSync('HP_DD_SIGNATURE', signature),
            doSync('HP_DD_ACCT_A_ACCT', accountOneAccount),
            doSync('HP_DD_ACCT_B_ACCT', accountTwoAccount),
            doSync('HP_DD_ACCT_A_TYPE', accountOneType),
            doSync('HP_DD_ACCT_B_TYPE', accountTwoType),
            doSync('HP_DD_ACCT_A_AMOUNT', accountOneAmount),
            doSync('HP_DD_ACCT_B_AMOUNT', accountTwoAmount),
            doSync('HP_DD_TOTAL_ACCOUNTS', totalAccounts),
            doSync('HP_DD_ACCT_A_ROUTING', accountOneRouting),
            doSync('HP_DD_ACCT_B_ROUTING', accountTwoRouting),
            doSync('HP_DD_ACCT_A_INSTITUTION', accountOneInstitution),
            doSync('HP_DD_ACCT_B_INSTITUTION', accountTwoInstitution)
        ]);
    };


    /**
     * Synchronize any default values.
     */
    useEffect(() => {
        if (!hasDirectDeposit) {
            return;
        }

        setSignature(getSetting('HP_DD_SIGNATURE', ''));
        setTotalAccounts(getSetting('HP_DD_TOTAL_ACCOUNTS', ''));
    }, [hasDirectDeposit]);


    /**
     * Closes the error dialogue.
     */
    const handleErrorClose = () => {
        setError('');
    };


    /**
     * Handles the form validation.
     */
    const handleValidation = () => {
        if (hasDirectDeposit) {
            return onValidate();
        }

        let requirements = [
            totalAccounts
        ];

        if (!!totalAccounts) {
            requirements = [
                ...requirements,
                accountOneType,
                accountOneAmount,
                accountOneAccount,
                accountOneRouting,
                accountOneAccountC,
                accountOneRoutingC,
                accountOneInstitution,
            ];
        }

        if (accountOneAccount || accountOneRouting) {
            if (accountOneAccount !== accountOneAccountC) {
                return setError('The account number that you entered does not match the confirmation for account one.');
            }

            if (accountOneRouting !== accountOneRoutingC) {
                return setError('The routing number that you entered does not match the confirmation for account one.');
            }
        }

        if (totalAccounts === 'two') {
            requirements = [
                ...requirements,
                accountTwoType,
                accountTwoAmount,
                accountTwoAccount,
                accountTwoRouting,
                accountTwoInstitution,
            ];

            if (accountTwoAccount || accountTwoRouting) {
                if (accountTwoAccount !== accountTwoAccountC) {
                    return setError('The account number that you entered does not match the confirmation for account two.');
                }

                if (accountTwoRouting !== accountTwoRoutingC) {
                    return setError('The routing number that you entered does not match the confirmation for account two.');
                }
            }
        }

        if (isUnderAmount || isOverAmount) {
            return setError('The total amount of the two accounts must equal 100%');
        }

        doValidate(requirements, async () => {
            setLoading(true);
            await doSaveAll();

            await API.put(`employees/${user.id}`, {
                hasDirectDeposit: true,
            });

            setLoading(false);
            onValidate();
        });
    };


    /**
     * The page description content under the heading.
     *
     * @type {string}
     */
    const pageDescription = description || 'This document must be signed by employee requesting automatic deposits of paychecks and retained on file by the employer.';


    /**
     * The parsed amounts for each account.
     *
     * @type {number|number}
     */
    const accountAmountA = accountOneAmount ? parseInt(accountOneAmount) : 0;
    const accountAmountB = accountTwoAmount ? parseInt(accountTwoAmount) : 0;


    /**
     * Provides validation for the total account values.
     *
     * @type {false|""|boolean}
     */
    const isOverAmount = totalAccounts === 'two' && accountOneAmount && accountTwoAmount && (parseInt(accountOneAmount) + parseInt(accountTwoAmount)) > 100;
    const isUnderAmount = totalAccounts === 'two' && accountOneAmount && accountTwoAmount && (parseInt(accountOneAmount) + parseInt(accountTwoAmount)) < 100;


    /**
     * The disabled state of each input.
     */
    const isDisabled = disabled || readOnly || isLoading;

    return (
        <Step step={index} value={step}>
            <LogoHeading
                title={'Direct Deposit Authorization'}
                actionSlot={addBackButton && (
                    <Button
                        size={'small'}
                        variant={'outlined'}
                        onClick={() => navigate('/')}
                        children={'Back'}
                        className={'text__small'}
                    />
                )}
            />

            <Box className={'columns__1'}>
                {!readOnly && (
                    hasDirectDeposit ? (
                        <Alert severity={'success'} icon={<LockIcon fontSize="inherit"/>}>
                            You have already completed this step! If you need to make any changes,
                            don't worry - You can do so once your profile is unlocked.
                        </Alert>
                    ) : (
                        <Alert severity={'warning'} icon={<LockIcon fontSize="inherit"/>}>
                            Any existing direct deposit information is intentionally left blank for security purposes.
                        </Alert>
                    )
                )}

                <Box children={pageDescription}/>

                <FormControl required error={isSubmitted && !totalAccounts}>
                    <FormLabel className={'d-block mb__2'}>How would you like to be paid?</FormLabel>
                    <RadioGroup
                        value={totalAccounts}
                        error={isSubmitted && !totalAccounts}
                        onChange={event => setTotalAccounts(event.target.value)}
                    >
                        <FormControlLabel
                            value={'one'}
                            label={'I elect to have all of my paycheck deposited into the first account'}
                            control={<Radio disabled={isDisabled || hasDirectDeposit}/>}
                        />
                        <FormControlLabel
                            value={'two'}
                            label={'I elect to have my paycheck deposited across two accounts'}
                            control={<Radio disabled={isDisabled || hasDirectDeposit}/>}
                        />
                    </RadioGroup>
                </FormControl>

                {((!hasDirectDeposit && totalAccounts) || readOnly) && (
                    <Box className={'columns__1'}>
                        <>
                            <SectionHeading title={'Account #1'}/>
                            <FormControl error={isSubmitted && !accountOneInstitution}>
                                <FormLabel className={'d-block mb__2'} required>Bank Name</FormLabel>
                                <TextField
                                    value={accountOneInstitution}
                                    error={isSubmitted && !accountOneInstitution}
                                    required
                                    disabled={isDisabled}
                                    onChange={event => setAccountOneInstitution(event.target.value)}
                                    fullWidth
                                    placeholder={'Example Bank'}
                                />
                            </FormControl>
                            <FormControl error={isSubmitted && !accountOneType}>
                                <FormLabel className={'d-block mb__2'} required>Account Type</FormLabel>
                                <RadioGroup
                                    row
                                    value={accountOneType}
                                    error={isSubmitted && !accountOneType}
                                    onChange={event => setAccountOneType(event.target.value)}
                                >
                                    <FormControlLabel
                                        value="checking"
                                        label="Checking"
                                        control={<Radio disabled={isDisabled}/>}
                                    />
                                    <FormControlLabel
                                        value="savings"
                                        label="Savings"
                                        control={<Radio disabled={isDisabled}/>}
                                    />
                                </RadioGroup>
                            </FormControl>
                            <FormControl error={isSubmitted && !accountOneRouting}>
                                <FormLabel className={'d-block mb__2'} required>Bank Routing Number (ABA
                                    Number)</FormLabel>
                                <TextField
                                    value={accountOneRouting}
                                    error={isSubmitted && !accountOneRouting}
                                    disabled={isDisabled}
                                    onChange={event => setAccountOneRouting(event.target.value)}
                                    fullWidth
                                    placeholder={'XXXXXXXXX'}
                                />
                            </FormControl>
                            <FormControl error={isSubmitted && !accountOneAccount}>
                                <FormLabel className={'d-block mb__2'} required>Bank Account Number</FormLabel>
                                <TextField
                                    value={accountOneAccount}
                                    error={isSubmitted && !accountOneAccount}
                                    disabled={isDisabled}
                                    onChange={event => setAccountOneAccount(event.target.value)}
                                    fullWidth
                                    placeholder={'XXXXXXXX'}
                                />
                            </FormControl>

                            {!readOnly && (accountOneRouting || accountOneAccount) && (
                                <Alert severity={'warning'}>
                                    <Box className={'columns__1'}>
                                        <Box>Please confirm your account and routing numbers in the fields
                                            below:</Box>

                                        <FormControl
                                            error={(isSubmitted && !accountOneRoutingC) || (accountOneRoutingC && accountOneRoutingC !== accountOneRouting)}>
                                            <FormLabel className={'d-block mb__2'} required>Confirm Routing
                                                Number</FormLabel>
                                            <TextField
                                                value={accountOneRoutingC}
                                                error={(isSubmitted && !accountOneRoutingC) || (accountOneRoutingC && accountOneRoutingC !== accountOneRouting)}
                                                disabled={isDisabled}
                                                onChange={event => setAccountOneRoutingC(event.target.value)}
                                                fullWidth
                                                placeholder={'XXXXXXXXX'}
                                            />
                                        </FormControl>

                                        <FormControl
                                            error={(isSubmitted && !accountOneAccountC) || (accountOneAccountC && accountOneAccountC !== accountOneAccount)}>
                                            <FormLabel className={'d-block mb__2'} required>Confirm Account
                                                Number</FormLabel>
                                            <TextField
                                                value={accountOneAccountC}
                                                error={(isSubmitted && !accountOneAccountC) || (accountOneAccountC && accountOneAccountC !== accountOneAccount)}
                                                disabled={isDisabled}
                                                onChange={event => setAccountOneAccountC(event.target.value)}
                                                fullWidth
                                                placeholder={'XXXXXXXX'}
                                            />
                                        </FormControl>
                                    </Box>
                                </Alert>
                            )}

                            {totalAccounts === 'two' && (
                                <FormControl error={isSubmitted && !accountOneAmount}>
                                    <FormLabel
                                        required
                                        children={'How much to deposit into account #1?'}
                                        className={'d-block mb__2'}
                                    />
                                    <InputSelect
                                        value={accountOneAmount}
                                        error={isSubmitted && !accountOneAmount}
                                        required
                                        options={(totalAccounts !== 'two' ? ['100'] : ['0', '25', '50', '75', '100']).map((option) => {
                                            return {
                                                label: `${option}%`,
                                                value: option,
                                            };
                                        })}
                                        disabled={isDisabled}
                                        onChange={event => {
                                            const currentAmount = event.target.value ? parseInt(event.target.value) : 0;
                                            setAccountOneAmount(event.target.value);
                                            setAccountTwoAmount((100 - currentAmount).toString());
                                        }}
                                    />
                                </FormControl>
                            )}

                            {totalAccounts === 'two' && <Box/>}
                        </>

                        {totalAccounts === 'two' && (
                            <>
                                <SectionHeading title={'Account #2'}/>
                                <Alert severity={'info'}>
                                    If you are requesting to have your paycheck split over two accounts, please complete
                                    the second account information below. If not, please update your selection above.
                                </Alert>
                                <FormControl error={isSubmitted && !accountTwoInstitution}>
                                    <FormLabel className={'d-block mb__2'} required>Bank Name</FormLabel>
                                    <TextField
                                        value={accountTwoInstitution}
                                        error={isSubmitted && !accountTwoInstitution}
                                        required
                                        onChange={event => setAccountTwoInstitution(event.target.value)}
                                        disabled={isDisabled}
                                        fullWidth
                                        placeholder={'Example Bank'}
                                    />
                                </FormControl>
                                <FormControl error={isSubmitted && !accountTwoType}>
                                    <FormLabel className={'d-block mb__2'}>Account Type</FormLabel>
                                    <RadioGroup
                                        row
                                        value={accountTwoType}
                                        error={isSubmitted && !accountTwoType}
                                        onChange={event => setAccountTwoType(event.target.value)}
                                    >
                                        <FormControlLabel
                                            value="checking"
                                            label="Checking"
                                            control={<Radio disabled={isDisabled}/>}
                                        />
                                        <FormControlLabel
                                            value="Savings"
                                            label="Savings"
                                            control={<Radio disabled={isDisabled}/>}
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <FormControl error={isSubmitted && !accountTwoRouting}>
                                    <FormLabel className={'d-block mb__2'} required>Bank Routing Number (ABA
                                        Number)</FormLabel>
                                    <TextField
                                        value={accountTwoRouting}
                                        error={isSubmitted && !accountTwoRouting}
                                        disabled={isDisabled}
                                        onChange={event => setAccountTwoRouting(event.target.value)}
                                        fullWidth
                                        placeholder={'XXXXXXXXX'}
                                    />
                                </FormControl>
                                <FormControl error={isSubmitted && !accountTwoAccount}>
                                    <FormLabel className={'d-block mb__2'} required>Bank Account Number</FormLabel>
                                    <TextField
                                        value={accountTwoAccount}
                                        error={isSubmitted && !accountTwoAccount}
                                        disabled={isDisabled}
                                        onChange={event => setAccountTwoAccount(event.target.value)}
                                        fullWidth
                                        placeholder={'XXXXXXXX'}
                                    />
                                </FormControl>

                                {!readOnly && (accountTwoRouting || accountTwoAccount) && (
                                    <Alert severity={'warning'}>
                                        <Box className={'columns__1'}>
                                            <Box>Please confirm your account and routing numbers in the fields
                                                below:</Box>

                                            <FormControl
                                                error={(isSubmitted && !accountTwoRoutingC) || (accountTwoRoutingC && accountTwoRoutingC !== accountTwoRouting)}>
                                                <FormLabel className={'d-block mb__2'} required>Confirm Routing
                                                    Number</FormLabel>
                                                <TextField
                                                    value={accountTwoRoutingC}
                                                    error={(isSubmitted && !accountTwoRoutingC) || (accountTwoRoutingC && accountTwoRoutingC !== accountTwoRouting)}
                                                    disabled={isDisabled}
                                                    onChange={event => setAccountTwoRoutingC(event.target.value)}
                                                    fullWidth
                                                    placeholder={'XXXXXXXXX'}
                                                />
                                            </FormControl>

                                            <FormControl
                                                error={(isSubmitted && !accountTwoAccountC) || (accountTwoAccountC && accountTwoAccountC !== accountTwoAccount)}>
                                                <FormLabel className={'d-block mb__2'} required>Confirm Account
                                                    Number</FormLabel>
                                                <TextField
                                                    value={accountTwoAccountC}
                                                    error={(isSubmitted && !accountTwoAccountC) || (accountTwoAccountC && accountTwoAccountC !== accountTwoAccount)}
                                                    disabled={isDisabled}
                                                    onChange={event => setAccountTwoAccountC(event.target.value)}
                                                    fullWidth
                                                    placeholder={'XXXXXXXX'}
                                                />
                                            </FormControl>
                                        </Box>
                                    </Alert>
                                )}

                                <FormControl error={isSubmitted && !accountTwoAmount}>
                                    <FormLabel
                                        required
                                        className={'d-block mb__2'}
                                        children={'How much to deposit into account #2?'}
                                    />
                                    <InputSelect
                                        value={accountTwoAmount}
                                        error={isSubmitted && !accountTwoAmount}
                                        options={['0', '25', '50', '75', '100'].map((option) => {
                                            return {
                                                label: `${option}%`,
                                                value: option,
                                            };
                                        })}
                                        required
                                        disabled={isDisabled}
                                        onChange={event => {
                                            const currentAmount = event.target.value ? parseInt(event.target.value) : 0;
                                            setAccountTwoAmount(event.target.value)
                                            setAccountOneAmount((100 - currentAmount).toString());
                                        }}
                                    />
                                </FormControl>

                                {isOverAmount && (
                                    <Alert severity={'error'}>
                                        The total amount of both accounts cannot exceed 100%.
                                    </Alert>
                                )}

                                {isUnderAmount && (
                                    <Alert severity={'error'}>
                                        The total amount of both accounts should equal 100%.
                                    </Alert>
                                )}
                            </>
                        )}

                        {totalAccounts === 'two' && !readOnly && (
                            <TableContainer className={'table table--striped'}>
                                <Table>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <Box className={'d-flex__start'}>
                                                    <AccountBalanceIcon/> <b className={'ml__2'}>
                                                        Account Review
                                                    </b>
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Box>
                                                    <b>Account
                                                        #1:</b> {accountOneInstitution || '(no name)'} - {accountOneAmount || '0'}%
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <Box>
                                                    <b>Account
                                                        #2:</b> {accountTwoInstitution || '(no name)'} - {accountTwoAmount || '0'}%
                                                </Box>
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Box>
                )}

                <InputSignature
                    value={signature}
                    label={'Please sign here:'}
                    disabled={isDisabled || hasDirectDeposit}
                    onChange={setSignature}
                    scaleFactor={2}
                />

                {!readOnly && (
                    <StepControls
                        step={step}
                        onBack={onStepChange}
                        onNext={!isDisabled ? handleValidation : onValidate}
                        disabled={isDisabled || (!signature && !hasDirectDeposit)}
                    />
                )}
            </Box>

            <Snackbar
                open={!!error}
                onClose={handleErrorClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={4000}
            >
                <Alert
                    sx={{width: '100%'}}
                    onClose={handleErrorClose}
                    severity={'error'}
                >
                    {error}
                </Alert>
            </Snackbar>
        </Step>
    );
});

export default DirectDepositStep;