import React from "react";

import App from "../Global/App";
import Model from "../Definitions/Model";
import Property from "../Definitions/Property";
import PropertyMap from "../Definitions/PropertyMap";
import ThreadPreview from "./ChatThread/ThreadPreview";
import CallLogActions from "../Actions/CallLogActions";
import ChatThreadActions from "../Actions/ChatThreadActions";

/**
 * ChatThread model.
 *
 * @type {Object}
 */
const ChatThread = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'chat-thread',


    /**
     * The appropriate API endpoint root.
     */
    route: 'chat-threads',


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'updatedDate'
    ],


    /**
     * Adjust the index layout accordingly.
     */
    tableOptions: {
        ...Model.tableOptions,
        rowPadding: 'none'
    },


    /**
     * Returns any default query parameters.
     *
     * @returns {*|{}}
     */
    getQuery() {
        const user = App.getUser();
        const filter = this.getDefaultFilter();

        const query = {
            $expand: 'department,employee,user,messages($orderby=createdDate desc;$top=10)',
            $filter: `userId in {${user.id}}`,
            $orderby: 'updatedDate desc'
        };

        return filter && filter.query ? {
            ...query,
            ...filter.query
        } : query;
    },


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'updatedDate',
            label: 'Chat Thread',
            render: (record, doReload, onClick) => <ThreadPreview record={record} onClick={onClick} doReload={doReload}/>,
            sortable: true
        })
    ])
};

export default ChatThread;