import React, {useEffect, useMemo, useState} from "react";

import API from "../../../Global/API";
import Logger from "../../../Global/Logger";
import TabPanel from "../../../Components/TabPanel";
import HourlyDistribution from "./EventMetrics/HourlyDistribution";
import DisciplinaryAction from "./EventMetrics/DisciplinaryAction";
import ExpiringAvailability from "./EventMetrics/ExpiringAvailability";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import CachedIcon from '@mui/icons-material/Cached';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import AssessmentIcon from '@mui/icons-material/Assessment';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';

/**
 * The number of results per page to pull.
 *
 * @type {number}
 */
const perPageLimit = 250;


/**
 * EventMetrics component.
 *
 * @returns {Element}
 * @constructor
 */
const EventMetrics = (props) => {
    const {
        isVisible,                     // {Boolean} Whether we should load the data.
    } = props;

    const [tab, setTab] = useState(0);
    const [index, setIndex] = useState(0);
    const [endDate, setEndDate] = useState(dayjs().endOf('month').format('YYYY-MM-DD'));
    const [isStarted, setStarted] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));
    const [controller, setController] = useState(new AbortController());

    /**
     * Abort any pending requests when the user closes the outer modal.
     */
    useEffect(() => {
        if(!isVisible){
            controller.abort();
            window.onbeforeunload = undefined;
        }
        else{
            window.onbeforeunload = () => true;
        }
    }, [isVisible]);

    /**
     * Loads all employee data for various reporting types.
     *
     * @returns {Promise<void>}
     */
    const getAllEmployees = async () => {
        let promises = [];
        setLoading(true);

        // Determine the filter criteria.
        const $filter = `isActive eq {1} and isDeleted eq {0}`;

        // Load all the clients page by page until we've reached the end.
        const count = await API.get('employees/count', {
            $top: perPageLimit,
            $filter,
        });

        for (let page = 0; page < count.pages; page++) {
            promises.push(
                API.get('employees', {
                    $top: perPageLimit,
                    $skip: page * perPageLimit,
                    $filter,
                    $expand: 'specialty',
                    $select: '*,lastAvailabilityDate,lastShiftDate,pointTotal',
                    $orderby: `lastName asc`,
                    '@cache': true,
                    '@signal': controller.signal,
                })
            );
        }

        try {
            const results = await Promise.all(promises);
            setEmployees(results.flat());
            setLoading(false);
        } catch (e) {
            Logger.warn('[EventMetrics] Unable to load employee data.', e);
        }
    };


    /**
     * Updates the index to refresh the layout.
     */
    const doRefresh = () => {
        setIndex(index + 1);
        setStarted(true);
        getAllEmployees();
    };

    return isVisible && (
        <Box>
            <Box className={'p__3 d-flex__justify'}>
                <Box>
                    <Box className={'index__title d-flex__start'}>
                        <AssessmentIcon/>

                        <h3 className={'m__0'}>
                            View Reports
                        </h3>
                    </Box>

                    <Box className={'text__small'}>Manage employees, track expiring availability, etc.</Box>
                </Box>

                <Box className={'d-flex__start'}>
                    <DatePicker
                        label="Start Date"
                        value={startDate ? dayjs(startDate) : null}
                        disabled={isLoading}
                        onChange={event => setStartDate(event ? event.format('YYYY-MM-DD') : '')}
                        className={'mr__2'}
                    />

                    <DatePicker
                        label={'End Date'}
                        value={endDate ? dayjs(endDate) : null}
                        disabled={isLoading}
                        onChange={event => setEndDate(event ? event.format('YYYY-MM-DD') : '')}
                        className={'mr__2'}
                    />

                    <Button
                        sx={{
                            minWidth: 46,
                            padding: '0.5em',
                            paddingTop: '0.5em',
                            paddingBottom: '0.5em'
                        }}
                        onClick={doRefresh}
                        variant={'contained'}
                        disabled={isLoading}
                    >
                        <CachedIcon/>
                    </Button>
                </Box>
            </Box>

            {!isStarted ? (
                <Box className={'p__3 pt__0'}>
                    <Alert
                        icon={<TipsAndUpdatesIcon fontSize={'inherit'}/>}
                        severity={'info'}
                    >
                        This tool provides an easy way to report and track schedules across the entire system. Whenever
                        you're ready, please select a date range and click the refresh button. This process may take a
                        few minutes, so please be patient!
                    </Alert>
                </Box>
            ) : (
                <>
                    <Tabs
                        value={tab}
                        onChange={(event, value) => setTab(value)}
                    >
                        <Tab label={'Hours by Facility'} disabled={isLoading}/>
                        <Tab label={'Expiring Availability'} disabled={isLoading}/>
                        <Tab label={'Disciplinary Action'} disabled={isLoading}/>
                    </Tabs>
                    <Divider/>
                    <TabPanel value={tab} index={0} fullWidth>
                        {isStarted && (
                            <HourlyDistribution
                                key={`hourly-distribution-${index}`}
                                endDate={endDate}
                                loading={isLoading}
                                startDate={startDate}
                                controller={controller}
                            />
                        )}
                    </TabPanel>
                    <TabPanel value={tab} index={1} fullWidth>
                        <ExpiringAvailability
                            key={`expiring-availability-${index}`}
                            endDate={endDate}
                            loading={isLoading}
                            employees={employees}
                            startDate={startDate}
                            controller={controller}
                        />
                    </TabPanel>
                    <TabPanel value={tab} index={2} fullWidth>
                        <DisciplinaryAction
                            loading={isLoading}
                            employees={employees}
                            controller={controller}
                        />
                    </TabPanel>
                </>
            )}
        </Box>
    );
};

export default EventMetrics;