import React, {useState} from "react";

import API from "../Global/API";
import App from "../Global/App";
import State from "../Global/State";
import AddNote from "./NoteForm";
import Settings from "../Global/Settings";
import {useAuth} from "../Global/Auth";
import PayRateForm from "./EmployeeActions/PayRateForm";
import EmployeeForm from "./EmployeeActions/EmployeeForm";
import DialogHeading from "../Components/Typography/DialogHeading";
import AvailabilityRequest from "./EmployeeActions/AvailabilityRequest";

import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

/**
 * EmployeeActions component.
 *
 * @returns {*}
 * @constructor
 */
const EmployeeActions = (props) => {
    const {
        record,         // {Object} The employee record / context.
        onClose,        // {Function} An optional close handler for the parent layout.
        doReload,       // {Function} Reloads the parent layout, if applicable.
    } = props;

    const {id} = record;
    const navigate = useNavigate();
    const {hasPermissionTo} = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isEditing, setEditing] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isAddingNote, setAddingNote] = useState(false);
    const [isConfirmingDelete, setConfirmDelete] = useState(false);
    const [isEditingPayRates, setEditingPayRates] = useState(false);
    const [isResettingTraining, setResettingTraining] = useState(false);
    const [isViewingAvailability, setAvailability] = useState(false);

    /**
     * Indicates whether we have an anchor element.
     *
     * @type {boolean}
     */
    const open = Boolean(anchorEl);


    /**
     * Indicates whether the employee is currently within the recruiting pipeline.
     *
     * @returns {boolean}
     */
    const isRecruiting = () => {
        return !record.isActive;
    };


    /**
     * Login as the employee record.
     */
    const handleEmployeeImpersonate = () => {
        State.set('auth-user', JSON.stringify({
            ...record,
            ['@model']: 'Employee'
        }));
        State.set('auth-scope', 'Employee');

        // Using a hard location reset to ensure that style updates are
        // being accounted for after the navigation.
        //
        window.location = '/';
    };


    /**
     * Fired whenever the menu button is clicked.
     *
     * @param event
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Closes the menu popover.
     */
    const handleClose = () => {
        setAnchorEl(null);
    };


    /**
     * Resets the employee's training.
     *
     * @returns {Promise<void>}
     */
    const doTrainingReset = async () => {
        setLoading(true);

        await API.post(`reports/reset-training`, {
            employeeId: id
        });

        if (doReload) {
            doReload(record);
        }

        setLoading(false);
        setResettingTraining(false);
    }


    /**
     * Reveals the reset dialog.
     */
    const handleTrainingResetOpen = () => {
        setResettingTraining(true);
    };


    /**
     * Closes the reset dialog.
     */
    const handleTrainingResetClose = () => {
        setResettingTraining(false);
    };


    /**
     * Reveals the "Add a Note" form.
     */
    const handleAddNoteOpen = () => {
        handleClose();
        setAddingNote(true);
    };


    /**
     * Hides the "Add a Note" form.
     */
    const handleAddNoteClose = () => {
        setAddingNote(false);
    };


    /**
     * Reveals the employee edit form.
     */
    const handleEmployeeEditOpen = () => {
        handleClose();
        setEditing(true);
    };


    /**
     * Hides the employee edit form.
     */
    const handleEmployeeEditClose = () => {
        setEditing(false);
    };


    /**
     * Handles the employee record save.
     */
    const handleEmployeeSave = (record) => {
        handleEmployeeEditClose();

        if (doReload) {
            doReload(record, true);
        }
    };


    /**
     * Reveals the delete confirmation.
     */
    const handleDeleteConfirmOpen = () => {
        setConfirmDelete(true);
    };


    /**
     * Hides the delete confirmation.
     */
    const handleDeleteConfirmClose = () => {
        setConfirmDelete(false);
    };


    /**
     * Reveals the availability dialog.
     */
    const handleAvailabilityOpen = () => {
        setAvailability(true);
    };


    /**
     * Closes the availability dialog.
     */
    const handleAvailabilityClose = () => {
        setAvailability(false);
    };


    /**
     * Processes the delete action.
     *
     * @returns {Promise<void>}
     */
    const doDelete = async () => {
        setLoading(true);

        const statuses = await API.get('statuses', {
            $top: 1,
            $filter: 'modelType eq {Employee} and shouldDeactivate eq {1} and isDeleted eq {0} and name ne {DNR}'
        });

        if (statuses.length) {
            const status = statuses[0];

            const response = await API.put(`employees/${id}`, {
                statusId: status.id
            });

            if (doReload) {
                doReload(response);
            }
        }

        setLoading(false);
        setConfirmDelete(false);
    };


    /**
     * Handles the schedule redirect.
     */
    const handleScheduleRedirect = () => {
        navigate(`/schedule/employee/${id}`);

        if (onClose) {
            onClose();
        }
    };


    /**
     * Redirects to the context record and triggers the close event.
     */
    const handleMapRedirect = () => {
        navigate(`/map/${record.latitude}/${record.longitude}?employeeId=${id}`);

        if (onClose) {
            onClose();
        }
    };


    /**
     * Reveals the pay rate modal.
     */
    const handlePayRatesEditOpen = () => {
        handleClose();
        setEditingPayRates(true);
    };


    /**
     * Closes the pay rate modal.
     */
    const handlePayRatesEditClose = () => {
        setEditingPayRates(false);
    };


    /**
     * Reveals the Helix redirect in a new URL.
     */
    const handleHelixRedirect = () => {
        window.open(`${Settings.helixUrl}/Caregivers/${record.helixId}`, '_blank');
    };

    return (
        <Box key={`EmployeeActions-${id}__record`}>
            <IconButton onClick={handleClick}>
                <MoreHorizIcon/>
            </IconButton>
            <Menu
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
            >
                <MenuItem
                    onClick={() => navigate(`/employees/${id}`)}
                    children={'View Profile'}
                />

                {hasPermissionTo('EDIT_EMPLOYEES') && [
                    <Divider key={`EmployeeActions-${id}__edit-divider`}/>,
                    <MenuItem
                        key={`EmployeeActions-${id}__add-note`}
                        onClick={handleAddNoteOpen}
                        children={'Add a Note'}
                    />,
                    <MenuItem
                        key={`EmployeeActions-${id}__print-badge`}
                        onClick={() => navigate(`/employees/${id}/badge`)}
                        children={'Print Badge'}
                    />,
                    <MenuItem
                        key={`EmployeeActions-${id}__reset-training`}
                        onClick={handleTrainingResetOpen}
                        children={'Reset Training'}
                    />,
                    <MenuItem
                        key={`EmployeeActions-${id}__edit-employee`}
                        onClick={handleEmployeeEditOpen}
                        children={'Edit Employee'}
                    />,
                    <MenuItem
                        key={`EmployeeActions-${id}__set-availability`}
                        onClick={handleAvailabilityOpen}
                        children={'Set Availability'}
                    />,
                    <MenuItem
                        key={`EmployeeActions-${id}__pay-rates`}
                        onClick={handlePayRatesEditOpen}
                        children={'Update Pay Rates'}
                    />
                ]}

                {/*Handle active candidates.*/}
                {!isRecruiting() && [
                    <Divider key={`EmployeeActions-${id}__active-divider`}/>,

                    hasPermissionTo('VIEW_SCHEDULE') ? (
                        <MenuItem
                            key={`EmployeeActions-${id}__manage-schedule`}
                            onClick={handleScheduleRedirect}
                            children={'Manage Schedule'}
                        />
                    ) : null,

                    <MenuItem
                        key={`EmployeeActions-${id}__view-map`}
                        onClick={handleMapRedirect}
                        children={'View on Map'}
                        disabled={!record.latitude || !record.longitude}
                    />
                ]}

                {App.isInstance('reliant') && [
                    isRecruiting() ? (
                        <Divider key={`EmployeeActions-${id}__recruiting-divider`}/>
                    ) : null,

                    <MenuItem
                        key={`EmployeeActions-${id}__view-helix`}
                        onClick={() => handleHelixRedirect()}
                        disabled={!record.helixId}
                        children={'View Helix Profile'}
                    />
                ]}

                {(hasPermissionTo('VIEW_SETTINGS') || hasPermissionTo('DELETE_EMPLOYEES')) && (
                    <Divider/>
                )}

                {hasPermissionTo('VIEW_SETTINGS') && (
                    <MenuItem
                        onClick={handleEmployeeImpersonate}
                        disabled={!App.isLocal()}
                    >Login as "{record.firstName}"</MenuItem>
                )}

                {hasPermissionTo('DELETE_EMPLOYEES') && (
                    <MenuItem
                        onClick={handleDeleteConfirmOpen}
                        children={'Delete Record'}
                        disabled={record.isDeleted}
                        className={'menu__error'}
                    />
                )}
            </Menu>

            {isAddingNote && (
                <AddNote
                    open={true}
                    record={record}
                    onSave={() => doReload(record)}
                    modelId={id}
                    onClose={handleAddNoteClose}
                    modelType={'Employee'}
                />
            )}

            {isEditing && (
                <EmployeeForm
                    open={true}
                    record={record}
                    onSave={handleEmployeeSave}
                    onClose={handleEmployeeEditClose}
                />
            )}

            {isViewingAvailability && (
                <AvailabilityRequest
                    open={true}
                    record={record}
                    onSave={record => doReload(record)}
                    onClose={handleAvailabilityClose}
                />
            )}

            {isEditingPayRates && (
                <PayRateForm
                    open={true}
                    record={record}
                    onSave={record => doReload(record)}
                    onClose={handlePayRatesEditClose}
                />
            )}

            {isResettingTraining && (
                <Dialog
                    open={isResettingTraining}
                    scroll={'body'}
                    onClose={handleTrainingResetClose}
                    maxWidth={'xs'}
                    fullWidth
                >
                    <DialogHeading
                        title={"Confirm Reset"}
                        noMargin
                    />
                    <DialogContent>
                        Are you sure that you'd like to reset this employee's training? This action cannot be undone.
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color={'error'}
                            onClick={doTrainingReset}
                            children={'Confirm'}
                            disabled={isLoading}
                        />
                    </DialogActions>
                </Dialog>
            )}

            {isConfirmingDelete && (
                <Dialog
                    open={isConfirmingDelete}
                    scroll={'body'}
                    onClose={handleDeleteConfirmClose}
                    maxWidth={'xs'}
                    fullWidth
                >
                    <DialogHeading
                        title={"Confirm Delete"}
                        noMargin
                    />
                    <DialogContent>
                        Are you sure that you'd like to delete this record?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color={'error'}
                            onClick={doDelete}
                            children={'Delete'}
                            disabled={isLoading}
                        />
                    </DialogActions>
                </Dialog>
            )}
        </Box>
    );
};

export default EmployeeActions;