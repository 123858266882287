import React, {useEffect, useMemo, useState} from "react";

import Step from "../../../Components/Step";
import Formatter from "../../../Global/Formatter";
import LogoHeading from "../../../Components/Typography/LogoHeading";
import StepControls from "../../../Components/StepControls";
import InputSignature from "../../../Components/Input/InputSignature";
import withSynchronizedSettings from "../Abstract/withSynchronizedSettings";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";

/**
 * ResidencyDisclosureStep component.
 *
 * @returns {*}
 * @constructor
 */
const ResidencyDisclosureStep = withSynchronizedSettings((props) => {
    const {
        step,                   // {Number} The current step for the user.
        index,                  // {Number} The expected step number.
        doSync,                 // {Function} The synchronization callback.
        employee,               // {Object} The employee record for added context
        readOnly,               // {Boolean} Whether the form is disabled.
        disabled,               // {Boolean} Whether the step is disabled from input.
        onValidate,             // {Function} The post-validation action.
        doValidate,             // {Function} Triggers the validation event.
        getSetting,             // {Function} Retrieves a particular setting value.
        isSubmitted,            // {Boolean} Indicates whether the parent form has been submitted.
        onStepChange,           // {Function} The step change callback for the parent component.
    } = props;

    /**
     * All available state storage for this step.
     */
    const [signature, setSignature] = useState(getSetting('HP_RD_SIGNATURE', ''));
    const [yearsInState, setYearsInState] = useState(getSetting('HP_RD_YEARS_IN_STATE', ''));
    const [monthsInState, setMonthsInState] = useState(getSetting('HP_RD_MONTHS_IN_STATE', ''));
    const [currentAddress, setCurrentAddress] = useState(getSetting('HP_RD_CURRENT_ADDRESS', ''));
    const [previousAddress, setPreviousAddress] = useState(getSetting('HP_RD_PREVIOUS_ADDRESS', ''));

    /**
     * Synchronize backend settings.
     */
    useEffect(() => {
        doSync('HP_RD_YEARS_IN_STATE', yearsInState);
    }, [yearsInState]);

    useEffect(() => {
        doSync('HP_RD_MONTHS_IN_STATE', monthsInState);
    }, [monthsInState]);

    useEffect(() => {
        doSync('HP_RD_CURRENT_ADDRESS', currentAddress);
    }, [currentAddress]);

    useEffect(() => {
        doSync('HP_RD_PREVIOUS_ADDRESS', previousAddress);
    }, [previousAddress]);

    useEffect(() => {
        doSync('HP_RD_SIGNATURE', signature);
    }, [signature]);


    /**
     * Synchronize any default values.
     */
    useEffect(() => {
        if(disabled){
            return;
        }

        if(!currentAddress){
            setCurrentAddress(
                Formatter.address(employee.addressLine1, employee.addressLine2, employee.city, 'PA', employee.postalCode)
            );
        }
    }, [disabled]);


    /**
     * Handles the form validation.
     */
    const handleValidation = () => {
        doValidate([
            yearsInState,
            monthsInState,
            currentAddress,
        ]);
    };


    /**
     * The disabled state of each input.
     */
    const isDisabled = disabled || readOnly;

    return (
        <Step step={index} value={step}>
            <LogoHeading title={'Residency Disclosure'}/>

            <Box className={'columns__1'}>
                <FormControl error={isSubmitted && !yearsInState}>
                    <FormLabel
                        required
                        children={'How many years have you been a resident of Pennsylvania?'}
                        className={'d-block mb__2'}
                    />
                    <TextField
                        type={'number'}
                        value={yearsInState}
                        error={isSubmitted && !yearsInState}
                        disabled={isDisabled}
                        onChange={event => setYearsInState(event.target.value)}
                        fullWidth
                        placeholder={'Years in PA'}
                    />
                </FormControl>

                <FormControl error={isSubmitted && !monthsInState}>
                    <FormLabel
                        children={'How many months have you been a resident of Pennsylvania?'}
                        required
                        className={'d-block mb__2'}
                    />
                    <TextField
                        type={'number'}
                        value={monthsInState}
                        error={isSubmitted && !monthsInState}
                        disabled={isDisabled}
                        onChange={event => setMonthsInState(event.target.value)}
                        fullWidth
                        placeholder={'Months in PA'}
                    />
                </FormControl>

                <FormControl error={isSubmitted && !currentAddress}>
                    <FormLabel
                        required
                        children={'What is your current address?'}
                        className={'d-block mb__2'}
                    />
                    <TextField
                        value={currentAddress}
                        error={isSubmitted && !currentAddress}
                        disabled={isDisabled}
                        onChange={event => setCurrentAddress(event.target.value)}
                        fullWidth
                        placeholder={'Current Address'}
                    />
                </FormControl>

                <Box>
                    <FormLabel className={'d-block mb__2'}>
                        If you have not been a resident of Pennsylvania for a minimum of the last two years, please
                        describe in detail where you have lived out of state below.
                    </FormLabel>
                    <TextField
                        rows={3}
                        value={previousAddress}
                        disabled={isDisabled}
                        onChange={event => setPreviousAddress(event.target.value)}
                        multiline
                        fullWidth
                        placeholder={'Please explain...'}
                    />
                </Box>

                <InputSignature
                    value={signature}
                    label={'Please sign here:'}
                    disabled={isDisabled}
                    onChange={setSignature}
                    scaleFactor={2}
                />

                {!readOnly && (
                    <StepControls
                        step={step}
                        onBack={onStepChange}
                        onNext={!isDisabled ? handleValidation : onValidate}
                        disabled={isDisabled || !signature}
                    />
                )}
            </Box>
        </Step>
    );
});

export default ResidencyDisclosureStep;