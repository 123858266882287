import React from "react";

import API from "../Global/API";
import Model from "../Definitions/Model";
import Property from "../Definitions/Property";
import PropertyMap from "../Definitions/PropertyMap";
import ImageWrapper from "../Components/ImageWrapper";
import SectionCount from "../Pages/Employee/Course/SectionCount";

import Box from "@mui/material/Box";
import {Link} from "react-router-dom";
import Button from "@mui/material/Button";
import CameraAltIcon from '@mui/icons-material/CameraAlt';

/**
 * EmployeeCourse model.
 *
 * @type {Object}
 */
const EmployeeCourse = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'course',


    /**
     * The appropriate API endpoint root.
     */
    route: 'courses',


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'name',
    ],


    /**
     * Additional query parameters for the index layout.
     */
    query: {
        $filter: 'isPublished eq {1} and isDeleted eq {0}'
    },


    /**
     * Adjust the index layout accordingly.
     */
    tableOptions: {
        ...Model.tableOptions,
        hideTableHeader: true
    },


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'name',
            label: 'Course',
            render: (record) => (
                <Box className={'p__3 columns__1'}>
                    <Box className={'d-flex__start'}>
                        <ImageWrapper
                            sx={{marginRight: '1em'}}
                            src={API.getFilePath(record.image)}
                            icon={<CameraAltIcon fontSize={'large'}/>}
                            width={64}
                            height={64}
                            horizontal
                        />

                        <Box>
                            <Link to={`/training/course/${record.id}`}>
                                <h3 className={'d-inline__block m__0'}>{record.name}</h3>
                            </Link>

                            <SectionCount courseId={record.id}/>
                        </Box>
                    </Box>

                    <Box className={'flex__grow columns__1'}>
                        {record.shortDescription && (
                            <Box>{record.shortDescription || '(no description)'}</Box>
                        )}

                        <Link
                            to={`/training/course/${record.id}`}
                            className={'columns__1'}
                        >
                            <Button variant={'outlined'} >
                                View Course
                            </Button>
                        </Link>
                    </Box>
                </Box>
            ),
            sortable: true
        })
    ])
};

export default EmployeeCourse;