import React, {useRef, useState} from "react";

import API from "../../Global/API";
import Page from "../../Components/Page";
import TabPanel from "../../Components/TabPanel";
import BillableHours from "./Reports/BillableHours";
import DialogHeading from "../../Components/Typography/DialogHeading";
import TimeSheetReport from "./Reports/TimeSheetReport";
import EmployeesByType from "./Reports/EmployeesByType";
import ShiftsByEmployee from "./Reports/ShiftsByEmployee";
import ActivationsReport from "./Reports/ActivationsReport";
import ExpiringDocuments from "./Reports/ExpiringDocuments";
import MissingTimeSheets from "./Reports/MissingTimeSheets";
import EmployeesByFacility from "./Reports/EmployeesByFacility";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import PrintIcon from "@mui/icons-material/Print";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import DialogActions from "@mui/material/DialogActions";
import AssessmentIcon from '@mui/icons-material/Assessment';

/**
 * Reports component.
 *
 * @returns {*}
 * @constructor
 */
const Reports = () => {
    /**
     * Capture any request parameters for this layout.
     */
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const defaultTab = urlParams.get('tab');


    /**
     * Various state parameters for this layout.
     */
    const [tab, setTab] = useState(defaultTab || 'Billable Hours');
    const [isLoading, setLoading] = useState(false);
    const [hasResults, setHasResults] = useState({});
    const [downloadPath, setDownloadPath] = useState('');


    /**
     * A list of all available reports.
     *
     * @type {string[]}
     */
    const tabs = [
        'Billable Hours',
        'Expiring Documents',
        'Activations Report',
        'Employees by Facility',
        'Shifts By Employee',
        'Time Sheets',
        'Missing Time Sheets',
        'Employees by Type',
    ];


    /**
     * Initializes the print button whenever results are available.
     */
    const handleReportComplete = (ref) => {
        setHasResults({
            ...hasResults,
            [tab]: ref
        });
    };


    /**
     * Handles the tab change event.
     *
     * @param event
     * @param value
     */
    const handleTabChange = (event, value) => {
        setTab(value);
    };


    /**
     * Prints the report results to an image.
     */
    const doPrint = async () => {
        const reference = hasResults[tab];

        if(!reference){
            return;
        }

        setLoading(true);

        const response = await API.post('pdf', {
            html: reference.current.innerHTML,
            heading: tabs[tab]
        });

        if(response.path){
            setDownloadPath(response.path);
        }

        setLoading(false);
    };


    /**
     * Closes the preview dialog.
     */
    const handlePreviewClose = () => {
        setDownloadPath('');
    };


    /**
     * Indicates if we've received a result for the selection.
     *
     * @type {boolean}
     */
    const hasResultsForTab = !!hasResults[tab];

    return (
        <Page hideHeader fullScreen>
            <Box className={'page__heading'}>
                <Box className={'index__title d-flex__start'}>
                    <AssessmentIcon/>
                    <h2>Reports</h2>
                </Box>
            </Box>
            <Divider/>
            <Box className={'d-flex__justify'}>
                <Tabs
                    value={tab}
                    onChange={handleTabChange}
                >
                    {tabs.map((tab, i) => <Tab key={i} label={tab} value={tab}/>)}
                </Tabs>

                <IconButton
                    onClick={doPrint}
                    children={<PrintIcon/>}
                    disabled={isLoading || !hasResultsForTab}
                    className={'mr__3'}
                />
            </Box>
            <Divider/>
            <Box>
                <TabPanel value={tab} index={'Billable Hours'} fullWidth>
                    <BillableHours
                        loading={isLoading}
                        onComplete={handleReportComplete}
                    />
                </TabPanel>
                <TabPanel value={tab} index={'Expiring Documents'} fullWidth>
                    <ExpiringDocuments
                        loading={isLoading}
                        onComplete={handleReportComplete}
                    />
                </TabPanel>
                <TabPanel value={tab} index={'Activations Report'} fullWidth>
                    <ActivationsReport
                        loading={isLoading}
                        onComplete={handleReportComplete}
                    />
                </TabPanel>
                <TabPanel value={tab} index={'Employees by Facility'} fullWidth>
                    <EmployeesByFacility
                        loading={isLoading}
                        onComplete={handleReportComplete}
                    />
                </TabPanel>
                <TabPanel value={tab} index={'Time Sheets'} fullWidth>
                    <TimeSheetReport
                        loading={isLoading}
                        onComplete={handleReportComplete}
                    />
                </TabPanel>
                <TabPanel value={tab} index={'Shifts By Employee'} fullWidth>
                    <ShiftsByEmployee
                        loading={isLoading}
                        onComplete={handleReportComplete}
                    />
                </TabPanel>
                <TabPanel value={tab} index={'Missing Time Sheets'} fullWidth>
                    <MissingTimeSheets
                        loading={isLoading}
                        onComplete={handleReportComplete}
                    />
                </TabPanel>
                <TabPanel value={tab} index={'Employees by Type'} fullWidth>
                    <EmployeesByType
                        loading={isLoading}
                        onComplete={handleReportComplete}
                    />
                </TabPanel>
            </Box>

            {!!downloadPath && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handlePreviewClose}
                    maxWidth={'lg'}
                    fullWidth
                >
                    <DialogHeading
                        title={'Download Report'}
                        actions={
                            <IconButton
                                size={'small'}
                                onClick={handlePreviewClose}
                                children={<CloseIcon fontSize={'small'}/>}
                            />
                        }
                        noMargin
                    />
                    <Box className={'iframe__container'}>
                        <embed
                            src={API.getFilePath(downloadPath)}
                            scrolling={'no'}
                            className={'responsive-iframe'}
                        />
                    </Box>
                    <DialogActions>
                        <Button
                            children={'Close'}
                            onClick={handlePreviewClose}
                        />
                    </DialogActions>
                </Dialog>
            )}
        </Page>
    );
};

export default Reports;