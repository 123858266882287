import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import AddNote from "../../../Actions/NoteForm";
import {useAuth} from "../../../Global/Auth";
import ClientView from "../Client/ClientView";
import EmployeeMenu from "./EmployeeMenu";
import EmployeeListItem from "../../../Components/Lists/EmployeeListItem";
import ExportShiftsDialog from "../../../Actions/ClientActions/ExportShiftsDialog";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Accordion from "@mui/material/Accordion";
import Typography from "@mui/material/Typography";
import EditNoteIcon from "@mui/icons-material/EditNote";
import DialogContent from "@mui/material/DialogContent";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

/**
 * ClientSlotMenu component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ClientSlotMenu = (props) => {
    const {
        record,         // {Object} The employee record / context.
        doReload,       // {Function} A reload callback for the client object.
        onHighlight,    // {Function} A highlight callback for synchronizing the selected employee.
    } = props;

    const {hasPermissionTo} = useAuth();
    const [isAddingNote, setAddingNote] = useState(false);
    const [isViewingShifts, setViewingShifts] = useState(false);
    const [isViewingProfile, setViewingProfile] = useState(false);
    const [selectedEmployee, setSelectedEmployee] = useState({});
    const [assignedEmployees, setAssignedEmployees] = useState([]);

    /**
     * Loads all required data on mount.
     */
    useEffect(() => {
        getAssignedEmployees();
    }, []);


    /**
     * Loads any assigned employees.
     *
     * @returns {Promise<void>}
     */
    const getAssignedEmployees = async () => {
        const results = await API.get('employee-clients', {
            $top: 1000,
            $expand: 'employee($expand=status,state,specialty;$select=*,pointTotal)',
            $filter: `clientId in {${record.id}} and employee/any{isActive eq {1} and isDeleted eq {0}}`
        });

        setAssignedEmployees(results.sort((a, b) => {
            const {employee: employeeA} = a;
            const {employee: employeeB} = b;

            return (employeeA.lastName > employeeB.lastName) ? 1 : ((employeeB.lastName > employeeA.lastName) ? -1 : 0)
        }))
    };


    /**
     * Closes the "Add Note" dialog.
     */
    const handleAddNoteClose = () => {
        setAddingNote(false);
    };


    /**
     * Reveals the "Add Note" dialog.
     */
    const handleAddNoteOpen = () => {
        setAddingNote(true);
    };


    /**
     * Reveals the profile modal.
     */
    const handleViewOpen = () => {
        setViewingProfile(true);
    };


    /**
     * Closes the profile modal.
     */
    const handleViewClose = () => {
        setViewingProfile(false);
    };


    /**
     * Selects an employee to highlight events for.
     *
     * @param employee
     */
    const handleEmployeeSelect = (employee) => {
        let updatedSelection = {};

        if (selectedEmployee && selectedEmployee.id === employee.id) {
            updatedSelection = {};
        } else {
            updatedSelection = employee;
        }

        setSelectedEmployee(updatedSelection);

        if (onHighlight) {
            onHighlight(updatedSelection);
        }
    };

    /**
     * Toggles the shift export modal.
     */
    const handleShiftExportOpen = () => {
        setViewingShifts(true)
    };


    /**
     * Closes the shift export dialog.
     */
    const handleShiftExportClose = () => {
        setViewingShifts(false);
    };

    return (
        <Box className={'flex__grow'}>
            <Box>
                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography>Shortcuts</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{paddingTop: 0}}>
                        <Box className={'columns__1 columns--small'}>
                            {hasPermissionTo('VIEW_CLIENTS') && (
                                <Button
                                    variant={'outlined'}
                                    onClick={handleViewOpen}
                                    children={'View Profile'}
                                    startIcon={<OpenInNewIcon/>}
                                />
                            )}
                            {hasPermissionTo('EDIT_CLIENTS') && (
                                <Button
                                    variant={'outlined'}
                                    onClick={handleAddNoteOpen}
                                    children={'Add Note'}
                                    startIcon={<EditNoteIcon/>}
                                />
                            )}
                            <Button
                                variant={'outlined'}
                                onClick={handleShiftExportOpen}
                                children={'Export Shifts'}
                                startIcon={<FileDownloadIcon/>}
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Accordion defaultExpanded>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography>Employees ({assignedEmployees.length})</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{padding: 0}}>
                        {!assignedEmployees.length && (
                            <Box className={'p__3 text__center text__disclaimer'}>
                                No results available.
                            </Box>
                        )}

                        {assignedEmployees.map(assignedEmployee => {
                            const {employee} = assignedEmployee;

                            const relatedEmployee = {
                                ...employee
                            };

                            return (
                                <EmployeeListItem
                                    key={`employee-${relatedEmployee.id}`}
                                    employee={relatedEmployee}
                                    onSelect={handleEmployeeSelect}
                                    selected={relatedEmployee.id === selectedEmployee.id}
                                    secondary={
                                        hasPermissionTo('EDIT_EMPLOYEES') ? (
                                            <Box className={'mr__2'}>
                                                <EmployeeMenu
                                                    record={relatedEmployee}
                                                    doReload={getAssignedEmployees}
                                                />
                                            </Box>
                                        ) : null
                                    }
                                />
                            )
                        })}
                    </AccordionDetails>
                </Accordion>
            </Box>

            {isAddingNote && (
                <AddNote
                    open={true}
                    record={record}
                    onSave={() => doReload(record)}
                    modelId={record.id}
                    onClose={handleAddNoteClose}
                    modelType={'Client'}
                />
            )}

            {isViewingShifts && (
                <ExportShiftsDialog
                    open={true}
                    record={record}
                    onClose={handleShiftExportClose}
                />
            )}

            {isViewingProfile && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handleViewClose}
                    maxWidth={'xl'}
                    fullWidth
                >
                    <DialogContent sx={{paddingTop: 0}}>
                        <Box className={'columns__1'}>
                            <ClientView
                                id={record.id}
                                onClose={handleViewClose}
                                doReload={getAssignedEmployees}
                            />
                        </Box>
                    </DialogContent>
                </Dialog>
            )}
        </Box>
    );
};

export default ClientSlotMenu;