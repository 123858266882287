import React, {useEffect, useState} from "react";

import API from "../../../../Global/API";
import Logger from "../../../../Global/Logger";

import dayjs from "dayjs";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {DateTimePicker} from "@mui/x-date-pickers";
import CircularProgress from "@mui/material/CircularProgress";

/**
 * ColumnFilter component.
 *
 * @returns {*}
 * @constructor
 */
const ColumnFilter = (props) => {
    const {
        style,                  // {Object} An optional style object.
        model,                  // {Model} The target model for pulling API results.
        value,                  // {*} The default / initial value.
        error,                  // {Boolean} Whether we should highlight the field in error.
        property,               // {Object} The property to find distinct values for.
        required,               // {Boolean} Whether we should require this value.
        disabled,               // {Boolean} Whether we should disable the input.
        onChange,               // {Function} Triggered whenever the user makes a selection.
        helperText,             // {String} Additional text to append to the input.
    } = props;

    // Various internal attributes.
    const label = property.label;
    const multiple = true;
    const filterType = property.filterType;
    const renderLabel = property.renderLabel || (option => option || '');

    // Component state storage.
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [defaultValue, setDefaultValue] = useState(multiple ? [] : null);

    /**
     * Binds the search to the user type event.
     */
    useEffect(() => {
        getOptions();
    }, [inputValue]);


    /**
     * Finds the default option via the API.
     */
    useEffect(() => {
        getDefaultOption();
    }, [value]);


    /**
     * Loads any applicable search results.
     *
     * @returns {Promise<void>}
     */
    const getOptions = async () => {
        Logger.debug(`[ColumnFilter] Loading search results for "${inputValue}"`);
        setLoading(true);

        // Pull general search results.
        const results = property.filterResults ?
            await property.filterResults(inputValue) :
            await API.get(model.getRoute(), {
                $select: property.key,
                $filter: `${property.key} ne {null}` + (inputValue ? `${property.key} eq {${inputValue}}` : ''),
                $orderby: `${property.key} asc`,
                '@cache': true
            });

        setOptions(results);
        setLoading(false);
    };


    /**
     * Loads the default option via the API.
     *
     * @returns {Promise<void>}
     */
    const getDefaultOption = async () => {
        if(multiple){
            if (value !== false && (!value || !value.length)) {
                return setDefaultValue([]);
            }

            Logger.debug(`[ColumnFilter] Assigning default options for "${property.key}": ${value.join(',')}`);

            if(property.defaultFilterOptions){
                setDefaultValue(
                    await property.defaultFilterOptions(value)
                );
            }
            else{
                setDefaultValue(value);
            }
        }
        else{
            if (!value) {
                return setDefaultValue(null);
            }

            Logger.debug(`[ColumnFilter] Assigning default option: ${value}`);
            setDefaultValue(value);
        }
    };


    /**
     * Processes the change event.
     *
     * @param event
     * @param value
     * @returns {*}
     */
    const handleChange = (event, value) => {
        if (!onChange) {
            return;
        }

        if(!multiple){
            if (value && value.id) {
                return onChange(value.id ? value.id : value);
            }

            onChange(null);
        }
        else{
            if (value && value.length) {
                return onChange(value.map(output => output.id ? output.id : output));
            }

            onChange([]);
        }
    };


    /**
     * Processes the change event.
     *
     * @param event
     * @param from
     * @param to
     * @returns {*}
     */
    const handleRangeChange = (event, from, to) => {
        if (!onChange) {
            return;
        }

        if (from || to) {
            return onChange({
                to,
                from,
            });
        }

        onChange({});
    };

    if(filterType === 'dateTime'){
        const {
            to,
            from,
        } = value || {};

        return (
            <>
                <DateTimePicker
                    value={from ? dayjs(from) : null}
                    label={`${label} (From)`}
                    disabled={loading}
                    onChange={event => {
                        if(!event){
                            return;
                        }

                        const fromDate = event.isValid() ? event.format('YYYY-MM-DD HH:mm:ss') : '';
                        handleRangeChange(event, fromDate, to)
                    }}
                />
                <DateTimePicker
                    value={to ? dayjs(to) : null}
                    label={`${label} (To)`}
                    disabled={loading}
                    onChange={event => {
                        if(!event){
                            return;
                        }

                        const toDate = event.isValid() ? event.format('YYYY-MM-DD HH:mm:ss') : '';
                        handleRangeChange(event, from, toDate)
                    }}
                />
            </>
        );
    }

    if(filterType === 'date'){
        const {
            to,
            from,
        } = value || {};

        return (
            <>
                <DatePicker
                    value={from ? dayjs(from) : null}
                    label={`${label} (From)`}
                    disabled={loading}
                    onChange={event => {
                        if(!event){
                            return;
                        }

                        const fromDate = event.isValid() ? event.format('YYYY-MM-DD') : '';
                        handleRangeChange(event, fromDate, to)
                    }}
                />
                <DatePicker
                    value={to ? dayjs(to) : null}
                    label={`${label} (To)`}
                    disabled={loading}
                    onChange={event => {
                        if(!event){
                            return;
                        }

                        const toDate = event.isValid() ? event.format('YYYY-MM-DD') : '';
                        handleRangeChange(event, from, toDate)
                    }}
                />
            </>
        );
    }

    return (
        <Autocomplete
            open={open}
            value={defaultValue}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            loading={loading}
            options={options}
            disabled={disabled}
            multiple={multiple}
            onChange={handleChange}
            onInputChange={(event, updated) => setInputValue(updated)}
            getOptionLabel={renderLabel}
            isOptionEqualToValue={property.filterIsEqual || ((option, value) => option === value)}
            renderInput={(params) => (
                <TextField
                    {...params}
                    sx={style || {}}
                    label={label}
                    error={error}
                    required={!!required}
                    fullWidth
                    helperText={helperText}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};

export default ColumnFilter;