import React from "react";

import Page from "../../Components/Page";
import EmployeeDocuments from "./Documents/EmployeeDocuments";

/**
 * Documents component.
 *
 * @returns {JSX.Element}
 * @constructor
 *
 * @description Allows employees to upload credential documents to their profile.
 */
const Documents = () => {
    return (
        <Page
            children={<EmployeeDocuments/>}
            hideHeader
        />
    );
};

export default Documents;