import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import State from '../../Global/State';
import {useAuth} from "../../Global/Auth";

import Box from "@mui/material/Box";
import {Link, NavLink} from "react-router-dom";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

/**
 * SecondaryMenu component.
 *
 * @returns {*}
 * @constructor
 */
const SecondaryMenu = () => {
    const {user, hasPermissionTo} = useAuth();
    const [recruitingIndicator, setRecruitingIndicator] = useState(!!State.get('recruiting_indicator'));

    /**
     * Load all ancillary data on mount.
     */
    useEffect(() => {
        getRecruitingIndicator();
    }, []);


    /**
     * Loads whether or not to display the indicator.
     *
     * @returns {Promise<void>}
     */
    const getRecruitingIndicator = async () => {
        const result = await API.get(`employees/count`, {
            $filter: `isDeleted eq {0} and isActive eq {0} and isHighlighted eq {1} and assigneeId in {${user.id}}`
        });

        setRecruitingIndicator(!!result.count);
        State.set('recruiting-indicator', !!result.count ? 'true' : '');
    };

    return (
        <Box className={'submenu'} sx={{
            display: {xs: 'none', md: 'block'}
        }}>
            <Box className={'d-flex__justify'}>
                <Box className={'d-flex'}>
                    {hasPermissionTo('VIEW_EMPLOYEES') && (
                        <NavLink to={'/employees'} className={'submenu__link'}>Employees</NavLink>
                    )}

                    {hasPermissionTo('VIEW_CLIENTS') && (
                        <NavLink to={'/clients'} className={'submenu__link'}>Clients</NavLink>
                    )}

                    {hasPermissionTo('VIEW_RECRUITING') && (
                        <NavLink to={'/recruiting'} className={'submenu__link d-flex__start'}>
                            {recruitingIndicator && (
                                <Box
                                    sx={{width: '0.5em', height: '0.5em', marginRight: '0.5em', marginTop: '0.1em'}}
                                    className={'notification__indicator notification__indicator--highlight'}
                                />
                            )}
                            Recruiting
                        </NavLink>
                    )}

                    {hasPermissionTo('VIEW_SCHEDULE') && (
                        <NavLink to={'/schedule'} className={'submenu__link'}>Schedule</NavLink>
                    )}

                    {hasPermissionTo('VIEW_TRAINING') && (
                        <NavLink to={'/training'} className={'submenu__link'}>Training</NavLink>
                    )}

                    {hasPermissionTo('VIEW_EMPLOYEES') && (
                        <NavLink to={'/map'} className={'submenu__link'}>Map</NavLink>
                    )}
                </Box>

                <Box sx={{display: 'none'}}>
                    <Link className={'submenu__link d-flex__start'}>
                        <div>Primary Office</div>
                        <ArrowDropDownIcon fontSize={'sm'}/>
                    </Link>
                </Box>
            </Box>
        </Box>
    )
};

export default SecondaryMenu;