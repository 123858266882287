import React from "react";

import App from "./App";
import Logger from './Logger';
import PhoneLink from "../Components/Navigation/PhoneLink";

import dayjs from 'dayjs';
import {PhoneNumberUtil, PhoneNumberFormat as PNF} from 'google-libphonenumber';

/**
 * Create an instance of the phone number formatter.
 *
 * @type {Log | * | {} | Logger}
 */
const phoneUtil = PhoneNumberUtil.getInstance();


/**
 * Formatter class.
 */
class Formatter {
    /**
     * Formats / masks a social security number.
     *
     * @param input
     * @returns {string}
     */
    static ssn(input = '') {
        if (!input) {
            return '';
        }

        return '***-**-' + input.substring(input.length - 4).padStart(4, '0');
    }


    static maskSsn(ssn = '') {
        return !ssn ? "" : ssn.length > 11 ? ssn.substr(0, 11) : ssn.replace(/[^\d*]/g, "").replace(/(?=\d{5})\d/, "*")
            .replace(/^(.{1,3})(.{1,2})?(.{1,4})?.*$/, (_, x, y, z) =>
                x + (y ? `-${y}` : '') + (z ? `-${z}` : ''));
    }


    /**
     * Formats an address.
     *
     * @param addressLine1
     * @param addressLine2
     * @param aptNumber
     * @param city
     * @param state
     * @param postalCode
     * @returns {string}
     */
    static address(addressLine1 = '', addressLine2 = '', aptNumber = '', city = '', state = '', postalCode = '') {
        let parts = [];
        let secondary = `${addressLine2 ? ` ${addressLine2}` : ''}${aptNumber ? ` Apt. ${aptNumber}` : ''}`;

        parts.push(addressLine1 ? `${addressLine1}${secondary}` : `${secondary.trim() ? `${secondary.trim()}` : ''}`);
        parts.push(`${city} ${state}`);
        parts.push(postalCode || '');

        return parts.filter(piece => !!piece.trim())
            .map(piece => piece.trim())
            .join(', ')
            .trim();
    }


    /**
     * Formats an input date string.
     *
     * @param date
     * @param fallback
     * @param localize
     * @returns {string}
     */
    static date(date, fallback = '', localize = true) {
        if (!date) {
            return fallback;
        }

        if (typeof date === 'string' || date instanceof String) {
            return localize ?
                dayjs.utc(date).local().format('MM/DD/YYYY') :
                dayjs(date).format('MM/DD/YYYY');
        }

        let year = date.getFullYear();
        let month = (1 + date.getMonth()).toString().padStart(2, '0');
        let day = date.getDate().toString().padStart(2, '0');

        return month + '/' + day + '/' + year;
    }


    /**
     * Returns a formatted date/time string.
     *
     * @param date          {String|Date} The date to format.
     * @param localize      {Boolean} Whether to localize the date.
     * @param modify        {Function} A modification callback for the date instance.
     * @returns {string}
     */
    static dateTime(date, localize = true, modify = null) {
        if (typeof date === 'string' || date instanceof String) {
            let dateDt = localize ?
                dayjs.utc(date).local() :
                dayjs(date);

            if(modify){
                dateDt = modify(dateDt);
            }

            return dateDt.format('MM/DD/YYYY h:mma');
        }

        return Formatter.date(date)
    }


    /**
     * Formats an input string as a clickable email.
     *
     * @param input
     * @returns {*}
     */
    static email(input) {
        return input ? (
            <a href={`mailto: ${input}`}>
                {input}
            </a>
        ) : null
    }


    /**
     * Returns an input number as e164.
     *
     * @param input
     * @returns {*}
     */
    static e164(input) {
        if (!input) {
            return '';
        }

        try {
            const number = phoneUtil.parseAndKeepRawInput(input, 'US');
            return phoneUtil.format(number, PNF.E164);
        } catch (e) {
            Logger.debug('[Formatter] Encountered error while parsing phone input:', input, e);
        }

        return '';
    }


    /**
     * Formats an input string as a clickable telephone link.
     *
     * @param input
     * @returns {*}
     */
    static phone(input) {
        return (
            <PhoneLink phoneNumber={input}/>
        );
    }


    /**
     * Formats a phone number in a clean way.
     *
     * @param input
     * @returns {*}
     */
    static phoneString(input) {
        let updated = input;

        if (updated && updated.substring(0, 2) === '+1') {
            updated = updated.substring(2);
        }

        let cleaned = ('' + updated).replace(/\D/g, '');
        let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }

        return input;
    }


    /**
     * Formats a boolean as a Yes / No string.
     *
     * @param input
     * @returns {string}
     */
    static boolean(input) {
        return input ? 'Yes' : 'No';
    }


    /**
     * Formats a number as a currency string.
     *
     * @param input
     * @returns {string}
     */
    static currency(input) {
        if (!input) {
            input = 0;
        }

        if (typeof input === 'string' || input instanceof String) {
            input = parseFloat(input);
        }

        return `$${input.toFixed(2)}`;
    }


    /**
     * Formats a number in the user's locale.
     *
     * @param input
     * @returns {string}
     */
    static number(input) {
        return input ? input.toLocaleString('en-US') : '0';
    }


    /**
     * Returns an uppercase version of the input string.
     *
     * @param input
     * @returns {*|string}
     */
    static capitalize(input) {
        return input && input[0].toUpperCase() + input.slice(1)
    }


    /**
     * Handles relative links in a way that is friendly with both systems.
     *
     * @param src
     * @returns {*|string}
     */
    static url(src) {
        return App.isCordova() ? src : `/${src}`;
    }


    /**
     * Returns the last four digits of an input string.
     *
     * @param input
     * @returns {number|string}
     */
    static lastFour(input) {
        const inputStr = input || '';
        const inputLength = inputStr.length;

        if (inputLength <= 4) {
            return inputStr;
        }

        return inputStr.substr(inputLength - 4);
    }
}

export default Formatter;