import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import Logger from "../../../Global/Logger";
import {useAuth} from "../../../Global/Auth";
import InputSelect from "../../../Components/Input/InputSelect";
import LogoHeading from "../../../Components/Typography/LogoHeading";
import DialogHeading from "../../../Components/Typography/DialogHeading";
import RecruitingSteps from "../../Employee/Dashboard/RecruitingSteps";
import useScreenOrientation from "../../../Hooks/useScreenOrientation";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {DatePicker} from "@mui/x-date-pickers";
import {useNavigate} from "react-router";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TableContainer from "@mui/material/TableContainer";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import ScreenRotationIcon from '@mui/icons-material/ScreenRotation';

/**
 * AvailabilityForm component.
 *
 * @returns {*}
 * @constructor
 */
const AvailabilityForm = (props) => {
    const {
        employee,       // {Object} The employee record in context.
    } = props;

    const navigate = useNavigate();
    const orientation = useScreenOrientation();
    const {user, isScope, onAuthUpdate, isHired} = useAuth();
    const weekOffset = isScope('Employee') ? 1 : 0;

    /**
     * Various state parameters for this form.
     */
    const [hours, setHours] = useState(0);
    const [error, setError] = useState('');
    const [items, setItems] = useState([]);
    const [isReady, setReady] = useState(false);
    const [comment, setComment] = useState('');
    const [maxWeeks, setMaxWeeks] = useState(6);
    const [isLoading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(dayjs());
    const [isComplete, setComplete] = useState(false);
    const [weekNumber, setWeekNumber] = useState(weekOffset);
    const [isConfirming, setConfirming] = useState(false);
    const [isContinuing, setContinuing] = useState(false);
    const [availability, setAvailability] = useState({});
    const [assignedClients, setAssignedClients] = useState([]);

    /**
     * The current date in DayJS format.
     *
     * @type {dayjs.Dayjs}
     */
    const todayJs = dayjs();


    /**
     * Indicates whether we're viewing in landscape.
     *
     * @type {boolean}
     */
    const isLandscape = orientation.includes('landscape');


    /**
     * Indicates whether we're adding additional weeks.
     *
     * @type {boolean}
     */
    const isAddingAdditional = weekNumber > maxWeeks && isScope('Employee') && isContinuing;


    /**
     * Calculates the starting day of the week.
     */
    const startDateOfWeek = (() => {
        return startDate
            .startOf('weeks')
            .add(weekNumber, 'weeks');
    })();


    /**
     * Calculates the ending day of the week.
     */
    const endDateOfWeek = (() => {
        return startDate
            .startOf('weeks')
            .add(weekNumber + 1, 'weeks')
            .subtract(1, 'days');
    })();


    /**
     * Loads the availability start date on mount.
     */
    useEffect(() => {
        handleEmployeeLoad();
    }, [employee]);


    /**
     * Load any ancillary data on mount.
     */
    useEffect(() => {
        if (!isReady) {
            return;
        }

        getRequiredWeekTotal();
        getForWeek();
    }, [isReady, startDate]);


    /**
     * Loads the employee details.
     *
     * @returns {Promise<void>}
     */
    const handleEmployeeLoad = async () => {
        if (!employee || !employee.id) {
            return;
        }

        const {
            availabilityStartDate
        } = employee;

        if (availabilityStartDate) {
            setStartDate(dayjs(availabilityStartDate).subtract(1, 'weeks'));
            setAssignedClients([]);
        } else {
            await getAssignedClients();
        }

        setReady(true);
    };


    /**
     * Scroll to the top whenever the error message changes.
     */
    useEffect(() => {
        if (error) {
            handleScrollToTop();
        }
    }, [error]);


    /**
     * Reload the availability entry for the current week on week change.
     */
    useEffect(() => {
        if (weekNumber > maxWeeks && isScope('Employee') && !isContinuing) {
            handleEmployeeUpdate();
        } else {
            getForWeek();
        }

        handleScrollToTop();
    }, [isContinuing, weekNumber]);


    /**
     * Captures the related facilities for this employee.
     *
     * @returns {Promise<void>}
     */
    const getAssignedClients = async () => {
        if (!employee || !employee.id) {
            return;
        }

        const clients = await API.get('clients', {
            $top: 1,
            $filter: `employeeClients/any{employeeId in {${employee.id}}}`,
            $orderby: 'scheduleToDate desc'
        });

        // Verify that we have availability to cover each of the schedules that we have pending.
        if (clients && clients.length && !!clients[0].scheduleFromDate) {
            setStartDate(dayjs(clients[0].scheduleFromDate).subtract(1, 'weeks'));
        }

        setAssignedClients(clients);
    };


    /**
     * Loads all availability items for the week.
     *0
     * @returns {Promise<void>}
     */
    const getForWeek = async () => {
        if (!weekNumber || !isReady) {
            return;
        }

        setLoading(true);
        Logger.debug(`[AvailabilityForm] Loading availability for week ${weekNumber}, ${startDateOfWeek.format('MM/DD/YYYY')} - ${endDateOfWeek.format('MM/DD/YYYY')}...`);

        await Promise.all([
            getItems(),
            getAvailability()
        ]);

        setLoading(false);
    };


    /**
     * Scrolls the user to the top of the page.
     */
    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };


    /**
     * Attempts to find the latest required week via the API.
     *
     * @returns {Promise<void>}
     */
    const getRequiredWeekTotal = async () => {
        let scheduleToDate = employee.availabilityEndDate;

        // Fallback to the clients' schedules.
        if (
            !scheduleToDate &&
            assignedClients &&
            assignedClients.length &&
            assignedClients.some(client => !!client.scheduleToDate)
        ) {
            const filteredClients = assignedClients.filter(client => !!client.scheduleToDate);

            if(filteredClients.length){
                scheduleToDate = filteredClients[0].scheduleToDate;
            }
        }

        if (scheduleToDate) {
            const latestDate = dayjs(scheduleToDate);
            const hours = Math.abs(latestDate.diff(startDateOfWeek, 'hours'));
            const days = Math.floor(hours / 24);
            const weeks = Math.floor(days / 7);

            if (latestDate.isAfter(startDateOfWeek) && weeks >= 2) {
                const maxWeeks = weeks + weekOffset;
                Logger.debug(`[AvailabilityForm] Setting max weeks to ${maxWeeks}...`);
                setMaxWeeks(maxWeeks);
            }
        }
    };


    /**
     * Updates the employee record on completion.
     *
     * @returns {Promise<void>}
     */
    const handleEmployeeUpdate = async () => {
        setComplete(true);

        // Update the availability date.
        const response = await API.put(`employees/${employee.id}`, {
            availabilityDate: dayjs().utc().format('YYYY-MM-DD HH:mm:ss'),
            availabilityEndDate: '',
            availabilityStartDate: '',
        });

        // Trigger the internal notification.
        await API.post(`notifications`, {
            note: `${user.displayName} availability has been submitted.`,
            modelId: employee.id,
            modelType: 'Employee',
            containerClass: 'green'
        });

        // Share the date update across the application.
        onAuthUpdate({
            availabilityDate: response.availabilityDate
        });
    };


    /**
     * Pulls the items for the week via the API.
     *
     * @returns {Promise<void>}
     */
    const getItems = async () => {
        const results = await API.get('events', {
            $top: 9999,
            $filter: `isBlockRequested eq {1} and employeeId in {${employee.id}} and startDate ge {${startDateOfWeek.format('YYYY-MM-DD')}} and endDate le {${endDateOfWeek.add(1, 'days').format('YYYY-MM-DD')}}`
        });

        setItems(results);
    };


    /**
     * Loads the availability record for the week and employee.
     *
     * @returns {Promise<void>}
     */
    const getAvailability = async () => {
        const result = await API.get('availability', {
            $top: 1,
            $filter: `employeeId in {${employee.id}} and startDate eq {${startDateOfWeek.format('YYYY-MM-DD')}}`
        });

        const record = result.length ? result[0] : {};

        setAvailability(record);
        setHours(record.id ? record.hours : 0);
        setComment(record.id ? record.comments : '');
    };


    /**
     * Saves the availability details for the current week / employee.
     *
     * @returns {Promise<void>}
     */
    const doSave = async () => {
        if (!weekNumber) {
            return;
        }

        const payload = {
            hours,
            comments: comment,
            startDate: startDateOfWeek.format('YYYY-MM-DD'),
            employeeId: employee.id
        };

        const result = !availability.id ?
            await API.post('availability', payload) :
            await API.put(`availability/${availability.id}`, payload);

        setAvailability(result);
    };


    /**
     * Determines the logic for disabling entry, etc.
     *
     * @type {*[]}
     */
    const shiftFunctions = [
        {
            hours: 8,
            label: 'Morning (8)',
            disabled: (date, shift) => {
                return isShiftChecked(date, {
                    label: 'Not Available'
                });
            }
        }, {
            hours: 8,
            label: 'Afternoon (8)',
            disabled: (date, shift) => {
                return isShiftChecked(date, {
                    label: 'Not Available'
                });
            }
        }, {
            hours: 8,
            label: 'Night (8)',
            disabled: (date, shift) => {
                return isShiftChecked(date, {
                    label: 'Not Available'
                });
            }
        }, {
            hours: 16,
            label: 'Double (16)',
            disabled: (date, shift) => {
                const results = [];

                if (isShiftChecked(date, {
                    label: 'Not Available'
                })) {
                    return true;
                }

                ['Morning (8)', 'Afternoon (8)', 'Night (8)'].map(label => {
                    results.push(isShiftChecked(date, {
                        label
                    }));
                });

                if (results.includes(true)) {
                    return false;
                }

                return true;
            }
        }, {
            hours: 0,
            label: 'Not Available'
        }
    ];


    /**
     * Validates the current week.
     *
     * @returns {Promise<void>}
     */
    const doValidate = async () => {
        if (!weekNumber) {
            return '';
        }

        // Verify that we have a selection for each day.
        const hasSelectionForEachDay = !weekDays.filter(day => !shiftFunctions.filter(shift => isShiftChecked(day, shift)).length).length;

        if (!hasSelectionForEachDay) {
            return 'Please make a selection for each day.'
        }

        // Verify that our hours selected are covered.
        const hoursRequested = parseInt(hours || '0');
        const hoursSelected = weekDays.map(day => {
            const selection = shiftFunctions.filter(shift => isShiftChecked(day, shift));
            const isNotAvailable = !!selection.filter(shift => shift.label === 'Not Available').length;

            // Verify that "Not Available" isn't checked.
            if (isNotAvailable || !selection.length) {
                return 0;
            }

            return Math.max(
                ...selection.map(shift => shift.hours)
            );
        }).reduce((iterator, hours) => iterator + hours, 0);

        if (hoursRequested && hoursSelected < hoursRequested) {
            return `You requested ${hours} hours, but only made yourself available for ${hoursSelected}. Please change your requested hours, or select enough shifts to cover the time.`;
        }

        if (!hoursRequested && hoursSelected > 0) {
            return `You made yourself available for ${hoursSelected} hours, but requested 0 for this week. Please update your requested hours, or choose "Not Available" for each day.`;
        }

        return '';
    };


    /**
     * Handles the week next button.
     */
    const handleWeekNumberNext = async () => {
        const error = await doValidate();

        if (!error) {
            setError('');
            await doSave();

            if (isAddingAdditional) {
                setConfirming(true);
            } else {
                setWeekNumber(weekNumber + 1);
            }
        } else {
            setError(error);
        }
    };


    /**
     * Handles the week previous button.
     */
    const handleWeekNumberPrevious = async () => {
        await doSave();
        setWeekNumber(weekNumber - 1);
    };


    /**
     * Determines each date object for the week.
     *
     * @type {Array}
     */
    const weekDays = (() => {
        const dates = [startDateOfWeek];

        for (let i = 1; i < 7; i++) {
            dates.push(startDateOfWeek.add(i, 'days'));
        }

        return dates;
    })();


    /**
     * Calculates the requested hour dropdown.
     *
     * @type {Array}
     */
    const requestedHourOptions = (() => {
        let options = [];
        let iterator = 0;

        while (iterator < 100) {
            options.push(iterator);
            iterator += 8;
        }

        return options;
    })();


    /**
     * Returns all the availability items on a particular shift / day.
     *
     * @param date
     * @param shift
     * @returns {*[]}
     */
    const getEventsForShift = (date, shift) => {
        return items.filter(event =>
            dayjs(event.startDate).format('YYYY-MM-DD') === date.format('YYYY-MM-DD') &&
            event.title === shift.label
        );
    };


    /**
     * Indicates whether a shift is checked for a particular day.
     *
     * @param date
     * @param shift
     * @returns {boolean}
     */
    const isShiftChecked = (date, shift) => {
        return !!getEventsForShift(date, shift).length;
    };


    /**
     * Processes the calendar event update on shift selection.
     *
     * @param date
     * @param shift
     * @param value
     * @returns {Promise<void>}
     */
    const handleShiftSelection = async (date, shift, value) => {
        const events = getEventsForShift(date, shift);
        const promises = [];
        const normalShifts = ['Morning (8)', 'Afternoon (8)', 'Night (8)'];

        if (!value) {
            // Eliminate any selections that no longer make sense.
            if (normalShifts.includes(shift.label)) {
                const doubleShifts = shiftFunctions
                    .filter(relative => relative.label === 'Double (16)');

                const otherNormalShifts = shiftFunctions
                    .filter(relative => normalShifts.includes(relative.label) && relative.label !== shift.label);

                const hasOtherSelectedDayShifts = !!otherNormalShifts
                    .map(shift => isShiftChecked(date, shift))
                    .filter(checked => !!checked)
                    .length;

                const isDouble = isShiftChecked(date, doubleShifts[0]);

                if (isDouble && !hasOtherSelectedDayShifts) {
                    await Promise.all(
                        doubleShifts
                            .map(shift => handleShiftSelection(date, shift, false))
                    )
                }
            }

            events.map(event => promises.push(API.delete(`events/${event.id}`)));
        } else {

            // Clear out any selections that no longer make sense.
            if (shift.label === 'Not Available') {
                await Promise.all(
                    shiftFunctions
                        .filter(shift => shift.label !== 'Not Available')
                        .map(shift => handleShiftSelection(date, shift, false))
                )
            }

            promises.push(
                API.post('events', {
                    title: shift.label,
                    endDate: date.endOf('day').format('YYYY-MM-DD HH:mm:ss'),
                    startDate: date.startOf('day').format('YYYY-MM-DD HH:mm:ss'),
                    employeeId: employee.id,
                    isBlockRequested: true,
                })
            );
        }

        await Promise.all(promises);
        getItems();
    };


    /**
     * Allows the employee to continue providing availability passed the default max week.
     *
     * @returns {Promise<void>}
     */
    const handleEmployeeContinue = async () => {
        setComplete(false);
        setContinuing(true);
    };


    /**
     * The start of the availability date range.
     *
     * @type {dayjs.Dayjs}
     */
    const rangeStart = startDate.startOf('weeks').add(1, 'weeks');


    /**
     * The end of the availability date range.
     *
     * @type {dayjs.Dayjs}
     */
    const rangeEnd = startDate.startOf('weeks').add(maxWeeks + 1, 'weeks').subtract(1, 'days');


    /**
     * Other attributes to influence the text output.
     *
     * @type {boolean}
     */
    const isManuallySet = !!employee.availabilityStartDate;
    const hasFacilities = !!assignedClients.length;

    return (
        <Box>
            {!weekNumber ? (
                <Box className={'columns__1 p__3'}>
                    <Alert severity={'info'} icon={<CalendarMonthIcon fontSize={'inherit'}/>}>
                        This user's availability period is {isManuallySet ? <b>manually</b> : <b>automatically</b>} set
                        to
                        cover {rangeStart.format('MM/DD/YYYY')} - {rangeEnd.format('MM/DD/YYYY')} ({maxWeeks} weeks){!isManuallySet && hasFacilities ? ' based on their contract' : ''}.
                    </Alert>

                    <Box>
                        <h3 className={'mt__0'}>Availability Form</h3>

                        <Box className={'d-flex__start'}>
                            <Box className={'mr__2'}>
                                <DatePicker
                                    value={startDate}
                                    label={'Start Date'}
                                    onChange={event => setStartDate(event ? event : '')}
                                />
                            </Box>

                            <Button
                                variant={'outlined'}
                                onClick={handleWeekNumberNext}
                                children={'Start'}
                            />
                        </Box>
                    </Box>
                </Box>
            ) : (
                isComplete ? (
                    <Box>
                        <LogoHeading title={'Thank You!'}/>
                        <Box className={'columns__1'}>
                            {isHired() ? (
                                <Box>
                                    Your availability has been completed successfully. You can use the button below
                                    to return to your dashboard, or if you'd like, you can <a
                                    onClick={() => handleEmployeeContinue()}>provide
                                    additional weeks.</a>
                                </Box>
                            ) : (
                                <Box>
                                    <Box className={'mb__3'}>
                                        Your availability has been completed successfully. Select your next step from
                                        the
                                        list below, or if you'd like, you can <a
                                        onClick={() => handleEmployeeContinue()}>provide
                                        additional weeks.</a>
                                    </Box>

                                    <RecruitingSteps/>
                                </Box>
                            )}


                            <Button
                                variant={'outlined'}
                                onClick={() => navigate('/')}
                                children={'Home'}
                            />
                        </Box>
                    </Box>
                ) : (
                    <Box className={'columns__1'}>
                        <Box className={'p__3 columns__1'} sx={{paddingBottom: 0}}>
                            {!isLandscape && isScope('Employee') && (
                                <Alert
                                    data-aos={'fade-right'}

                                    icon={<ScreenRotationIcon fontSize="inherit"/>}
                                    severity={'warning'}
                                    children={'Please turn your phone to the side to avoid any scheduling issues.'}
                                />
                            )}

                            {!!error && (
                                <Alert severity={'error'}>
                                    {error}
                                </Alert>
                            )}

                            <Box className={'d-flex__justify'}>
                                <h3 className={'mt__0 mb__0'}>
                                    Week #{weekNumber} {startDateOfWeek.format('M/D')} - {endDateOfWeek.format('M/D')}
                                </h3>
                            </Box>
                            <FormControl>
                                <InputLabel required>
                                    Number of hours requested for this week?
                                </InputLabel>
                                <InputSelect
                                    label={'Number of hours requested for this week?'}
                                    value={hours}
                                    options={requestedHourOptions.map(option => {
                                        return {
                                            label: option,
                                            value: option
                                        };
                                    })}
                                    required
                                    disabled={!isReady || isLoading}
                                    onChange={event => setHours(event.target.value)}
                                    fullWidth
                                />
                            </FormControl>
                        </Box>
                        <TableContainer className={'table table--striped table--responsive'}>
                            <Table size={'small'} padding={'checkbox'}>
                                <TableBody>
                                    <TableRow>
                                        <TableCell/>
                                        {shiftFunctions.map((shift, i) => (
                                            <TableCell
                                                key={i}
                                                align={'center'}
                                                children={
                                                    <Box className={'availability__heading'}>
                                                        <Box
                                                            sx={{
                                                                paddingTop: '0.3em',
                                                                paddingBottom: '0.3em'
                                                            }}
                                                            children={shift.label}
                                                        />
                                                    </Box>
                                                }
                                                className={'text__small'}
                                            />
                                        ))}
                                    </TableRow>

                                    {weekDays.map((date, i) => {
                                        return (
                                            <TableRow key={i}>
                                                <TableCell key={-1} className={'text__small'}>
                                                    <Box>
                                                        <Box sx={{paddingTop: '0.3em', paddingBottom: '0.3em'}}>
                                                            <Box>
                                                                {date.toDate().toLocaleString('en-us', {weekday: 'long'})}
                                                            </Box>
                                                            <Box className={'text__light'}>
                                                                {date.format('M/D')}
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </TableCell>

                                                {shiftFunctions.map((shift, j) => (
                                                    <TableCell key={j} align={'center'}>
                                                        <Checkbox
                                                            checked={isShiftChecked(date, shift)}
                                                            disabled={!isReady || isLoading || (shift.disabled ? shift.disabled(date, shift) : false)}
                                                            onChange={event => handleShiftSelection(date, shift, event.target.checked)}
                                                        />
                                                    </TableCell>
                                                ))}
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <Box
                            sx={{paddingTop: 0}}
                            align={'center'}
                            className={'p__3 columns__1'}
                        >
                            <TextField
                                rows={4}
                                value={comment}
                                label="Enter any additional notes..."
                                disabled={!isReady || isLoading}
                                onChange={event => setComment(event.target.value)}
                                multiline
                                fullWidth
                            />
                            <Box className={'columns__2 columns--small'}>
                                <Button
                                    color={'error'}
                                    variant={'outlined'}
                                    onClick={handleWeekNumberPrevious}
                                    children={'Back'}
                                    disabled={weekNumber < 2}
                                    className={'mr__2'}
                                />
                                <Button
                                    variant={'outlined'}
                                    onClick={handleWeekNumberNext}
                                    children={isContinuing ? 'Submit' : 'Next'}
                                />
                            </Box>
                        </Box>
                    </Box>
                )
            )}

            {isConfirming && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    maxWidth={'xs'}
                    fullWidth
                >
                    <DialogHeading
                        title={`Continue?`}
                        noMargin
                    />
                    <DialogContent>
                        <Box children={'Would you like to add another week?'}/>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={() => {
                                setConfirming(false);
                                setWeekNumber(weekNumber + 1);
                            }}
                            children={'Yes'}
                        />
                        <Button
                            color={'error'}
                            onClick={() => navigate('/')}
                            children={'No'}
                        />
                    </DialogActions>
                </Dialog>
            )}
        </Box>
    );
};

export default AvailabilityForm;