import React, {useState} from "react";

import API from "../../Global/API";
import UserTask from "../../Models/UserTask";
import {useAuth} from "../../Global/Auth";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {DateTimePicker} from "@mui/x-date-pickers";

/**
 * TaskForm component.
 *
 * @returns {*}
 * @constructor
 */
const TaskForm = (props) => {
    const {
        open,               // {Boolean} Indicates whether or not the form is open.
        record,             // {Object} The form context object, handles pre-loading the form.
        onSave,             // {Function} An onSave callback, triggered whenever the form is saved.,
        onClose,            // {Function} An onClose callback, triggered whenever the modal is closed.
    } = props;

    const {user} = useAuth();
    const [fields, setFields] = useState(UserTask.getInstance({
        dueDate: dayjs().add(1, 'day').format('YYYY-MM-DD HH:mm:ss'),
        ...record
    }));
    const [isLoading, setLoading] = useState(false);

    /**
     * Performs the save via the API.
     */
    const doSave = async () => {
        setLoading(true);

        const payload = {
            ...fields,
            userId: user.id
        };

        const response = fields.id ?
            await API.put(`task/${fields.id}`, payload) :
            await API.post('tasks', payload);

        setLoading(false);

        if (onSave) {
            onSave(response);
        }
    };


    /**
     * Updates a particular form value.
     *
     * @param key
     * @param value
     */
    const setValue = (key, value) => {
        setFields({
            ...fields,
            [key]: value
        });
    };

    return (
        <Dialog
            open={open}
            scroll={'body'}
            onClose={onClose}
            maxWidth={'sm'}
            fullWidth
        >

            <DialogContent>
                <Box className={'columns__1'}>
                    <InputLabel
                        children={'Task Details'}
                        className={'form__heading form__heading--standalone'}
                    />
                    <TextField
                        label={'What do you need to do?'}
                        value={fields.title}
                        disabled={isLoading}
                        onChange={event => setValue('title', event.target.value)}
                    />
                    <DateTimePicker
                        disabled={isLoading}
                        value={fields.dueDate ? dayjs(fields.dueDate) : null}
                        onChange={(event) => setValue('dueDate', event ? event.format('YYYY-MM-DD HH:mm:ss') : '')}
                        label={'When is the due date?'}
                    />
                    <TextField
                        rows={4}
                        label={'Feel free to share as much detail as you\'d like.'}
                        value={fields.description}
                        disabled={isLoading}
                        onChange={event => setValue('description', event.target.value)}
                        fullWidth
                        multiline
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={doSave}
                    children={'Save'}
                />
                <Button
                    color={'error'}
                    onClick={onClose}
                    children={'Close'}
                />
            </DialogActions>
        </Dialog>
    );
};

export default TaskForm;