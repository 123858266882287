import React, {useEffect, useMemo, useState} from "react";

import API from "../../Global/API";
import Page from "../../Components/Page";
import TabPanel from "../../Components/TabPanel";
import DataTable from "../../Components/Layouts/Index/DataTable";
import HelixQueue from "../../Models/HelixQueue";
import TabHeading from "../../Components/Layouts/Profile/TabHeading";
import SectionHeading from "../../Components/Typography/SectionHeading";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Snackbar from "@mui/material/Snackbar";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';

/**
 * SystemSettings component.
 *
 * @returns {*}
 * @constructor
 */
const SystemSettings = (props) => {
    /**
     * Various state variables.
     */
    const [tab, setTab] = useState(0);
    const [isLoaded, setLoaded] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [settings, setSettings] = useState({});
    const [isInitialized, setInitialized] = useState(false);
    const [recruitingReminders, setRecruitingReminders] = useState({});
    const [isAutoRoutingEnabled, setAutoRoutingEnabled] = useState(false);

    /**
     * Loads all ancillary data on mount.
     */
    useEffect(() => {
        getSettings();
    }, []);


    /**
     * Synchronize setting values to the various state parameters.
     */
    useEffect(() => {
        if (!isLoaded || isInitialized) {
            return;
        }

        setAutoRoutingEnabled(!!setting('SS_AUTO_ROUTING_ENABLED', true));
        setRecruitingReminders(JSON.parse(setting('SS_RECRUITING_REMINDERS', '{}')));

        setTimeout(() => {
            setInitialized(true);
        }, 500);
    }, [isLoaded]);


    /**
     * Synchronize the enabled reminders.
     */
    useEffect(() => {
        sync('SS_RECRUITING_REMINDERS', JSON.stringify(recruitingReminders));
    }, [recruitingReminders]);


    /**
     * Synchronize the auto-routing enabled flag.
     */
    useEffect(() => {
        sync('SS_AUTO_ROUTING_ENABLED', isAutoRoutingEnabled);
    }, [isAutoRoutingEnabled]);


    /**
     * Loads all setting values.
     *
     * @returns {Promise<void>}
     */
    const getSettings = async () => {
        setLoading(true);

        const response = await API.get('settings', {
            $top: 1000,
            $filter: `modelType eq {Office} and modelId in {1} and key eq {"SS_}`,
            $orderby: 'id desc'
        });

        const formatted = {};

        response.map(setting => {
            if (!formatted.hasOwnProperty(setting.key)) {
                formatted[setting.key] = setting
            }
        });

        setLoaded(true);
        setLoading(false);
        setSettings({
            ...formatted
        });
    };


    /**
     * Returns a setting value.
     *
     * @param key
     * @param fallback
     * @returns {*}
     */
    const setting = (key, fallback) => {
        return settings.hasOwnProperty(key) ?
            settings[key].value : fallback;
    };


    /**
     * Synchronizes a setting key.
     *
     * @param key
     * @param value
     * @returns {Promise<void>}
     */
    const sync = async (key, value) => {
        if (!isLoaded || !isInitialized) {
            return;
        }

        let existing = {};

        if (settings.hasOwnProperty(key)) {
            existing = settings[key];
        }

        // Bind the setting storage.
        const response = existing && existing.id ?
            await API.put(`settings/${existing.id}`, {
                value
            }) : await API.post(`settings`, {
                key,
                value,
                modelId: 1,
                modelType: 'Office'
            });

        // Update the settings to include the new value.
        setSettings({
            ...settings,
            [key]: response
        });
    };


    /**
     * Handles the user tab selection.
     *
     * @param event
     * @param value
     */
    const handleTabChange = (event, value) => {
        setTab(value);
    };


    /**
     * Assigns a particular extension mapping value.
     *
     * @param key
     * @param value
     */
    const setRecruitingReminderValue = (key, value) => {
        setRecruitingReminders({...recruitingReminders, [key]: value});
    }


    /**
     * The "True Helix" content on the form.
     *
     * @type {Component}
     */
    const helixQueue = useMemo(() => (
        <Box
            sx={{height: 'calc(100vh - 400px)'}}
            className={'v-align__top'}
        >
            <DataTable
                model={HelixQueue}
                query={HelixQueue.getQuery()}
                actions={false}
                topAlign
                doReload={null}
            />
        </Box>
    ), []);

    return (
        <Page
            icon={<SettingsSuggestIcon/>}
            heading={'System Settings'}
            children={
                <Paper sx={{
                    position: 'relative',
                }}>
                    <TabHeading tab={tab}
                        onChange={handleTabChange}
                    >
                        <Tab label={'General'}/>
                        <Tab label={'True Helix'}/>
                    </TabHeading>
                    <TabPanel value={tab}
                        index={0}
                        fullWidth
                    >
                        <Box className={'columns__1 p__3'}>
                            <Box className={'columns__1 columns--small'}>
                                <SectionHeading
                                    title={'Communications'}
                                />

                                <FormGroup>
                                    <FormControlLabel control={
                                        <Checkbox
                                            checked={isAutoRoutingEnabled}
                                            disabled={isLoading}
                                            onChange={event => setAutoRoutingEnabled(event.target.checked)}
                                        />
                                    } label="Enable Automatic Call Routing" />

                                    <FormHelperText className={'text__small'}>If enabled, will automatically switch daylight / evening attendants to align with business hours.</FormHelperText>
                                </FormGroup>
                            </Box>

                            <SectionHeading
                                title={'Recruiting'}
                            />
                            <FormGroup>
                                <FormLabel>Enable / Disable Reminders</FormLabel>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={!!recruitingReminders.invitation}
                                        disabled={isLoading}
                                        onChange={event => setRecruitingReminderValue('invitation', event.target.checked)}
                                    />
                                } label="Invitation" />
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={!!recruitingReminders.disclosure}
                                        disabled={isLoading}
                                        onChange={event => setRecruitingReminderValue('disclosure', event.target.checked)}
                                    />
                                } label="Disclosures" />
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={!!recruitingReminders.availability}
                                        disabled={isLoading}
                                        onChange={event => setRecruitingReminderValue('availability', event.target.checked)}
                                    />
                                } label="Availability" />
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={!!recruitingReminders.hirePacket}
                                        disabled={isLoading}
                                        onChange={event => setRecruitingReminderValue('hirePacket', event.target.checked)}
                                    />
                                } label="Hire Packet" />
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={!!recruitingReminders.training}
                                        disabled={isLoading}
                                        onChange={event => setRecruitingReminderValue('training', event.target.checked)}
                                    />
                                } label="Training" />
                                <FormHelperText className={'text__small'}>Each of these notification processes run every 12 hours (7am and 7pm).</FormHelperText>
                            </FormGroup>
                        </Box>
                    </TabPanel>

                    <TabPanel
                        index={1}
                        value={tab}
                        fullWidth
                    >
                        <Box className={'columns__1'}>
                            {helixQueue}
                        </Box>
                    </TabPanel>

                    {isSuccess && (
                        <Snackbar open={isSuccess}
                            autoHideDuration={6000}
                            onClose={() => setSuccess(false)}>
                            <Alert
                                sx={{width: '100%', textAlign: 'left'}}
                                onClose={() => setSuccess(false)}
                                severity={'success'}
                            >
                                Your changes have been saved successfully!
                            </Alert>
                        </Snackbar>
                    )}
                </Paper>
            }
        />
    );
};

export default SystemSettings;