import React, {useRef, useState} from "react";

import API from "../../../Global/API";
import User from "../../../Models/User";
import Status from "../../../Models/Status";
import Formatter from "../../../Global/Formatter";
import ModelSearch from "../../../Components/Input/ModelSearch";
import ModalButton from "../../../Components/ModalButton";
import EmployeeView from "../Employee/EmployeeView";
import SectionHeading from "../../../Components/Typography/SectionHeading";
import EmployeeListItem from "../../../Components/Lists/EmployeeListItem";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import {DatePicker} from "@mui/x-date-pickers";
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";
import FormControlLabel from "@mui/material/FormControlLabel";

/**
 * The number of results or page to sequentially pull data.
 *
 * @type {number}
 */
const perPageLimit = 250;


/**
 * EmployeesByType component.
 *
 * @returns {*}
 * @constructor
 */
const EmployeesByType = (props) => {
    const {
        loading,            // {Boolean} Indicates if the report is loading.
        onComplete          // {Function} A post-completion callback from whenever the report has results.
    } = props;

    const resultsRef = useRef(null);
    const [index, setIndex] = useState(0);
    const [users, setUsers] = useState([]);
    const [endDate, setEndDate] = useState(dayjs().endOf('month').format('YYYY-MM-DD'));
    const [statuses, setStatuses] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(dayjs().startOf('month').format('YYYY-MM-DD'))
    const [typeFilter, setTypeFilter] = useState('all');
    const [recruiters, setRecruiters] = useState([]);
    const [showResults, setShowResults] = useState(false);
    const [employeeResults, setEmployeeResults] = useState([]);

    /**
     * Loads all of our employee rows.
     *
     * @returns {Promise<void>}
     */
    const getAllEmployees = async () => {
        let promises = [];

        // Determine the filter criteria.
        let employeeFilters = [];
        employeeFilters.push(endDate ? `activatedDate le {${endDate}}` : ``);
        employeeFilters.push(startDate ? `activatedDate ge {${startDate}}` : ``);
        employeeFilters.push(typeFilter === 'perDiem' ? 'isPerDiem eq {1}' : '');
        employeeFilters.push(typeFilter === 'contract' ? 'isContract eq {1}' : '');
        employeeFilters.push(users && users.length ? `activatedById in {${users.map(user => user.id).join(',')}}` : '');
        employeeFilters.push(statuses && statuses.length ? `statusId in {${statuses.map(status => status.id).join(',')}}` : '');
        employeeFilters = employeeFilters.filter(filter => !!filter.trim());

        // Load all the employees page by page until we've reached the end.
        const count = await API.get('employees/count', {
            $top: perPageLimit,
            $filter: `${employeeFilters.join(' and ')}`,
        });

        for (let page = 0; page < count.pages; page++) {
            promises.push(
                API.get('employees', {
                    $top: perPageLimit,
                    $skip: page * perPageLimit,
                    $filter: `${employeeFilters.join(' and ')}`,
                    $expand: 'specialty,state,status',
                    $orderby: `lastName asc, firstName asc`,
                })
            );
        }

        const results = await Promise.all(promises);
        setEmployeeResults(results.flat());
    };


    /**
     * Loads all of our recruiter records.
     *
     * @returns {Promise<void>}
     */
    const getRecruiters = async () => {
        setRecruiters(
            await API.get('users')
        );
    };


    /**
     * Loads all of our report data.
     *
     * @returns {Promise<void>}
     */
    const getResults = async () => {
        setLoading(true);

        await Promise.all([
            getRecruiters(),
            getAllEmployees(),
        ]);

        setShowResults(true);
        setLoading(false);
        setIndex(index + 1);

        if (onComplete) {
            onComplete(resultsRef);
        }
    };


    /**
     * Indicates if the report is in progress.
     *
     * @type {Boolean}
     */
    const inProgress = isLoading || loading;


    /**
     * The total number of entries.
     *
     * @type {*}
     */
    const totalCount = employeeResults.length;


    /**
     * Retrieves a recruiter by their ID.
     *
     * @param id
     * @returns {Object}
     */
    const getRecruiterById = id => {
        return recruiters.find(recruiter => recruiter.id === id) || {};
    };

    return (
        <Box className={'d-flex'}>
            <Box
                sx={{width: 400, overflowY: 'hidden'}}
                className={'border__right p__3 full__height--left-tabs'}
            >
                <Box className={'columns__1'}>
                    <Box className={'columns__2'}>
                        <DatePicker
                            label={'Start Date'}
                            value={startDate ? dayjs(startDate) : null}
                            disabled={inProgress}
                            onChange={event => setStartDate(event ? event.format('YYYY-MM-DD') : '')}
                            fullWidth
                        />
                        <DatePicker
                            label={'End Date'}
                            value={endDate ? dayjs(endDate) : null}
                            disabled={inProgress}
                            onChange={event => setEndDate(event ? event.format('YYYY-MM-DD') : '')}
                            fullWidth
                        />
                    </Box>
                    <ModelSearch
                        model={Status}
                        value={statuses}
                        label={'Statuses'}
                        multiple
                        disabled={inProgress}
                        onChange={setStatuses}
                        renderLabel={option => !option ? '' : option.name}
                    />
                    <ModelSearch
                        model={User}
                        value={users}
                        label={'Recruiter(s)'}
                        multiple
                        disabled={inProgress}
                        onChange={setUsers}
                        helperText={'(Leave blank to include all.)'}
                        renderLabel={option => !option ? '' : option.displayName}
                    />
                    <FormControl>
                        <FormLabel>Which types should be included?</FormLabel>
                        <RadioGroup
                            value={typeFilter}
                            onChange={event => setTypeFilter(event.target.value)}
                        >
                            <FormControlLabel
                                value={'perDiem'}
                                label={'Per Diem'}
                                control={
                                    <Radio
                                        disabled={inProgress}
                                    />
                                }
                            />
                            <FormControlLabel
                                value={'contract'}
                                label={'Contract'}
                                control={
                                    <Radio
                                        disabled={inProgress}
                                    />
                                }
                            />
                            <FormControlLabel
                                value={'all'}
                                label={'Show All'}
                                control={
                                    <Radio
                                        disabled={inProgress}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl>
                    <Button
                        variant={'outlined'}
                        onClick={getResults}
                        children={'Apply'}
                        disabled={inProgress}
                    />
                </Box>
            </Box>

            <Box
                sx={{borderRadius: 0}}
                className={`flex__grow full__height--left-tabs ${!showResults ? 'well__container' : ''}`}
            >
                {inProgress && <LinearProgress sx={{width: '100%'}}/>}

                {!showResults && (
                    <Box className={'p__3 text__center text__disclaimer'}>Your results will display here.</Box>
                )}

                {showResults && (
                    <Box className={'p__3 columns__1'}>
                        <Box>
                            <SectionHeading title={`Employees (${totalCount})`} primary/>
                            <TableContainer className={'table table--striped result__set'}>
                                <Table size={'small'}>
                                    <colgroup>
                                        <col width={'25%'}/>
                                        <col width={'25%'}/>
                                        <col width={'25%'}/>
                                        <col width={'25%'}/>
                                    </colgroup>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Employee</TableCell>
                                            <TableCell>Recruiter</TableCell>
                                            <TableCell>Activated Date</TableCell>
                                            <TableCell>Type</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {employeeResults.map(employee => {
                                            const {activatedById} = employee;
                                            const user = getRecruiterById(activatedById);

                                            return (
                                                <TableRow key={employee.id}>
                                                    <TableCell className={'v-align__top'} padding={'none'}>
                                                        <ModalButton
                                                            label={
                                                                <EmployeeListItem
                                                                    onSelect={() => true}
                                                                    employee={employee}
                                                                />
                                                            }
                                                            bodyStyle={{
                                                                paddingTop: 0
                                                            }}
                                                            children={
                                                                <EmployeeView id={employee.id}/>
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {user.displayName || ''}
                                                    </TableCell>
                                                    <TableCell>
                                                        {Formatter.dateTime(employee.activatedDate)}
                                                    </TableCell>
                                                    <TableCell>
                                                        {employee.isPerDiem ? 'Per Diem' : 'Contract'}
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                )}
            </Box>

            {/*The hidden display for PDF rendering.*/}
            <Box className={'d-hidden'}>
                <Box ref={resultsRef} key={`results-${index}`}>
                    <table className={'table--bordered'}>
                        <tbody>
                            <tr>
                                <td style={{width: '25%'}}>
                                    <b>Employee</b>
                                </td>
                                <td style={{width: '25%'}}>
                                    <b>Recruiter</b>
                                </td>
                                <td style={{width: '25%'}}>
                                    <b>Activated Date</b>
                                </td>
                                <td style={{width: '25%'}}>
                                    <b>Type</b>
                                </td>
                            </tr>

                            {employeeResults.map(employee => {
                                const {activatedById} = employee;
                                const user = getRecruiterById(activatedById);

                                return (
                                    <tr key={`${employee.id}-print`}>
                                        <td>
                                            {employee.lastName}, {employee.firstName}
                                        </td>
                                        <td>
                                            {user.displayName || ''}
                                        </td>
                                        <td>
                                            {Formatter.dateTime(employee.activatedDate)}
                                        </td>
                                        <td>
                                            {employee.isPerDiem ? 'Per Diem' : 'Contract'}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Box>
            </Box>
        </Box>
    );
};

export default EmployeesByType;