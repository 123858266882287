import React from "react";

import Course from "../../Models/Course";
import {useAuth} from "../../Global/Auth";
import TableLayout from "../../Components/Layouts/TableLayout";

import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import SchoolIcon from '@mui/icons-material/School';
import {useNavigate} from "react-router";

/**
 * Training component.
 *
 * @returns {*}
 * @constructor
 */
const Training = () => {
    const navigate = useNavigate();
    const {hasPermissionTo} = useAuth();

    return (
        <TableLayout
            icon={<SchoolIcon/>}
            model={Course}
            heading={'Training'}
            condensed
            hideFilters
            filterSlot={
                hasPermissionTo('EDIT_TRAINING') ? (
                    <Button variant={'link'} className={'layout__control'} onClick={() => navigate('/training/editor')}>
                        <AddIcon/>
                    </Button>
                ) : null
            }
            formPermission={'EDIT_TRAINING'}
        />
    )
};

export default Training;