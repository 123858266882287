import React, {useMemo} from "react";

import SectionHeading from "../../../Components/Typography/SectionHeading";

import Box from "@mui/material/Box";
import RecruitingSteps from "./RecruitingSteps";

/**
 * PipelineEmployeeExperienceIncomplete component.
 *
 * @returns {*}
 * @constructor
 */
const PipelineEmployeeExperienceIncomplete = (props) => {
    const {
        employee            // {Object} The employee record object from the API.
    } = props;

    return useMemo(() => (
        <Box className={'columns__1'}>
            <SectionHeading title={'Introduction'}/>
            <Box>
                <b>Congratulations on your new job with Reliant!</b> Now that you
                have completed the credentialing phase with your recruiter, let's
                get the rest of the new hire paperwork situated.
            </Box>
            <Box>
                Whenever you're ready to begin, click on and complete the steps in
                the order as they appear below. Once you have finished all of the
                steps, our office will call you within 24 hours. If you need any
                assistance with completing the forms, please use the chat feature
                below to speak with your recruiter.
            </Box>
            <Box/>

            <Box>
                <SectionHeading title={`What's Next?`}/>

                <RecruitingSteps/>
            </Box>
        </Box>
    ), [employee]);
};

export default PipelineEmployeeExperienceIncomplete;