import React from "react";

import Model from "../Definitions/Model";
import Property from "../Definitions/Property";
import Formatter from "../Global/Formatter";
import BoundSwitch from "../Components/Input/BoundSwitch";
import PropertyMap from "../Definitions/PropertyMap";
import MassMailForm from "../Actions/MassMailActions/MassMailForm";
import MassMailActions from "../Actions/MassMailActions";

import {faker} from '@faker-js/faker';

/**
 * MassMail model.
 *
 * @type {Object}
 */
const MassMail = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'mass-mail',


    /**
     * The appropriate API endpoint root.
     */
    route: 'mass-mail',


    /**
     * The form component.
     *
     * @returns {*}
     * @param parameters
     */
    form: (parameters) => <MassMailForm {...parameters}/>,


    /**
     * The menu action.
     *
     * @param parameters
     * @returns {*}
     */
    action: (parameters) => <MassMailActions {...parameters}/>,


    /**
     * Any additional query parameters.
     */
    query: {
        $expand: 'template',
        $filter: 'isDeleted eq {0}'
    },


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'isEnabled',
        'relatedTemplate',
        'description',
        'scheduledDate',
        'modelType',
        'type',
    ],


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'isEnabled',
            label: 'Enabled',
            render: (record) => (
                <BoundSwitch
                    model={MassMail}
                    field={'isEnabled'}
                    record={record}
                    permission={'EDIT_COMMUNICATIONS'}
                />
            ),
            sortable: true
        }),
        Property.create({
            key: 'relatedTemplate',
            width: '16%',
            label: 'Template',
            render: (record) => record.isInline ? record.name : (record.template && record.template.name ? record.template.name : ''),
            sortable: false
        }),
        Property.create({
            key: 'description',
            width: '25%',
            label: 'Description',
            render: (record) => record.description,
            sortable: true
        }),
        Property.create({
            key: 'scheduledDate',
            width: '16%',
            label: 'Scheduled Date',
            render: (record) => Formatter.dateTime(record.scheduledDate, '', false),
            sortable: true
        }),
        Property.create({
            key: 'modelType',
            width: '16%',
            label: 'Record Type',
            render: (record) => record.modelType,
            sortable: true
        }),
        Property.create({
            key: 'type',
            width: '16%',
            label: 'Message Type',
            render: (record) => record.type,
            sortable: true
        }),
        Property.create({
            key: 'name',
            label: 'Name',
            render: (record) => record.name,
            sortable: true
        })
    ])
};

export default MassMail;