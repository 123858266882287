import React, {useState} from "react";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import HelpIcon from '@mui/icons-material/Help';
import IconButton from "@mui/material/IconButton";

/**
 * SectionHeading component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const SectionHeading = (props) => {
    const {
        help,           // {Component} An optional helper dialog, used to provide additional information.,
        title,          // {String} The text content of the heading.
        action,         // {Component} An optional action component.
        primary         // {Boolean} If enabled will present a primary-colored heading.
    } = props;

    const [helpOpen, setHelpOpen] = useState(false);

    /**
     * Reveals the additional information dialog.
     */
    const handleHelpOpen = () => {
        setHelpOpen(true);
    };


    /**
     * Closes the additional information dialog.
     */
    const handleHelpClose = () => {
        setHelpOpen(false);
    };

    return (
        <Box className={`form__heading ${primary ? 'form__heading--primary' : ''}`}>
            <Box className={'d-flex__justify'}>
                {title}

                {help && (
                    <IconButton onClick={handleHelpOpen} size={'small'}>
                        <HelpIcon/>
                    </IconButton>
                )}

                {action && (
                    <>{action}</>
                )}
            </Box>

            {help && (
                <Dialog
                    open={helpOpen}
                    scroll={'body'}
                    onClose={handleHelpClose}
                    maxWidth={'xl'}
                    fullWidth
                >
                    {help}
                </Dialog>
            )}
        </Box>
    );
};

export default SectionHeading;