import React from "react";

import API from "../../Global/API";
import Formatter from "../../Global/Formatter";
import ImageWrapper from "../../Components/ImageWrapper";
import ViewNoteModal from "./ViewNoteModal";
import VoiceMailModal from "./../CallLog/VoiceMailModal";

import Box from "@mui/material/Box";
import CallMadeIcon from '@mui/icons-material/CallMade';
import CallMissedIcon from '@mui/icons-material/CallMissed';
import CallReceivedIcon from '@mui/icons-material/CallReceived';
import CallMissedOutgoingIcon from '@mui/icons-material/CallMissedOutgoing';

/**
 * RecipientColumn component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const RecipientColumn = (props) => {
    const {
        record,             // {Object} The call log record object.
        condensed,          // {Boolean} Whether or not to utilize the condensed display method.
    } = props;

    const recipient = {
        ...record.recipient
    };

    const iconParams = {
        fontSize: 'small'
    };

    const imageSize = condensed ? 36 : 48;
    const recipientType = recipient['@type'] || '';
    const relatedClient = recipientType === 'Contact' ?
        {...recipient.client} : {};

    return (
        <Box className={'d-flex__start'}>
            <ImageWrapper
                sx={{
                    marginTop: condensed ? '0.5em' : '',
                    marginBottom: condensed ? '0.5em' : ''
                }}
                src={API.getFilePath(recipient.image)}
                width={imageSize}
                height={imageSize}
                className={'mr__2'}
                horizontal={recipientType === 'Client'}
            />

            <Box className={'flex__grow d-flex__justify'}>
                <Box className={'flex__grow'}>
                    <Box className={'d-flex__start'}>
                        <Box className={'mr__2'}>
                            <b>
                                {recipient.name || recipient.displayName || '(no name)'}
                            </b>
                        </Box>
                        <Box>
                            {record.direction === 'Inbound' ? (
                                <>
                                    {record.isFailed ? (
                                        <CallMissedIcon {...iconParams} color={'error'}/>
                                    ) : (
                                        <CallReceivedIcon {...iconParams} color={'info'}/>
                                    )}
                                </>
                            ) : (
                                <>
                                    {record.isFailed ? (
                                        <CallMissedOutgoingIcon {...iconParams} color={'error'}/>
                                    ) : (
                                        <CallMadeIcon {...iconParams} color={'success'}/>
                                    )}
                                </>
                            )}
                        </Box>

                        {!condensed && (
                            <>
                                <VoiceMailModal
                                    record={record}
                                    recipient={recipient}
                                />
                                <ViewNoteModal
                                    record={record}
                                />
                            </>
                        )}
                    </Box>

                    {!condensed ? (
                        <Box className={'text__small text__light'}>
                            {recipient['@type'] || '-'}

                            {!!relatedClient.name && (
                                <span className={'ml__1'}>
                                    ({relatedClient.name})
                                </span>
                            )}
                        </Box>
                    ) : (
                        <Box className={'notification__timestamp'}>
                            {Formatter.dateTime(record.createdDate)}
                        </Box>
                    )}
                </Box>

                {!!condensed && (
                    <>
                        <VoiceMailModal
                            record={record}
                            recipient={recipient}
                        />
                        <ViewNoteModal
                            record={record}
                        />
                    </>
                )}
            </Box>
        </Box>
    );
}

export default RecipientColumn;