import React, {useEffect, useState} from "react";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';

/**
 * Timer component.
 *
 * @returns {*}
 * @constructor
 */
const Timer = (props) => {
    const {
        to,             // {String} The destination date/time string.
        from,           // {String} The origin date/time string.
    } = props;

    const [days, setDays] = useState(0);
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);
    const [initialized, setInitialized] = useState(false);

    /**
     * Indicates whether or not we'd received a "to" date.
     *
     * @type {boolean}
     */
    const hasTargetDate = !!to;


    /**
     * Parses out the time difference.
     */
    const getTime = () => {
        const now = hasTargetDate ? dayjs.utc(to) : dayjs.utc();
        const date = dayjs.utc(from);
        const time = now.diff(date, 'milliseconds');

        setDays(Math.floor(time / (1000 * 60 * 60 * 24)));
        setHours(Math.floor((time / (1000 * 60 * 60))));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
        setInitialized(true);
    };


    /**
     * If we hadn't initialized the timer, go ahead and do so otherwise we'll
     * end up with a 00:00:00 display for the first second alive.
     */
    if(!initialized){
        getTime();
    }


    /**
     * Initialize the timer loop.
     */
    useEffect(() => {
        // Ignore the continuous counter if we have a "to" date.
        if(hasTargetDate){
            return;
        }

        const interval = setInterval(() => getTime(), 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <Box className={'d-flex__center'}>
            <Chip
                size={'small'}
                icon={<TimerOutlinedIcon/>}
                color={hasTargetDate ? 'warning' : 'default'}
                label={`${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`}
            />
        </Box>
    );
};

export default Timer;