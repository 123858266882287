import React, {useEffect, useMemo, useState} from "react";

import API from "../../../Global/API";
import TabPanel from "../../../Components/TabPanel";
import {useAuth} from "../../../Global/Auth";
import DataTable from "../../../Components/Layouts/Index/DataTable";
import TabHeading from "../../../Components/Layouts/Profile/TabHeading";
import NotesBlock from "../../../Components/Layouts/Profile/NotesBlock";
import FileBrowser from "../../../Components/FileBrowser";
import usePhoneCall from "../../../Hooks/usePhoneCall";
import DocumentList from "../../../Components/Layouts/Profile/DocumentList";
import RecruitingTab from "./RecruitingTab";
import EmployeeModel from "../../../Models/Employee";
import EmployeeDetails from "./EmployeeDetails";
import EmployeeMessage from "../../../Models/EmployeeMessage";
import EmployeeActions from "../../../Actions/EmployeeActions";
import QuickSendWidget from "../../../Components/Widgets/QuickSendWidget";
import AvailabilityForm from "./AvailabilityForm";
import EmployeePayDetails from "./EmployeePayDetails";
import EmployeeProfileBadge from "./EmployeeProfileBadge";
import AssignedClientsBlock from "./AssignedClientsBlock";

import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import PinDropIcon from '@mui/icons-material/PinDrop';
import {useNavigate} from "react-router";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

/**
 * EmployeeView component.
 *
 * @returns {*}
 * @constructor
 */
const EmployeeView = (props) => {
    const {
        id,                 // {Number} The ID of the employee to load.
        onClose,            // {Function} An on-close callback if this were to appear in a modal.
        defaultTab,         // {Number} A default tab index, otherwise 0.
    } = props;

    const navigate = useNavigate();
    const [tab, setTab] = useState(defaultTab || 'Details');
    const {hasPermissionTo} = useAuth();
    const [record, setRecord] = useState(EmployeeModel.getInstance());
    const [isLoading, setLoading] = useState(false);

    /**
     * Determine whether or we're in a call to avoid dropping while navigating away.
     */
    const {phoneCall} = usePhoneCall();
    const isCalling = !!phoneCall;


    /**
     * Load any additional details on component mount.
     */
    useEffect(() => {
        getResult();
    }, []);


    /**
     * Indicates if the employee is currently in the recruiting pipeline.
     *
     * @type {boolean}
     */
    const isRecruiting = !record.isActive && !record.isDeleted;


    /**
     * Fired whenever a user clicks into a tab.
     *
     * @param event
     * @param tab
     */
    const handleTabChange = (event, tab) => {
        setTab(tab);
    };


    /**
     * Loads the target record.
     *
     * @returns {Promise<void>}
     */
    const getResult = async () => {
        setLoading(true);
        const result = await API.get(`${EmployeeModel.getRoute()}/${id}`, EmployeeModel.getQuery());

        if (result.isHighlighted) {
            await API.put(`${EmployeeModel.getRoute()}/${id}`, {
                isHighlighted: false
            });
        }

        setLoading(false);
        setRecord(result);
    };


    /**
     * Redirects the user to the schedule page.
     */
    const handleScheduleRedirect = () => {
        if(!isCalling){
            window.location.href = `/schedule/employee/${record.id}`;
            return;
        }

        navigate(`/schedule/employee/${record.id}`);

        if(onClose){
            onClose();
        }
    };


    /**
     * Redirects the user to the map page.
     */
    const handleMapRedirect = () => {
        if(!isCalling){
            window.location.href = `/map/${record.latitude}/${record.longitude}?employeeId=${record.id}`;
            return;
        }

        navigate(`/map/${record.latitude}/${record.longitude}?employeeId=${record.id}`);

        if(onClose){
            onClose();
        }
    };


    /**
     * The message data table.
     *
     * @type {unknown}
     */
    const messageContent = useMemo(() => (
        <DataTable
            model={EmployeeMessage}
            query={EmployeeMessage.getQuery(id)}
            actions={false}
            doReload={null}
            hideHeader
        />
    ), [record])

    return (
        <Box className={'details__view'}>
            <Box className={'d-flex__justify'}>
                <EmployeeProfileBadge record={record}/>

                <Box className={'d-flex__start'}>
                    {hasPermissionTo('VIEW_SCHEDULE') && (
                        <IconButton className={'mr__1'} onClick={handleScheduleRedirect}>
                            <CalendarMonthIcon/>
                        </IconButton>
                    )}

                    <IconButton
                        onClick={handleMapRedirect}
                        className={'mr__2'}
                        disabled={!record.latitude || !record.longitude}
                    >
                        <PinDropIcon/>
                    </IconButton>

                    {hasPermissionTo('VIEW_COMMUNICATIONS') && (
                        <QuickSendWidget record={record}/>
                    )}
                </Box>
            </Box>
            <Box className={'d-flex details__body'}>
                <Box sx={{flexGrow: 1, position: 'relative'}}>
                    <Paper>
                        <TabHeading
                            tab={tab}
                            actions={
                                <EmployeeActions
                                    record={record}
                                    onClose={onClose}
                                    doReload={getResult}
                                />
                            }
                            onChange={handleTabChange}
                        >
                            <Tab label="Details" value={'Details'}/>
                            <Tab label="Documents" value={'Documents'} disabled={!record.id}/>
                            {hasPermissionTo('VIEW_EMPLOYEE_FILES') && (
                                <Tab label="Files" value={'Files'}  disabled={!record.id}/>
                            )}
                            {hasPermissionTo('EDIT_EMPLOYEES') && (
                                <Tab label="Availability" value={'Availability'} disabled={!record.id}/>
                            )}
                            {(hasPermissionTo('VIEW_RECRUITING') || hasPermissionTo('VIEW_RECRUITING_BASIC')) && (
                                <Tab label="Recruiting" value={'Recruiting'} disabled={!record.id}/>
                            )}
                            {hasPermissionTo('VIEW_COMMUNICATIONS') && (
                                <Tab label="Messages" value={'Messages'} disabled={!record.id}/>
                            )}
                        </TabHeading>
                        <TabPanel value={tab} index={'Details'}>
                            <EmployeeDetails record={record}/>
                        </TabPanel>
                        <TabPanel value={tab} index={'Documents'} fullWidth>
                            <DocumentList record={record}/>
                        </TabPanel>
                        <TabPanel value={tab} index={'Files'} fullWidth lazyLoad>
                            <FileBrowser basePath={`employees/${record.id}`}/>
                        </TabPanel>
                        {hasPermissionTo('EDIT_EMPLOYEES') && (
                            <TabPanel fullWidth value={tab} index={'Availability'}>
                                <AvailabilityForm employee={record}/>
                            </TabPanel>
                        )}
                        {(hasPermissionTo('VIEW_RECRUITING') || hasPermissionTo('VIEW_RECRUITING_BASIC')) && (
                            <TabPanel value={tab} index={'Recruiting'}>
                                <RecruitingTab
                                    loading={isLoading}
                                    employee={record}
                                    doReload={getResult}
                                    readOnly={hasPermissionTo('VIEW_RECRUITING_BASIC')}
                                />
                            </TabPanel>
                        )}
                        {hasPermissionTo('VIEW_COMMUNICATIONS') && (
                            <TabPanel value={tab} index={'Messages'} fullWidth>
                                <Box sx={{height: 'calc(100vh - 400px)'}}>
                                    {messageContent}
                                </Box>
                            </TabPanel>
                        )}
                        {hasPermissionTo('VIEW_EMPLOYEE_RATES') && (
                            <TabPanel value={tab} index={'Pay Rates'} fullWidth lazyLoad>
                                <EmployeePayDetails record={record}/>
                            </TabPanel>
                        )}
                    </Paper>
                </Box>
                <Box className={'details__right'}>
                    <Box className={'columns__1'}>
                        <NotesBlock
                            record={record}
                            modelId={id}
                            modelType={'Employee'}
                        />
                        <AssignedClientsBlock
                            record={record}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
};

export default EmployeeView;