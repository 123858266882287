import State from "./State";
import Settings from "./Settings";

/**
 * App object.
 *
 * @type {Object}
 * @description Provides various interfaces for environment-level interactions.
 */
const App = {
    /**
     * Indicates whether the app is running in Cordova.
     *
     * @returns {boolean}
     */
    isCordova() {
        return !!window.isCordova;
    },


    /**
     * Returns the value if we are running in Cordova.
     *
     * @param value
     * @param fallback
     * @returns {string}
     */
    ifMobile(value = '', fallback = '') {
        return App.isCordova() ? value : fallback;
    },


    /**
     * Returns the value if we are running in Cordova.
     *
     * @param value
     * @param fallback
     * @returns {string}
     */
    ifDesktop(value = '', fallback = '') {
        return !App.isCordova() ? value : fallback;
    },


    /**
     * Used to identify whether we are running under Android.
     *
     * @returns {boolean}
     */
    isAndroid() {
        if (!App.isCordova()) {
            return false;
        }

        return /android/i.test(
            navigator.userAgent || navigator.vendor || window.opera
        );
    },


    /**
     * Returns true if we are running under iOS.
     *
     * @returns {boolean}
     */
    isIOS() {
        if (!App.isCordova()) {
            return false;
        }

        if (/iPad|iPhone|iPod/.test(navigator.userAgent || navigator.vendor || window.opera) && !window.MSStream) {
            return true;
        }
    },


    /**
     * Indicates if we're currently running under an iPad.
     *
     * @returns {boolean}
     */
    isIPad(){
        return (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 0) || navigator.platform === 'iPad';
    },


    /**
     * Indicates whether we are utilizing a production / development environment.
     *
     * @returns {boolean}
     */
    isProduction() {
        return process.env.NODE_ENV === 'production' || App.isCordova();
    },


    /**
     * Indicates if we're operating under a local environment.
     *
     * @returns {boolean}
     */
    isLocal() {
        return !!window.isDevelopment;
    },


    /**
     * Indicates if the specified module is enabled.
     *
     * @param module
     * @returns {boolean}
     *
     * @todo Integrate this within the application 'version' response.
     */
    isModuleEnabled(module) {
        if(App.isInstance('reliant')){
            switch(module){
                case 'trueHelix':
                    return true;
            }
        }

        return false;
    },


    /**
     * Returns the current subdomain.
     *
     * @returns {string}
     */
    getSubdomain() {
        const host = window.location.host || '';
        return host.split('.')[0];
    },


    /**
     * Returns true if we're utilizing the "stg.reliantstaffing.app" domain.
     *
     * @returns {boolean}
     */
    isStaging() {
        if (App.isCordova()) {
            return false;
        }

        return process.env.NODE_ENV === 'production' && App.getSubdomain() === 'stg';
    },


    /**
     * Returns the authenticated user / employee / etc.
     *
     * @returns {*|{}}
     */
    getUser() {
        return State.json('auth-user')
    },


    /**
     * Returns the appropriate download link.
     *
     * @returns {string}
     */
    getDownloadLink() {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Detect android devices.
        if (/android/i.test(userAgent)) {
            return 'https://play.google.com/store/apps/details?id=com.reliantstaffing.app';
        }

        // iOS's detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return 'https://apps.apple.com/us/app/reliant-staffing-healthcare/id6460259134';
        }

        return 'https://play.google.com/store/apps/details?id=com.reliantstaffing.app';
    },


    /**
     * Indicates if the instance is of a specific tenant.
     *
     * @param key
     * @returns {boolean}
     */
    isInstance(key = '') {
        return Settings.tenantKey === key;
    }
};

export default App;