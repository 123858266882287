import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import Client from "../../Models/Client";
import MassMail from "../../Models/MassMail";
import Employee from "../../Models/Employee";
import DataTable from "../../Components/Layouts/Index/DataTable";
import TextEditor from "../../Components/Input/TextEditor";
import ColumnFilter from "../../Components/Layouts/Index/FilterDrawer/ColumnFilter";
import DialogHeading from "../../Components/Typography/DialogHeading";
import SectionHeading from "../../Components/Typography/SectionHeading";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Switch from "@mui/material/Switch";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import ButtonGroup from "@mui/material/ButtonGroup";
import FormControl from "@mui/material/FormControl";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormHelperText from "@mui/material/FormHelperText";
import {DateTimePicker} from "@mui/x-date-pickers";
import FormControlLabel from "@mui/material/FormControlLabel";

/**
 * MassMailForm component.
 *
 * @returns {*}
 * @constructor
 */
const MassMailForm = (props) => {
    const {
        open,               // {Boolean} Indicates whether the form is open.
        record,             // {Object} The form context object, handles preloading the form.
        onSave,             // {Function} An onSave callback, triggered whenever the form is saved.,
        onClose,            // {Function} An onClose callback, triggered whenever the modal is closed.
    } = props;

    const [index, setIndex] = useState(0);
    const [count, setCount] = useState(0);
    const [fields, setFields] = useState(MassMail.getInstance(record));
    const [isLoading, setLoading] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [isPreviewing, setViewingPreview] = useState(false);

    /**
     * Load any additional information on mount.
     */
    useEffect(() => {
        getTemplates(fields.type, fields.modelType);
    }, []);


    /**
     * Retrieves any templates via the API.
     *
     * @returns {Promise<void>}
     */
    const getTemplates = async (messageType, modelType) => {
        let filter = '';

        // Validate input parameters.
        if (!messageType || !modelType) {
            return setTemplates([]);
        }

        if (messageType) {
            switch (messageType) {
                case 'SMS':
                    filter = 'isSms eq {1}';
                    break;

                case 'Email':
                    filter = 'isEmail eq {1}';
                    break;

                case 'Chat':
                    filter = 'isChat eq {1}';
                    break;
            }
        }

        if (modelType) {
            filter += (!!filter ? ' and ' : '') + `modelType eq {${modelType}}`
        }

        filter += (!!filter ? ' and ' : '') + `isDeleted eq {0}`;

        setTemplates(
            await API.get('templates', {
                $filter: filter
            })
        )
    };

    /**
     * Performs the save via the API.
     */
    const doSave = async () => {
        setLoading(true);

        const response = fields.id ?
            await API.put(`mass-mail/${fields.id}`, fields) :
            await API.post('mass-mail', fields);

        setLoading(false);

        if (onSave) {
            onSave(response);
        }
    };


    /**
     * Updates a particular form value.
     *
     * @param key
     * @param value
     */
    const setValue = (key, value) => {
        setFields({
            ...fields,
            [key]: value
        });
    };


    /**
     * Determines the filterable properties for the selected model.
     *
     * @type {*|Array}
     */
    const filterable = (fields.modelType === 'Client' ? Client : Employee).getFilterableProperties();


    /**
     * The selected filter criteria.
     *
     * @type {any|{}}
     */
    const selectedFilters = fields.filters ? JSON.parse(fields.filters) : {};


    /**
     * The target campaign model.
     *
     * @type {Object}
     */
    const targetModel = fields.modelType === 'Client' ? Client : Employee;


    /**
     * Recalculates the recipient counter whenever filters change.
     */
    useEffect(() => {
        getRecipientCount();
    }, [targetModel, selectedFilters])


    /**
     * Returns the filter query for recipients.
     *
     * @returns {{$expand: string}|{}}
     */
    const getFilterQuery = () => {
        const request = fields.modelType === 'Employee' ? {
            '$expand': 'specialty,state'
        } : {};
        const filterCriteria = [];

        for (let i in selectedFilters) {
            if (!selectedFilters.hasOwnProperty(i) || !selectedFilters[i] || !selectedFilters[i].length) {
                continue;
            }

            // Handle type-specific selections for compatibility with the backend.
            const selectedOptions = selectedFilters[i].map(option => option === true ? '1' :
                (option === false ? '0' : option)
            );

            filterCriteria.push(`${i} in {${selectedOptions.join(',')}}`)
        }

        // Build out the filter string.
        if (filterCriteria.length) {
            let existing = request['$filter'] || '';
            request['$filter'] = filterCriteria.join(' and ');

            if (request['$filter'].trim()) {
                request['$filter'] += ` and ${existing}`
            }
        }

        return request;
    };


    /**
     * Returns the appropriate filter value from local storage.
     *
     * @param property
     * @returns {*}
     */
    const getFilterValue = property => {
        if (selectedFilters.hasOwnProperty(property.key)) {
            const selection = selectedFilters[property.key];

            return selection && selection.length ? selection : [];
        }

        return [];
    };


    /**
     * Processes the selected filter update.
     *
     * @param property
     * @param value
     * @returns {Promise<void>}
     */
    const handleFilterChange = async (property, value) => {
        setValue('filters', JSON.stringify({
            ...selectedFilters,
            [property.key]: value
        }));
    };


    /**
     * Displays the recipient preview.
     */
    const handlePreviewOpen = () => {
        setViewingPreview(true);
    };


    /**
     * Closes the recipient preview.
     */
    const handlePreviewClose = () => {
        setViewingPreview(false);
    };


    /**
     * Calculates the recipient count.
     *
     * @returns {Promise<void>}
     */
    const getRecipientCount = async () => {
        if(!fields.modelType){
            return;
        }

        const endpoint = fields.modelType === 'Client' ? 'clients' : 'employees';
        const response = await API.get(`${endpoint}/count`, getFilterQuery());
        setCount(response.count);
    };

    return (
        <>
            <Dialog
                open={open}
                scroll={'body'}
                onClose={onClose}
                maxWidth={'lg'}
                fullWidth
            >
                <DialogHeading
                    title={'Campaign Details'}
                    noMargin
                />
                <DialogContent>
                    <Box className={'columns__1'}>
                        <SectionHeading title={'General Information'}/>
                        <Box className={'columns__3'}>
                            <FormControl>
                                <InputLabel required>Message Type</InputLabel>
                                <Select
                                    disabled={isLoading}
                                    label="Message Type"
                                    required
                                    onChange={event => {
                                        setValue('type', event.target.value);
                                        getTemplates(event.target.value, fields.modelType);
                                    }}
                                    value={fields.type}
                                >
                                    {['SMS', 'Email', 'Chat'].map((option) =>
                                        <MenuItem
                                            key={option}
                                            value={option}
                                            children={option}
                                        />
                                    )}
                                </Select>
                            </FormControl>

                            <FormControl>
                                <InputLabel required>Record Type</InputLabel>
                                <Select
                                    label={'Record Type'}
                                    required
                                    disabled={isLoading}
                                    onChange={event => {
                                        setValue('fields', '{}');
                                        setIndex(index + 1);
                                        setValue('modelType', event.target.value);
                                        getTemplates(fields.type, event.target.value);
                                    }}
                                    value={fields.modelType}
                                >
                                    {['Employee', 'Client'].map((option) =>
                                        <MenuItem
                                            key={option}
                                            value={option}
                                            children={option}
                                            disabled={option === 'Client'}
                                        />
                                    )}
                                </Select>
                            </FormControl>

                            <DateTimePicker
                                label={'Scheduled Date'}
                                value={fields.scheduledDate ? dayjs(fields.scheduledDate) : null}
                                disabled={isLoading}
                                onChange={event => setValue('scheduledDate', event ? event.format('YYYY-MM-DD HH:mm:ss') : '')}
                            />
                        </Box>

                        <Box className={'d-flex__justify'}>
                            <Box className={'d-flex__grow columns__1 mr__3'}>
                                {!fields.isInline ? (
                                    <FormControl>
                                        <InputLabel>Template</InputLabel>
                                        <Select
                                            label={'Template'}
                                            value={fields.templateId}
                                            disabled={isLoading || !fields.type || !fields.modelType}
                                            onChange={event => setValue('templateId', event.target.value)}
                                        >
                                            {templates.map((template) =>
                                                <MenuItem
                                                    key={template.id}
                                                    value={template.id}
                                                    children={template.name}
                                                />
                                            )}
                                        </Select>
                                    </FormControl>
                                ) : (
                                    <FormControl>
                                        <TextField
                                            label={'Subject'}
                                            value={fields.name}
                                            required
                                            disabled={isLoading}
                                            onChange={event => setValue('name', event.target.value)}
                                        />
                                    </FormControl>
                                )}
                            </Box>

                            <Box>
                                <ButtonGroup>
                                    <Button
                                        onClick={() => setValue('isInline', false)}
                                        variant={!fields.isInline ? 'contained' : 'outlined'}
                                        children={'Template'}
                                    />
                                    <Button
                                        onClick={() => setValue('isInline', true)}
                                        variant={!fields.isInline ? 'outlined' : 'contained'}
                                        children={'Inline'}
                                    />
                                </ButtonGroup>
                            </Box>
                        </Box>

                        <TextField
                            rows={4}
                            label={'Description'}
                            value={fields.description}
                            disabled={isLoading}
                            onChange={event => setValue('description', event.target.value)}
                            multiline
                            fullWidth
                        />

                        {!!fields.isInline && (
                            <>
                                <SectionHeading title={'Campaign Content'}/>

                                <TextEditor
                                    value={fields.content}
                                    onChange={value => setValue('content', value)}
                                />
                            </>
                        )}

                        <Box className={'columns__1'} sx={{overflow: 'auto'}}>
                            <SectionHeading title={'Who should see this message?'}/>

                            {!!fields.modelType ? (
                                <Box className={'columns__3'} key={`filters-${index}`}>
                                    {filterable.map((property, i) => {
                                        return (
                                            <ColumnFilter
                                                key={property.key}
                                                model={targetModel}
                                                value={getFilterValue(property)}
                                                property={property}
                                                onChange={value => handleFilterChange(property, value)}
                                            />
                                        );
                                    })}
                                </Box>
                            ) : (
                                <Alert severity={'info'}>
                                    Please first select a record type.
                                </Alert>
                            )}
                        </Box>

                        <FormGroup>
                            <FormControlLabel control={
                                <Switch
                                    onChange={event => setValue('isEnabled', event.target.checked)}
                                    defaultChecked={fields.isEnabled}
                                />
                            } label="Activate this marketing message?"/>
                            <FormHelperText>This communication will only send if activated, please verify that
                                everything looks accurate before saving.</FormHelperText>
                        </FormGroup>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handlePreviewOpen}
                        children={`Recipients (${count})`}
                    />
                    <Button
                        onClick={doSave}
                        children={'Save'}
                    />
                    <Button
                        color={'error'}
                        onClick={onClose}
                        children={'Close'}
                    />
                </DialogActions>
            </Dialog>

            {isPreviewing && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handlePreviewClose}
                    maxWidth={'lg'}
                    fullWidth
                >
                    <Box className={'notifications__body'} sx={{padding: 0, overflow: 'hidden'}}>
                        <DataTable
                            model={targetModel}
                            query={getFilterQuery()}
                            actions={false}
                            doReload={null}
                        />
                    </Box>
                </Dialog>
            )}
        </>
    );
};

export default MassMailForm;