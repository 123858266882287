import React, {useState} from "react";

import Client from "../../Models/Client";
import Settings from "../../Global/Settings";
import {useAuth} from "../../Global/Auth";
import Formatter from "../../Global/Formatter";
import TrueHelix from "../../Integrations/TrueHelix";

import List from "@mui/material/List";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import MapIcon from '@mui/icons-material/Map';
import ListItem from "@mui/material/ListItem";
import MenuIcon from '@mui/icons-material/Menu';
import FeedIcon from '@mui/icons-material/Feed';
import BadgeIcon from '@mui/icons-material/Badge';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import PeopleIcon from '@mui/icons-material/People';
import SchoolIcon from '@mui/icons-material/School';
import BackupIcon from '@mui/icons-material/Backup';
import GroupsIcon from '@mui/icons-material/Groups';
import PinDropIcon from '@mui/icons-material/PinDrop';
import AddCardIcon from '@mui/icons-material/AddCard';
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import CampaignIcon from '@mui/icons-material/Campaign';
import {useNavigate} from "react-router";
import EventNoteIcon from '@mui/icons-material/EventNote';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import ListItemButton from "@mui/material/ListItemButton";
import AssessmentIcon from '@mui/icons-material/Assessment';
import AssignmentIcon from '@mui/icons-material/Assignment';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PunchClockIcon from '@mui/icons-material/PunchClock';
import DescriptionIcon from '@mui/icons-material/Description';
import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';
import BrowseGalleryIcon from '@mui/icons-material/BrowseGallery';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

/**
 * MainMenu component.
 *
 * @returns {*}
 * @constructor
 */
const MainMenu = (props) => {
    const {
        isParent,                   // {Boolean} Determines if this appears in the sidebar or the container.
        disabled,                   // {Boolean} Indicates whether we should disable linking.
        onChildLogoClick,           // {Function} A callback against the child / inner menu logo.
    } = props;

    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = useState(null);
    const {user, isScope, isHired, isInviteCompleted, hasPermissionTo} = useAuth();

    /**
     * The True Helix enrollment flag.
     */
    const {isHelixOptIn} = user || {};


    /**
     * Fired whenever a user clicks on the image with text.
     */
    const handleLogoClick = () => {
        if (disabled) {
            return;
        }

        if (isParent) {
            return navigate('/');
        }

        if (onChildLogoClick) {
            onChildLogoClick();
        }
    };


    /**
     * Fired whenever a user clicks on the hamburger icon.
     */
    const handleHamburgerClick = (event) => {
        if (disabled) {
            return;
        }

        if (isParent) {
            handleOpenMenu(event);
        }

        if (onChildLogoClick) {
            onChildLogoClick();
        }
    };


    /**
     * Reveals the main navigation drawer.
     */
    const handleOpenMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };


    /**
     * Closes the main navigation drawer.
     */
    const handleCloseMenu = () => {
        setAnchorElNav(null);
    };

    return (
        <>
            <div className={'d-flex__start'}>
                <IconButton
                    size="large"
                    color="inherit"
                    sx={!isParent ? {
                        "&.MuiButtonBase-root:hover": {
                            backgroundColor: "transparent"
                        }
                    } : {}}
                    onClick={handleHamburgerClick}
                >
                    <MenuIcon/>
                </IconButton>
                <Typography
                    sx={{
                        mr: 2,
                        color: 'inherit',
                        display: isParent ? {
                            xs: 'none', md: 'flex'
                        } : {},
                        textDecoration: 'none',
                    }}
                    noWrap
                    onClick={handleLogoClick}
                    component={'a'}
                >
                    <div className={`logo ${isParent ? 'logo--parent' : 'logo--drawer'}`}>
                        <img src={Formatter.url('assets/images/badge.png')} alt={'Reliant Staffing'}/>
                        <div className={'logo__text'}>{Settings.applicationName}</div>
                    </div>
                </Typography>
            </div>
            <Drawer
                open={Boolean(anchorElNav)}
                anchor={'left'}
                onClose={handleCloseMenu}
            >
                <List className={'main-menu__container'}>
                    <ListItem disablePadding>
                        <ListItemButton>
                            <MainMenu onChildLogoClick={handleCloseMenu}/>
                        </ListItemButton>
                    </ListItem>

                    {isScope('User') && (
                        <>
                            <ListItem disablePadding>
                                <ListItemButton onClick={() => navigate(`/`)}>
                                    <ListItemIcon>
                                        <MapsHomeWorkIcon/>
                                    </ListItemIcon>
                                    <ListItemText primary="Home"/>
                                </ListItemButton>
                            </ListItem>
                            <Divider/>

                            {hasPermissionTo('VIEW_CLIENTS') && (
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => navigate('/clients')}>
                                        <ListItemIcon>
                                            {Client.icon()}
                                        </ListItemIcon>
                                        <ListItemText primary="Clients"/>
                                    </ListItemButton>
                                </ListItem>
                            )}

                            {hasPermissionTo('VIEW_EMPLOYEES') && (
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => navigate('/employees')}>
                                        <ListItemIcon>
                                            <PeopleIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Employees"/>
                                    </ListItemButton>
                                </ListItem>
                            )}

                            {hasPermissionTo('VIEW_RECRUITING') && (
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => navigate('/recruiting')}>
                                        <ListItemIcon>
                                            <AssignmentIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Recruiting"/>
                                    </ListItemButton>
                                </ListItem>
                            )}

                            {hasPermissionTo('VIEW_SCHEDULE') && (
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => navigate('/schedule')}>
                                        <ListItemIcon>
                                            <CalendarMonthIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Schedule"/>
                                    </ListItemButton>
                                </ListItem>
                            )}

                            {hasPermissionTo('VIEW_TRAINING') && (
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => navigate('/training')}>
                                        <ListItemIcon>
                                            <SchoolIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Training"/>
                                    </ListItemButton>
                                </ListItem>
                            )}

                            {hasPermissionTo('VIEW_EMPLOYEES') && (
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => navigate('/map')}>
                                        <ListItemIcon>
                                            <MapIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Map"/>
                                    </ListItemButton>
                                </ListItem>
                            )}

                            {hasPermissionTo('VIEW_COMMUNICATIONS') && (
                                <>
                                    <Divider/>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate('/marketing')}>
                                            <ListItemIcon>
                                                <CampaignIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Campaigns"/>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate('/templates')}>
                                            <ListItemIcon>
                                                <FeedIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Templates"/>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate('/call-logs')}>
                                            <ListItemIcon>
                                                <LocalPhoneIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Call Logs"/>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate('/call-settings')}>
                                            <ListItemIcon>
                                                <PhonelinkSetupIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Call Settings"/>
                                        </ListItemButton>
                                    </ListItem>
                                </>
                            )}

                            {hasPermissionTo('VIEW_LIBRARIES') && (
                                <>
                                    <Divider/>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate('/departments')}>
                                            <ListItemIcon>
                                                <GroupsIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Departments"/>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate('/document-types')}>
                                            <ListItemIcon>
                                                <LibraryBooksIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Documents"/>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate('/specialties')}>
                                            <ListItemIcon>
                                                <FolderSpecialIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Specialties"/>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate('/states')}>
                                            <ListItemIcon>
                                                <PinDropIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="States"/>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate('/statuses')}>
                                            <ListItemIcon>
                                                <WidgetsIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Statuses"/>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate('/users')}>
                                            <ListItemIcon>
                                                <SupervisedUserCircleIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Users"/>
                                        </ListItemButton>
                                    </ListItem>
                                </>
                            )}

                            {(
                                hasPermissionTo('VIEW_REPORTS') ||
                                hasPermissionTo('VIEW_SETTINGS')
                            ) && (
                                <Divider/>
                            )}
                            {hasPermissionTo('VIEW_REPORTS') && (
                                <>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate('/reports')}>
                                            <ListItemIcon>
                                                <AssessmentIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Reports"/>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate('/time-sheets')}>
                                            <ListItemIcon>
                                                <PendingActionsIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Time Sheets"/>
                                        </ListItemButton>
                                    </ListItem>
                                </>
                            )}
                            {hasPermissionTo('VIEW_SETTINGS') && (
                                <ListItem disablePadding>
                                    <ListItemButton onClick={() => navigate('/system-settings')}>
                                        <ListItemIcon>
                                            <SettingsSuggestIcon/>
                                        </ListItemIcon>
                                        <ListItemText primary="Settings"/>
                                    </ListItemButton>
                                </ListItem>
                            )}
                        </>
                    )}

                    {isScope('Employee') && (
                        <>
                            {isHired() && (
                                <>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate(`/`)}>
                                            <ListItemIcon>
                                                <MapsHomeWorkIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Home"/>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate(`/profile`)}>
                                            <ListItemIcon>
                                                <AccountCircleIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="My Profile"/>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate(`/employee/schedule`)}>
                                            <ListItemIcon>
                                                <CalendarMonthIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Schedule"/>
                                        </ListItemButton>
                                    </ListItem>
                                    {!!isHelixOptIn && (
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={TrueHelix.open}>
                                                <ListItemIcon>
                                                    <PunchClockIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary="Time Clock"/>
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate(`/employee/add-shift`)}>
                                            <ListItemIcon>
                                                <EditCalendarIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Shift Pick Up"/>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate(`/employee/time-sheet`)}>
                                            <ListItemIcon>
                                                <AlarmOnIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Submit Time Sheet"/>
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider/>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate(`/availability`)}>
                                            <ListItemIcon>
                                                <BrowseGalleryIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Availability"/>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate(`/employee/documents`)}>
                                            <ListItemIcon>
                                                <BackupIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Upload Credentials"/>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate(`/employee/training`)}>
                                            <ListItemIcon>
                                                <SchoolIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Training"/>
                                        </ListItemButton>
                                    </ListItem>
                                    <Divider/>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate(`/employee/direct-deposit`)}>
                                            <ListItemIcon>
                                                <AddCardIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Direct Deposit"/>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate(`/employee/badge-request`)}>
                                            <ListItemIcon>
                                                <BadgeIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Request a Badge"/>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate(`/employee/benefits`)}>
                                            <ListItemIcon>
                                                <LoyaltyIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Job Benefits"/>
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate(`/employee/time-off-request`)}>
                                            <ListItemIcon>
                                                <ScheduleSendIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Paid Time Off"/>
                                        </ListItemButton>
                                    </ListItem>
                                    {user.offerLetterDate && user.hirePacketDate && (
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => navigate(`/employee/disclosures`)}>
                                                <ListItemIcon>
                                                    <DescriptionIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary="My Documents"/>
                                            </ListItemButton>
                                        </ListItem>
                                    )}
                                    <Divider/>
                                    <ListItem disablePadding>
                                        <ListItemButton onClick={() => navigate(`/refer-friend`)}>
                                            <ListItemIcon>
                                                <PeopleAltIcon/>
                                            </ListItemIcon>
                                            <ListItemText primary="Refer a Friend"/>
                                        </ListItemButton>
                                    </ListItem>
                                </>
                            )}

                            {!isHired() && (
                                !isInviteCompleted() ? (
                                    <>
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => navigate(`/`)}>
                                                <ListItemIcon>
                                                    <MapsHomeWorkIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary="Home"/>
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => navigate(`/invitation`)}>
                                                <ListItemIcon>
                                                    <AccountCircleIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary="My Profile"/>
                                            </ListItemButton>
                                        </ListItem>
                                    </>
                                ) : (
                                    <>
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => navigate(`/`)}>
                                                <ListItemIcon>
                                                    <MapsHomeWorkIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary="Home"/>
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => navigate(`/profile`)}>
                                                <ListItemIcon>
                                                    <AccountCircleIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary="My Profile"/>
                                            </ListItemButton>
                                        </ListItem>
                                        <Divider/>
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => navigate(`/offer-letter`)}>
                                                <ListItemIcon>
                                                    <HistoryEduIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary="Disclosures"/>
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => navigate(`/availability`)}>
                                                <ListItemIcon>
                                                    <BrowseGalleryIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary="Availability"/>
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => navigate(`/hire-packet`)}>
                                                <ListItemIcon>
                                                    <AssignmentIndIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary="Hire Packet"/>
                                            </ListItemButton>
                                        </ListItem>
                                        <ListItem disablePadding>
                                            <ListItemButton onClick={() => navigate(`/employee/training`)}>
                                                <ListItemIcon>
                                                    <SchoolIcon/>
                                                </ListItemIcon>
                                                <ListItemText primary="Training"/>
                                            </ListItemButton>
                                        </ListItem>
                                    </>
                                )
                            )}
                        </>
                    )}
                </List>

                {isScope('Employee') && !isHired() && (
                    <ListItem disablePadding className={'menu__referral'}>
                        <ListItemButton onClick={() => navigate(`/refer-friend`)}>
                            <ListItemIcon>
                                <PeopleAltIcon/>
                            </ListItemIcon>
                            <ListItemText primary="Refer a Friend"/>
                        </ListItemButton>
                    </ListItem>
                )}
            </Drawer>
        </>
    )
};

export default MainMenu;