import React from "react";

import TableLayout from "../../Components/Layouts/TableLayout";
import EmployeeCourse from "../../Models/EmployeeCourse";

import Button from "@mui/material/Button";
import SchoolIcon from '@mui/icons-material/School';
import {useNavigate} from "react-router-dom";

/**
 * Training component.
 *
 * @returns {*}
 * @constructor
 */
const Training = () => {
    const navigate = useNavigate();

    return (
        <TableLayout
            icon={<SchoolIcon/>}
            model={EmployeeCourse}
            heading={'Training'}
            condensed
            useLoader
            hideFilters
            resultLabel={'courses'}
            filterSlotEnd={
                <Button
                    size={'small'}
                    variant={'outlined'}
                    onClick={() => navigate('/')}
                    children={'Back'}
                    className={'text__small'}
                />
            }
        />
    )
};

export default Training;