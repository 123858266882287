import React, {useState} from "react";

import Box from "@mui/material/Box";

/**
 * RecruitingFilter component.
 *
 * @returns {*}
 * @constructor
 */
const RecruitingFilter = (props) => {
    const {
        value,          // {String} The existing tab selection.
        onChange        // {Function} Triggered whenever the user clicks a new tab.
    } = props;

    const [tab, setTab] = useState(value);

    /**
     * Handles the change event for tabs.
     *
     * @param tab
     */
    const handleTabClick = (tab) => {
        setTab(tab);

        if (onChange) {
            onChange(tab);
        }
    };

    return (
        <Box
            className={'breadcrumbs quick__filters'}
            sx={{padding: '0.4em', paddingLeft: '0.5em', paddingRight: '0.5em'}}
        >
            <div className={'crumb'}>
                <a
                    className={`d-flex__start generic__link ${tab === 'new' ? 'active' : ''}`}
                    onClick={() => handleTabClick('new')}
                >New</a>
            </div>
            <div className={'divider'}/>
            <div className={'crumb'}>
                <a
                    className={`d-flex__start generic__link ${tab === 'invited' ? 'active' : ''}`}
                    onClick={() => handleTabClick('invited')}
                >Invited</a>
            </div>
        </Box>
    );
};

export default RecruitingFilter;