import React, {useState} from "react";

import API from "../../Global/API";
import {useAuth} from "../../Global/Auth";
import Formatter from "../../Global/Formatter";
import usePhoneCall from "../../Hooks/usePhoneCall";
import ImageWrapper from "../../Components/ImageWrapper";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import ListItem from "@mui/material/ListItem";
import CloseIcon from '@mui/icons-material/Close';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import RestoreIcon from '@mui/icons-material/Restore';
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import PhoneDisabledIcon from "@mui/icons-material/PhoneDisabled";

/**
 * ThreadPreview component.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
const ThreadPreview = (props) => {
    const {
        record,             // {Object} The chat thread record for context.
        onClick,            // {Function} An on-click handler for the list item preview.
        doReload,           // {Function} An optional reload callback.
    } = props;

    const [isLoading, setLoading] = useState(false);

    const {
        user,
        isScope
    } = useAuth();

    const {
        isMuted,
        phoneCall,
        phoneNumber: activePhoneNumber
    } = usePhoneCall();


    /**
     * Returns the appropriate relational key depending on the authenticated user.
     *
     * @type {string}
     */
    const relationKey = isScope('Employee') ? 'user' : 'employee';


    /**
     * Indicates if a record is currently inactive.
     *
     * @type {boolean|*}
     */
    const isInactive = record.isDeleted || record.isSnoozed;


    /**
     * The related record object.
     *
     * @type {Object}
     */
    const relatedRecord = {
        ...record[relationKey]
    };


    /**
     * Snoozes a particular conversation.
     *
     * @returns {Promise<void>}
     */
    const doSnooze = async () => {
        setLoading(true);

        const response = await API.put(`chat-threads/${record.id}`, {
            isSnoozed: true
        });

        setLoading(false);

        if(doReload){
            doReload(response);
        }
    };


    /**
     * Restores a chat thread.
     *
     * @returns {Promise<void>}
     */
    const doRestore = async () => {
        setLoading(true);

        const response = await API.put(`chat-threads/${record.id}`, {
            isSnoozed: false,
            isDeleted: false
        });

        setLoading(false);

        if(doReload){
            doReload(response);
        }
    };


    /**
     * Indicates whether the chat thread has a related department.
     *
     * @param chatThread
     * @returns {*}
     */
    const hasDepartment = (chatThread) => {
        return chatThread.department && chatThread.department.name;
    };


    /**
     * Returns the nice name of a user.
     *
     * @param user
     * @returns {string}
     */
    const getUserNiceName = (user) => {
        return user && user.firstName ? `${user.firstName} ${user.lastName ? `${user.lastName.charAt(0)}.` : ''}` : '(no name)';
    };


    /**
     * Indicates if there are unread messages.
     *
     * @param chatThread
     * @returns {number}
     */
    const hasUnread = (chatThread) => {
        const messages = [...(chatThread.messages || [])];

        if (isScope('User')) {
            return messages.filter(message => message.recipientType === 'User' && message.recipientId === user.id && !message.isRead).length;
        }

        return messages.filter(message => message.type === 'Chat' && message.recipientType === 'Employee' && message.recipientId === user.id && !message.isRead).length;
    };


    /**
     * Indicates if the chat thread recipient is the person we're speaking to.
     *
     * @param phoneNumber
     * @returns {boolean|Settings.activeCall|{}}
     */
    const isActiveCall = (phoneNumber) => {
        return isScope('User') &&
            phoneCall &&
            phoneNumber &&
            Formatter.e164(activePhoneNumber) === Formatter.e164(phoneNumber);
    };

    return (
        <ListItem
            className={'chat-thread__preview'}
            disablePadding
            secondaryAction={
                <Box className={'d-flex__start'}>
                    {isActiveCall(relatedRecord.phoneNumber) && (
                        <>
                            {!isMuted ? (
                                <LocalPhoneIcon
                                    className={'mr__1 text__light'}
                                />
                            ) : (
                                <PhoneDisabledIcon
                                    className={'mr__1 text__light'}
                                />
                            )}
                        </>
                    )}

                    {hasUnread(record) ? (
                        <Box
                            sx={{pointerEvents: 'none', width: '0.5em', height: '0.5em', marginRight: '0.5em'}}
                            className={'notification__indicator notification__indicator--highlight'}
                        />
                    ) : null}

                    <Box className={'chat-thread__snooze-wrapper'}>
                        <IconButton
                            onClick={isInactive ? doRestore : doSnooze}
                            disabled={isLoading}
                            children={isInactive ? <RestoreIcon fontSize={'small'}/> : <CloseIcon fontSize={'small'}/>}
                            className={'chat-thread__snooze'}
                        />
                    </Box>
                </Box>
            }
        >
            <ListItemButton
                sx={{
                    paddingLeft: '1em'
                }}
                onClick={() => onClick(record)}
                disableGutters
            >
                <ListItemAvatar>
                    <ImageWrapper
                        src={API.getFilePath(relatedRecord && relatedRecord.image ? relatedRecord.image : '')}
                    />
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <Box className={'d-flex__start mb__1'}>
                            <b>{isScope('User') ? `${relatedRecord.firstName} ${relatedRecord.lastName}` : getUserNiceName(relatedRecord)}</b>

                            {!!record.isSnoozed ? (
                                <Chip
                                    size={'small'}
                                    label={'Snoozed'}
                                    color={'info'}
                                    className={'ml__2'}
                                />
                            ) : (
                                <>
                                    {!!record.isDeleted && (
                                        <Chip
                                            size={'small'}
                                            color={'error'}
                                            label={'Deleted'}
                                            className={'ml__2'}
                                        />
                                    )}
                                </>
                            )}
                        </Box>
                    }
                    secondary={
                        <Box className={'text__small'}>
                            {hasDepartment(record) && (
                                <Typography
                                    sx={{display: 'inline'}}
                                    color="text.primary"
                                    variant="body2"
                                    component="span"
                                >
                                    {isScope('User') ? 'Assigned to ' : ''}{record.department && record.department.name ? record.department.name : '(no department)'}
                                    {` — `}
                                </Typography>
                            )}

                            {`Started ${Formatter.dateTime(record.createdDate)}`}
                        </Box>
                    }
                />
            </ListItemButton>
        </ListItem>
    );
};

export default ThreadPreview;