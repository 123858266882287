import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import Template from "../../Models/Template";
import Settings from "../../Global/Settings";
import {useAuth} from "../../Global/Auth";
import InputFile from "../Input/InputFile";
import ModelSearch from "../Input/ModelSearch";

import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LinearProgress from "@mui/material/LinearProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";

/**
 * GroupChatDialog component.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
const GroupChatDialog = (props) => {
    const {
        open,               // {Boolean} The open state of the dialog.
        onSend,             // {Function} A post-send callback.
        onClose,            // {Function} The close handler for the dialog.
        selection,          // {Array} An array of selected recipients.
    } = props;

    const {user} = useAuth();
    const [isSms, setSms] = useState(false);
    const [isChat, setChat] = useState(false);
    const [isEmail, setEmail] = useState(false);
    const [message, setMessage] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isTemplate, setTemplate] = useState(false);
    const [attachment, setAttachment] = useState('');
    const [templatePreview, setTemplatePreview] = useState({});
    const [isMessageSuccess, setMessageSuccess] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState('');

    /**
     * The message heading content for the bulk message dialog.
     *
     * @type {string}
     */
    const messageHeading = `Send Message${selection.length ? ` (${selection.length})` : ''}`;


    /**
     * Synchronize the template preview when the selected template changes.
     */
    useEffect(() => {
        getTemplatePreview();
    }, [selectedTemplate]);


    /**
     * Loads the template content for the selected template.
     *
     * @returns {Promise<void>}
     */
    const getTemplatePreview = async () => {
        if (!selectedTemplate) {
            setTemplatePreview({});
            return;
        }

        setTemplatePreview(
            await API.get(`templates/${selectedTemplate}`)
        );
    };


    /**
     * Delivers the bulk message to the selected employees.
     *
     * @returns {Promise<void>}
     */
    const handleMessageSend = async () => {
        setLoading(true);
        const chunkSize = 10;
        const types = [];

        if (isSms) {
            types.push('SMS');
        }

        if (isChat) {
            types.push('Chat');
        }

        if (isEmail) {
            types.push('Email');
        }

        // Break out the selection into small chunks.
        for (let j = 0; j < types.length; j++) {
            const type = types[j];

            for (let i = 0; i < selection.length; i += chunkSize) {
                const chunk = selection.slice(i, i + chunkSize);

                await Promise.all(
                    chunk.map(async employee => {
                        let payload = {
                            type: type,
                            senderId: user.id,
                            senderType: 'User',
                            recipientId: employee.id,
                            recipientType: 'Employee',
                        };

                        if(attachment){
                            payload.attachment = attachment;
                        }

                        if(isTemplate && selectedTemplate){
                            const response = await API.post('render', {
                                modelId: employee.id,
                                modelType: 'Employee',
                                templateId: selectedTemplate,
                                expectedFormat: type,
                            });

                            payload.content = response.template;
                            payload.subject = response.subject;
                            payload.templateId = selectedTemplate;
                        }
                        else{
                            payload.content = message;
                        }

                        await API.post('messages', payload)
                    })
                );
            }
        }

        setSms(false);
        setChat(false);
        setEmail(false);
        setMessage('');
        setLoading(false);
        setTemplate(false);
        setSelectedTemplate('');
        setMessageSuccess(true);

        if (onSend) {
            onSend();
        }

        if (onClose) {
            onClose();
        }
    };

    return (
        <>
            <Dialog
                open={open}
                scroll={'body'}
                onClose={!isLoading ? onClose : null}
                maxWidth={'md'}
                fullWidth
            >
                {isLoading && <LinearProgress/>}

                <DialogContent>
                    {isLoading ? (
                        <Alert severity={'warning'} icon={<PhoneAndroidIcon fontSize={'inherit'}/>}>
                            Your messages are being sent, please be patient as this may take a moment...
                        </Alert>
                    ) : (
                        <Alert severity={'warning'} icon={<PhoneAndroidIcon fontSize={'inherit'}/>}>
                            This form will send a group message to the selected recipients.
                        </Alert>
                    )}
                    <Box className={'columns__1'}>
                        <InputLabel
                            children={messageHeading}
                            className={'form__heading form__heading--standalone'}
                        />

                        <Box className={'columns__1'}>
                            <FormControl variant="standard">
                                <FormLabel required>How should these messages be delivered?</FormLabel>
                                <FormGroup row>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isSms}
                                                disabled={isLoading}
                                                onChange={event => setSms(event.target.checked)}
                                            />
                                        }
                                        label="SMS"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isChat}
                                                disabled={isLoading}
                                                onChange={event => setChat(event.target.checked)}
                                            />
                                        }
                                        label="Chat"
                                    />
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={isEmail}
                                                disabled={isLoading}
                                                onChange={event => setEmail(event.target.checked)}
                                            />
                                        }
                                        label="Email"
                                    />
                                </FormGroup>
                            </FormControl>

                            <Box className={'mb__2'}>
                                <FormControl className={'columns__1 columns--small'}>
                                    <FormLabel>Add an attachment?</FormLabel>
                                    <InputFile
                                        value={attachment}
                                        disabled={isLoading}
                                        onChange={value => setAttachment(value)}
                                        uploadPath={`attachments`}
                                    />
                                </FormControl>
                            </Box>

                            <FormControl>
                                <FormLabel>Do you want to use a template?</FormLabel>
                                <RadioGroup
                                    row
                                    value={isTemplate}
                                    onChange={event => setTemplate(event.target.value === 'true')}
                                >
                                    <FormControlLabel
                                        label={'No'}
                                        value={'false'}
                                        control={<Radio disabled={isLoading}/>}
                                    />
                                    <FormControlLabel
                                        label={'Yes'}
                                        value={'true'}
                                        control={<Radio disabled={isLoading}/>}
                                    />
                                </RadioGroup>
                            </FormControl>

                            {isTemplate ? (
                                <>
                                    <ModelSearch
                                        model={Template}
                                        label={'Select a template...'}
                                        value={selectedTemplate}
                                        disabled={isLoading}
                                        onChange={value => setSelectedTemplate(value)}
                                        renderLabel={(option) => !option ? '' : option.name}
                                        filterQuery={query => `isDeleted eq {0} and name eq {${query}}`}
                                    />

                                    {templatePreview.content && (
                                        <Box className={'well__container p__3'} dangerouslySetInnerHTML={{__html: templatePreview.content || Settings.emptyContentLabel}}/>
                                    )}
                                </>
                            ) : (
                                <FormControl>
                                    <FormLabel className={'mb__2'}>What do you want to say?</FormLabel>
                                    <TextField
                                        rows={5}
                                        value={message}
                                        multiline
                                        disabled={isLoading}
                                        onChange={event => setMessage(event.target.value)}
                                    />
                                </FormControl>
                            )}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleMessageSend}
                        children={'Send'}
                        disabled={
                            (!isTemplate && !message) ||
                            (isTemplate && !selectedTemplate) ||
                            isLoading
                        }
                    />
                    <Button
                        color={'error'}
                        onClick={onClose}
                        children={'Cancel'}
                        disabled={isLoading}
                    />
                </DialogActions>
            </Dialog>
            <Snackbar open={isMessageSuccess} autoHideDuration={6000} onClose={() => setMessageSuccess(false)}>
                <Alert onClose={() => setMessageSuccess(false)} severity={'success'}>
                    Your messages have been sent successfully!
                </Alert>
            </Snackbar>
        </>
    );
};

export default GroupChatDialog;