import React, {useState} from "react";

import API from "../../Global/API";
import DocumentType from "../../Models/DocumentType";
import DialogHeading from "../../Components/Typography/DialogHeading";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Select from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import FormControlLabel from "@mui/material/FormControlLabel";

/**
 * DocumentTypeForm component.
 *
 * @returns {*}
 * @constructor
 */
const DocumentTypeForm = (props) => {
    const {
        open,           // {Boolean} Indicates whether or not the form is open.
        record,         // {Object} The form context object, handles pre-loading the form.
        onSave,         // {Function} An onSave callback, triggered whenever the form is saved.,
        onClose,        // {Function} An onClose callback, triggered whenever the modal is closed.
    } = props;

    const [fields, setFields] = useState(DocumentType.getInstance(record));
    const [isLoading, setLoading] = useState(false);

    /**
     * Performs the save via the API.
     */
    const doSave = async () => {
        setLoading(true);

        const response = fields.id ?
            await API.put(`document-type/${fields.id}`, fields) :
            await API.post('document-types', fields);

        setLoading(false);

        if (onSave) {
            onSave(response);
        }
    };


    /**
     * Updates a particular form value.
     *
     * @param key
     * @param value
     */
    const setValue = (key, value) => {
        setFields({
            ...fields,
            [key]: value
        });
    };

    return (
        <Dialog
            open={open}
            scroll={'body'}
            onClose={onClose}
            maxWidth={'sm'}
            fullWidth
        >
            <DialogHeading
                title={'Document Type Details'}
                noMargin
            />
            <DialogContent>
                <Box className={'columns__1'}>
                    <TextField
                        value={fields.name}
                        label={'Name'}
                        required
                        disabled={isLoading}
                        onChange={event => setValue('name', event.target.value)}
                    />

                    {/*Only reveal the expiration options if we have an expiration enabled.*/}
                    {!!fields.hasExpiration && (
                        <Box className={'columns__2'}>
                            <TextField
                                type={'number'}
                                label={'Expiration Length'}
                                value={fields.expirationLength}
                                disabled={isLoading}
                                onChange={event => setValue('expirationLength', event.target.value)}
                            />
                            <FormControl fullWidth>
                                <InputLabel>Term</InputLabel>
                                <Select
                                    label={'Term'}
                                    value={fields.expirationTerm}
                                    onChange={event => setValue('expirationTerm', event.target.value)}
                                    children={
                                        ['Year', 'Day', 'Month', 'Week'].map((option, i) => (
                                            <MenuItem
                                                key={i}
                                                value={option}
                                                children={`${option}s`}
                                            />
                                        ))
                                    }
                                />
                            </FormControl>
                        </Box>
                    )}

                    <FormControlLabel
                        label="Should this document expire?"
                        control={
                            <Checkbox
                                sx={{paddingTop: 0, paddingBottom: 0}}
                                checked={!!fields.hasExpiration}
                                onChange={event => setValue('hasExpiration', event.target.checked)}
                            />
                        }
                    />
                    <FormControlLabel
                        label="Requirement for recruiting?"
                        control={
                            <Checkbox
                                sx={{paddingTop: 0, paddingBottom: 0}}
                                checked={!!fields.isRecruiting}
                                onChange={event => setValue('isRecruiting', event.target.checked)}
                            />
                        }
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={doSave}
                    children={'Save'}
                />
                <Button
                    color={'error'}
                    onClick={onClose}
                    children={'Close'}
                />
            </DialogActions>
        </Dialog>
    );
};

export default DocumentTypeForm;