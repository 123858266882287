import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import Client from "../../../Models/Client";
import {useAuth} from "../../../Global/Auth";
import ClientView from "../Client/ClientView";
import ModalButton from "../../../Components/ModalButton";
import ContactDetails from "../../../Components/Layouts/Profile/ContactsBlock/ContactDetails";
import ClientStatusIndicator from "../Client/ClientStatusIndicator";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import MenuItem from "@mui/material/MenuItem";

/**
 * AssignedClientsBlock component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const AssignedClientsBlock = (props) => {
    const {
        record
    } = props;

    const {hasPermissionTo} = useAuth();
    const [clients, setClients] = useState([]);
    const [isLoading, setLoading] = useState(false);

    /**
     * Loads any additional data on mount.
     */
    useEffect(() => {
        getAssignedClients();
    }, [record]);


    /**
     * Loads all of the assigned clients for this employee.
     *
     * @returns {Promise<void>}
     */
    const getAssignedClients = async () => {
        if (!record.id) {
            return;
        }

        setLoading(true);
        setClients(
            await API.get('clients', {
                $filter: `employeeClients/any{employeeId in {${record.id}}}`
            })
        );
        setLoading(false);
    };

    return (
        <div>
            <Paper>
                <Box className={'d-flex__justify'} sx={{paddingLeft: '1em', paddingRight: '0.5em', paddingTop: '1em', paddingBottom: '1em'}}>
                    <h3 className={'m__0'}>Clients</h3>
                </Box>
                <Divider/>
                <Box className={'columns__1'}>
                    {isLoading && !clients.length && (
                        <div className={'p__3'}>
                            <div><Skeleton/></div>
                        </div>
                    )}

                    {!isLoading && !clients.length && (
                        <div className={'p__3'}>
                            <div className={'text__center text__disclaimer'}>No results available.</div>
                        </div>
                    )}

                    {clients && !!clients.length && (
                        <Box>
                            {clients.map(record => (
                                <ModalButton
                                    id={`client-${record.id}`}
                                    key={`client-${record.id}`}
                                    bodyStyle={{
                                        paddingTop: 0
                                    }}
                                    className={'quick-search__result'}
                                    permission={'VIEW_CLIENTS'}
                                    label={
                                        <MenuItem disabled={!hasPermissionTo('VIEW_CLIENTS')}>
                                            <ContactDetails
                                                src={API.getFilePath(record.image)}
                                                name={record.name}
                                                icon={Client.icon()}
                                                actions={
                                                    <ClientStatusIndicator
                                                        record={record}
                                                        className={'mr__2'}
                                                    />
                                                }
                                                minWidth={'100%'}
                                                horizontal
                                                description={record.type || '-'}
                                            />
                                        </MenuItem>
                                    }
                                >
                                    <ClientView id={record.id}/>
                                </ModalButton>
                            ))}
                        </Box>
                    )}
                </Box>
            </Paper>
        </div>
    );
};

export default AssignedClientsBlock;