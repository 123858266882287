import React, {useState} from "react";

import API from "../../Global/API";
import Employee from "../../Models/Employee";
import TimeSheet from "../../Models/TimeSheet";
import InputFile from "../../Components/Input/InputFile";
import ModelSearch from "../../Components/Input/ModelSearch";
import DialogHeading from "../../Components/Typography/DialogHeading";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import {DateTimePicker} from "@mui/x-date-pickers";

/**
 * TimeSheetForm component.
 *
 * @returns {*}
 * @constructor
 */
const TimeSheetForm = (props) => {
    const {
        open,               // {Boolean} Indicates whether the form is open.
        record,             // {Object} The form context object, handles pre-loading the form.
        onSave,             // {Function} An onSave callback, triggered whenever the form is saved.,
        onClose,            // {Function} An onClose callback, triggered whenever the modal is closed.
    } = props;

    const [fields, setFields] = useState(TimeSheet.getInstance({
        effectiveDate: dayjs().format('YYYY-MM-DD HH:mm:ss'),
        ...record
    }));
    const [isLoading, setLoading] = useState(false);

    /**
     * Performs the save via the API.
     */
    const doSave = async () => {
        setLoading(true);

        const response = fields.id ?
            await API.put(`time-sheet/${fields.id}`, fields) :
            await API.post('time-sheets', fields);

        setLoading(false);

        if (onSave) {
            onSave(response);
        }
    };


    /**
     * Updates a particular form value.
     *
     * @param key
     * @param value
     */
    const setValue = (key, value) => {
        setFields({
            ...fields,
            [key]: value
        });
    };

    return (
        <Dialog
            open={open}
            scroll={'body'}
            onClose={onClose}
            maxWidth={'sm'}
            fullWidth
        >
            <DialogHeading
                title={'Time Sheet Details'}
                noMargin
            />
            <DialogContent>
                <Box className={'columns__1'}>
                    <ModelSearch
                        label={'Employee'}
                        value={fields.employeeId}
                        model={Employee}
                        onChange={value => setValue('employeeId', value)}
                        filterQuery={query => `isDeleted eq {0} and displayName eq {${query}}`}
                        renderLabel={option => !option ? '' : `${option.lastName}, ${option.firstName}`}
                    />
                    <DateTimePicker
                        label={'Effective Date'}
                        value={fields.effectiveDate ? dayjs(fields.effectiveDate) : null}
                        disabled={isLoading}
                        onChange={event => setValue('effectiveDate', event ? event.format('YYYY-MM-DD HH:mm:ss') : '')}
                    />
                    <InputFile
                        value={fields.file}
                        disabled={isLoading || !fields.employeeId}
                        onChange={value => setValue('file', value)}
                        helperText={!fields.employeeId ? 'Please select an employee above.' : ''}
                        uploadPath={`employees/${fields.employeeId}/time-sheets`}
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={doSave}
                    children={'Save'}
                />
                <Button
                    color={'error'}
                    onClick={onClose}
                    children={'Close'}
                />
            </DialogActions>
        </Dialog>
    );
};

export default TimeSheetForm;