import React from "react";

import Box from "@mui/material/Box";
import DialogTitle from "@mui/material/DialogTitle";

/**
 * DialogHeading component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const DialogHeading = (props) => {
    const {
        icon,               // {Component} An optional icon component to prepend within the heading.
        title,              // {String} The content of the heading.
        actions,            // {Component} An optional actions component to append within the heading.
        noMargin,           // {Boolean} Whether or not to disable the default margin.
    } = props;

    return (
        <DialogTitle className={'form__title'}>
            <Box className={`d-flex__justify ${!noMargin ? 'mb__3' : ''}`}>
                {title && <Box className={'d-flex__start'}>
                    {icon && <>{icon}</>}
                    {title}
                </Box>}
                {actions && <>{actions}</>}
            </Box>
        </DialogTitle>
    );
};

export default DialogHeading;