import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import State from "../../Global/State";
import CallLogFeed from "./CallLogFeed";

import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Popover from "@mui/material/Popover";
import PhoneIcon from '@mui/icons-material/Phone';
import IconButton from "@mui/material/IconButton";

/**
 * CallNotifications component.
 *
 * @returns {*}
 * @constructor
 */
const CallNotifications = (props) => {
    const [unseen, setHasUnseet] = useState(!!State.get('call-logs-unseen'));
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    /**
     * Initialize the timer loop on mount.
     */
    useEffect(() => {
        getUnreadCount();

        const interval = setInterval(() => getUnreadCount(), 5000);
        return () => clearInterval(interval);
    });


    /**
     * Emits the "Read All" event whenever the user opens up the list.
     */
    useEffect(() => {
        if (!unseen) {
            return;
        }

        if (!open) {
            getUnreadCount();
            return;
        }

        doSeeAll();
    }, [open]);


    /**
     * Loads the total count of unseen records.
     *
     * @returns {Promise<void>}
     */
    const getUnreadCount = async () => {
        if(open){
            return;
        }

        const results = await API.get('missed-call-logs/count');
        State.set('call-logs-unseen', !!results.count ? 'true' : '');
        setHasUnseet(!!results.count);
    };


    /**
     * Saves each call log as having been read by the user.
     *
     * @returns {Promise<void>}
     */
    const doSeeAll = async () => {
        await API.post('call-logs/read-all');
    };


    /**
     * Reveals the feed on click.
     *
     * @param event
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Hides the popover whenever users click out of the feed.
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                size={'large'}
                color={'inherit'}
                onClick={handleClick}
            >
                <Badge
                    color={'error'}
                    variant={'dot'}
                    children={
                        <PhoneIcon/>
                    }
                    invisible={!unseen}
                />
            </IconButton>

            <Popover
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box className={'notifications__feed'}>
                    <CallLogFeed
                        title={<h4>Recent Calls</h4>}
                    />
                </Box>
            </Popover>
        </>
    );
};

export default CallNotifications;