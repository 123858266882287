import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import Step from "../../Components/Step";
import Page from "../../Components/Page";
import {useAuth} from "../../Global/Auth";
import LogoHeading from "../../Components/Typography/LogoHeading";
import RecruitingSteps from "./Dashboard/RecruitingSteps";
import DirectDepositStep from "./HirePacket/DirectDepositStep";
import W4CertificateStep from "./HirePacket/W4CertificateStep";
import ResidencyDisclosureStep from "./HirePacket/ResidencyDisclosureStep";
import EmploymentEligibilityStep from "./HirePacket/EmploymentEligibilityStep";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router";
import LinearProgress from "@mui/material/LinearProgress";

/**
 * HirePacket component.
 *
 * @returns {*}
 * @constructor
 */
const HirePacket = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(0);
    const [index, setIndex] = useState(0);
    const {user, onAuthUpdate} = useAuth();
    const [employee, setEmployee] = useState({});

    /**
     * Load all required data on initial mount.
     */
    useEffect(() => {
        getEmployee();
    }, []);


    /**
     * Bind a scroll handler against the current step parameter.
     */
    useEffect(() => {
        handleScrollToTop();

        if (step === totalSteps) {
            handleEmployeeUpdate();
        }
    }, [step]);


    /**
     * The total number of steps, calculates the top progress bar.
     *
     * @type {number}
     */
    const totalSteps = 4;


    /**
     * Loads the employee record.
     *
     * @returns {Promise<void>}
     */
    const getEmployee = async () => {
        setEmployee(
            await API.get(`employees/${user.id}`)
        );
    };


    /**
     * Updates the employee record on completion.
     *
     * @returns {Promise<void>}
     */
    const handleEmployeeUpdate = async () => {
        if (!!employee.hirePacketDate) {
            return;
        }

        // Update the completion date and emit throughout the application.
        const response = await API.put(`employees/${employee.id}`, {
            hirePacketDate: dayjs().utc().format('YYYY-MM-DD HH:mm:ss')
        });

        onAuthUpdate({
            hirePacketDate: response.hirePacketDate
        });
    };


    /**
     * Scrolls the user to the top of the page.
     */
    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    return (
        <Page hideHeader fullScreen>
            <LinearProgress
                value={100 * (step / totalSteps)}
                variant={"determinate"}
                className={'mobile__progress'}
            />

            <Box className={'p__3 pt__0 columns__1'} key={index}>
                {step === 0 && (
                    <ResidencyDisclosureStep
                        step={step}
                        index={0}
                        employee={employee}
                        disabled={!!employee.hirePacketDate}
                        onValidate={() => setStep(1)}
                        onStepChange={setStep}
                        expectedKeys={[
                            'HP_RD_SIGNATURE',
                            'HP_RD_YEARS_IN_STATE',
                            'HP_RD_MONTHS_IN_STATE',
                            'HP_RD_CURRENT_ADDRESS',
                            'HP_RD_PREVIOUS_ADDRESS',
                        ]}
                        settingsPrefix={'HP_RD_'}
                    />
                )}

                {step === 1 && (
                    <W4CertificateStep
                        step={step}
                        index={1}
                        employee={employee}
                        disabled={!!employee.hirePacketDate}
                        onValidate={() => setStep(2)}
                        onStepChange={setStep}
                        expectedKeys={[
                            'HP_W4_CITY',
                            'HP_W4_STATE',
                            'HP_W4_TWO_JOBS',
                            'HP_W4_LAST_NAME',
                            'HP_W4_SIGNATURE',
                            'HP_W4_FIRST_NAME',
                            'HP_W4_POSTAL_CODE',
                            'HP_W4_NON_STANDARD',
                            'HP_W4_OTHER_TAXABLE',
                            'HP_W4_FILING_STATUS',
                            'HP_W4_ADDITIONAL_TAX',
                            'HP_W4_STREET_ADDRESS',
                            'HP_W4_MIDDLE_INITIAL',
                            'HP_W4_SOCIAL_SECURITY',
                            'HP_W4_APARTMENT_NUMBER',
                            'HP_W4_OTHER_DEPENDENTS',
                            'HP_W4_QUALIFYING_CHILDREN',
                        ]}
                        settingsPrefix={'HP_W4_'}
                    />
                )}

                {step === 2 && (
                    <DirectDepositStep
                        step={step}
                        index={2}
                        employee={employee}
                        disabled={!!employee.hirePacketDate}
                        onValidate={() => {
                            getEmployee();
                            setStep(3);
                        }}
                        onStepChange={setStep}
                        expectedKeys={[
                            'HP_DD_SIGNATURE',
                            'HP_DD_ACCT_A_TYPE',
                            'HP_DD_ACCT_B_TYPE',
                            'HP_DD_ACCT_A_ACCT',
                            'HP_DD_ACCT_B_ACCT',
                            'HP_DD_ACCT_A_AMOUNT',
                            'HP_DD_ACCT_B_AMOUNT',
                            'HP_DD_TOTAL_ACCOUNTS',
                            'HP_DD_ACCT_A_ROUTING',
                            'HP_DD_ACCT_B_ROUTING',
                            'HP_DD_ACCT_A_INSTITUTION',
                            'HP_DD_ACCT_B_INSTITUTION',
                        ]}
                        settingsPrefix={'HP_DD_'}
                    />
                )}

                {step === 3 && (
                    <EmploymentEligibilityStep
                        step={step}
                        index={3}
                        employee={employee}
                        disabled={!!employee.hirePacketDate}
                        onValidate={() => setStep(4)}
                        onStepChange={setStep}
                        expectedKeys={[
                            'HP_EE_CITY',
                            'HP_EE_STATE',
                            'HP_EE_LAST_NAME',
                            'HP_EE_SIGNATURE',
                            'HP_EE_FIRST_NAME',
                            'HP_EE_POSTAL_CODE',
                            'HP_EE_PHONE_NUMBER',
                            'HP_EE_WORK_EXP_DATE',
                            'HP_EE_WORK_A_NUMBER',
                            'HP_EE_DATE_OF_BIRTH',
                            'HP_EE_EMAIL_ADDRESS',
                            'HP_EE_MIDDLE_INITIAL',
                            'HP_EE_CITIZEN_STATUS',
                            'HP_EE_STREET_ADDRESS',
                            'HP_EE_WORK_I94_NUMBER',
                            'HP_EE_SOCIAL_SECURITY',
                            'HP_EE_APARTMENT_NUMBER',
                            'HP_EE_WORK_PASSPORT_NUMBER',
                        ]}
                        settingsPrefix={'HP_EE_'}
                    />
                )}

                <Step step={totalSteps} value={step}>
                    <LogoHeading title={'Thank You!'}/>

                    <Box className={'columns__1'}>
                        <Box>
                            <Box className={'mb__3'}>
                                Your hire packet has been completed successfully. Select your next step from the list
                                below, or use the "Home" button below to return to your dashboard.
                            </Box>

                            <RecruitingSteps/>
                        </Box>
                        <Button
                            variant={'outlined'}
                            onClick={() => navigate('/')}
                            children={'Home'}
                        />
                    </Box>
                </Step>
            </Box>
        </Page>
    );
};

export default HirePacket;