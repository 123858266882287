import React from "react";

import UserMenu from "./Navigation/UserMenu";
import MainMenu from "./Navigation/MainMenu";
import {useAuth} from "../Global/Auth";
import QuickSearch from "./Navigation/QuickSearch";
import SecondaryMenu from "./Navigation/SecondaryMenu";

import Box from "@mui/material/Box";
import AppBar from '@mui/material/AppBar';

/**
 * Navigation component.
 *
 * @returns {*}
 * @constructor
 */
const Navigation = () => {
    const {
        isScope,
        authenticated
    } = useAuth();

    return (
        <>
            <AppBar position="static" className={'navigation'} sx={{
                boxShadow: 'none',
                '& .MuiToolbar-root': {
                    minHeight: 'initial',
                },
            }}>
                <Box className={'columns__3'} sx={{
                    alignItems: 'center',
                    paddingLeft: '1em',
                    paddingRight: '1em',
                }}>
                    <MainMenu disabled={!authenticated} isParent/>

                    {authenticated && (
                        <>
                            {isScope('User') && (
                                <>
                                    <QuickSearch/>
                                    <UserMenu/>
                                </>
                            )}

                            {isScope('Employee') && (
                                <>
                                    <Box/>
                                    <UserMenu/>
                                </>
                            )}
                        </>
                    )}
                </Box>

                {isScope('User') && <SecondaryMenu/>}
            </AppBar>
        </>
    );
};

export default Navigation;