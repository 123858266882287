/**
 * Property class.
 */
class Property {
    /**
     * Property constructor.
     *
     * @param key
     * @param heading
     * @param faker
     * @param render
     * @param sortable
     * @param filterable
     * @param selectable
     */
    constructor(key, heading, faker, render, sortable, filterable, selectable) {
        this.key = '';
        this.label = '';
        this.width = null;
        this.align = 'left';
        this.faker = () => null;
        this.render = () => null;
        this.loader = () => null;
        this.sortable = false;
        this.hideLabel = false;
        this.selectable = true;
        this.filterable = false;
        this.filterType = '';

        // Filter customization options.
        this.renderLabel = null;
        this.filterIsEqual = null;
        this.filterResults = null;
        this.defaultFilterOptions = null;

        // Support for spread / object notation.
        if (key instanceof Object) {
            for (let i in key) {
                if (!key.hasOwnProperty(i)) {
                    continue;
                }

                this[i] = key[i];
            }

            return;
        }

        // Default for constructor parameters.
        this.key = key;
        this.label = heading;
        this.faker = faker;
        this.render = render;
        this.sortable = !!sortable;
        this.filterable = !!filterable;
        this.selectable = !!selectable;
    }


    /**
     * Creates a new property instance.
     *
     * @param params
     * @returns {Property}
     */
    static create(...params) {
        return new Property(...params);
    }


    /**
     * Indicates whether or not the property is sortable.
     *
     * @returns {*}
     */
    isSortable() {
        return !!this.sortable;
    }


    /**
     * Indicates whether or not the property is filterable.
     *
     * @returns {boolean}
     */
    isFilterable() {
        return !!this.filterable;
    }


    /**
     * Indicates whether or not the property is selectable.
     *
     * @returns {boolean}
     */
    isSelectable() {
        return !!this.selectable;
    }


    /**
     * Returns the unique model / column key.
     *
     * @returns {*}
     */
    getKey() {
        return this.key;
    }


    /**
     * Returns the table alignment.
     *
     * @returns {string}
     */
    getAlignment() {
        return this.align;
    }


    /**
     * Returns the user-friendly label.
     *
     * @returns {*}
     */
    getLabel() {
        return this.label || '';
    }


    /**
     * Returns the faker expression.
     *
     * @returns {*}
     */
    getFaker() {
        return this.faker;
    }


    /**
     * Executes the faker expression.
     *
     * @returns {*}
     */
    getFakeValue() {
        if (this.faker) {
            return this.faker();
        }

        return '';
    }


    /**
     * Returns the rendered column.
     *
     * @param record
     * @param doReload
     * @param onClick
     * @returns {*}
     */
    getRendered(record, doReload, onClick) {
        if (this.render) {
            return this.render(record, doReload, onClick);
        }

        return null;
    }


    /**
     * Returns the loading indicator.
     *
     * @returns {*}
     */
    getLoader() {
        if (this.loader) {
            return this.loader();
        }

        return null;
    }


    /**
     * Indicates whether or not to hide the column heading.
     *
     * @returns {boolean}
     */
    isLabelHidden(){
        return this.hideLabel;
    }
}

export default Property;