import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import State from "../../Global/State";
import NotificationFeed from "./NotificationFeed";

import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";
import NotificationsIcon from '@mui/icons-material/Notifications';

/**
 * NotificationBell component.
 *
 * @returns {*}
 * @constructor
 */
const NotificationBell = () => {
    const [unread, setUnread] = useState(State.get('notifications-unread') || 0);
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    /**
     * Initialize any additional functionality on mount.
     */
    useEffect(() => {
        getUnreadCount();
    }, []);


    /**
     * Loads the total count of unread records.
     *
     * @returns {Promise<void>}
     */
    const getUnreadCount = async () => {
        const results = await API.get('unread-notifications/count');
        setUnread(results.count);
        State.set('notifications-unread', results.count);
    };


    /**
     * Reveals the feed on click.
     *
     * @param event
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Hides the popover whenever users click out of the feed.
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <IconButton
                sx={{marginRight: '0.3em'}}
                size="large"
                color="inherit"
                onClick={handleClick}
            >
                {unread ? (
                    <Badge badgeContent={unread} color="error">
                        <NotificationsIcon/>
                    </Badge>
                ) : (
                    <NotificationsIcon/>
                )}
            </IconButton>

            <Popover
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box className={'notifications__feed'}>
                    <NotificationFeed
                        title={<h4>Notifications</h4>}
                    />
                </Box>
            </Popover>
        </>
    );
};

export default NotificationBell;