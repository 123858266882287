import React from "react";

import App from "../Global/App";
import Model from "../Definitions/Model";
import Property from "../Definitions/Property";
import Formatter from "../Global/Formatter";
import PropertyMap from "../Definitions/PropertyMap";
import TimeSheetDownload from "./TimeSheet/TimeSheetDownload";

import Box from "@mui/material/Box";
import UploadIcon from '@mui/icons-material/Upload';

/**
 * EmployeeTimeSheet model.
 *
 * @type {Object}
 */
const EmployeeTimeSheet = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'employee-time-sheet',


    /**
     * The appropriate API endpoint root.
     */
    route: 'time-sheets',


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'effectiveDate',
    ],


    /**
     * Returns any default query parameters.
     *
     * @returns {*|{}}
     */
    getQuery() {
        const user = App.getUser();
        const filter = this.getDefaultFilter();

        const query = {
            $filter: `employeeId in {${user.id}}`,
            $orderby: 'effectiveDate desc'
        };

        return filter && filter.query ? {
            ...query,
            ...filter.query
        } : query;
    },


    /**
     * Adjust the index layout accordingly.
     */
    tableOptions: {
        ...Model.tableOptions,
        hideTableHeader: true
    },


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'effectiveDate',
            label: 'Shift',
            render: (record) => (
                <Box className={'d-flex__center'} sx={{paddingTop: '0.5em', paddingBottom: '0.5em'}}>
                    <Box className={'d-flex__start'}>
                        <UploadIcon fontSize={'small'} className={'mr__1'}/>
                        {Formatter.dateTime(record.effectiveDate, '', true)}
                    </Box>

                    <TimeSheetDownload
                        path={record.file}
                        hideIcon
                        className={'text__small'}
                        hideDownload
                    />
                </Box>
            ),
            sortable: true
        })
    ])
};

export default EmployeeTimeSheet;