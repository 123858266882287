import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import Formatter from "../../Global/Formatter";
import InputPhone from "../../Components/Input/InputPhone";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Select from "@mui/material/Select";
import AddIcon from '@mui/icons-material/Add';
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import EditIcon from '@mui/icons-material/Edit';
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TextField from "@mui/material/TextField";
import ClearIcon from '@mui/icons-material/Clear';
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TableContainer from "@mui/material/TableContainer";

/**
 * ContactInput component.
 *
 * @returns {*}
 * @constructor
 */
const ContactInput = (props) => {
    const {
        record,         // {Object} The related record to link contacts against.
        onChange,       // {Function} Called whenever anything changes here.,
        disabled        // {Boolean} Whether we should disable the input.
    } = props;

    const [id, setId] = useState('');
    const [type, setType] = useState('');
    const [lastName, setLastName] = useState('');
    const [contacts, setContacts] = useState([]);
    const [isEditing, setEditing] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [internalId, setInternalId] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [emailAddress, setEmailAddress] = useState('');

    /**
     * Load any additional data on mount.
     */
    useEffect(() => {
        if(!record || !record.id){
            return;
        }

        getContacts();
    }, []);


    /**
     * Synchronize the related contacts.
     */
    useEffect(() => {
        if(onChange){
            onChange(contacts);
        }
    }, [contacts]);


    /**
     * Returns all non-deleted contacts.
     *
     * @type {*[]}
     */
    const nonDeletedContacts = contacts.filter(contact => !contact.isDeleted);


    /**
     * Returns a random string ID.
     *
     * @returns {string}
     */
    const getRandomId = () => {
        return (Math.random() + 1).toString(36).substring(7);
    };


    /**
     * Loads all related contacts via the API.
     *
     * @returns {Promise<void>}
     */
    const getContacts = async () => {
        if(!record || !record.id){
            return;
        }

        const results = await API.get('contacts', {
            $filter: `clientId in {${record.id}} and isDeleted eq {0}`
        });

        setContacts(results.map(result => {
            return {
                ...result,
                internalId: getRandomId()
            }
        }));
    };


    /**
     * Reveals the contact details form.
     */
    const handleFormOpen = (event, record) => {
        setId(record ? record.id : '');
        setType(record ? record.type : 'Administrator');
        setLastName(record ? record.lastName : '');
        setFirstName(record ? record.firstName : '');
        setInternalId(record ? record.internalId : '');
        setPhoneNumber(record ? record.phoneNumber : '');
        setEmailAddress(record ? record.emailAddress : '');

        setEditing(true);
    };


    /**
     * Closes the contact form.
     */
    const handleFormClose = () => {
        setEditing(false);
    };


    /**
     * Deletes a contact entry.
     *
     * @param contact
     */
    const doDelete = (contact) => {
        setContacts(
            contacts.map(record => record.internalId === contact.internalId ? {
                ...contact,
                ['isDeleted']: true
            } : record)
        );
    };


    /**
     * Handles the contact save.
     */
    const doSave = () => {
        const updated = {
            id,
            type,
            lastName,
            firstName,
            internalId,
            phoneNumber,
            emailAddress,
        };

        // Add or update accordingly.
        if(!internalId){
            // Generate an internal ID if we don't have one.
            if(!updated.internalId){
                updated.internalId = getRandomId();
            }

            setContacts([
                ...contacts,
                updated
            ])
        }
        else{
            setContacts(
                contacts.map(contact => contact.internalId === internalId ? updated : contact)
            )
        }

        handleFormClose();
    };

    return (
        <div className={'columns__1'}>
            <Box
                className={'form__heading form__heading--narrow'}
            >
                <Box className={'d-flex__justify'}>
                    <Box className={'flex__grow'}>Additional Contacts</Box>

                    <Box>
                        <IconButton
                            sx={{margin: 0}}
                            size={'small'}
                            onClick={handleFormOpen}
                            disabled={disabled}
                            children={
                                <AddIcon sx={{color: '#fff'}}/>
                            }
                        />
                    </Box>
                </Box>
            </Box>

            <TableContainer className={'table table--striped'}>
                <Table size={'small'}>
                    <colgroup>
                        <col width={'19%'}/>
                        <col width={'19%'}/>
                        <col width={'19%'}/>
                        <col width={'19%'}/>
                        <col width={'19%'}/>
                        <col/>
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell>First Name</TableCell>
                            <TableCell>Last Name</TableCell>
                            <TableCell>Email Address</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell/>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {nonDeletedContacts.map(contact => (
                            <TableRow key={contact.id}>
                                <TableCell>
                                    {contact.firstName}
                                </TableCell>
                                <TableCell>
                                    {contact.lastName}
                                </TableCell>
                                <TableCell>
                                    {Formatter.email(contact.emailAddress)}
                                </TableCell>
                                <TableCell>
                                    {Formatter.phone(contact.phoneNumber)}
                                </TableCell>
                                <TableCell>
                                    {contact.type}
                                </TableCell>
                                <TableCell align={'right'}>
                                    <Box sx={{whiteSpace: 'pre'}}>
                                        <IconButton
                                            size={'small'}
                                            onClick={event => handleFormOpen(event, contact)}
                                            disabled={disabled}
                                            children={<EditIcon/>}
                                        />
                                        <IconButton
                                            size={'small'}
                                            color={'error'}
                                            onClick={() => doDelete(contact)}
                                            disabled={disabled}
                                            children={<ClearIcon/>}
                                        />
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ))}

                        {!nonDeletedContacts.length && (
                            <TableRow>
                                <TableCell colSpan={6}>
                                    <Box className={'text__disclaimer text__center p__2'}>
                                        No results available.
                                    </Box>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog
                open={isEditing}
                scroll={'body'}
                onClose={handleFormClose}
                maxWidth={'md'}
                fullWidth
            >
                <DialogContent>
                    <Box className={'columns__1'}>
                        <InputLabel
                            children={'Contact Information'}
                            className={'form__heading form__heading--standalone'}
                        />

                        <Box className={'columns__3'}>
                            <TextField
                                value={firstName}
                                label={'First Name'}
                                required
                                onChange={event => setFirstName(event.target.value)}
                                fullWidth
                            />

                            <TextField
                                value={lastName}
                                label={'Last Name'}
                                required
                                onChange={event => setLastName(event.target.value)}
                                fullWidth
                            />

                            <FormControl>
                                <InputLabel>Type</InputLabel>
                                <Select
                                    value={type}
                                    label={'Type'}
                                    onChange={event => setType(event.target.value)}
                                >
                                    {['Administrator', 'Scheduler'].map((option) =>
                                        <MenuItem
                                            key={option}
                                            value={option}
                                            children={option}
                                        />
                                    )}
                                </Select>
                            </FormControl>
                        </Box>

                        <Box className={'columns__2'}>
                            <TextField
                                value={emailAddress}
                                label={'Email Address'}
                                onChange={event => setEmailAddress(event.target.value)}
                                fullWidth
                            />

                            <InputPhone
                                value={phoneNumber}
                                label={'Phone Number'}
                                onChange={event => setPhoneNumber(event.target.value)}
                                fullWidth
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={doSave}>Save</Button>
                    <Button onClick={handleFormClose} color={'error'}>Cancel</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default ContactInput;