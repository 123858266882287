import React, {useState} from "react";

import API from "../../../Global/API";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Avatar from "@mui/material/Avatar";
import {useNavigate} from "react-router";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

/**
 * Notification component.
 *
 * @returns {*}
 * @constructor
 */
const Notification = (props) => {
    const {
        record,         // {Object} The notification entry record.
        userId          // {String} The authenticated user ID.
    } = props;

    const navigate = useNavigate();


    /**
     * The underlying notification record.
     */
    const [notification, setNotification] = useState(record || {});


    /**
     * Returns the appropriate elapsed time label.
     *
     * @type {string}
     */
    const elapsedTimeLabel = (() => {
        let output = '';

        if (!record.createdDate) {
            return '';
        }

        const now = dayjs.utc();
        const comparator = dayjs.utc(record.createdDate);

        // Determine each descending unit increment.
        const days = Math.floor(now.diff(comparator, 'days', true));
        const weeks = Math.floor(now.diff(comparator, 'weeks', true));
        const hours = Math.floor(now.diff(comparator, 'hours', true));
        const months = Math.floor(now.diff(comparator, 'months', true));
        const minutes = Math.floor(now.diff(comparator, 'minutes', true));
        const seconds = Math.floor(now.diff(comparator, 'seconds', true));

        if (months) {
            let weeks = now.subtract(months, 'months').diff(comparator, 'weeks');
            output += `${months} ${months > 1 ? 'months' : 'month'}`;
            output += weeks ? `, ${weeks} ${weeks > 1 ? 'weeks' : 'week'}` : ``;
        } else if (weeks) {
            let days = now.subtract(weeks, 'weeks').diff(comparator, 'days');
            output += `${weeks} ${weeks > 1 ? 'weeks' : 'week'}`;
            output += days ? `, ${days} ${days > 1 ? 'days' : 'day'}` : ``;
        } else if (days) {
            let hours = now.subtract(days, 'days').diff(comparator, 'hours');
            output += `${days} ${days > 1 ? 'days' : 'day'}`;
            output += hours ? `, ${hours} ${hours > 1 ? 'hours' : 'hour'}` : ``;
        } else if (hours) {
            let minutes = now.subtract(hours, 'hours').diff(comparator, 'minutes');
            output += `${hours} ${hours > 1 ? 'hours' : 'hour'}`;
            output += minutes ? `, ${minutes} ${minutes > 1 ? 'minutes' : 'minute'}` : ``;
        } else if (minutes) {
            let seconds = now.subtract(minutes, 'minutes').diff(comparator, 'seconds');
            output += `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`;
            output += seconds ? `, ${seconds} ${seconds > 1 ? 'seconds' : 'second'}` : ``;
        } else {
            return `${seconds} ${seconds > 1 ? 'seconds' : 'second'} ago`;
        }

        return `${output} ago`
    })();


    /**
     * Indicates whether or not the current user has seen the notification.
     *
     * @type {boolean}
     */
    const seenByUser =
        notification.notificationReads &&
        notification.notificationReads.length &&
        !!notification.notificationReads.filter(read => read.userId === userId).length;


    /**
     * Requests an updated notification entry.
     *
     * @returns {Promise<void>}
     */
    const doUpdate = async () => {
        const result = await API.get(`notifications/${notification.id}`, {
            $expand: 'notificationReads'
        });

        setNotification(result);
    };


    /**
     * Handles the indicator lamp click.
     */
    const onIndicatorClick = async () => {
        if(seenByUser){
            await Promise.all(
                notification.notificationReads
                    .filter(read => read.userId === userId)
                    .map(read => API.delete(`notification-reads/${read.id}`))
            );
        }
        else{
            await API.post('notification-reads', {
                userId,
                notificationId: notification.id
            });
        }

        await doUpdate();
    };


    const handleRedirect = () => {
        if(!notification){
            return;
        }

        const {
            modelId,
            modelType,
        } = notification;

        if(!modelId || !modelType){
            return;
        }

        switch(modelType){
            case 'Employee':
                navigate(`/employees/${modelId}`)
                break;

            case 'Client':
                navigate(`/clients/${modelId}`)
                break;
        }
    };

    return (
        <div className={'d-flex notification__entry'}>
            <Box>
                <a
                    className={`notification__indicator ${!seenByUser ? `notification__indicator--highlight` : ``}`}
                    onClick={onIndicatorClick}
                />
            </Box>

            <Box className={'notification__wrapper'}>
                <Box className={`notification__body ${notification.containerClass ? `notification--${notification.containerClass}` : ''}`}>
                    <a onClick={handleRedirect}>
                        <Avatar
                            src={'/favicon.png'}
                            variant={'rounded'}
                            sx={{width: 32, height: 32}}
                        />
                    </a>

                    <Box sx={{flexGrow: 1, marginLeft: '1em'}}>
                        {notification.note}
                    </Box>
                </Box>
                <Box className={'notification__timestamp'}>
                    <AccessTimeIcon fontSize={'small'} sx={{marginRight: '0.2em'}}/> {elapsedTimeLabel}
                </Box>
            </Box>
        </div>
    );
};

export default Notification;