import React from "react";

import API from "../../../Global/API";
import {useAuth} from "../../../Global/Auth";
import Formatter from "../../../Global/Formatter";
import ImageWrapper from "../../ImageWrapper";
import usePhoneCall from "../../../Hooks/usePhoneCall";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Typography from "@mui/material/Typography";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';

/**
 * ChatThreadList component.
 *
 * @constructor
 */
const ChatThreadList = (props) => {
    const {
        onClick,                // {Function} The click event for a thread.
        chatThreads,            // {Array} An array of chat threads with all relevant relations.
    } = props;

    const {user, isScope} = useAuth();

    const {
        isMuted,
        phoneCall,
        phoneNumber: activePhoneNumber
    } = usePhoneCall();


    /**
     * Returns the appropriate relational key depending on the authenticated user.
     *
     * @type {string}
     */
    const relationKey = isScope('Employee') ? 'user' : 'employee';


    /**
     * Indicates whether the chat thread has a related department.
     *
     * @param chatThread
     * @returns {*}
     */
    const hasDepartment = (chatThread) => {
        return chatThread.department && chatThread.department.name;
    };


    /**
     * Returns the nice name of a user.
     *
     * @param user
     * @returns {string}
     */
    const getUserNiceName = (user) => {
        return user && user.firstName ? `${user.firstName} ${user.lastName ? `${user.lastName.charAt(0)}.` : ''}` : '(no name)';
    };


    /**
     * Indicates if there are unread messages.
     *
     * @param chatThread
     * @returns {number}
     */
    const hasUnread = (chatThread) => {
        if (isScope('User')) {
            return chatThread.messages.filter(message => message.recipientType === 'User' && message.recipientId === user.id && !message.isRead).length;
        }

        return chatThread.messages.filter(message => message.type === 'Chat' && message.recipientType === 'Employee' && message.recipientId === user.id && !message.isRead).length;
    };


    /**
     * Indicates if the chat thread recipient is the person we're speaking to.
     *
     * @param phoneNumber
     * @returns {boolean|Settings.activeCall|{}}
     */
    const isActiveCall = (phoneNumber) => {
        return isScope('User') &&
            phoneCall &&
            phoneNumber &&
            Formatter.e164(activePhoneNumber) === Formatter.e164(phoneNumber);
    };

    return chatThreads && chatThreads.length ? (
        <List>
            {chatThreads.map(chatThread => (
                <ListItem
                    disablePadding
                    secondaryAction={
                        <Box className={'d-flex__start'}>
                            {isActiveCall(chatThread[relationKey].phoneNumber) && (
                                <>
                                    {!isMuted ? (
                                        <LocalPhoneIcon
                                            className={'mr__1 text__light'}
                                        />
                                    ) : (
                                        <PhoneDisabledIcon
                                            className={'mr__1 text__light'}
                                        />
                                    )}
                                </>
                            )}

                            {hasUnread(chatThread) ? (
                                <Box
                                    sx={{pointerEvents: 'none', width: '0.5em', height: '0.5em', marginRight: '0.5em'}}
                                    className={'notification__indicator notification__indicator--highlight'}
                                />
                            ) : null}
                        </Box>
                    }
                >
                    <ListItemButton onClick={() => onClick(chatThread)} disableGutters>
                        <ListItemAvatar>
                            <ImageWrapper
                                src={API.getFilePath(chatThread[relationKey] && chatThread[relationKey].image ? chatThread[relationKey].image : '')}
                            />
                        </ListItemAvatar>
                        <ListItemText
                            primary={
                                <Box className={'d-flex__start'}>
                                    <b className={'mr__1'}>{isScope('User') ? `${chatThread[relationKey].firstName} ${chatThread[relationKey].lastName}` : getUserNiceName(chatThread[relationKey])}</b>

                                    {hasDepartment(chatThread) && (
                                        <Typography
                                            sx={{display: 'inline'}}
                                            color="text.primary"
                                            variant="body2"
                                            component="span"
                                            className={'chat-label__department'}
                                        >
                                            {` — `}
                                            {isScope('User') ? 'Assigned to ' : ''}{chatThread.department && chatThread.department.name ? chatThread.department.name : '(no department)'}
                                        </Typography>
                                    )}
                                </Box>
                            }
                            secondary={
                                <Box className={'text__small'}>
                                    {`Started ${Formatter.dateTime(chatThread.createdDate)}`}
                                </Box>
                            }
                        />
                    </ListItemButton>
                </ListItem>
            ))}
        </List>
    ) : null;
};

export default ChatThreadList;