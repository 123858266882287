import React from "react";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

/**
 * EmptyRow component.
 *
 * @param props
 * @returns {*}
 * @constructor
 *
 * @description Displayed whenever no results are available.
 */
const EmptyRow = (props) => {
    const {
        model,      // {Model} The target model to reference.
    } = props;

    const layout = model.getLayoutProperties();
    const actions = model.hasActions();

    return (
        <TableRow {...props}>
            <TableCell colSpan={layout.length + (!!actions ? 1 : 0)}>
                <div className={'text__center text__disclaimer p__3'}>No results available.</div>
            </TableCell>
        </TableRow>
    );
};

export default EmptyRow;