import React, {useRef, useState} from "react";

import API from "../../../Global/API";
import Logger from "../../../Global/Logger";
import Client from "../../../Models/Client";
import Employee from "../../../Models/Employee";
import ModelSearch from "../../../Components/Input/ModelSearch";
import SectionHeading from "../../../Components/Typography/SectionHeading";
import EmployeePreview from "./Components/EmployeePreview";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {DatePicker} from "@mui/x-date-pickers";
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";
import FormControlLabel from "@mui/material/FormControlLabel";

/**
 * The number of results or page to sequentially pull data.
 *
 * @type {number}
 */
const perPageLimit = 250;


/**
 * ShiftsByEmployee component.
 *
 * @returns {*}
 * @constructor
 */
const ShiftsByEmployee = (props) => {
    const {
        loading,            // {Boolean} Indicates if the report is loading.
        onComplete          // {Function} A post-completion callback from whenever the report has results.
    } = props;

    const resultsRef = useRef(null);
    const [clients, setClients] = useState([]);
    const [endDate, setEndDate] = useState(dayjs().endOf('day').format('YYYY-MM-DD'));
    const [isLoading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().subtract(1, 'day').startOf('day').format('YYYY-MM-DD'))
    const [showResults, setShowResults] = useState(false);
    const [employeeResults, setEmployeeResults] = useState([]);
    const [attributeFilters, setAttributeFilters] = useState({});

    /**
     * The attribute options available for this report.
     *
     * @type {Array<Object>}
     */
    const attributeOptions = [
        {
            label: 'Self-Scheduled (SS)',
            value: 'isSelfScheduled'
        }, {
            label: 'Client Cancelled (CC)',
            value: 'isClientCancelled'
        }, {
            label: 'Employee Called Off (ECO)',
            value: 'isEmployeeCancelled'
        }, {
            label: 'Employee Late (EL)',
            value: 'isEmployeeLate'
        }, {
            label: 'Employee NC/NS (NC/NS)',
            value: 'isEmployeeNoCall'
        }, {
            label: 'Employee Left Early (LE)',
            value: 'isEmployeeLeftEarly'
        }, {
            label: 'Not On Schedule (NOS)',
            value: 'isEmployeeNotOnSchedule'
        }, {
            label: 'Helix Self-Scheduled (HX)',
            value: 'isHelixSelfScheduled'
        }
    ];


    /**
     * Loads all of our client rows.
     *
     * @returns {Promise<void>}
     */
    const getEmployeesByAttribute = async (attribute) => {
        let promises = [];
        let attributeLabel = attributeOptions.find(option => option.value === attribute).label;

        // Determine the filter criteria.
        let eventFilters = ['isBlockRequested eq {0}'];
        eventFilters.push(endDate ? `startDate lt {${endDate}}` : ``);
        eventFilters.push(startDate ? `startDate gt {${startDate}}` : ``);

        if (clients && clients.length) {
            eventFilters.push(`clientId in {${clients.map(client => client.id).join(',')}}`);
        }

        if (attribute) {
            eventFilters.push(`${attribute} ne {0}`);
        }

        eventFilters = eventFilters.filter(filter => !!filter.trim());

        // Filter specific employees, if applicable.
        let employeeSelection = employees && employees.length ? ` and id in {${employees.map(employee => employee.id).join(',')}}` : '';

        // Load all the employees page by page until we've reached the end.
        const count = await API.get('employees/count', {
            $top: perPageLimit,
            $filter: `events/any{${eventFilters.join(' and ')}}${employeeSelection}`,
        });

        for (let page = 0; page < count.pages; page++) {
            promises.push(
                API.get('employees', {
                    $top: perPageLimit,
                    $skip: page * perPageLimit,
                    endDate,
                    $expand: 'specialty,status',
                    $select: '*,shiftsByAttribute',
                    $filter: `events/any{${eventFilters.join(' and ')}}${employeeSelection}`,
                    $orderby: `name asc`,
                    startDate,
                    attributes: JSON.stringify([attribute])
                })
            );
        }

        const results = await Promise.all(promises);
        Logger.debug(`[ShiftsByEmployee] Loaded employee results for attribute "${attribute}."`, results);
        return results.flat().map(employee => {
            const {shiftsByAttribute} = employee;

            return {
                ...employee,
                shiftsByAttribute: shiftsByAttribute.map(shift => {
                    return {
                        ...shift,
                        attribute: attributeLabel
                    };
                })
            };
        });
    };


    /**
     * Loads all of our report data.
     *
     * @returns {Promise<void>}
     */
    const getResults = async () => {
        setLoading(true);

        const results = await Promise.all(attributes.map(attribute => getEmployeesByAttribute(attribute)));
        const employees = {};

        /**
         * This report sequentially pulls employees that have shifts with a particular attribute. In order to capture
         * all employees with shifts covering all attributes, we need to pull the data for each, and merge into a uniform
         * format, while also merging each set of shifts per attribute.
         */
        results.flat().map(result => {
            const {id, shiftsByAttribute} = result;

            if (!employees[id]) {
                employees[id] = result;
            } else {
                employees[id].shiftsByAttribute = [
                    ...employees[id].shiftsByAttribute,
                    ...shiftsByAttribute
                ].sort((a, b) => dayjs(a.startDate).isAfter(dayjs(b.startDate)) ? 1 : -1)
            }
        });

        setEmployeeResults(Object.values(employees).sort((a, b) => {
            return (a.lastName > b.lastName) ? 1 : ((b.lastName > a.lastName) ? -1 : 0)
        }));

        setShowResults(true);
        setLoading(false);

        if (onComplete) {
            onComplete(resultsRef);
        }
    };


    /**
     * Assigns an attribute option for filtering.
     *
     * @param option
     * @param value
     */
    const setAttributeOption = (option, value) => {
        setAttributeFilters({
            ...attributeFilters,
            [option]: value
        });
    };


    /**
     * Indicates if the report is in progress.
     *
     * @type {boolean}
     */
    const inProgress = isLoading || loading;


    /**
     * A series of all selected attribute keys.
     *
     * @type {string[]}
     */
    const attributes = attributeOptions.map(option => option.value).filter(key => !!attributeFilters[key]);


    /**
     * Indicates whether there is a selection.
     *
     * @type {boolean}
     */
    const hasAttributeSelection = !!attributes && attributes.length > 0;


    /**
     * Indicates if all are selected.
     *
     * @type {boolean}
     */
    const allAttributesSelected = hasAttributeSelection && attributes.length === Object.keys(attributeFilters).length;


    /**
     * Indicates if the selection is indeterminate.
     *
     * @type {boolean}
     */
    const isAttributeIndeterminate = hasAttributeSelection && !allAttributesSelected;


    /**
     * Handles the select all event.
     */
    const handleSelectAll = () => {
        const options = {};
        const value = !allAttributesSelected;

        attributeOptions.map(option => {
            options[option.value] = value;
        });

        setAttributeFilters(options);
    };

    return (
        <Box className={'d-flex'}>
            <Box
                sx={{width: 400, overflowY: 'hidden'}}
                className={'border__right p__3 full__height--left-tabs'}
            >
                <Box className={'columns__1'}>
                    <Box className={'columns__2'}>
                        <DatePicker
                            label="Start Date"
                            value={startDate ? dayjs(startDate) : null}
                            disabled={inProgress}
                            onChange={event => setStartDate(event ? event.format('YYYY-MM-DD') : '')}
                            fullWidth
                        />

                        <DatePicker
                            label="End Date"
                            value={endDate ? dayjs(endDate) : null}
                            disabled={inProgress}
                            onChange={event => setEndDate(event ? event.format('YYYY-MM-DD') : '')}
                            fullWidth
                        />
                    </Box>

                    <ModelSearch
                        model={Employee}
                        value={employees}
                        label={'Employee(s)'}
                        multiple
                        disabled={inProgress}
                        onChange={setEmployees}
                        renderLabel={option => !option ? '' : option.displayName}
                        filterQuery={query => `isDeleted eq {0} and displayName eq {${query}}`}
                    />

                    <ModelSearch
                        model={Client}
                        value={clients}
                        label={'Client(s)'}
                        multiple
                        disabled={inProgress}
                        onChange={setClients}
                        helperText={'(Leave blank to include all.)'}
                        renderLabel={option => !option ? '' : option.name}
                        filterQuery={query => `isDeleted eq {0} and name eq {${query}}`}
                        queryParams={{
                            $top: 250,
                            $orderby: 'name asc'
                        }}
                    />

                    <Box>
                        <FormControlLabel
                            label={`Shift Filters (${attributes.length})`}
                            control={
                                <Checkbox
                                    checked={allAttributesSelected}
                                    onChange={handleSelectAll}
                                    indeterminate={isAttributeIndeterminate}
                                />
                            }
                        />
                        {attributeOptions.map(option => (
                            <Box className={'pl__3'}>
                                <FormControlLabel
                                    label={option.label}
                                    control={
                                        <Checkbox
                                            checked={!!attributeFilters[option.value]}
                                            onChange={event => setAttributeOption(option.value, event.target.checked)}
                                        />
                                    }
                                />
                            </Box>
                        ))}
                    </Box>

                    {!hasAttributeSelection && (
                        <Alert severity={'warning'}>
                            Please select at least one filter.
                        </Alert>
                    )}

                    <Button
                        variant={'outlined'}
                        onClick={getResults}
                        children={'Apply'}
                        disabled={inProgress || !hasAttributeSelection}
                    />
                </Box>
            </Box>

            <Box
                sx={{borderRadius: 0}}
                className={`flex__grow full__height--left-tabs ${!showResults ? 'well__container' : ''}`}
            >
                {inProgress && <LinearProgress sx={{width: '100%'}}/>}

                {!showResults && (
                    <Box className={'p__3 text__center text__disclaimer'}>Your results will display here.</Box>
                )}

                {showResults && (
                    <Box className={'p__3 columns__1'}>
                        <Box>
                            <SectionHeading title={`Employees (${employeeResults.length})`} primary/>
                            <TableContainer className={'table table--striped result__set'}>
                                <Table size={'small'}>
                                    <colgroup>
                                        <col width={'25%'}/>
                                        <col width={'75%'}/>
                                    </colgroup>
                                    <TableBody>
                                        {employeeResults.map(employee => {
                                            const shiftsByAttribute = employee.shiftsByAttribute || [];

                                            if (!shiftsByAttribute || !shiftsByAttribute.length) {
                                                return null;
                                            }

                                            return (
                                                <TableRow key={employee.id}>
                                                    <TableCell className={'v-align__top'} padding={'none'}>
                                                        <EmployeePreview
                                                            noCache
                                                            employee={employee}
                                                        />
                                                    </TableCell>
                                                    <TableCell padding={'none'}>
                                                        <Table>
                                                            <colgroup>
                                                                <col width={'33%'}/>
                                                                <col width={'33%'}/>
                                                                <col width={'33%'}/>
                                                            </colgroup>
                                                            <TableBody>
                                                                {shiftsByAttribute.map(shift => {
                                                                    const endDate = dayjs(shift.endDate).format('h:mm A');
                                                                    const startDate = dayjs(shift.startDate).format('MM/DD/YYYY h:mm A');

                                                                    return (
                                                                        <TableRow key={`shift-${shift.id}`}>
                                                                            <TableCell sx={{
                                                                                padding: '0.5em',
                                                                                background: 'none!important'
                                                                            }}>{shift.clientName}</TableCell>
                                                                            <TableCell sx={{
                                                                                padding: '0.5em',
                                                                                background: 'none!important'
                                                                            }}>{startDate} - {endDate}</TableCell>
                                                                            <TableCell sx={{
                                                                                padding: '0.5em',
                                                                                background: 'none!important'
                                                                            }}><b>{shift.attribute}</b>: {shift.notes}</TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                )}
            </Box>

            {/*The hidden display for PDF rendering.*/}
            <Box className={'d-hidden'}>
                <Box ref={resultsRef}>
                    <table className={'table--bordered'}>
                        <tbody>
                        <tr>
                            <td style={{width: '25%'}}>
                                <b>Employee</b>
                            </td>
                            <td style={{width: '25%'}}>
                                <b>Client</b>
                            </td>
                            <td style={{width: '25%'}}>
                                <b>Shift</b>
                            </td>
                            <td>
                                <b>Status</b>
                            </td>
                        </tr>

                        {employeeResults.map(employee => {
                            const shiftsByAttribute = employee.shiftsByAttribute || [];

                            if (!shiftsByAttribute || !shiftsByAttribute.length) {
                                return null;
                            }

                            return shiftsByAttribute.map((shift, i) => {
                                const endDate = dayjs(shift.endDate).format('h:mm A');
                                const startDate = dayjs(shift.startDate).format('MM/DD/YYYY h:mm A');

                                return (
                                    <tr key={`print-employee-${employee.id}-${shift.id}`}>
                                        <td
                                            style={{
                                                border: 'none',
                                                borderTop: !i ? '1px solid #ccc' : 'none',
                                                borderLeft: '1px solid #ccc',
                                                borderBottom: i === shiftsByAttribute.length - 1 ? '1px solid #ccc' : 'none',
                                                verticalAlign: 'top',
                                            }}
                                        >
                                            {!i && (
                                                <>{employee.lastName}, {employee.firstName}</>
                                            )}
                                        </td>
                                        <td>
                                            {shift.clientName}
                                        </td>
                                        <td>
                                            {startDate} - {endDate}
                                        </td>
                                        <td>
                                            <b>{shift.attribute}</b>: {shift.notes}
                                        </td>
                                    </tr>
                                )
                            });
                        })}
                        </tbody>
                    </table>
                </Box>
            </Box>
        </Box>
    );
};

export default ShiftsByEmployee;