import React, {useEffect, useMemo, useRef, useState} from "react";

import API from "../../Global/API";
import Logger from "../../Global/Logger";
import Settings from "../../Global/Settings";
import {useAuth} from "../../Global/Auth";
import Formatter from "../../Global/Formatter";
import DataTable from "../Layouts/Index/DataTable";
import InputImage from "../Input/InputImage";
import TextEditor from "../Input/TextEditor";
import ChatThread from "../../Models/ChatThread";
import CallButton from "./CommunicationFeed/CallButton";
import LoadingRow from "../../Pages/Admin/Schedule/LoadingRow";
import SmallButton from "./CommunicationFeed/SmallButton";
import ImageWrapper from "../ImageWrapper";
import usePhoneCall from "../../Hooks/usePhoneCall";
import CrumbWrapper from "../../Components/CrumbWrapper";
import MessageStream from "./CommunicationFeed/MessageStream";
import SectionHeading from "../Typography/SectionHeading";
import EmployeeListItem from "../Lists/EmployeeListItem";
import ChatThreadActions from "../../Actions/ChatThreadActions";

import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import List from "@mui/material/List";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import CloseIcon from '@mui/icons-material/Close';
import ButtonGroup from "@mui/material/ButtonGroup";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import CloudDoneIcon from "@mui/icons-material/CloudDone";
import LinearProgress from "@mui/material/LinearProgress";
import AttachmentIcon from "@mui/icons-material/AttachFile";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import CircularProgress from "@mui/material/CircularProgress";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

import {
    Grow,
    Popper,
    useTheme,
    useMediaQuery,
    ClickAwayListener,
} from "@mui/material";

/**
 * The number of results per page.
 *
 * @type {number}
 */
const perPage = 50;


/**
 * CommunicationFeed component.
 *
 * @constructor
 */
const CommunicationFeed = (props) => {
    const {
        onClose,                    // {Function} An optional close handler. If provided, will render an "X" button.
        condensed,                  // {Boolean} Whether to condense / minify the layout.
        hasUnread,                  // {Boolean} Indicates whether the user has unread messages.
        hideHeading,                // {Boolean} Optionally hides the "Here's what you missed..." heading.
        defaultSelection,           // {Object} An employee record to pre-select.
        hideEmployeeSelect          // {Boolean} Indicates whether to hide the left-side selection.
    } = props;

    const [tab, setTab] = useState('SMS');
    const {user, isScope} = useAuth();
    const [page, setPage] = useState(0);
    const [index, setIndex] = useState(0);
    const [search, setSearch] = useState('');
    const [subject, setSubject] = useState('');
    const [content, setContent] = useState('');
    const [targets, setTargets] = useState({});
    const [showAll, setShowAll] = useState(false);
    const [isLoaded, setLoaded] = useState(false);
    const {phoneCall, recipient} = usePhoneCall();
    const [isLoading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [attachment, setAttachment] = useState('');
    const [chatThread, setChatThread] = useState({});
    const [isCollapsed, setCollapsed] = useState(false);
    const [chatThreads, setChatThreads] = useState([]);
    const [isLoadingFile, setLoadingFile] = useState(false);
    const [isLoadingNext, setLoadingNext] = useState(false);
    const [templateIndex, setTemplateIndex] = useState('');
    const [isSendMenuOpen, setSendMenuOpen] = useState(false);
    const [phoneRecipient, setPhoneRecipient] = useState({});
    const [unreadMessages, setUnreadMessages] = useState([]);
    const [hasMoreResults, setHasMoreResults] = useState(true);
    const [selectedEmployee, setSelectedEmployee] = useState({});

    /**
     * Variables for rendering different layouts.
     *
     * @type {Theme}
     */
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));


    /**
     * The anchor point for the sub-menu in the "Send" button.
     *
     * @type {React.MutableRefObject<null>}
     */
    const sendButtonAnchorRef = useRef(null);


    /**
     * Load all unread messages initially.
     */
    useEffect(() => {
        getChatThreads();
        getUnreadMessages();

        if (defaultSelection && defaultSelection.id) {
            handleEmployeeSelect(defaultSelection);
        }
    }, []);


    /**
     * Refresh data on tab change.
     */
    useEffect(() => {
        setSubject('');
        setContent('');
        setAttachment('');
        setTemplateIndex('');
        setTargets({
            [tab]: true
        });
    }, [tab]);


    /**
     * Causes a reload on the messages whenever we have a new unread message.
     */
    useEffect(() => {
        if (!hasUnread) {
            return;
        }

        setIndex(index + 1);
    }, [hasUnread]);


    /**
     * Selects the employee as a call target.
     */
    useEffect(() => {
        if (!phoneCall) {
            return setPhoneRecipient({});
        }

        if (recipient && recipient['@type'] === 'Employee') {
            return setPhoneRecipient(recipient);
        }

        setPhoneRecipient(selectedEmployee);
    }, [phoneCall, recipient]);


    /**
     * Loads all required component information on mount.
     */
    useEffect(() => {
        getTemplates();
    }, [selectedEmployee, tab]);


    /**
     * Handle employee loading / searching, etc.
     */
    useEffect(() => {
        getEmployees();
    }, [page, search]);


    /**
     * Indicates if the send fields are deleted.
     *
     * @type {boolean}
     */
    const isDisabled = isLoading || !selectedEmployee.id || !!chatThread.isDeleted;


    /**
     * Indicates whether there is an employee selected or not.
     *
     * @type {Boolean}
     */
    const hasSelection = selectedEmployee && selectedEmployee.id;


    /**
     * The dynamic content for the "Send" button.
     *
     * @type {number}
     */
    const nonTabFilters = Object.keys(targets).filter(key => key !== tab && !!targets[key]).length;
    const sendButtonLabel = `Send${nonTabFilters ? ` (${nonTabFilters + 1})` : ''}`;


    /**
     * Loads templates for the current tab.
     *
     * @returns {Promise<void>}
     */
    const getTemplates = async () => {
        if (!selectedEmployee || !selectedEmployee.id) {
            return setTemplates([]);
        }

        const field = tab === 'SMS' ? 'isSms' : (
            tab === 'Chat' ? 'isChat' : 'isEmail'
        );

        setTemplates(
            await API.get('templates', {
                $top: 100,
                $filter: `${field} eq {1} and isDeleted eq {0}`
            })
        );
    };


    /**
     * Loads all available employees.
     *
     * @returns {Promise<void>}
     */
    const getEmployees = async () => {
        Logger.debug(`[Schedule] Loading employee results for page ${page}...`);

        const results = await API.get('employees', {
            $top: perPage,
            $skip: page * perPage,
            $filter: `isDeleted eq {0} and displayName eq {${search}}`,
            $expand: 'specialty,status',
            $orderby: 'lastName asc'
        });

        if (!results.length || results.length < perPage) {
            setHasMoreResults(false);
        }

        setEmployees([
            ...employees,
            ...results
        ]);

        setLoaded(true);
        setLoadingNext(false);
    };


    /**
     * Loads all active chat threads for the current user.
     *
     * @returns {Promise<void>}
     */
    const getChatThreads = async () => {
        const results = await API.get('chat-threads', {
            $top: 25,
            $expand: 'department,employee,user,messages($orderby=createdDate desc;$top=10)',
            $filter: `userId in {${user.id}} and isDeleted eq {0}`,
            $orderby: 'updatedDate desc'
        });

        Logger.debug(`[CommunicationFeed] Loaded chat thread results`, results);
        setChatThreads(results);
    };


    /**
     * Handles the unread message retrieval.
     *
     * @returns {Promise<void>}
     */
    const getUnreadMessages = async () => {
        setUnreadMessages(
            await API.get('messages', {
                $top: 250,
                $filter: `isRead eq {0} and recipientId in {${user.id}} and recipientType in {User}`
            })
        )
    };


    /**
     * Updates a single property of one of the loaded employees.
     *
     * @param employee
     * @param key
     * @param value
     */
    const setEmployeeProperty = (employee, key, value) => {
        for (let i = 0; i < employees.length; i++) {
            const current = employees[i];

            if (current.id !== employee.id) {
                continue;
            }

            current[key] = value;
            break;
        }

        setEmployees([...employees]);
    };


    /**
     * Attempts to find a conversation match for the selected thread.
     *
     * @returns {Promise<void>}
     */
    const getThreadPrediction = async () => {
        if (!chatThread || !chatThread.id) {
            return;
        }

        setLoading(true);
        const response = await API.post('ai/prompt', {
            messageType: tab,
            chatThreadId: chatThread.id
        });
        setLoading(false);

        if (response.status === 'error') {
            return;
        }

        setContent(response.message);
    };


    /**
     * Handles tab changes on the scheduler.
     *
     * @param tab
     */
    const handleTabChange = (tab) => {
        setTab(tab);
        setLoading(true);
        getUnreadMessages();
    };


    /**
     * Updates the left selection based on the search criteria.
     *
     * @param event
     */
    const handleSearchChange = event => {
        setSearch(event.target.value);
        handleRefresh(true, true);
    };


    /**
     * Handles tab changes on the scheduler.
     *
     * @param keepSelection
     * @param keepSearch
     */
    const handleRefresh = (keepSelection, keepSearch) => {
        setPage(0);
        setLoaded(false);
        setLoadingNext(false);
        setHasMoreResults(true);

        if (!keepSearch) {
            setSearch('');
        }

        setEmployees([]);

        if (!keepSelection) {
            setSelectedEmployee({});
        }
    };


    /**
     * Toggles an employee for selection.
     *
     * @param employee
     */
    const handleEmployeeSelect = async (employee) => {
        if (hasSelection && selectedEmployee.id === employee.id) {
            return setSelectedEmployee({});
        }

        // Attempt to find an existing thread with this person.
        const matchedThreads = await API.get('chat-threads', {
            $top: 1,
            $filter: `isDeleted eq {0} and userId in {${user.id}} and employeeId in {${employee.id}}`,
            $expand: 'department,employee,user,messages($orderby=createdDate desc;$top=1)',
            $orderby: 'updatedDate desc'
        });

        setIndex(index + 1);
        setLoading(true);
        setChatThread(matchedThreads && matchedThreads.length ? matchedThreads[0] : {});
        setSelectedEmployee(employee);
    };


    /**
     * Handles data loading on scroll.
     *
     * @param event
     */
    const handleContainerScroll = event => {
        const el = event.target;
        const threshold = 600;

        if (isLoadingNext) {
            return;
        }

        if ((el.scrollTop + threshold) <= (el.scrollHeight - el.offsetHeight)) {
            return;
        }

        if (hasMoreResults) {
            setLoadingNext(true);
            setPage(page + 1);
        }
    };


    /**
     * Sends a message via the API.
     *
     * @returns {Promise<void>}
     */
    const handleMessageSend = async () => {
        if (!content) {
            return;
        }

        setLoading(true);

        const payload = {
            type: tab,
            content,
            subject,
            senderId: user.id,
            attachment,
            templateId: templateIndex !== '' && templates[templateIndex] ? templates[templateIndex].id : null,
            senderType: 'User',
            recipientId: selectedEmployee.id,
            chatThreadId: chatThread.id || null,
            recipientType: 'Employee',
        };

        const response = await API.post(`messages`, payload);

        // Send the ancillary types as well.
        const ancillary = Object.keys(targets).filter(key => key !== tab && !!targets[key]).map(key => key);

        if(ancillary && ancillary.length){
            for(let i = 0; i < ancillary.length; i++){
                const key = ancillary[i];

                /**
                 * This was formerly an await Promise.all([...]), however doing so appears to
                 * create lock-out issues in the DB. As an alternative, we'll just sequentially
                 * fire off each communication.
                 */
                await API.post(`messages`, {
                    ...payload,
                    type: key
                });
            }
        }

        if (!chatThread || !chatThread.id) {
            setChatThread({
                id: response.chatThreadId,
                userId: user.id,
                employeeId: selectedEmployee.id,
            });
        }
        else if(!!chatThread.isSnoozed){
            setChatThread({
                ...chatThread,
                isSnoozed: false
            });
        }

        setIndex(index + 1);
        setContent('');
        setAttachment('');

        setTargets({
            [tab]: true
        });
    };


    /**
     * Renders the selected template.
     *
     * @param event
     * @param index
     * @returns {Promise<void>}
     */
    const getTemplate = async (event, index) => {
        const template = templates[index];
        setTemplateIndex(index);

        if (!template) {
            return;
        }

        const response = await API.post('render', {
            modelId: selectedEmployee.id,
            modelType: 'Employee',
            templateId: template.id,
            expectedFormat: tab,
        });

        if (response.status === 'success') {
            setSubject(response.subject);
            setContent(response.template);
        }
    };


    /**
     * Called after reassigning is completed.
     */
    const handleReassign = () => {
        setChatThread({});
        setSelectedEmployee({});
        getChatThreads();
    };


    /**
     * Updates the send targets.
     *
     * @param key
     * @param value
     */
    const setTarget = (key, value) => {
        setTargets({
            ...targets,
            [key]: value
        });
    };


    /**
     * Processes the chat thread selection event.
     *
     * @param chatThread
     */
    const handleChatThreadClick = (chatThread) => {
        setChatThread(chatThread);
        setSelectedEmployee(chatThread.employee);
    };


    /**
     * Toggles the send menu options.
     */
    const handleMessageOptionsToggle = () => {
        setSendMenuOpen(isSendMenuOpen => !isSendMenuOpen);
    };


    /**
     * Closes the send menu.
     *
     * @param event
     */
    const handleSendMenuClose = event => {
        if (sendButtonAnchorRef.current && sendButtonAnchorRef.current.contains(event.target)) {
            return;
        }

        setSendMenuOpen(false);
    };


    /**
     * The "Send" button action menu.
     *
     * @type {React.JSX.Element}
     */
    const sendMenu = (
        <Popper
            sx={{
                zIndex: 1,
            }}
            role={undefined}
            open={isSendMenuOpen}
            anchorEl={sendButtonAnchorRef.current}
            transition
            disablePortal
        >
            {({TransitionProps, placement}) => (
                <Grow
                    {...TransitionProps}
                    style={{
                        transformOrigin:
                            placement === 'bottom' ? 'center top' : 'center bottom',
                    }}
                >
                    <Paper>
                        <ClickAwayListener onClickAway={handleSendMenuClose}>
                            <MenuList sx={{minWidth: 175}} autoFocusItem>
                                <MenuItem disabled>
                                    Send to...
                                </MenuItem>
                                {['SMS', 'Chat', 'Email'].map(option => (
                                    <MenuItem disableGutters dense disabled={tab === option}
                                        onClick={() => setTarget(option, !targets[option])}>
                                        <ListItemIcon>
                                            <Checkbox
                                                checked={!!targets[option]}
                                                onChange={() => setTarget(option, !targets[option])}
                                                disableRipple
                                            />
                                        </ListItemIcon>
                                        <ListItemText>{option}</ListItemText>
                                    </MenuItem>
                                ))}
                                <Divider/>
                                <MenuItem
                                    onClick={getThreadPrediction}
                                    disabled={isLoading}
                                    children={'AI Prediction'}
                                />
                            </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    );


    /**
     * The chat buttons to send, attach files, etc.
     *
     * @type {React.JSX.Element}
     */
    const fileIconAttached = isLoadingFile ? <CircularProgress size={24}/> : <CloudDoneIcon/>;
    const fileIconEmpty = isLoadingFile ? <CircularProgress size={24}/> : <AttachmentIcon/>;
    const chatButtons = (
        <>
            {tab !== 'Email' && (
                <Box
                    sx={!isMobile ? {
                        width: nonTabFilters ? '10em' : '8em'
                    } : {}}
                    className={!isMobile ? 'columns__1 columns--small' : 'd-flex__justify'}
                >
                    <ButtonGroup
                        ref={sendButtonAnchorRef}
                        variant={'outlined'}
                        className={isMobile ? 'w__100 mr__3' : ''}
                    >
                        <Button
                            onClick={handleMessageSend}
                            children={sendButtonLabel}
                            disabled={isDisabled}
                            className={'flex__grow'}
                        />

                        <Button
                            size={'small'}
                            onClick={handleMessageOptionsToggle}
                            disabled={isDisabled}
                            children={<ArrowDropDownIcon/>}
                        />
                    </ButtonGroup>

                    {sendMenu}

                    <InputImage
                        editable
                        children={
                            attachment ? (
                                <Button
                                    color={'success'}
                                    variant={'outlined'}
                                    disabled={isDisabled}
                                    children={!isMobile ? 'Attached!' : fileIconAttached}
                                    startIcon={!isMobile ? fileIconAttached : null}
                                    className={'message-attachment__upload'}
                                />
                            ) : (
                                <Button
                                    variant={'outlined'}
                                    disabled={isDisabled}
                                    children={!isMobile ? 'Add File' : fileIconEmpty}
                                    startIcon={!isMobile ? fileIconEmpty : null}
                                    className={'message-attachment__upload'}
                                />
                            )
                        }
                        onChange={setAttachment}
                        doLoading={setLoadingFile}
                        className={!isMobile ? 'columns__1' : ''}
                        uploadPath={`chat-threads/${chatThread.id}`}
                    />
                </Box>
            )}
        </>
    );


    /**
     * Collapses the input controls.
     */
    const handleCollapse = () => {
        setCollapsed(!isCollapsed);
    };


    /**
     * The thread list content on the overview.
     *
     * @type {unknown}
     */
    const threadList = useMemo(() => {
        const query = ChatThread.getQuery();

        // Ignore snoozed and deleted if only showing active recent.
        if(!showAll){
            query.$filter = `${query.$filter} and isDeleted eq {0} and isSnoozed eq {0}`;
        }

        return (
            <DataTable
                key={`threads-${index}-${showAll ? 'all' : 'recent'}`}
                model={ChatThread}
                query={query}
                actions={!!ChatThread.action}
                onClick={handleChatThreadClick}
                doReload={!showAll ? () => {
                    setIndex(index + 1);
                } : null}
                hideHeader
            />
        );
    }, [showAll, index]);


    /**
     * All the bottom controls for writing messages, etc.
     *
     * @type {React.JSX.Element}
     */
    const bottomControls = (
        <Box className={'communication__controls'}>
            <Box className={'communication__collapse'}>
                <a onClick={handleCollapse}>
                    <Paper sx={{
                        padding: '0.5em',
                        paddingBottom: 0
                    }}>
                        {isCollapsed ? (
                            <ExpandLessIcon/>
                        ) : (
                            <ExpandMoreIcon/>
                        )}
                    </Paper>
                </a>
            </Box>

            {!isCollapsed && (
               <>
                   <Box className={'border__bottom'}>
                       {!!templates.length && !chatThread.isDeleted && (
                           <Tabs
                               value={templateIndex}
                               variant={'scrollable'}
                               onChange={getTemplate}
                               scrollButtons
                               allowScrollButtonsMobile
                           >
                               {templates.map(template => (
                                   <Tab label={template.name} key={template.id}/>
                               ))}
                           </Tabs>
                       )}
                   </Box>
                   <Box className={'d-flex__justify p__3'}>
                       <Box className={`flex__grow ${tab !== 'Email' && !isMobile ? 'mr__3' : ''}`}>
                           <Box className={'columns__1'}>
                               {tab === 'Email' ? (
                                   <>
                                       <Box className={'d-flex__justify'}>
                                           <TextField
                                               value={subject}
                                               label={'Subject'}
                                               disabled={isDisabled}
                                               onChange={event => setSubject(event.target.value)}
                                               fullWidth
                                               className={'mr__3'}
                                               InputProps={{spellCheck: true}}
                                           />

                                           <Button
                                               variant={'outlined'}
                                               onClick={handleMessageSend}
                                               children={'Send'}
                                               disabled={isDisabled}
                                           />
                                       </Box>

                                       <TextEditor
                                           value={content}
                                           height={250}
                                           disabled={isDisabled}
                                           onChange={(value) => setContent(value)}
                                       />
                                   </>
                               ) : (
                                   <>
                                       <TextField
                                           rows={3}
                                           value={content}
                                           label={chatThread.isDeleted ? 'This chat thread has been deleted.' : 'Write a message...'}
                                           disabled={isDisabled}
                                           onChange={event => setContent(event.target.value)}
                                           multiline
                                           fullWidth
                                           className={'chat__textarea'}
                                           InputProps={{spellCheck: true}}
                                       />
                                   </>
                               )}
                           </Box>
                       </Box>

                       {!isMobile && (
                           <>{chatButtons}</>
                       )}
                   </Box>

                   {isMobile && (
                       <Box
                           sx={{
                               paddingTop: 0
                           }}
                           className={'p__3'}
                       >{chatButtons}</Box>
                   )}
               </>
            )}
        </Box>
    );


    /**
     * Displayed whenever no results are available.
     *
     * @returns {*}
     * @constructor
     */
    const NoResultsListItem = () => {
        return (
            <MenuItem>
                <Box
                    sx={{
                        width: '100%',
                        paddingTop: '0.2em',
                        paddingBottom: '0.2em'
                    }}
                    className={'d-flex__start'}
                >
                    {search ? `No results for "${search}"...` : `No results available.`}
                </Box>
            </MenuItem>
        );
    };

    return (
        <>
            <Box className={'d-flex'}>
                {!hideEmployeeSelect && isDesktop && (
                    <Box className={'two-column__border-right'}>
                        <Box sx={{width: Settings.drawerWidth}}>
                            <Box className={'columns__1 p__3'}>
                                <TextField
                                    value={search}
                                    label={'Search...'}
                                    onChange={handleSearchChange}
                                    fullWidth
                                />
                            </Box>
                            <Divider/>
                            <Box
                                sx={{
                                    position: 'relative'
                                }}
                                onScroll={handleContainerScroll}
                                className={'two-column__left-results'}
                            >
                                <List dense sx={{width: '100%'}}>
                                    {!isLoaded && [...Array(10).keys()].map((item, i) => (
                                        <LoadingRow key={i}/>
                                    ))}

                                    {isLoaded && (
                                        <>
                                            {employees.map(employee => {
                                                const isSelected = selectedEmployee.id === employee.id;

                                                return (
                                                    <EmployeeListItem
                                                        employee={employee}
                                                        selected={isSelected}
                                                        onSelect={handleEmployeeSelect}
                                                        onChange={setEmployeeProperty}
                                                        actionSlot={unreadMessages.filter(message => message.senderId === employee.id).length ? (
                                                            <Box
                                                                sx={{
                                                                    width: '0.5em',
                                                                    height: '0.5em',
                                                                    marginRight: '0.5em',
                                                                    pointerEvents: 'none'
                                                                }}
                                                                className={'notification__indicator notification__indicator--highlight'}
                                                            />
                                                        ) : null}
                                                    />
                                                );
                                            })}

                                            {!employees.length && <NoResultsListItem/>}

                                            {/*Loading buffer for additional results.*/}
                                            {hasMoreResults && [...Array(5).keys()].map((item, i) => {
                                                return (
                                                    <LoadingRow key={i}/>
                                                );
                                            })}
                                        </>
                                    )}
                                </List>
                            </Box>
                        </Box>
                    </Box>
                )}

                <Box className={`two-column__right--natural`}>
                    {hasSelection && (
                        <>
                            <Box className={'border__bottom'} align={'center'}>
                                <CrumbWrapper
                                    value={tab}
                                    options={[
                                        'SMS',
                                        'Chat',
                                        'Email',
                                    ]}
                                    onChange={handleTabChange}
                                    controlSlot={
                                        !condensed ? (
                                            <SmallButton
                                                onClick={() => {
                                                    setSelectedEmployee({});
                                                    getChatThreads();
                                                }}
                                                children={
                                                    <>
                                                        <NavigateBeforeIcon
                                                            sx={{fontSize: '1.2em', marginTop: '0.1em'}}
                                                        /> Back
                                                    </>
                                                }
                                            />
                                        ) : (
                                            onClose ? (
                                                <SmallButton
                                                    onClick={onClose}
                                                    children={
                                                        <CloseIcon fontSize={'inherit'}/>
                                                    }
                                                />
                                            ) : null
                                        )
                                    }
                                    className={'d-flex__justify communication__tabs'}
                                    renderLabel={option => {

                                        // Ignore if we're currently looking at the tab.
                                        if (tab === option) {
                                            return option;
                                        }

                                        const isHighlighted = !!unreadMessages.filter(message => message.senderId === selectedEmployee.id && message.type === option).length;

                                        if (isHighlighted) {
                                            return (
                                                <Box className={'d-flex__start'}>
                                                    {option}

                                                    <Box
                                                        sx={{
                                                            width: '0.5em',
                                                            height: '0.5em',
                                                            marginLeft: '0.5em',
                                                            pointerEvents: 'none',
                                                        }}
                                                        className={'notification__indicator notification__indicator--highlight'}
                                                    />
                                                </Box>
                                            );
                                        }

                                        return option;
                                    }}
                                />
                            </Box>

                            {isLoading && <LinearProgress sx={{width: '100%'}}/>}

                            <Box className={'p__3 border__bottom'}>
                                <Box className={'d-flex__start'}>
                                    <ImageWrapper
                                        src={API.getFilePath(selectedEmployee.image || '')}
                                    />

                                    <Box className={'ml__2 flex__grow d-flex__justify'}>
                                        <Box>
                                            <Box>
                                                <b>{selectedEmployee.firstName} {selectedEmployee.lastName}</b>

                                                {!!chatThread.isSnoozed ? (
                                                    <Chip
                                                        size={'small'}
                                                        label={'Snoozed'}
                                                        color={'info'}
                                                        className={'ml__2 mb__1'}
                                                    />
                                                ) : (
                                                    <>
                                                        {!!chatThread.isDeleted && (
                                                            <Chip
                                                                size={'small'}
                                                                color={'error'}
                                                                label={'Deleted'}
                                                                className={'ml__2 mb__1'}
                                                            />
                                                        )}
                                                    </>
                                                )}
                                            </Box>

                                            {isMobile ? (
                                                <div className={'text__small text__light'}>
                                                    Since {Formatter.date(selectedEmployee.createdDate)}
                                                </div>
                                            ) : (
                                                <div className={'text__small text__light'}>
                                                    Member since {Formatter.date(selectedEmployee.createdDate)}
                                                </div>
                                            )}
                                        </Box>

                                        {isScope('User') && (
                                            <Box className={'d-flex__start'}>
                                                <CallButton
                                                    key={`call-${selectedEmployee.id}`}
                                                    className={'mr__2'}
                                                    attributes={{
                                                        selectedEmployee,
                                                        recipient: {
                                                            ...selectedEmployee,
                                                            '@type': 'Employee'
                                                        }
                                                    }}
                                                    phoneNumber={selectedEmployee.phoneNumber}
                                                />

                                                {!isMobile && (
                                                    <ChatThreadActions
                                                        record={chatThread}
                                                        doReload={record => setChatThread({
                                                            ...chatThread,
                                                            ...record
                                                        })}
                                                        employee={selectedEmployee}
                                                        onReassign={handleReassign}
                                                    />
                                                )}
                                            </Box>
                                        )}
                                    </Box>
                                </Box>
                            </Box>

                            <MessageStream
                                key={`${selectedEmployee.id || 0}-${tab}-${index}`}
                                record={selectedEmployee}
                                senderId={user.id}
                                senderType={'User'}
                                hideSpacer={isCollapsed}
                                onLoadDone={() => setLoading(false)}
                                messageType={tab}
                                chatThreadId={chatThread.id || ''}
                                hasTemplates={!!templates.length}
                                recipientType={'Employee'}
                            />

                            {bottomControls}
                        </>
                    )}

                    {!hasSelection && (
                        <>
                            <Box className={'communication__overview'}>
                                {!hideHeading && (
                                    <>
                                        <h3 className={'mt__0'}>📰 Here's what you missed...</h3>
                                        <SectionHeading
                                            title={'Recent Threads'}
                                            action={
                                                <SmallButton
                                                    onClick={() => setShowAll(!showAll)}
                                                    children={!showAll ? 'View All' : 'Hide Inactive'}
                                                    className={'text__small text__thin'}
                                                />
                                            }
                                        />
                                    </>
                                )}

                                <Box className={'communication__threads'}>
                                    {threadList}
                                </Box>

                                {!chatThreads.length && (
                                    <Box className={'p__3 text__center text__disclaimer'}>
                                        No threads available.
                                    </Box>
                                )}
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default CommunicationFeed;