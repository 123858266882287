import React from "react";

import Page from "../../Components/Page";
import CommunicationFeed from "../../Components/Widgets/CommunicationFeed";

import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import ForumIcon from '@mui/icons-material/Forum';

/**
 * Communication component.
 *
 * @returns {*}
 * @constructor
 */
const Communication = () => {
    return (
        <Page hideHeader fullScreen>
            <Box className={'page__heading'}>
                <Box className={'index__title d-flex__start'}>
                    <ForumIcon/>
                    <h2>Communication</h2>
                </Box>
            </Box>
            <Divider/>
            <CommunicationFeed/>
        </Page>
    );
};

export default Communication;