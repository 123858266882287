import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import State from "../../../Global/State";
import FileItem from "./DocumentList/FileItem";
import PdfEmbed from "./DocumentList/PdfEmbed";
import Settings from "../../../Global/Settings";
import ProfileForm from "../../../Pages/Employee/Profile/ProfileForm";
import ReferenceStep from "../../../Pages/Employee/Invitation/ReferenceStep";
import OfferLetterStep from "../../../Pages/Employee/OfferLetter/OfferLetterStep";
import TrainingOverview from "../../../Pages/Employee/Profile/TrainingOverview";
import JobInterviewStep from "../../../Pages/Employee/Invitation/JobInterviewStep";
import DirectDepositStep from "../../../Pages/Employee/HirePacket/DirectDepositStep";
import W4CertificateStep from "../../../Pages/Employee/HirePacket/W4CertificateStep";
import ResidencyDisclosureStep from "../../../Pages/Employee/HirePacket/ResidencyDisclosureStep";
import EmploymentEligibilityStep from "../../../Pages/Employee/HirePacket/EmploymentEligibilityStep";

import Box from "@mui/material/Box";
import JobDescriptionStep from "../../../Pages/Employee/HirePacket/JobDescriptionStep";

/**
 * DocumentList component.
 *
 * @returns {*}
 * @constructor
 */
const DocumentList = (props) => {
    const {
        record,         // {Object} The stored employee object to relate documents against.
    } = props;

    const [documents, setDocuments] = useState([]);
    const [documentTypes, setDocumentTypes] = useState([]);

    /**
     * Load all required data on component mount.
     */
    useEffect(() => {
        getDocuments();
        getDocumentTypes();
    }, [record]);


    /**
     * Fetches all available document types.
     *
     * @returns {Promise<void>}
     */
    const getDocuments = async () => {
        setDocuments(
            await API.get('documents', {
                $top: 250,
                $filter: `modelType in {Employee} and modelId in {${record.id}}`,
                $orderby: 'createdDate desc'
            })
        );
    };


    /**
     * Fetches all available document types.
     *
     * @returns {Promise<void>}
     */
    const getDocumentTypes = async () => {
        setDocumentTypes(
            await API.get('document-types', {
                $top: 250,
                $filter: 'isDeleted eq {0}',
                $orderby: 'name asc',
            })
        );
    };

    return (
        <Box className={'file__list document__list'}>
            {documentTypes.map(document => {
                document['isRequired'] = document.isRecruiting;

                return document;
            }).sort((a, b) => {
                // Place the required items toward the top.
                if (a.isRequired < b.isRequired) {
                    return 1;
                }

                if (a.isRequired > b.isRequired) {
                    return -1;
                }

                return 0;
            }).map((document, i) => {
                const existing = documents.filter(item => item.documentTypeId === document.id);
                const target = !!existing.length ? existing[0] : {};

                if(document.name === 'Direct Deposit'){
                    const hasDirectDeposit = !!record.hirePacketDate || record.hasDirectDeposit;

                    return (
                        <FileItem
                            key={i}
                            file={document}
                            record={!hasDirectDeposit && target.id ? target : {
                                isAccepted: true,
                                createdDate: record.hirePacketDate
                            }}
                            hasFile={hasDirectDeposit}
                            onUpload={getDocuments}
                            onDelete={getDocuments}
                            employeeId={record.id}
                            uploadError={'This employee has not completed their direct deposit.'}
                            previewContent={() => {
                                return (
                                    <Box className={'pt__0'}>
                                        <DirectDepositStep
                                            readOnly
                                            employee={record}
                                            settingsPrefix={'HP_DD_'}
                                        />
                                    </Box>
                                );
                            }}
                            disableDownload
                        />
                    );
                }

                if(document.name === 'Annual Training'){
                    return (
                        <FileItem
                            key={i}
                            file={document}
                            record={target.id ? target : {
                                isAccepted: true,
                                createdDate: record.trainingDate
                            }}
                            hasFile={!!record.trainingDate}
                            onUpload={getDocuments}
                            onDelete={getDocuments}
                            employeeId={record.id}
                            uploadError={'This employee has not completed their training.'}
                            previewContent={() => {
                                return (
                                    <Box className={'pt__0'}>
                                        <TrainingOverview employee={record}/>
                                    </Box>
                                );
                            }}
                        />
                    );
                }

                if(document.name === 'Job Description'){
                    return (
                        <FileItem
                            key={i}
                            file={document}
                            record={target.id ? target : {
                                isAccepted: true,
                                createdDate: record.offerLetterDate
                            }}
                            hasFile={!!record.offerLetterDate}
                            onUpload={getDocuments}
                            onDelete={getDocuments}
                            employeeId={record.id}
                            uploadError={'This employee has not completed their offer letter.'}
                            previewContent={() => {
                                return (
                                    <Box className={'pt__0'}>
                                        <JobDescriptionStep
                                            readOnly
                                            employee={record}
                                            specialty={record.specialty || {}}
                                            settingsPrefix={'HP_JOB'}
                                        />
                                    </Box>
                                );
                            }}
                        />
                    );
                }

                if(document.name === 'Residency Disclosure'){
                    return (
                        <FileItem
                            key={i}
                            file={document}
                            record={target.id ? target : {
                                isAccepted: true,
                                createdDate: record.hirePacketDate
                            }}
                            hasFile={!!record.hirePacketDate}
                            onUpload={getDocuments}
                            onDelete={getDocuments}
                            employeeId={record.id}
                            uploadError={'This employee has not completed their hire packet.'}
                            previewContent={() => {
                                return (
                                    <Box className={'pt__0'}>
                                        <ResidencyDisclosureStep
                                            readOnly
                                            employee={record}
                                            settingsPrefix={'HP_RD_'}
                                        />
                                    </Box>
                                );
                            }}
                        />
                    );
                }

                if(document.name === 'Hire Packet'){
                    return (
                        <FileItem
                            key={i}
                            file={document}
                            record={target.id ? target : {
                                isAccepted: true,
                                createdDate: record.hirePacketDate
                            }}
                            hasFile={!!record.hirePacketDate}
                            onUpload={getDocuments}
                            onDelete={getDocuments}
                            employeeId={record.id}
                            uploadError={'This employee has not completed their hire packet.'}
                            previewContent={() => {
                                return (
                                    <Box className={'pt__0'}>
                                        <ResidencyDisclosureStep
                                            readOnly
                                            employee={record}
                                            settingsPrefix={'HP_RD_'}
                                        />
                                        <W4CertificateStep
                                            readOnly
                                            employee={record}
                                            settingsPrefix={'HP_W4_'}
                                        />
                                        <DirectDepositStep
                                            readOnly
                                            employee={record}
                                            settingsPrefix={'HP_DD_'}
                                        />
                                        <EmploymentEligibilityStep
                                            readOnly
                                            employee={record}
                                            settingsPrefix={'HP_EE_'}
                                        />
                                    </Box>
                                );
                            }}
                            disableDownload
                        />
                    );
                }

                if(document.name === 'I9'){
                    return (
                        <FileItem
                            key={i}
                            file={document}
                            record={target.id ? target : {
                                isAccepted: true,
                                createdDate: record.hirePacketDate
                            }}
                            hasFile={!!record.hirePacketDate}
                            onUpload={getDocuments}
                            onDelete={getDocuments}
                            employeeId={record.id}
                            uploadError={'This employee has not completed their hire packet.'}
                            downloadUrl={`${Settings.apiPath}/pdf/i9?employeeId=${record.id}&x-access-token=${State.get('auth-token')}`}
                            previewContent={() => {
                                return (
                                    <Box className={'pt__0'}>
                                        <PdfEmbed
                                            path={`pdf/i9?employeeId=${record.id}`}
                                        />
                                    </Box>
                                );
                            }}
                        />
                    );
                }

                if(document.name === 'W4'){
                    return (
                        <FileItem
                            key={i}
                            file={document}
                            record={target.id ? target : {
                                isAccepted: true,
                                createdDate: record.hirePacketDate
                            }}
                            hasFile={!!record.hirePacketDate}
                            onUpload={getDocuments}
                            onDelete={getDocuments}
                            employeeId={record.id}
                            refreshable
                            uploadError={'This employee has not completed their hire packet.'}
                            downloadUrl={`${Settings.apiPath}/pdf/w4?employeeId=${record.id}&x-access-token=${State.get('auth-token')}`}
                            previewContent={(refreshed) => (
                                <Box className={'pt__0'}>
                                    <PdfEmbed path={`pdf/w4?employeeId=${record.id}${refreshed ? '&refresh=true' : ''}`}/>
                                </Box>
                            )}
                        />
                    );
                }

                if(document.name === 'Offer Letter'){
                    return (
                        <FileItem
                            key={i}
                            file={document}
                            record={target.id ? target : {
                                isAccepted: true,
                                createdDate: record.offerLetterDate
                            }}
                            hasFile={!!record.offerLetterDate}
                            onUpload={getDocuments}
                            onDelete={getDocuments}
                            employeeId={record.id}
                            uploadError={'This employee has not completed their offer letter.'}
                            previewContent={() => {
                                return (
                                    <Box className={'pt__0'}>
                                        <OfferLetterStep
                                            employee={record}
                                            readOnly
                                        />
                                    </Box>
                                );
                            }}
                        />
                    );
                }

                if(document.name === 'References'){
                    return (
                        <FileItem
                            key={i}
                            file={document}
                            record={target.id ? target : {
                                isAccepted: true,
                                createdDate: record.inviteCompletedDate
                            }}
                            hasFile={!!record.inviteCompletedDate}
                            onUpload={getDocuments}
                            onDelete={getDocuments}
                            employeeId={record.id}
                            uploadError={'This employee has not completed their application.'}
                            previewContent={() => {
                                return (
                                    <Box>
                                        <ReferenceStep
                                            readOnly
                                            employee={record}
                                        />
                                    </Box>
                                );
                            }}
                        />
                    );
                }

                if(document.name === 'Application'){
                    return (
                        <FileItem
                            key={i}
                            file={document}
                            record={target.id ? target : {
                                isAccepted: true,
                                createdDate: record.inviteCompletedDate
                            }}
                            hasFile={!!record.inviteCompletedDate}
                            onUpload={getDocuments}
                            onDelete={getDocuments}
                            employeeId={record.id}
                            uploadError={'This employee has not completed their application.'}
                            previewContent={() => {
                                return (
                                    <Box>
                                        <ProfileForm
                                            readOnly
                                            employee={record}
                                        />
                                        <JobInterviewStep
                                            readOnly
                                            employee={record}
                                        />
                                        <ReferenceStep
                                            readOnly
                                            employee={record}
                                        />
                                    </Box>
                                );
                            }}
                        />
                    );
                }

                return (
                    <FileItem
                        key={i}
                        file={document}
                        record={target}
                        onUpload={getDocuments}
                        onDelete={getDocuments}
                        employeeId={record.id}
                    />
                );
            })}
        </Box>
    );
};

export default DocumentList;