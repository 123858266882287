import React, {useRef, useState} from "react";

import API from "../../../Global/API";
import User from "../../../Models/User";
import Settings from "../../../Global/Settings";
import Formatter from "../../../Global/Formatter";
import ModelSearch from "../../../Components/Input/ModelSearch";
import ImageWrapper from "../../../Components/ImageWrapper";
import SectionHeading from "../../../Components/Typography/SectionHeading";
import EmployeePreview from "./Components/EmployeePreview";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {DatePicker} from "@mui/x-date-pickers";
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";

/**
 * The number of results or page to sequentially pull data.
 *
 * @type {number}
 */
const perPageLimit = 100;


/**
 * ExpiringDocuments component.
 *
 * @returns {*}
 * @constructor
 */
const ActivationsReport = (props) => {
    const {
        loading,            // {Boolean} Indicates if the report is loading.
        onComplete          // {Function} A post-completion callback from whenever the report has results.
    } = props;

    const resultsRef = useRef(null);
    const [users, setUsers] = useState([]);
    const [endDate, setEndDate] = useState(dayjs().endOf('month').format('YYYY-MM-DD'));
    const [isLoading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));
    const [showResults, setShowResults] = useState(false);
    const [userResults, setUserResults] = useState([]);

    /**
     * Loads all of our report rows.
     *
     * @returns {Promise<void>}
     */
    const getAllResults = async () => {
        let promises = [];
        const filters = [];

        if(users && users.length){
            filters.push(`id in {${users.map(user => user.id).join(',')}}`)
        }

        let employeeFilters = ['id ne {0} and isDeleted eq {0} and isActive eq {1}'];
        employeeFilters.push(endDate ? `activatedDate lt {${endDate}}` : ``);
        employeeFilters.push(startDate ? `activatedDate gt {${startDate}}` : ``);
        employeeFilters = employeeFilters.filter(filter => !!filter.trim());

        // Load all the clients page by page until we've reached the end.
        const count = await API.get('users/count', {
            $top: perPageLimit,
            $filter: `activatedEmployees/any{${employeeFilters.join(' and ')}} ${filters.length ? `and ${filters.join(' and ')}` : ''}`,
        });

        for (let page = 0; page < count.pages; page++) {
            promises.push(
                API.get('users', {
                    $top: perPageLimit,
                    $skip: page * perPageLimit,
                    endDate,
                    $select: '*,activations',
                    $filter: `activatedEmployees/any{${employeeFilters.join(' and ')}} ${filters.length ? `and ${filters.join(' and ')}` : ''}`,
                    $orderby: `lastName asc`,
                    startDate
                })
            );
        }

        const results = await Promise.all(promises);
        setUserResults(results.flat());
    };


    /**
     * Loads all of our report data.
     *
     * @returns {Promise<void>}
     */
    const getResults = async () => {
        setLoading(true);

        await Promise.all([
            getAllResults()
        ]);

        setShowResults(true);
        setLoading(false);

        if(onComplete){
            onComplete(resultsRef);
        }
    };


    /**
     * Indicates if the report is in progress.
     *
     * @type {boolean}
     */
    const inProgress = isLoading || loading;

    return (
        <Box className={'d-flex'}>
            <Box
                sx={{width: 400, overflowY: 'hidden'}}
                className={'border__right p__3 full__height--left-tabs'}
            >
                <Box className={'columns__1'}>
                    <Box className={'columns__2'}>
                        <DatePicker
                            label="Start Date"
                            value={startDate ? dayjs(startDate) : null}
                            disabled={inProgress}
                            onChange={event => setStartDate(event ? event.format('YYYY-MM-DD') : '')}
                            fullWidth
                        />
                        <DatePicker
                            label="End Date"
                            value={endDate ? dayjs(endDate) : null}
                            disabled={inProgress}
                            onChange={event => setEndDate(event ? event.format('YYYY-MM-DD') : '')}
                            fullWidth
                        />
                    </Box>

                    <ModelSearch
                        model={User}
                        value={users}
                        label={'User(s)'}
                        multiple
                        disabled={inProgress}
                        onChange={setUsers}
                        helperText={'(Leave blank to include all.)'}
                        renderLabel={option => !option ? '' : option.displayName}
                    />

                    <Button
                        variant={'outlined'}
                        onClick={getResults}
                        children={'Apply'}
                        disabled={inProgress}
                    />
                </Box>
            </Box>

            <Box
                sx={{borderRadius: 0}}
                className={`flex__grow full__height--left-tabs ${!showResults ? 'well__container' : ''}`}
            >
                {inProgress && <LinearProgress sx={{width: '100%'}}/>}

                {!showResults && (
                    <Box className={'p__3 text__center text__disclaimer'}>Your results will display here.</Box>
                )}

                {showResults && (
                    <Box className={'p__3 columns__1'}>
                        <Box>
                            <SectionHeading title={'Activations'} primary/>
                            <TableContainer className={'table table--striped result__set'}>
                                <Table size={'small'}>
                                    <colgroup>
                                        <col width={'33%'}/>
                                        <col width={'66%'}/>
                                    </colgroup>
                                    <TableBody>
                                        {!userResults.length && (
                                            <TableRow>
                                                <TableCell colSpan={2} className={'text__disclaimer text__center'} align={'center'}>
                                                    No results available.
                                                </TableCell>
                                            </TableRow>
                                        )}

                                        {userResults.map(user => {
                                            const activatedEmployees = user.activations.map(activation => {
                                                const {
                                                    id,
                                                    image,
                                                    statusId,
                                                    lastName,
                                                    firstName,
                                                    statusName,
                                                    statusColor,
                                                    specialtyId,
                                                    activatedDate,
                                                    specialtyName,
                                                } = activation;

                                                return {
                                                    id,
                                                    image,
                                                    lastName,
                                                    firstName,
                                                    activatedDate,
                                                    status: {
                                                        id: statusId,
                                                        name: statusName,
                                                        color: statusColor
                                                    },
                                                    specialty: {
                                                        id: specialtyId,
                                                        name: specialtyName
                                                    }
                                                };
                                            });

                                            if(!activatedEmployees || !activatedEmployees.length){
                                                return null;
                                            }

                                            return [
                                                <TableRow key={`user-${user.id}`}>
                                                    <TableCell className={'v-align__top'}>
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                paddingTop: '0.2em',
                                                                paddingBottom: '0.2em'
                                                            }}
                                                            className={`d-flex__start`}
                                                        >
                                                            <ImageWrapper
                                                                src={API.getFilePath(user.image)}
                                                                width={36}
                                                                height={36}
                                                                className={'mr__2'}
                                                            />

                                                            <Box
                                                                className={'d-flex__justify flex__grow'}
                                                            >
                                                                <Box>
                                                                    <b>{user.lastName}, {user.firstName} ({activatedEmployees.length})</b>

                                                                    <Box
                                                                        className={'d-flex__start text__small'}
                                                                    >
                                                                        <Box className={'divider__wrapper'}>
                                                                            <Box
                                                                                className={'text__light d-inline__block'}
                                                                                children={user.jobTitle || Settings.emptyContentLabel}
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell sx={{padding: 0}}>
                                                        <Table>
                                                            <colgroup>
                                                                <col width={'50%'}/>
                                                                <col width={'50%'}/>
                                                            </colgroup>
                                                            <TableBody>
                                                                {activatedEmployees.map(employee => (
                                                                    <TableRow key={`employee-${employee.id}`}>
                                                                        <TableCell sx={{background: 'none!important', padding: 0}}>
                                                                            <EmployeePreview employee={employee}/>
                                                                        </TableCell>
                                                                        <TableCell sx={{background: 'none!important'}}>
                                                                            {Formatter.dateTime(employee.activatedDate)}
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>,
                                            ];
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                )}
            </Box>

            {/*The hidden display for PDF rendering.*/}
            <Box className={'d-hidden'}>
                <Box ref={resultsRef}>
                    <table className={'table--bordered'}>
                        <tbody>
                            <tr>
                                <td>
                                    <b>User</b>
                                </td>
                                <td>
                                    <b>Employee</b>
                                </td>
                                <td>
                                    <b>Activation Date</b>
                                </td>
                            </tr>

                            {userResults.map(user => {
                                const activatedEmployees = user.activations.map(activation => {
                                    const {
                                        id,
                                        image,
                                        statusId,
                                        lastName,
                                        firstName,
                                        statusName,
                                        statusColor,
                                        specialtyId,
                                        activatedDate,
                                        specialtyName,
                                    } = activation;

                                    return {
                                        id,
                                        image,
                                        lastName,
                                        firstName,
                                        activatedDate,
                                        status: {
                                            id: statusId,
                                            name: statusName,
                                            color: statusColor
                                        },
                                        specialty: {
                                            id: specialtyId,
                                            name: specialtyName
                                        }
                                    };
                                });

                                if(!activatedEmployees || !activatedEmployees.length){
                                    return null;
                                }

                                return (
                                    <tr key={`user-${user.id}`}>
                                        <td style={{verticalAlign: 'top', width: '33%'}}>
                                            {user.lastName}, {user.firstName} ({activatedEmployees.length})
                                        </td>
                                        <td colSpan={2} style={{padding: 0, width: '66%'}}>
                                            <table>
                                                <tbody>
                                                {activatedEmployees.map(employee => (
                                                    <tr key={`employee-${employee.id}`}>
                                                        <td style={{width: '50%'}}>
                                                            {employee.lastName}, {employee.firstName}
                                                        </td>
                                                        <td style={{width: '50%'}}>
                                                            {Formatter.dateTime(employee.activatedDate)}
                                                        </td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </table>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Box>
            </Box>
        </Box>
    );
};

export default ActivationsReport;