import React, {useState} from "react";

import API from "../../../Global/API";
import {useAuth} from "../../../Global/Auth";
import ClientModel from '../../../Models/Client'
import ModelSearch from "../../../Components/Input/ModelSearch";
import EmployeeModel from '../../../Models/Employee'
import DialogHeading from "../../../Components/Typography/DialogHeading";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import {Portal} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

/**
 * EventForm component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const EventForm = (props) => {
    const {
        tab,                        // {String} The current scheduler tab.
        onClose,                    // {Function} A modal close callback, fired whenever the user clicks out of the form.
        onCancel,                   // {Function} A cancel callback. to be triggered after clicking "Cancel".
        onSubmit,                   // {Function} A submit callback, to be triggered after saving.
        dataItem,                   // {Object} The KendoJS event object.
        selectedClients,            // {Array} An array of selected client records.
        selectedEmployees,          // {Array} An array of selected employee records.
    } = props;

    // Capture the underlying record instance.
    const record = {...dataItem.record};

    // Format start / end times properly.
    const defaultStart = dayjs(dataItem.startDate || dataItem.start || dataItem.date)
        .set('seconds', 0)
        .format('YYYY-MM-DD HH:mm:ss');

    const [startTime, setStartTime] = useState(defaultStart);
    const [endTime, setEndTime] = useState(!record.id ? defaultStart : dayjs(dataItem.endDate || dataItem.end)
        .set('days', dayjs(dataItem.startDate || dataItem.start || dataItem.date).get('days'))
        .set('seconds', 0)
        .format('YYYY-MM-DD HH:mm:ss'));

    // Handle any additional attributes.
    const {hasPermissionTo} = useAuth();
    const [error, setError] = useState('');
    const [notes, setNotes] = useState(dataItem.notes || '');
    const [clientId, setClientId] = useState(dataItem.clientId || (selectedClients.length === 1 ? selectedClients[0].id : ''));
    const [isLoading, setLoading] = useState(false);
    const [employeeId, setEmployeeId] = useState(dataItem.employeeId || (selectedEmployees.length === 1 ? selectedEmployees[0].id : ''));
    const [doubleShift, setDoubleShift] = useState(false);
    const [employeeLate, setEmployeeLate] = useState(!!record.isEmployeeLate);
    const [employeeNCNS, setEmployeeNCNS] = useState(!!record.isEmployeeNoCall);
    const [viewingShifts, setViewingShifts] = useState(false);
    const [notOnSchedule, setNotOnSchedule] = useState(!!record.isEmployeeNotOnSchedule);
    const [selfScheduled, setSelfScheduled] = useState(!!record.isSelfScheduled);
    const [clientCancelled, setClientCancelled] = useState(!!record.isClientCancelled);
    const [employeeCalledOff, setEmployeeCalledOff] = useState(!!record.isEmployeeCancelled);
    const [employeeLeftEarly, setEmployeeLeftEarly] = useState(!!record.isEmployeeLeftEarly);
    const [helixSelfScheduled, setHelixSelfScheduled] = useState(!!record.isHelixSelfScheduled);

    /**
     * Indicates if we are currently in an error state.
     *
     * @type {boolean}
     */
    const isError = !!error;


    /**
     * Indicates if we should disable the form fields.
     *
     * @type {boolean}
     */
    const isDisabled = isLoading || !hasPermissionTo('EDIT_SCHEDULE');


    /**
     * Performs the save.
     */
    const doSave = async () => {
        if (!employeeId) {
            return setError('Please select an employee.');
        }

        if (!clientId) {
            return setError('Please select a client.');
        }

        setLoading(true);

        const payload = {
            notes,
            clientId,
            employeeId,
            endDate: endTime,
            startDate: startTime,
            isEmployeeLate: employeeLate,
            isEmployeeNoCall: employeeNCNS,
            isClientCancelled: clientCancelled,
            isEmployeeCancelled: employeeCalledOff,
            isEmployeeLeftEarly: employeeLeftEarly,
            isHelixSelfScheduled: helixSelfScheduled,
            isEmployeeNotOnSchedule: notOnSchedule,
        };

        const response = record.id && !record.isBlockRequested ?
            await API.put(`events/${record.id}`, payload) :
            await API.post('events', payload);

        // Handle error responses from the backend.
        if (response.status && response.status === 'error') {
            setLoading(false);
            return setError(response.message);
        }

        if (onSubmit) {
            onSubmit(response);
        }
    };


    /**
     * All available time options.
     *
     * @type {Array}
     */
    const timeOptions = (() => {
        const results = [];

        for (let y = 0; y < 24; y++) {
            for (let x = 0; x < 4; x++) {
                const hours = y;
                const minutes = x * 15;

                const now = dayjs(dataItem.startDate || dataItem.start || dataItem.date)
                    .set('hours', hours)
                    .set('minutes', minutes)
                    .set('seconds', 0);

                results.push({
                    value: now.format('YYYY-MM-DD HH:mm:ss'),
                    label: now.format('h:mmA')
                });
            }
        }

        return results;
    })();


    /**
     * Reveals the shift selection dialog.
     */
    const handleShiftSelectOpen = () => {
        setViewingShifts(true);
    };


    /**
     * Closes the shift selection dialog.
     */
    const handleShiftSelectClose = () => {
        setViewingShifts(false);
    };


    /**
     * Reveals the shift option controls.
     *
     * @returns {Array}
     * @constructor
     */
    const ShiftOptions = () => {
        const options = [];

        for (let i = 0; i < 3; i++) {
            for (let j = 0; j < 4; j++) {
                let shiftStart = dayjs(defaultStart)
                    .set('hours', 6 + (i * 8))
                    .set('minutes', !j ? 0 : (j + 1) * 15);

                let shiftEndNormal = dayjs(defaultStart)
                    .set('hours', 6 + (i * 8) + 8)
                    .set('minutes', (!j ? 0 : (j + 1) * 15) + 30)
                    .set('days', shiftStart.day())
                    .set('years', shiftStart.year())
                    .set('months', shiftStart.month());

                let shiftEndDouble = dayjs(defaultStart)
                    .set('hours', 6 + (i * 8) + 16)
                    .set('minutes', (!j ? 0 : (j + 1) * 15) + 30)
                    .set('days', shiftStart.day())
                    .set('years', shiftStart.year())
                    .set('months', shiftStart.month());

                options.push(
                    <Button
                        size={'small'}
                        variant={'outlined'}
                        onClick={() => {
                            setEndTime(shiftEndNormal.format('YYYY-MM-DD HH:mm:ss'));
                            setStartTime(shiftStart.format('YYYY-MM-DD HH:mm:ss'));
                            setDoubleShift(false);
                            handleShiftSelectClose();
                        }}
                    >
                        {shiftStart.format('h:mmA')} - {shiftEndNormal.format('h:mmA')} (8)
                    </Button>
                );

                options.push(
                    <Button
                        size={'small'}
                        variant={'outlined'}
                        onClick={() => {
                            setEndTime(shiftEndDouble.format('YYYY-MM-DD HH:mm:ss'));
                            setStartTime(shiftStart.format('YYYY-MM-DD HH:mm:ss'));
                            setDoubleShift(true);
                            handleShiftSelectClose();
                        }}
                    >
                        {shiftStart.format('h:mmA')} - {shiftEndDouble.format('h:mmA')} (16)
                    </Button>
                );
            }
        }

        return options;
    };

    return (
        <>
            <Dialog
                open={true}
                scroll={'body'}
                onClose={onClose}
                maxWidth={'sm'}
                fullWidth
            >
                <DialogHeading title={'Event Details'}/>

                <DialogContent>
                    <Box className={'columns__1'}>
                        {tab === 'Employee' ? (
                            <>
                                <FormControl>
                                    <InputLabel required>Employee</InputLabel>
                                    <Select
                                        label={'Employee'}
                                        value={employeeId}
                                        disabled={isDisabled}
                                        onChange={event => setEmployeeId(event.target.value)}
                                    >
                                        {selectedEmployees.map(employee => (
                                            <MenuItem
                                                key={employee.id}
                                                value={employee.id}
                                                children={`${employee.firstName} ${employee.lastName}`}
                                            />
                                        ))}
                                    </Select>
                                </FormControl>
                                <ModelSearch
                                    label={'Client'}
                                    value={clientId}
                                    model={ClientModel}
                                    required
                                    disabled={isDisabled}
                                    onChange={value => setClientId(value)}
                                    renderLabel={(option) => !option ? '' : option.name}
                                    filterQuery={query => `isDeleted eq {0} and name eq {${query}}`}
                                    queryParams={{
                                        $top: 250,
                                        $orderby: 'name asc'
                                    }}
                                />
                            </>
                        ) : (
                            <>
                                <ModelSearch
                                    label={'Employee'}
                                    value={employeeId}
                                    model={EmployeeModel}
                                    onChange={value => setEmployeeId(value)}
                                    renderLabel={(option) => !option ? '' : `${option.lastName}, ${option.firstName}`}
                                />
                                <FormControl>
                                    <InputLabel>Client</InputLabel>
                                    <Select
                                        value={clientId}
                                        label={'Client'}
                                        disabled={isDisabled}
                                        onChange={event => setClientId(event.target.value)}
                                    >
                                        {selectedClients.map(client => (
                                            <MenuItem
                                                key={client.id}
                                                value={client.id}
                                                children={client.name}
                                            />
                                        ))}
                                    </Select>
                                </FormControl>
                            </>
                        )}
                        <Box className={'d-flex__start'}>
                            <Box className={'columns__2 flex__grow'} sx={{marginRight: '0.5em'}}>
                                <FormControl>
                                    <InputLabel>Start Time</InputLabel>
                                    <Select
                                        value={startTime}
                                        label={'Start Time'}
                                        disabled={isDisabled}
                                        onChange={event => setStartTime(event.target.value)}
                                    >
                                        {timeOptions.map((option, i) => (
                                            <MenuItem
                                                key={i}
                                                value={option.value}
                                                children={option.label}
                                            />
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormControl>
                                    <InputLabel>End Time</InputLabel>
                                    <Select
                                        value={endTime}
                                        label={'End Time'}
                                        disabled={isDisabled}
                                        onChange={event => setEndTime(event.target.value)}
                                    >
                                        {timeOptions.map((option, i) => (
                                            <MenuItem
                                                key={i}
                                                value={option.value}
                                                children={option.label}
                                            />
                                        ))}
                                    </Select>
                                </FormControl>
                            </Box>

                            <IconButton
                                onClick={handleShiftSelectOpen}
                                disabled={isDisabled}
                                children={<CalendarMonthIcon/>}
                            />
                        </Box>

                        <TextField
                            rows={4}
                            value={notes}
                            label={'Notes'}
                            disabled={isDisabled}
                            onChange={event => setNotes(event.target.value)}
                            multiline
                        />

                        <FormControl>
                            <FormControlLabel
                                label={'Self-Scheduled (SS)'}
                                control={
                                    <Checkbox
                                        checked={selfScheduled}
                                        disabled={isDisabled}
                                        onChange={event => setSelfScheduled(event.target.checked)}
                                    />
                                }
                            />
                            <FormControlLabel
                                label={'Client Cancelled (CC)'}
                                control={
                                    <Checkbox
                                        checked={clientCancelled}
                                        disabled={isDisabled}
                                        onChange={event => setClientCancelled(event.target.checked)}
                                    />
                                }
                            />
                            <FormControlLabel
                                label={'Employee Called Off (ECO)'}
                                control={
                                    <Checkbox
                                        checked={employeeCalledOff}
                                        disabled={isDisabled}
                                        onChange={event => setEmployeeCalledOff(event.target.checked)}
                                    />
                                }
                            />
                            <FormControlLabel
                                label={'Employee Late (EL)'}
                                control={
                                    <Checkbox
                                        checked={employeeLate}
                                        disabled={isDisabled}
                                        onChange={event => setEmployeeLate(event.target.checked)}
                                    />
                                }
                            />
                            <FormControlLabel
                                label={'Employee NC/NS (NC/NS)'}
                                control={
                                    <Checkbox
                                        checked={employeeNCNS}
                                        disabled={isDisabled}
                                        onChange={event => setEmployeeNCNS(event.target.checked)}
                                    />
                                }
                            />
                            <FormControlLabel
                                label={'Employee Left Early (LE)'}
                                control={
                                    <Checkbox
                                        checked={employeeLeftEarly}
                                        disabled={isDisabled}
                                        onChange={event => setEmployeeLeftEarly(event.target.checked)}
                                    />
                                }
                            />
                            <FormControlLabel
                                label={'Not On Schedule (NOS)'}
                                control={
                                    <Checkbox
                                        checked={notOnSchedule}
                                        disabled={isDisabled}
                                        onChange={event => setNotOnSchedule(event.target.checked)}
                                    />
                                }
                            />
                            <FormControlLabel
                                label={'Helix Self-Scheduled (HX)'}
                                control={
                                    <Checkbox
                                        checked={helixSelfScheduled}
                                        disabled={isDisabled}
                                        onChange={event => setHelixSelfScheduled(event.target.checked)}
                                    />
                                }
                            />
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={doSave}
                        children={'Save'}
                        disabled={isDisabled}
                    />
                    <Button
                        color={'error'}
                        onClick={onCancel}
                        children={'Close'}
                        disabled={isDisabled}
                    />
                </DialogActions>
            </Dialog>

            <Dialog
                open={viewingShifts}
                scroll={'body'}
                onClose={handleShiftSelectClose}
                maxWidth={'sm'}
                fullWidth
            >
                <DialogHeading title={'Shift Options'}/>
                <DialogContent>
                    <Box className={'columns__2'} sx={{gap: '0.5em', gridGap: '0.5em'}}>
                        <ShiftOptions/>
                    </Box>
                </DialogContent>
            </Dialog>

            <Portal>
                <Snackbar open={isError} autoHideDuration={6000} onClose={() => setError('')}>
                    <Alert onClose={() => setError('')} severity={'error'}>
                        {error}
                    </Alert>
                </Snackbar>
            </Portal>
        </>
    );
};

export default EventForm;