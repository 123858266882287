import React from "react";

import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";

/**
 * BannerAlert component.
 *
 * @returns {*}
 * @constructor
 */
const BannerAlert = (props) => {
    const {
        children
    } = props;

    return (
        <Alert
            {...props}
            sx={{
                paddingTop: '0.15em',
                paddingLeft: '1em',
                marginRight: '0.5em',
                paddingRight: '1em',
                paddingBottom: 0,
            }}
            className={'text__small'}
        >
            <Box sx={{
                paddingTop: '0.25em'
            }}>{children}</Box>
        </Alert>
    );
};

export default BannerAlert;