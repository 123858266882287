import React from "react";

import Model from "../Definitions/Model";
import Property from "../Definitions/Property";
import StatusForm from "../Actions/StatusActions/StatusForm";
import BoundSwitch from "../Components/Input/BoundSwitch";
import PropertyMap from "../Definitions/PropertyMap";
import StatusActions from "../Actions/StatusActions";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

/**
 * Status model.
 *
 * @type {Object}
 */
const Status = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'status',


    /**
     * The appropriate API endpoint root.
     */
    route: 'statuses',


    /**
     * Additional query parameters to share throughout the application.
     */
    query: {
        $filter: 'isDeleted eq {0}'
    },


    /**
     * The form component.
     *
     * @returns {*}
     * @param parameters
     */
    form: (parameters) => <StatusForm {...parameters}/>,


    /**
     * The menu action.
     *
     * @param parameters
     * @returns {*}
     */
    action: (parameters) => <StatusActions {...parameters}/>,


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'name',
        'modelType',
        'shouldNotify',
        'shouldDeactivate',
        'color'
    ],


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'name',
            width: '20%',
            label: 'Name',
            render: (record) => (
                <Box className={'d-flex__start'}>
                    <Box className={'mr__1'}>{record.name}</Box>
                    {record.isDefault && <Chip size={'small'} label={'Default'}/>}
                </Box>
            ),
            sortable: true
        }),
        Property.create({
            key: 'modelType',
            width: '20%',
            label: 'Record Type',
            render: (record) => record.modelType,
            sortable: true
        }),
        Property.create({
            key: 'shouldDeactivate',
            width: '20%',
            label: 'Should Deactivate?',
            render: (record) => (
                <BoundSwitch
                    model={Status}
                    field={'shouldDeactivate'}
                    record={record}
                    permission={'EDIT_LIBRARIES'}
                />
            ),
            sortable: true
        }),
        Property.create({
            key: 'shouldNotify',
            width: '20%',
            label: 'Should Notify?',
            render: (record) => (
                <BoundSwitch
                    model={Status}
                    field={'shouldNotify'}
                    record={record}
                    permission={'EDIT_LIBRARIES'}
                />
            ),
            sortable: true
        }),
        Property.create({
            key: 'color',
            align: 'center',
            label: 'Preview',
            render: (record) => <Chip label={record.name} sx={{backgroundColor: record.color || 'initial', color: 'white'}}/>,
            sortable: true
        })
    ])
};

export default Status;