import React, {useEffect, useState} from "react";

import Step from "../../../Components/Step";
import {useAuth} from "../../../Global/Auth";
import LogoHeading from "../../../Components/Typography/LogoHeading";
import StepControls from "../../../Components/StepControls";
import InputSignature from "../../../Components/Input/InputSignature";
import SectionHeading from "../../../Components/Typography/SectionHeading";

import Box from "@mui/material/Box";

/**
 * SchedulingProcedureStep component.
 *
 * @returns {*}
 * @constructor
 */
const SchedulingProcedureStep = (props) => {
    const {
        step,               // {Number} The current step for the user.
        index,              // {Number} The expected step number.
        onClose,            // {Function} An optional method for closing the form if it appears in a dialog.
        readOnly,           // {Boolean} Whether the form is disabled.
        employee,           // {Object} The employee object for read only context.
        disabled,           // {Boolean} Whether the step is disabled from input.
        contentOnly,        // {Boolean} Whether to only render the content.
        onStepChange        // {Function} The on change callback to load the next step.
    } = props;

    const [isLoaded, setLoaded] = useState(false);
    const [signature, setSignature] = useState('');
    const {setUserSetting, getUserSettingValue, getUserSettingValueForEmployee} = useAuth();

    /**
     * Loads the required settings for this step.
     */
    useEffect(() => {
        handleSettingsLoad();
    }, []);


    /**
     * Synchronize the job description signature.
     */
    useEffect(() => {
        if(!readOnly && isLoaded){
            setUserSetting('HP_SCHEDULING_PROCESS', signature)
        }
    }, [signature]);


    /**
     * Loads each of the appropriate user settings.
     *
     * @returns {Promise<void>}
     */
    const handleSettingsLoad = async () => {
        setSignature(
            readOnly ?
                await getUserSettingValueForEmployee(employee.id, 'HP_SCHEDULING_PROCESS') :
                await getUserSettingValue('HP_SCHEDULING_PROCESS')
        );

        setLoaded(true);
    };


    /**
     * The disabled state of the form.
     */
    const isDisabled = readOnly || disabled;

    return (
        <Step step={index} value={step}>
            <LogoHeading
                title={'Scheduling Procedure'}
                onClose={onClose}
            />

            <Box className={'columns__1'}>
                <Box className={'text__small'}>
                    <SectionHeading title={"Don't worry, this process is super simple!"}/>
                </Box>
                <Box>
                    The following is an overview of how our scheduling process works and the purpose of this
                    disclosure is to simply familiarize you with our process.
                </Box>
                <Box>
                    <b>When your availability is needed</b>, your scheduler will implement a process where you
                    will receive a text message from a team member in the office. This message will state the
                    following message:
                </Box>
                <Box>
                    "Reliant Staffing is requesting your MONTHLY AVAILABILITY. Please check your email/spam
                    folder for the logon instructions to access the availability form. Do not reply to this
                    text. Messages sent to this number will not be received or returned."
                </Box>
                <Box>
                    Like the text message states, go to your email and open the email sent titled "Reliant
                    Staffing - Request Availability." Follow the log in instructions to access the availability
                    form. WATCH the training video then complete and submit the form accordingly.
                </Box>
                <Box>
                    <b>After you have submitted your availability</b>, your scheduler will go to work for you
                    and will schedule you according to the availability you gave at the facilities you like to
                    work at.
                    Once your schedule is finished, you will receive another TEXT message from a team member. This
                    text will state the following message:
                </Box>
                <Box>
                    "Reliant Staffing has sent you an email with your new SCHEDULE attached. Please check your
                    email/spam folder and save your schedule. Do not reply to this text. Messages sent to this
                    number will not be received or returned."
                    Like the text message states, go to your email and open the email sent title, “Reliant
                    Staffing – Employee Schedule.” View the schedule by clicking on the link and save or print
                    your schedule.
                </Box>
                <Box>
                    <b>If you would like to pick up additional shifts</b>, please do so by calling your scheduler.
                    Your schedule is based on what you made yourself available for. Your scheduler does not have
                    the time to constantly move your schedule around. Once your schedule is finished, you will
                    only be permitted to make two changes a month. If you cannot do that, then you will have to
                    call off and be subjected to Reliant’s attendance policy. See the attendance policy for
                    details.
                </Box>

                {!contentOnly && (
                    <>
                        <InputSignature
                            value={signature}
                            label={'Please sign here:'}
                            disabled={isDisabled}
                            onChange={setSignature}
                            scaleFactor={2}
                        />

                        {!readOnly && (
                            <StepControls
                                step={step}
                                disabled={isDisabled || !signature}
                                onStepChange={onStepChange}
                            />
                        )}
                    </>
                )}
            </Box>
        </Step>
    );
};

export default SchedulingProcedureStep;