import React, {useState} from "react";

import API from "../Global/API";
import Page from "../Components/Page";
import State from "../Global/State";
import Panel from "../Components/Panel";
import Formatter from "../Global/Formatter";
import CopyrightDisclaimer from "./Login/CopyrightDisclaimer";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import {useNavigate, useParams} from "react-router";

/**
 * ResetPassword component.
 *
 * @returns {*}
 * @constructor
 */
const ResetPassword = () => {
    const {token} = useParams();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);
    const [passwordConfirm, setPasswordConfirm] = useState('');

    /**
     * A series of boolean checks to determine if the password meets the requirements.
     *
     * @type {boolean}
     */
    const bHasUppercase = /[A-Z]/.test(password);
    const bHasLowercase = /[a-z]/.test(password);
    const bHasMinimumCharacters = password.length >= 8;
    const bHasSpecialCharacter = /[~`!#$%\^_&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(password);


    /**
     * Attempt to process the password reset.
     */
    const doReset = async () => {
        setError('');
        setLoading(true);

        const response = await API.post(`password-reset/${token}`, {
            password,
            passwordConfirm
        });

        setLoading(false);

        if (response.status === 'error') {
            return setError(response.message);
        }

        setSubmitted(true);
        State.set('login-last-email', '');
        State.set('login-last-password', '');
    };


    /**
     * Allow login on enter key.
     *
     * @param event
     */
    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
            doReset();
        }
    };


    /**
     * A series of icon properties for each icon in the requirements list.
     *
     * @type {{fontSize: string, className: string}}
     */
    const iconProps = {
        fontSize: 'small',
        className: 'mr__2'
    };

    return (
        <Page hideHeader>
            <Box className={'columns__1'}>
                <Panel
                    header={
                        <Box
                            className={'text__center'}
                            children={
                                <img
                                    alt={'Login logo'}
                                    src={Formatter.url('assets/images/logo.png')}
                                    style={{width: '70%'}}
                                />
                            }
                        />
                    }
                    padding={0}
                    className={'login__block'}
                    hideDivider
                >
                    <Box className={'columns__1'} sx={{padding: '1.5em', paddingTop: 0}}>
                        {!isSubmitted ? (
                            <>
                                {error && <Alert color={'error'} children={error}/>}

                                <Box>
                                    <Box>
                                        Please enter a new password below. As an additional security measure, your
                                        password should contain:
                                    </Box>
                                    <ul className={'mb__0 no__bullet'}>
                                        {!bHasMinimumCharacters ? (
                                            <li className={'d-flex__start'}>
                                                <CloseIcon color={'error'} {...iconProps}/>
                                                <span>At least 8 characters.</span>
                                            </li>
                                        ) : (
                                            <li className={'d-flex__start'}>
                                                <CheckIcon color={'success'} {...iconProps}/>
                                                <del className={'text__light'}>At least 8 characters.</del>
                                            </li>
                                        )}


                                        {!bHasUppercase ? (
                                            <li className={'d-flex__start'}>
                                                <CloseIcon color={'error'} {...iconProps}/>
                                                <span>One uppercase letter.</span>
                                            </li>
                                        ) : (
                                            <li className={'d-flex__start'}>
                                                <CheckIcon color={'success'} {...iconProps}/>
                                                <del className={'text__light'}>One uppercase letter.</del>
                                            </li>
                                        )}

                                        {!bHasLowercase ? (
                                            <li className={'d-flex__start'}>
                                                <CloseIcon color={'error'} {...iconProps}/>
                                                <span>One lowercase letter.</span>
                                            </li>
                                        ) : (
                                            <li className={'d-flex__start'}>
                                                <CheckIcon color={'success'} {...iconProps}/>
                                                <del className={'text__light'}>One lowercase letter.</del>
                                            </li>
                                        )}

                                        {!bHasSpecialCharacter ? (
                                            <li className={'d-flex__start'}>
                                                <CloseIcon color={'error'} {...iconProps}/>
                                                <span>One special character.</span>
                                            </li>
                                        ) : (
                                            <li className={'d-flex__start'}>
                                                <CheckIcon color={'success'} {...iconProps}/>
                                                <del className={'text__light'}>One special character.</del>
                                            </li>
                                        )}
                                    </ul>
                                </Box>

                                <TextField
                                    type={'password'}
                                    value={password}
                                    label={'New Password'}
                                    required
                                    disabled={isLoading}
                                    onChange={event => setPassword(event.target.value)}
                                    onKeyDown={handleKeyDown}
                                />

                                <TextField
                                    type={'password'}
                                    value={passwordConfirm}
                                    label={'Confirm Password'}
                                    required
                                    disabled={isLoading}
                                    onChange={event => setPasswordConfirm(event.target.value)}
                                    onKeyDown={handleKeyDown}
                                />

                                <div className={'columns__1'}>
                                    <Button
                                        sx={{padding: '1em', textTransform: 'capitalize'}}
                                        onClick={doReset}
                                        variant={'contained'}
                                        disabled={isLoading}
                                        children={'Update Password'}
                                    />
                                </div>

                                <CopyrightDisclaimer/>
                            </>
                        ) : (
                            <>
                                <Alert severity={'success'}>Successfully updated password!</Alert>

                                <Box>
                                    Your password has been saved successfully. You may now login using your new
                                    credentials below.
                                </Box>

                                <Button
                                    sx={{padding: '1em', textTransform: 'capitalize'}}
                                    onClick={() => navigate('/login')}
                                    variant={'outlined'}
                                    disabled={isLoading}
                                    children={'Login'}
                                />
                            </>
                        )}
                    </Box>
                </Panel>
            </Box>
        </Page>
    );
};

export default ResetPassword;