import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import State from "../../Global/State";
import Logger from "../../Global/Logger";
import {useAuth} from "../../Global/Auth";
import Notification from "./NotificationFeed/Notification";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import Badge from "@mui/material/Badge";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import ListItem from "@mui/material/ListItem";
import Skeleton from "@mui/material/Skeleton";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import CheckIcon from '@mui/icons-material/Check';
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FormControlLabel from "@mui/material/FormControlLabel";
import CircularProgress from "@mui/material/CircularProgress";

/**
 * NotificationFeed component.
 *
 * @constructor
 */
const NotificationFeed = (props) => {
    const {
        title      // {Component} The title component within the header.
    } = props;

    const {user} = useAuth();
    const [tab, setTab] = useState(State.get('notification-feed-tab') || 'Unread');
    const [page, setPage] = useState(0);
    const [fact, setFact] = useState(State.get('last-fact'));
    const [index, setIndex] = useState(0);
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isLoadingNext, setLoadingNext] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const [hasMoreResults, setHasMoreResults] = useState(true);
    const [isLoadingReadAll, setLoadingReadAll] = useState(false);
    const [isReadAllComplete, setReadAllComplete] = useState(false);

    /**
     * All of our applicable filters.
     */
    const [filters, setFilters] = useState({
        upload: true,
        status: true,
        training: true,
        expiration: true,
        discipline: true,
        hirePacket: true,
        availability: true,

        ...State.json('notification-feed-filters')
    });


    /**
     * Returns true if there are any notification filters applied.
     *
     * @type {boolean}
     */
    const hasFiltersApplied = Object.keys(filters).some(key => filters[key] !== true);


    /**
     * Indicates whether we have an anchor target for the menu.
     *
     * @type {boolean}
     */
    const open = Boolean(anchorEl);


    /**
     * Load all ancillary data on mount.
     */
    useEffect(() => {
        if(!fact){
            getFact();
        }

        Logger.debug(`[NotificationFeed] Loading "${tab}" notifications, page index: ${page}...`);
        getNotifications();
    }, [tab, page, index, filters]);


    /**
     * Resets the initial scroll / load state.
     */
    const doRefresh = async (bFact) => {
        if(bFact === true){
            await getFact();
        }

        setPage(0);
        setIndex(index + 1);
        setLoading(true);
        setLoadingNext(false);
        setNotifications([]);
        setHasMoreResults(true);
    };


    /**
     * Handles the menu tab selection.
     *
     * @param updated
     */
    const handleTabSelect = (updated) => {
        if (updated === tab) {
            return;
        }

        setTab(updated);
        setLoadingReadAll(false);
        setReadAllComplete(false);

        doRefresh();
        State.set('notification-feed-tab', updated);
    };


    /**
     * Generates n number of notifications.
     *
     * @returns {Array}
     */
    const getNotifications = async () => {
        const perPage = 25;

        setLoading(true);

        // Handle unread notifications.
        if (tab === 'Unread') {
            const results = await API.get('unread-notifications', {
                $top: perPage,
                $skip: page * perPage,
                $expand: 'notificationReads',
                filters: JSON.stringify(filters),
                $orderby: 'createdDate desc'
            });

            if (!results.length) {
                setHasMoreResults(false);
            }

            setNotifications([
                ...notifications,
                ...results
            ]);

            setLoadingNext(false);
            return setLoading(false);
        }

        // Otherwise, just use the traditional endpoint with a simple "Read" filter.
        const payload = {
            $top: perPage,
            $skip: page * perPage,
            $expand: 'notificationReads',
            filters: JSON.stringify(filters),
            $orderby: 'createdDate desc',
        };

        if (tab === 'Read') {
            payload['readOnly'] = true;
        }

        const results = await API.get('user-notifications', payload);

        if (!results.length) {
            setHasMoreResults(false);
        }

        setNotifications([
            ...notifications,
            ...results
        ]);

        setLoadingNext(false);
        return setLoading(false);
    };


    /**
     * Handles data loading on scroll.
     *
     * @param event
     */
    const handleContainerScroll = (event) => {
        const el = event.target;
        const threshold = 400;

        if (isLoadingNext) {
            return;
        }

        if (el.scrollTop <= (el.scrollHeight - el.offsetHeight) - threshold) {
            return;
        }

        if (hasMoreResults) {
            setLoadingNext(true);
            setPage(page + 1);
        }
    };


    /**
     * Marks all notifications as "Read".
     *
     * @returns {Promise<void>}
     */
    const doReadAll = async () => {
        setLoadingReadAll(true);
        const result = await API.post('notifications/read-all');

        if (result.status && result.status === 'success') {
            doRefresh(true);
        }

        setLoadingReadAll(false);
        setReadAllComplete(true);
    };


    /**
     * Retrieves a random fact.
     *
     * @returns {Promise<void>}
     */
    const getFact = async () => {
        const response = await API.get('fact');
        const fact = response.message;

        if(fact){
            setFact(fact);
            State.set('last-fact', fact);
        }
    };


    /**
     * Fired whenever the menu button is clicked.
     *
     * @param event
     */
    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Closes the menu popover.
     */
    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    /**
     * Updates a particular filter.
     *
     * @param key
     * @param value
     */
    const setFilter = (key, value) => {
        const updatedFilters = {
            ...filters,
            [key]: value
        };

        setFilters(updatedFilters);
        State.set('notification-feed-filters', JSON.stringify(updatedFilters));
        doRefresh();
    };


    /**
     * Returns the header component for the notification container.
     *
     * @returns {*}
     * @constructor
     */
    const NotificationsHeader = () => {
        return (
            <Box className={'notifications__menu mr__3'}>
                <a
                    onClick={() => handleTabSelect('All')}
                    children={'All'}
                    className={tab === 'All' ? 'text__bold' : ''}
                />
                <span className={'divider'}/>
                <a
                    onClick={() => handleTabSelect('Unread')}
                    children={'Unread'}
                    className={tab === 'Unread' ? 'text__bold' : ''}
                />
                <span className={'divider'}/>
                <a
                    onClick={() => handleTabSelect('Read')}
                    children={'Read'}
                    className={tab === 'Read' ? 'text__bold' : ''}
                />
            </Box>
        );
    };

    return (
        <Box className={'notifications__wrapper'}>
            <Box className={'notifications__header'}>
                {title && <>{title}</>}

                <Box className={'d-flex__start'}>
                    <NotificationsHeader/>

                    <IconButton
                        size={'small'}
                        onClick={handleMenuOpen}
                    >
                        <Badge invisible={!hasFiltersApplied} color={'primary'} variant={'dot'}>
                            <MoreHorizIcon/>
                        </Badge>
                    </IconButton>
                </Box>
            </Box>
            <Divider/>
            <Box
                onScroll={handleContainerScroll}
                className={'notifications__body'}
            >
                <Box>
                    {isLoading && !isLoadingNext && (
                        <Box
                            sx={{gap: '1em', gridGap: '1em'}}
                            className={'columns__1'}
                        >
                            {[...Array(3).keys()].map((key, i) => {
                                return (
                                    <Box
                                        key={i}
                                        sx={{alignItems: 'start'}}
                                        className={'d-flex__start'}
                                    >
                                        <Skeleton
                                            sx={{marginRight: '0.75em'}}
                                            variant={'circular'}
                                            width={'0.75em'}
                                            height={'0.75em'}
                                        />

                                        <Box sx={{flexGrow: 1}}>
                                            <Skeleton
                                                variant={'rounded'}
                                                width={'100%'}
                                                height={'6.5em'}
                                            />
                                        </Box>
                                    </Box>
                                )
                            })}
                        </Box>
                    )}

                    {(isLoadingNext || !isLoading) && (
                        <>
                            {notifications.map(notification => (
                                <Notification
                                    key={notification.id}
                                    userId={user.id}
                                    record={notification}
                                />
                            ))}

                            {!notifications.length && !!fact && (
                                <div className={'disclaimer__alert'}>
                                    <span><b>💡 Did you know?</b> {fact}.</span>
                                </div>
                            )}
                        </>
                    )}
                </Box>
            </Box>

            {tab === 'Unread' && (
                <Chip
                    icon={isLoadingReadAll ?
                        <CircularProgress size={14}/> : (isReadAllComplete ? <CheckIcon/> : null)}
                    label={isReadAllComplete ? 'Up to date!' : 'Mark all as read'}
                    color={isReadAllComplete ? 'success' : 'default'}
                    onClick={doReadAll}
                    disabled={isReadAllComplete}
                    className={'bulk__seen'}
                />
            )}

            <Menu
                open={open}
                onClose={handleMenuClose}
                anchorEl={anchorEl}
            >
                <MenuItem disabled>
                    Filter Items
                </MenuItem>
                <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
                    <FormGroup>
                        <FormControlLabel
                            label={'Availability'}
                            control={
                                <Checkbox
                                    checked={filters.availability}
                                    onChange={event => setFilter('availability', event.target.checked)}
                                />
                            }
                        />
                        <FormControlLabel
                            label={'Document Uploads'}
                            control={
                                <Checkbox
                                    checked={filters.upload}
                                    onChange={event => setFilter('upload', event.target.checked)}
                                />
                            }
                        />
                        <FormControlLabel
                            label={'Document Expiration'}
                            control={
                                <Checkbox
                                    checked={filters.expiration}
                                    onChange={event => setFilter('expiration', event.target.checked)}
                                />
                            }
                        />
                        <FormControlLabel
                            label={'Discipline / Tally'}
                            control={
                                <Checkbox
                                    checked={filters.discipline}
                                    onChange={event => setFilter('discipline', event.target.checked)}
                                />
                            }
                        />
                        <FormControlLabel
                            label={'Status Updates'}
                            control={
                                <Checkbox
                                    checked={filters.status}
                                    onChange={event => setFilter('status', event.target.checked)}
                                />
                            }
                        />
                        <FormControlLabel
                            label={'Training Notifications'}
                            control={
                                <Checkbox
                                    checked={filters.training}
                                    onChange={event => setFilter('training', event.target.checked)}
                                />
                            }
                        />
                        <FormControlLabel
                            label={'Hire Packets'}
                            control={
                                <Checkbox
                                    checked={filters.hirePacket}
                                    onChange={event => setFilter('hirePacket', event.target.checked)}
                                />
                            }
                        />
                    </FormGroup>
                </ListItem>
            </Menu>
        </Box>
    );
};

export default NotificationFeed;