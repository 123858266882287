import React, {useEffect} from "react";

import Logger from "../../../Global/Logger";
import Formatter from "../../../Global/Formatter";
import usePhoneCall from "../../../Hooks/usePhoneCall";
import usePhoneDevice from "../../../Hooks/usePhoneDevice";

import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import MicIcon from '@mui/icons-material/Mic';
import StopIcon from '@mui/icons-material/Stop';
import MicOffIcon from '@mui/icons-material/MicOff';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import PhoneLockedIcon from '@mui/icons-material/PhoneLocked';

/**
 * CallButton component.
 *
 * @returns {*}
 * @constructor
 */
const CallButton = (props) => {
    const {
        className,              // {String} An optional class name to pass to the component.
        attributes,             // {Object} A collection of generic attributes to pass alongside the activeCall storage.
        phoneNumber,            // {String} The outbound phone number.
    } = props;

    const {device} = usePhoneDevice();
    const {phoneCall, phoneNumber: activePhoneNumber} = usePhoneCall();

    const isCalling = !!phoneCall;
    const isAccepted = Formatter.e164(activePhoneNumber) === Formatter.e164(phoneNumber);
    const isMuted = phoneCall && phoneCall.isMuted();
    const isDisabled = isCalling && !isAccepted;

    /**
     * Attempt to preload an active call if we have one.
     */
    useEffect(() => {
        Logger.debug('[CallButton] Active call:', phoneCall, isMuted, isAccepted, isDisabled);
    }, [phoneCall, isMuted, isAccepted, isDisabled]);


    /**
     * Broadcasts an update to the call outward.
     *
     * @param call
     * @param options
     */
    const broadcast = (call, options = {}) => {
        window.dispatchEvent(
            new CustomEvent('phonecallchange', {
                detail: !call ? {} : {
                    ...attributes,
                    call,
                    isMuted,
                    phoneNumber,
                    ...options
                }
            })
        );
    };


    /**
     * Executes a new outbound call.
     *
     * @returns {Promise<void>}
     */
    const handleCall = async () => {
        if (isCalling || isAccepted) {
            return;
        }

        // Ensure that we aren't creating multiple instances here (only one active call at a time).
        const formattedNumber = '+1' + phoneNumber.replace(/[^0-9a-z]/gi, '');
        Logger.debug(`[CallButton] Attempting to call ${formattedNumber}`);

        const call = await device.connect({
            params: {
                To: formattedNumber
            },
        });

        call.on('error', error => {
            Logger.debug('[CallButton] Encountered an error:', error);
            broadcast(null);
        });

        call.on('accept', call => {
            Logger.debug('[CallButton] The call was accepted or the outgoing call\'s media session has finished setting up.');
            broadcast(call);
        });

        call.on('cancel', () => {
            Logger.debug('[CallButton] The call has been canceled.');
            broadcast(null);
        });

        call.on('disconnect', () => {
            Logger.debug('[CallButton] The call has been disconnected.');
            broadcast(null);
        });

        call.on('mute', (isMuted, call) => {
            Logger.debug('[CallButton] The call has been muted.', isMuted);
            broadcast(call, {
                isMuted
            });
        });

        broadcast(call);
    };


    /**
     * Disconnects the active phone call.
     */
    const handleCallCancel = () => {
        if (!phoneCall) {
            return;
        }

        phoneCall.disconnect();
        broadcast(null, true);
    };


    /**
     * Processes the mute toggle.
     */
    const handleMute = () => {
        if (!phoneCall) {
            return;
        }

        const updated = !isMuted;
        phoneCall.mute(updated);
    };

    return (
        <Box className={`d-flex__start ${className || ''}`}>
            {!isDisabled && isCalling && (
                <Fab
                    size={'small'}
                    color={'error'}
                    variant={'contained'}
                    onClick={handleCallCancel}
                    className={'mr__2'}
                >
                    <StopIcon/>
                </Fab>
            )}

            <Fab
                color={'primary'}
                variant={'contained'}
                onClick={isAccepted ? handleMute : handleCall}
                disabled={isDisabled || !phoneNumber || (isCalling && !isAccepted)}
                children={
                    !!isDisabled ? <PhoneLockedIcon/> : (
                        !isAccepted ? <LocalPhoneIcon/> : (
                            isMuted ? <MicOffIcon/> : <MicIcon/>
                        )
                    )
                }
            />
        </Box>
    );
};

export default CallButton;