import React, {useState} from "react";

import State from "../../Global/State";
import RecruitNew from "../../Models/RecruitNew";
import TableLayout from "../../Components/Layouts/TableLayout";
import RecruitInvited from "../../Models/RecruitInvited";
import RecruitingFilter from "./Recruiting/RecruitingFilter";

import AssignmentIcon from '@mui/icons-material/Assignment';

/**
 * Recruiting component.
 *
 * @returns {*}
 * @constructor
 */
const Recruiting = () => {
    const [tab, setTab] = useState(State.get('recruiting-layout') || 'new');

    /**
     * Handles the tab selection event.
     *
     * @param tab
     */
    const handleTabChange = (tab) => {
        setTab(tab);
        State.set('recruiting-layout', tab);
    };

    return (
        <TableLayout
            key={tab}
            condensed
            icon={<AssignmentIcon/>}
            model={tab === 'new' ? RecruitNew : RecruitInvited}
            heading={'Recruiting'}
            applySearch={search => {
                const model = tab === 'new' ?
                    RecruitNew : RecruitInvited;

                const filter = model.getQuery().$filter;
                return `${filter} and displayName eq {${search}} or emailAddress eq {${search}}`
            }}
            filterSlot={<RecruitingFilter value={tab} onChange={handleTabChange}/>}
            hideFilters
            formPermission={'EDIT_RECRUITING'}
        />
    );
};

export default Recruiting;