import React, {useState} from "react";

import API from "../../Global/API";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import InputLabel from "@mui/material/InputLabel";
import {DatePicker} from "@mui/x-date-pickers";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

/**
 * AvailabilityRequest component.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const AvailabilityRequest = (props) => {
    const {
        open,           // {Boolean} Indicates if the dialog is currently open.
        record,         // {Object} The employee record for context.
        onSave,         // {Function} Called after saving the record.
        loading,        // {Boolean} Indicates if the form is currently loading.
        onClose,        // {Function} An on-close callback for the dialog.
    } = props;

    const [error, setError] = useState('');
    const [endDate, setEndDate] = useState(record.availabilityEndDate || '');
    const [isLoading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(record.availabilityStartDate || '');

    /**
     * Handles the save action.
     *
     * @returns {Promise<void>}
     */
    const doSave = async () => {
        if(startDate || endDate) {
            if(!startDate || !endDate){
                return setError('Please provide a start and end date.');
            }

            if(dayjs(startDate).isAfter(dayjs(endDate))) {
                return setError('The start date must be before the end date.');
            }
        }

        setError('');
        setLoading(true);

        const response = await API.put(`employees/${record.id}`, {
            availabilityEndDate: endDate,
            availabilityStartDate: startDate,
        });

        if(onSave){
            onSave(response);
        }

        if(onClose){
            onClose();
        }
    };


    /**
     * Indicates if the form is currently loading.
     *
     * @type {boolean}
     */
    const isDisabled = loading || isLoading;

    return (
        <Dialog
            open={open}
            scroll={'body'}
            onClose={onClose}
            maxWidth={'sm'}
        >
            <DialogContent>
                {error ? (
                    <Alert severity={'error'}>
                        {error}
                    </Alert>
                ) : (
                    <Alert severity={'info'} icon={<CalendarMonthIcon fontSize={'inherit'}/>}>
                        By manually assigning an availability date, you are overriding the default period which
                        is set by the employee's contract / assigned facility. If left empty, the system
                        will default to the next 6 weeks.
                    </Alert>
                )}

                <Box className={'columns__1'}>
                    <InputLabel
                        children={'Update Availability'}
                        className={'form__heading form__heading--standalone'}
                    />

                    <Box className={'columns__2'}>
                        <DatePicker
                            label={'Start Date'}
                            value={startDate ? dayjs(startDate) : null}
                            disabled={isDisabled}
                            onChange={event => setStartDate(event ? event.format('YYYY-MM-DD') : '')}
                        />
                        <DatePicker
                            label={'End Date'}
                            value={endDate ? dayjs(endDate) : null}
                            disabled={isDisabled}
                            onChange={event => setEndDate(event ? event.format('YYYY-MM-DD') : '')}
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={doSave}
                    children={'Save'}
                    disabled={isDisabled}
                />
                <Button
                    color={'error'}
                    onClick={onClose}
                    disabled={isDisabled}
                    children={'Cancel'}
                />
            </DialogActions>
        </Dialog>
    );
};

export default AvailabilityRequest;