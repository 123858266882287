import React, {useState} from "react";

import {useAuth} from "../../../../Global/Auth";
import Formatter from "../../../../Global/Formatter";
import ModalButton from "../../../../Components/ModalButton";
import EmployeeView from "../../Employee/EmployeeView";
import QuickSendWidget from "../../../../Components/Widgets/QuickSendWidget";
import EmployeeListItem from "../../../../Components/Lists/EmployeeListItem";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import ChatIcon from "@mui/icons-material/Chat";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

/**
 * AssignedEmployeesTable component.
 *
 * @returns {Element}
 * @constructor
 */
const AssignedEmployeesTable = (props) => {
    const {
        endDate,                // {String} The end date for comparison.
        onChange,               // {Function} The change callback event.
        startDate,              // {String} The start date for shift comparison.
        employees,              // {Array} The list of employees to display.
        selectable,             // {Boolean} Indicates if the table is selectable.
        defaultSelection,       // {Array} An array of selected employee records.
    } = props;

    const {hasPermissionTo} = useAuth();
    const [selection, setSelection] = useState(defaultSelection || []);

    /**
     * Indicates whether a date occurs before another.
     *
     * @param date
     * @param comparison
     * @returns {boolean}
     */
    const isBefore = (date, comparison) => {
        if (!date || !comparison) {
            return false;
        }

        return dayjs(date).isBefore(dayjs(comparison));
    };


    /**
     * Handles the employee change.
     *
     * @param employee
     */
    const handleChange = (employee) => {
        let updated = [];
        let isSelected = selection.some(record => record.id === employee.id);

        // Assign the new selection.
        if (!isSelected) {
            updated = [...selection, employee];
        } else {
            updated = selection.filter(record => record.id !== employee.id);
        }

        setSelection(updated);

        // Execute the change callback.
        if (onChange) {
            onChange(updated);
        }
    };


    /**
     * Handles the global selection change (the table header checkbox).
     */
    const handleSelectAll = () => {
        let updatedSelection = [];

        if (isIndeterminate) {
            // Clear the selection.
        } else if (allSelected) {
            // Clear the selection.
        } else {
            updatedSelection = [...employees];
        }

        setSelection(updatedSelection);

        if (onChange) {
            onChange(updatedSelection);
        }
    };


    /**
     * Indicates whether there is a selection.
     *
     * @type {boolean}
     */
    const hasSelection = !!selection && selection.length > 0;


    /**
     * Indicates if all are selected.
     *
     * @type {boolean}
     */
    const allSelected = hasSelection && selection.length === employees.length;


    /**
     * Indicates if the selection is indeterminate.
     *
     * @type {boolean}
     */
    const isIndeterminate = hasSelection && !allSelected;

    return (
        <TableContainer className={'table table--striped client-schedule__table'}>
            <Table size={'small'}>
                <colgroup>
                    {selectable && <col width={'24px'}/>}
                    <col/>
                    <col width={'26%'}/>
                    <col width={'26%'}/>
                    <col width={'15%'}/>
                </colgroup>
                <TableHead>
                    <TableRow>
                        {selectable && (
                            <TableCell sx={{padding: 0, paddingLeft: '0.5em'}}>
                                <Checkbox
                                    checked={allSelected}
                                    onChange={handleSelectAll}
                                    indeterminate={isIndeterminate}
                                />
                            </TableCell>
                        )}
                        <TableCell>
                            Employee
                        </TableCell>
                        <TableCell align={'center'}>
                            Recent Shift
                        </TableCell>
                        <TableCell align={'center'}>
                            Availability Date
                        </TableCell>
                        <TableCell align={'right'}>
                            {hasPermissionTo('VIEW_COMMUNICATIONS') && (
                                <>Chat</>
                            )}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {employees.map(employee => (
                        <TableRow key={employee.id}>
                            {selectable && (
                                <TableCell padding={'checkbox'}>
                                    <Checkbox
                                        checked={hasSelection && selection.some(record => record.id === employee.id)}
                                        onChange={() => handleChange(employee)}
                                    />
                                </TableCell>
                            )}
                            <TableCell sx={{padding: 0}}>
                                <ModalButton
                                    label={
                                        <EmployeeListItem
                                            onSelect={() => true}
                                            employee={employee}
                                        />
                                    }
                                    bodyStyle={{
                                        paddingTop: 0
                                    }}
                                    children={
                                        <EmployeeView id={employee.id}/>
                                    }
                                />
                            </TableCell>
                            <TableCell align={'center'}>
                                {!employee.lastShiftDate && (
                                    <Box className={'text__light'}>
                                        (not available)
                                    </Box>
                                )}

                                {!startDate ? (
                                    <>
                                        {Formatter.date(employee.lastShiftDate)}
                                    </>
                                ) : (
                                    <>
                                        <Box className={'d-flex__start'} sx={{justifyContent: 'center'}}>
                                            {isBefore(employee.lastShiftDate, startDate) ? (
                                                <>
                                                    <ErrorOutlineIcon
                                                        color={'error'}
                                                        className={'mr__1'}
                                                    />

                                                    <Box sx={{color: 'error.main'}}>
                                                        {Formatter.date(employee.lastShiftDate, '', false)}
                                                    </Box>
                                                </>
                                            ) : (
                                                <>
                                                    {Formatter.date(employee.lastShiftDate, '', false)}
                                                </>
                                            )}
                                        </Box>
                                    </>
                                )}

                            </TableCell>
                            <TableCell align={'center'}>
                                <Box className={'text__center p__3'}>
                                    {!employee.lastAvailabilityDate ? (
                                        <Box className={'text__light'}>
                                            (not available)
                                        </Box>
                                    ) : (
                                        <Box className={'d-flex__start'} sx={{justifyContent: 'center'}}>
                                            {isBefore(employee.lastAvailabilityDate, endDate) ? (
                                                <>
                                                    <ErrorOutlineIcon
                                                        color={'error'}
                                                        className={'mr__1'}
                                                    />

                                                    <Box sx={{color: 'error.main'}}>
                                                        {Formatter.date(employee.lastAvailabilityDate, '', false)}
                                                    </Box>
                                                </>
                                            ) : (
                                                <>
                                                    {Formatter.date(employee.lastAvailabilityDate, '', false)}
                                                </>
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            </TableCell>
                            <TableCell align={'right'}>
                                {hasPermissionTo('VIEW_COMMUNICATIONS') && (
                                    <QuickSendWidget
                                        record={employee}
                                        icon={<ChatIcon/>}
                                    />
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

export default AssignedEmployeesTable;