import React, {useState} from "react";

import API from "../Global/API";
import User from "../Models/User";
import ModalButton from "../Components/ModalButton";
import ModelSearch from "../Components/Input/ModelSearch";
import EmployeeView from "../Pages/Admin/Employee/EmployeeView";
import DialogHeading from "../Components/Typography/DialogHeading";

import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

/**
 * ChatThreadActions component.
 *
 * @returns {*}
 * @constructor
 */
const ChatThreadActions = (props) => {
    const {
        record,         // {Object} The chat thread record / context.
        employee,       // {Object} An employee record for context.
        doReload,       // {Function} A reload callback for changes.
        onReassign,     // {Function} A reassignment callback.
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [assigneeId, setAssigneeId] = useState(record.userId || '');
    const [isViewingReassignModal, setViewingReassignModal] = useState(false);
    const [isViewingDelete, setViewingDelete] = useState(false);

    /**
     * Indicates whether we have an anchor target.
     *
     * @type {boolean}
     */
    const open = Boolean(anchorEl);


    /**
     * Indicates whether we have an employee record for context.
     */
    const hasEmployee = employee && employee.id;


    /**
     * Fired whenever the menu button is clicked.
     *
     * @param event
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Closes the menu popover.
     */
    const handleClose = () => {
        setAnchorEl(null);
    };


    /**
     * Reveals the call off modal.
     */
    const handleDeleteOpen = () => {
        setViewingDelete(true);
    };


    /**
     * Hides the call off modal.
     */
    const handleDeleteClose = () => {
        setViewingDelete(false)
    };


    /**
     * Reveals the call off modal.
     */
    const handleReassignModalOpen = () => {
        setViewingReassignModal(true);
    };


    /**
     * Hides the call off modal.
     */
    const handleReassignModalClose = () => {
        setViewingReassignModal(false)
    };


    /**
     * Saves the thread with the updated assignee.
     *
     * @returns {Promise<void>}
     */
    const doSave = async () => {
        if (!assigneeId) {
            return;
        }

        setLoading(true);

        await API.put(`chat-threads/${record.id}`, {
            userId: assigneeId
        });

        setLoading(false);
        handleReassignModalClose();

        if (onReassign) {
            onReassign();
        }
    };


    /**
     * Handles the conversation delete.
     *
     * @returns {Promise<void>}
     */
    const doDelete = async () => {
        setLoading(true);
        await API.put(`chat-threads/${record.id}`, {
            isDeleted: true
        });
        setLoading(false);
        handleDeleteClose();

        if (onReassign) {
            onReassign();
        }
    };


    /**
     * Restores a chat thread.
     *
     * @returns {Promise<void>}
     */
    const doRestore = async () => {
        setLoading(true);

        const response = await API.put(`chat-threads/${record.id}`, {
            isSnoozed: false,
            isDeleted: false
        });

        setLoading(false);

        if(doReload){
            doReload(response);
        }
    };


    /**
     * Snoozes a particular conversation.
     *
     * @returns {Promise<void>}
     */
    const doSnooze = async () => {
        setLoading(true);

        const response = await API.put(`chat-threads/${record.id}`, {
            isSnoozed: true
        });

        setLoading(false);

        if(doReload){
            doReload(response);
        }
    };

    return (
        <Box key={record.id}>
            <IconButton onClick={handleClick}>
                <MoreHorizIcon/>
            </IconButton>
            <Menu
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
            >
                {hasEmployee && (
                    <ModalButton
                        label={
                            <MenuItem>
                                View Profile
                            </MenuItem>
                        }
                        bodyStyle={{
                            paddingTop: 0
                        }}
                        doRender={handleClose => (
                            <EmployeeView
                                id={employee.id}
                                onClose={handleClose}
                            />
                        )}
                    />
                )}
                {!record.isSnoozed && (
                    <MenuItem
                        onClick={doSnooze}
                        disabled={!record.id}
                        children={'Snooze Thread'}
                    />
                )}
                <MenuItem
                    onClick={handleReassignModalOpen}
                    disabled={!record.id}
                    children={'Reassign Thread'}
                />
                <Divider/>
                {!!record.isSnoozed || !!record.isDeleted ? (
                    <MenuItem
                        onClick={doRestore}
                        disabled={!record.id}
                        children={'Restore Thread'}
                        className={'menu__restore'}
                    />
                ) : (
                    <MenuItem
                        onClick={handleDeleteOpen}
                        disabled={!record.id}
                        children={'Delete Thread'}
                        className={'menu__error'}
                    />
                )}
            </Menu>

            <Dialog
                open={isViewingReassignModal}
                scroll={'body'}
                onClose={handleReassignModalClose}
                maxWidth={'xs'}
                fullWidth
            >
                <DialogContent>
                    <Box className={'columns__1'}>
                        <InputLabel
                            children={'Reassign Chat Thread'}
                            className={'form__heading form__heading--standalone'}
                        />

                        <ModelSearch
                            model={User}
                            label={'Assignee'}
                            value={record.userId}
                            required
                            disabled={isLoading}
                            onChange={value => setAssigneeId(value)}
                            renderLabel={option => !option ? '' : option.displayName}
                            filterQuery={query => `isDeleted eq {0} and displayName eq {${query}}`}
                        />
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={doSave}
                        children={'Save'}
                        disabled={isLoading}
                    />
                    <Button
                        color={'error'}
                        onClick={handleReassignModalClose}
                        children={'Cancel'}
                    />
                </DialogActions>
            </Dialog>

            {isViewingDelete && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    maxWidth={'xs'}
                    onClose={handleDeleteClose}
                    fullWidth
                >
                    <DialogHeading
                        title={"Delete Confirm"}
                        noMargin
                    />
                    <DialogContent>
                        Are you sure you'd like to delete this chat thread? By doing so, you will remove access to
                        this conversation for both you and the assigned recipient.
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleDeleteClose}
                            children={'Cancel'}
                        />
                        <Button
                            color={'error'}
                            onClick={doDelete}
                            children={'Confirm'}
                            disabled={isLoading}
                        />
                    </DialogActions>
                </Dialog>
            )}
        </Box>
    );
};

export default ChatThreadActions;