import React, {useState} from "react";

import Page from "../../Components/Page";
import TabPanel from "../../Components/TabPanel";
import {useAuth} from "../../Global/Auth";
import OfferLetterStep from "./OfferLetter/OfferLetterStep";
import W4CertificateStep from "./HirePacket/W4CertificateStep";
import ResidencyDisclosureStep from "./HirePacket/ResidencyDisclosureStep";
import EmploymentEligibilityStep from "./HirePacket/EmploymentEligibilityStep";

import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Divider from "@mui/material/Divider";

/**
 * Disclosures component.
 *
 * @returns {JSX.Element}
 * @constructor
 *
 * @description Provides an interface for employees to review their onboarding documentation.
 */
const Disclosures = () => {
    const {user} = useAuth();
    const [tab, setTab] = useState(0);

    return (
        <Page fullScreen hideHeader>
            <Tabs
                value={tab}
                variant={'scrollable'}
                onChange={(event, value) => setTab(value)}
                scrollButtons
                allowScrollButtonsMobile
            >
                <Tab label={'General Disclosures'}/>
                <Tab label={'Residency Disclosure'}/>
                <Tab label={'Employment Eligibility (I-9)'}/>
                <Tab label={'Employee Withholding (W-4)'}/>
            </Tabs>
            <Divider/>
            <TabPanel value={tab} index={0} fullWidth lazyLoad>
                <Box className={'p__3 pt__0'}>
                    <OfferLetterStep
                        step={0}
                        index={0}
                        heading={'General Disclosures'}
                        readOnly
                        employee={user}
                    />
                </Box>
            </TabPanel>
            <TabPanel value={tab} index={1} fullWidth lazyLoad>
                <Box className={'p__3 pt__0'}>
                    <ResidencyDisclosureStep
                        step={0}
                        index={0}
                        readOnly
                        employee={user}
                        settingsPrefix={'HP_RD_'}
                    />
                </Box>
            </TabPanel>
            <TabPanel value={tab} index={2} fullWidth lazyLoad>
                <Box className={'p__3 pt__0'}>
                    <EmploymentEligibilityStep
                        step={0}
                        index={0}
                        readOnly
                        employee={user}
                        settingsPrefix={'HP_EE_'}
                    />
                </Box>
            </TabPanel>
            <TabPanel value={tab} index={3} fullWidth lazyLoad>
                <Box className={'p__3 pt__0'}>
                    <W4CertificateStep
                        step={0}
                        index={0}
                        readOnly
                        employee={user}
                        settingsPrefix={'HP_W4_'}
                    />
                </Box>
            </TabPanel>
        </Page>
    );
};

export default Disclosures;