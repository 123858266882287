import React, {useEffect, useMemo, useState} from "react";

import API from "../../../Global/API";
import Logger from "../../../Global/Logger";
import {useAuth} from "../../../Global/Auth";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";

/**
 * withSynchronizedSettings component.
 *
 * @param InputComponent
 * @constructor
 */
const withSynchronizedSettings = InputComponent => props => {
    const {
        step,                   // {Number} The step number.
        disabled,               // {Boolean} Whether the form is disabled.
        employee,               // {Object} The employee object for read only context.
        readOnly,               // {Boolean} Whether the form is disabled.
        onValidate,             // {Function} The validation callback.
        expectedKeys,           // {Array} An array of expected setting keys.
        settingsPrefix,         // {String} The settings prefix for this particular form.
    } = props;

    const {user, isScope} = useAuth();
    const [index, setIndex] = useState(0);
    const [error, setError] = useState('');
    const [isLoaded, setLoaded] = useState(false);
    const [settings, setSettings] = useState({});
    const [isSubmitted, setSubmitted] = useState(false);

    /**
     * Loads the required settings for this step.
     */
    useEffect(() => {
        initialize();
    }, []);


    /**
     * Returns a setting value.
     *
     * @param key
     * @param fallback
     * @returns {*}
     */
    const setting = (key, fallback = '') => {
        if (settings.hasOwnProperty(key)) {
            return settings[key].value || '';
        }

        return fallback;
    };


    /**
     * Closes the error dialogue.
     */
    const handleErrorClose = () => {
        setError('');
    };


    /**
     * Loads all setting values.
     *
     * @returns {Promise<void>}
     */
    const initialize = async () => {
        const formatted = {};

        if (settingsPrefix) {
            /**
             * Load all settings under the step prefix. For employees, they should only have access to
             * their own settings endpoint. Users / administrators on the other hand should have the ability
             * to query all settings.
             */
            const response = !readOnly || isScope('Employee') ?
                await API.get('employee-settings', {
                    $top: 1000,
                    $filter: `key eq {"${settingsPrefix}}`,
                    $orderby: 'id desc'
                }) : await API.get('settings', {
                    $top: 1000,
                    $filter: `modelType in {Employee} and modelId in {${employee.id}} and key eq {"${settingsPrefix}}`,
                    $orderby: 'id desc'
                });

            Logger.debug('[withSynchronizedSettings] Loaded settings', response);
            response.map(setting => {
                if (!formatted.hasOwnProperty(setting.key)) {
                    formatted[setting.key] = setting
                }
            });

            // Initialize any potentially missing settings.
            if (expectedKeys && expectedKeys.length && !readOnly) {
                const responses = await Promise.all(
                    expectedKeys.map(key => (
                        doSync(key, '', {
                            override: formatted,
                            createOnly: true,
                        })
                    ))
                );

                responses.map(setting => formatted[setting.key] = setting);
                Logger.debug('[withSynchronizedSettings] Prepared default settings', expectedKeys, responses);
            }
        } else {
            Logger.warn('[withSynchronizedSettings] No prefix provided, failed to match initial settings series.');
        }

        setIndex(index + 1);
        setLoaded(true);
        setSettings({...formatted});
    };


    /**
     * Synchronizes a setting key.
     *
     * @param key
     * @param value
     * @param options
     * @returns {Promise<Object>}
     */
    const doSync = async (key, value, options = {}) => {
        let existing = {};

        const {
            override,       // {Object} An override to the default settings object.
            createOnly,     // {Boolean} Indicates if we should only perform "create" requests. Used for preparing keys.
        } = options;

        /**
         * Read-only views of forms shouldn't actually emit any update events. This is mainly
         * used for displaying the form preview to users, administrators, etc.
         */
        if (readOnly) {
            return {};
        }

        /**
         * If we aren't forcing the sync, we should wait until things are loaded in order to
         * access the default / initial settings object.
         */
        if (!createOnly && !isLoaded) {
            return {};
        }

        const matchedSettings = override || settings;

        if (matchedSettings.hasOwnProperty(key)) {
            existing = matchedSettings[key];

            // Ignore if we aren't actually changing anything here.
            if (existing.value === value) {
                return existing;
            }

            // If we're not updating anything, return the existing setting.
            if (createOnly && existing.id) {
                return existing;
            }
        }

        /**
         * Bind the setting storage. If we matched an existing parameter / setting, we'll handle
         * as an update request. Otherwise, we'll create the new setting.
         */
        Logger.debug('[withSynchronizedSettings] Updating value', key, value);

        const response = existing && existing.id ?
            await API.put(`employee-settings/${existing.id}`, {
                value
            }) : await API.post(`employee-settings`, {
                key,
                value,
                modelId: user.id,
                modelType: 'Employee'
            });

        if(!response.id){
            Logger.warn(`[withSynchronizedSettings] Failed to synchronize "${key}" setting, response attached.`, response);
            return {};
        }

        // If we aren't overriding, update the settings to include the new value.
        if (!override) {
            setSettings({
                ...settings,
                [key]: response
            });
        }

        return response;
    };


    /**
     * Handles validation along the step increment.
     *
     * @param requirements
     * @param callback
     */
    const doValidate = (requirements = [], callback = null) => {
        setSubmitted(true);

        // Determine if any of the required fields are missing.
        if (!requirements.filter(requirement => !requirement).length) {
            if (callback) {
                callback();
            } else {
                onValidate();
            }
        } else {
            setError('Please answer all required fields.')
        }
    };

    return settings && (
        <Box>
            <InputComponent
                {...props}

                key={`sync-${index}`}
                doSync={doSync}
                disabled={!isLoaded || disabled}
                getSetting={setting}
                doValidate={doValidate}
                isSubmitted={isSubmitted}
                loadedSettings={settings}
            />

            <Snackbar
                open={!!error}
                onClose={handleErrorClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={4000}
            >
                <Alert
                    sx={{width: '100%'}}
                    onClose={handleErrorClose}
                    children={error}
                    severity={'error'}
                />
            </Snackbar>
        </Box>
    );
};

export default withSynchronizedSettings;