import React from "react";

import API from "../../Global/API";
import Settings from "../../Global/Settings";
import ImageWrapper from "../ImageWrapper";
import EmployeeStatusLabel from "../../Pages/Admin/Employee/EmployeeStatusLabel";

import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

/**
 * EmployeeListItem component.
 *
 * @returns {*}
 * @constructor
 */
const EmployeeListItem = (props) => {
    const {
        noCache,                // {Boolean} Indicates if we should bypass the image caching mechanism.
        employee,               // {Object} The employee record.
        selected,               // {Boolean} Indicates whether to select the item.
        onSelect,               // {Function} A selection callback, triggered whenever the user clicks on the item.
        imageSize,              // {Number} An optional image size replacement (defaults to 36).
        secondary,              // {Component} An optional secondary action slot.
        actionSlot,             // {Component} An optional inline action slot.
    } = props;

    /**
     * The related status record.
     *
     * @type {Object}
     */
    const status = employee.status || {};


    /**
     * The related specialty record.
     *
     * @type {Object}
     */
    const specialty = employee.specialty || {};


    /**
     * Processes the selection event.
     *
     * @param event
     * @param employee
     */
    const handleSelect = (event, employee) => {
        if (onSelect) {
            onSelect(employee, event);
        }
    };

    return (
        <>
            <ListItem
                sx={{paddingTop: 0, paddingBottom: 0}}
                className={`list-item__row`}
                disableGutters
                secondaryAction={secondary}
            >
                <ListItemButton
                    onClick={(event) => handleSelect(event, employee)}
                    selected={selected}
                    className={`w__100`}
                    disableGutters
                    sx={{
                        paddingTop: '0.5em',
                        paddingLeft: '1em',
                        paddingBottom: '0.5em',
                        pointerEvents: !onSelect ? 'none' : 'inherit',
                    }}
                >
                    <Box width={'100%'}>
                        <Box className={'d-flex__justify'}>
                            <Box className={`d-flex__start`} sx={{
                                width: '100%',
                                paddingTop: '0.2em',
                                paddingBottom: '0.2em'
                            }}>
                                <ImageWrapper
                                    src={API.getFilePath(employee.image)}
                                    width={imageSize || 36}
                                    height={imageSize || 36}
                                    noCache={noCache}
                                    className={'mr__2'}
                                />

                                <Box
                                    className={'d-flex__justify flex__grow'}
                                >
                                    <div>
                                        <b>{employee.lastName}, {employee.firstName}</b>

                                        <Box
                                            className={'d-flex__start text__small'}
                                        >
                                            <Box className={'divider__wrapper'}>
                                                {(specialty.id || status.id) && (
                                                    <>
                                                        {specialty.id && (
                                                            <Box
                                                                className={'text__light d-inline__block'}
                                                                children={specialty.name || Settings.emptyContentLabel}
                                                            />
                                                        )}

                                                        {status.id && (
                                                            <>
                                                                <span className={'divider'}/>
                                                                <EmployeeStatusLabel
                                                                    record={employee}
                                                                />
                                                            </>
                                                        )}
                                                    </>
                                                )}
                                            </Box>
                                        </Box>
                                    </div>

                                    {actionSlot && (
                                        <>{actionSlot}</>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </ListItemButton>
            </ListItem>
        </>
    );
};

export default EmployeeListItem;