import React, {useRef, useState} from "react";

import API from "../../../Global/API";
import ModalButton from "../../../Components/ModalButton";
import SmallButton from "../../../Components/Widgets/CommunicationFeed/SmallButton";
import EmployeeView from "../Employee/EmployeeView";
import SectionHeading from "../../../Components/Typography/SectionHeading";
import GroupChatDialog from "../../../Components/Widgets/GroupChatDialog";
import EmployeeListItem from "../../../Components/Lists/EmployeeListItem";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";
import {DateTimePicker} from "@mui/x-date-pickers";
import ContentPasteOffIcon from '@mui/icons-material/ContentPasteOff';

/**
 * The number of results or page to sequentially pull data.
 *
 * @type {number}
 */
const perPageLimit = 250;


/**
 * MissingTimeSheets component.
 *
 * @returns {*}
 * @constructor
 */
const MissingTimeSheets = (props) => {
    const {
        loading,            // {Boolean} Indicates if the report is loading.
        onComplete          // {Function} A post-completion callback from whenever the report has results.
    } = props;

    const resultsRef = useRef(null);
    const [endDate, setEndDate] = useState(dayjs().endOf('week').format('YYYY-MM-DD 10:00:00'));
    const [isLoading, setLoading] = useState(false);
    const [selection, setSelection] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().startOf('week').format('YYYY-MM-DD 10:00:00'))
    const [showResults, setShowResults] = useState(false);
    const [employeeResults, setEmployeeResults] = useState([]);
    const [isSendingMessage, setSendingMessage] = useState(false);

    /**
     * Loads all of our employee rows.
     *
     * @returns {Promise<void>}
     */
    const getAllEmployees = async () => {
        let promises = [];
        const filters = [];

        if (employees && employees.length) {
            filters.push(`id in {${employees.map(employee => employee.id).join(',')}}`)
        }

        // Load all the clients page by page until we've reached the end.
        const count = await API.get('employees/count', {
            $top: perPageLimit,
            $filter: `isActive eq {1} and isDeleted eq {0} ${filters.length ? `and ${filters.join(' and ')}` : ''}`,
        });

        for (let page = 0; page < count.pages; page++) {
            promises.push(
                API.get('employees', {
                    $top: perPageLimit,
                    $skip: page * perPageLimit,
                    endDate,
                    $select: '*,approvedTimeSheets',
                    $expand: 'specialty,state,status',
                    $filter: `isActive eq {1} and isDeleted eq {0} ${filters.length ? `and ${filters.join(' and ')}` : ''}`,
                    $orderby: `lastName asc`,
                    startDate
                })
            );
        }

        const results = await Promise.all(promises);
        setEmployeeResults(results.flat());
    };


    /**
     * Indicates whether there is a selection.
     *
     * @type {boolean}
     */
    const hasSelection = !!selection && selection.length > 0;


    /**
     * Indicates if all are selected.
     *
     * @type {boolean}
     */
    const allSelected = hasSelection && selection.length === employees.length;


    /**
     * Indicates if the selection is indeterminate.
     *
     * @type {boolean}
     */
    const isIndeterminate = hasSelection && !allSelected;


    /**
     * Handles the employee change.
     *
     * @param employee
     */
    const handleChange = (employee) => {
        let updated = [];
        let isSelected = selection.some(record => record.id === employee.id);

        // Assign the new selection.
        if (!isSelected) {
            updated = [...selection, employee];
        } else {
            updated = selection.filter(record => record.id !== employee.id);
        }

        setSelection(updated);
    };


    /**
     * Handles the global selection change (the table header checkbox).
     */
    const handleSelectAll = () => {
        let updatedSelection = [];

        if (isIndeterminate) {
            // Clear the selection.
        } else if (allSelected) {
            // Clear the selection.
        } else {
            updatedSelection = [...filteredEmployees];
        }

        setSelection(updatedSelection);
    };


    /**
     * Loads the report results from the backend.
     *
     * @returns {Promise<void>}
     */
    const getResults = async () => {
        setLoading(true);

        await Promise.all([
            getAllEmployees()
        ]);

        setShowResults(true);
        setLoading(false);

        if (onComplete) {
            onComplete(resultsRef);
        }
    };


    /**
     * Returns the filtered employees.
     *
     * @type {*[]}
     */
    const filteredEmployees = employeeResults.filter(employee => {
        const {approvedTimeSheets} = employee;

        return !approvedTimeSheets || !approvedTimeSheets.length;
    });


    /**
     * Closes the group message dialog.
     */
    const handleMessageClose = () => {
        setSendingMessage(false);
    };


    /**
     * Reveals the group message dialog.
     */
    const handleMessageOpen = () => {
        setSendingMessage(true);
    };


    /**
     * Indicates if the report is in progress.
     *
     * @type {boolean}
     */
    const inProgress = isLoading || loading;

    return (
        <Box className={'d-flex'}>
            <Box
                sx={{width: 400, overflowY: 'hidden'}}
                className={'border__right p__3 full__height--left-tabs'}
            >
                <Box className={'columns__1'}>
                    <Box className={'columns__1'}>
                        <DateTimePicker
                            label={'Start Date'}
                            value={startDate ? dayjs(startDate) : null}
                            disabled={inProgress}
                            onChange={event => setStartDate(event ? event.format('YYYY-MM-DD HH:mm:ss') : '')}
                            fullWidth
                        />
                        <DateTimePicker
                            label={'End Date'}
                            value={endDate ? dayjs(endDate) : null}
                            disabled={inProgress}
                            onChange={event => setEndDate(event ? event.format('YYYY-MM-DD HH:mm:ss') : '')}
                            fullWidth
                        />
                    </Box>

                    <Button
                        variant={'outlined'}
                        onClick={getResults}
                        children={'Apply'}
                        disabled={inProgress}
                    />

                    <Box className={'disclaimer__alert d-flex__start'}>
                        <ContentPasteOffIcon
                            fontSize={'inherit'}
                            className={'mr__2'}
                        />
                        This report displays a list of employees who haven't submitted a time sheet under
                        the effective date range.
                    </Box>
                </Box>
            </Box>

            <Box
                sx={{borderRadius: 0}}
                className={`flex__grow full__height--left-tabs ${!showResults ? 'well__container' : ''}`}
            >
                {inProgress && <LinearProgress sx={{width: '100%'}}/>}

                {!showResults && (
                    <Box className={'p__3 text__center text__disclaimer'}>
                        Your results will display here.
                    </Box>
                )}

                {showResults && (
                    <Box className={'p__3 columns__1'}>
                        <Box>
                            <SectionHeading
                                title={`Employees`}
                                primary
                                action={
                                    <SmallButton
                                        onClick={handleMessageOpen}
                                        children={
                                            <span
                                                style={{color: '#fff'}}
                                                children={`Send Group Message (${selection.length})`}
                                            />
                                        }
                                        disabled={!selection || !selection.length}
                                        className={'text__small text__thin'}
                                    />
                                }
                            />
                            <TableContainer className={'table table--striped result__set'}>
                                <Table size={'small'}>
                                    <colgroup>
                                        <col width={'24px'}/>
                                        <col/>
                                    </colgroup>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{padding: 0, paddingLeft: '0.5em'}}>
                                                <Checkbox
                                                    checked={allSelected}
                                                    onChange={handleSelectAll}
                                                    indeterminate={isIndeterminate}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                Employee
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!filteredEmployees.length && (
                                            <TableRow>
                                                <TableCell colSpan={2} className={'text__disclaimer text__center'}
                                                    align={'center'}>
                                                    No results available.
                                                </TableCell>
                                            </TableRow>
                                        )}

                                        {filteredEmployees.map(employee => {

                                            return [
                                                <TableRow key={`employee-${employee.id}`}>
                                                    <TableCell sx={{paddingRight: 0, paddingLeft: '0.5em'}}
                                                        className={'v-align__top'}>
                                                        <Checkbox
                                                            checked={hasSelection && selection.some(record => record.id === employee.id)}
                                                            onChange={() => handleChange(employee)}
                                                        />
                                                    </TableCell>
                                                    <TableCell sx={{padding: 0}} className={'v-align__top'}>
                                                        <ModalButton
                                                            label={
                                                                <EmployeeListItem
                                                                    onSelect={() => true}
                                                                    employee={employee}
                                                                />
                                                            }
                                                            bodyStyle={{
                                                                paddingTop: 0
                                                            }}
                                                            children={
                                                                <EmployeeView id={employee.id}/>
                                                            }
                                                        />
                                                    </TableCell>
                                                </TableRow>,
                                            ];
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                )}
            </Box>

            <GroupChatDialog
                open={isSendingMessage}
                onClose={handleMessageClose}
                selection={selection}
            />

            {/*The hidden display for PDF rendering.*/}
            <Box className={'d-hidden'}>
                <Box ref={resultsRef}>
                    <table className={'table--bordered'}>
                        <tbody>
                        <tr>
                            <td>
                                <b>Employee</b>
                            </td>
                        </tr>

                        {!filteredEmployees.length && (
                            <tr>
                                <td>
                                    No results available.
                                </td>
                            </tr>
                        )}

                        {filteredEmployees.map(employee => {
                            return [
                                <tr key={`employee-${employee.id}`}>
                                    <td style={{verticalAlign: 'top'}}>
                                        {employee.lastName}, {employee.firstName}
                                    </td>
                                </tr>,
                            ];
                        })}
                        </tbody>
                    </table>
                    <br/>
                </Box>
            </Box>
        </Box>
    );
};

export default MissingTimeSheets;