import React, {useState} from "react";

import API from "../../Global/API";
import DialogHeading from "../../Components/Typography/DialogHeading";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Radio from "@mui/material/Radio";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";

/**
 * ContactAttempt component.
 *
 * @returns {*}
 * @constructor
 */
const ContactAttempt = (props) => {
    const {
        record,         // {Object} The employee record.
        onSave,         // {Function} A post-save callback function.
        className,      // {String} An additional class to append to the container.
    } = props;

    const [note, setNote] = useState('');
    const [type, setType] = useState('Call');
    const [open, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);

    /**
     * Handlers for toggling the dialog.
     */
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    /**
     * Handles the contact type update.
     *
     * @param event
     */
    const handleChange = (event) => {
        setType(event.target.value);
    };


    /**
     * Process the note save.
     *
     * @returns {Promise<void>}
     */
    const doSave = async () => {
        setLoading(true);
        const attempts = (record.contactAttempts || 0) + 1;

        // Determine the appropriate timer string for the note.
        const now = dayjs().utc();
        const date = dayjs.utc(record.contactDate || (record.statusEffectiveDate || record.createdDate));
        const time = now.diff(date, 'milliseconds');
        const hours = (Math.floor((time / (1000 * 60 * 60))));
        const minutes = (Math.floor((time / 1000 / 60) % 60));
        const seconds = (Math.floor((time / 1000) % 60));

        // Build the timer string in HH:MM:SS format.
        const timer = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

        const response = await API.put(`employee/${record.id}`, {
            contactDate: dayjs().utc().format('YYYY-MM-DD HH:mm:ss'),
            isHighlighted: false,
            contactAttempts: attempts
        });

        // Map the contact type to an Emoji symbol.
        const typeEmoji = {
            'Call': '📞',
            'Text': '💬',
            'Email': '✉️',
        };

        await API.post(`notes`, {
            note: `Contact Attempt #${attempts} (${timer}) ${typeEmoji[type]}${note ? `: ${note}` : ``}`,
            modelId: record.id,
            modelType: 'Employee',
        });

        setNote('');
        setOpen(false);
        setLoading(false);

        // Handle the post-save callback.
        if(onSave){
            onSave(response);
        }
    };

    return (
        <>
            <Button
                size={'small'}
                color={'warning'}
                onClick={handleOpen}
                variant={'contained'}
                className={className}
            >
                Contact ({record.contactAttempts || 0})
            </Button>
            <Dialog
                open={open}
                scroll={'body'}
                onClose={handleClose}
                maxWidth={'sm'}
                fullWidth
            >
                <DialogHeading
                    title={'Contact Details'}
                    noMargin
                />
                <DialogContent>
                    <Box className={'columns__1'}>
                        <FormControl>
                            <FormLabel>How did you contact this employee?</FormLabel>
                            <RadioGroup
                                row
                                value={type}
                                onChange={handleChange}
                            >
                                <FormControlLabel
                                    value={'Call'}
                                    label={'Call'}
                                    control={<Radio />}
                                />
                                <FormControlLabel
                                    value={'Text'}
                                    label={'Text'}
                                    control={<Radio />}
                                />
                                <FormControlLabel
                                    value={'Email'}
                                    label={'Email'}
                                    control={<Radio />}
                                />
                            </RadioGroup>
                        </FormControl>
                        <TextField
                            rows={4}
                            value={note}
                            label={'Please provide any additional details...'}
                            disabled={isLoading}
                            onChange={event => setNote(event.target.value)}
                            multiline
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={doSave}
                        children={'Save'}
                        disabled={isLoading}
                    />
                    <Button
                        color={'error'}
                        onClick={handleClose}
                        children={'Close'}
                        disabled={isLoading}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default ContactAttempt;