import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import LogoHeading from "../../../Components/Typography/LogoHeading";
import StepControls from "../../../Components/StepControls";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import Radio from "@mui/material/Radio";
import Table from "@mui/material/Table";
import Snackbar from "@mui/material/Snackbar";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import TableBody from "@mui/material/TableBody";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import {DatePicker} from "@mui/x-date-pickers";
import TableContainer from "@mui/material/TableContainer";
import FormControlLabel from "@mui/material/FormControlLabel";

/**
 * JobInterviewStep component.
 *
 * @constructor
 */
const JobInterviewStep = (props) => {
    const {
        step,                   // {Number} The current step of the form.
        loading,                // {Boolean} Indicates whether the form is currently loading.
        readOnly,               // {Boolean} Indicates whether the form is read-only.
        employee,               // {Object} The employee record for the authenticated employee.
        onStepChange            // {Function} Handles the step transition.
    } = props;

    const [error, setError] = useState('');
    const [isLoaded, setLoaded] = useState(false);
    const [settings, setSettings] = useState({});
    const [disabled, setDisabled] = useState(null);
    const [isSubmitted, setSubmitted] = useState(false);
    const [commuteType, setCommuteType] = useState(null);
    const [covidStatus, setCovidStatus] = useState(null);
    const [difficulties, setDifficulties] = useState(null);
    const [workedAgency, setWorkedAgency] = useState(null);
    const [isInitialized, setInitialized] = useState(false);
    const [disabledOther, setDisabledOther] = useState('');
    const [numberOfHours, setNumberOfHours] = useState('');
    const [confirmWork24, setConfirmWork24] = useState(null);
    const [multiJobYesNo, setMultiJobYesNo] = useState(null);
    const [weekdayNights, setWeekdayNights] = useState(false);
    const [weekendNights, setWeekendNights] = useState(false);
    const [agenciesWorked, setAgenciesWorked] = useState('');
    const [criminalRecord, setCriminalRecord] = useState(null);
    const [canWork40Hours, setCanWork40Hours] = useState(null);
    const [weekdayDaylight, setWeekdayDaylight] = useState(false);
    const [weekdayEvenings, setWeekdayEvenings] = useState(false);
    const [weekendEvenings, setWeekendEvenings] = useState(false);
    const [weekendDaylight, setWeekendDaylight] = useState(false);
    const [reasonForLeaving, setReasonForLeaving] = useState('');
    const [facilitiesWorked, setFacilitiesWorked] = useState('');
    const [leavingToWorkFor, setLeavingToWorkFor] = useState(null);
    const [expectedStartDate, setExpectedStartDate] = useState('');
    const [difficultiesOther, setDifficultiesOther] = useState('');
    const [previousEmployers, setPreviousEmployers] = useState('');
    const [currentlyEmployed, setCurrentlyEmployed] = useState(null);
    const [currentEmployerName, setCurrentEmployerName] = useState('');
    const [criminalRecordOther, setCriminalRecordOther] = useState('');
    const [currentFacilityName, setCurrentFacilityName] = useState('');
    const [currentEmployerAgency, setCurrentEmployerAgency] = useState(null);
    const [currentEmployerAgencyName, setCurrentEmployerAgencyName] = useState('');

    /**
     * Loads all user settings for the job interview portion of the invite process.
     */
    useEffect(() => {
        getSettings();
    }, []);


    /**
     * Disabled or Pregnant - Yes / No
     */
    useEffect(() => {
        sync('JI_DISABLED', disabled);
    }, [disabled]);


    /**
     * Commute Type - (Vehicle Options)
     */
    useEffect(() => {
        sync('JI_COMMUTE_TYPE', commuteType);
    }, [commuteType]);


    /**
     * Covid Status - (Covid Options)
     */
    useEffect(() => {
        sync('JI_COVID_STATUS', covidStatus);
    }, [covidStatus]);


    /**
     * Difficulties With Working - Yes / No
     */
    useEffect(() => {
        sync('JI_DIFFICULTIES', difficulties);
    }, [difficulties]);


    /**
     * Worked Agency - Yes / No
     */
    useEffect(() => {
        sync('JI_WORKED_AGENCY', workedAgency);
    }, [workedAgency]);


    /**
     * Disabled Other - Test
     */
    useEffect(() => {
        sync('JI_DISABLED_OTHER', disabledOther);
    }, [disabledOther]);


    /**
     * Number of Hours Per Week - Text
     */
    useEffect(() => {
        sync('JI_NUMBER_OF_HOURS', numberOfHours);
    }, [numberOfHours]);


    /**
     * Confirm 24 Hours Minimum - Yes / No
     */
    useEffect(() => {
        sync('JI_CONFIRM_WORK_24', confirmWork24);
    }, [confirmWork24]);


    /**
     * Multiple Agencies - Yes / No
     */
    useEffect(() => {
        sync('JI_MULTI_JOB_YES_NO', multiJobYesNo);
    }, [multiJobYesNo]);


    /**
     * Weekday Nights - Checkbox
     */
    useEffect(() => {
        sync('JI_WEEKDAY_NIGHTS', weekdayNights);
    }, [weekdayNights]);


    /**
     * Weekend Nights - Checkbox
     */
    useEffect(() => {
        sync('JI_WEEKEND_NIGHTS', weekendNights);
    }, [weekendNights]);


    /**
     * Agencies Worked - Text
     */
    useEffect(() => {
        sync('JI_AGENCIES_WORKED', agenciesWorked);
    }, [agenciesWorked]);


    /**
     * Able to Work 40 Hours - Yes / No
     */
    useEffect(() => {
        sync('JI_CAN_WORK_40_HOURS', canWork40Hours);
    }, [canWork40Hours]);


    /**
     * Criminal Record - Yes / No
     */
    useEffect(() => {
        sync('JI_CRIMINAL_RECORD', criminalRecord);
    }, [criminalRecord]);


    /**
     * Weekday Daylight - Checkbox
     */
    useEffect(() => {
        sync('JI_WEEKDAY_DAYLIGHT', weekdayDaylight);
    }, [weekdayDaylight]);


    /**
     * Weekday Evenings - Checkbox
     */
    useEffect(() => {
        sync('JI_WEEKDAY_EVENINGS', weekdayEvenings);
    }, [weekdayEvenings]);


    /**
     * Weekend Evenings - Checkbox
     */
    useEffect(() => {
        sync('JI_WEEKEND_EVENINGS', weekendEvenings);
    }, [weekendEvenings]);


    /**
     * Weekend Daylight - Checkbox
     */
    useEffect(() => {
        sync('JI_WEEKEND_DAYLIGHT', weekendDaylight);
    }, [weekendDaylight]);


    /**
     * Reason for Leaving Former Employer
     */
    useEffect(() => {
        sync('JI_REASON_FOR_LEAVING', reasonForLeaving);
    }, [reasonForLeaving]);


    /**
     * Any Previously-Worked Facilities
     */
    useEffect(() => {
        sync('JI_FACILITIES_WORKED', facilitiesWorked);
    }, [facilitiesWorked]);


    /**
     * Leaving Employer for Reliant - Yes / No
     */
    useEffect(() => {
        sync('JI_LEAVING_TO_WORK_FOR', leavingToWorkFor);
    }, [leavingToWorkFor]);


    /**
     * The Expected Start Date w/ Reliant - Date
     */
    useEffect(() => {
        sync('JI_EXPECTED_START_DATE', expectedStartDate);
    }, [expectedStartDate]);


    /**
     * Explain Difficulties for Working - Text
     */
    useEffect(() => {
        sync('JI_DIFFICULTIES_OTHER', difficultiesOther);
    }, [difficultiesOther]);


    /**
     * Previous Employers - Text
     */
    useEffect(() => {
        sync('JI_PREVIOUS_EMPLOYERS', previousEmployers);
    }, [previousEmployers]);


    /**
     * Currently Employed - Yes / No
     */
    useEffect(() => {
        sync('JI_CURRENTLY_EMPLOYED', currentlyEmployed);
    }, [currentlyEmployed]);


    /**
     * Current Employer Name - Text
     */
    useEffect(() => {
        sync('JI_CURRENT_EMPLOYER_NAME', currentEmployerName);
    }, [currentEmployerName]);


    /**
     * Criminal Record Explain - Text
     */
    useEffect(() => {
        sync('JI_CRIMINAL_RECORD_OTHER', criminalRecordOther);
    }, [criminalRecordOther]);


    /**
     * Current Facility Name - Text
     */
    useEffect(() => {
        sync('JI_CURRENT_FACILITY_NAME', currentFacilityName);
    }, [currentFacilityName]);


    /**
     * Current Employer is Agency - Yes / No
     */
    useEffect(() => {
        sync('JI_CURRENT_EMPLOYER_AGENCY', currentEmployerAgency);
    }, [currentEmployerAgency]);


    /**
     * Current Employer Agency Name - Text
     */
    useEffect(() => {
        sync('JI_CURRENT_EMPLOYER_AGENCY_NAME', currentEmployerAgencyName);
    }, [currentEmployerAgencyName]);


    /**
     * Synchronize setting values to the various state parameters.
     */
    useEffect(() => {
        if (!isLoaded || isInitialized) {
            return;
        }

        setDisabled(setting('JI_DISABLED', null));
        setCommuteType(setting('JI_COMMUTE_TYPE', null));
        setCovidStatus(setting('JI_COVID_STATUS', null));
        setDifficulties(setting('JI_DIFFICULTIES', null));
        setWorkedAgency(setting('JI_WORKED_AGENCY', null));
        setDisabledOther(setting('JI_DISABLED_OTHER', ''));
        setNumberOfHours(setting('JI_NUMBER_OF_HOURS', ''));
        setConfirmWork24(setting('JI_CONFIRM_WORK_24', null));
        setMultiJobYesNo(setting('JI_MULTI_JOB_YES_NO', null));
        setWeekdayNights(setting('JI_WEEKDAY_NIGHTS', false));
        setWeekendNights(setting('JI_WEEKEND_NIGHTS', false));
        setAgenciesWorked(setting('JI_AGENCIES_WORKED', ''));
        setCanWork40Hours(setting('JI_CAN_WORK_40_HOURS', null));
        setCriminalRecord(setting('JI_CRIMINAL_RECORD', null));
        setWeekdayDaylight(setting('JI_WEEKDAY_DAYLIGHT', false));
        setWeekdayEvenings(setting('JI_WEEKDAY_EVENINGS', false));
        setWeekendEvenings(setting('JI_WEEKEND_EVENINGS', false));
        setWeekendDaylight(setting('JI_WEEKEND_DAYLIGHT', false));
        setReasonForLeaving(setting('JI_REASON_FOR_LEAVING', ''));
        setFacilitiesWorked(setting('JI_FACILITIES_WORKED', ''));
        setLeavingToWorkFor(setting('JI_LEAVING_TO_WORK_FOR', null));
        setExpectedStartDate(setting('JI_EXPECTED_START_DATE', ''));
        setDifficultiesOther(setting('JI_DIFFICULTIES_OTHER', ''));
        setPreviousEmployers(setting('JI_PREVIOUS_EMPLOYERS', ''));
        setCurrentlyEmployed(setting('JI_CURRENTLY_EMPLOYED', null));
        setCurrentEmployerName(setting('JI_CURRENT_EMPLOYER_NAME', ''));
        setCriminalRecordOther(setting('JI_CRIMINAL_RECORD_OTHER', ''));
        setCurrentFacilityName(setting('JI_CURRENT_FACILITY_NAME', ''));
        setCurrentEmployerAgency(setting('JI_CURRENT_EMPLOYER_AGENCY', null));
        setCurrentEmployerAgencyName(setting('JI_CURRENT_EMPLOYER_AGENCY_NAME', ''));

        setTimeout(() => {
            setInitialized(true);
        }, 500);
    }, [isLoaded]);


    /**
     * The disabled status of each input.
     */
    const isDisabled = loading || readOnly || !isInitialized;


    /**
     * Returns a setting value.
     *
     * @param key
     * @param fallback
     * @returns {*}
     */
    const setting = (key, fallback) => {
        if(settings.hasOwnProperty(key)){
            return settings[key].value;
        }

        sync(key, fallback);
        return fallback;
    };


    /**
     * Closes the error dialogue.
     */
    const handleErrorClose = () => {
        setError('');
    };


    /**
     * Synchronizes a setting key.
     *
     * @param key
     * @param value
     * @returns {Promise<void>}
     */
    const sync = async (key, value) => {
        let existing = {};

        // Ignore if we haven't yet loaded our settings by prefix.
        if (readOnly || !isLoaded) {
            return;
        }

        // Determine if we have a match already for this property.
        if (settings.hasOwnProperty(key)) {
            existing = settings[key];

            if(existing.value === value){
                return existing;
            }
        }

        // Perform the backend update and bind the setting storage.
        const response = existing && existing.id ?
            await API.put(`employee-settings/${existing.id}`, {
                value
            }) : await API.post(`employee-settings`, {
                key,
                value,
                modelId: employee.id,
                modelType: 'Employee'
            });

        // Update the settings to include the new value.
        setSettings({
            ...settings,
            [key]: response
        });
    };


    /**
     * Loads all setting values.
     *
     * @returns {Promise<void>}
     */
    const getSettings = async () => {
        const response = readOnly ? await API.get('settings', {
            $top: 1000,
            $filter: `modelType in {Employee} and modelId in {${employee.id}} and key eq {"JI_}`,
            $orderby: 'id desc'
        }) : await API.get('employee-settings', {
            $top: 1000,
            $filter: `key eq {"JI_}`,
            $orderby: 'id desc'
        });

        const formatted = {};

        response.map(setting => {
            if (!formatted.hasOwnProperty(setting.key)) {
                formatted[setting.key] = setting
            }
        });

        setLoaded(true);
        setSettings({...formatted});
    };


    /**
     * Validates all the form inputs.
     *
     * @param updated
     * @returns {*}
     */
    const doValidate = (updated) => {
        if (updated < step) {
            return onStepChange(updated);
        }

        setSubmitted(true);

        // Build out the entire list of required fields depending on
        // the form criteria / user flow.
        //
        let requirements = [
            covidStatus,
            numberOfHours,
            commuteType,
            criminalRecord,
            disabled,
            difficulties,
            currentlyEmployed
        ];

        if (criminalRecord === 'yes') {
            requirements.push(criminalRecordOther);
        }

        if (disabled === 'yes') {
            requirements.push(disabledOther);
        }

        if (difficulties === 'yes') {
            requirements.push(difficultiesOther);
        }

        if (currentlyEmployed === 'no') {
            requirements = [
                ...requirements,
                previousEmployers,
                workedAgency
            ];

            if (workedAgency === 'yes') {
                requirements = [
                    ...requirements,
                    agenciesWorked,
                    facilitiesWorked
                ];
            }
        } else {
            requirements = [
                ...requirements,
                currentEmployerAgency,
                leavingToWorkFor
            ];

            if (currentEmployerAgency === 'yes') {
                requirements = [
                    ...requirements,
                    currentEmployerAgencyName,
                    currentFacilityName,
                    multiJobYesNo
                ]
            } else {
                requirements = [
                    ...requirements,
                    currentEmployerName,
                ]
            }

            if (leavingToWorkFor === 'yes') {
                requirements = [
                    ...requirements,
                    reasonForLeaving,
                    expectedStartDate,
                    canWork40Hours
                ];

                if (canWork40Hours === 'no') {
                    requirements.push(confirmWork24)
                }
            } else {
                requirements = [
                    ...requirements,
                    expectedStartDate,
                    confirmWork24
                ];
            }
        }

        // Determine if any of the required fields are missing.
        if (!requirements.filter(requirement => !requirement).length) {
            onStepChange(step + 1);
        } else {
            setError('Please answer all required fields.')
        }
    };

    return (
        <>
            <LogoHeading title={'Job Interview'}/>

            <Box className={'columns__1'}>
                <FormControl>
                    <FormLabel
                        error={isSubmitted && !covidStatus}
                        required
                        className={'d-block mb__2'}
                    >
                        Which statement applies to you?
                    </FormLabel>
                    <RadioGroup
                        value={covidStatus}
                        onChange={event => setCovidStatus(event.target.value)}
                    >
                        <FormControlLabel
                            value="vaccinated"
                            label="I am currently vaccinated from Covid."
                            control={<Radio disabled={isDisabled}/>}
                        />
                        <FormControlLabel
                            value="pre_vaccinated"
                            label="I am in the process of getting vaccinated from Covid."
                            control={<Radio disabled={isDisabled}/>}
                        />
                        <FormControlLabel
                            value="medical_exemption"
                            label="I have a written medical exemption letter from my physician."
                            control={<Radio disabled={isDisabled}/>}
                        />
                        <FormControlLabel
                            value="religious_exemption"
                            label="I have a religious exemption."
                            control={<Radio disabled={isDisabled}/>}
                        />
                        <FormControlLabel
                            value="not_receiving"
                            label="I have no exemption and have no plans for getting vaccinated against Covid."
                            control={<Radio disabled={isDisabled}/>}
                        />
                    </RadioGroup>
                </FormControl>

                <TableContainer className={'table table--striped table--responsive'}>
                    <FormLabel className={'d-block'} required>
                        Which shifts are you available to work?
                    </FormLabel>
                    <Table size={'small'}>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={2} sx={{
                                    paddingTop: '1em',
                                    paddingLeft: 0,
                                    paddingRight: 0,
                                    paddingBottom: '1em',
                                }}>
                                    <Alert severity={'info'}>
                                        Do NOT just select daylight, and check all that apply.
                                    </Alert>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Weekdays
                                </TableCell>
                                <TableCell>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={weekdayDaylight}
                                                    disabled={isDisabled}
                                                    onChange={event => setWeekdayDaylight(event.target.checked)}
                                                />
                                            }
                                            label="Daylight"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={weekdayEvenings}
                                                    disabled={isDisabled}
                                                    onChange={event => setWeekdayEvenings(event.target.checked)}
                                                />
                                            }
                                            label="Evenings"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={weekdayNights}
                                                    disabled={isDisabled}
                                                    onChange={event => setWeekdayNights(event.target.checked)}
                                                />
                                            }
                                            label="Nights"
                                        />
                                    </FormGroup>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Weekends
                                </TableCell>
                                <TableCell>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={weekendDaylight}
                                                    disabled={isDisabled}
                                                    onChange={event => setWeekendDaylight(event.target.checked)}
                                                />
                                            }
                                            label="Daylight"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={weekendEvenings}
                                                    disabled={isDisabled}
                                                    onChange={event => setWeekendEvenings(event.target.checked)}
                                                />
                                            }
                                            label="Evenings"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={weekendNights}
                                                    disabled={isDisabled}
                                                    onChange={event => setWeekendNights(event.target.checked)}
                                                />
                                            }
                                            label="Nights"
                                        />
                                    </FormGroup>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <FormControl variant="standard">
                    <FormLabel
                        error={isSubmitted && !numberOfHours}
                        required
                        className={'d-block mb__2'}
                    >
                        How many hours are you interested in working each week?
                    </FormLabel>
                    <TextField
                        value={numberOfHours}
                        onFocus={() => sync('JI_NUMBER_OF_HOURS', numberOfHours)}
                        required
                        disabled={isDisabled}
                        onChange={event => setNumberOfHours(event.target.value)}
                        placeholder={'# of Hours'}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel
                        error={isSubmitted && !commuteType}
                        required
                        className={'d-block mb__2'}
                    >
                        How do you commute to work?
                    </FormLabel>
                    <RadioGroup
                        value={commuteType}
                        onChange={event => setCommuteType(event.target.value)}
                    >
                        <FormControlLabel
                            value="self_owned"
                            label="I have my own vehicle."
                            control={<Radio disabled={isDisabled}/>}
                        />
                        <FormControlLabel
                            value="public_transportation"
                            label="I use public transportation."
                            control={<Radio disabled={isDisabled}/>}
                        />
                        <FormControlLabel
                            value="vehicle_service"
                            label="I use Uber / Lyft."
                            control={<Radio disabled={isDisabled}/>}
                        />
                        <FormControlLabel
                            value="not_available"
                            label="I'm in the process of figuring that out."
                            control={<Radio disabled={isDisabled}/>}
                        />
                    </RadioGroup>
                </FormControl>

                <FormControl>
                    <FormLabel
                        error={isSubmitted && (!criminalRecord || criminalRecord === 'yes' && !criminalRecordOther)}
                        required
                        className={'d-block mb__2'}
                    >
                        Do you have a criminal record within the last 7 years?
                    </FormLabel>
                    <RadioGroup
                        row
                        value={criminalRecord}
                        onChange={event => setCriminalRecord(event.target.value)}
                    >
                        <FormControlLabel
                            value="no"
                            label="No"
                            control={<Radio disabled={isDisabled}/>}
                        />
                        <FormControlLabel
                            value="yes"
                            label="Yes"
                            control={<Radio disabled={isDisabled}/>}
                        />
                    </RadioGroup>

                    {criminalRecord === 'yes' && (
                        <TextField
                            rows={3}
                            value={criminalRecordOther}
                            error={isSubmitted && criminalRecord === 'yes' && !criminalRecordOther}
                            onFocus={() => sync('JI_CRIMINAL_RECORD_OTHER', criminalRecordOther)}
                            required
                            disabled={isDisabled}
                            onChange={event => setCriminalRecordOther(event.target.value)}
                            multiline
                            placeholder={'Please explain...'}
                        />
                    )}
                </FormControl>

                <FormControl>
                    <FormLabel
                        error={isSubmitted && (!disabled || disabled === 'yes' && !disabledOther)}
                        required
                        className={'d-block mb__2'}
                    >
                        Are you pregnant, have disabilities or any other health issue that requires us to assist you in
                        the workplace?
                    </FormLabel>
                    <RadioGroup
                        row
                        value={disabled}
                        onChange={event => setDisabled(event.target.value)}
                    >
                        <FormControlLabel
                            value="no"
                            label="No"
                            control={<Radio disabled={isDisabled}/>}
                        />
                        <FormControlLabel
                            value="yes"
                            label="Yes"
                            control={<Radio disabled={isDisabled}/>}
                        />
                    </RadioGroup>

                    {disabled === 'yes' && (
                        <TextField
                            rows={3}
                            value={disabledOther}
                            error={isSubmitted && disabled === 'yes' && !disabledOther}
                            onFocus={() => sync('JI_DISABLED_OTHER', disabledOther)}
                            required
                            disabled={isDisabled}
                            onChange={event => setDisabledOther(event.target.value)}
                            multiline
                            placeholder={'Please explain...'}
                        />
                    )}
                </FormControl>

                <FormControl>
                    <FormLabel
                        error={isSubmitted && (!difficulties || difficulties === 'yes' && !difficultiesOther)}
                        required
                        className={'d-block mb__2'}
                    >
                        Do you have any issues that make it difficult for you to get to work on time and staying for
                        your entire shift? (Example: school schedule, daycare issues, another job?)
                    </FormLabel>
                    <RadioGroup
                        row
                        value={difficulties}
                        onChange={event => setDifficulties(event.target.value)}
                    >
                        <FormControlLabel
                            value="no"
                            label="No"
                            control={<Radio disabled={isDisabled}/>}
                        />
                        <FormControlLabel
                            value="yes"
                            label="Yes"
                            control={<Radio disabled={isDisabled}/>}
                        />
                    </RadioGroup>

                    {difficulties === 'yes' && (
                        <TextField
                            rows={3}
                            value={difficultiesOther}
                            error={isSubmitted && difficulties === 'yes' && !difficultiesOther}
                            onFocus={() => sync('JI_DIFFICULTIES_OTHER', difficultiesOther)}
                            required
                            disabled={isDisabled}
                            onChange={event => setDifficultiesOther(event.target.value)}
                            multiline
                            placeholder={'Please explain...'}
                        />
                    )}
                </FormControl>
                <FormControl>
                    <FormLabel
                        error={isSubmitted && !currentlyEmployed}
                        required
                        className={'d-block mb__2'}
                    >
                        Are you currently employed?
                    </FormLabel>
                    <RadioGroup
                        row
                        value={currentlyEmployed}
                        onChange={event => setCurrentlyEmployed(event.target.value)}
                    >
                        <FormControlLabel
                            value="no"
                            label="No"
                            control={<Radio disabled={isDisabled}/>}
                        />
                        <FormControlLabel
                            value="yes"
                            label="Yes"
                            control={<Radio disabled={isDisabled}/>}
                        />
                    </RadioGroup>
                </FormControl>

                {!!currentlyEmployed && (
                    currentlyEmployed !== 'yes' ? (
                        <Box className={'columns__1'}>
                            <FormControl>
                                <FormLabel
                                    error={isSubmitted && !previousEmployers}
                                    required
                                    className={'d-block mb__2'}
                                >
                                    Please list your last three places of employment.
                                </FormLabel>
                                <TextField
                                    rows={4}
                                    value={previousEmployers}
                                    error={isSubmitted && !previousEmployers}
                                    onFocus={() => sync('JI_PREVIOUS_EMPLOYERS', previousEmployers)}
                                    required
                                    disabled={isDisabled}
                                    onChange={event => setPreviousEmployers(event.target.value)}
                                    multiline
                                />
                            </FormControl>

                            <FormControl>
                                <FormLabel
                                    error={isSubmitted && !workedAgency}
                                    required
                                    className={'d-block mb__2'}
                                >
                                    Have you ever worked for a healthcare staffing agency before?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    value={workedAgency}
                                    onChange={event => setWorkedAgency(event.target.value)}
                                >
                                    <FormControlLabel
                                        value="no"
                                        label="No"
                                        control={<Radio disabled={isDisabled}/>}
                                    />
                                    <FormControlLabel
                                        value="yes"
                                        label="Yes"
                                        control={<Radio disabled={isDisabled}/>}
                                    />
                                </RadioGroup>
                            </FormControl>

                            {workedAgency === 'yes' && (
                                <>
                                    <FormControl>
                                        <FormLabel className={'d-block mb__2'}>
                                            Please list any agencies you have previously worked for.
                                        </FormLabel>
                                        <TextField
                                            rows={4}
                                            value={agenciesWorked}
                                            onFocus={() => sync('JI_AGENCIES_WORKED', agenciesWorked)}
                                            required
                                            disabled={isDisabled}
                                            onChange={event => setAgenciesWorked(event.target.value)}
                                            multiline
                                        />
                                    </FormControl>
                                    <FormControl>
                                        <FormLabel className={'d-block mb__2'}>
                                            What facilities have you worked at through an agency?
                                        </FormLabel>
                                        <TextField
                                            rows={4}
                                            value={facilitiesWorked}
                                            onFocus={() => sync('JI_FACILITIES_WORKED', facilitiesWorked)}
                                            disabled={isDisabled}
                                            onChange={event => setFacilitiesWorked(event.target.value)}
                                            multiline
                                        />
                                    </FormControl>
                                </>

                            )}
                        </Box>
                    ) : (
                        <Box className={'columns__1'}>
                            <FormControl>
                                <FormLabel
                                    error={isSubmitted && !currentEmployerAgency}
                                    required
                                    className={'d-block mb__2'}
                                >
                                    Is your current employer a healthcare staffing agency?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    value={currentEmployerAgency}
                                    onChange={event => setCurrentEmployerAgency(event.target.value)}
                                >
                                    <FormControlLabel
                                        value="no"
                                        label="No"
                                        control={<Radio disabled={isDisabled}/>}
                                    />
                                    <FormControlLabel
                                        value="yes"
                                        label="Yes"
                                        control={<Radio disabled={isDisabled}/>}
                                    />
                                </RadioGroup>
                            </FormControl>

                            {!!currentEmployerAgency && (
                                currentEmployerAgency === 'no' ? (
                                    <>
                                        <FormControl variant="standard">
                                            <FormLabel
                                                error={isSubmitted && !currentEmployerName}
                                                required
                                                className={'d-block mb__2'}
                                            >
                                                Where do you currently work?
                                            </FormLabel>
                                            <TextField
                                                value={currentEmployerName}
                                                error={isSubmitted && !currentEmployerName}
                                                onFocus={() => sync('JI_CURRENT_EMPLOYER_NAME', currentEmployerName)}
                                                required
                                                disabled={isDisabled}
                                                onChange={event => setCurrentEmployerName(event.target.value)}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel className={'d-block mb__2'}>
                                                What other facilities have you worked at?
                                            </FormLabel>
                                            <TextField
                                                rows={4}
                                                value={facilitiesWorked}
                                                onFocus={() => sync('JI_FACILITIES_WORKED', facilitiesWorked)}
                                                disabled={isDisabled}
                                                onChange={event => setFacilitiesWorked(event.target.value)}
                                                multiline
                                            />
                                        </FormControl>
                                    </>
                                ) : (
                                    <>
                                        <FormControl variant="standard">
                                            <FormLabel
                                                error={isSubmitted && !currentEmployerAgencyName}
                                                required
                                                className={'d-block mb__2'}
                                            >
                                                What is the name of your agency?
                                            </FormLabel>
                                            <TextField
                                                value={currentEmployerAgencyName}
                                                error={isSubmitted && !currentEmployerAgencyName}
                                                onFocus={() => sync('JI_CURRENT_EMPLOYER_AGENCY_NAME', currentEmployerAgencyName)}
                                                required
                                                disabled={isDisabled}
                                                onChange={event => setCurrentEmployerAgencyName(event.target.value)}
                                            />
                                        </FormControl>
                                        <FormControl variant="standard">
                                            <FormLabel
                                                error={isSubmitted && !currentFacilityName}
                                                required
                                                className={'d-block mb__2'}
                                            >
                                                What facility do you currently work at?
                                            </FormLabel>
                                            <TextField
                                                value={currentFacilityName}
                                                error={isSubmitted && !currentFacilityName}
                                                onFocus={() => sync('JI_CURRENT_FACILITY_NAME', currentFacilityName)}
                                                required
                                                disabled={isDisabled}
                                                onChange={event => setCurrentFacilityName(event.target.value)}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel
                                                error={isSubmitted && !multiJobYesNo}
                                                required
                                                className={'d-block mb__2'}
                                            >
                                                Do you plan to work with your current agency and Reliant at
                                                the same time?
                                            </FormLabel>
                                            <RadioGroup
                                                row
                                                value={multiJobYesNo}
                                                onChange={event => setMultiJobYesNo(event.target.value)}
                                            >
                                                <FormControlLabel
                                                    value="no"
                                                    label="No"
                                                    control={<Radio disabled={isDisabled}/>}
                                                />
                                                <FormControlLabel
                                                    value="yes"
                                                    label="Yes"
                                                    control={<Radio disabled={isDisabled}/>}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </>
                                )
                            )}

                            <FormControl>
                                <FormLabel
                                    error={isSubmitted && !leavingToWorkFor}
                                    required
                                    className={'d-block mb__2'}
                                >
                                    Are you leaving your current employer to work with Reliant?
                                </FormLabel>
                                <RadioGroup
                                    row
                                    value={leavingToWorkFor}
                                    onChange={event => setLeavingToWorkFor(event.target.value)}
                                >
                                    <FormControlLabel
                                        value="no"
                                        label="No"
                                        control={<Radio disabled={isDisabled}/>}
                                    />
                                    <FormControlLabel
                                        value="yes"
                                        label="Yes"
                                        control={<Radio disabled={isDisabled}/>}
                                    />
                                </RadioGroup>
                            </FormControl>

                            {!!leavingToWorkFor && (
                                leavingToWorkFor === 'yes' ? (
                                    <>
                                        <FormControl variant="standard">
                                            <FormLabel
                                                error={isSubmitted && !reasonForLeaving}
                                                required
                                                className={'d-block mb__2'}
                                            >
                                                Why are you leaving your current employer?
                                            </FormLabel>
                                            <TextField
                                                value={reasonForLeaving}
                                                error={isSubmitted && !reasonForLeaving}
                                                onFocus={() => sync('JI_REASON_FOR_LEAVING', reasonForLeaving)}
                                                required
                                                disabled={isDisabled}
                                                onChange={event => setReasonForLeaving(event.target.value)}
                                            />
                                        </FormControl>

                                        <FormControl variant="standard">
                                            <FormLabel
                                                error={isSubmitted && !expectedStartDate}
                                                required
                                                className={'d-block mb__2'}
                                            >
                                                What date is your expected start date with Reliant?
                                            </FormLabel>
                                            <DatePicker
                                                value={expectedStartDate ? dayjs(expectedStartDate) : null}
                                                disabled={isDisabled}
                                                onChange={event => setExpectedStartDate(event ? event.format('YYYY-MM-DD') : '')}
                                            />
                                        </FormControl>

                                        <FormControl>
                                            <FormLabel
                                                error={isSubmitted && !canWork40Hours}
                                                required
                                                className={'d-block mb__2'}
                                            >
                                                Are you available to work 40 hours a week?
                                            </FormLabel>
                                            <RadioGroup
                                                row
                                                value={canWork40Hours}
                                                onChange={event => setCanWork40Hours(event.target.value)}
                                            >
                                                <FormControlLabel
                                                    value="no"
                                                    label="No"
                                                    control={<Radio disabled={isDisabled}/>}
                                                />
                                                <FormControlLabel
                                                    value="yes"
                                                    label="Yes"
                                                    control={<Radio disabled={isDisabled}/>}
                                                />
                                            </RadioGroup>
                                        </FormControl>

                                        {canWork40Hours === 'no' && (
                                            <FormControl>
                                                <FormLabel
                                                    error={isSubmitted && !confirmWork24}
                                                    required
                                                    className={'d-block mb__2'}
                                                >
                                                    Reliant has a minimum of 24 hours/week. Are you able to work between
                                                    24-40?
                                                </FormLabel>
                                                <RadioGroup
                                                    row
                                                    value={confirmWork24}
                                                    onChange={event => setConfirmWork24(event.target.value)}
                                                >
                                                    <FormControlLabel
                                                        value="no"
                                                        label="No"
                                                        control={<Radio disabled={isDisabled}/>}
                                                    />
                                                    <FormControlLabel
                                                        value="yes"
                                                        label="Yes"
                                                        control={<Radio disabled={isDisabled}/>}
                                                    />
                                                </RadioGroup>
                                            </FormControl>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <FormControl variant="standard">
                                            <FormLabel
                                                error={isSubmitted && !expectedStartDate}
                                                required
                                                className={'d-block mb__2'}
                                            >
                                                What date is your expected start date with Reliant?
                                            </FormLabel>
                                            <DatePicker
                                                value={expectedStartDate ? dayjs(expectedStartDate) : null}
                                                disabled={isDisabled}
                                                onChange={event => setExpectedStartDate(event ? event.format('YYYY-MM-DD') : '')}
                                            />
                                        </FormControl>
                                        <FormControl>
                                            <FormLabel
                                                error={isSubmitted && !confirmWork24}
                                                required
                                                className={'d-block mb__2'}
                                            >
                                                Reliant has a minimum of 24 hours/week. Are you able to work
                                                between 24-40?
                                            </FormLabel>
                                            <RadioGroup
                                                row
                                                value={confirmWork24}
                                                onChange={event => setConfirmWork24(event.target.value)}
                                            >
                                                <FormControlLabel
                                                    value="no"
                                                    label="No"
                                                    control={<Radio disabled={isDisabled}/>}
                                                />
                                                <FormControlLabel
                                                    value="yes"
                                                    label="Yes"
                                                    control={<Radio disabled={isDisabled}/>}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </>
                                )
                            )}
                        </Box>
                    )
                )}

                {!readOnly && (
                    <StepControls
                        step={step}
                        onNext={doValidate}
                        onBack={onStepChange}
                        disabled={isDisabled}
                    />
                )}
            </Box>

            <Snackbar
                open={!!error}
                onClose={handleErrorClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={4000}
            >
                <Alert
                    sx={{width: '100%'}}
                    onClose={handleErrorClose}
                    severity={'error'}
                >
                    {error}
                </Alert>
            </Snackbar>
        </>
    );
};

export default JobInterviewStep;