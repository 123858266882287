import {faker} from '@faker-js/faker';

/**
 * Generator class.
 */
class Generator {
    /**
     * Returns a random UUID.
     *
     * @returns {string}
     */
    static uuid() {
        return (Math.random() + 1).toString(36).substring(7);
    }


    /**
     * Returns a fake instance of a record based on its definition.
     *
     * @param model
     */
    static getFakeRecord(model) {
        let result = {};
        let properties = model.getProperties();

        for (let j in properties) {
            if (!properties.hasOwnProperty(j)) {
                continue;
            }

            const property = properties[j];
            result[property.getKey()] = property.getFakeValue();
        }

        return result;
    };


    /**
     * Indicates whether the word starts with a vowel.
     *
     * @param word
     * @returns {boolean}
     */
    static isVowel(word) {
        const vowels = ("aeiouAEIOU");
        return vowels.indexOf(word[0]) !== -1;
    };


    /**
     * Generates a randomized question.
     *
     * @returns {string}
     */
    static getRandomQuestion() {
        const species = [
            'bear', 'bird', 'cat',
            'dog', 'fish', 'insect',
            'rabbit', 'rodent', 'snake'
        ].random();


        const animal = faker.animal[species]();
        const article = Generator.isVowel(animal) ? 'an' : 'a';
        const entity = `${article} ${animal}`;

        const structure = {
            'Where': {
                'can': {
                    [entity]: {
                        'be found': {}
                    }
                },
                'can you find': {
                    [entity]: {}
                },
                'does': {
                    [entity]: {
                        'live': {}
                    }
                }
            },
            'What': {
                'type of species is': {
                    [entity]: {}
                },
                'color is': {
                    [entity]: []
                },
                'is': {
                    [entity]: []
                },
                'does': {
                    [entity]: {
                        'look like': {}
                    }
                }
            },
            'How': {
                'large is a juvenile': {
                    [animal]: {}
                },
                'large is an adult': {
                    [animal]: {}
                },
                'does': {
                    [entity]: {
                        'hunt': {},
                        'smell': {},
                        'forage': {},
                    }
                }
            }
        };

        let sentence = [];
        let iterator = Object.keys(structure).random();
        let options = structure[iterator];

        while (true) {
            sentence.push(iterator);

            if (!options || !Object.keys(options).length) {
                break;
            }

            iterator = Object.keys(options).random();
            options = options[iterator] || [];
        }

        return `${sentence.filter(segment => !!segment.trim()).join(' ')}?`;
    }
}

export default Generator;