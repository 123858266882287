import React, {useState} from "react";

import Settings from "../Global/Settings";
import Formatter from "../Global/Formatter";

import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

/**
 * EmployeeShiftActions component.
 *
 * @returns {*}
 * @constructor
 */
const EmployeeShiftActions = (props) => {
    const {
        record,         // {Object} The employee record / context.
        doReload,       // {Function} Reloads the parent layout, if applicable.
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [isViewingCallOffModal, setViewingCallOffModal] = useState(false);

    /**
     * Indicates whether or not we have an anchor target.
     *
     * @type {boolean}
     */
    const open = Boolean(anchorEl);


    /**
     * Fired whenever the menu button is clicked.
     *
     * @param event
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Closes the menu popover.
     */
    const handleClose = () => {
        setAnchorEl(null);
    };


    /**
     * Reveals the call off modal.
     */
    const handleCallOffModal = () => {
        setViewingCallOffModal(true);
    };


    /**
     * Hides the call off modal.
     */
    const handleCallOffModalClose = () => {
        setViewingCallOffModal(false)
    };


    /**
     * Opens a browser tab with directions to the facility.
     */
    const getDirections = () => {
        const client = {...record.client};

        // Format the client address.
        const formattedAddress = Formatter.address(
            client.addressLine1 || '',
            client.addressLine2 || '',
            '',
            client.city || '',
            client.state && client.state.abbreviation ? client.state.abbreviation : '',
            client.postalCode || ''
        );

        // Reveal the directions for the facility.
        window.open(`https://www.google.com/maps/place/${formattedAddress}`, '_blank');
    };

    return (
        <Box key={record.id}>
            <IconButton onClick={handleClick}>
                <MoreHorizIcon/>
            </IconButton>
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <MenuItem onClick={getDirections}>Find Directions</MenuItem>
                <Divider/>
                <MenuItem onClick={handleCallOffModal}>Call in Late</MenuItem>
                <MenuItem onClick={handleCallOffModal}>Call Off</MenuItem>
            </Menu>
            <Dialog
                open={isViewingCallOffModal}
                scroll={'body'}
                onClose={handleCallOffModalClose}
                fullWidth
                maxWidth={'xs'}
            >
                <Box className={'p__3'}>
                    To call off or provide a late notice for a shift, please call our phone number
                    at {Formatter.phone(Settings.phoneNumber)} within 12 hours of your scheduled shift.
                </Box>
            </Dialog>
        </Box>
    );
};

export default EmployeeShiftActions;