import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import {useAuth} from "../../../Global/Auth";
import {_navigate} from "../../../Global/History";
import LogoHeading from "../../../Components/Typography/LogoHeading";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import {DatePicker} from "@mui/x-date-pickers";
import FormControlLabel from "@mui/material/FormControlLabel";

/**
 * TimeOffForm component.
 *
 * @constructor
 */
const TimeOffForm = (props) => {
    const {
        onBack,             // {Function} A function to call when the user clicks the back button.
    } = props

    const {user} = useAuth();
    const [error, setError] = useState('');
    const [lastName, setLastName] = useState(user.lastName || '');
    const [isLoading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState(user.firstName || '');
    const [isSubmitted, setSubmitted] = useState(false);
    const [isSuccessful, setSuccessful] = useState(false);
    const [emailAddress, setEmailAddress] = useState(user.emailAddress || '');

    /**
     * Various responses to the form questionnaire.
     */
    const [endDate, setEndDate] = useState('');
    const [startDate, setStartDate] = useState('');
    const [ptoMethod, setPtoMethod] = useState(null);
    const [takeOffDates, setTakeOffDates] = useState('');
    const [reasonForRequest, setReasonForRequest] = useState(null);
    const [isCurrentlyEmployed, setCurrentlyEmployed] = useState(null);
    const [numberOfHoursOnCheck, setNumberOfHoursOnCheck] = useState(null);
    const [numberOfHoursRequested, setNumberOfHoursRequested] = useState(null);

    /**
     * Scroll to the bottom automatically for any boolean answers.
     */
    useEffect(() => {
        handleScrollToBottom();
    }, [ptoMethod, isCurrentlyEmployed, numberOfHoursOnCheck, reasonForRequest])


    /**
     * Navigates back to the home page.
     */
    const backToHome = () => {
        _navigate('/');
    };


    /**
     * Scrolls the user to the top of the page.
     */
    const handleScrollToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth"
        });
    };


    /**
     * Processes the form submission and sends a message to the scheduler.
     *
     * @returns {Promise<void>}
     */
    const handleSubmit = async () => {
        setError('');
        setSubmitted(true);

        const requirements = [
            lastName,
            firstName,
            ptoMethod,
            emailAddress,
            reasonForRequest,
            isCurrentlyEmployed,
            numberOfHoursOnCheck,
            numberOfHoursRequested,
        ];

        if (reasonForRequest === 'Vacation' || reasonForRequest === 'Personal'){
            requirements.push(endDate);
            requirements.push(startDate);
        }

        if (ptoMethod === 'Take Off') {
            requirements.push(takeOffDates);
        }

        if (!requirements.filter(requirement => !requirement).length) {
            if(reasonForRequest === 'Vacation' || reasonForRequest === 'Personal'){
                if(dayjs(startDate).isBefore(dayjs().add(27, 'days'))){
                    setError('PTO for vacation and personal reasons must be schedule a minimum of 4 weeks out.');
                    return;
                }
            }

            setLoading(true);

            await API.post('messages/time-off', {
                endDate,
                lastName,
                firstName,
                startDate,
                ptoMethod,
                emailAddress,
                takeOffDates,
                reasonForRequest,
                isCurrentlyEmployed,
                numberOfHoursOnCheck,
                numberOfHoursRequested,
            });

            setLoading(false);
            setSuccessful(true);
        } else {
            setError('Please answer all required fields.')
        }
    };

    return (
        <Box>
            <LogoHeading
                title={'Request Time Off'}
                actionSlot={
                    <Button
                        size={'small'}
                        variant={'outlined'}
                        onClick={onBack}
                        children={'Back'}
                        className={'text__small'}
                    />
                }
            />

            {!isSuccessful ? (
                <Box className={'columns__1'}>
                    <Box>
                        This interactive form will guide you through our process for requesting time off.
                    </Box>

                    <Box className={'columns__1'} data-aos={'fade-right'}>
                        <Divider>Question 1</Divider>
                        <FormControl>
                            <FormLabel>Are you a currently active employee who has been employed with Reliant for at
                                least 90 days?</FormLabel>
                            <RadioGroup
                                row
                                value={isCurrentlyEmployed}
                                onChange={event => setCurrentlyEmployed(event.target.value)}
                                className={'benefits__radio'}
                            >
                                <FormControlLabel
                                    value={'Yes'}
                                    label={'Yes'}
                                    control={
                                        <Radio/>
                                    }
                                />
                                <FormControlLabel
                                    value={'No'}
                                    label={'No'}
                                    control={
                                        <Radio/>
                                    }
                                />
                            </RadioGroup>
                        </FormControl>
                    </Box>

                    {isCurrentlyEmployed === 'No' && (
                        <Box className={'columns__1'} data-aos={'fade-right'}>
                            <Alert icon={false} severity={'warning'}>
                                Staff who are employed for less than 90 days are not eligible to use their PTO. Please
                                inquire once you have been employed for a minimum of 90 days.
                            </Alert>
                            <Button variant={'contained'} onClick={backToHome}>
                                Back to "Home"
                            </Button>
                        </Box>
                    )}

                    {isCurrentlyEmployed === 'Yes' && (
                        <Box className={'columns__1'} data-aos={'fade-right'}>
                            <Divider>Question 2</Divider>
                            <FormControl>
                                <FormLabel>What is the reason to why you are requesting Paid Time Off?</FormLabel>
                                <RadioGroup
                                    row
                                    value={reasonForRequest}
                                    onChange={event => setReasonForRequest(event.target.value)}
                                    className={'benefits__radio'}
                                >
                                    <FormControlLabel
                                        value={'Vacation'}
                                        label={'Vacation'}
                                        control={<Radio/>}
                                    />
                                    <FormControlLabel
                                        value={'Medical'}
                                        label={'Medical'}
                                        control={<Radio/>}
                                    />
                                    <FormControlLabel
                                        value={'Personal'}
                                        label={'Personal'}
                                        control={<Radio/>}
                                    />
                                </RadioGroup>
                            </FormControl>

                            {['Vacation', 'Personal'].includes(reasonForRequest) && (
                                <Alert icon={false} severity={'warning'}>
                                    <Box className={'columns__1'}>
                                        <Box>
                                            {reasonForRequest} PTO requests have to be scheduled 4 weeks in advance to
                                            qualify. What date range are you scheduling your time away? <span
                                            className={'asterisk'}>*</span>
                                        </Box>
                                        <Box className={'columns__2'}>
                                            <DatePicker
                                                label={'Start Date'}
                                                value={startDate ? dayjs(startDate) : null}
                                                required
                                                onChange={event => setStartDate(event && event.isValid() ? event.format('YYYY-MM-DD') : '')}
                                            />
                                            <DatePicker
                                                label={'End Date'}
                                                value={endDate ? dayjs(endDate) : null}
                                                required
                                                onChange={event => setEndDate(event && event.isValid() ? event.format('YYYY-MM-DD') : '')}
                                            />
                                        </Box>
                                    </Box>
                                </Alert>
                            )}

                            {!!reasonForRequest && (
                                <>
                                    <Box className={'columns__1'} data-aos={'fade-right'}>
                                        <Divider>Question 3</Divider>
                                        <FormControl>
                                            <FormLabel>Are you attempting to cash out your PTO or take a scheduled shift
                                                off?</FormLabel>
                                            <RadioGroup
                                                row
                                                value={ptoMethod}
                                                onChange={event => setPtoMethod(event.target.value)}
                                                className={'benefits__radio'}
                                            >
                                                <FormControlLabel
                                                    value={'Cash Out'}
                                                    label={'Cash Out'}
                                                    control={<Radio/>}
                                                />
                                                <FormControlLabel
                                                    value={'Take Off'}
                                                    label={'Take Off'}
                                                    control={<Radio/>}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>

                                    {ptoMethod === 'Take Off' && (
                                        <Alert icon={false} severity={'warning'}>
                                            <FormControl>
                                                <FormLabel className={'mb__3'}>
                                                    List the specific scheduled dates you are wanting to take off and
                                                    receive PTO for: <span className={'asterisk'}>*</span>
                                                </FormLabel>
                                                <TextField
                                                    rows={4}
                                                    value={takeOffDates || ''}
                                                    onChange={event => setTakeOffDates(event.target.value)}
                                                    multiline
                                                    fullWidth
                                                    placeholder={'Write your dates here...'}
                                                />
                                            </FormControl>
                                        </Alert>
                                    )}

                                    {!!ptoMethod && (
                                        <>
                                            <Box className={'columns__1'} data-aos={'fade-right'}>
                                                <Divider>Question 4</Divider>
                                                <FormControl>
                                                    <FormLabel className={'mb__3'}>
                                                        How many hours of PTO are available on your most current ADP
                                                        pay check?
                                                    </FormLabel>
                                                    <TextField
                                                        type={'number'}
                                                        value={numberOfHoursOnCheck}
                                                        onChange={event => setNumberOfHoursOnCheck(event.target.value)}
                                                        fullWidth
                                                    />
                                                </FormControl>
                                            </Box>

                                            <Box className={'columns__1'} data-aos={'fade-right'}>
                                                <Divider>Question 5</Divider>
                                                <FormControl>
                                                    <FormLabel className={'mb__3'}>
                                                        How many hours of your PTO are you wanting to use?
                                                    </FormLabel>
                                                    <Box>
                                                        <TextField
                                                            type={'number'}
                                                            value={numberOfHoursRequested || ''}
                                                            onChange={event => setNumberOfHoursRequested(event.target.value)}
                                                            fullWidth
                                                        />
                                                    </Box>
                                                </FormControl>
                                                <Button
                                                    variant={'contained'}
                                                    onClick={handleSubmit}
                                                    children={'Submit'}
                                                    disabled={!numberOfHoursRequested || !numberOfHoursOnCheck || isLoading}
                                                />
                                            </Box>
                                        </>
                                    )}
                                </>
                            )}
                        </Box>
                    )}
                </Box>
            ) : (
                <Box className={'columns__1'}>
                    <Alert severity={'success'}>
                        Successfully received message!
                    </Alert>
                    <Box>
                        Thank you for your time off submission. Your request will be processed if
                        approved within 24-48 hours.
                    </Box>
                    <Button
                        variant={'outlined'}
                        onClick={() => _navigate('/')}
                        children={'Home'}
                    />
                </Box>
            )}
            <Snackbar
                open={!!error}
                onClose={() => setError('')}
                autoHideDuration={6000}
            >
                <Alert
                    onClose={() => setError('')}
                    children={error}
                    severity={'error'}
                />
            </Snackbar>
        </Box>
    );
};

export default TimeOffForm;