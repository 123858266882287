import React from "react";

import Box from "@mui/material/Box";

/**
 * EmployeeStatusIndicator component.
 *
 * @constructor
 */
const EmployeeStatusIndicator = (props) => {
    const {
        record,         // {Object} The employee record to display the status of.
        className,      // {String} An optional class to include on the indicator.
    } = props;

    return record.status && record.status.name ? (
        <Box className={`notification__indicator ${className || ''}`} sx={{background: record.status.color, boxShadow: `0 0 4px ${record.status.color}85`}}/>
    ) : (
        !record.isDeleted ? (
            record.isActive ?
                <Box className={`notification__indicator ${className || ''}`} sx={{background: '#2e7d32', boxShadow: `0 0 4px #2e7d3285`}}/> : (
                    record.isInvited ?
                        <Box className={`notification__indicator ${className || ''}`} sx={{background: '#0288d1', boxShadow: `0 0 4px #0288d185`}}/> :
                        <Box className={`notification__indicator ${className || ''}`} sx={{background: '#ed6c02', boxShadow: `0 0 4px #ed6c0285`}}/>
                )
        ) : <Box className={`notification__indicator ${className || ''}`}/>
    )
};

export default EmployeeStatusIndicator;