import React from "react";

import Model from "../Definitions/Model";
import Property from "../Definitions/Property";
import Formatter from "../Global/Formatter";
import PropertyMap from "../Definitions/PropertyMap";
import CallLogActions from "../Actions/CallLogActions";
import RecipientColumn from "./CallLog/RecipientColumn";

/**
 * CallLog model.
 *
 * @type {Object}
 */
const CallLog = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'call-log',


    /**
     * The appropriate API endpoint root.
     */
    route: 'relevant-call-logs',


    /**
     * Any additional query parameters.
     */
    query: {
        $select: '*,recipient',
        showAll: true
    },


    /**
     * The menu action.
     *
     * @param parameters
     * @returns {*}
     */
    action: (parameters) => <CallLogActions {...parameters}/>,


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'recipient',
        'createdDate',
        'fromNumber',
        'reason'
    ],


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'recipient',
            label: 'Caller / Recipient',
            render: (record) => <RecipientColumn record={record}/>,
            sortable: false
        }),
        Property.create({
            key: 'direction',
            label: 'Direction',
            render: (record) => Formatter.capitalize(record.direction),
            sortable: true,
            filterable: true
        }),
        Property.create({
            key: 'createdDate',
            label: 'Date / Time',
            render: (record) => Formatter.dateTime(record.createdDate),
            sortable: true
        }),
        Property.create({
            key: 'fromNumber',
            label: 'From Number',
            render: (record) => Formatter.phone(record.fromNumber),
            sortable: true
        }),
        Property.create({
            key: 'toNumber',
            label: 'To Number',
            render: (record) => Formatter.phone(record.toNumber),
            sortable: true
        }),
        Property.create({
            key: 'reason',
            label: 'Reason',
            render: (record) => record.reason,
            sortable: true
        }),
        Property.create({
            key: 'status',
            label: 'Status',
            render: (record) => record.status,
            sortable: true,
            filterable: true,
            selectable: true
        }),
        Property.create({
            key: 'sid',
            label: 'SID',
            render: (record) => record.sid,
            sortable: true
        }),
    ])
};

export default CallLog;