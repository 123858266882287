import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import Logger from "../../Global/Logger";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import CircularProgress from "@mui/material/CircularProgress";

/**
 * ModelSearch component.
 *
 * @returns {*}
 * @constructor
 */
const ModelSearch = (props) => {
    const {
        style,                  // {Object} An optional style object.
        model,                  // {Model} The target model for pulling API results.
        value,                  // {*} The default / initial value.
        error,                  // {Boolean} Whether to highlight the field in error.
        label,                  // {String} The label of the input field.
        required,               // {Boolean} Whether to require this value.
        disabled,               // {Boolean} Whether to disable the input.
        onChange,               // {Function} Triggered whenever the user makes a selection.
        multiple,               // {Boolean} Whether to handle as a multi-select.
        helperText,             // {String} Additional text to append to the input.
        renderLabel,            // {Function} Calculates the label display.
        filterQuery,            // {Function} Additional query parameters to pass to the API.
        queryParams,            // {Object} Additional search parameters to pass to the API.
    } = props;

    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [defaultValue, setDefaultValue] = useState(multiple ? [] : null);

    /**
     * Binds the search to the user type event.
     */
    useEffect(() => {
        getOptions();
    }, [inputValue]);


    /**
     * Finds the default option via the API.
     */
    useEffect(() => {
        getDefaultOption();
    }, [value]);


    /**
     * Loads each of the search results.
     *
     * @returns {Promise<void>}
     */
    const getOptions = async () => {
        Logger.debug(`[ModelSearch] Loading search results for "${inputValue}"`);
        setLoading(true);

        // Pull general search results.
        const results = await API.get(model.getRoute(), {
            ...(queryParams || {}),
            $filter: filterQuery ? filterQuery(inputValue) : inputValue
        });

        setOptions(results);
        setLoading(false);
    };


    /**
     * Loads the default option via the API.
     *
     * @returns {Promise<void>}
     */
    const getDefaultOption = async () => {
        if(multiple){
            if (!value || !value.length) {
                return setDefaultValue([]);
            }

            Logger.debug(`[ModelSearch] Loading default options, ID: ${value.map(value => value.id).join(',')}`);
            setDefaultValue(
                await API.get(model.getRoute(), {
                    $filter: `id in {${value.map(value => value.id).join(',')}}`
                })
            );
        }
        else{
            if (!value) {
                return setDefaultValue(null);
            }

            Logger.debug(`[ModelSearch] Loading default option, ID: ${value}`);
            const result = await API.get(`${model.getRoute()}/${value}`);

            if (result && result.id) {
                setDefaultValue(result);
            }
        }
    };


    /**
     * Processes the change event.
     *
     * @param event
     * @param value
     * @returns {*}
     */
    const handleChange = (event, value) => {
        if (!onChange) {
            return;
        }

        if(!multiple){
            if (value && value.id) {
                return onChange(value.id);
            }

            onChange(null);
        }
        else{
            if (value && value.length) {
                return onChange(value);
            }

            onChange([]);
        }
    };

    return (
        <Autocomplete
            open={open}
            value={defaultValue}
            onOpen={() => {
                setOpen(true);
            }}
            onClose={() => {
                setOpen(false);
            }}
            loading={loading}
            options={options}
            disabled={disabled}
            multiple={multiple}
            onChange={handleChange}
            onInputChange={(event, updated) => setInputValue(updated)}
            getOptionLabel={renderLabel}
            disableCloseOnSelect={multiple}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            renderInput={(params) => (
                <TextField
                    {...params}
                    sx={style || {}}
                    label={label}
                    error={error}
                    required={!!required}
                    fullWidth
                    helperText={helperText}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? <CircularProgress color="inherit" size={20}/> : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    );
};

export default ModelSearch;