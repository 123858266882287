import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import Settings from "../../Global/Settings";
import {useAuth} from "../../Global/Auth";
import Formatter from "../../Global/Formatter";
import ImageWrapper from "../ImageWrapper";
import DialogHeading from "../Typography/DialogHeading";
import usePhoneDevice from "../../Hooks/usePhoneDevice";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DialogContent from "@mui/material/DialogContent";
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';

/**
 * InboundCallDialog component.
 *
 * @returns {*}
 * @constructor
 *
 * @description Enables the application to answer inbound calls.
 */
const InboundCallDialog = () => {
    const {user} = useAuth();
    const {inbound} = usePhoneDevice();
    const [isOpen, setOpen] = useState(false);
    const [index, setIndex] = useState(0);
    const [recipients, setRecipients] = useState([]);
    const [phoneNumber, setPhoneNumber] = useState('');

    /**
     * Synchronize the modal against the inbound call.
     */
    useEffect(() => {
        if(isOpen && !!inbound){
            getRecipient();
        }
    }, [isOpen]);


    /**
     * Reveals the call modal.
     */
    const handleOpen = () => {
        setOpen(true);
    };


    /**
     * Closes the call modal.
     */
    const handleClose = () => {
        setOpen(false);
    };


    /**
     * Matches employees by phone number.
     *
     * @param phoneNumber
     * @returns {Promise<*>}
     */
    const getEmployeesByPhoneNumber = async (phoneNumber) => {
        const results = await API.get('employees-by-phone-number', {
            phoneNumber
        });

        return results.map(result => {
            return {
                ...result,
                '@type': 'Employee'
            }
        })
    };


    /**
     * Matches clients by phone number.
     *
     * @param phoneNumber
     * @returns {Promise<*>}
     */
    const getClientsByPhoneNumber = async (phoneNumber) => {
        const results = await API.get('clients-by-phone-number', {
            phoneNumber
        });

        return results.map(result => {
            return {
                ...result,
                '@type': 'Client'
            }
        })
    };


    /**
     * Matches users by phone number.
     *
     * @param phoneNumber
     * @returns {Promise<*>}
     */
    const getUsersByPhoneNumber = async (phoneNumber) => {
        const results = await API.get('users-by-phone-number', {
            phoneNumber
        });

        return results.map(result => {
            return {
                ...result,
                '@type': 'User'
            }
        })
    };


    /**
     * Matches contacts by phone number.
     *
     * @param phoneNumber
     * @returns {Promise<*>}
     */
    const getContactsByPhoneNumber = async (phoneNumber) => {
        const results = await API.get('contacts-by-phone-number', {
            phoneNumber
        });

        return results.map(result => {
            return {
                ...result,
                '@type': 'Contact'
            }
        })
    };


    /**
     * Finds a recipient match against the phone number.
     *
     * @returns {Promise<void>}
     */
    const getRecipient = async () => {
        if (!inbound) {
            return setRecipients([]);
        }

        // Find the most recent call to this user.
        const callLogs = await API.get('call-logs', {
            $top: 1,
            $filter: `toNumber in {client:${user.emailAddress}}`,
            $orderby: 'createdDate desc'
        });

        if (!callLogs || !callLogs.length) {
            return setRecipients([]);
        }

        // Since the most recent call will just come from the application, we need
        // to actually match the parent record for the true call details.
        const callLog = callLogs[0];

        if (!callLog || !callLog.parentId) {
            return setRecipients([]);
        }

        const parentLog = await API.get(`call-log/${callLog.parentId}`);

        if (!parentLog || !parentLog.fromNumber) {
            return setRecipients([]);
        }

        // We don't really care about the country code for this.
        const phoneNumber = parentLog.fromNumber.substring(2);

        const matches = await Promise.all([
            getUsersByPhoneNumber(phoneNumber),
            getClientsByPhoneNumber(phoneNumber),
            getContactsByPhoneNumber(phoneNumber),
            getEmployeesByPhoneNumber(phoneNumber)
        ]);

        const filtered = matches.filter(group => group && !!group.length);

        if (filtered && filtered.length) {
            setRecipients(filtered.flat());
        } else {
            return setRecipients([]);
        }

        setPhoneNumber(parentLog.fromNumber);
    };


    /**
     * Accepts the inbound call.
     */
    const handleAccept = () => {
        const employeeMatches = recipients.filter(recipient => recipient['@type'] === 'Employee');

        Settings.activeCall = {
            call: inbound,
            isMuted: inbound.isMuted(),
            recipient: employeeMatches.length ? employeeMatches[0] : {},
            phoneNumber,

            // @deprecated
            selectedEmployee: employeeMatches.length ? employeeMatches[0] : {}
        };

        inbound.accept();
    };


    /**
     * Rejects the inbound call.
     */
    const handleReject = () => {
        inbound.reject();
    };

    return (
        <>
            {!isOpen && (
                <Chip
                    key={`inbound-chip-${index}`}
                    icon={<PhoneCallbackIcon/>}
                    label={phoneNumber ?
                        <Box>Call from <b>{Formatter.phoneString(phoneNumber)}</b></Box> :
                        `Incoming call...`
                    }
                    color={'warning'}
                    onClick={handleOpen}
                    clickable
                    className={'mr__2'}
                />
            )}

            <Dialog
                key={`inbound-dialog-${index}`}
                open={isOpen}
                scroll={'body'}
                onClose={handleClose}
                maxWidth={'xs'}
                fullWidth
            >
                <DialogHeading
                    title={
                        <h3 className={'d-flex__start mt__0 mb__0'}>
                            <PhoneCallbackIcon className={'mr__2'}/>
                            {phoneNumber ?
                                <Box>Incoming Call From <b>{Formatter.phoneString(phoneNumber)}</b></Box> :
                                `Call Incoming...`
                            }
                        </h3>
                    }
                    noMargin
                />
                <DialogContent>
                    <Box className={'columns__1 columns--small'}>
                        <Box className={'well__container p__3'}>
                            {recipients && recipients.length ? (
                                <Box className={'columns__1'}>
                                    {recipients.map(recipient => (
                                        <Box className={'d-flex__start'}>
                                            <ImageWrapper
                                                src={API.getFilePath(recipient.image || '')}
                                                width={48}
                                                height={48}
                                                className={'mr__2'}
                                            />

                                            <Box>
                                                <b>{recipient.displayName}</b>
                                                <Box
                                                    className={'text__light text__small'}
                                                >{recipient['@type']}</Box>
                                            </Box>
                                        </Box>
                                    ))}
                                </Box>
                            ) : (
                                <Box>
                                    No caller information available.
                                </Box>
                            )}
                        </Box>
                        <Box>Would you like to accept the call?</Box>
                        <Box className={'columns__2 columns--small'}>
                            <Button
                                size={'large'}
                                color={'success'}
                                variant={'outlined'}
                                onClick={handleAccept}
                                startIcon={<CheckIcon/>}
                            >Accept</Button>
                            <Button
                                size={'large'}
                                color={'error'}
                                variant={'outlined'}
                                onClick={handleReject}
                                startIcon={<CloseIcon/>}
                            >Reject</Button>
                        </Box>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default InboundCallDialog;