import React from "react";

import Page from "../../Components/Page";
import AddShiftForm from "./Dashboard/AddShiftForm";

/**
 * AddShift component.
 *
 * @returns {JSX.Element}
 * @constructor
 *
 * @description Provides a messaging interface for employees to notify the office of shift pickups.
 */
const AddShift = () => {
    return (
        <Page
            hideHeader
        >
            <AddShiftForm/>
        </Page>
    );
};

export default AddShift;