import {useState} from "react";

import API from "../../../Global/API";
import {useAuth} from "../../../Global/Auth";
import Formatter from "../../../Global/Formatter";

import PushPinIcon from '@mui/icons-material/PushPin';

/**
 * MessageActions component.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const MessageActions = (props) => {
    const {
        message,            // {Object} The message object for context.
        onUpdate,           // {Function} The reload function for the parent component.
    } = props;

    const {user, isScope} = useAuth();
    const {id, isPinned} = message;
    const [isLoading, setLoading] = useState(false);


    /**
     * The class name for the action button.
     *
     * @type {string}
     */
    const className = `message__action ${isPinned ? 'message__action--active' : ''} ${isLoading ? 'message__action--loading' : ''}`;


    /**
     * Ignore for other auth types as they'd have no ability for these actions.
     */
    if (!isScope('User')) {
        return null;
    }


    /**
     * Performs the pin update / toggle to the corresponding notes section.
     *
     * @returns {Promise<void>}
     */
    const doPinUpdate = async () => {
        if (isLoading) {
            return;
        }

        const {
            type,
            notes,
            senderId,
            senderType,
            recipientId,
            createdDate,
            senderRecord,
            recipientType
        } = message;

        const {
            displayName
        } = senderRecord;

        const isPinned = !message.isPinned;
        setLoading(true);

        // Will represent the final message update payload.
        const payload = {
            isPinned
        };

        // If we're un-pinning, remove any related note entries.
        if (!isPinned && notes) {
            await Promise.all(notes.map(async (note) => {
                const {id} = note;

                await API.put(`notes/${id}`, {
                    isDeleted: true
                });
            }));
        }

        // If we are newly pinning, create a new note entry alongside the update.
        if (isPinned) {
            let modelId;
            const modelType = [senderType, recipientType].includes('Employee') ?
                'Employee' : 'User';

            // At this point, we can really only pin messages to the employee profiles. At some time
            // or another, we may expand this to include other types of recipients.
            //
            if (modelType === 'Employee') {
                modelId = senderType === 'Employee' ?
                    senderId : recipientId;
            }

            if (modelType && modelId) {
                await API.post('notes', {
                    note: `${type} from ${displayName} (${Formatter.dateTime(createdDate)}): ${message.content}`,
                    userId: user.id,
                    modelId,
                    modelType,
                    messageId: message.id
                });
            }
        }

        // Perform the message update.
        const response = await API.put(`messages/${id}`, payload);

        if (onUpdate) {
            await onUpdate(response);
        }

        setLoading(false);
    };

    return (
        <a
            onClick={doPinUpdate}
            children={<PushPinIcon fontSize={'small'}/>}
            className={className}
        />
    );
};

export default MessageActions;