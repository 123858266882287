import React from "react";

import App from "../../../Global/App";
import Settings from "../../../Global/Settings";
import Formatter from "../../../Global/Formatter";
import DetailWrapper from "../../../Components/Layouts/Profile/DetailWrapper";

import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

/**
 * ProfileDetails component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ProfileDetails = (props) => {
    const {
        record      // {Object} The record in which to reference.
    } = props;

    /**
     * The combined address for display purposes.
     *
     * @type {string}
     */
    const formattedAddress = Formatter.address(
        record.addressLine1 || '',
        record.addressLine2 || '',
        record.apartmentNumber || '',
        record.city || '',
        record.state && record.state.abbreviation ? record.state.abbreviation : '',
        record.postalCode || ''
    );


    /**
     * Indicates if the record hasn't yet been loaded.
     *
     * @type {boolean}
     */
    const isLoading = !record || !record.id;


    /**
     * Returns the value if the record has been loaded, otherwise returns a skeleton loader.
     *
     * @param value
     * @param width
     * @returns {*|JSX.Element}
     */
    const onLoaded = (value, width = null) => {
        if (!isLoading) {
            return value;
        }

        return <Skeleton width={width || (100 + (Math.random() * 75))}/>
    };

    const properties = [
        <DetailWrapper
            label={'Name'}
            value={onLoaded(`${record.firstName} ${record.lastName}`)}
        />,
        <DetailWrapper
            label={'SSN'}
            value={onLoaded(Formatter.ssn(record.ssn || ''))}
        />,
        <DetailWrapper
            label={'Phone'}
            value={onLoaded(<a href={`tel:${record.phoneNumber}`}>{Formatter.phoneString(record.phoneNumber)}</a>)}
        />,
        <DetailWrapper
            label={'Birth Date'}
            value={onLoaded(Formatter.date(record.birthDate, '', false))}
        />,
        <DetailWrapper
            label={'Email Address'}
            value={onLoaded(<a href={`mailto:${record.emailAddress}`}>{record.emailAddress}</a>)}
        />,
        <DetailWrapper
            label={'Address'}
            value={onLoaded(formattedAddress)}
        />
    ];

    return (
        <Box className={'columns__1'}>
            <Box className={'columns__1 pl__3 pr__3 pt__3'}>
                {properties.map((property, i) => (
                    <Box
                        data-aos={'fade-right'}
                        children={property}
                        data-aos-delay={i * 50}
                    />
                ))}
            </Box>

            {/*Display the employee's address on a map embed.*/}
            {formattedAddress ? (
                !App.isCordova() ? (
                    <iframe
                        width="100%"
                        height="300"
                        style={{border: 0}}
                        loading="lazy"
                        allowFullScreen
                        referrerPolicy="no-referrer-when-downgrade"
                    />
                ) : (
                    <Box>
                        <img
                            src={`https://maps.googleapis.com/maps/api/staticmap?center=${formattedAddress}&zoom=15&size=1200x500&key=${Settings.googleApiKey}`}
                        />
                    </Box>
                )
            ) : null}
        </Box>
    );
};

export default ProfileDetails;