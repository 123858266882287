import React from "react";

import State from "../Global/State";
import Generator from "../Global/Generator";

import PersonIcon from '@mui/icons-material/Person';
import Logger from "../Global/Logger";

/**
 * Model object.
 *
 * @type {Object}
 */
const Model = {
    /**
     * The identifying key for the model.
     */
    key: 'model',


    /**
     * Any default query parameters.
     */
    query: {},


    /**
     * A series of quick filters to apply to the index layout.
     */
    filters: [],


    /**
     * Returns the appropriate icon.
     *
     * @param parameters
     * @returns {*}
     */
    icon: (parameters) => <PersonIcon {...parameters}/>,


    /**
     * Various options for layouts utilizing the DataTable and TableLayout components.
     */
    tableOptions: {
        hideTableHeader: false
    },


    /**
     * Indicates whether this model has quick filters created.
     *
     * @returns {boolean}
     */
    hasQuickFilters(){
        return !!this.filters.length;
    },


    /**
     * Pulls a singular table option for the index layout.
     *
     * @param key
     * @param value
     * @returns {*}
     */
    getTableOption(key, value = null) {
        if (this.tableOptions.hasOwnProperty(key)) {
            return this.tableOptions[key];
        }

        return value;
    },


    /**
     * Returns all available cache keys for local storage, redis, etc.
     */
    getCacheKeys() {
        const {key} = this;

        return {
            layout: `${key}-layout`,
            filters: `${key}-filters`,
            lastCount: `${key}-last-count`,
            tableSort: `${key}-table-sort`,
            quickFilter: `${key}-quick-filter`,
            lastRowCount: `${key}-row-count`,
            filterDrawer: `${key}-filter-drawer`,
            sortDirection: `${key}-sort-direction`,
        };
    },


    /**
     * Returns the appropriate cache key for quick filters.
     *
     * @returns {string}
     */
    getQuickFilterCacheKey() {
        return this.getCacheKeys().quickFilter;
    },


    /**
     * Returns the cache key for the table layout.
     *
     * @returns {string}
     */
    getLayoutCacheKey() {
        return this.getCacheKeys().layout;
    },


    /**
     * Returns the cache key for the selected filters
     *
     * @returns {string}
     */
    getFiltersCacheKey() {
        return this.getCacheKeys().filters;
    },


    /**
     * Returns the appropriate layout selection.
     *
     * @returns {string[]}
     */
    getLayout() {
        const stored = State.array(this.getLayoutCacheKey());

        return stored && stored.length ? stored : this.layout;
    },


    /**
     * Returns the quick filters for this model.
     *
     * @returns {Array}
     */
    getFilters() {
        return this.filters || [];
    },


    /**
     * Returns the selected filter values.
     *
     * @returns {*|{}}
     */
    getFilterValues() {
        return State.json(this.getFiltersCacheKey());
    },


    /**
     * Returns the property map.
     *
     * @returns {*}
     */
    getPropertyMap() {
        return this.properties;
    },


    /**
     * Returns each individual property.
     *
     * @returns {*}
     */
    getProperties() {
        const map = this.getPropertyMap();

        return map ? map.properties : [];
    },


    /**
     * Generates a list of n fully-populated results.
     *
     * @param amount
     * @returns {Array}
     */
    getFakeResults(amount) {
        let result = [];

        for (let i = 0; i < amount; i++) {
            result.push(Generator.getFakeRecord(this));
        }

        return this.sort ? this.sort(result) : result;
    },


    /**
     * Returns all currently-enabled properties based on the model layout.
     *
     * @returns {Array}
     */
    getLayoutProperties() {
        const result = [];
        const layout = this.getLayout();

        for (let i in layout) {
            if (!layout.hasOwnProperty(i)) {
                continue;
            }

            result.push(
                this.properties.getByKey(
                    layout[i]
                )
            );
        }

        return result;
    },


    /**
     * Returns only the filterable properties.
     *
     * @returns {Array}
     */
    getFilterableProperties() {
        const result = [];
        const properties = this.getProperties();

        for (let i in properties) {
            if (!properties.hasOwnProperty(i)) {
                continue;
            }

            const property = properties[i];

            if (property.isFilterable()) {
                result.push(property);
            }
        }

        return result;
    },


    /**
     * Returns an array containing all properties flagged as selectable.
     *
     * @returns {Array}
     */
    getSelectableProperties() {
        const keys = [];
        const layout = this.getLayout();
        const properties = this.getProperties();

        // This should return properties in the order of the layout. In order to do so,
        // we'll first run through the layout to organize properties, and then eventually
        // fall back to just adding the results in the event that things are missed or
        // whatever the case might be.
        //
        const result = layout.map(key => {
            const matches = properties.filter(property => property.key === key);

            if (!matches || !matches.length) {
                return null;
            }

            keys.push(key);
            return matches[0];
        }).filter(property => !!property);

        // Handle any other property. These will essentially be anything else that
        // was unselected by the layout. On page refresh, these will end up moved to
        // the end of the layout selection within the filter drawer.
        //
        for (let i in properties) {
            if (!properties.hasOwnProperty(i)) {
                continue;
            }

            const property = properties[i];

            // Ignore keys that we may have already added from the
            // input / stored layout for this model.
            if (keys.includes(property.key)) {
                continue;
            }

            if (property.isSelectable()) {
                result.push(property);
            }
        }

        return result;
    },


    /**
     * Returns the form component.
     *
     * @returns {*}
     */
    getForm(...params) {
        return this.form(...params);
    },


    /**
     * Returns the base API endpoint.
     *
     * @returns {string}
     */
    getRoute() {
        return this.route || '';
    },


    /**
     * Returns an empty object.
     *
     * @param fields
     * @returns {Object}
     */
    getInstance(fields) {
        const object = {};

        this.getProperties().map(property => {
            object[property.getKey()] = '';
        });

        // Propagate an existing instance if one were provided.
        if (fields && typeof fields === 'object') {
            Object.keys(fields).map(key => {
                const value = fields[key];

                // Ignore object / relational properties.
                if (typeof value === 'object' && value !== null) {
                    return;
                }

                object[key] = value;
            });
        }

        return {
            ...object
        };
    },


    /**
     * Returns any default query parameters.
     *
     * @returns {*|{}}
     */
    getQuery() {
        const filter = this.getDefaultFilter();
        const query = this.query || {};

        return filter && filter.query ? {
            ...query,
            ...filter.query
        } : query;
    },


    /**
     * Returns the default filter.
     *
     * @returns {{}}
     */
    getDefaultFilter() {
        const lastSelection = parseInt(State.get(this.getQuickFilterCacheKey()) || 0);
        const quickFilters = this.getFilters();

        if (quickFilters.length > lastSelection) {
            return quickFilters[lastSelection];
        }

        return quickFilters.length ? quickFilters[0] : {};
    },


    /**
     * Indicates whether we're utilizing an "Actions" column.
     *
     * @returns {boolean}
     */
    hasActions() {
        return !!this.action;
    },


    /**
     * Returns the current filter criteria based on the query, quick filters, etc.
     *
     * @returns {[]}
     */
    getFilterCriteria(){
        const filterCriteria = [];
        const selectedFilters = this.getFilterValues();
        Logger.debug(`[Model] Selected filters:`, selectedFilters);

        for(let i in selectedFilters){
            if(!selectedFilters.hasOwnProperty(i) || !selectedFilters[i]){
                continue;
            }

            if(Array.isArray(selectedFilters[i])){
                // Handle typical filter scenarios (usually a multiple autocomplete).
                if(!selectedFilters[i].length){
                    continue;
                }

                // Handle type-specific selections for compatibility with the backend.
                const selectedOptions = selectedFilters[i].map(option => option === true ? '1' :
                    (option === false ? '0' : option)
                );

                filterCriteria.push(`${i} in {${selectedOptions.join(',')}}`)
            }
            else{
                // Range-type filters.
                const {
                    to,
                    from,
                } = selectedFilters[i] || {};

                if(!to && !from){
                    continue;
                }

                if(to){
                    filterCriteria.push(`${i} le {${to}}`)
                }

                if(from){
                    filterCriteria.push(`${i} ge {${from}}`)
                }
            }
        }

        return filterCriteria;
    },


    /**
     * Returns the joined filter criteria as a string.
     *
     * @returns {string}
     */
    getFilterString(input = ''){
        const root = (input || '').trim();
        const criteria = this.getFilterCriteria();
        const joined = criteria && criteria.length ?
            criteria.join(' and ') : '';

        // If we have no criteria, return the root input.
        if(!joined){
            return root;
        }

        return !root ? joined : `${root} and ${joined}`;
    }
};

export default Model;