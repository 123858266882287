import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import Logger from "../../../Global/Logger";
import TabPanel from "../../../Components/TabPanel";
import {useAuth} from "../../../Global/Auth";
import Formatter from "../../../Global/Formatter";
import TabHeading from "../../../Components/Layouts/Profile/TabHeading";
import NotesBlock from "../../../Components/Layouts/Profile/NotesBlock";
import ClientModel from "../../../Models/Client";
import FileBrowser from "../../../Components/FileBrowser";
import usePhoneCall from "../../../Hooks/usePhoneCall";
import ProfileBadge from "../../../Components/Layouts/Profile/ProfileBadge";
import ClientDetails from "./ClientDetails";
import ClientActions from "../../../Actions/ClientActions";
import ContactsBlock from "../../../Components/Layouts/Profile/ContactsBlock";
import ClientSchedule from "./ClientSchedule";
import ClientStatusChip from "./ClientStatusChip";
import ClientPayDetails from "./ClientPayDetails";

import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import PinDropIcon from "@mui/icons-material/PinDrop";
import {useNavigate} from "react-router";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";

/**
 * ClientView component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ClientView = (props) => {
    const {
        id,             // {Number} The ID of the client to load.
        onClose,        // {Function} A close handler for the dialog.
        doReload,       // {Function} An optional reload handler for the parent layout.
    } = props;

    const navigate = useNavigate();
    const [tab, setTab] = useState('Details');
    const {hasPermissionTo} = useAuth();
    const [record, setRecord] = useState(ClientModel.getInstance());
    const [isLoading, setLoading] = useState(false);

    /**
     * Determine whether or we're in a call to avoid dropping while navigating away.
     */
    const {phoneCall} = usePhoneCall();
    const isCalling = !!phoneCall;


    /**
     * Load any additional details on component mount.
     */
    useEffect(() => {
        getResult();
    }, []);


    /**
     * Fired whenever a user clicks into a tab.
     *
     * @param event
     * @param tab
     */
    const handleTabChange = (event, tab) => {
        setTab(tab);
    };


    /**
     * Loads the target record.
     *
     * @returns {Promise<void>}
     */
    const getResult = async () => {
        Logger.debug(`[ClientView] Loading client result...`);
        setLoading(true);
        setRecord(
            await API.get(`${ClientModel.getRoute()}/${id}`, ClientModel.getQuery())
        );
        setLoading(false);
    };


    /**
     * Redirects the user to the schedule page.
     */
    const handleScheduleRedirect = () => {
        if(!isCalling){
            window.location.href = `/schedule/client/${record.id}`;
            return;
        }

        navigate(`/schedule/client/${record.id}`);

        if (onClose) {
            onClose();
        }
    };


    /**
     * Redirects the user to the map page.
     */
    const handleMapRedirect = () => {
        if(!isCalling){
            window.location.href = `/map/${record.latitude}/${record.longitude}`;
            return;
        }

        navigate(`/map/${record.latitude}/${record.longitude}`);

        if (onClose) {
            onClose();
        }
    };


    /**
     * Triggered whenever the assigned employees have been updated.
     *
     * @returns {Promise<void>}
     */
    const handleScheduleUpdated = async () => {
        getResult();

        if (doReload) {
            doReload();
        }
    };

    return (
        <Box className={'details__view'}>
            <Box className={'d-flex__justify'}>
                <ProfileBadge
                    icon={ClientModel.icon({fontSize: 'large'})}
                    model={ClientModel}
                    record={record}
                    heading={`${record.name}`}
                    decorator={
                        <Box sx={{marginLeft: '0.5em'}}>
                            <ClientStatusChip record={record}/>
                        </Box>
                    }
                    horizontal
                    imageField={'image'}
                    subheading={`Since ${Formatter.date(record.createdDate)}`}
                />

                <Box className={'d-flex__start'}>
                    <IconButton
                        onClick={handleScheduleRedirect}
                        children={<CalendarMonthIcon/>}
                        className={'mr__1'}
                    />
                    <IconButton
                        onClick={handleMapRedirect}
                        children={<PinDropIcon/>}
                        disabled={!record.latitude || !record.longitude}
                    />
                </Box>
            </Box>

            <Box className={'d-flex details__body'}>
                <Box sx={{flexGrow: 1, position: 'relative'}}>
                    <Paper>
                        <TabHeading
                            tab={tab}
                            actions={
                                <ClientActions
                                    record={record}
                                    onClose={onClose}
                                    doReload={getResult}
                                />
                            }
                            onChange={handleTabChange}
                        >
                            <Tab label="Details" value={'Details'}/>
                            {hasPermissionTo('VIEW_CLIENT_FILES') && (
                                <Tab label="Files" value={'Files'}  disabled={!record.id}/>
                            )}
                            <Tab label="Employees" value={'Employees'} disabled={!record.id}/>
                        </TabHeading>
                        <TabPanel value={tab} index={'Details'}>
                            <ClientDetails record={record}/>
                        </TabPanel>
                        <TabPanel value={tab} index={'Files'} fullWidth lazyLoad>
                            <FileBrowser basePath={`clients/${record.id}`}/>
                        </TabPanel>
                        <TabPanel value={tab} index={'Employees'} fullWidth lazyLoad>
                            <ClientSchedule
                                record={record}
                                doReload={handleScheduleUpdated}
                            />
                        </TabPanel>
                        {hasPermissionTo('VIEW_CLIENT_RATES') && (
                            <TabPanel value={tab} index={'Rates'} fullWidth>
                                <ClientPayDetails record={record}/>
                            </TabPanel>
                        )}
                    </Paper>
                </Box>
                <Box className={'details__right'}>
                    <Box className={'columns__1'}>
                        <ContactsBlock record={record} doReload={getResult}/>
                        <NotesBlock record={record} modelId={id} modelType={'Client'}/>
                    </Box>
                </Box>
            </Box>
        </Box>
    );

};

export default ClientView;