import React from "react";

import Client from "../../Models/Client";
import TableLayout from "../../Components/Layouts/TableLayout";

/**
 * Clients component.
 *
 * @returns {*}
 * @constructor
 */
const Clients = () => (
    <TableLayout
        icon={Client.icon()}
        model={Client}
        heading={'Clients'}
        applySearch={search => {
            const filter = Client.getQuery().$filter;
            return `${filter ? `${filter} and ` : ''}name eq {${search}} or type eq {${search}} or addressLine1 eq {${search}} or emailAddress eq {${search}} or helixId eq {${search}}`
        }}
        formPermission={'EDIT_CLIENTS'}
    />
);

export default Clients;