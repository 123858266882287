import React from "react";

import Settings from "../../../Global/Settings";
import Formatter from "../../../Global/Formatter";
import DetailWrapper from "../../../Components/Layouts/Profile/DetailWrapper";

/**
 * EmployeeDetails component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const EmployeeDetails = (props) => {
    const {
        record      // {Object} The record in which to reference.
    } = props;

    // Combine the address fields for display purposes.
    const formattedAddress = Formatter.address(
        record.addressLine1 || '',
        record.addressLine2 || '',
        record.apartmentNumber || '',
        record.city || '',
        record.state && record.state.abbreviation ? record.state.abbreviation : '',
        record.postalCode || ''
    );

    return (
        <div className={'columns__1'}>
            <div className={'columns__3'}>
                <DetailWrapper
                    label={'Name'}
                    value={`${record.firstName} ${record.lastName}`}
                />
                <DetailWrapper
                    label={'SSN'}
                    value={Formatter.ssn(record.ssn)}
                />
                <DetailWrapper
                    label={'Phone'}
                    value={<a href={`tel:${record.phoneNumber}`}>{Formatter.phoneString(record.phoneNumber)}</a>}
                />
                <DetailWrapper
                    label={'Birth Date'}
                    value={Formatter.date(record.birthDate, '', false)}
                />
                <DetailWrapper
                    label={'Email Address'}
                    value={<a href={`mailto:${record.emailAddress}`}>{record.emailAddress}</a>}
                />
                <DetailWrapper
                    label={'Hire Date'}
                    value={Formatter.date(record.hireDate, '', false)}
                />
                <DetailWrapper
                    label={'Activation Date'}
                    value={Formatter.date(record.activatedDate)}
                />
                <DetailWrapper
                    label={'Termination Date'}
                    value={Formatter.date(record.terminationDate)}
                />
                <DetailWrapper
                    label={'Address'}
                    value={formattedAddress}
                />
            </div>

            {/*Display the employee's address on a map embed.*/}
            {formattedAddress ? <iframe
                src={`https://www.google.com/maps/embed/v1/place?key=${Settings.googleApiKey}&q=${formattedAddress}`}
                width={'100%'}
                style={{border: 0}}
                height={'250'}
                loading={'lazy'}
                referrerPolicy={'no-referrer-when-downgrade'}
                allowFullScreen
            /> : null}
        </div>
    );
};

export default EmployeeDetails;