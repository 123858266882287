import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import FileItem from "../../../Components/Layouts/Profile/DocumentList/FileItem";
import {useAuth} from "../../../Global/Auth";
import LogoHeading from "../../../Components/Typography/LogoHeading";
import StepControls from "../../../Components/StepControls";
import DialogHeading from "../../../Components/Typography/DialogHeading";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

/**
 * CredentialStep component.
 *
 * @constructor
 */
const CredentialStep = (props) => {
    const {
        step,                   // {Number} The current step of the form.
        loading,                // {Boolean} Indicates if the form is currently loading.
        onStepChange            // {Function} Handles the step transition.
    } = props;

    const {user} = useAuth();
    const [isLoading, setLoading] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [isConfirming, setConfirming] = useState(false);
    const [documentTypes, setDocumentTypes] = useState([]);

    /**
     * Load all required data on component mount.
     */
    useEffect(() => {
        getData();
    }, []);


    /**
     * Load all required data for the view.
     *
     * @returns {Promise<void>}
     */
    const getData = async () => {
        setLoading(true);

        await Promise.all([
            getDocuments(),
            getRequiredTypes(),
        ]);

        setLoading(false);
    };


    /**
     * Loads all of our required documents for the current user.
     *
     * @returns {Promise<void>}
     */
    const getDocuments = async () => {
        setDocuments(
            await API.get('employee-documents', {
                $top: 250,
                $orderby: 'createdDate desc'
            })
        );
    };


    /**
     * Loads all required document types.
     *
     * @returns {Promise<void>}
     */
    const getRequiredTypes = async () => {
        setDocumentTypes(
            await API.get('document-types', {
                $filter: 'isDeleted eq {0} and isRecruiting eq {1} and name ne {Application}',
                $orderby: 'name asc'
            })
        );
    };


    /**
     * Performs validation on the required documents.
     *
     * @param updated
     * @returns {*}
     */
    const handleValidation = (updated) => {
        let valid = true;

        if (updated < step) {
            return onStepChange(updated);
        }

        // Attempt to find matching documents for each type.
        documentTypes.map(type => {
            if (!documents.filter(document => document.documentTypeId === type.id).length) {
                valid = false;
            }
        });

        // Proceed in the form accordingly.
        if (valid) {
            onStepChange(updated);
        } else {
            setConfirming(true);
        }
    };


    /**
     * Closes the confirmation dialog.
     */
    const handleConfirmationClose = () => {
        setConfirming(false);
    };

    return (
        <>
            <LogoHeading title={'Credentials'}/>

            <Box className={'columns__1'}>
                <Box>
                    Please upload professional-quality scans or photographs for each of the following
                    documents / credentials.
                </Box>

                <Box className={'file__list'}>
                    {isLoading ? (
                        <List>
                            {Array.from(Array(3).keys()).map(i => (
                                <Box key={i} className={'file'}>
                                    <Box className={'file__body'}>
                                        <IconButton
                                            disabled
                                            children={<Skeleton variant="circular" width={20} height={20}/>}
                                            className={'mr__1'}
                                        />
                                        <Box>
                                            <Skeleton width={125 + Math.floor(Math.random() * 50)}/>
                                        </Box>
                                    </Box>
                                </Box>
                            ))}
                        </List>
                    ) : (
                        <>
                            {documentTypes.map(type => {
                                const existing = documents.filter(document => document.documentTypeId === type.id);

                                return (
                                    <FileItem
                                        key={type.id}
                                        file={type}
                                        mobile
                                        record={existing.length ? existing[0] : {}}
                                        onUpload={getDocuments}
                                        onDelete={getDocuments}
                                        employeeId={user.id}
                                    />
                                );
                            })}
                        </>
                    )}
                </Box>

                <StepControls
                    step={step}
                    onBack={onStepChange}
                    onNext={handleValidation}
                    disabled={loading}
                />
            </Box>

            <Dialog
                open={isConfirming}
                scroll={'body'}
                onClose={handleConfirmationClose}
                maxWidth={'sm'}
                fullWidth
            >
                <DialogHeading
                    title={'Missing Documents'}
                    noMargin
                />
                <DialogContent>
                    <Box className={'columns__1'}>
                        You haven't uploaded all of the required credentials. Would you like to continue anyway?
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={() => onStepChange(step + 1)}
                        children={'Continue'}
                    />
                    <Button
                        color={'error'}
                        onClick={handleConfirmationClose}
                        children={'Cancel'}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CredentialStep;