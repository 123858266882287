import React, {useState} from "react";

import API from "../Global/API";

import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import {useNavigate} from "react-router";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

/**
 * CallLogActions component.
 *
 * @returns {*}
 * @constructor
 */
const CallLogActions = (props) => {
    const {
        record,         // {Object} The target record / context.
        doReload,       // {Function} Reloads the parent layout, if applicable.
    } = props;

    const navigate = useNavigate();
    const [note, setNote] = useState(record.note || '');
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isAddingNote, setAddingNote] = useState(false);

    /**
     * Indicates whether an anchor target exists for the menu.
     *
     * @type {boolean}
     */
    const open = Boolean(anchorEl);


    /**
     * The recipient object for the call log entry.
     */
    const recipient = {...record.recipient};


    /**
     * Indicates if the record is viewable.
     *
     * @type {boolean}
     */
    const isViewable = ['Employee', 'Client'].includes(recipient['@type'] || '');


    /**
     * Returns the appropriate recipient endpoint.
     *
     * @type {string}
     */
    const viewEndpoint = recipient['@type'] === 'Client' ? 'clients' : 'employees';


    /**
     * The view URL for the recipient.
     *
     * @type {string|string}
     */
    const viewUrl = isViewable ? `/${viewEndpoint}/${recipient.id}` : '';


    /**
     * Fired whenever the menu button is clicked.
     *
     * @param event
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Closes the menu popover.
     */
    const handleClose = () => {
        setAnchorEl(null);
    };


    /**
     * Reveals the note edit form.
     */
    const handleAddNoteOpen = () => {
        setNote(record.note || '');
        setAddingNote(true);
        handleClose();
    };


    /**
     * Closes the note edit form.
     */
    const handleAddNoteClose = () => {
        setAddingNote(false);
    };


    /**
     * Saves the updated note content.
     *
     * @returns {Promise<void>}
     */
    const doNoteSave = async () => {
        if(!record || !record.id){
            return setAddingNote(false);
        }

        setLoading(true);

        // Perform the update to the call log.
        const response = await API.put(`call-logs/${record.id}`, {
            note
        });

        // Reload the layout.
        if(doReload){
            doReload(response);
        }

        setAddingNote(false);
        setLoading(false);
    };

    return (
        <Box>
            <IconButton
                onClick={handleClick}
                children={<MoreHorizIcon/>}
            />
            <Menu
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
            >
                <MenuItem
                    onClick={() => navigate(viewUrl)}
                    disabled={!isViewable}
                    children={'View Recipient'}
                />
                <MenuItem
                    onClick={handleAddNoteOpen}
                    children={!!note ? 'Edit Note' : 'Add a Note'}
                />
                <Divider/>
                <MenuItem
                    onClick={handleClose}
                    disabled={true}
                    children={'Delete Record'}
                    className={'menu__error'}
                />
            </Menu>

            {isAddingNote && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handleAddNoteClose}
                    maxWidth={'sm'}
                    fullWidth
                >
                    <DialogContent>
                        <Box className={'columns__1'}>
                            <InputLabel
                                children={'Call Log Note'}
                                className={'form__heading form__heading--standalone'}
                            />
                            <TextField
                                rows={4}
                                value={note}
                                label={'Enter your note...'}
                                disabled={isLoading}
                                onChange={event => setNote(event.target.value)}
                                multiline
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={doNoteSave}
                            children={'Save'}
                            disabled={isLoading}
                        />
                        <Button
                            color={'error'}
                            onClick={handleAddNoteClose}
                            children={'Close'}
                            disabled={isLoading}
                        />
                    </DialogActions>
                </Dialog>
            )}
        </Box>
    );
};

export default CallLogActions;