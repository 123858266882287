import React, {useState} from "react";

import Page from "../../Components/Page";
import Step from "../../Components/Step";
import LogoHeading from "../../Components/Typography/LogoHeading";
import DirectDepositStep from "./HirePacket/DirectDepositStep";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router";

/**
 * DirectDeposit component.
 *
 * @returns {JSX.Element}
 * @constructor
 *
 * @description Allows employees to upload credential documents to their profile.
 */
const DirectDeposit = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(0);

    return (
        <Page
            hideHeader
        >
            <DirectDepositStep
                step={step}
                index={0}
                onValidate={() => setStep(1)}
                description={
                    <>
                        Please use this form to update your direct deposit information. If you have any questions,
                        contact the office using the chat feature within your profile.
                    </>
                }
                onStepChange={setStep}
                addBackButton
                settingsPrefix={'HP_DD_'}
            />

            <Step step={1} value={step}>
                <LogoHeading title={'Thank You!'}/>

                <Box className={'columns__1'}>
                    <Box>
                        Your direct deposit information has been updated successfully! Please use the following
                        button to return to your dashboard.
                    </Box>
                    <Button
                        variant={'outlined'}
                        onClick={() => navigate('/')}
                        children={'Close'}
                    />
                </Box>
            </Step>
        </Page>
    );
};

export default DirectDeposit;