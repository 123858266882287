import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import {useAuth} from "../../../Global/Auth";
import ActionButton from "../../../Components/ActionButton";
import SectionHeading from "../../../Components/Typography/SectionHeading";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Alert from "@mui/material/Alert";
import {Link} from "react-router-dom";
import Confetti from "react-confetti";
import SchoolIcon from '@mui/icons-material/School';
import {useNavigate} from "react-router";
import HistoryEduIcon from '@mui/icons-material/HistoryEdu';
import {useWindowSize} from "react-use";
import CelebrationIcon from "@mui/icons-material/Celebration";
import BrowseGalleryIcon from '@mui/icons-material/BrowseGallery';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import RecruitingSteps from "./RecruitingSteps";

/**
 * PipelineEmployeeExperienceComplete component.
 *
 * @constructor
 */
const PipelineEmployeeExperienceComplete = (props) => {
    const {
        employee,           // {Object} The employee record object from the API.
        doReload,           // {Function} A reload callback to refresh the employee.
    } = props;

    const {logout} = useAuth();
    const navigate = useNavigate();
    const {width, height} = useWindowSize();
    const [showConfetti, setShowConfetti] = useState(true);

    /**
     * Reveal the confetti for a finite period of time.
     */
    useEffect(() => {
        onLoad();
    }, [employee]);


    /**
     * Returns true if we have some confetti on the screen.
     *
     * @type {boolean}
     */
    const isShowingConfetti = !employee.seenConfetti && showConfetti;


    /**
     * Initializes the confetti mechanism on mount.
     *
     * @returns {Promise<void>}
     */
    const onLoad = async () => {
        if (employee.seenConfetti) {
            setShowConfetti(false);
            return;
        }

        setShowConfetti(true);

        await API.put(`employees/${employee.id}`, {
            seenConfetti: true
        });

        setTimeout(() => {
            setShowConfetti(false);
        }, 3000);
    }


    /**
     * Used to celebrate the employee's completion of the pipeline.
     *
     * @returns {Promise<void>}
     */
    const doConfetti = async () => {
        if(isShowingConfetti){
            return;
        }

        if(employee.seenConfetti) {
            await API.put(`employees/${employee.id}`, {
                seenConfetti: false
            });
        }

        if (doReload) {
            await doReload();
        }
    };


    /**
     * Handles user logout.
     */
    const handleLogout = () => {
        logout().then(() => {
            navigate('/login');
        });
    };

    return (
        <>
            {!employee.seenConfetti && (
                <Confetti
                    width={width}
                    height={height}
                    numberOfPieces={showConfetti ? 200 : 0}
                />
            )}

            <Box className={'columns__1'}>
                <Alert severity={'success'} icon={<CelebrationIcon onClick={doConfetti}/>}>
                    <b>Congratulations!</b> Now that you have successfully completed your checklist items, a team
                    member at the office will be in touch within 48 hours to discuss your next steps. In the
                    meantime, feel free to <Link to={'/profile'}>update your profile</Link>, or review your onboarding
                    documentation below.
                </Alert>

                <Box>
                    <SectionHeading title={'Documentation'}/>

                    <RecruitingSteps/>
                </Box>
            </Box>
        </>
    );
};

export default PipelineEmployeeExperienceComplete;