import React from "react";

import Box from "@mui/material/Box";

/**
 * ClientStatusIndicator component.
 *
 * @constructor
 */
const ClientStatusIndicator = (props) => {
    const {
        record,         // {Object} The client record to display the status of.
        className,      // {String} An optional class to include on the indicator.
    } = props;

    return record.status && record.status.name ? (
        <Box className={`notification__indicator ${className || ''}`} sx={{background: record.status.color, boxShadow: `0 0 6px ${record.status.color}85`}}/>
    ) : (
        !record.isDeleted ?
            <Box className={`notification__indicator ${className || ''}`} sx={{background: '#2e7d32', boxShadow: `0 0 6px #2e7d3285`}}/> :
            <Box className={`notification__indicator ${className || ''}`}/>
    )
};

export default ClientStatusIndicator;