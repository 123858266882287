import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import Page from "../../Components/Page";
import Logger from "../../Global/Logger";
import {useAuth} from "../../Global/Auth";
import CourseForm from "../../Actions/CourseActions/CourseForm";
import BannerAlert from "../../Components/Typography/BannerAlert";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import SaveIcon from '@mui/icons-material/Save';
import IconButton from "@mui/material/IconButton";
import SchoolIcon from '@mui/icons-material/School';
import {useParams} from "react-router";

/**
 * CourseEditor component.
 *
 * @returns {*}
 * @constructor
 */
const CourseEditor = () => {
    const {
        id              // {String} An optional ID to include for editing.
    } = useParams();

    const {hasPermissionTo} = useAuth();
    const [course, setCourse] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [isUpdated, setUpdated] = useState(false);
    const [isSuccessful, setSuccessful] = useState(false);
    const [changeLength, setChangeLength] = useState(0);

    /**
     * Indicates if the user has permission to edit training.
     *
     * @type {Boolean}
     */
    const isChangeEnabled = hasPermissionTo('EDIT_TRAINING');


    /**
     * Indicates if the form is disabled.
     *
     * @type {boolean}
     */
    const isDisabled = !isChangeEnabled || isLoading;


    /**
     * Load all ancillary data on component mount.
     */
    useEffect(() => {
        getCourse();
    }, []);


    /**
     * Pulls all the current course data via the API.
     *
     * @returns {Promise<void>}
     */
    const getCourse = async () => {
        if (!id) {
            return;
        }

        setCourse(
            await API.get(`course/${id}`, {
                $expand: 'sections($expand=test($expand=questions);$orderby=order asc)'
            })
        )
    };


    /**
     * Processes the course update transaction.
     */
    const doSave = async () => {
        setLoading(true);

        const payload = {
            name: course.name,
            image: course.image,
            isRequired: course.isRequired,
            isPublished: course.isPublished,
            longDescription: course.longDescription,
            shortDescription: course.shortDescription,
        };

        Logger.debug('[CourseEditor] Saving "Course" record:', payload);

        const response = course.id ?
            await API.put(`course/${course.id}`, payload) :
            await API.post(`courses`, payload);

        Logger.debug('[CourseEditor] "Course" response:', response);

        if (!course.id) {
            course.id = response.id;
        }

        if (!course.sections) {
            course.sections = [];
        }

        await Promise.all(
            course.sections.map((section, i) => (async () => {
                const sectionPayload = {
                    name: section.name,
                    order: i,
                    isTest: section.isTest,
                    testId: section.testId,
                    content: section.content,
                    courseId: course.id,
                    isDeleted: section.isDeleted,
                    description: section.description,
                };

                Logger.debug(`[CourseEditor] Saving "CourseSection" record, index "${i}":`, payload);

                const sectionResponse = section.id ?
                    await API.put(`course-section/${section.id}`, sectionPayload) :
                    await API.post(`course-sections`, sectionPayload);

                Logger.debug(`[CourseEditor] "CourseSection" response, index "${i}":`, response);

                if (!section.id) {
                    section.id = sectionResponse.id;
                    course.sections[i] = section;
                }

                // Handle the test save if there were one.
                if (section.isTest) {
                    section.test = {
                        ...section.test
                    };

                    if (!section.test.questions) {
                        section.test.questions = [];
                    }

                    const payload = {
                        passingPercentage: section.test.passingPercentage,
                    };

                    Logger.debug(`[CourseEditor] Saving "Test" record for section index "${i}":`, payload);

                    const response = section.test.id ?
                        await API.put(`test/${section.test.id}`, payload) :
                        await API.post(`tests`, payload);

                    Logger.debug('[CourseEditor] "Test" response:', response);

                    if (!section.test.id) {
                        section.test.id = response.id;
                    }

                    // Update the singular test reference.
                    if (!section.testId || section.testId !== section.test.id) {
                        Logger.debug(`[CourseEditor] Assigning new test record to section index "${i}", record ID: ${section.test.id}`);

                        await API.put(`course-section/${section.id}`, {
                            testId: section.test.id
                        });

                        section.testId = section.test.id;
                    }

                    course.sections[i] = section;
                    sectionPayload['testId'] = section.test.id;

                    // Handle each individual question.
                    await Promise.all(
                        section.test.questions.map((question, j) => (async () => {
                            const payload = {
                                order: j,
                                testId: section.testId,
                                answer: question.answer,
                                options: question.options,
                                question: question.question,
                                isDeleted: question.isDeleted
                            };

                            Logger.debug(`[CourseEditor] Saving "TestQuestion" record for section index "${i}", question index "${j}":`, payload);

                            const response = question.id ?
                                await API.put(`test-question/${question.id}`, payload) :
                                await API.post(`test-questions`, payload);

                            // Capture the section ID for new records.
                            if (!question.id) {
                                question.id = response.id;
                                section.test.questions[j] = question;
                                course.sections[i] = section;
                            }
                        })())
                    )
                }
            })())
        );

        setCourse({...course});
        setUpdated(false);
        setLoading(false);
        setSuccessful(true);
        setChangeLength(0);
    };


    /**
     * Handles any updates made against the form.
     *
     * @param course
     */
    const handleChange = (course) => {
        setCourse(course);
        setUpdated(true);
        setChangeLength(changeLength + 1);
    };

    return (
        <Page hideHeader fullScreen>
            <Box className={'page__heading'}>
                <Box className={'index__title d-flex__start'}>
                    <SchoolIcon/>
                    <h2>Course Editor</h2>
                </Box>
                {hasPermissionTo('EDIT_TRAINING') && (
                    <Box className={'d-flex'}>

                    {isUpdated && changeLength > 25 && (
                            <BannerAlert
                                severity={'info'}
                            >
                                <b>Heads up!</b> Looks like it's been awhile since you saved.
                            </BannerAlert>
                        )}

                        <IconButton onClick={doSave} disabled={isLoading}>
                            <SaveIcon/>
                        </IconButton>
                    </Box>
                )}
            </Box>
            <Divider/>
            <CourseForm
                record={course}
                loading={isLoading}
                onChange={handleChange}
                disabled={!isChangeEnabled}
            />
            <Snackbar
                open={isSuccessful}
                onClose={() => setSuccessful(false)}
                autoHideDuration={6000}
            >
                <Alert
                    onClose={() => setSuccessful(false)}
                    children={'Successfully saved course!'}
                    severity={'success'}
                />
            </Snackbar>
        </Page>
    );
};

export default CourseEditor;