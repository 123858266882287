import React, {useState} from "react";

import Step from "../../Components/Step";
import Page from "../../Components/Page";
import {useAuth} from "../../Global/Auth";
import LogoHeading from "../../Components/Typography/LogoHeading";
import RecruitingSteps from "./Dashboard/RecruitingSteps";
import OfferLetterStep from "./OfferLetter/OfferLetterStep";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router";
import LinearProgress from "@mui/material/LinearProgress";

/**
 * OfferLetter component.
 *
 * @returns {*}
 * @constructor
 */
const OfferLetter = () => {
    const {user} = useAuth();
    const navigate = useNavigate();
    const [step, setStep] = useState(0);

    /**
     * The total number of steps, calculates the top progress bar.
     *
     * @type {number}
     */
    const totalSteps = 1;

    return (
        <Page hideHeader fullScreen>
            <LinearProgress
                value={100 * (step / totalSteps)}
                variant={"determinate"}
                className={'mobile__progress'}
            />

            <Box className={'p__3 pt__0 columns__1'}>
                <OfferLetterStep
                    step={step}
                    index={0}
                    employee={user}
                    onStepChange={setStep}
                />

                <Step step={1} value={step}>
                    <LogoHeading title={'Thank You!'}/>

                    <Box className={'columns__1'}>
                        <Box>
                            <Box className={'mb__3'}>
                                Your disclosures have been completed successfully. Select your next step from
                                the list below, or use the "Home" button below to return to your dashboard.
                            </Box>

                            <RecruitingSteps/>
                        </Box>

                        <Button
                            variant={'outlined'}
                            onClick={() => navigate('/')}
                            children={'Home'}
                        />
                    </Box>
                </Step>
            </Box>
        </Page>
    );
};

export default OfferLetter;