import React, {useState} from "react";

import Menu from "@mui/material/Menu";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

/**
 * SectionMenu component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const SectionMenu = (props) => {
    const {
        onDelete,           // {Function} A delete callback, used to remove the section from the course.
        onMoveUpward,       // {Function} A function callback to move the section upward in order.
        onMoveDownward,     // {Function} A function callback to move the section downward in order.
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    /**
     * Indicates whether or not we have an anchor target.
     *
     * @type {boolean}
     */
    const open = Boolean(anchorEl);


    /**
     * Reveals the menu popover.
     */
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Closes the menu popover.
     */
    const handleClose = () => {
        setAnchorEl(null);
    };


    /**
     * Deletes the section.
     */
    const handleDelete = () => {
        handleClose();

        if(onDelete){
            onDelete();
        }
    };


    /**
     * Processes the move upward.
     */
    const handleMoveUpward = () => {
        handleClose();

        if(onMoveUpward){
            onMoveUpward();
        }
    };


    /**
     * Processes the move downward.
     */
    const handleMoveDownward = () => {
        handleClose();

        if(onMoveDownward){
            onMoveDownward();
        }
    };

    return (
        <>
            <IconButton onClick={handleOpen}>
                <MoreHorizIcon/>
            </IconButton>

            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <MenuItem onClick={handleMoveUpward} disabled={!onMoveUpward}>Move Up</MenuItem>
                <MenuItem onClick={handleMoveDownward} disabled={!onMoveDownward}>Move Down</MenuItem>
                <Divider/>
                <MenuItem onClick={handleDelete} className={'menu__error'}>Delete Section</MenuItem>
            </Menu>
        </>
    )
};

export default SectionMenu;