import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import {useAuth} from "../../../Global/Auth";
import Formatter from "../../../Global/Formatter";
import {_navigate} from "../../../Global/History";
import LogoHeading from "../../../Components/Typography/LogoHeading";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import {useNavigate} from "react-router-dom";
import FormControlLabel from "@mui/material/FormControlLabel";

/**
 * BenefitsForm component.
 *
 * @constructor
 */
const BenefitsForm = () => {
    const {user} = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [lastName, setLastName] = useState(user.lastName || '');
    const [isLoading, setLoading] = useState(false);
    const [firstName, setFirstName] = useState(user.firstName || '');
    const [isSubmitted, setSubmitted] = useState(false);
    const [isSuccessful, setSuccessful] = useState(false);
    const [emailAddress, setEmailAddress] = useState(user.emailAddress || '');

    /**
     * Various responses to the form questionnaire.
     */
    const [lifeEvent, setLifeEvent] = useState('');
    const [isFullTime, setFullTime] = useState(null);
    const [isNewlyHired, setNewlyHired] = useState(null);
    const [wantToSignUp, setWantToSignUp] = useState(null);
    const [modifyStatement, setModifyStatement] = useState('');
    const [questionStatement, setQuestionStatement] = useState('');
    const [hasQualifyingEvent, setHasQualifyingEvent] = useState(null);
    const [needInsuranceCards, setNeedInsuranceCards] = useState(null);
    const [currentlyHaveBenefits, setCurrentlyHaveBenefits] = useState(null);
    const [wantToModifyInsurance, setWantToModifyInsurance] = useState(null);
    const [haveQuestionAboutInsurance, setHaveQuestionAboutInsurance] = useState(null);

    /**
     * Scroll to the bottom automatically for any boolean answers.
     */
    useEffect(() => {
        handleScrollToBottom();
    }, [isFullTime, isNewlyHired, wantToSignUp, hasQualifyingEvent, needInsuranceCards, currentlyHaveBenefits, wantToModifyInsurance, haveQuestionAboutInsurance])


    /**
     * Navigates back to the home page.
     */
    const backToHome = () => {
        _navigate('/');
    };


    /**
     * Scrolls the user to the top of the page.
     */
    const handleScrollToBottom = () => {
        window.scrollTo({
            top: document.body.scrollHeight,
            behavior: "smooth"
        });
    };


    /**
     * Processes the form submission and sends a message to the scheduler.
     *
     * @returns {Promise<void>}
     */
    const handleSubmit = async (qualifier, statement) => {
        setError('');
        setSubmitted(true);

        const requirements = [
            lastName,
            firstName,
            emailAddress,
        ];

        if (!requirements.filter(requirement => !requirement).length) {
            setLoading(true);

            // Life Event
            // Newly Hired
            // Modify Insurance
            // Question
            await API.post('messages/benefits-request', {
                lastName,
                qualifier,
                statement,
                firstName,
                emailAddress,
            });

            setLoading(false);
            setSuccessful(true);
        } else {
            setError('Please answer all required fields.')
        }
    };

    return (
        <Box>
            <LogoHeading
                title={'My Benefits'}
                actionSlot={
                    <Button
                        size={'small'}
                        variant={'outlined'}
                        onClick={() => navigate('/')}
                        children={'Back'}
                        className={'text__small'}
                    />
                }
            />

            {!isSuccessful ? (
                <Box className={'columns__1'}>
                    <Box>
                        This interactive form will guide you through our process for requesting benefits information.
                    </Box>

                    <Box className={'columns__1'} data-aos={'fade-right'}>
                        <Divider>Question 1</Divider>
                        <FormControl disabled={!!currentlyHaveBenefits}>
                            <FormLabel>Do you currently have benefits with Reliant?</FormLabel>
                            <RadioGroup
                                row
                                value={currentlyHaveBenefits}
                                onChange={event => setCurrentlyHaveBenefits(event.target.value)}
                                className={'benefits__radio'}
                            >
                                <FormControlLabel
                                    value={'Yes'}
                                    label={'Yes'}
                                    control={
                                        <Radio/>
                                    }
                                />
                                <FormControlLabel
                                    value={'No'}
                                    label={'No'}
                                    control={
                                        <Radio/>
                                    }
                                />
                            </RadioGroup>
                        </FormControl>
                    </Box>

                    {currentlyHaveBenefits === 'No' && (
                        <Box className={'columns__1'} data-aos={'fade-right'}>
                            <Divider>Question 2</Divider>
                            <FormControl disabled={!!wantToSignUp}>
                                <FormLabel>Are you interested in signing up for benefits?</FormLabel>
                                <RadioGroup
                                    row
                                    value={wantToSignUp}
                                    onChange={event => setWantToSignUp(event.target.value)}
                                    className={'benefits__radio'}
                                >
                                    <FormControlLabel
                                        value={'Yes'}
                                        label={'Yes'}
                                        control={<Radio/>}
                                    />
                                    <FormControlLabel
                                        value={'No'}
                                        label={'No'}
                                        control={<Radio/>}
                                    />
                                </RadioGroup>
                            </FormControl>

                            {wantToSignUp === 'No' && (
                                <Box className={'columns__1'} data-aos={'fade-right'}>
                                    <Alert icon={false} severity={'warning'}>
                                        This feature has no further options. Either restart the form or exit. Thanks for
                                        inquiring!
                                    </Alert>
                                    <Button variant={'contained'} onClick={backToHome}>
                                        Back to "Home"
                                    </Button>
                                </Box>
                            )}

                            {wantToSignUp === 'Yes' && (
                                <Box className={'columns__1'} data-aos={'fade-right'}>
                                    <Divider>Question 3</Divider>
                                    <FormControl disabled={!!isNewlyHired}>
                                        <FormLabel>
                                            Are you newly hired and have been working for the last 30-60 days?
                                        </FormLabel>
                                        <RadioGroup
                                            row
                                            value={isNewlyHired}
                                            onChange={event => setNewlyHired(event.target.value)}
                                            className={'benefits__radio'}
                                        >
                                            <FormControlLabel
                                                value={'Yes'}
                                                label={'Yes'}
                                                control={<Radio/>}
                                            />
                                            <FormControlLabel
                                                value={'No'}
                                                label={'No'}
                                                control={<Radio/>}
                                            />
                                        </RadioGroup>
                                    </FormControl>

                                    {isNewlyHired === 'No' && (
                                        <Box className={'columns__1'} data-aos={'fade-right'}>
                                            <Divider>Question 4</Divider>
                                            <FormControl disabled={!!hasQualifyingEvent}>
                                                <FormLabel>
                                                    Do you have a qualifying life event such as getting married, having
                                                    a baby, losing existing health insurance, death in the family, or a
                                                    change in residence?
                                                </FormLabel>
                                                <RadioGroup
                                                    row
                                                    value={hasQualifyingEvent}
                                                    onChange={event => setHasQualifyingEvent(event.target.value)}
                                                    className={'benefits__radio'}
                                                >
                                                    <FormControlLabel
                                                        value={'Yes'}
                                                        label={'Yes'}
                                                        control={<Radio/>}
                                                    />
                                                    <FormControlLabel
                                                        value={'No'}
                                                        label={'No'}
                                                        control={<Radio/>}
                                                    />
                                                </RadioGroup>
                                            </FormControl>

                                            {hasQualifyingEvent === 'No' && (
                                                <Box className={'columns__1'} data-aos={'fade-right'}>
                                                    <Alert icon={false} severity={'warning'}>
                                                        Open enrollment is currently closed. Eligible employees will be
                                                        able to enroll starting May 1st. Return then and restart this
                                                        process.
                                                    </Alert>
                                                    <Button variant={'contained'} onClick={backToHome}>
                                                        Back to "Home"
                                                    </Button>
                                                </Box>
                                            )}

                                            {hasQualifyingEvent === 'Yes' && (
                                                <Box className={'columns__1'} data-aos={'fade-right'}>
                                                    <Divider>Question 5</Divider>
                                                    <FormControl>
                                                        <FormLabel className={'mb__3'}>
                                                            Describe the life event in detail using dates to when and
                                                            how.
                                                        </FormLabel>
                                                        <TextField
                                                            rows={4}
                                                            value={lifeEvent || ''}
                                                            onChange={event => setLifeEvent(event.target.value)}
                                                            multiline
                                                            fullWidth
                                                            placeholder={'Please explain...'}
                                                        />
                                                    </FormControl>
                                                    <Button
                                                        variant={'contained'}
                                                        onClick={() => handleSubmit('Enroll (Life Event)', lifeEvent)}
                                                        children={'Submit'}
                                                        disabled={!lifeEvent || isLoading}
                                                    />
                                                </Box>
                                            )}
                                        </Box>
                                    )}

                                    {isNewlyHired === 'Yes' && (
                                        <Box className={'columns__1'} data-aos={'fade-right'}>
                                            <Divider>Question 4</Divider>
                                            <FormControl disabled={!!isFullTime}>
                                                <FormLabel>
                                                    Do you work full time (minimum of 32 hours a week on average)?
                                                </FormLabel>
                                                <RadioGroup
                                                    row
                                                    value={isFullTime}
                                                    onChange={event => setFullTime(event.target.value)}
                                                    className={'benefits__radio'}
                                                >
                                                    <FormControlLabel
                                                        value={'Yes'}
                                                        label={'Yes'}
                                                        control={<Radio/>}
                                                    />
                                                    <FormControlLabel
                                                        value={'No'}
                                                        label={'No'}
                                                        control={<Radio/>}
                                                    />
                                                </RadioGroup>
                                            </FormControl>

                                            {isFullTime === 'Yes' && (
                                                <Box className={'columns__1'} data-aos={'fade-right'}>
                                                    <Box>
                                                        Based on your answers, you may qualify for benefits. To complete
                                                        your request, please click the "Submit" button below, and a
                                                        representative will get back to you as soon as possible.
                                                    </Box>
                                                    <Button
                                                        variant={'contained'}
                                                        onClick={() => handleSubmit('Enroll (New Hire)', '')}
                                                        children={'Submit'}
                                                        disabled={isLoading}
                                                    />
                                                </Box>
                                            )}

                                            {isFullTime === 'No' && (
                                                <Box className={'columns__1'} data-aos={'fade-right'}>
                                                    <Alert icon={false} severity={'warning'}>
                                                        Benefits are only available to staff who work a minimum of 32
                                                        hours a week. If you work the next 30 days with an average of 32
                                                        hours/week, you will then be qualified and can start this
                                                        process over.
                                                    </Alert>
                                                    <Button variant={'contained'} onClick={backToHome}>
                                                        Back to "Home"
                                                    </Button>
                                                </Box>
                                            )}
                                        </Box>
                                    )}
                                </Box>
                            )}
                        </Box>
                    )}

                    {currentlyHaveBenefits === 'Yes' && (
                        <Box className={'columns__1'} data-aos={'fade-right'}>
                            <Divider>Question 2</Divider>
                            <FormControl disabled={!!haveQuestionAboutInsurance}>
                                <FormLabel>Do you have a question about our insurance?</FormLabel>
                                <RadioGroup
                                    row
                                    value={haveQuestionAboutInsurance}
                                    onChange={event => setHaveQuestionAboutInsurance(event.target.value)}
                                    className={'benefits__radio'}
                                >
                                    <FormControlLabel
                                        value={'Yes'}
                                        label={'Yes'}
                                        control={<Radio/>}
                                    />
                                    <FormControlLabel
                                        value={'No'}
                                        label={'No'}
                                        control={<Radio/>}
                                    />
                                </RadioGroup>
                            </FormControl>

                            {haveQuestionAboutInsurance === 'Yes' && (
                                <Alert severity={'info'} icon={false} data-aos={'fade-right'}>
                                    For general questions, please call our insurance representative Krissy Lexi
                                    at {Formatter.phone('7249295208')}.
                                </Alert>
                            )}

                            {haveQuestionAboutInsurance === 'No' && (
                                <>
                                    <Box className={'columns__1'} data-aos={'fade-right'}>
                                        <Divider>Question 3</Divider>
                                        <FormControl disabled={!!needInsuranceCards}>
                                            <FormLabel>Do you need insurance cards or proof of coverage?</FormLabel>
                                            <RadioGroup
                                                row
                                                value={needInsuranceCards}
                                                onChange={event => setNeedInsuranceCards(event.target.value)}
                                                className={'benefits__radio'}
                                            >
                                                <FormControlLabel
                                                    value={'Yes'}
                                                    label={'Yes'}
                                                    control={<Radio/>}
                                                />
                                                <FormControlLabel
                                                    value={'No'}
                                                    label={'No'}
                                                    control={<Radio/>}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </Box>

                                    {needInsuranceCards === 'Yes' && (
                                        <Alert severity={'info'} data-aos={'fade-right'}>
                                            Please call our insurance representative Krissy Lexi
                                            at {Formatter.phone('7249295208')}.
                                        </Alert>
                                    )}

                                    {needInsuranceCards === 'No' && (
                                        <>
                                            <Box className={'columns__1'} data-aos={'fade-right'}>
                                                <Divider>Question 4</Divider>
                                                <FormControl disabled={!!wantToModifyInsurance}>
                                                    <FormLabel>Do you want to modify or cancel your
                                                        insurance?</FormLabel>
                                                    <RadioGroup
                                                        row
                                                        value={wantToModifyInsurance}
                                                        onChange={event => setWantToModifyInsurance(event.target.value)}
                                                        className={'benefits__radio'}
                                                    >
                                                        <FormControlLabel
                                                            value={'Yes'}
                                                            label={'Yes'}
                                                            control={<Radio/>}
                                                        />
                                                        <FormControlLabel
                                                            value={'No'}
                                                            label={'No'}
                                                            control={<Radio/>}
                                                        />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Box>

                                            {wantToModifyInsurance === 'Yes' && (
                                                <Box className={'columns__1'} data-aos={'fade-right'}>
                                                    <Divider>Question 5</Divider>
                                                    <FormControl>
                                                        <FormLabel className={'mb__3'}>
                                                            Please complete a statement
                                                            telling us specifically which insurance you have and
                                                            what you would like to modify or cancel. Use specific
                                                            details and dates please so we know exactly what you are
                                                            trying to accomplish.
                                                        </FormLabel>
                                                        <TextField
                                                            rows={4}
                                                            value={modifyStatement || ''}
                                                            onChange={event => setModifyStatement(event.target.value)}
                                                            multiline
                                                            fullWidth
                                                            placeholder={'Please explain...'}
                                                        />
                                                    </FormControl>
                                                    <Button
                                                        variant={'contained'}
                                                        onClick={() => handleSubmit('Modify / Cancel', modifyStatement)}
                                                        children={'Submit'}
                                                        disabled={!modifyStatement || isLoading}
                                                    />
                                                </Box>
                                            )}

                                            {wantToModifyInsurance === 'No' && (
                                                <Box className={'columns__1'} data-aos={'fade-right'}>
                                                    <Divider>Question 5</Divider>
                                                    <FormControl>
                                                        <FormLabel className={'mb__3'}>
                                                            Describe your question as detailed as possible and click
                                                            "Submit". A representative will get back to you as soon as
                                                            possible with an answer.
                                                        </FormLabel>
                                                        <TextField
                                                            rows={4}
                                                            value={questionStatement || ''}
                                                            onChange={event => setQuestionStatement(event.target.value)}
                                                            multiline
                                                            fullWidth
                                                            placeholder={'Please explain...'}
                                                        />
                                                    </FormControl>
                                                    <Button
                                                        variant={'contained'}
                                                        onClick={() => handleSubmit('Question', questionStatement)}
                                                        children={'Submit'}
                                                        disabled={!questionStatement || isLoading}
                                                    />
                                                </Box>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </Box>
                    )}
                </Box>
            ) : (
                <Box className={'columns__1'}>
                    <Alert severity={'success'}>
                        Successfully received message!
                    </Alert>

                    <Box>Thank you for submitting this request, we'll reach out soon with your information!</Box>

                    <Button
                        variant={'outlined'}
                        onClick={() => _navigate('/')}
                        children={'Home'}
                    />
                </Box>
            )}
            <Snackbar
                open={!!error}
                onClose={() => setError('')}
                autoHideDuration={6000}
            >
                <Alert
                    onClose={() => setError('')}
                    children={error}
                    severity={'error'}
                />
            </Snackbar>
        </Box>
    );
};

export default BenefitsForm;