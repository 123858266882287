import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import State from "../../Global/State";
import Logger from "../../Global/Logger";
import Settings from "../../Global/Settings";
import {useAuth} from "../../Global/Auth";
import Formatter from "../../Global/Formatter";
import InputImage from "../Input/InputImage";
import Department from "../../Models/Department";
import ImageWrapper from "../ImageWrapper";
import MessageStream from "./CommunicationFeed/MessageStream";
import ChatThreadList from "./CommunicationFeed/ChatThreadList";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import ListItem from "@mui/material/ListItem";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import CloudDoneIcon from '@mui/icons-material/CloudDone';
import LinearProgress from "@mui/material/LinearProgress";
import ListItemButton from "@mui/material/ListItemButton";
import AttachmentIcon from '@mui/icons-material/AttachFile';
import CircularProgress from "@mui/material/CircularProgress";
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";

/**
 * The number of threads to display at any time.
 *
 * @type {number}
 */
const defaultThreadCount = 1;


/**
 * ChatFeed component.
 *
 * @constructor
 */
const ChatFeed = (props) => {
    const {
        onClose,                    // {Function} An optional close callback to hide the dialog.
        hasUnread,                  // {Boolean} Whether there are any unread messages.
        defaultSelectedId,          // {Number} An optional default selection.
        onDepartmentSelect,         // {Function} An optional callback for when a department is selected.
    } = props;

    const {user} = useAuth();
    const [tab, setTab] = useState('Chat');
    const [index, setIndex] = useState(0);
    const [content, setContent] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [attachment, setAttachment] = useState('');
    const [chatThread, setChatThread] = useState(State.json('last-chat-thread'));
    const [chatThreads, setChatThreads] = useState([]);
    const [departments, setDepartments] = useState([]);
    const [isLoadingFile, setLoadingFile] = useState(false);
    const [isViewingAllThreads, setViewingAllThreads] = useState(false);

    /**
     * Initialize the component on mount.
     */
    useEffect(() => {
        if (defaultSelectedId) {
            handleUserSelect(defaultSelectedId);
        }
    }, []);


    /**
     * Causes a reload on the messages whenever we have a new unread message.
     */
    useEffect(() => {
        if (!hasUnread) {
            return;
        }

        setIndex(index + 1);
    }, [hasUnread]);


    /**
     * Load all ancillary data on load.
     */
    useEffect(() => {
        State.set('last-chat-thread', JSON.stringify(chatThread));
        getDepartments();
        getChatThreads();
    }, [chatThread]);


    /**
     * Loads all outstanding chat threads.
     *
     * @returns {Promise<void>}
     */
    const getChatThreads = async () => {
        setChatThreads(
            await API.get(`employee/${user.id}/chatThreads`, {
                $top: 100,
                $filter: `isDeleted eq {0}`,
                $expand: `user,employee,department,messages($orderby=createdDate desc;$top=10)`,
                $orderby: 'updatedDate desc'
            })
        )
    };


    /**
     * Load all configured departments.
     *
     * @returns {Promise<void>}
     */
    const getDepartments = async () => {
        setDepartments(
            await API.get('departments', {
                $expand: 'userDepartments',
                $filter: 'isDeleted eq {0}',
                '@cache': true,
            })
        );
    };


    /**
     * Selects a new department and creates a corresponding thread.
     *
     * @param value
     * @returns {Promise<void>}
     */
    const handleUserSelect = async (value) => {
        const existing = await API.get(`employee/${user.id}/chatThreads`, {
            $top: 1,
            $filter: `userId in {${value}} and isDeleted eq {0}`,
            $expand: 'user,employee,department'
        });

        if (!existing || !existing.length) {
            Logger.debug('[ChatFeed] No previous threads available, starting new...');

            const response = await API.post('chat-threads', {
                userId: value,
                employeeId: user.id
            });

            return setChatThread(
                await API.get(`chat-threads/${response.id}`, {
                    $expand: 'user,employee,department'
                })
            );
        }

        Logger.debug('[ChatFeed] Assigning existing chat thread for selected department.');
        setChatThread(existing[0]);
    };


    /**
     * Selects a new department and creates a corresponding thread.
     *
     * @param value
     * @returns {Promise<void>}
     */
    const handleDepartmentSelect = async (value) => {
        if (onDepartmentSelect) {
            Logger.debug('[ChatFeed] Invoking "onDepartmentSelect" callback.');
            const response = onDepartmentSelect(value);

            if (response === false) {
                return;
            }
        }

        // Attempt to match an existing thread with the selected department.
        const existing = await API.get(`employee/${user.id}/chatThreads`, {
            $top: 1,
            $filter: `departmentId in {${value.id}} and isDeleted eq {0}`,
            $expand: 'user,employee,department'
        });

        if (!existing || !existing.length) {
            Logger.debug('[ChatFeed] No previous threads available, starting new...');

            const response = await API.post('chat-threads', {
                userId: value.userDepartments.random().userId,
                employeeId: user.id,
                departmentId: value.id
            });

            return setChatThread(
                await API.get(`chat-threads/${response.id}`, {
                    $expand: 'user,employee,department'
                })
            );
        }

        Logger.debug('[ChatFeed] Assigning existing chat thread for selected department.');
        setChatThread(existing[0]);
    };


    /**
     * Sends a message via the API.
     *
     * @returns {Promise<void>}
     */
    const handleMessageSend = async () => {
        if (!content) {
            return;
        }

        setLoading(true);

        // Determine the recipient as the latest
        await API.post(`messages`, {
            content,
            type: tab,
            senderId: user.id,
            attachment,
            senderType: 'Employee',
            recipientId: chatThread.user.id,
            chatThreadId: chatThread.id,
            recipientType: 'User',
        });

        setIndex(index + 1);
        setContent('');
        setAttachment('');
    };


    /**
     * Returns the nice name of a user.
     *
     * @param user
     * @returns {string}
     */
    const getUserNiceName = (user) => {
        return user && user.firstName ? `${user.firstName} ${user.lastName ? `${user.lastName.charAt(0)}.` : ''}` : '(no name)';
    };


    /**
     * Returns the appropriate icon for a department based on a series of mappings.
     *
     * @param department
     * @returns {*|React.JSX.Element}
     */
    const getIconForDepartment = department => {
        const selectedIcon = Department.iconOptions.find(option => option.name === department.icon)

        if(selectedIcon){
            return selectedIcon.icon({});
        }

        return <MapsHomeWorkIcon/>;
    };

    return (
        <Box className={'d-flex'}>
            <Box className={`two-column__right`}>
                {isLoading && <LinearProgress sx={{width: '100%'}}/>}

                {chatThread && chatThread.id ? (
                    <>
                        <Box className={'p__3 border__bottom'}>
                            <Box className={'d-flex__start'}>
                                <ImageWrapper
                                    src={API.getFilePath(chatThread.user && chatThread.user.image ? chatThread.user.image : '')}
                                />

                                <Box className={'ml__2 flex__grow d-flex__justify'}>
                                    <Box>
                                        <b>{getUserNiceName(chatThread.user)}</b>

                                        <div className={'text__small'}>
                                            {chatThread.user && chatThread.user.jobTitle ?
                                                chatThread.user.jobTitle : 'Support Member'
                                            }
                                        </div>
                                    </Box>

                                    {!defaultSelectedId ? (
                                        <Button
                                            size={'small'}
                                            variant={'outlined'}
                                            onClick={() => setChatThread({})}
                                            children={'Back'}
                                            disabled={isLoading}
                                        />
                                    ) : (
                                        <>
                                            {onClose && (
                                                <IconButton onClick={onClose} size={'small'}>
                                                    <CloseIcon/>
                                                </IconButton>
                                            )}
                                        </>
                                    )}
                                </Box>
                            </Box>
                        </Box>

                        <MessageStream
                            key={`${user.id || 0}-${tab}-${index}`}
                            record={user}
                            onLoadDone={() => setLoading(false)}
                            messageType={'Chat'}
                            chatThreadId={chatThread.id}
                        />

                        <Box className={'communication__controls'}>
                            <Box className={'p__3 columns__1'}>
                                {attachment && (
                                    <Alert color={'success'} className={'text__small'}>
                                        Your file is ready to send!
                                    </Alert>
                                )}

                                <TextField
                                    sx={{marginRight: '1em'}}
                                    rows={4}
                                    value={content}
                                    label={'Write a message...'}
                                    disabled={isLoading || !user.id}
                                    onChange={(event) => setContent(event.target.value)}
                                    multiline
                                    fullWidth
                                />

                                <Box className={'d-flex__justify'}>
                                    <Button
                                        variant={'outlined'}
                                        onClick={handleMessageSend}
                                        children={'Send'}
                                        disabled={isLoading || isLoadingFile || !user.id}
                                        className={'w__100 mr__2'}
                                    />

                                    <InputImage
                                        editable
                                        children={
                                            attachment ? (
                                                <Button
                                                    color={'success'}
                                                    disabled={isLoading || !user.id}
                                                    children={isLoadingFile ? <CircularProgress size={24}/> :
                                                        <CloudDoneIcon/>}
                                                    className={'message-attachment__upload'}
                                                />
                                            ) : (
                                                <Button
                                                    variant={'outlined'}
                                                    disabled={isLoading || !user.id}
                                                    children={isLoadingFile ? <CircularProgress size={24}/> :
                                                        <AttachmentIcon/>}
                                                    className={'message-attachment__upload'}
                                                />
                                            )
                                        }
                                        onChange={setAttachment}
                                        doLoading={setLoadingFile}
                                        uploadPath={`chat-threads/${chatThread.id}`}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </>
                ) : (
                    !defaultSelectedId && (
                        <Box className={'stream__container'}>
                            <Box>
                                <Box className={'d-flex__justify mb__3'}>
                                    <h3 className={'m__0'}>💬 How can we help?</h3>

                                    {onClose && (
                                        <IconButton onClick={onClose} size={'small'}>
                                            <CloseIcon/>
                                        </IconButton>
                                    )}
                                </Box>

                                {!!chatThreads.length && (
                                    <Box
                                        sx={{
                                            marginBottom: '1.5em',
                                        }}
                                        className={'p__3 well__container chat__recent-conversations'}
                                    >
                                        <Box className={'d-flex__justify'}>
                                            <b>My Conversations</b>
                                        </Box>

                                        <ChatThreadList
                                            onClick={setChatThread}
                                            chatThreads={isViewingAllThreads ?
                                                chatThreads :
                                                chatThreads.slice(0, defaultThreadCount)
                                            }
                                        />

                                        {!isViewingAllThreads && chatThreads.length > defaultThreadCount && (
                                            <Box className={'text__center'}>
                                                <a
                                                    onClick={() => setViewingAllThreads(true)}
                                                    children={`View All (${chatThreads.length})`}
                                                    className={'text__small'}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                )}

                                <Box>Select a department to send a message!</Box>

                                <Box className={'columns__1'}>
                                    <List>
                                        {departments.map((department, i) => (
                                            <ListItem disablePadding key={`department-option__${department.id}`}>
                                                <ListItemButton onClick={() => handleDepartmentSelect(department)}>
                                                    <ListItemIcon>
                                                        {getIconForDepartment(department)}
                                                    </ListItemIcon>
                                                    <ListItemText
                                                        primary={<b>{department.name}</b>}
                                                    />
                                                </ListItemButton>
                                            </ListItem>
                                        ))}
                                    </List>

                                    <Box className={'well__container p__3'}>
                                        Still not sure who to talk to? Call the
                                        office at any time at {Formatter.phone(Settings.phoneNumber)}.
                                    </Box>

                                    <Box className={'text__center text__disclaimer'} sx={{fontSize: '0.8em'}}>
                                        Version: v{Settings.version}
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    )
                )}
            </Box>
        </Box>
    );
};

export default ChatFeed;