import React from "react";

import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import {Reorder} from "framer-motion"
import DragHandleIcon from '@mui/icons-material/DragHandle';
import FormControlLabel from "@mui/material/FormControlLabel";

/**
 * ReorderableColumn component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ReorderableColumn = (props) => {
    const {
        property,                   // {Object} The property object within the layout.
        onUpdate,                   // {Function} An onchange event for the checkbox.,
        isChecked,                  // {Function} A method of determining whether or not the property is checked.
    } = props;

    return (
        <Reorder.Item
            as={'div'}
            value={property}
            className={'filter__column'}
            style={{ x: 0 }}
        >
            <Box className={'d-flex__justify'}>
                <FormControlLabel
                    label={property.getLabel()}
                    control={
                        <Checkbox
                            checked={isChecked(property)}
                            onChange={event => onUpdate(property, event.target.checked)}
                        />
                    }
                />
                <DragHandleIcon
                    className={'drag__control'}
                />
            </Box>
        </Reorder.Item>
    );
};

export default ReorderableColumn;