import React, {useState} from "react";

import App from "../../Global/App";
import API from "../../Global/API";
import File from "../../Global/File";
import DialogHeading from "../../Components/Typography/DialogHeading";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import DialogActions from "@mui/material/DialogActions";
import AttachFileIcon from '@mui/icons-material/AttachFile';

/**
 * TimeSheetDownload component.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
const TimeSheetDownload = (props) => {
    const {
        path,               // {String} The file path of the time sheet.
        label,              // {String} The text label of the button.
        variant,            // {String} An optional variant override for the button.
        hideIcon,           // {Boolean} Whether to hide the icon.
        className,          // {String} An optional class to include on the button.
        hideDownload,       // {Boolean} Whether to hide the download button.
    } = props;

    const [isPreviewing, setPreviewing] = useState(false);

    /**
     * Returns the inner document content.
     *
     * @returns {JSX.Element}
     * @constructor
     */
    const PreviewContent = () => {
        const isImage = (path || '').match(/\.(jpg|jpeg|png|gif)$/i);

        return isImage ? (
            <Box
                sx={App.isCordova() ? {} : {
                    maxHeight: 'calc(100vh - 350px)',
                    overflowY: 'scroll'
                }}
                children={
                    <img
                        src={API.getFilePath(path)}
                        className={'v-align__top'}
                    />
                }
            />
        ) : (
            <Box className={'iframe__container'}>
                <embed
                    src={API.getFilePath(path)}
                    scrolling={'no'}
                    className={'responsive-iframe'}
                />
            </Box>
        );
    };


    /**
     * Reveals the attachment preview.
     */
    const handlePreviewOpen = () => {
        setPreviewing(true);
    };


    /**
     * Closes the attachment preview modal.
     */
    const handlePreviewClose = () => {
        setPreviewing(false);
    };


    const doDownload = () => {
        File.doDownload(API.getFilePath(path), 'time-sheet');
    };

    return path ? (
        <div>
            <Button
                size={'small'}
                variant={variant || 'contained'}
                onClick={handlePreviewOpen}
                children={label || 'View File'}
                className={className || ''}
                startIcon={!hideIcon ? <AttachFileIcon/> : null}
            />

            {isPreviewing && (
                <>
                    <Dialog
                        open={true}
                        scroll={'body'}
                        onClose={handlePreviewClose}
                        maxWidth={'md'}
                        fullWidth
                    >
                        <DialogHeading
                            title={"View File"}
                            actions={
                                <IconButton
                                    size={'small'}
                                    onClick={handlePreviewClose}
                                >
                                    <CloseIcon fontSize={'small'}/>
                                </IconButton>
                            }
                            noMargin
                        />
                        <PreviewContent/>

                        {!hideDownload && (
                            <DialogActions>
                                <Button
                                    onClick={doDownload}
                                    children={'Download'}
                                />
                            </DialogActions>
                        )}
                    </Dialog>
                </>
            )}
        </div>
    ) : null;
};

export default TimeSheetDownload;