import React, {useEffect, useState} from "react";

import State from "../../../Global/State";
import Settings from "../../../Global/Settings";
import ColumnFilter from "./FilterDrawer/ColumnFilter";
import ReorderableColumn from "./FilterDrawer/ReorderableColumn";

import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import {Reorder} from "framer-motion"
import FormGroup from "@mui/material/FormGroup";
import InputLabel from "@mui/material/InputLabel";
import {useMediaQuery, useTheme} from "@mui/material";

/**
 * FilterDrawer component.
 *
 * @returns {*}
 * @constructor
 */
const FilterDrawer = (props) => {
    const {
        model,          // {Model} The representative model to calculate filters against.
        onClose,        // {Function} The on-close callback for mobile drawers.
        expanded,       // {Boolean} Indicates if the drawer is toggled.
        doReload,       // {Function} An on-change callback to refresh the parent layout.
    } = props;

    /**
     * A collection of filterable properties based on the model.
     */
    const [filterable] = useState(model.getFilterableProperties());


    /**
     * A collection of selected properties based on the model.
     */
    const [selectable, setSelectable] = useState(model.getSelectableProperties());


    /**
     * Variables for rendering different layouts.
     *
     * @type {Theme}
     */
    const theme = useTheme();
    const isMobile = true; //useMediaQuery(theme.breakpoints.down('sm'));


    /**
     * Synchronize column re-ordering with local storage.
     */
    useEffect(() => {
        State.set(model.getLayoutCacheKey(), JSON.stringify(
            selectable
                .filter(property => isColumnChecked(property))
                .map(property => property.key)
        ));

        if (doReload) {
            doReload();
        }
    }, [selectable]);


    /**
     * Indicates whether a particular column is selected.
     *
     * @param property
     * @returns {boolean}
     */
    const isColumnChecked = (property) => {
        return model.getLayout().includes(property.key);
    };


    /**
     * Updates the layout selection.
     *
     * @param property
     * @param value
     */
    const handleLayoutUpdate = (property, value) => {
        State.set(model.getLayoutCacheKey(), JSON.stringify(
            value ?
                selectable.filter(local => local.key === property.key || isColumnChecked(local))
                    .map(property => property.key) :
                model.getLayout().filter(key => key !== property.key)
        ));

        if (doReload) {
            doReload();
        }
    };


    /**
     * Returns the appropriate filter value from local storage.
     *
     * @param property
     * @returns {*}
     */
    const getFilterValue = property => {
        const filters = State.json(
            model.getFiltersCacheKey()
        );

        if (filters.hasOwnProperty(property.key)) {
            const selection = filters[property.key];

            // Handle any range-type filters.
            if(['date', 'dateTime'].includes(property.filterType)){
                return selection || {};
            }

            return selection && selection.length ? selection : [];
        }

        return [];
    };


    /**
     * Processes the selected filter update.
     *
     * @param property
     * @param value
     * @returns {Promise<void>}
     */
    const handleFilterChange = async (property, value) => {
        const cacheKey = model.getFiltersCacheKey();
        const filters = State.json(cacheKey);

        filters[property.key] = value;
        State.set(cacheKey, JSON.stringify(filters));

        if (doReload) {
            doReload();
        }
    };


    /**
     * The inner content of the drawer.
     *
     * @type {React.JSX.Element}
     */
    const drawerContent = (
        <Box>
            <Box
                className={'p__3 columns__1'}
            >
                <InputLabel>Filter by Column</InputLabel>
                {filterable.map((property, i) => {
                    return (
                        <ColumnFilter
                            key={`FilterDrawer-${i}`}
                            model={model}
                            value={getFilterValue(property)}
                            property={property}
                            onChange={value => handleFilterChange(property, value)}
                        />
                    );
                })}
            </Box>
            <Divider/>
            <Box className={'p__3 columns__1'} sx={{overflow: 'hidden'}}>
                <InputLabel>Table Layout</InputLabel>
                <Reorder.Group
                    as={FormGroup}
                    axis={'y'}
                    values={selectable}
                    onReorder={setSelectable}
                >
                    {selectable.map(property => (
                        <ReorderableColumn
                            key={property.key}
                            property={property}
                            onUpdate={handleLayoutUpdate}
                            isChecked={isColumnChecked}
                        />
                    ))}
                </Reorder.Group>
            </Box>
        </Box>
    );

    if(isMobile){
        return (
            <Drawer
                open={expanded}
                anchor={'right'}
                onClose={onClose}
                children={drawerContent}
                PaperProps={{
                    sx: {
                        width: 300,
                    },
                }}
            />
        );
    }

    return (
        <Box
            sx={{
                width: expanded ? Settings.drawerWidth : 0,
            }}
            open={expanded}
            children={drawerContent}
            className={'filter__drawer full__height'}
        />
    );
};

export default FilterDrawer;