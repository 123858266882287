import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import FileItem from "../../../Components/Layouts/Profile/DocumentList/FileItem";
import {useAuth} from "../../../Global/Auth";
import LogoHeading from "../../../Components/Typography/LogoHeading";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";

/**
 * EmployeeDocuments component.
 *
 * @constructor
 */
const EmployeeDocuments = (props) => {
    const {
        step,                   // {Number} The current step of the form.
        loading,                // {Boolean} Whether the form is currently loading.
        onStepChange            // {Function} Handles the step transition.
    } = props;

    const {user} = useAuth();
    const navigate = useNavigate();
    const [documents, setDocuments] = useState([]);
    const [documentTypes, setDocumentTypes] = useState([]);

    /**
     * Load all required data on component mount.
     */
    useEffect(() => {
        getDocuments();
        getRequiredTypes();
    }, []);


    /**
     * Loads all the documents for the current user.
     *
     * @returns {Promise<void>}
     */
    const getDocuments = async () => {
        setDocuments(
            await API.get('employee-documents', {
                $top: 250,
                $orderby: 'createdDate desc'
            })
        );
    };


    /**
     * Loads all required document types.
     *
     * @returns {Promise<void>}
     */
    const getRequiredTypes = async () => {
        setDocumentTypes(
            await API.get('document-types', {
                $filter: 'isDeleted eq {0} and hasExpiration eq {1}',
                $orderby: 'name asc'
            })
        );
    };

    return (
        <>
            <LogoHeading
                title={'Send Documents'}
                actionSlot={
                    <Button
                        size={'small'}
                        variant={'outlined'}
                        onClick={() => navigate('/')}
                        children={'Back'}
                        className={'text__small'}
                    />
                }
            />

            <Box className={'columns__1'}>
                <Box>
                    Please upload professional-quality scans or photographs for each of the following documents / credentials.
                </Box>

                <Box className={'file__list'}>
                    {documentTypes.map(type => {
                        const existing = documents.filter(document => document.documentTypeId === type.id);

                        return (
                            <FileItem
                                key={type.id}
                                file={type}
                                mobile
                                record={existing.length ? existing[0] : {}}
                                onUpload={getDocuments}
                                onDelete={getDocuments}
                                employeeId={user.id}
                            />
                        )
                    })}
                </Box>
            </Box>
        </>
    );
};

export default EmployeeDocuments;