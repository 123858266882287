import React from "react";

import Employee from "../../../Models/Employee";
import {useAuth} from "../../../Global/Auth";
import Formatter from "../../../Global/Formatter";
import ProfileBadge from "../../../Components/Layouts/Profile/ProfileBadge";
import EmployeeStatusChip from "./EmployeeStatusChip";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";
import Skeleton from "@mui/material/Skeleton";
import CakeIcon from '@mui/icons-material/Cake';

/**
 * EmployeeProfileBadge component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const EmployeeProfileBadge = (props) => {
    const {
        record,             // {Object} The loaded employee record.
        syncUser,           // {Boolean} Indicates whether we should sync user fields to this.
        actionSlot,         // {Component} An optional action area to append controls to.
    } = props;

    const {isScope} = useAuth();

    /**
     * Indicates if the employee record is loading.
     *
     * @type {boolean}
     */
    const isLoading = !record || !record.id;


    /**
     * The heading line (ie. name) of the profile banner.
     *
     * @type {JSX.Element|string}
     */
    const headingContent = isLoading ?
        <Skeleton width={200} height={'1.5em'}/> :
        `${record.firstName} ${record.lastName}`;


    /**
     * The subheading line of the employee profile.
     *
     * @type {string}
     */
    const subheadingContent = isLoading ?
        <Skeleton width={175}/> :
        `${record.specialty && record.specialty.id ? `${record.specialty.name} • ` : ``}Since ${Formatter.date(record.createdDate)}`;


    /**
     * Indicates if today is the employee's birthday.
     *
     * @returns {boolean}
     */
    const isBirthday = () => {
        if (!record.id || !record.birthDate) {
            return false;
        }

        const now = dayjs();
        return now.isSame(record.birthDate, 'month') && now.isSame(record.birthDate, 'day');
    };

    return (
        <ProfileBadge
            model={Employee}
            record={record}
            heading={headingContent}
            syncUser={syncUser}
            decorator={
                record.id && isScope('User') && (
                    <>
                        <Box sx={{marginLeft: '0.5em'}}>
                            <EmployeeStatusChip record={record}/>
                        </Box>
                        {isBirthday() && (
                            <Box sx={{marginLeft: '0.5em'}}>
                                <Chip
                                    icon={<CakeIcon/>}
                                    label={`Today is ${record.firstName}'s birthday!`}
                                    size={'small'}
                                />
                            </Box>
                        )}
                    </>
                )
            }
            imageField={'image'}
            actionSlot={actionSlot}
            subheading={subheadingContent}
        />
    );
};

export default EmployeeProfileBadge;