import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import EmployeeView from "../Employee/EmployeeView";
import DialogHeading from "../../../Components/Typography/DialogHeading";
import AssignedAvailabilityNotes from "../Client/AssignedAvailabilityNotes";

import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import CheckIcon from '@mui/icons-material/Check';
import IconButton from "@mui/material/IconButton";
import {DatePicker} from "@mui/x-date-pickers";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

/**
 * EmployeeMenu component.
 *
 * @param props
 * @returns {*}
 * @constructor
 *
 * @deprecated This menu had been replaced by the EmployeeSlotMenu, so this is only really used for reference at this point.
 */
const EmployeeMenu = (props) => {
    const {
        size,           // {string} The size of the menu button
        record,         // {Object} The employee record / context.
        onChange,       // {Function} Updates an employee record property.
        doReload,       // {Function} Reloads the parent layout, if applicable.
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [pointTotal, setPointTotal] = useState(0);
    const [isViewingRecord, setViewingRecord] = useState(false);
    const [isEditingPoints, setEditingPoints] = useState(false);
    const [isConfirmingPoint, setConfirmingPoint] = useState(false);
    const [updatedPointTotal, setUpdatedPointTotal] = useState(record.pointTotal || 0);
    const [currentPointTotal, setCurrentPointTotal] = useState(record.pointTotal || 0);

    /**
     * Properties exclusive to the availability export.
     */
    const [availabilityContext, setAvailabilityContext] = useState(null);
    const [availabilityEndDate, setAvailabilityEndDate] = useState(dayjs().endOf('month').format('YYYY-MM-DD'));
    const [isViewingAvailability, setViewingAvailability] = useState(false);
    const [availabilityStartDate, setAvailabilityStartDate] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));


    /**
     * Properties related to size / interaction, etc.
     *
     * @type {boolean}
     */
    const isSmall = size === 'small';


    /**
     * Synchronizes the point total from the input employee.
     */
    useEffect(() => {
        setCurrentPointTotal(record.pointTotal || 0);
        setUpdatedPointTotal(record.pointTotal || 0);
    }, [record]);


    /**
     * Synchronizes the availability context against the dates.
     */
    useEffect(() => {
        if (!availabilityContext || !availabilityStartDate || !availabilityEndDate) {
            return;
        }

        // Update the context entry on date updates.
        setAvailabilityContext({
            ...availabilityContext,
            scheduleToDate: availabilityEndDate,
            scheduleFromDate: availabilityStartDate,
        });
    }, [availabilityStartDate, availabilityEndDate]);

    /**
     * Indicates whether we have an anchor element.
     *
     * @type {boolean}
     */
    const open = Boolean(anchorEl);


    /**
     * Fired whenever the menu button is clicked.
     *
     * @param event
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Closes the menu popover.
     */
    const handleClose = () => {
        setAnchorEl(null);
    };


    /**
     * Reveals the point confirmation dialog.
     *
     * @param points
     */
    const handlePointConfirmationOpen = (points) => {
        setPointTotal(points);
        setConfirmingPoint(true);
    };


    /**
     * Closes the point confirmation dialog.
     */
    const handlePointConfirmationClose = () => {
        setConfirmingPoint(false);
    };


    /**
     * Reveals the point confirmation dialog.
     */
    const handlePointsEditOpen = () => {
        setEditingPoints(true);
    };


    /**
     * Closes the point confirmation dialog.
     */
    const handlePointsEditClose = () => {
        setEditingPoints(false);
    };


    /**
     * Saves the input tally.
     *
     * @returns {Promise<void>}
     */
    const doTallySave = async () => {
        await API.post('tallies', {
            amount: pointTotal,
            employeeId: record.id
        });

        // Refresh the employee record within the layout.
        const employee = await API.get(`employees/${record.id}`, {
            $select: '*,pointTotal',
            $expand: 'specialty,status'
        });

        setCurrentPointTotal(employee.pointTotal);
        setUpdatedPointTotal(employee.pointTotal);
        handlePointConfirmationClose();

        if (doReload) {
            doReload(employee);
        }
    };


    /**
     * Saves the input tally.
     *
     * @returns {Promise<void>}
     */
    const doTallyTotalSave = async () => {
        await API.post('tallies', {
            amount: (updatedPointTotal || 0) - currentPointTotal,
            employeeId: record.id
        });

        // Refresh the employee record within the layout.
        const employee = await API.get(`employees/${record.id}`, {
            $select: '*,pointTotal',
            $expand: 'specialty,status'
        });

        setCurrentPointTotal(employee.pointTotal);
        setUpdatedPointTotal(employee.pointTotal);
        handlePointsEditClose();

        if (doReload) {
            doReload(employee);
        }
    };


    /**
     * Synchronizes the numeric value of the input.
     *
     * @param event
     */
    const handlePointTotalUpdate = (event) => {
        const value = event.target.value;

        if (!value) {
            return setUpdatedPointTotal(null);
        }

        setUpdatedPointTotal(parseInt(value));
    };


    /**
     * Handles toggling the availability exporter.
     */
    const handleAvailabilityOpen = () => setViewingAvailability(true);
    const handleAvailabilityClose = () => {
        setAvailabilityContext(null);
        setViewingAvailability(false)
    };


    /**
     * Retrieves the availability results.
     */
    const doGetAvailabilityResults = () => {
        if (!availabilityStartDate || !availabilityEndDate) {
            return;
        }

        setAvailabilityContext({
            scheduleToDate: availabilityEndDate,
            scheduleFromDate: availabilityStartDate,
        });
    };


    /**
     * Reveals the record dialog.
     */
    const handleViewOpen = () => {
        setViewingRecord(true);
    };


    /**
     * Closes the record dialog.
     */
    const handleViewClose = () => {
        setViewingRecord(false);
    };

    return (
        <Box key={`${record.id}-schedule-employee-menu`}>
            <IconButton onClick={handleClick} size={size}>
                <MoreHorizIcon/>
            </IconButton>
            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <MenuItem sx={{minWidth: 180, marginBottom: '0.5em'}} onClick={handleViewOpen}>
                    View Profile
                </MenuItem>

                <Divider/>

                <MenuItem disabled>
                    Attendance
                </MenuItem>
                <MenuItem
                    onClick={handlePointsEditOpen}
                    children={`Edit Tally (${currentPointTotal})`}
                />
                <MenuItem
                    onClick={() => handlePointConfirmationOpen(1)}
                    children={'+1 Point'}
                />
                <MenuItem
                    onClick={() => handlePointConfirmationOpen(2)}
                    children={'+2 Points'}
                />
                <MenuItem
                    onClick={() => handlePointConfirmationOpen(4)}
                    children={'+4 Points'}
                />

                <Divider/>

                <MenuItem disabled>
                    Export
                </MenuItem>

                <MenuItem onClick={handleAvailabilityOpen}>
                    Availability
                </MenuItem>
            </Menu>

            <Dialog
                open={isConfirmingPoint}
                scroll={'body'}
                onClose={handlePointConfirmationClose}
                maxWidth={'xs'}
                fullWidth
            >
                <DialogHeading
                    title={"Are you sure?"}
                    noMargin
                />
                <DialogContent>
                    <Box>
                        You are about to assign {pointTotal} point{pointTotal < 2 ? '' : 's'}. Would
                        you like to continue?
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={doTallySave}
                        children={'Yes'}
                    />
                    <Button
                        color={'error'}
                        onClick={handlePointConfirmationClose}
                        children={'No'}
                    />
                </DialogActions>
            </Dialog>

            <Dialog
                open={isViewingAvailability}
                scroll={'body'}
                onClose={handleAvailabilityClose}
                maxWidth={'sm'}
                fullWidth
            >
                <DialogHeading
                    title={"Export Availability"}
                    noMargin
                />
                <DialogContent>
                    <Box className={'columns__1'}>
                        <Box>
                            Please select a date range to download availability for:
                        </Box>
                        <Box className={'columns__2'}>
                            <DatePicker
                                label={'Start Date'}
                                value={availabilityStartDate ? dayjs(availabilityStartDate) : null}
                                disabled={isLoading}
                                onChange={event => setAvailabilityStartDate(event ? event.format('YYYY-MM-DD') : '')}
                            />

                            <DatePicker
                                label={'End Date'}
                                value={availabilityEndDate ? dayjs(availabilityEndDate) : null}
                                disabled={isLoading}
                                onChange={event => setAvailabilityEndDate(event ? event.format('YYYY-MM-DD') : '')}
                            />
                        </Box>
                        {!!availabilityContext && (
                            <AssignedAvailabilityNotes
                                nested
                                record={availabilityContext}
                                defaultEmployees={[record]}
                            />
                        )}
                    </Box>
                </DialogContent>

                {!availabilityContext && (
                    <DialogActions>
                        <Button
                            onClick={doGetAvailabilityResults}
                            disabled={!availabilityStartDate || !availabilityEndDate}
                            children={'Download'}
                        />
                        <Button
                            color={'error'}
                            onClick={handleAvailabilityClose}
                            children={'Cancel'}
                        />
                    </DialogActions>
                )}
            </Dialog>

            <Dialog
                open={isEditingPoints}
                scroll={'body'}
                onClose={handlePointsEditClose}
                maxWidth={'xs'}
                fullWidth
            >
                <DialogHeading
                    title={"Edit Tally Points"}
                    noMargin
                />
                <DialogContent>
                    <Box className={'columns__1'}>
                        <Box>
                            Please enter an updated point total for the current period:
                        </Box>
                        <Box>
                            <TextField
                                type={'number'}
                                value={updatedPointTotal}
                                label={'Point Total'}
                                fullWidth
                                onChange={handlePointTotalUpdate}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={doTallyTotalSave}
                        children={'Save'}
                    />
                    <Button
                        color={'error'}
                        onClick={handlePointsEditClose}
                        children={'Cancel'}
                    />
                </DialogActions>
            </Dialog>

            {isViewingRecord && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    maxWidth={'xl'}
                    onClose={handleViewClose}
                    fullWidth
                >
                    <DialogContent sx={{paddingTop: 0}}>
                        <Box className={'columns__1'}>
                            <EmployeeView
                                id={record.id}
                                onClose={() => {
                                    handleClose();
                                    handleViewClose();
                                }}
                            />
                        </Box>
                    </DialogContent>
                </Dialog>
            )}
        </Box>
    );
};

export default EmployeeMenu;