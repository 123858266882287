/**
 * Touch component.
 *
 * @type {Object}
 * @description Used to provide more granular control over touch interactions.
 */
const Touch = {
    /**
     * Indicates whether we are running on a mobile device.
     *
     * @returns {boolean}
     */
    isMobile(){
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Detect android devices.
        if (/android/i.test(userAgent)) {

            // This technically should be true, but we're going to return false since
            // we haven't actually run into this weird "Double Click" issue on Android.
            //
            return false;
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return true;
        }

        return false;
    },


    /**
     * Will return the callback only if click events are supported.
     *
     * @param callback
     * @returns {*}
     */
    clickable(callback){
        if(!callback){
            return;
        }

        if(!Touch.isMobile()){
            return callback;
        }
    },


    /**
     * Will return the callback only if touch events are supported.
     *
     * @param callback
     * @returns {*}
     */
    tappable(callback){
        if(!callback){
            return;
        }

        if(Touch.isMobile()){
            return callback;
        }
    }
};

export default Touch;