import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import Step from "../../../Components/Step";
import {useAuth} from "../../../Global/Auth";
import ModalButton from "../../../Components/ModalButton";
import LogoHeading from "../../../Components/Typography/LogoHeading";
import StepControls from "../../../Components/StepControls";
import NonCompeteStep from "../HirePacket/NonCompeteStep";
import InputSignature from "../../../Components/Input/InputSignature";
import CovidDisclosureStep from "../HirePacket/CovidDisclosureStep";
import TimeSheetPolicyStep from "../HirePacket/TimeSheetPolicyStep";
import EmployeeHandbookStep from "../HirePacket/EmployeeHandbookStep";
import ConductAgreementStep from "../HirePacket/ConductAgreementStep";
import AttendancePolicyStep from "../HirePacket/AttendancePolicyStep";
import SchedulingProcedureStep from "../HirePacket/SchedulingProcedureStep";
import WorkersCompensationStep from "../HirePacket/WorkersCompensationStep";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import TableContainer from "@mui/material/TableContainer";
import DescriptionIcon from '@mui/icons-material/Description';

/**
 * OfferLetterStep component.
 *
 * @constructor
 */
const OfferLetterStep = (props) => {
    const {
        step,                   // {Number} The current step index on the parent component.
        index,                  // {Number} The expected step index on the parent component.
        heading,                // {String} Optional replacement text for the heading.
        employee,               // {Object} The employee record to pull context from.
        readOnly,               // {Boolean} An optional read-only attribute to disable inputs.
        onStepChange,           // {Function} An on change callback to move along to the next step.
    } = props;

    const [record, setRecord] = useState({});
    const [isLoaded, setLoaded] = useState(false);
    const [specialty, setSpecialty] = useState({});
    const [documents, setDocuments] = useState(false);
    const [offerLetterSignature, setOfferLetterSignature] = useState(null);

    /**
     * Various authentication methods.
     */
    const {
        isScope,
        onAuthUpdate,
        setUserSetting,
        getUserSettingValue,
        getUserSettingValueForEmployee
    } = useAuth();


    /**
     * Load all required data on initial mount.
     */
    useEffect(() => {
        getSpecialty();
        handleSettingsLoad();
    }, []);


    /**
     * Synchronizes the backend setting values.
     */
    useEffect(() => {
        if (!readOnly && isLoaded) {
            setUserSetting('OFFER_LETTER_SIGNATURE', offerLetterSignature)
        }
    }, [offerLetterSignature]);


    /**
     * Bind a scroll handler against the current step parameter.
     */
    useEffect(() => {
        handleScrollToTop();

        if (step === 1) {
            handleEmployeeUpdate();
        }
    }, [step]);


    /**
     * Retrieves the employee specialty via the API.
     *
     * @returns {Promise<void>}
     */
    const getSpecialty = async () => {
        setSpecialty(
            await API.get(`specialties/${employee.specialtyId}`)
        );
    };


    /**
     * Each of the input disclosure agreements.
     *
     * @type {Array<Object>}
     */
    const allDisclosures = [
        {
            label: 'Offer Letter',
            content: (onClose) => (
                <Box>
                    <LogoHeading
                        title={'Offer Letter'}
                        onClose={onClose}
                    />
                    <Box dangerouslySetInnerHTML={{__html: record.offerLetter || 'No offer letter content available.'}}/>
                </Box>
            )
        }, {
            label: 'Job Description',
            content: (onClose) => (
                <Box>
                    <LogoHeading
                        title={'Job Description'}
                        onClose={onClose}
                    />
                    <Box dangerouslySetInnerHTML={{__html: specialty.jobDescription || 'No job description available.'}}/>
                </Box>
            )
        }, {
            label: 'Covid Disclosure',
            content: (onClose) => (
                <CovidDisclosureStep
                    onClose={onClose}
                    contentOnly
                />
            )
        }, {
            label: 'Non-complete Disclosure',
            content: (onClose) => (
                <NonCompeteStep
                    onClose={onClose}
                    contentOnly
                />
            )
        }, {
            label: 'Scheduling Policy',
            content: (onClose) => (
                <SchedulingProcedureStep
                    onClose={onClose}
                    contentOnly
                />
            )
        }, {
            label: 'Attendance Policy',
            content: (onClose) => (
                <AttendancePolicyStep
                    onClose={onClose}
                    contentOnly
                />
            )
        }, {
            label: 'Time Sheet Policy',
            content: (onClose) => (
                <TimeSheetPolicyStep
                    onClose={onClose}
                    contentOnly
                />
            )
        }, {
            label: 'Workers\' Compensation',
            content: (onClose) => (
                <WorkersCompensationStep
                    onClose={onClose}
                    contentOnly
                />
            )
        }, {
            label: 'Professional Conduct Agreement',
            content: (onClose) => (
                <ConductAgreementStep
                    onClose={onClose}
                    contentOnly
                />
            )
        }, {
            label: 'Employee Handbook',
            content: (onClose) => (
                <EmployeeHandbookStep
                    onClose={onClose}
                    contentOnly
                />
            )
        },
    ];


    /**
     * Indicates if we've seen all the disclosures.
     *
     * @type {boolean}
     */
    const allSeen = !!record.offerLetterDate ||
        !allDisclosures.filter(document => !documents.hasOwnProperty(document.label)).length;


    /**
     * Updates the employee record on completion.
     *
     * @returns {Promise<void>}
     */
    const handleEmployeeUpdate = async () => {
        if (!!employee.offerLetterDate) {
            return;
        }

        const response = await API.put(`employees/${employee.id}`, {
            offerLetterDate: dayjs().utc().format('YYYY-MM-DD HH:mm:ss')
        });

        onAuthUpdate({
            offerLetterDate: response.offerLetterDate
        });
    };


    /**
     * Loads each of the appropriate user settings.
     *
     * @returns {Promise<void>}
     */
    const handleSettingsLoad = async () => {
        setRecord(
            await API.get(`employees/${employee.id}`)
        );

        setOfferLetterSignature(
            readOnly && !isScope('Employee') ?
                await getUserSettingValueForEmployee(employee.id, 'OFFER_LETTER_SIGNATURE') :
                await getUserSettingValue('OFFER_LETTER_SIGNATURE')
        );

        setLoaded(true);
    };


    /**
     * Scrolls the user to the top of the page.
     */
    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };


    /**
     * Updates the checked status of each disclosure.
     *
     * @param document
     */
    const handleOpen = (document) => {
        setDocuments({
            ...documents,
            [document.label]: true
        });
    };

    return (
        <Step step={index} value={step}>
            <LogoHeading title={heading || 'Disclosures'}/>

            <Box className={'columns__1'}>
                <Box>
                    Please acknowledge each of the disclosures below by clicking on each document, and reviewing the
                    content within each. After you have reviewed each document, please sign below.
                </Box>

                <TableContainer className={'table table--striped'}>
                    <Table>
                        <colgroup>
                            <col width={'100%'}/>
                            <col/>
                        </colgroup>
                        <TableBody>
                            {allDisclosures.map((document, i) => {
                                const isChecked = !!employee.offerLetterDate || !!record.offerLetterDate || documents.hasOwnProperty(document.label);

                                return (
                                    <TableRow key={`document-${i}`}>
                                        <TableCell disableGutters>
                                            <ModalButton
                                                size={'xs'}
                                                label={(
                                                    <Box className={'d-flex__start'} data-aos={'fade-right'} data-aos-delay={i * 50}>
                                                        <DescriptionIcon className={'mr__1'}/> {document.label}
                                                    </Box>
                                                )}
                                                onOpen={() => handleOpen(document)}
                                                doRender={document.content}
                                                component={'a'}
                                                bodyStyle={{paddingTop: 0}}
                                                closeButton
                                            />
                                        </TableCell>
                                        <TableCell disableGutters
                                            align={'right'}>
                                            {isChecked ? (
                                                <TaskAltIcon color={'success'}/>
                                            ) : (
                                                <Chip
                                                    size={'small'}
                                                    color={'warning'}
                                                    label={'Not Seen'}
                                                />
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                {!allSeen ? (
                    <Alert severity={'warning'}>
                        Please first review each of the documents above before signing.
                    </Alert>
                ) : (
                    <>
                        <Box>
                            By signing this page, you are acknowledging that you have read and agree to
                            each of the documents above.
                        </Box>

                        <InputSignature
                            key={0}
                            value={offerLetterSignature}
                            label={'Please sign here:'}
                            disabled={readOnly || !!record.offerLetterDate}
                            onChange={signature => setOfferLetterSignature(signature)}
                            scaleFactor={2}
                        />
                    </>
                )}

                {!readOnly && (
                    <StepControls
                        step={step}
                        disabled={!offerLetterSignature || !allSeen}
                        onStepChange={onStepChange}
                    />
                )}
            </Box>
        </Step>
    );
};

export default OfferLetterStep;