import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import App from "../../../Global/App";
import ProfileForm from "./ProfileForm";
import EmployeeModel from "../../../Models/Employee";
import ProfileDetails from "./ProfileDetails";
import SectionHeading from "../../../Components/Typography/SectionHeading";
import EmployeeProfileBadge from "../../Admin/Employee/EmployeeProfileBadge";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CreateIcon from '@mui/icons-material/Create';
import {useNavigate} from "react-router-dom";

/**
 * ProfileView component.
 *
 * @returns {*}
 * @constructor
 */
const ProfileView = (props) => {
    const {
        id      // {Number} The ID of the employee to load.
    } = props;

    const navigate = useNavigate();
    const [record, setRecord] = useState(EmployeeModel.getInstance());
    const [isLoading, setLoading] = useState(true);
    const [isEditing, setEditing] = useState(false);
    const [isSuccess, setSuccess] = useState(false);

    /**
     * Load any additional details on component mount.
     */
    useEffect(() => {
        getEmployee();
    }, []);


    /**
     * Loads the target record.
     *
     * @returns {Promise<void>}
     */
    const getEmployee = async () => {
        setLoading(true);
        setRecord(
            await API.get(`${EmployeeModel.getRoute()}/${id}`, EmployeeModel.getQuery())
        );
        setLoading(false);
    };


    /**
     * Reveals the employee form.
     */
    const handleEdit = () => {
        setEditing(true);
    };


    /**
     * Saves the employee updates.
     */
    const handleSave = async () => {
        setLoading(true);

        const payload = {
            ...record
        };

        // Remove relations that might interfere with the backend.
        if (payload.hasOwnProperty('status')) {
            delete payload['status'];
        }

        if (payload.hasOwnProperty('specialty')) {
            delete payload['specialty'];
        }

        await API.put(`employee/${record.id}`, payload);
        await getEmployee();

        setLoading(false);
        setEditing(false);
    };


    /**
     * Refreshes the form and employee after saving.
     *
     * @returns {Promise<void>}
     */
    const handlePostSave = async () => {
        await getEmployee();
        setEditing(false);
        setSuccess(true);
    };


    /**
     * Closes the success message.
     */
    const handleSuccessClose = () => {
        setSuccess(false);
    };

    return (
        <Box className={'details__view'}>
            <Box className={'d-flex__justify pl__3 pr__3'}>
                <EmployeeProfileBadge
                    record={record}
                    syncUser
                    actionSlot={
                        App.isCordova() ? <Button
                            size={'small'}
                            variant={'outlined'}
                            onClick={() => navigate('/')}
                            children={'Back'}
                            className={'text__small'}
                        /> : null
                    }
                />
            </Box>
            <SectionHeading
                title={'My Information'}
                action={
                    <Box>
                        {!isEditing && (
                            <IconButton
                                size={'small'}
                                onClick={handleEdit}
                                disabled={isLoading}
                                children={<CreateIcon/>}
                            />
                        )}
                    </Box>
                }
            />

            {isEditing ? (
                <Box className={'p__3'}>
                    <ProfileForm
                        employee={record}
                        onSave={handlePostSave}
                    />
                </Box>
            ) : (
                <ProfileDetails record={record}/>
            )}

            {isSuccess && (
                <Snackbar
                    open={isSuccess}
                    autoHideDuration={6000}
                    onClose={handleSuccessClose}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                >
                    <Alert onClose={handleSuccessClose} severity="success" sx={{width: '100%'}}>
                        Your profile has been updated!
                    </Alert>
                </Snackbar>
            )}
        </Box>
    )
};

export default ProfileView;