import React from "react";

import Model from "../Definitions/Model";
import Property from "../Definitions/Property";
import PropertyMap from "../Definitions/PropertyMap";

/**
 * State model.
 *
 * @type {Object}
 */
const State = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'state',


    /**
     * The appropriate API endpoint root.
     */
    route: 'states',


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'name',
        'abbreviation'
    ],


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'name',
            label: 'Name',
            render: (record) => record.name,
            sortable: true
        }),
        Property.create({
            key: 'abbreviation',
            label: 'Abbreviation',
            render: (record) => record.abbreviation,
            sortable: true
        })
    ])
};

export default State;