import React from "react";

import App from "../../Global/App";
import LogoHeading from "../Typography/LogoHeading";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

/**
 * MobileLanding component.
 *
 * @returns {*}
 * @constructor
 */
const MobileLanding = () => {
    /**
     * Returns the appropriate download link based on the device.
     *
     * @returns {string}
     */
    const getDownloadLink = () => {
        return App.getDownloadLink();
    }


    /**
     * Opens the download link in a new tab.
     */
    const doDownload = () => {
        window.open(getDownloadLink(), '_blank');
    };

    return (
        <Box className={'error__block mobile__notification'}>
            <LogoHeading
                title={'We have exciting news!'}
                className={'mt__0'}
            />
            <Box className={'columns__1'}>
                <Box>
                    <b>It's finally here!</b> We've been hard at work creating an entirely new experience for you,
                    to help you deliver your best work possible.
                </Box>
                <Box>
                    <Box>
                        Starting today, you can access your profile by first downloading our mobile app, and
                        signing in using your existing credentials. Our new app offers a much more intuitive experience,
                        and allows you to easily:
                    </Box>
                    <ul className={'mb__0'}>
                        <li>Share documents..</li>
                        <li>Manage your schedule.</li>
                        <li>Communicate with the team.</li>
                        <li>Keep track of credentials.</li>
                        <li>
                            <i className={'text__light'}>(and more...)</i>
                        </li>
                    </ul>
                </Box>
                <Button
                    sx={{
                        padding: '1em',
                        marginTop: '1em',
                        marginBottom: '1em',
                        textTransform: 'initial'
                    }}
                    variant={'contained'}
                    onClick={doDownload}
                    children={'Download the app today!'}
                />
                <Box className={'text__center text__small mr__2'}>
                    <span className={'text__light'}>
                        With care, your team members at <b>Reliant Staffing</b>
                    </span>
                    <span
                        style={{fontSize: '1.2em'}}
                        children={<>📣</>}
                    />
                </Box>
            </Box>
        </Box>
    );
};

export default MobileLanding;