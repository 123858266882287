import React from "react";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";

/**
 * Panel component.
 *
 * @returns {*}
 * @constructor
 */
const Panel = (props) => {
    const {
        width,              // {String{ A width CSS modifier for the panel.
        header,             // {Component} An optional override for the heading content.
        padding,            // {String} A padding CSS modifier.
        heading,            // {String} A title label for the panel.
        children,           // {Component} Any children elements, essentially the body of the panel.
        className,          // {String} An optional class to apply to the outer container.
        hideDivider,        // {Boolean} Indicates whether or not to hide the heading divider.
    } = props;

    return (
        <Paper className={`panel ${className || ''}`} sx={{width}}>
            <Box className={'panel__header'}>
                {heading && <h3>{heading}</h3>}

                {header}
            </Box>
            {!hideDivider && <Divider/>}
            <Box className={'panel__body'} sx={{padding}}>
                {children}
            </Box>
        </Paper>
    );
};

export default Panel;