import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import TabPanel from "../../../Components/TabPanel";
import Clipboard from "../../../Global/Clipboard";
import TextEditor from "../../../Components/Input/TextEditor";
import TabHeading from "../../../Components/Layouts/Profile/TabHeading";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import DownloadIcon from "@mui/icons-material/Download";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

/**
 * AssignedAvailabilityNotes component.
 *
 * @returns {*}
 * @constructor
 */
const AssignedAvailabilityNotes = (props) => {
    const {
        nested,                 // {Boolean} An optional condensed flag.
        record,                 // {Object} The client record.
        defaultEmployees,       // {Array} The default employees to display.
    } = props;

    const [tab, setTab] = useState(0);
    const [shifts, setShifts] = useState([]);
    const [markup, setMarkup] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [copySuccess, setCopySuccess] = useState(false);
    const [availability, setAvailability] = useState([]);

    /**
     * Initialize the component on mount.
     */
    useEffect(() => {
        getEmployees();
    }, [record]);


    /**
     * Pulls the availability for the selected employee.
     */
    useEffect(() => {
        getData();
    }, [employees, tab]);


    /**
     * Synchronizes the text editor markup vs the employee's availability.
     */
    useEffect(() => {
        getMarkup();
    }, [availability, shifts])


    /**
     * Indicates whether the client has been set-up correctly.
     *
     * @type {boolean}
     */
    const clientInvalid = !record || !record.scheduleFromDate || !record.scheduleToDate;


    /**
     * Indicates whether we have a selected employee.
     *
     * @type {boolean}
     */
    const employeesInvalid = !employees || !employees.length || employees.length <= tab;


    /**
     * The selected employee record.
     *
     * @type {{}}
     */
    const selectedEmployee = !employeesInvalid ? employees[tab] : {};


    /**
     * Pulls the shifts for the week via the API.
     *
     * @returns {Promise<void>}
     */
    const getData = async () => {
        if (clientInvalid || employeesInvalid) {
            return;
        }

        let availability = await API.get('availability', {
            $top: 1000,
            $filter: `employeeId in {${selectedEmployee.id}} and startDate ge {${record.scheduleFromDate}} and startDate lt {${record.scheduleToDate}}`,
            $orderby: 'startDate asc'
        });

        let shifts = await API.get('events', {
            $top: 9999,
            $filter: `isBlockRequested eq {1} and employeeId in {${selectedEmployee.id}} and startDate ge {${record.scheduleFromDate}} and endDate le {${record.scheduleToDate}}`,
            $orderby: 'startDate asc'
        });

        /**
         * Handle legacy afas (may come attached to detached availability records). The old system
         * utilized availability that wasn't directly attached to anything. The employee selections were
         * handled in an arbitrary JSON format with each day indicating the employee's selection.
         *
         * @type {Object}
         */
        const legacyGroupings = {};

        shifts
            .filter(result => !!result.legacyData)
            .map(result => {
                return {
                    ...result,
                    availability: JSON.parse(result.legacyData || '{}')
                };
            })
            .map(result => {
                return {
                    ...result,
                    availability: {
                        ...result.availability,
                        configuration: JSON.parse(result.availability.configuration || '{}')
                    }
                };
            })
            .map(result => {
                const {
                    availability
                } = result;

                const converted = {
                    hours: availability.number_of_hours,
                    comments: availability.comments,
                    startDate: availability.start_datetime,
                    legacySelection: {
                        ...availability.configuration
                    }
                };

                if (!legacyGroupings.hasOwnProperty(converted.startDate)) {
                    legacyGroupings[converted.startDate] = converted;
                }
            });

        // Push the legacy data into the initial availability results array.
        if (legacyGroupings && Object.values(legacyGroupings).length) {
            availability = [
                ...availability,
                ...Object.values(legacyGroupings)
            ];
        }

        // Update the state storage.
        setShifts(
            shifts.filter(shift => [
                'Morning (8)', 'Afternoon (8)', 'Night (8)', 'Double (16)'
            ].includes(shift.title))
        );

        setAvailability(
            availability.sort((a, b) => dayjs(a.startDate).isAfter(dayjs(b.startDate)) ? 1 : -1)
        );
    };


    /**
     * Returns the assigned employees to the client.
     *
     * @returns {Promise<void>}
     */
    const getEmployees = async () => {
        if (defaultEmployees && defaultEmployees.length) {
            setEmployees(defaultEmployees);
        }

        if (!record || !record.id) {
            return;
        }

        setEmployees(
            await API.get('employees', {
                $top: 1000,
                $filter: `employeeClients/any{clientId in {${record.id}}}`
            })
        );
    };


    /**
     * Build out the markup based on selected shifts / availability.
     */
    const getMarkup = () => {
        setMarkup(
            availability.map(week => {
                const startDate = dayjs(week.startDate);

                const startDateOfWeek = startDate
                    .startOf('weeks');

                const endDateOfWeek = startDate
                    .startOf('weeks')
                    .add(1, 'weeks')
                    .subtract(1, 'days');

                const weeklyShifts = shifts.filter(event => {
                    const startDateJs = dayjs(event.startDate);

                    return (startDateJs.isSame(startDateOfWeek) || startDateJs.isAfter(startDateOfWeek)) &&
                        (startDateJs.isSame(endDateOfWeek) || startDateJs.isBefore(endDateOfWeek))
                });

                const weeklyShiftDates = {};

                // Handle legacy selections.
                if (week.hasOwnProperty('legacySelection')) {
                    const legacySelection = week.legacySelection.days || [];

                    legacySelection.map(day => {
                        const values = day.values || [];
                        const startDateJs = dayjs(day.date);
                        const dateFormatted = startDateJs.format('MM/DD/YYYY');

                        values
                            .filter(value => !!value.value)
                            .map(value => {
                                    const event = {
                                        title: value.name
                                    };

                                    // Ignore "Not Available" since it's redundant.
                                    if (event.title === 'Not Available') {
                                        return;
                                    }

                                    if (weeklyShiftDates.hasOwnProperty(dateFormatted)) {
                                        if(weeklyShiftDates[dateFormatted].some(shift => shift.title === event.title)){
                                            return;
                                        }

                                        return weeklyShiftDates[dateFormatted].push(event);
                                    }

                                    weeklyShiftDates[dateFormatted] = [event];
                                }
                            );
                    });
                }

                weeklyShifts.map(event => {
                    const startDateJs = dayjs(event.startDate);
                    const dateFormatted = startDateJs.format('MM/DD/YYYY');

                    if (weeklyShiftDates.hasOwnProperty(dateFormatted)) {
                        if(weeklyShiftDates[dateFormatted].some(shift => shift.title === event.title)){
                            return;
                        }

                        return weeklyShiftDates[dateFormatted].push(event);
                    }

                    weeklyShiftDates[dateFormatted] = [event];
                });

                return [
                    `<div><b style="background-color: yellow;"><u>Week of ${startDateOfWeek.format('MM/DD/YYYY')} - ${endDateOfWeek.format('MM/DD/YYYY')}</u></b></div>`,
                    `<div><b style="background-color: yellow;"><i><u>Only schedule the employee the following number of hours (for this week only): ${week.hours || 0}</u></i></b></div>`,
                    week.comments ? `<div><u>${week.comments}</u></div>` : ``,

                    !!Object.keys(weeklyShiftDates).length ?
                        `<ul>${Object.keys(weeklyShiftDates).map(date => {
                            const shifts = weeklyShiftDates[date];
                            
                            const shiftOrder = {
                                'Morning (8)': 0,
                                'Afternoon (8)': 1,
                                'Night (8)': 2,
                                'Double (16)': 3
                            };

                            return `<li>${date} - ${shifts.sort((a, b) => (shiftOrder[a.title] > shiftOrder[b.title]) ? 1 : -1).map(shift => shift.title).join(', ')}</li>`;
                        }).join('')}</ul>` :
                        '<ul><li>(Not available.)</li></ul>'
                ].join('');
            }).join('<br/>')
        );
    };


    /**
     * Handles the user tab selection.
     *
     * @param event
     * @param value
     */
    const handleTabChange = (event, value) => {
        setTab(value);
    };


    /**
     * Closes the success message.
     */
    const handleCopySuccessClose = () => setCopySuccess(false);


    /**
     * Opens the success message.
     */
    const handleCopySuccessOpen = () => setCopySuccess(true);


    /**
     * Copies the markup content to the clipboard.
     */
    const doCopyMarkup = () => {
        Clipboard.doCopy(markup, handleCopySuccessOpen, {html: true});
    };


    /**
     * Downloads the availability as a PDF document.
     *
     * @returns {Promise<void>}
     */
    const getAvailabilityAsPdf = async () => {
        setLoading(true);

        // Create the PDF document.
        const response = await API.post('pdf', {
            html: markup,
            heading: `Availability Results`
        });

        // Download the document.
        await new Promise(resolve => {
            fetch(API.getFilePath(response.path), {
                method: 'GET'
            }).then(resp => resp.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = `availability.pdf`;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    resolve();
                })
        });

        setLoading(false);
    };

    return !employees.length ? (
        <Box className={'p__3 text__center text__disclaimer'}>
            No results available.
        </Box>
    ) : (
        <Box>
            {!nested && (
                <TabHeading
                    tab={tab}
                    scrollable
                    onChange={handleTabChange}
                >
                    {employees.map(employee => (
                        <Tab label={employee.displayName}/>
                    ))}
                </TabHeading>
            )}
            <TabPanel value={tab} index={tab} fullWidth={nested}>
                <Box className={'columns__1'}>
                    {(!record.scheduleFromDate || !record.scheduleToDate) && (
                        <Alert severity={'warning'}>
                            This facility is missing a scheduling period, please add one first.
                        </Alert>
                    )}

                    <TextEditor
                        value={markup}
                        height={350}
                        onChange={value => setMarkup(value)}
                    />

                    <Box align={'right'}>
                        <Button
                            onClick={getAvailabilityAsPdf}
                            disabled={isLoading}
                            children={'Save as PDF'}
                            startIcon={<DownloadIcon/>}
                        />
                        <Button
                            onClick={doCopyMarkup}
                            disabled={isLoading}
                            children={'Copy to Clipboard'}
                            startIcon={<ContentCopyIcon/>}
                        />
                    </Box>
                </Box>
            </TabPanel>

            {copySuccess && (
                <Snackbar open={true} autoHideDuration={6000} onClose={handleCopySuccessClose}>
                    <Alert onClose={handleCopySuccessClose} severity={'success'}>
                        Copied successfully!
                    </Alert>
                </Snackbar>
            )}
        </Box>
    );
};

export default AssignedAvailabilityNotes;