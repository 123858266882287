import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import Step from "../../Components/Step";
import Page from "../../Components/Page";
import {useAuth} from "../../Global/Auth";
import LogoHeading from "../../Components/Typography/LogoHeading";
import ProfileForm from "./Profile/ProfileForm";
import ReferenceStep from "./Invitation/ReferenceStep";
import CredentialStep from "./Invitation/CredentialStep";
import SectionHeading from "../../Components/Typography/SectionHeading";
import JobInterviewStep from "./Invitation/JobInterviewStep";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router";
import LinearProgress from "@mui/material/LinearProgress";

/**
 * Invitation component.
 *
 * @returns {*}
 * @constructor
 */
const Invitation = () => {
    const navigate = useNavigate();
    const {user, refresh} = useAuth();
    const [step, setStep] = useState(0);
    const [employee, setEmployee] = useState({});
    const [isLoading, setLoading] = useState(false);

    /**
     * Load all required data on initial mount.
     */
    useEffect(() => {
        getEmployee();
    }, []);


    /**
     * Bind a scroll handler against the current step parameter.
     */
    useEffect(() => {
        handleScrollToTop();

        if (step === totalSteps) {
            handleEmployeeUpdate();
        }
    }, [step]);


    /**
     * The total number of steps, calculates the top progress bar.
     *
     * @type {number}
     */
    const totalSteps = 4;


    /**
     * Loads the current employee record.
     *
     * @returns {Promise<void>}
     */
    const getEmployee = async () => {
        setEmployee(
            await API.get(`employees/${user.id}`)
        );
    };


    /**
     * Updates the employee record on completion.
     *
     * @returns {Promise<void>}
     */
    const handleEmployeeUpdate = async () => {
        setLoading(true);

        if (!employee.inviteCompletedDate) {
            await API.put(`employees/${employee.id}`, {
                inviteCompletedDate: dayjs().utc().format('YYYY-MM-DD HH:mm:ss')
            });
        }

        navigate('/');
    };


    /**
     * Scrolls the user to the top of the page.
     */
    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };


    const handleProfileFormSave = async(response) => {
        window.dispatchEvent(
            new CustomEvent('userupdated', {
                detail: {
                    ...user,
                    image: response.image
                }
            })
        );

        await getEmployee();
        setStep(step + 1);
    };

    return (
        <Page hideHeader fullScreen>
            <LinearProgress
                value={100 * (step / totalSteps)}
                variant={"determinate"}
                className={'mobile__progress'}
            />

            <Box className={'p__3 pt__0 columns__1'}>
                <Step step={0} value={step}>
                    <LogoHeading title={'My Profile'}/>

                    <Box className={'columns__1'}>
                        <Box>
                            We're so excited to work with you! Before we begin, please verify
                            that all of the following information is accurate and up-to-date.
                        </Box>

                        <SectionHeading title={'Contact Information'}/>

                        <ProfileForm
                            unlock
                            onSave={handleProfileFormSave}
                            employee={employee}
                            saveLabel={'Next'}
                            profileImage
                        />
                    </Box>
                </Step>

                <Step step={1} value={step}>
                    <JobInterviewStep
                        step={step}
                        loading={isLoading}
                        employee={employee}
                        onStepChange={setStep}
                    />
                </Step>

                <Step step={2} value={step}>
                    <ReferenceStep
                        step={step}
                        loading={isLoading}
                        employee={employee}
                        onStepChange={setStep}
                    />
                </Step>

                <Step step={3} value={step}>
                    <CredentialStep
                        step={step}
                        loading={isLoading}
                        onStepChange={setStep}
                    />
                </Step>

                <Box className={'d-hidden'}>
                    <Step step={4} value={step}>
                        <LogoHeading title={'Thank You!'}/>

                        <Box className={'columns__1'}>
                            <Box>
                                Your invitation has been completed successfully. Use the button below to return to your
                                dashboard.
                            </Box>

                            <Button
                                variant={'outlined'}
                                onClick={() => navigate('/')}
                            >Home</Button>
                        </Box>
                    </Step>
                </Box>
            </Box>
        </Page>
    );
};

export default Invitation;