import React from "react";

import InputMask from "react-input-mask";
import TextField from "@mui/material/TextField";

/**
 * InputPhone component.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
const InputPhone = (props) => {
    const {
        disabled,           // {Boolean} Indicates if the input is currently disabled.
    } = props;

    return (
        <InputMask
            {...props}
            mask={'(999) 999-9999'}
            maskChar={''}
        >
            {(inputProps) => (
                <TextField
                    {...inputProps}
                    disabled={disabled}
                />
            )}
        </InputMask>
    );
};

export default InputPhone;