import React from "react";

import Box from "@mui/material/Box";

/**
 * EmployeeStatusLabel component.
 *
 * @constructor
 */
const EmployeeStatusLabel = (props) => {
    const {
        record      // {Object} The employee record to display the status of.
    } = props;

    return record.status && record.status.name ? (
        <Box className={'text__bold d-inline__block'} children={record.status.name} sx={{color: record.status.color}}/>
    ) : (
        !record.isDeleted ? (
            record.isActive ?
                <Box className={'text__bold d-inline__block'} children="Active" sx={{color: '#2e7d32'}}/> : (
                    record.isInvited ?
                        <Box className={'text__bold d-inline__block'} children="Invited" color={'info'}/> :
                        <Box className={'text__bold d-inline__block'} children="Recruiting" color={'warning'}/>
                )
        ) : <Box className={'text__bold d-inline__block'} children="Inactive"/>
    )
};

export default EmployeeStatusLabel;