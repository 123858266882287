import React, {useEffect, useState} from "react";

import Step from "../../../Components/Step";
import {useAuth} from "../../../Global/Auth";
import LogoHeading from "../../../Components/Typography/LogoHeading";
import StepControls from "../../../Components/StepControls";
import InputSignature from "../../../Components/Input/InputSignature";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

/**
 * TimeSheetPolicyStep component.
 *
 * @returns {*}
 * @constructor
 */
const TimeSheetPolicyStep = (props) => {
    const {
        step,                   // {Number} The current step for the user.
        index,                  // {Number} The expected step number.
        onClose,                // {Function} An optional method for closing the form if it appears in a dialog.
        readOnly,               // {Boolean} Whether the form is disabled.
        employee,               // {Object} The employee object for read only context.
        disabled,               // {Boolean} Whether the step is disabled from input.
        contentOnly,            // {Boolean} Whether to only render the content.
        onStepChange            // {Function} The on change callback to load the next step.
    } = props;

    const [isLoaded, setLoaded] = useState(false);
    const [signature, setSignature] = useState('');
    const {setUserSetting, getUserSettingValue, getUserSettingValueForEmployee} = useAuth();

    /**
     * Loads the required settings for this step.
     */
    useEffect(() => {
        handleSettingsLoad();
    }, []);


    /**
     * Synchronize the job description signature.
     */
    useEffect(() => {
        if(!readOnly && isLoaded){
            setUserSetting('HP_TIME_SHEETS', signature)
        }
    }, [signature]);


    /**
     * Loads each of the appropriate user settings.
     *
     * @returns {Promise<void>}
     */
    const handleSettingsLoad = async () => {
        setSignature(
            readOnly ?
                await getUserSettingValueForEmployee(employee.id, 'HP_TIME_SHEETS') :
                await getUserSettingValue('HP_TIME_SHEETS')
        );

        setLoaded(true);
    };


    /**
     * The disabled state of the form.
     */
    const isDisabled = readOnly || disabled;

    return (
        <Step step={index} value={step}>
            <LogoHeading
                title={'Time Sheets'}
                onClose={onClose}
            />

            <Box className={'columns__1'}>
                <Box>
                    Reliant uses a manual process for recording employees’ time. The time sheet can be found in
                    the employee profile and at <a href={'www.ReliantStaffing.com'}>our website</a> under the
                    employee tab in the forms section.
                    Although the time sheets are very simple to use, there are a few rules that must be
                    followed.
                </Box>

                <TableContainer className={'table table--striped'}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    Time sheets are to include the employee’s name, specialty and the name of
                                    the facility worked at.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Reliant’s work week starts on Sunday and ends on Saturday. The date you
                                    write in each day of the week should start with Sunday.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    If you work at multiple facilities, you must complete separate time sheets
                                    for each facility.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Please print neatly in all boxes. If we can’t read your writing, then we
                                    can’t process your pay.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Healthcare providers do not chart in advance, so please do not pre-fill out
                                    your time sheet for the week and then scribble changes all over it.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Reliant audits every time sheet every week. You should only enter the exact
                                    time at which you started and finished your shift. Your entered time should
                                    be the exact time you punched in/out on the facility’s time clock if used.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    If you do not get a break, you must write a zero in the break box and have
                                    the supervisor initial the break box. If you do not get the supervisor to
                                    initial the break box, a 30-minute break will be subtracted from your hours.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    At the end of each shift, you are to get a supervisor’s signature to confirm
                                    your time for the shift.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Reliant will not receive payment with time sheets that do not have
                                    signatures after each shift. If you submit a time sheet without a signature,
                                    we will not process the payroll for that shift. You will have to return to
                                    the facility to get the signature and then resubmit for payroll.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Forging signatures and padding hours are felonies. Healthcare workers who
                                    have felonies on their background reports will likely not work again in
                                    healthcare. Please don’t do this.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Once your work week is over, you are to submit your time sheets one of two
                                    ways. You can either text a PROFESSIONAL GRADE PICTURE or FAX. Time sheets
                                    must be received no later that 10AM on Monday. The number to text and fax
                                    your time sheet appears at the bottom of the time sheet.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    If sending your time sheet by text, please send a high-quality picture. Lay
                                    the time sheet flat on a table, use adequate lighting, take the picture
                                    straight capturing the entire time sheet and take a picture that is on an
                                    appropriate angle.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    If you lose your time sheet, you will have to reproduce your time sheet and
                                    get the facility to resign for the time and shifts worked.
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Please be responsible with your time sheet and submit it when you finish
                                    working for the week.
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                {!contentOnly && (
                    <>
                        <InputSignature
                            value={signature}
                            label={'Please sign here:'}
                            disabled={isDisabled}
                            onChange={setSignature}
                            scaleFactor={2}
                        />

                        {!readOnly && (
                            <StepControls
                                step={step}
                                disabled={isDisabled || !signature}
                                onStepChange={onStepChange}
                            />
                        )}
                    </>
                )}
            </Box>
        </Step>
    );
};

export default TimeSheetPolicyStep;