import React, {useState} from "react";

import Settings from "../../Global/Settings";
import {useAuth} from "../../Global/Auth";
import SectionMenu from "../../Pages/Admin/CourseEditor/SectionMenu";
import CourseSectionForm from "../../Pages/Admin/CourseEditor/CourseSectionForm";
import CourseOverviewForm from "../../Pages/Admin/CourseEditor/CourseOverviewForm";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import AddIcon from '@mui/icons-material/Add';
import ListItem from "@mui/material/ListItem";
import InfoIcon from '@mui/icons-material/Info';
import QuizIcon from '@mui/icons-material/Quiz';
import ArticleIcon from '@mui/icons-material/Article';
import ListItemButton from "@mui/material/ListItemButton";

/**
 * CourseForm component.
 *
 * @returns {*}
 * @constructor
 */
const CourseForm = (props) => {
    const {
        record,         // {Object} The form context object, handles pre-loading the form.
        loading,        // {Boolean} A loading indicator boolean / flag.
        disabled,       // {Boolean} A disabled flag.
        onChange,       // {Function} A change callback, emits the current form state back to the page.
    } = props;

    const {hasPermissionTo} = useAuth();
    const [section, setSection] = useState(null);

    /**
     * Indicates if the user has permission to edit training.
     *
     * @type {Boolean}
     */
    const isAllowedToEdit = hasPermissionTo('EDIT_TRAINING');


    /**
     * Registers a new section.
     */
    const addSection = () => {
        if(!isAllowedToEdit){
            return;
        }

        if (!record.sections) {
            record.sections = [];
        }

        record.sections.push({
            name: 'New Section',
            content: '',
        });

        handleCourseChange(record);
        setSection(record.sections.length - 1);
    };


    /**
     * Synchronizes updates made against the course record.
     *
     * @param updated
     */
    const handleCourseChange = (updated) => {
        if (onChange) {
            onChange(updated);
        }
    };


    /**
     * Synchronizes updates made against the current section selection.
     *
     * @param updated
     */
    const handleSectionChange = (updated) => {
        record.sections[section] = updated;
        handleCourseChange(record);
    };


    /**
     * Returns the sidebar actions.
     *
     * @returns {*[]}
     */
    const getActions = () => {
        const sections = record.sections || [];

        return [
            {
                icon: InfoIcon,
                label: 'Course Overview',
                onClick: () => {
                    setSection(null);
                },
                selected: section === null,
            },
            ...sections.map((item, index) => {
                if (item.isDeleted) {
                    return null;
                }

                return {
                    icon: item.isTest ? QuizIcon : ArticleIcon,
                    secondary: isAllowedToEdit ? (
                        <SectionMenu
                            onMoveUpward={index ? () => {
                                let i = 1;

                                // Ignore any deleted sections.
                                if (index - i >= 0 && record.sections[index - i].isDeleted) {
                                    while (index - i >= 0 && record.sections[index - i].isDeleted) {
                                        i++;
                                    }
                                }

                                // Move the section to the new index within the "Course → Sections" relationship.
                                record.sections.splice(index, 1);
                                record.sections.splice(index - i, 0, item);
                                handleCourseChange(record);

                                // Replace the selection index if the moved section were previously selected.
                                if (section === index) {
                                    setSection(index - i);
                                }
                            } : null}
                            onMoveDownward={index < sections.length - 1 ? () => {
                                let i = 1;

                                // Ignore any deleted sections.
                                if (record.sections.length > index + i && record.sections[index + i].isDeleted) {
                                    while (record.sections.length > index + i && record.sections[index + i].isDeleted) {
                                        i++;
                                    }
                                }

                                // Move the section to the new index within the "Course → Sections" relationship.
                                record.sections.splice(index, 1);
                                record.sections.splice(index + i, 0, item);
                                handleCourseChange(record);

                                // Replace the selection index if the moved section were previously selected.
                                if (section === index) {
                                    setSection(index + i);
                                }
                            } : null}
                            onDelete={() => {
                                record.sections[index].isDeleted = true;
                                handleCourseChange(record);

                                // If we deleted the section we're currently looking at, pre-select the "Course
                                // Overview" page instead since we'll end up on a deleted stub otherwise.
                                if (section === index) {
                                    setSection(null);
                                }
                            }}
                        />
                    ) : null,
                    label: item.name || Settings.emptyContentLabel,
                    onClick: () => {
                        setSection(index)
                    },
                    selected: section === index
                }
            }),
            {
                icon: AddIcon,
                label: 'Add Section',
                onClick: addSection,
                selected: false,
                disabled: !isAllowedToEdit
            }
        ].filter(action => action !== null);
    };

    return (
        <Box className={'d-flex'}>
            <Box
                sx={{width: Settings.drawerWidth}}
                className={'border__right full__height--left'}
            >
                <List dense sx={{width: '100%'}}>
                    {getActions().map(action => (
                        <ListItem
                            sx={{paddingTop: 0, paddingBottom: 0}}
                            className={`list-item__row ${action.selected ? 'list-item__row--selected' : ''}`}
                            disableGutters
                            secondaryAction={action.secondary}
                        >
                            <ListItemButton
                                sx={{
                                    paddingTop: '0.5em',
                                    paddingLeft: '1em',
                                    paddingBottom: '0.5em',
                                }}
                                onClick={action.onClick}
                                disabled={action.disabled}
                                selected={action.selected}
                                className={`w__100`}
                                disableGutters
                            >
                                <Box
                                    sx={{paddingTop: '0.2em', paddingBottom: '0.2em'}}
                                    width={'100%'}
                                    className={'d-flex__start'}
                                >
                                    {action.icon && (
                                        <Box component={action.icon} className="mr__1"/>
                                    )}
                                    <Box children={action.label}/>
                                </Box>
                            </ListItemButton>
                        </ListItem>
                    ))}
                </List>
            </Box>
            <Box className={'flex__grow p__4'}>
                {section === null ? (
                    <CourseOverviewForm
                        record={record}
                        loading={loading || disabled}
                        onChange={handleCourseChange}
                    />
                ) : (
                    <CourseSectionForm
                        key={section}
                        record={record.sections[section]}
                        loading={loading || disabled}
                        onChange={handleSectionChange}
                    />
                )}
            </Box>
        </Box>
    );
};

export default CourseForm;