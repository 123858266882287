import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import {useAuth} from "../../../Global/Auth";

import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

/**
 * CallRouteDropdown component.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const CallRouteDropdown = (props) => {
    const {
        onChange        // {Function} The callback function to reload data on filter selection.
    } = props;

    const {user} = useAuth();
    const [method, setMethod] = useState('');
    const [setting, setSetting] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setLoading] = useState(true);
    const open = Boolean(anchorEl);

    /**
     * Load all initial data on mount.
     */
    useEffect(() => {
        getSetting();
    }, []);


    /**
     * Synchronize the method field.
     */
    useEffect(() => {
        doUpdate();
    }, [method]);


    /**
     * Loads the synchronized setting from the API.
     *
     * @returns {Promise<void>}
     */
    const getSetting = async () => {
        const response = await API.get('settings', {
            $top: 1,
            $filter: `key eq {CS_CALL_METHOD} and modelId in {${user.id}} and modelType in {User}`
        });

        if (!response.length) {
            return;
        }

        const setting = response[0];
        setMethod(setting.value);
        setSetting(setting);
    }


    /**
     * Creates / updates the related call setting.
     *
     * @returns {Promise<void>}
     */
    const doUpdate = async () => {
        if (!method) {
            return;
        }

        if (!setting.id) {
            return setSetting(
                await API.post('settings', {
                    key: 'CS_CALL_METHOD',
                    value: method,
                    modelId: user.id,
                    modelType: 'User',
                })
            );
        }

        await API.put(`settings/${setting.id}`, {
            value: method
        });
    };


    /**
     * Fired whenever the menu button is clicked.
     *
     * @param event
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Closes the menu popover.
     */
    const handleClose = () => {
        setAnchorEl(null);
    };


    /**
     * Handle a filter selection.
     *
     * @param value
     */
    const handleMethodClick = value => {

        handleClose();
        setMethod(value);

        if (onChange) {
            onChange(value);
        }
    };

    return (
        <Box
            sx={{padding: '0.3em', paddingLeft: '0.5em', paddingRight: '0.5em'}}
            className={`breadcrumbs quick__filters call-routing__dropdown`}
        >
            <div className={'crumb'}>
                <a
                    href={'#'}
                    onClick={handleClick}
                    className={'d-flex__start'}
                >
                    {method ? (
                        <>
                            <b style={{marginRight: '0.5em'}}>Call Routing:</b>
                            {method}
                        </>
                    ) : (
                        <>
                            <b style={{marginRight: '0.5em'}}>Call Routing:</b>
                            (default)
                        </>
                    )}

                    <ArrowDropDownIcon fontSize={'small'}/>
                </a>
                <Menu
                    open={open}
                    onClose={handleClose}
                    anchorEl={anchorEl}
                    disabled={isLoading}
                >
                    {['Browser', 'Cell Phone'].map(target => (
                        <MenuItem
                            key={target}
                            onClick={() => handleMethodClick(target)}
                            selected={method === target}
                        >{target}</MenuItem>
                    ))}
                </Menu>
            </div>
        </Box>
    );
};

export default CallRouteDropdown;