import React from "react";

import API from "../Global/API";
import Model from "../Definitions/Model";
import Property from "../Definitions/Property";
import {useAuth} from "../Global/Auth";
import PropertyMap from "../Definitions/PropertyMap";
import ImageWrapper from "../Components/ImageWrapper";
import CourseActions from "../Actions/CourseActions";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import {Link} from "react-router-dom";
import CameraAltIcon from '@mui/icons-material/CameraAlt';

/**
 * Course model.
 *
 * @type {Object}
 */
const Course = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'course',


    /**
     * The appropriate API endpoint root.
     */
    route: 'courses',


    /**
     * Additional query parameters for the index layout.
     */
    query: {
        $filter: 'isDeleted eq {0}'
    },


    /**
     * The menu action.
     *
     * @param parameters
     * @returns {*}
     */
    action: (parameters) => <CourseActions {...parameters}/>,


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'name',
    ],


    /**
     * Adjust the index layout accordingly.
     */
    tableOptions: {
        ...Model.tableOptions,
        hideTableHeader: true
    },


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'name',
            label: 'Course',
            render: (record) => {
                const CourseRender = () => {
                    const {hasPermissionTo} = useAuth();

                    return (
                        <Box className={'d-flex__start p__3'}>
                            <ImageWrapper
                                sx={{marginRight: '1em'}}
                                src={API.getFilePath(record.image)}
                                icon={<CameraAltIcon fontSize={'large'}/>}
                                width={64}
                                height={64}
                                horizontal
                            />
                            <Box className={'flex__grow columns__1 columns--small'}>
                                <Box className={'d-flex__start'}>
                                    {hasPermissionTo('VIEW_TRAINING') ? (
                                        <Link to={`/training/editor/${record.id}`}>
                                            <h3 className={'d-inline__block m__0 mr__2'}>{record.name}</h3>
                                        </Link>
                                    ) : (
                                        <h3 className={'d-inline__block m__0 mr__2'}>{record.name}</h3>
                                    )}

                                    {!record.isPublished && (
                                        <Chip
                                            size={'small'}
                                            label={'Draft'}
                                        />
                                    )}
                                </Box>

                                {record.shortDescription && (
                                    <Box>{record.shortDescription}</Box>
                                )}
                            </Box>
                        </Box>
                    )
                };

                return <CourseRender/>
            },
            sortable: true
        }),
        Property.create({
            key: 'shortDescription',
            label: 'Short Description',
            render: (record) => record.shortDescription,
            sortable: true
        }),
        Property.create({
            key: 'longDescription',
            label: 'Long Description',
            render: (record) => record.longDescription,
            sortable: true
        }),
    ])
};

export default Course;