import React from "react";

import API from "../Global/API";
import Model from "../Definitions/Model";
import Property from "../Definitions/Property";
import UserForm from "../Actions/UserActions/UserForm";
import Formatter from "../Global/Formatter";
import UserActions from "../Actions/UserActions";
import PropertyMap from "../Definitions/PropertyMap";
import ImageWrapper from "../Components/ImageWrapper";

import Skeleton from "@mui/material/Skeleton";

/**
 * User model.
 *
 * @type {Object}
 */
const User = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'user',


    /**
     * The appropriate API endpoint root.
     */
    route: 'users',


    /**
     * Additional query parameters to share throughout the application.
     */
    query: {
        $filter: 'isDeleted eq {0}'
    },


    /**
     * The form component.
     *
     * @returns {*}
     * @param parameters
     */
    form: (parameters) => <UserForm {...parameters}/>,


    /**
     * The menu action.
     *
     * @param parameters
     * @returns {*}
     */
    action: (parameters) => <UserActions {...parameters}/>,


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'image',
        'lastName',
        'firstName',
        'emailAddress',
        'phoneNumber',
        'jobTitle',
    ],


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'image',
            label: 'Image',
            width: '60px',
            loader: () => <Skeleton height={48} width={48}/>,
            render: (record) => (
                <ImageWrapper
                    src={API.getFilePath(record.image)}
                    width={48}
                    height={48}
                />
            ),
            hideLabel: true,
            selectable: true
        }),
        Property.create({
            key: 'lastName',
            width: '20%',
            label: 'Last Name',
            render: (record) => record.lastName,
            sortable: true
        }),
        Property.create({
            key: 'firstName',
            width: '20%',
            label: 'First Name',
            render: (record) => record.firstName,
            sortable: true
        }),
        Property.create({
            key: 'emailAddress',
            width: '20%',
            label: 'Email',
            render: (record) => Formatter.email(record.emailAddress),
            sortable: true
        }),
        Property.create({
            key: 'phoneNumber',
            width: '20%',
            label: 'Phone',
            render: (record) => Formatter.phone(record.phoneNumber),
            sortable: true
        }),
        Property.create({
            key: 'jobTitle',
            width: '20%',
            label: 'Job Title',
            render: (record) => record.jobTitle,
            sortable: true
        })
    ])
};

export default User;