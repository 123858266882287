import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import Step from "../../Components/Step";
import Page from "../../Components/Page";
import {useAuth} from "../../Global/Auth";
import AvailabilityForm from "../Admin/Employee/AvailabilityForm";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import {useNavigate} from "react-router";

/**
 * Availability component.
 *
 * @returns {*}
 * @constructor
 */
const Availability = () => {
    const navigate = useNavigate();
    const [step] = useState(0);
    const {user} = useAuth();
    const [employee, setEmployee] = useState({});

    /**
     * Load ancillary data on mount.
     */
    useEffect(() => {
        getEmployee();
    }, []);


    /**
     * Bind a scroll handler against the current step parameter.
     */
    useEffect(() => {
        handleScrollToTop();
    }, [step]);


    /**
     * Scrolls the user to the top of the page.
     */
    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };


    /**
     * Loads the active employee user.
     *
     * @returns {Promise<void>}
     */
    const getEmployee = async () => {
        setEmployee(await API.get(`employee/${user.id}`));
    };

    return (
        <Page hideHeader fullScreen>
            <Box className={'p__3 pt__0 columns__1'}>
                <Step step={0} value={step}>
                    {employee && employee.id && (
                        <AvailabilityForm employee={employee}/>
                    )}
                </Step>

                <Step step={1} value={step}>
                    <h2>Thank You!</h2>

                    <Box className={'columns__1'}>
                        <Box>
                            Your availability has been submitted successfully. Use the button below to return to your
                            dashboard.
                        </Box>

                        <Button
                            variant={'outlined'}
                            onClick={() => navigate('/')}
                        >Home</Button>
                    </Box>
                </Step>
            </Box>
        </Page>
    );
};

export default Availability;