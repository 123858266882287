import React, {useEffect, useState} from "react";

import Step from "../../../Components/Step";
import {useAuth} from "../../../Global/Auth";
import Formatter from "../../../Global/Formatter";
import LogoHeading from "../../../Components/Typography/LogoHeading";
import StepControls from "../../../Components/StepControls";
import InputSignature from "../../../Components/Input/InputSignature";
import withSynchronizedSettings from "../Abstract/withSynchronizedSettings";

import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import InputMask from "react-input-mask";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";

/**
 * W4CertificateStep component.
 *
 * @returns {*}
 * @constructor
 */
const W4CertificateStep = withSynchronizedSettings((props) => {
    const {
        step,                   // {Number} The current step for the user.
        index,                  // {Number} The expected step number.
        doSync,                 // {Function} The synchronization callback.
        readOnly,               // {Boolean} Whether the form is disabled.
        employee,               // {Object} The employee record for added context.
        disabled,               // {Boolean} Whether the step is disabled from input.
        onValidate,             // {Function} The post-validation action.
        doValidate,             // {Function} Triggers the validation event.
        getSetting,             // {Function} Retrieves a particular setting value.
        isSubmitted,            // {Boolean} Indicates whether the parent form has been submitted.
        onStepChange,           // {Function} The step change callback for the parent component.
    } = props;

    const {isScope} = useAuth();
    const [city, setCity] = useState(getSetting('HP_W4_CITY', ''));
    const [state, setState] = useState(getSetting('HP_W4_STATE', ''));
    const [lastName, setLastName] = useState(getSetting('HP_W4_LAST_NAME', ''));
    const [signature, setSignature] = useState(getSetting('HP_W4_SIGNATURE', ''));
    const [firstName, setFirstName] = useState(getSetting('HP_W4_FIRST_NAME', ''));
    const [postalCode, setPostalCode] = useState(getSetting('HP_W4_POSTAL_CODE', ''));
    const [twoJobsTotal, setTwoJobsTotal] = useState(getSetting('HP_W4_TWO_JOBS', ''));
    const [otherTaxable, setOtherTaxable] = useState(getSetting('HP_W4_OTHER_TAXABLE', ''));
    const [filingStatus, setFilingStatus] = useState(getSetting('HP_W4_FILING_STATUS', ''));
    const [additionalTax, setAdditionalTax] = useState(getSetting('HP_W4_ADDITIONAL_TAX', ''));
    const [streetAddress, setStreetAddress] = useState(getSetting('HP_W4_STREET_ADDRESS', ''));
    const [middleInitial, setMiddleInitial] = useState(getSetting('HP_W4_MIDDLE_INITIAL', ''));
    const [socialSecurity, setSocialSecurity] = useState(getSetting('HP_W4_SOCIAL_SECURITY', ''));
    const [apartmentNumber, setApartmentNumber] = useState(getSetting('HP_W4_APARTMENT_NUMBER', ''));
    const [otherDependents, setOtherDependents] = useState(getSetting('HP_W4_OTHER_DEPENDENTS', ''));
    const [qualifyingChildren, setQualifyingChildren] = useState(getSetting('HP_W4_QUALIFYING_CHILDREN', ''));
    const [nonStandardDeductions, setNonStandardDeductions] = useState(getSetting('HP_W4_NON_STANDARD', ''));

    /**
     * Synchronize backend settings.
     */
    useEffect(() => {
        doSync('HP_W4_CITY', city);
    }, [city]);

    useEffect(() => {
        doSync('HP_W4_STATE', state);
    }, [state]);

    useEffect(() => {
        doSync('HP_W4_LAST_NAME', lastName);
    }, [lastName]);

    useEffect(() => {
        doSync('HP_W4_SIGNATURE', signature);
    }, [signature]);

    useEffect(() => {
        doSync('HP_W4_FIRST_NAME', firstName);
    }, [firstName]);

    useEffect(() => {
        doSync('HP_W4_TWO_JOBS', twoJobsTotal);
    }, [twoJobsTotal]);

    useEffect(() => {
        doSync('HP_W4_POSTAL_CODE', postalCode);
    }, [postalCode]);

    useEffect(() => {
        doSync('HP_W4_OTHER_TAXABLE', otherTaxable);
    }, [otherTaxable]);

    useEffect(() => {
        doSync('HP_W4_FILING_STATUS', filingStatus);
    }, [filingStatus]);

    useEffect(() => {
        doSync('HP_W4_ADDITIONAL_TAX', additionalTax);
    }, [additionalTax]);

    useEffect(() => {
        doSync('HP_W4_STREET_ADDRESS', streetAddress);
    }, [streetAddress]);

    useEffect(() => {
        doSync('HP_W4_MIDDLE_INITIAL', middleInitial);
    }, [middleInitial]);

    useEffect(() => {
        doSync('HP_W4_SOCIAL_SECURITY', socialSecurity);
    }, [socialSecurity]);

    useEffect(() => {
        doSync('HP_W4_APARTMENT_NUMBER', apartmentNumber);
    }, [apartmentNumber]);

    useEffect(() => {
        doSync('HP_W4_OTHER_DEPENDENTS', otherDependents);
    }, [otherDependents]);

    useEffect(() => {
        doSync('HP_W4_NON_STANDARD', qualifyingChildren);
    }, [qualifyingChildren]);

    useEffect(() => {
        doSync('HP_W4_NON_STANDARD', nonStandardDeductions);
    }, [nonStandardDeductions]);


    /**
     * Synchronize any default values.
     */
    useEffect(() => {
        if(disabled){
            return;
        }

        if(!firstName){
            setFirstName(employee.firstName);
        }

        if(!lastName){
            setLastName(employee.lastName);
        }

        if(!streetAddress){
            setStreetAddress(employee.addressLine1);
        }

        if(!apartmentNumber){
            setApartmentNumber(employee.apartmentNumber);
        }

        if(!city){
            setCity(employee.city);
        }

        if(!state){
            setState('PA');
        }

        if(!postalCode){
            setPostalCode(employee.postalCode);
        }
    }, []);


    /**
     * Handles the form validation.
     */
    const handleValidation = () => {
        doValidate([
            city,
            state,
            lastName,
            firstName,
            postalCode,
            filingStatus,
            streetAddress,
            socialSecurity,
        ]);
    };


    /**
     * The disabled state of each input.
     */
    const isDisabled = disabled || readOnly;

    return (
        <Step step={index} value={step}>
            <LogoHeading title={'Employee\'s Withholding Certificate (W-4)'}/>

            <Box className={'columns__1'}>
                <FormControl error={isSubmitted && !firstName}>
                    <FormLabel className={'d-block mb__2'} required>First Name</FormLabel>
                    <TextField
                        value={firstName}
                        error={isSubmitted && !firstName}
                        disabled={isDisabled}
                        onChange={event => setFirstName(event.target.value)}
                        fullWidth
                        placeholder={'John'}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel className={'d-block mb__2'}>Middle Initial</FormLabel>
                    <TextField
                        value={middleInitial}
                        disabled={isDisabled}
                        onChange={event => setMiddleInitial(event.target.value)}
                        fullWidth
                    />
                </FormControl>

                <FormControl error={isSubmitted && !lastName}>
                    <FormLabel className={'d-block mb__2'} required>Last Name</FormLabel>
                    <TextField
                        value={lastName}
                        error={isSubmitted && !lastName}
                        disabled={isDisabled}
                        onChange={event => setLastName(event.target.value)}
                        fullWidth
                        placeholder={'Doe'}
                    />
                </FormControl>

                <FormControl error={isSubmitted && !socialSecurity}>
                    <FormLabel className={'d-block mb__2'} required>Social Security #</FormLabel>

                    {/* For employees reviewing their own W4, we'll want to display only the last four here. */}
                    {readOnly && isScope('Employee') ? (
                        <TextField
                            value={Formatter.ssn(socialSecurity)}
                            disabled={true}
                            placeholder={'XXX-XX-XXXX'}
                        />
                    ) : (
                        <InputMask
                            mask="999-99-9999"
                            value={socialSecurity}
                            error={isSubmitted && !socialSecurity}
                            required
                            disabled={isDisabled}
                            onChange={event => setSocialSecurity(event.target.value)}
                            maskChar=""
                            fullWidth
                        >
                            {(inputProps) => (
                                <TextField
                                    {...inputProps}
                                    disabled={isDisabled}
                                    placeholder={'XXX-XX-XXXX'}
                                />
                            )}
                        </InputMask>
                    )}

                </FormControl>



                <FormControl error={isSubmitted && !streetAddress}>
                    <FormLabel className={'d-block mb__2'} required>Street Address</FormLabel>
                    <TextField
                        value={streetAddress}
                        error={isSubmitted && !streetAddress}
                        disabled={isDisabled}
                        onChange={event => setStreetAddress(event.target.value)}
                        fullWidth
                        placeholder={'1 Example Road'}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel className={'d-block mb__2'}>Apartment Number</FormLabel>
                    <TextField
                        value={apartmentNumber}
                        disabled={isDisabled}
                        onChange={event => setApartmentNumber(event.target.value)}
                        fullWidth
                    />
                </FormControl>

                <FormControl error={isSubmitted && !city}>
                    <FormLabel className={'d-block mb__2'} required>City / Town</FormLabel>
                    <TextField
                        value={city}
                        error={isSubmitted && !city}
                        disabled={isDisabled}
                        onChange={event => setCity(event.target.value)}
                        fullWidth
                        placeholder={'Pittsburgh'}
                    />
                </FormControl>

                <FormControl error={isSubmitted && !state}>
                    <FormLabel className={'d-block mb__2'} required>State</FormLabel>
                    <TextField
                        value={state}
                        error={isSubmitted && !state}
                        disabled={isDisabled}
                        onChange={event => setState(event.target.value)}
                        fullWidth
                        placeholder={'PA'}
                    />
                </FormControl>

                <FormControl error={isSubmitted && !postalCode}>
                    <FormLabel className={'d-block mb__2'} required>Postal Code</FormLabel>
                    <TextField
                        value={postalCode}
                        error={isSubmitted && !postalCode}
                        disabled={isDisabled}
                        onChange={event => setPostalCode(event.target.value)}
                        fullWidth
                        placeholder={'XXXXX'}
                    />
                </FormControl>

                <FormControl error={isSubmitted && !filingStatus}>
                    <FormLabel required>Filing Status</FormLabel>
                    <RadioGroup
                        value={filingStatus}
                        onChange={event => setFilingStatus(event.target.value)}
                    >
                        <FormControlLabel
                            value="single"
                            label="Single or Married filing separately"
                            control={<Radio disabled={isDisabled}/>}
                        />
                        <FormControlLabel
                            value="married"
                            label="Married filing jointly"
                            control={<Radio disabled={isDisabled}/>}
                        />
                        <FormControlLabel
                            value="hoh"
                            label="Head of household"
                            control={<Radio disabled={isDisabled}/>}
                        />
                    </RadioGroup>
                </FormControl>

                <Box className={'well__container p__3'}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={twoJobsTotal}
                                disabled={isDisabled}
                                onChange={event => setTwoJobsTotal(event.target.checked)}
                            />
                        }
                        label={
                            <>
                                <b>For multiple jobs</b> - If there are only two jobs total, you may check this box. Do
                                the same on Form W-4 for the other job.
                            </>
                        }
                    />
                </Box>

                <Box><b>Please complete the following sections ONLY if they apply to you</b></Box>

                <Box>
                    <FormLabel className={'d-block mb__2'}>
                        If your income will be $200,000 or less ($400,000 or less if married filing jointly):
                    </FormLabel>

                    <Box className={'well__container p__3 columns__1'}>
                        <FormControl>
                            <FormLabel className={'d-block mb__2'}>
                                Multiply the number of qualifying children under age 17 by $2,000
                            </FormLabel>
                            <OutlinedInput
                                value={qualifyingChildren}
                                disabled={isDisabled}
                                onChange={event => setQualifyingChildren(event.target.value)}
                                fullWidth
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            />
                        </FormControl>

                        <FormControl>
                            <FormLabel className={'d-block mb__2'}>
                                Multiply the number of other dependents by $500
                            </FormLabel>
                            <OutlinedInput
                                value={otherDependents}
                                disabled={isDisabled}
                                onChange={event => setOtherDependents(event.target.value)}
                                fullWidth
                                startAdornment={<InputAdornment position="start">$</InputAdornment>}
                            />
                        </FormControl>
                    </Box>
                </Box>

                <FormControl>
                    <FormLabel className={'d-block mb__2'}>
                        If you want tax withheld for other income you expect this year that won't have withholding,
                        enter the amount of other income here. This may include interest, dividends, and retirement
                        income.
                    </FormLabel>
                    <OutlinedInput
                        value={otherTaxable}
                        disabled={isDisabled}
                        onChange={event => setOtherTaxable(event.target.value)}
                        fullWidth
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel className={'d-block mb__2'}>
                        If you expect to claim deductions other than the standard deduction and want to reduce your
                        withholding, enter the amount here.
                    </FormLabel>
                    <OutlinedInput
                        value={nonStandardDeductions}
                        disabled={isDisabled}
                        onChange={event => setNonStandardDeductions(event.target.value)}
                        fullWidth
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    />
                </FormControl>

                <FormControl>
                    <FormLabel className={'d-block mb__2'}>
                        Enter any additional tax you want withheld each pay period.
                    </FormLabel>
                    <OutlinedInput
                        value={additionalTax}
                        disabled={isDisabled}
                        onChange={event => setAdditionalTax(event.target.value)}
                        fullWidth
                        startAdornment={<InputAdornment position="start">$</InputAdornment>}
                    />
                </FormControl>
                <InputSignature
                    value={signature}
                    label={'Please sign here:'}
                    disabled={isDisabled}
                    onChange={setSignature}
                    scaleFactor={2}
                />

                {!readOnly && (
                    <StepControls
                        step={step}
                        onBack={onStepChange}
                        onNext={!isDisabled ? handleValidation : onValidate}
                        disabled={isDisabled || !signature}
                    />
                )}
            </Box>
        </Step>
    );
});

export default W4CertificateStep;