import React, {useState} from "react";

import API from "../../Global/API";
import {useAuth} from "../../Global/Auth";
import Formatter from "../../Global/Formatter";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Button from "@mui/material/Button";
import EmailIcon from '@mui/icons-material/Email';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

/**
 * DrugScreenButton component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const DrugScreenButton = (props) => {
    const {
        record,         // {Object} The target record to base values from.
        doReload,       // {Function} The reload callback for the table.
    } = props;

    const {user} = useAuth();
    const [isLoading, setLoading] = useState(false);

    /**
     * Processes the drug screen date updates.
     *
     * @returns {Promise<void>}
     */
    const doUpdate = async () => {
        setLoading(true);

        if (!record.drugScreenDate) {
            const result = await API.put(`employee/${record.id}`, {
                drugScreenDate: dayjs().utc().format('YYYY-MM-DD HH:mm:ss')
            });

            // Post an additional note.
            await API.post('notes', {
                note: 'Drug Screen Ordered',
                userId: user.id,
                modelId: record.id,
                modelType: 'Employee'
            });

            if (doReload) {
                doReload(result);
            }
        } else if (!record.drugScreenReceivedDate) {
            const result = await API.put(`employee/${record.id}`, {
                drugScreenReceivedDate: dayjs().utc().format('YYYY-MM-DD HH:mm:ss')
            });

            // Post an additional note.
            await API.post('notes', {
                note: 'Drug Screen Received',
                userId: user.id,
                modelId: record.id,
                modelType: 'Employee'
            });

            if (doReload) {
                doReload(result);
            }
        } else {
            const result = await API.put(`employee/${record.id}`, {
                drugScreenDate: '',
                drugScreenReceivedDate: '',
            });

            if (doReload) {
                doReload(result);
            }
        }

        setLoading(false);
    };

    return (
        <Box className={'white-space__pre'}>
            {!record.drugScreenDate ? (
                <Button
                    size={'small'}
                    onClick={doUpdate}
                    variant={'outlined'}
                    disabled={isLoading}
                    className={'white-space__pre'}
                >Order Now</Button>
            ) : (
                !record.drugScreenReceivedDate ? (
                    <Button
                        size={'small'}
                        onClick={doUpdate}
                        disabled={isLoading}
                        variant={'outlined'}
                        startIcon={<EmailIcon/>}
                        className={'white-space__pre'}
                    >{Formatter.dateTime(record.drugScreenDate)}</Button>
                ) : (
                    <Button
                        size={'small'}
                        onClick={doUpdate}
                        variant={'outlined'}
                        className={'white-space__pre'}
                        startIcon={<MarkEmailReadIcon/>}
                    >{Formatter.dateTime(record.drugScreenReceivedDate)}</Button>
                )
            )}
        </Box>
    )
};

export default DrugScreenButton;