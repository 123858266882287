import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import State from "../../../Global/State";
import AddNote from "../../../Actions/NoteForm";
import Settings from "../../../Global/Settings";
import {useAuth} from "../../../Global/Auth";
import Formatter from "../../../Global/Formatter";
import DialogHeading from "../../Typography/DialogHeading";

import Box from "@mui/material/Box";
import Menu from '@mui/material/Menu';
import Paper from "@mui/material/Paper";
import Badge from "@mui/material/Badge";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Skeleton from "@mui/material/Skeleton";
import MenuItem from '@mui/material/MenuItem';
import Checkbox from "@mui/material/Checkbox";
import ListItem from "@mui/material/ListItem";
import Accordion from "@mui/material/Accordion";
import FormGroup from "@mui/material/FormGroup";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import FormControlLabel from "@mui/material/FormControlLabel";
import PostAddOutlinedIcon from '@mui/icons-material/PostAddOutlined';

/**
 * NotesBlock component.
 *
 * @returns {*}
 * @constructor
 */
const NotesBlock = (props) => {
    const {
        record,         // {Object} The related record.
        modelId,        // {Integer} The model ID to reference for notes.,
        modelType,      // {String} The appropriate model type to reference.
    } = props;

    const {hasPermissionTo} = useAuth();
    const [results, setResults] = useState([]);
    const [context, setContext] = useState({});
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [isEditing, setEditing] = useState(false);
    const [isDeleting, setDeleting] = useState(false);
    const [isAddingNote, setAddingNote] = useState(false);
    const [hideMessages, setHideMessages] = useState(!!State.get('hide-note-messages'));
    const [outerAnchorEl, setOuterAnchorEl] = useState(null);
    const [hideRecruiting, setHideRecruiting] = useState(!!State.get('hide-recruiting-notes'));
    const isMenuOpen = Boolean(anchorEl);

    /**
     * Load all details on component mount.
     */
    useEffect(() => {
        State.set('hide-note-messages', hideMessages ? 'true' : '');
        State.set('hide-recruiting-notes', hideRecruiting ? 'true' : '');
        getResults();
    }, [hideMessages, hideRecruiting]);


    /**
     * Reveals the action menu.
     *
     * @param event
     * @param context
     */
    const handleMenuOpen = (event, context) => {
        setContext(context);
        setAnchorEl(event.currentTarget);
    };


    /**
     * Closes the action menu.
     */
    const handleMenuClose = () => {
        setAnchorEl(null);
    };


    /**
     * Reveals the "Edit Note" form.
     */
    const handleEditOpen = () => {
        handleMenuClose();
        setEditing(true);
    };


    /**
     * Reveals the "Add a Note" form.
     */
    const handleAddNoteOpen = () => {
        setContext({});
        setAddingNote(true);
    };


    /**
     * Hides the "Add a Note" form.
     */
    const handleAddNoteClose = async () => {
        setEditing(false);
        setAddingNote(false);
        await getResults()
    };


    /**
     * Processes a delete on a note.
     *
     * @returns {Promise<void>}
     */
    const doDelete = async () => {
        setLoading(true);
        await API.put(`notes/${context.id}`, {
            isDeleted: true
        });
        handleDeleteClose();
        getResults();
    };


    /**
     * Reveals the delete confirmation dialog.
     */
    const handleDeleteOpen = () => {
        handleMenuClose();
        setDeleting(true);
    };


    /**
     * Hides the delete confirmation dialog.
     */
    const handleDeleteClose = () => {
        setDeleting(false);
    };


    /**
     * Loads any note records for the related resource.
     *
     * @returns {Promise<void>}
     */
    const getResults = async () => {
        setLoading(true);

        const results = await API.get('notes', {
            $top: 500,
            $expand: 'user,message',
            $filter: `isDeleted eq {0} and modelType in {${modelType}} and modelId in {${modelId}} ${hideMessages ? 'and note ne {Sent SMS.} and note ne {Sent Email.}' : ''}`,
            $orderby: 'createdDate desc'
        });
        setResults(results.filter(result => {
            if (hideRecruiting) {
                const note = result.note || "";

                return !note.includes("Contact Attempt #") &&
                    !note.includes("was uploaded by") &&
                    !note.includes("was approved by") &&
                    !note.includes("Drug Screen Received") &&
                    !note.includes("Drug Screen Ordered");
            }

            return true;
        }));
        setLoading(false);
    };


    /**
     * Returns true if we have the ability to edit this type of note.
     *
     * @type {*}
     */
    const bCanEdit = modelType === 'Client' ?
        hasPermissionTo('EDIT_CLIENTS') :
        hasPermissionTo('EDIT_EMPLOYEES');


    /**
     * Reveals the outer filter menu.
     *
     * @param event
     */
    const handleFilterOpen = (event) => {
        setOuterAnchorEl(event.currentTarget);
    };


    /**
     * Closes the filter menu.
     */
    const handleFilterClose = () => {
        setOuterAnchorEl(null);
    };


    /**
     * Indicates if any filters are applied.
     *
     * @type {boolean}
     */
    const hasFiltersApplied = !!hideMessages || !!hideRecruiting

    return (
        <Paper>
            <Box
                sx={{
                    paddingTop: '0.35em',
                    paddingLeft: '1em',
                    paddingRight: '0.5em',
                    paddingBottom: '0.35em',
                }}
                className={'d-flex__justify'}
            >
                <h3 className={'m__0'}>Notes</h3>

                <Box className={'d-flex__start'}>
                    <IconButton onClick={handleAddNoteOpen} sx={!bCanEdit ? {opacity: 0, pointerEvents: 'none'} : {}}>
                        <PostAddOutlinedIcon/>
                    </IconButton>

                    <IconButton onClick={handleFilterOpen}>
                        <Badge invisible={!hasFiltersApplied} color={'primary'} variant={'dot'}>
                            <MoreHorizIcon/>
                        </Badge>
                    </IconButton>
                </Box>
            </Box>
            <Divider/>
            <Box className={'columns__1 p__3'} sx={{maxHeight: 300, overflowY: 'scroll'}}>
                {isLoading && !results.length && (
                    <div>
                        <div><Skeleton width={'40%'}/></div>
                        <div><Skeleton/></div>
                    </div>
                )}

                {!isLoading && !results.length && (
                    <div>
                        <div className={'text__center text__disclaimer'}>No results available.</div>
                    </div>
                )}

                {results.map(result => {
                    const {
                        message
                    } = result;

                    const bHasUser = result.user && result.user.id;
                    const bHasMessage = message && message.id && !message.isPinned;

                    return (
                        <div key={result.id}>
                            <Box className={'d-flex__justify mb__2'}>
                                <b>{bHasUser ? (result.user.displayName || Settings.emptyContentLabel) : 'System'} - {Formatter.dateTime(result.createdDate)}</b>

                                {bHasUser && bCanEdit && (
                                    <IconButton size={'small'} onClick={event => handleMenuOpen(event, result)}>
                                        <MoreHorizIcon/>
                                    </IconButton>
                                )}
                            </Box>
                            <div>
                                {bHasMessage ? (
                                    <Accordion>
                                        <AccordionSummary
                                            children={
                                                <Box>Sent {message.type} {message.type !== 'Email' ? 'Message' : ''}</Box>
                                            }
                                            expandIcon={<ExpandMoreIcon/>}
                                        />
                                        <AccordionDetails>
                                            <Box className={'white-space__pre-line'}>
                                                {message.type !== 'Email' ? (
                                                    <>{message.content || Settings.emptyContentLabel}</>
                                                ) : (
                                                    <Box
                                                        dangerouslySetInnerHTML={{__html: message.content || Settings.emptyContentLabel}}/>
                                                )}
                                            </Box>
                                        </AccordionDetails>
                                    </Accordion>
                                ) : (result.note || Settings.emptyContentLabel)}
                            </div>
                        </div>
                    );
                })}
            </Box>

            <Menu
                open={isMenuOpen}
                onClose={handleMenuClose}
                anchorEl={anchorEl}
            >
                <MenuItem
                    onClick={handleEditOpen}
                    children={'Edit Note'}
                />
                <Divider/>
                <MenuItem
                    onClick={handleDeleteOpen}
                    children={'Delete Note'}
                    className={'menu__error'}
                />
            </Menu>

            {(isAddingNote || isEditing) && (
                <AddNote
                    open={true}
                    record={record}
                    modelId={record.id}
                    onClose={handleAddNoteClose}
                    existing={context}
                    modelType={modelType}
                />
            )}

            {isDeleting && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handleDeleteClose}
                    maxWidth={'xs'}
                    fullWidth
                >
                    <DialogHeading
                        title={"Confirm Delete"}
                        noMargin
                    />
                    <DialogContent>
                        Are you sure that you'd like to delete this note?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleDeleteClose}
                            children={'Cancel'}
                        />
                        <Button
                            color={'error'}
                            onClick={doDelete}
                            children={'Delete'}
                            disabled={isLoading}
                        />
                    </DialogActions>
                </Dialog>
            )}

            <Menu
                open={Boolean(outerAnchorEl)}
                onClose={handleFilterClose}
                anchorEl={outerAnchorEl}
            >
                <MenuItem disabled>
                    Filter Items
                </MenuItem>
                <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
                    <FormGroup>
                        <FormControlLabel
                            label={'Hide System Notes'}
                            control={
                                <Checkbox
                                    checked={hideMessages}
                                    onChange={event => setHideMessages(event.target.checked)}
                                />
                            }
                        />
                    </FormGroup>
                </ListItem>
                <ListItem sx={{paddingTop: 0, paddingBottom: 0}}>
                    <FormGroup>
                        <FormControlLabel
                            label={'Hide Recruiting Notes'}
                            control={
                                <Checkbox
                                    checked={hideRecruiting}
                                    onChange={event => setHideRecruiting(event.target.checked)}
                                />
                            }
                        />
                    </FormGroup>
                </ListItem>
            </Menu>
        </Paper>
    );
};

export default NotesBlock;