import React from "react";

import Model from "../Definitions/Model";
import Property from "../Definitions/Property";
import BoundSwitch from "../Components/Input/BoundSwitch";
import PropertyMap from "../Definitions/PropertyMap";
import TemplateForm from "../Actions/TemplateActions/TemplateForm";
import TemplateActions from "../Actions/TemplateActions";

import {faker} from '@faker-js/faker';

/**
 * Template model.
 *
 * @type {Object}
 */
const Template = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'template',


    /**
     * The appropriate API endpoint root.
     */
    route: 'templates',


    /**
     * Additional query parameters for the index layout.
     */
    query: {
        $filter: 'isDeleted eq {0}'
    },


    /**
     * The form component.
     *
     * @returns {*}
     * @param parameters
     */
    form: (parameters) => <TemplateForm {...parameters}/>,


    /**
     * The menu action.
     *
     * @param parameters
     * @returns {*}
     */
    action: (parameters) => <TemplateActions {...parameters}/>,


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'name',
        'modelType',
        'isSms',
        'isChat',
        'isEmail',
    ],


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'name',
            width: '22%',
            label: 'Name',
            render: (record) => record.name,
            sortable: true
        }),
        Property.create({
            key: 'content',
            label: 'Content',
            render: (record) => record.content,
            sortable: true
        }),
        Property.create({
            key: 'modelType',
            width: '19%',
            label: 'Record Type',
            render: (record) => record.modelType,
            sortable: true
        }),
        Property.create({
            key: 'isSms',
            width: '17%',
            label: 'SMS',
            render: (record) => (
                <BoundSwitch
                    record={record}
                    field={'isSms'}
                    model={Template}
                    permission={'EDIT_COMMUNICATIONS'}
                />
            ),
            sortable: true
        }),
        Property.create({
            key: 'isChat',
            width: '17%',
            label: 'Chat',
            render: (record) => (
                <BoundSwitch
                    record={record}
                    field={'isChat'}
                    model={Template}
                    permission={'EDIT_COMMUNICATIONS'}
                />
            ),
            sortable: true
        }),
        Property.create({
            key: 'isEmail',
            width: '17%',
            label: 'Email',
            render: (record) => (
                <BoundSwitch
                    record={record}
                    field={'isEmail'}
                    model={Template}
                    permission={'EDIT_COMMUNICATIONS'}
                />
            ),
            sortable: true
        }),
    ])
};

export default Template;