import Logger from "./Logger";

/**
 * Clipboard object.
 *
 * @type {Object}
 * @description Handles copying to the clipboard.
 */
const Clipboard = {
    /**
     * Performs a text copy using deprecated methods.
     *
     * @param text
     * @param callback
     */
    doCopyFallback(text, callback){
        const textArea = document.createElement("textarea");
        textArea.value = text;

        // Avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";

        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();

        try {
            const successful = document.execCommand('copy');
            const msg = successful ? 'successful' : 'unsuccessful';
            Logger.debug('[Clipboard] Fallback: Copying text command was ' + msg);

            // Execute the callback if successful.
            if(successful && callback){
                callback();
            }

            return;
        } catch (err) {
            Logger.warn('[Clipboard] Fallback: Oops, unable to copy', err)
        }

        document.body.removeChild(textArea);
    },


    /**
     * Performs a text copy.
     *
     * @param text
     * @param callback
     * @param options
     */
    doCopy(text, callback, options){
        const {html} = options || {};

        if (!navigator.clipboard) {
            Clipboard.doCopyFallback(text, callback);
            return;
        }

        // Handle HTML / rich copying.
        if(html){
            const blob = new Blob([text], {type: 'text/html'});
            const clipboardItem = new window.ClipboardItem({'text/html': blob});

            navigator.clipboard.write([clipboardItem]).then(function () {

                // Execute the callback on success.
                if(callback){
                    callback();
                }

                Logger.debug('[Clipboard] Async: Copying HTML to clipboard was successful!');
            }, function (err) {
                Logger.warn('[Clipboard] Async: Could not copy HTML: ', err);
            });
            return;
        }

        navigator.clipboard.writeText(text).then(function () {

            // Execute the callback on success.
            if(callback){
                callback();
            }

            Logger.debug('[Clipboard] Async: Copying to clipboard was successful!');
        }, function (err) {
            Logger.warn('[Clipboard] Async: Could not copy text: ', err);
        });
    }
};

export default Clipboard;