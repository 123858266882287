import React, {useState} from "react";

import Box from "@mui/material/Box";

/**
 * CrumbWrapper component.
 *
 * @returns {*}
 * @constructor
 */
const CrumbWrapper = (props) => {
    const {
        value,              // {String} The existing tab selection.
        options,            // {Array} A text array of basic options or "crumbs"
        onChange,           // {Function} Triggered whenever the user clicks a new tab.
        className,          // {String} An optional class is include on the outer wrapper.
        renderLabel,        // {Function} An optional label callback to render options by.
        controlSlot,        // {Component} An optional control to append to the container.
    } = props;

    const [tab, setTab] = useState(value || (options.length ? options[0] : ''));

    /**
     * Handles the change event for tabs.
     *
     * @param updated
     */
    const handleTabClick = (updated) => {
        if(tab === updated){
            return;
        }

        setTab(updated);

        if (onChange) {
            onChange(updated);
        }
    };

    return (
        <Box
            sx={{
                padding: '0.4em',
                paddingLeft: '0.5em',
                paddingRight: '0.5em'
            }}
            className={`breadcrumbs quick__filters ${className || ''}`}
        >
            <Box className={`d-flex__start`}>
                {options.map((option, i) => (
                    <>
                        <Box className={'crumb'} key={`crumb_${i}`}>
                            <a
                                onClick={() => handleTabClick(option)}
                                children={renderLabel ? renderLabel(option) : option}
                                className={`
                                    d-flex__start
                                    generic__link
                                    ${tab === option ? 'active' : ''}
                                `}
                            />
                        </Box>

                        {/*Display the divider on all results but the last.*/}
                        {i < options.length - 1 && <div className={'divider'} key={`crumb_d${i}`}/>}
                    </>
                ))}
            </Box>

            {controlSlot && (
                <>
                    {controlSlot}
                </>
            )}
        </Box>
    )
};

export default CrumbWrapper;