import React, {useState} from "react";

import API from "../../Global/API";
import {useAuth} from "../../Global/Auth";
import ImageWrapper from "../ImageWrapper";
import NotificationBell from "../Widgets/NotificationBell";
import CallNotifications from "../Widgets/CallNotifications";

import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import Badge from "@mui/material/Badge";
import Tooltip from '@mui/material/Tooltip';
import Divider from "@mui/material/Divider";
import {styled} from "@mui/material";
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {useNavigate} from "react-router";

/**
 * The status colors for the authenticated user.
 *
 * @type {Object}
 */
const colorMap = {
    active: '#36bd60',
    away: '#d32f2f',
};


/**
 * StyledBadgeActive component.
 *
 * @type {Component}
 */
const StyledBadgeActive = styled(Badge)(({theme}) => ({
    '& .MuiBadge-badge': {
        top: '100%',
        color: colorMap.active,
        boxShadow: `0 0 0 2px #417199`,
        backgroundColor: colorMap.active,
        '&::after': {
            position: 'absolute',
            top: -1,
            left: -1,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            transform: 'scale(.6)',
            border: '1px solid currentColor',
            content: '""',
        },
    }
}));


/**
 * StyledBadgeAway component.
 *
 * @type {Component}
 */
const StyledBadgeAway = styled(Badge)(({theme}) => ({
    '& .MuiBadge-badge': {
        top: '100%',
        color: theme.palette.error.main,
        boxShadow: `0 0 0 2px #417199`,
        backgroundColor: theme.palette.error.main,
        '&::after': {
            position: 'absolute',
            top: -1,
            left: -1,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            transform: 'scale(.6)',
            border: '1px solid currentColor',
            content: '""',
        },
    }
}));

/**
 * UserMenu component.
 *
 * @returns {*}
 * @constructor
 */
const UserMenu = () => {
    const navigate = useNavigate();
    const [isLoading, setLoading] = useState(false);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const {user, isScope, onAuthUpdate, isInviteCompleted, logout, hasPermissionTo} = useAuth();

    /**
     * Handles user logout.
     */
    const handleLogout = () => {
        logout().then(() => {
            navigate('/login');
        });
    };


    /**
     * Reveals the user navigation popover.
     */
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };


    /**
     * Closes the user navigation popover.
     */
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };


    /**
     * Updates the users' active status.
     *
     * @returns {Promise<void>}
     */
    const handleStatusToggle = async () => {
        setLoading(true);
        const response = await API.put(`users/${user.id}`, {
            isOffline: !user.isOffline,
        });

        if (response && response.id) {
            onAuthUpdate({
                isOffline: response.isOffline
            });
        }
        setLoading(false);
    };


    /**
     * Indicates whether the user is currently online.
     *
     * @type {boolean}
     */
    const isOnline = !user.isOffline;

    return (
        <Box className={'user__menu'}>
            {isScope('User') && (
                <>
                    {hasPermissionTo('VIEW_COMMUNICATIONS') && (
                        <CallNotifications/>
                    )}

                    {hasPermissionTo('VIEW_EMPLOYEES') && (
                        <NotificationBell/>
                    )}
                </>
            )}
            <Tooltip title={'Open settings'}>
                <IconButton onClick={handleOpenUserMenu} sx={{p: 0}}>
                    {isScope('Employee') ? (
                        <ImageWrapper
                            src={API.getFilePath(user.image)}
                            width={32}
                            height={32}
                        />
                    ) : (
                        isOnline ? (
                            <StyledBadgeActive
                                color={'error'}
                                variant={'dot'}
                                children={
                                    <ImageWrapper
                                        src={API.getFilePath(user.image)}
                                        width={32}
                                        height={32}
                                    />
                                }
                            />
                        ) : (
                            <StyledBadgeAway
                                color={'error'}
                                variant={'dot'}
                                children={
                                    <ImageWrapper
                                        src={API.getFilePath(user.image)}
                                        width={32}
                                        height={32}
                                    />
                                }
                            />
                        )
                    )}
                </IconButton>
            </Tooltip>
            <Menu
                sx={{mt: '45px'}}
                open={Boolean(anchorElUser)}
                onClose={handleCloseUserMenu}
                anchorEl={anchorElUser}
                keepMounted
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                {isScope('Employee') && (
                    !isInviteCompleted() ? (
                        <MenuItem
                            onClick={() => navigate('/invitation')}
                            disabled={isLoading}
                            children={<Typography textAlign="center">My Profile</Typography>}
                        />
                    ) : (
                        <MenuItem
                            onClick={() => navigate('/profile')}
                            disabled={isLoading}
                            children={<Typography textAlign="center">My Profile</Typography>}
                        />
                    )
                )}

                {isScope('User') && [
                    <MenuItem
                        key={'user-menu__profile'}
                        onClick={() => navigate('/settings')}
                        disabled={isLoading}
                        children={<Typography textAlign="center">My Profile</Typography>}
                    />,
                    <MenuItem
                        key={'user-menu__status'}
                        onClick={handleStatusToggle}
                        disabled={isLoading}
                    >
                        <Typography textAlign="center">Status:
                            {isOnline ? (
                                <b
                                    style={{color: '#2e7d32'}}
                                    children={'Available'}
                                    className={'ml__1'}
                                />
                            ) : (
                                <b
                                    style={{color: colorMap.away}}
                                    children={'Away'}
                                    className={'ml__1'}
                                />
                            )}
                        </Typography>
                    </MenuItem>,
                    <Divider key={'user-menu__divider'}/>
                ]}

                <MenuItem
                    onClick={handleLogout}
                    disabled={isLoading}
                    children={
                        <Typography textAlign="center">Logout</Typography>
                    }
                />
            </Menu>
        </Box>
    );
};

export default UserMenu;