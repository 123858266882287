import React from "react";

import App from "../../../Global/App";
import Formatter from '../../../Global/Formatter';

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import LogoutIcon from '@mui/icons-material/Logout';
import EditNoteIcon from '@mui/icons-material/EditNote';
import {useNavigate} from "react-router";
import CelebrationIcon from '@mui/icons-material/Celebration';

/**
 * PipelineEmployeeInviteIncomplete component.
 *
 * @returns {*}
 * @constructor
 */
const PipelineEmployeeInviteIncomplete = (props) => {
    const {
        employee            // {Object} The employee record object from the API.
    } = props;

    const navigate = useNavigate();

    /**
     * Indicates whether the employee has completed the invitation process.
     *
     * @type {boolean}
     */
    const isCompleted = !!employee.inviteCompletedDate;


    /**
     * Closes the app window.
     */
    const handleAppClose = () => {
        if (!App.isCordova()) {
            return;
        }

        if (!window.navigator || !window.navigator.app) {
            return;
        }

        window.navigator.app.exitApp();
    }

    return (
        <Box className={'columns__1'}>
            <Box
                data-aos={'fade-right'}

                children={<img src={Formatter.url('assets/images/logo.png')}/>}
                className={'recruiting-logo__image'}
            />

            {isCompleted ? (
                <>
                    <Alert
                        data-aos={'fade-right'}
                        data-aos-delay={250}

                        icon={<CelebrationIcon/>}
                        severity={'success'}
                    >
                        <b>Your invitation has been completed!</b> Your recruiter will review your
                        answers in the next 24 hours and will prompt you to the next step in the hire process.
                        No further action is necessary, you can now minimize or close the app until you hear back.
                    </Alert>

                    {App.isAndroid() ? (
                        <Button
                            data-aos={'fade-right'}
                            data-aos-delay={500}

                            size={'large'}
                            variant={'outlined'}
                            onClick={handleAppClose}
                            children={'Click to Close'}
                            startIcon={<LogoutIcon/>}
                            className={'text__undecorated'}
                        />
                    ) : (

                        /**
                         * For iOS, we unfortunately don't have the same capability. Instead, we'll just
                         * show a link to review the answers rather than close the app.
                         *
                         * @see https://stackoverflow.com/a/45187209
                         */
                        <Button
                            data-aos={'fade-right'}
                            data-aos-delay={500}

                            size={'large'}
                            variant={'outlined'}
                            onClick={() => navigate('/invitation')}
                            children={'Review My Answers'}
                            startIcon={<EditNoteIcon/>}
                        />
                    )}
                </>
            ) : (
                <>
                    <Box
                        data-aos={'fade-right'}
                        data-aos-delay={250}

                        className={'columns__1'}
                    >
                        <Box>
                            Welcome, and congratulations on taking the next step in your career! We're excited and
                            honored
                            to have you take part in our interview process.
                        </Box>
                        <Box>
                            Before we get started, we'll need to have you complete and sign each step of our detailed
                            application form. To begin, use the button below.
                        </Box>
                    </Box>

                    <Button
                        data-aos={'fade-right'}
                        data-aos-delay={500}

                        size={'large'}
                        variant={'outlined'}
                        onClick={() => navigate('/invitation')}
                        children={'Complete My Profile'}
                        startIcon={<EditNoteIcon/>}
                    />
                </>
            )}
        </Box>
    );
};

export default PipelineEmployeeInviteIncomplete;