import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import SFX from "../../Global/SFX";
import State from "../../Global/State";
import Logger from "../../Global/Logger";
import ChatFeed from "./ChatFeed";
import Settings from "../../Global/Settings";
import {useAuth} from "../../Global/Auth";
import usePhoneCall from "../../Hooks/usePhoneCall";
import usePhoneDevice from "../../Hooks/usePhoneDevice";
import CommunicationFeed from "./CommunicationFeed";
import InboundCallDialog from "./InboundCallDialog";

import Fab from "@mui/material/Fab";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Badge from "@mui/material/Badge";
import Dialog from "@mui/material/Dialog";
import {styled} from "@mui/material";
import ChatIcon from '@mui/icons-material/Chat';
import PhoneInTalkIcon from '@mui/icons-material/PhoneInTalk';
import PhoneDisabledIcon from '@mui/icons-material/PhoneDisabled';

/**
 * StyledBadge component.
 *
 * @type {Component}
 * @description These are the badge styles for the unread / ripple badge over the chat icon.
 */
const StyledBadge = styled(Badge)(({theme}) => ({
    '& .MuiBadge-badge': {
        color: theme.palette.error.main,
        backgroundColor: theme.palette.error.main,
        boxShadow: `0 0 0 2px ${theme.palette.primary.main}`,
        '&::after': {
            position: 'absolute',
            top: -1,
            left: -1,
            width: '100%',
            height: '100%',
            borderRadius: '50%',
            animation: 'ripple 1.2s infinite ease-in-out',
            border: '1px solid currentColor',
            content: '""',
        },
    },
    '@keyframes ripple': {
        '0%': {
            transform: 'scale(.8)',
            opacity: 1,
        },
        '100%': {
            transform: 'scale(2.4)',
            opacity: 0,
        },
    },
}));


/**
 * ChatButton component.
 *
 * @returns {*}
 * @constructor
 */
const ChatButton = () => {

    const {inbound} = usePhoneDevice();
    const [isOpen, setOpen] = useState(false);
    const [hasUnread, setHasUnread] = useState(!!State.get('messages-unread'));
    const [unreadCount, setUnreadCount] = useState(0);
    const [soundEffect, setSoundEffect] = useState(null);
    const {phoneCall, isMuted, recipient} = usePhoneCall();
    const [isInitialized, setInitialized] = useState(false);
    const [isLoadingUnread, setLoadingUnread] = useState(false);
    const [defaultSelection, setDefaultSelection] = useState(null);
    const {user, isScope, isHired, isInviteCompleted, hasPermissionTo} = useAuth();

    /**
     * Initialize the timer loop on mount.
     */
    useEffect(() => {
        doInitialize();

        const interval = setInterval(() => {
            setInitialized(true);
            getUnread();
        }, 5000);

        return () => clearInterval(interval);
    }, []);


    /**
     * Plays the sound effect whenever we have a new unread message.
     */
    useEffect(() => {
        Logger.debug('[ChatButton] Received unread update', unreadCount, isInitialized);

        if (!isInitialized) {
            return;
        }

        if (unreadCount) {
            doSound();
        }
    }, [unreadCount]);


    /**
     * Listens for any phone call updates.
     */
    useEffect(() => {
        Logger.debug('[ChatButton] Received updated call event:', phoneCall);
    }, [phoneCall]);


    /**
     * Initializes the audio effect against the unread counter.
     *
     * @returns {Promise<void>}
     */
    const doInitialize = async () => {
        await getUnread();
        doLoadAudio();
    };


    /**
     * Initializes the audio effect.
     */
    const doLoadAudio = () => {
        if (!isScope('User')) {
            return;
        }

        const settings = user.settings || {};

        // Verify that we have everything we need to emit notifications.
        if (!settings['US_NOTIFICATION_ENABLED'] || !settings['US_NOTIFICATION_SFX']) {
            return;
        }

        // Attempt to isolate the selected sound effect.
        const sfx = SFX[settings['US_NOTIFICATION_SFX']];

        if (sfx && sfx.audio) {
            Logger.debug(`[ChatButton] Initialized sound effect ${sfx.label}`);
            setSoundEffect(sfx.audio);
        }
    };


    /**
     * Plays the configured sound effect.
     */
    const doSound = () => {
        if (!isScope('User')) {
            return;
        }

        if (!soundEffect) {
            return;
        }

        try {
            soundEffect.play();
        } catch (e) {
            Logger.warn('[ChatButton] Failed to play sound effect', e);
        }
    };


    /**
     * Checks to see whether there are any unread messages.
     *
     * @returns {Promise<void>}
     */
    const getUnread = async () => {

        // Prevent accidental stampede.
        if(isLoadingUnread){
            return;
        }

        setLoadingUnread(true);
        const types = isScope('Employee') ? 'Chat' : 'SMS,Chat';
        const results = await API.get('grouped-messages/count', {
            $filter: `type in {${types}}`,
        });

        State.set('messages-unread', !!results.count ? 'true' : '');
        setHasUnread(!!results.count);
        setUnreadCount(results.count);
        setLoadingUnread(false);
    };


    /**
     * Reveals the chat feature.
     */
    const handleOpen = () => {
        setOpen(true);
        setDefaultSelection(null);
        getUnread();
    };


    /**
     * Closes the chat feature.
     */
    const handleClose = () => {
        setOpen(false);
        getUnread();
    };


    /**
     * Jumps to the active call.
     */
    const handleCallJump = () => {
        const activeCall = Settings.activeCall;
        setDefaultSelection(activeCall.selectedEmployee || null);
        handleOpen();
    };

    return (
        <>
            <Box className={'message__icon'}>
                {phoneCall && (
                    <Chip
                        icon={!isMuted ? <PhoneInTalkIcon/> : <PhoneDisabledIcon/>}
                        label={recipient.displayName || 'Caller unavailable.'}
                        onClick={handleCallJump}
                        clickable
                        className={'mr__2 call__chip'}
                    />
                )}

                {isScope('User') && inbound && (
                    <InboundCallDialog/>
                )}

                {(isScope('Employee') || hasPermissionTo('VIEW_COMMUNICATIONS')) && (
                    <Fab
                        size={'large'}
                        color={'primary'}
                        onClick={handleOpen}
                    >
                        <StyledBadge
                            color={'error'}
                            variant={'dot'}
                            children={<ChatIcon/>}
                            invisible={!hasUnread}
                        />
                    </Fab>
                )}
            </Box>

            {isScope('User') && (
                <Dialog
                    open={isOpen}
                    scroll={'body'}
                    onClose={handleClose}
                    maxWidth={'xl'}
                    fullWidth
                >
                    <CommunicationFeed
                        hasUnread={hasUnread}
                        defaultSelection={defaultSelection}
                    />
                </Dialog>
            )}

            {isScope('Employee') && (
                <Dialog
                    open={isOpen}
                    scroll={'body'}
                    onClose={handleClose}
                    maxWidth={'md'}
                    fullWidth
                >
                    {!isHired() || !isInviteCompleted() ? (
                        <ChatFeed
                            onClose={handleClose}
                            hasUnread={hasUnread}
                            defaultSelectedId={user.assigneeId}
                        />
                    ) : (
                        <ChatFeed
                            onClose={handleClose}
                            hasUnread={hasUnread}
                        />
                    )}
                </Dialog>
            )}
        </>
    );
};

export default ChatButton;