import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import {useAuth} from "../../../Global/Auth";
import ImageWrapper from "../../../Components/ImageWrapper";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import {useNavigate} from "react-router";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import ListItemButton from "@mui/material/ListItemButton";

/**
 * CourseCompletionStep component.
 *
 * @constructor
 */
const CourseCompletionStep = (props) => {
    const {
        id,             // {String} The ID of the current course.
    } = props;

    const {user} = useAuth();
    const navigate = useNavigate();
    const [isLoaded, setLoaded] = useState(false);
    const [hasMoreCourses, setHasMoreCourses] = useState(true);
    const [incompleteCourses, setIncompleteCourses] = useState([]);

    /**
     * Used to determine whether we've already completed the courses or not.
     */
    useEffect(() => {
        getCourseResults();
    }, []);


    /**
     * Navigate to the home back if we no longer have any courses.
     */
    useEffect(() => {
        if (!hasMoreCourses && !user.seenConfetti) {
            navigate('/');
        }
    }, [hasMoreCourses]);


    /**
     * Compares the total number of courses versus completions.
     *
     * @returns {Promise<void>}
     */
    const getCourseResults = async () => {
        const completions = await API.get('course-completions', {
            $top: 100,
            $filter: `employeeId in {${user.id}}`
        });

        const courses = await API.get('courses', {
            $top: 100,
            $filter: `isPublished eq {1} and isDeleted eq {0} and id ne {${id}}`
        });

        // Determine whether any of the courses are incomplete.
        const incompleteCourses = courses.filter(course => {
            return !completions.some(completion => completion.courseId === course.id);
        });

        setLoaded(true);
        setIncompleteCourses(incompleteCourses);
        setHasMoreCourses(incompleteCourses.length > 0);
    };

    return !isLoaded ? (
        <Box className={'p__3 columns__1'}>
            <Skeleton height={'6em'}/>

            <List>
                {Array.from(Array(1).keys()).map(i => {
                    return (
                        <ListItemButton disabled disableGutters>
                            <Box className={'d-flex__justify w__100'}>
                                <Box className={'d-flex__start'}>
                                    <Box width={48} height={48} className={'mr__2'}>
                                        <Skeleton variant={'rounded'} height={48} width={48}/>
                                    </Box>
                                    <Box>
                                        <Skeleton width={150 + Math.floor(Math.random() * 50)}/>
                                        <Box>
                                            <Skeleton width={50 + Math.floor(Math.random() * 50)}/>
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </ListItemButton>
                    );
                })}
            </List>

            <Skeleton height={'3em'} width={'100%'}/>
        </Box>
    ) : (
        <Box className={'p__3 columns__1'}>
            {hasMoreCourses ? (
                <>
                    <Box>
                        <b>Congratulations!</b> You've completed this training module. Select your next module from the list
                        below, or use the "Back" button below to return to the course listing
                    </Box>

                    <List>
                        {incompleteCourses.slice(0, 4).map(course => (
                            <ListItemButton
                                onClick={() => navigate(`/training/course/${course.id}`)}
                                disableGutters
                            >
                                <Box className={'d-flex__justify w__100'}>
                                    <Box className={'d-flex__start'}>
                                        <ImageWrapper
                                            src={API.getFilePath(course.image)}
                                            icon={<CameraAltIcon fontSize={'medium'}/>}
                                            width={48}
                                            height={48}
                                            className={'mr__2'}
                                            horizontal
                                        />
                                        <Box>
                                            <b>{course.name}</b>
                                            <Box className={'text__small text__light'}>
                                                {course.abbreviation}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Chip label={'Incomplete'} size={'small'} color={'warning'}/>
                                </Box>
                            </ListItemButton>
                        ))}
                    </List>
                </>
            ) : (
                <Box>
                    <b>Congratulations!</b> You've completed this training module. Use the "Back" button below to
                    return to the course listing.
                </Box>
            )}

            <Button
                variant={'outlined'}
                onClick={() => navigate('/employee/training')}
                children={'Back'}
            />
        </Box>
    );
};

export default CourseCompletionStep;