import React from "react";

import Department from "../../Models/Department";
import TableLayout from "../../Components/Layouts/TableLayout";

import GroupsIcon from '@mui/icons-material/Groups';

/**
 * Departments component.
 *
 * @returns {*}
 * @constructor
 */
const Departments = () => (
    <TableLayout
        icon={<GroupsIcon/>}
        model={Department}
        heading={'Departments'}
        condensed
        hideFilters
        formPermission={'EDIT_LIBRARIES'}
    />
);

export default Departments;