import React, {useMemo} from "react";

import Panel from "../Panel";
import DataTable from "../Layouts/Index/DataTable";
import UserCallLog from "../../Models/UserCallLog";
import CallRouteDropdown from "./CallLogFeed/CallRouteDropdown";

import Box from "@mui/material/Box";

/**'
 * CallLogFeed component.
 *
 * @returns {*}
 * @constructor
 */
const CallLogFeed = (props) => {
    const {
        title,          // {Component} An optional title / heading replacement component.
        className,      // {String} An optional class to apply to the outer container.
    } = props;

    /**
     * The inner widget content.
     *
     * @type {unknown}
     */
    const content = useMemo(() => (
        <DataTable
            model={UserCallLog}
            query={UserCallLog.getQuery()}
            actions={!!UserCallLog.action}
            hideHeader
        />
    ), []);

    return (
        <Panel
            header={title ? (
                <Box className={'d-flex__justify w__100'}>
                    {title}

                    <Box className={'d-flex__start'}>
                        <CallRouteDropdown/>

                    </Box>
                </Box>
            ) : null}
            padding={0}
            heading={!title ? 'Recent Calls' : ''}
            className={className}
        >
            <Box
                style={{padding: 0}}
                className={'notifications__body'}
            >
                {content}
            </Box>
        </Panel>
    );
};

export default CallLogFeed;