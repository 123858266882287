import React, {useEffect, useState} from "react";

import Step from "../../../Components/Step";
import {useAuth} from "../../../Global/Auth";
import LogoHeading from "../../../Components/Typography/LogoHeading";
import StepControls from "../../../Components/StepControls";
import InputSignature from "../../../Components/Input/InputSignature";

import Box from "@mui/material/Box";

/**
 * NonCompeteStep component.
 *
 * @returns {*}
 * @constructor
 */
const NonCompeteStep = (props) => {
    const {
        step,               // {Number} The current step for the user.
        index,              // {Number} The expected step number.
        onClose,            // {Function} An optional method for closing the form if it appears in a dialog.
        employee,           // {Object} The employee record for read only context.
        readOnly,           // {Boolean} Whether the form should be read only.
        disabled,           // {Boolean} Whether the step is disabled from input.
        contentOnly,        // {Boolean} Whether to only render the content.
        onStepChange        // {Function} The on change callback to load the next step.
    } = props;

    const [isLoaded, setLoaded] = useState(false);
    const [signature, setSignature] = useState('');
    const {setUserSetting, getUserSettingValue, getUserSettingValueForEmployee} = useAuth();

    /**
     * Loads the required settings for this step.
     */
    useEffect(() => {
        handleSettingsLoad();
    }, []);


    /**
     * Synchronize the job description signature.
     */
    useEffect(() => {
        if(!readOnly && isLoaded){
            setUserSetting('HP_NON_COMPETE_DISCLOSURE', signature)
        }
    }, [signature]);


    /**
     * Loads each of the appropriate user settings.
     *
     * @returns {Promise<void>}
     */
    const handleSettingsLoad = async () => {
        setSignature(
            readOnly ?
                await getUserSettingValueForEmployee(employee.id, 'HP_NON_COMPETE_DISCLOSURE') :
                await getUserSettingValue('HP_NON_COMPETE_DISCLOSURE')
        );

        setLoaded(true);
    };


    /**
     * The disabled state of the form.
     */
    const isDisabled = readOnly || disabled;

    return (
        <Step step={index} value={step}>
            <LogoHeading
                title={'Non-Compete Disclosure'}
                onClose={onClose}
            />

            <Box className={'columns__1'}>
                <Box>
                    Reliant Staffing is a medical staffing company whose product is providing its employees to
                    work for its clients. By working for Reliant, the employee acknowledges that Reliant has a
                    legitimate business interest in protecting its product from being hired by individuals,
                    business entities, or facilities that Reliant sends Employees to work for during or
                    immediately after Reliant's employment of the Employee.
                </Box>

                <Box>
                    Employee, during his/her employment by Reliant, shall not inquire about, apply for, or
                    accept employment offers to work for any individual, business entity, or facility in which
                    Reliant actively places or has previously placed the Employee. Upon the end of the
                    Employee's employment by Reliant, Employee shall not work for any individual, business
                    entity, or facility where Reliant has previously placed the Employee, as well as for another
                    staffing agency that places the Employee to individuals, business entity, or facilities that
                    Reliant has previously placed the Employee for a period of one year. This one-year
                    restriction applies in the county and all immediately contiguous counties, regardless of
                    which state the county lies, which Employee last worked in on Reliant's behalf. Pennsylvania
                    law, without reference to Pennsylvania's conflict-of-law rules, applies to this non-compete
                    clause.
                </Box>

                <Box>
                    Employee agrees that violating this non-compete clause irreparably harms Reliant and that
                    Reliant is entitled to, at a minimum, injunctive relief from a court of proper jurisdiction
                    to remedy Employee's violation of this non-compete clause. In addition, upon a judicial
                    determination that Employee violated this non-compete clause, Employee shall pay Reliant's
                    reasonable attorneys' fees that Reliant incurs to enforce this non-compete clause.
                </Box>

                {!contentOnly && (
                    <>
                        <InputSignature
                            key={2}
                            value={signature}
                            label={'Please sign here:'}
                            disabled={isDisabled}
                            onChange={setSignature}
                            scaleFactor={2}
                        />

                        {!readOnly && (
                            <StepControls
                                step={step}
                                disabled={isDisabled || !signature}
                                onStepChange={onStepChange}
                            />
                        )}
                    </>
                )}
            </Box>
        </Step>
    );
};

export default NonCompeteStep;