import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import {useAuth} from "../../../Global/Auth";
import Formatter from "../../../Global/Formatter";
import GroupChatDialog from "../../../Components/Widgets/GroupChatDialog";
import EmployeeSelection from "../../../Components/Input/EmployeeSelection";
import AssignedEmployeesTable from "./ClientSchedule/AssignedEmployeesTable";
import AssignedAvailabilityNotes from "./AssignedAvailabilityNotes";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import {DatePicker} from "@mui/x-date-pickers";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DateRangeIcon from '@mui/icons-material/DateRange';
import LinearProgress from "@mui/material/LinearProgress";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

/**
 * ClientSchedule component.
 *
 * @returns {*}
 * @constructor
 */
const ClientSchedule = (props) => {
    const {
        record,         // {Object} The client record for context.
        doReload,       // {Function} A reload callback to refresh the layout.
    } = props;

    const {hasPermissionTo} = useAuth();
    const [isLoaded, setLoaded] = useState(false);
    const [endDate, setEndDate] = useState(record.scheduleToDate || '');
    const [isLoading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [selection, setSelection] = useState([]);
    const [startDate, setStartDate] = useState(record.scheduleFromDate || '');
    const [isSendingMessage, setSendingMessage] = useState(false);
    const [isChangingPeriod, setChangingPeriod] = useState(false);
    const [isSelectingEmployees, setSelectingEmployees] = useState(false);
    const [isViewingAvailability, setViewingAvailability] = useState(false);

    /**
     * The message heading content for the bulk message dialog.
     *
     * @type {string}
     */
    const messageHeading = `Send Message${selection.length ? ` (${selection.length})` : ''}`;


    /**
     * Loads all required data on mount.
     */
    useEffect(() => {
        doLoad();
    }, []);


    /**
     * Stores the updated date values.
     */
    useEffect(() => {
        syncDates();
    }, [endDate, startDate]);


    /**
     * Loads all ancillary data.
     *
     * @returns {Promise<void>}
     */
    const doLoad = async () => {
        await getExistingEmployees();

        // Transfer initial props if available.
        if (record && record.id) {
            setEndDate(record.scheduleToDate);
            setStartDate(record.scheduleFromDate);
        }

        setLoaded(true);
    };


    /**
     * Synchronizes the client record using the selected dates.
     *
     * @returns {Promise<void>}
     */
    const syncDates = async () => {
        if (!isLoaded) {
            return;
        }

        await API.put(`clients/${record.id}`, {
            scheduleToDate: endDate ? dayjs.utc(endDate).format('YYYY-MM-DD') : '',
            scheduleFromDate: startDate ? dayjs.utc(startDate).format('YYYY-MM-DD') : '',
        });

        if (doReload) {
            doReload();
        }
    };


    /**
     * Loads all existing relationships.
     *
     * @returns {Promise<void>}
     */
    const getExistingEmployees = async () => {
        const related = await API.get('employee-clients', {
            $top: 1000,
            $filter: `clientId in {${record.id}} and employee/any{isActive eq {1} and isDeleted eq {0}}`
        });

        if(related && related.length){
            await handleEmployeeChange(related.map(result => {
                return {
                    id: result.employeeId
                }
            }))
        }
    };


    /**
     * Reveals the employee selection dialog.
     */
    const handleEmployeeSelectOpen = () => {
        setSelectingEmployees(true);
    };


    /**
     * Closes the employee selection dialog.
     */
    const handleEmployeeSelectClose = () => {
        setSelectingEmployees(false);
    };


    /**
     * Reveals the period change dialog.
     */
    const handlePeriodChangeOpen = () => {
        setChangingPeriod(true);
    };


    /**
     * Closes the period change dialog.
     */
    const handlePeriodChangeClose = () => {
        setChangingPeriod(false);
    };


    /**
     * Reveals the period change dialog.
     */
    const handleAvailabilityNotesOpen = () => {
        setViewingAvailability(true);
    };


    /**
     * Closes the period change dialog.
     */
    const handleAvailabilityNotesClose = () => {
        setViewingAvailability(false);
    };


    /**
     * Reveals the bulk message dialog.
     */
    const handleMessageOpen = () => {
        setSendingMessage(true);
    };


    /**
     * Closes the bulk message dialog.
     */
    const handleMessageClose = () => {
        setSendingMessage(false);
    };


    /**
     * Assigns employees to the facility.
     *
     * @param employees
     */
    const handleEmployeeChange = async (employees) => {
        setLoading(true);

        // Fetch the employee selection.
        const results = await API.get('employees', {
            $top: 1000,
            $filter: `id in {${employees.map(employee => employee.id)}}`,
            $select: '*,lastAvailabilityDate,lastShiftDate',
            $expand: 'specialty, state, status',
            $orderby: 'lastName asc'
        });

        // Create the correct relationships to reload.
        const related = await API.get('employee-clients', {
            $top: 1000,
            $filter: `clientId in {${record.id}} and employee/any{isActive eq {1} and isDeleted eq {0}}`
        });

        const unsaved = results.filter(result => !related.filter(relation => relation.employeeId === result.id).length);
        const deleted = related.filter(relation => !results.filter(result => result.id === relation.employeeId).length);

        await Promise.all([
            Promise.all(
                unsaved.map(async result => await API.post('employee-clients', {
                    clientId: record.id,
                    employeeId: result.id
                }))
            ),
            Promise.all(
                deleted.map(async result => await API.delete(`employee-client/${result.id}`))
            )
        ]);

        setEmployees(results);
        setLoading(false);

        if (doReload) {
            doReload();
        }
    };


    /**
     * Indicates if the component is disabled.
     *
     * @type {boolean}
     */
    const isDisabled = isLoading || !isLoaded;

    return (
        <>
            <Box>
                <Box className={'d-flex__justify p__3'}>
                    <Box className={'mt__0 mb__0 d-flex__start'}>
                        <CalendarMonthIcon className={'mr__1'}/>
                        <b>Current Schedule:&nbsp;</b>
                        {startDate && endDate ? (
                            <>
                                {Formatter.date(startDate, '', false)} - {Formatter.date(endDate, '', false)}
                            </>
                        ) : (
                            <Box className={'text__light ml__1'}>
                                (not set)
                            </Box>
                        )}

                        {hasPermissionTo('EDIT_CLIENTS') && (
                            <IconButton
                                size={'small'}
                                color={'primary'}
                                onClick={handlePeriodChangeOpen}
                                disabled={!isLoaded}
                                children={<DateRangeIcon fontSize={'small'}/>}
                                className={'ml__1'}
                            />
                        )}
                    </Box>

                    <Box className={'d-flex'}>
                        {hasPermissionTo('VIEW_COMMUNICATIONS') && (
                            <Button
                                onClick={handleMessageOpen}
                                variant={'outlined'}
                                disabled={isDisabled || !selection.length}
                                children={messageHeading}
                                className={'mr__2'}
                            />
                        )}

                        {hasPermissionTo('EDIT_CLIENTS') && (
                            <Button
                                onClick={handleEmployeeSelectOpen}
                                variant={'outlined'}
                                disabled={isDisabled}
                                children={'Assign Employees'}
                                className={'mr__2'}
                            />
                        )}
                        {hasPermissionTo('VIEW_SCHEDULE') && (
                            <Button
                                onClick={handleAvailabilityNotesOpen}
                                variant={'outlined'}
                                disabled={isDisabled}
                                className={'mr__2'}
                                children={'Availability'}
                            />
                        )}
                    </Box>
                </Box>
                <Divider/>

                {/*Only display the progress bar on initial load.*/}
                {isLoading && !employees.length && <LinearProgress/>}

                {!employees.length ? (
                    <Box className={'text__center text__disclaimer p__3'}>
                        No selected employees.
                    </Box>
                ) : (
                    <AssignedEmployeesTable
                        endDate={endDate}
                        onChange={setSelection}
                        employees={employees}
                        selectable={hasPermissionTo('VIEW_COMMUNICATIONS')}
                    />
                )}
            </Box>

            <Dialog
                open={isChangingPeriod}
                scroll={'body'}
                onClose={handlePeriodChangeClose}
                maxWidth={'sm'}
                fullWidth
            >
                <DialogContent>
                    <Box className={'columns__1'}>
                        <InputLabel
                            children={'Change Period'}
                            className={'form__heading form__heading--standalone'}
                        />

                        <Box className={'columns__2'}>
                            <DatePicker
                                label={'Start Date'}
                                value={startDate ? dayjs(startDate) : null}
                                disabled={isDisabled}
                                onChange={event => setStartDate(event && event.isValid() ? event.format('YYYY-MM-DD') : '')}
                            />

                            <DatePicker
                                label={'End Date'}
                                value={endDate ? dayjs(endDate) : null}
                                disabled={isDisabled}
                                onChange={event => setEndDate(event && event.isValid() ? event.format('YYYY-MM-DD') : '')}
                            />
                        </Box>
                    </Box>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={handlePeriodChangeClose}
                        children={'Close'}
                    />
                </DialogActions>
            </Dialog>

            <GroupChatDialog
                open={isSendingMessage}
                onClose={handleMessageClose}
                selection={selection}
            />

            {isSelectingEmployees && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handleEmployeeSelectClose}
                    maxWidth={'md'}
                    fullWidth
                >
                    <DialogContent sx={{padding: 0}}>
                        <EmployeeSelection
                            filter={search => `isActive eq {1} and isDeleted eq {0} and displayName eq {${search}}`}
                            checkbox
                            onChange={handleEmployeeChange}
                            selection={employees.map(employee => employee.id)}
                        />
                    </DialogContent>
                </Dialog>
            )}

            {isViewingAvailability && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handleAvailabilityNotesClose}
                    maxWidth={'md'}
                    fullWidth
                >
                    <AssignedAvailabilityNotes record={record}/>
                </Dialog>
            )}
        </>
    );
};

export default ClientSchedule;