import React from "react";

import Model from "../Definitions/Model";
import Settings from "../Global/Settings";
import Property from "../Definitions/Property";
import BoundSwitch from "../Components/Input/BoundSwitch";
import PropertyMap from "../Definitions/PropertyMap";
import DocumentTypeForm from "../Actions/DocumentTypeActions/DocumentTypeForm";
import DocumentTypeActions from "../Actions/DocumentTypeActions";

import {faker} from '@faker-js/faker';

/**
 * DocumentType model.
 *
 * @type {Object}
 */
const DocumentType = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'document-type',


    /**
     * The appropriate API endpoint root.
     */
    route: 'document-types',


    /**
     * Additional query parameters to share throughout the application.
     */
    query: {
        $filter: 'isDeleted eq {0}'
    },


    /**
     * The form component.
     *
     * @returns {*}
     * @param parameters
     */
    form: (parameters) => <DocumentTypeForm {...parameters}/>,


    /**
     * The menu action.
     *
     * @param parameters
     * @returns {*}
     */
    action: (parameters) => <DocumentTypeActions {...parameters}/>,


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'name',
        'isRecruiting',
        'hasExpiration'
    ],


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'name',
            width: '33%',
            label: 'Name',
            render: (record) => record.name,
            sortable: true
        }),
        Property.create({
            key: 'isRecruiting',
            width: '33%',
            label: 'Required for Recruiting?',
            render: (record, doReload) => (
                <BoundSwitch
                    model={DocumentType}
                    field={'isRecruiting'}
                    record={record}
                    doReload={doReload}
                    permission={'EDIT_LIBRARIES'}
                />
            ),
            sortable: true
        }),
        Property.create({
            key: 'hasExpiration',
            width: '33%',
            label: 'Expiration Date',
            render: (record) => record.hasExpiration && record.expirationLength ?
                `${record.expirationLength} ${Number(record.expirationLength) > 1 ? `${record.expirationTerm}s` : record.expirationTerm}` :
                Settings.emptyContentLabel,
            sortable: true
        })
    ])
};

export default DocumentType;