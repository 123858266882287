import React from "react";

import TableLayout from "../../Components/Layouts/TableLayout";
import DocumentType from "../../Models/DocumentType";

import LibraryBooksIcon from '@mui/icons-material/LibraryBooks';

/**
 * DocumentTypes component.
 *
 * @returns {*}
 * @constructor
 */
const DocumentTypes = () => (
    <TableLayout
        icon={ <LibraryBooksIcon/>}
        model={DocumentType}
        heading={'Document Types'}
        condensed
        hideFilters
        formPermission={'EDIT_LIBRARIES'}
    />
);

export default DocumentTypes;