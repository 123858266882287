import React, {useRef, useState} from "react";

import API from "../../../Global/API";
import Client from "../../../Models/Client";
import Employee from "../../../Models/Employee";
import Formatter from "../../../Global/Formatter";
import ModelSearch from "../../../Components/Input/ModelSearch";
import ImageWrapper from "../../../Components/ImageWrapper";
import SectionHeading from "../../../Components/Typography/SectionHeading";
import EmployeePreview from "./Components/EmployeePreview";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {DatePicker} from "@mui/x-date-pickers";
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";

/**
 * The number of results or page to sequentially pull data.
 *
 * @type {number}
 */
const perPageLimit = 250;


/**
 * BillableHours component.
 *
 * @returns {*}
 * @constructor
 */
const BillableHours = (props) => {
    const {
        loading,            // {Boolean} Indicates if the report is loading.
        onComplete          // {Function} A post-completion callback from whenever the report has results.
    } = props;

    const resultsRef = useRef(null);
    const [clients, setClients] = useState([]);
    const [endDate, setEndDate] = useState(dayjs().endOf('week').format('YYYY-MM-DD'));
    const [isLoading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().startOf('week').format('YYYY-MM-DD'));
    const [showResults, setShowResults] = useState(false);
    const [clientResults, setClientResults] = useState([]);
    const [employeeResults, setEmployeeResults] = useState([]);

    /**
     * Loads all of our client rows.
     *
     * @returns {Promise<void>}
     */
    const getAllClients = async () => {
        let promises = [];

        // Determine the filter criteria.
        let eventFilters = [
            'isBlockRequested eq {0}',
            'employee/any{isDeleted eq {0}}',
        ];
        eventFilters.push(endDate ? `startDate lt {${endDate}}` : ``);
        eventFilters.push(startDate ? `startDate gt {${startDate}}` : ``);
        eventFilters = eventFilters.filter(filter => !!filter.trim());

        // Filter specific clients, if applicable.
        let clientSelection = clients && clients.length ? ` and id in {${clients.map(client => client.id).join(',')}}` : '';
        let employeeSelection = employees && employees.length ? ` and employeeId in {${employees.map(employee => employee.id).join(',')}}` : '';

        // Load all the clients page by page until we've reached the end.
        const count = await API.get('clients/count', {
            $top: perPageLimit,
            $filter: `events/any{${eventFilters.join(' and ')}${employeeSelection}}${clientSelection}`,
        });

        for (let page = 0; page < count.pages; page++) {
            promises.push(
                API.get('clients', {
                    $top: perPageLimit,
                    $skip: page * perPageLimit,
                    endDate,
                    $filter: `events/any{${eventFilters.join(' and ')}${employeeSelection}}${clientSelection}`,
                    $select: '*,billableHours',
                    $orderby: `name asc`,
                    startDate
                })
            );
        }

        const results = await Promise.all(promises);
        setClientResults(results.flat());
    };


    /**
     * Loads all of our employee rows.
     *
     * @returns {Promise<void>}
     */
    const getAllEmployees = async () => {
        let promises = [];

        // Determine the filter criteria.
        let eventFilters = [
            'isBlockRequested eq {0}'
        ];
        eventFilters.push(endDate ? `startDate lt {${endDate}}` : ``);
        eventFilters.push(startDate ? `startDate gt {${startDate}}` : ``);
        eventFilters = eventFilters.filter(filter => !!filter.trim());

        // Filter specific clients, if applicable.
        let clientSelection = clients && clients.length ? ` and clientId in {${clients.map(client => client.id).join(',')}}` : '';
        let employeeSelection = employees && employees.length ? ` and id in {${employees.map(employee => employee.id).join(',')}}` : '';

        // Load all the clients page by page until we've reached the end.
        const count = await API.get('employees/count', {
            $top: perPageLimit,
            $filter: `events/any{${eventFilters.join(' and ')}${clientSelection}}${employeeSelection}`,
        });

        for (let page = 0; page < count.pages; page++) {
            promises.push(
                API.get('employees', {
                    $top: perPageLimit,
                    $skip: page * perPageLimit,
                    endDate,
                    $filter: `isDeleted eq {0} and events/any{${eventFilters.join(' and ')}${clientSelection}}${employeeSelection}`,
                    $select: '*,billableHours',
                    $orderby: `lastName asc, firstName asc`,
                    startDate,
                })
            );
        }

        const results = await Promise.all(promises);
        setEmployeeResults(results.flat());
    };


    /**
     * Loads all of our report data.
     *
     * @returns {Promise<void>}
     */
    const getResults = async () => {
        setLoading(true);

        await Promise.all([
            getAllClients(),
            getAllEmployees(),
        ]);

        setShowResults(true);
        setLoading(false);

        if(onComplete){
            onComplete(resultsRef);
        }
    };


    /**
     * Maps the client billable hour results.
     *
     * @type {*[]}
     */
    const clientBillableHours = clientResults.map(client => client.billableHours);


    /**
     * Maps the employee billable hour results.
     *
     * @type {*[]}
     */
    const employeeBillableHours = employeeResults.map(employee => employee.billableHours);


    /**
     * The total number of scheduled items.
     */
    const totalItems = employeeBillableHours.reduce((n, {items}) => n + items, 0);


    /**
     * The total hours of scheduled items.
     */
    const totalHours = employeeBillableHours.reduce((n, {hours}) => n + hours, 0);


    /**
     * The number of client cancelled hours.
     */
    const clientCancelledHours = clientBillableHours.reduce((n, {deductions}) => n + deductions, 0);


    /**
     * The number of employee cancelled hours.
     */
    const employeeCancelledHours = employeeBillableHours.reduce((n, {deductions}) => n + deductions, 0);


    /**
     * Indicates if the report is in progress.
     *
     * @type {boolean}
     */
    const inProgress = isLoading || loading;

    return (
        <Box className={'d-flex'}>
            <Box
                sx={{width: 400, overflowY: 'hidden'}}
                className={'border__right p__3 full__height--left-tabs'}
            >
                <Box className={'columns__1'}>
                    <Box className={'columns__2'}>
                        <DatePicker
                            label="Start Date"
                            value={startDate ? dayjs(startDate) : null}
                            disabled={inProgress}
                            onChange={event => setStartDate(event ? event.format('YYYY-MM-DD') : '')}
                            fullWidth
                        />

                        <DatePicker
                            label="End Date"
                            value={endDate ? dayjs(endDate) : null}
                            disabled={inProgress}
                            onChange={event => setEndDate(event ? event.format('YYYY-MM-DD') : '')}
                            fullWidth
                        />
                    </Box>

                    <ModelSearch
                        model={Client}
                        value={clients}
                        label={'Client(s)'}
                        multiple
                        disabled={inProgress}
                        onChange={setClients}
                        renderLabel={option => !option ? '' : option.name}
                        filterQuery={query => `isDeleted eq {0} and name eq {${query}}`}
                        queryParams={{
                            $top: 250,
                            $orderby: 'name asc'
                        }}
                    />

                    <ModelSearch
                        model={Employee}
                        value={employees}
                        label={'Employee(s)'}
                        multiple
                        disabled={inProgress}
                        onChange={setEmployees}
                        helperText={'(Leave blank to include all.)'}
                        renderLabel={option => !option ? '' : option.displayName}
                    />

                    <Button
                        variant={'outlined'}
                        onClick={getResults}
                        children={'Apply'}
                        disabled={inProgress}
                    />
                </Box>
            </Box>

            <Box
                sx={{borderRadius: 0}}
                className={`flex__grow full__height--left-tabs ${!showResults ? 'well__container' : ''}`}
            >
                {inProgress && <LinearProgress sx={{width: '100%'}}/>}

                {!showResults && (
                    <Box className={'p__3 text__center text__disclaimer'}>Your results will display here.</Box>
                )}

                {showResults && (
                    <Box className={'p__3 columns__1'}>
                        <Box>
                            <SectionHeading title={'Overview'} primary/>
                            <TableContainer className={'table table--striped result__set'}>
                                <Table size={'small'}>
                                    <colgroup>
                                        <col width={'40%'}/>
                                        <col width={'60%'}/>
                                    </colgroup>
                                    <TableBody>
                                        <TableRow>
                                            <TableCell>
                                                <b>Date (From)</b>
                                            </TableCell>
                                            <TableCell>
                                                {Formatter.date(startDate, '', false)}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>Date (To)</b>
                                            </TableCell>
                                            <TableCell>
                                                {Formatter.date(endDate, '', false)}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b># Scheduled Items</b>
                                            </TableCell>
                                            <TableCell>
                                                {Formatter.number(totalItems)}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>Total Hours Scheduled</b>
                                            </TableCell>
                                            <TableCell>
                                                {Formatter.number(totalHours)}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>Total Client Hours Cancelled</b>
                                            </TableCell>
                                            <TableCell>
                                                {Formatter.number(clientCancelledHours)}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>Total Employee Hours Called Off</b>
                                            </TableCell>
                                            <TableCell>
                                                {Formatter.number(employeeCancelledHours)}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell>
                                                <b>Projected Production Hours</b>
                                            </TableCell>
                                            <TableCell>
                                                {Formatter.number(totalHours - clientCancelledHours - employeeCancelledHours)}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>

                        <Box>
                            <SectionHeading title={'Clients'} primary/>
                            <TableContainer className={'table table--striped result__set'}>
                                <Table size={'small'}>
                                    <colgroup>
                                        <col width={'40%'}/>
                                        <col width={'30%'}/>
                                        <col width={'30%'}/>
                                    </colgroup>
                                    <TableBody>
                                        {clientResults.map(client => (
                                            <TableRow key={client.id}>
                                                <TableCell>
                                                    <Box className={'d-flex__start'}>
                                                        <ImageWrapper
                                                            src={API.getFilePath(client.image)}
                                                            width={36}
                                                            height={36}
                                                            noCache
                                                            className={'mr__2'}
                                                            horizontal
                                                        />
                                                        <b>{client.billableHours.name}</b>
                                                    </Box>
                                                </TableCell>
                                                <TableCell>{Formatter.number(client.billableHours.hours)}</TableCell>
                                                <TableCell>{Formatter.number(client.billableHours.deductions ? -client.billableHours.deductions : 0)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>

                        <Box>
                            <SectionHeading title={'Employees'} primary/>
                            <TableContainer className={'table table--striped result__set'}>
                                <Table size={'small'}>
                                    <colgroup>
                                        <col width={'40%'}/>
                                        <col width={'30%'}/>
                                        <col width={'30%'}/>
                                    </colgroup>
                                    <TableBody>
                                        {employeeResults.map(employee => (
                                            <TableRow key={employee.id}>
                                                <TableCell padding={'none'}>
                                                    <EmployeePreview
                                                        noCache
                                                        employee={employee}
                                                    />
                                                </TableCell>
                                                <TableCell>{Formatter.number(employee.billableHours.hours)}</TableCell>
                                                <TableCell>{Formatter.number(employee.billableHours.deductions ? -employee.billableHours.deductions : 0)}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                )}
            </Box>

            {/*The hidden display for PDF rendering.*/}
            <Box className={'d-hidden'}>
                <Box ref={resultsRef}>
                    <table className={'table--bordered'}>
                        <tbody>
                            <tr>
                                <td colSpan={2}>
                                    <b>Overview</b>
                                </td>
                            </tr>
                            <tr>
                                <td style={{width: '40%'}}>
                                    <b>Date (From)</b>
                                </td>
                                <td style={{width: '60%'}}>
                                    {Formatter.date(startDate)}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Date (To)</b>
                                </td>
                                <td>
                                    {Formatter.date(endDate)}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b># Scheduled Items</b>
                                </td>
                                <td>
                                    {Formatter.number(totalItems)}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Total Hours Scheduled</b>
                                </td>
                                <td>
                                    {Formatter.number(totalHours)}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Total Client Hours Cancelled</b>
                                </td>
                                <td>
                                    {Formatter.number(clientCancelledHours)}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Total Employee Hours Called Off</b>
                                </td>
                                <td>
                                    {Formatter.number(employeeCancelledHours)}
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <b>Projected Production Hours</b>
                                </td>
                                <td>
                                    {Formatter.number(totalHours - clientCancelledHours - employeeCancelledHours)}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <br/>
                    <table className={'table--bordered'}>
                        <tbody>
                            <tr>
                                <td colSpan={3}>
                                    <b>Clients</b>
                                </td>
                            </tr>
                            {clientResults.map(client => (
                                <tr key={client.id}>
                                    <td style={{width: '40%'}}>
                                        <b>{client.billableHours.name}</b>
                                    </td>
                                    <td style={{width: '30%'}}>{Formatter.number(client.billableHours.hours)}</td>
                                    <td style={{width: '30%'}}>{Formatter.number(client.billableHours.deductions ? -client.billableHours.deductions : 0)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                    <br/>
                    <table className={'table--bordered'}>
                        <tbody>
                            <tr>
                                <td colSpan={3}>
                                    <b>Employees</b>
                                </td>
                            </tr>
                            {employeeResults.map(employee => (
                                <tr key={employee.id}>
                                    <td style={{width: '40%'}}>
                                        <b>{employee.lastName}, {employee.firstName}</b>
                                    </td>
                                    <td style={{width: '30%'}}>{Formatter.number(employee.billableHours.hours)}</td>
                                    <td style={{width: '30%'}}>{Formatter.number(employee.billableHours.deductions ? -employee.billableHours.deductions : 0)}</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </Box>
            </Box>
        </Box>
    );
};

export default BillableHours;