import React from "react";

import App from "../Global/App";
import Model from "../Definitions/Model";
import Property from "../Definitions/Property";
import Formatter from "../Global/Formatter";
import ModalButton from "../Components/ModalButton";
import PropertyMap from "../Definitions/PropertyMap";
import ProfileForm from "../Pages/Employee/Profile/ProfileForm";
import EmployeeView from "../Pages/Admin/Employee/EmployeeView";
import EmployeeForm from "../Actions/EmployeeActions/EmployeeForm";
import ReferenceStep from "../Pages/Employee/Invitation/ReferenceStep";
import EmployeeActions from "../Actions/EmployeeActions";
import QuickSendWidget from "../Components/Widgets/QuickSendWidget";
import DrugScreenButton from "./RecruitInvited/DrugScreenButton";
import JobInterviewStep from "../Pages/Employee/Invitation/JobInterviewStep";
import InvitationActions from "./RecruitInvited/InvitationActions";
import HighlightIndicator from "../Actions/EmployeeActions/HighlightIndicator";
import EmployeeDetailsPreview from "./RecruitInvited/EmployeeDetailsPreview";

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import {faker} from '@faker-js/faker';
import Skeleton from "@mui/material/Skeleton";
import ChatIcon from '@mui/icons-material/Chat';
import IconButton from "@mui/material/IconButton";
import LaunchIcon from '@mui/icons-material/Launch';

/**
 * RecruitInvited model.
 *
 * @type {Object}
 */
const RecruitInvited = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'recruit',


    /**
     * The endpoint for recruiting candidates.
     */
    route: 'employees',


    /**
     * The form component.
     *
     * @returns {*}
     * @param parameters
     */
    form: (parameters) => <EmployeeForm {...parameters}/>,


    /**
     * The menu action.
     *
     * @param parameters
     * @returns {*}
     */
    action: (parameters) => <EmployeeActions {...parameters}/>,


    /**
     * Additional query parameters for new recruiting candidates.
     */
    query: {
        $filter: 'isActive eq {0} and isDeleted eq {0} and isInvited eq {1}',
        $expand: 'specialty',
        $orderby: 'recentDate desc, lastName asc'
    },


    /**
     * Returns any default query parameters.
     *
     * @returns {*|{}}
     */
    getQuery() {
        const user = App.getUser();
        const filter = this.getDefaultFilter();
        const query = {
            ...(this.query || {})
        };

        // Handle the "View All" permission.
        const bCanSeeAll = user &&
            user.permissions &&
            user.permissions.length &&
            user.permissions.includes('VIEW_ALL_CANDIDATES');

        query.$filter = `${query.$filter}${bCanSeeAll ? `` : ` and assigneeId in {${user.id}}`}`;

        return filter && filter.query ? {
            ...query,
            ...filter.query
        } : query;
    },


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'isHighlighted',
        'lastName',
        'inviteCompletedDate',
        'offerLetterDate',
        'availabilityDate',
        'hirePacketDate',
        'trainingDate',
        'drugScreen',
        'action',
        'chat'
    ],


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            faker: () => faker.datatype.uuid(),
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'isHighlighted',
            label: 'Highlighted',
            align: 'center',
            width: 30,
            faker: () => faker.datatype.boolean(),
            render: (record, doReload) => <HighlightIndicator record={record} doReload={doReload}/>,
            hideLabel: true
        }),
        Property.create({
            key: 'lastName',
            label: 'Name',
            render: (record) => (
                <EmployeeDetailsPreview record={record}/>
            )
        }),
        Property.create({
            key: 'inviteCompletedDate',
            label: 'Invite Completed',
            render: (record) => {
                if(!record.inviteCompletedDate){
                    return null;
                }

                return (
                    <Box className={'d-flex__start'}>
                        <span className={'mr__1'}>
                            {Formatter.dateTime(record.inviteCompletedDate)}
                        </span>
                        <ModalButton
                            size={'md'}
                            label={
                                <IconButton color={'primary'}>
                                    <LaunchIcon fontSize={'small'}/>
                                </IconButton>
                            }
                            heading={`Job Application for "${record.displayName || '(no name)'}"`}
                            noMargin
                            children={
                                <Box className={'fixed-height__preview fixed-height__preview--large'}>
                                    <ProfileForm
                                        readOnly
                                        employee={record}
                                    />
                                    <JobInterviewStep
                                        readOnly
                                        employee={record}
                                    />
                                    <ReferenceStep
                                        readOnly
                                        employee={record}
                                    />
                                </Box>
                            }
                            bodyStyle={{
                                padding: 0
                            }}
                        />

                    </Box>
                )
            }
        }),
        Property.create({
            key: 'offerLetterDate',
            label: 'Disclosures',
            render: (record) => Formatter.dateTime(record.offerLetterDate)
        }),
        Property.create({
            key: 'availabilityDate',
            label: 'Availability Complete',
            render: (record) => Formatter.dateTime(record.availabilityDate)
        }),
        Property.create({
            key: 'hirePacketDate',
            label: 'Hire Packet',
            render: (record) => Formatter.dateTime(record.hirePacketDate)
        }),
        Property.create({
            key: 'trainingDate',
            label: 'Training Complete',
            render: (record) => Formatter.dateTime(record.trainingDate)
        }),
        Property.create({
            key: 'drugScreen',
            align: 'center',
            label: 'Drug Screen',
            faker: () => faker.name.firstName(),
            render: (record, doReload) => (
                <DrugScreenButton record={record} doReload={doReload}/>
            ),
        }),
        Property.create({
            key: 'chat',
            label: 'Chat',
            align: 'center',
            render: (record) => <QuickSendWidget record={record} icon={<ChatIcon/>}/>
        }),
        Property.create({
            key: 'action',
            label: 'Action',
            align: 'center',
            faker: () => faker.name.firstName(),
            render: (record, doReload) => (
                <InvitationActions record={record} doReload={doReload}/>
            ),
        }),
        Property.create({
            key: 'image',
            label: '',
            width: '60px',
            faker: () => faker.datatype.uuid(),
            loader: () => <Skeleton height={48} width={48}/>,
            render: (record) => (
                <Avatar
                    sx={{width: 48, height: 48}}
                    alt={`${record.firstName} ${record.lastName}`}
                    variant={'rounded'}
                />
            ),
            selectable: false
        }),
        Property.create({
            key: 'specialtyId',
            label: 'Specialty Id',
            selectable: false
        }),
        Property.create({
            key: 'createdDate',
            label: 'Created At',
            render: (record) => Formatter.dateTime(record.createdDate),
            selectable: false
        }),
        Property.create({
            key: 'interviewDate',
            label: 'Date Invited',
            render: (record) => Formatter.dateTime(record.interviewDate),
            selectable: false
        }),
        Property.create({
            key: 'hirePacket',
            label: 'Hire Packet',
            render: (record) => Formatter.dateTime(record.hirePacketDate)
        }),
        Property.create({
            key: 'firstName',
            label: 'First Name',
            render: (record) => (
                <ModalButton
                    label={record.firstName}
                    component={'a'}
                    bodyStyle={{
                        paddingTop: 0
                    }}
                    children={
                        <EmployeeView id={record.id}/>
                    }
                />
            )
        }),
        Property.create({
            key: 'specialty',
            label: 'Specialty',
            faker: () => ['RN', 'LPN', 'CNA', 'MA', 'NA'].random(),
            render: (record) => record.specialty ? record.specialty.name : '',
            filterable: true,
        }),
        Property.create({
            key: 'emailAddress',
            label: 'Email',
            faker: () => faker.internet.email(),
            render: (record) => <a href={`mailto:${record.emailAddress}`}>{record.emailAddress}</a>
        }),
        Property.create({
            key: 'phoneNumber',
            label: 'Phone',
            faker: () => faker.phone.number(),
            render: (record) => <a href={`tel:${record.phoneNumber}`}>{record.phoneNumber}</a>,
            sortable: false
        }),
        Property.create({
            key: 'active',
            label: 'Active',
            faker: () => faker.datatype.boolean(),
            render: (record) => !record.isDeleted ? 'Active' : 'Inactive',
            filterable: true,
        })
    ])
};

export default RecruitInvited;