import React, {useEffect, useState} from "react";

import API from "../../../../Global/API";
import Logger from "../../../../Global/Logger";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";
import {ResponsiveRadar} from '@nivo/radar'


/**
 * The number of clients to load per page.
 *
 * @type {number}
 */
const perPageLimit = 100;


/**
 * HourlyDistribution component.
 *
 * @returns {Element}
 * @constructor
 */
const HourlyDistribution = (props) => {
    const {
        loading,            // {Boolean} Indicates if we're currently loading.
        endDate,            // {String} The end date of the date range.
        startDate,          // {String} The start date of the date range.
        controller,         // {Controller} The abort controller.
        onLoadComplete,     // {Function} A callback function after the data has been loaded.
    } = props;

    const [data, setData] = useState([]);
    const [isLoading, setLoading] = useState(true);

    /**
     * Load all data on mount.
     */
    useEffect(() => {
        getData();
    }, []);


    /**
     * Loads our report results.
     *
     * @returns {Promise<void>}
     */
    const getData = async () => {
        let promises = [];
        setLoading(true);

        // Determine the filter criteria.
        let eventFilters = ['isBlockRequested eq {0}'];
        eventFilters.push(endDate ? `startDate lt {${endDate}}` : ``);
        eventFilters.push(startDate ? `startDate gt {${startDate}}` : ``);
        eventFilters = eventFilters.filter(filter => !!filter.trim());

        // Load all the clients page by page until we've reached the end.
        const count = await API.get('clients/count', {
            $top: perPageLimit,
            $filter: `events/any{${eventFilters.join(' and ')}}`,
        });

        for (let page = 0; page < count.pages; page++) {
            promises.push(
                API.get('clients', {
                    $top: perPageLimit,
                    $skip: page * perPageLimit,
                    endDate,
                    $filter: `events/any{${eventFilters.join(' and ')}}`,
                    $select: '*,billableHours',
                    $orderby: `name asc`,
                    startDate,
                    '@cache': true,
                    '@signal': controller.signal
                })
            );
        }

        try{
            const results = await Promise.all(promises);

            setData(results.flat().map(facility => {
                const billableHours = {
                    ...facility.billableHours
                };

                return {
                    Hours: billableHours.hours || 0,
                    Facility: facility.name || '(no name)',
                    Cancellations: billableHours.deductions || 0
                };
            }).sort((a, b) => {
                return b.Hours - a.Hours;
            }));

            setLoading(false);

            if(onLoadComplete){
                onLoadComplete();
            }
        }
        catch(e){
            Logger.warn(`[HourlyDistribution] Failed to load data: ${e}`);
        }
    };

    return (
        <>
            <Box sx={{height: 'calc(100vh - 300px)', paddingBottom: '1em'}}>
                <ResponsiveRadar
                    data={data}
                    keys={['Hours', 'Cancellations']}
                    indexBy={'Facility'}
                    margin={{top: 70, right: 80, bottom: 40, left: 80}}
                    borderColor={{from: 'color'}}
                    gridLabelOffset={36}
                    dotSize={10}
                    dotColor={{theme: 'background'}}
                    dotBorderWidth={2}
                    colors={{scheme: 'nivo'}}
                    // blendMode="multiply"
                    motionConfig="wobbly"
                    legends={[
                        {
                            anchor: 'top-left',
                            direction: 'column',
                            translateX: -50,
                            translateY: -40,
                            itemWidth: 80,
                            itemHeight: 20,
                            itemTextColor: '#999',
                            symbolSize: 12,
                            symbolShape: 'circle',
                            effects: [
                                {
                                    on: 'hover',
                                    style: {
                                        itemTextColor: '#000'
                                    }
                                }
                            ]
                        }
                    ]}
                />
            </Box>

            {loading && <LinearProgress/>}
        </>
    );
};

export default HourlyDistribution;