import React from "react";

import App from "../Global/App";
import Model from "../Definitions/Model";
import Timer from "../Components/Timer";
import Property from "../Definitions/Property";
import Formatter from "../Global/Formatter";
import ModalButton from "../Components/ModalButton";
import PropertyMap from "../Definitions/PropertyMap";
import EmployeeView from "../Pages/Admin/Employee/EmployeeView";
import EmployeeForm from "../Actions/EmployeeActions/EmployeeForm";
import ContactAttempt from "../Actions/EmployeeActions/ContactAttempt";
import EmployeeActions from "../Actions/EmployeeActions";
import QuickSendWidget from "../Components/Widgets/QuickSendWidget";
import InterviewButton from "../Actions/EmployeeActions/InterviewButton";
import HighlightIndicator from "../Actions/EmployeeActions/HighlightIndicator";
import EmployeeDetailsPreview from "./RecruitInvited/EmployeeDetailsPreview";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import {faker} from '@faker-js/faker';
import Skeleton from "@mui/material/Skeleton";
import ChatIcon from "@mui/icons-material/Chat";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

/**
 * Recruit model.
 *
 * @type {Object}
 */
const RecruitNew = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'recruit-new-v2',


    /**
     * The endpoint for recruiting candidates.
     */
    route: 'employees',


    /**
     * The form component.
     *
     * @returns {*}
     * @param parameters
     */
    form: (parameters) => <EmployeeForm {...parameters}/>,


    /**
     * The menu action.
     *
     * @param parameters
     * @returns {*}
     */
    action: (parameters) => <EmployeeActions {...parameters}/>,


    /**
     * Additional query parameters for new recruiting candidates.
     */
    query: {
        $filter: 'isActive eq {0} and isDeleted eq {0} and isInvited eq {0}',
        $expand: 'specialty',
    },


    /**
     * Returns any default query parameters.
     *
     * @returns {*|{}}
     */
    getQuery() {
        const user = App.getUser();
        const filter = this.getDefaultFilter();
        const query = {
            ...(this.query || {})
        };

        // Handle the "View All" permission.
        const bCanSeeAll = user &&
            user.permissions &&
            user.permissions.length &&
            user.permissions.includes('VIEW_ALL_CANDIDATES');

        query.$filter = `${query.$filter}${bCanSeeAll ? `` : ` and assigneeId in {${user.id}}`}`;

        return filter && filter.query ? {
            ...query,
            ...filter.query
        } : query;
    },


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'isHighlighted',
        'lastName',
        'phoneNumber',
        'createdDate',
        'timer',
        'recruitAction',
        'chat',
    ],


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            faker: () => faker.datatype.uuid(),
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'isHighlighted',
            label: 'Highlight',
            align: 'center',
            width: 30,
            faker: () => faker.datatype.boolean(),
            render: (record, doReload) => <HighlightIndicator record={record} doReload={doReload}/>,
            hideLabel: true
        }),
        Property.create({
            key: 'lastName',
            label: 'Name',
            width: '20%',
            render: (record) => (
                <EmployeeDetailsPreview record={record}/>
            ),
            sortable: true
        }),
        Property.create({
            key: 'phoneNumber',
            width: '20%',
            label: 'Phone',
            faker: () => faker.phone.number(),
            render: (record) => <a href={`tel:${record.phoneNumber}`}>{Formatter.phoneString(record.phoneNumber)}</a>,
        }),
        Property.create({
            key: 'createdDate',
            width: '16%',
            label: 'Created At',
            render: (record) => Formatter.dateTime(record.createdDate),
            sortable: true
        }),
        Property.create({
            key: 'image',
            label: '',
            width: '60px',
            faker: () => faker.datatype.uuid(),
            loader: () => <Skeleton height={48} width={48}/>,
            render: (record) => (
                <Avatar
                    sx={{width: 48, height: 48}}
                    alt={`${record.firstName} ${record.lastName}`}
                    variant={'rounded'}
                />
            ),
            selectable: false
        }),
        Property.create({
            key: 'timer',
            width: '20%',
            align: 'center',
            label: 'Timer',
            render: (record) => <Timer from={record.statusEffectiveDate || record.createdDate} to={record.contactDate}/>
        }),
        Property.create({
            key: 'recruitAction',
            width: '20%',
            align: 'center',
            label: 'Action',
            render: (record, doReload) => (
                <Box className={'white-space__pre'}>
                    {!!record.interviewDate && (record.isInvited || record.isDeleted) ? (
                        record.isInvited ? (
                            <Chip
                                label={
                                    <Box className={'d-flex__start'}>
                                        <CheckIcon fontSize={'small'} className={'mr__1'}/>
                                        Invited!
                                    </Box>
                                }
                                color={'success'}
                            />
                        ) : (
                            <Chip
                                label={
                                    <Box className={'d-flex__start'}>
                                        <CloseIcon fontSize={'small'} className={'mr__1'}/>
                                        Rejected
                                    </Box>
                                }
                                color={'error'}
                            />
                        )
                    ) : (
                        <>
                            <ContactAttempt record={record} onSave={doReload} className={'mr__3'}/>
                            <InterviewButton record={record} onSave={doReload}/>
                        </>
                    )}
                </Box>
            ),
        }),
        Property.create({
            key: 'firstName',
            label: 'First Name',
            render: (record) => (
                <ModalButton
                    label={record.firstName}
                    children={
                        <EmployeeView id={record.id}/>
                    }
                    bodyStyle={{
                        paddingTop: 0
                    }}
                    component={'a'}
                />
            ),
        }),
        Property.create({
            key: 'chat',
            label: 'Chat',
            align: 'center',
            render: (record) => <QuickSendWidget record={record} icon={<ChatIcon/>}/>
        }),
        Property.create({
            key: 'specialty',
            label: 'Specialty',
            faker: () => ['RN', 'LPN', 'CNA', 'MA', 'NA'].random(),
            render: (record) => record.specialty ? record.specialty.name : '',
        }),
        Property.create({
            key: 'emailAddress',
            label: 'Email',
            faker: () => faker.internet.email(),
            render: (record) => <a href={`mailto:${record.emailAddress}`}>{record.emailAddress}</a>,
        }),
        Property.create({
            key: 'active',
            label: 'Active',
            faker: () => faker.datatype.boolean(),
            render: (record) => !record.isDeleted ? 'Active' : 'Inactive',
        })
    ])
};

export default RecruitNew;