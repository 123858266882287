import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import Logger from "../../../Global/Logger";
import AddNote from "../../../Actions/NoteForm";
import {useAuth} from "../../../Global/Auth";
import Clipboard from "../../../Global/Clipboard";
import TextEditor from "../../../Components/Input/TextEditor";
import EmployeeView from "../Employee/EmployeeView";
import DialogHeading from "../../../Components/Typography/DialogHeading";
import CommunicationFeed from "../../../Components/Widgets/CommunicationFeed";
import AvailabilityRequest from "../../../Actions/EmployeeActions/AvailabilityRequest";
import AssignedAvailabilityNotes from "../Client/AssignedAvailabilityNotes";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {Portal} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import Checkbox from "@mui/material/Checkbox";
import SendIcon from '@mui/icons-material/Send';
import TextField from "@mui/material/TextField";
import Accordion from "@mui/material/Accordion";
import FormGroup from "@mui/material/FormGroup";
import Typography from "@mui/material/Typography";
import CreateIcon from '@mui/icons-material/Create';
import {DatePicker} from "@mui/x-date-pickers";
import EditNoteIcon from '@mui/icons-material/EditNote';
import DownloadIcon from '@mui/icons-material/Download';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinearProgress from "@mui/material/LinearProgress";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import FormControlLabel from "@mui/material/FormControlLabel";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

/**
 * EmployeeSlotMenu component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const EmployeeSlotMenu = (props) => {
    const {
        record,             // {Object} The employee record / context.
        onNext,             // {Function} A next button callback event.
        onChange,           // {Function} Updates an employee record property.
        doReload,           // {Function} Reloads the parent layout, if applicable.
        onPrevious,         // {Function} A previous button callback event.
    } = props;

    /**
     * Generic state properties for this action menu.
     */
    const {hasPermissionTo} = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const [pointTotal, setPointTotal] = useState(0);
    const [isAddingNote, setAddingNote] = useState(false);
    const [isViewingChat, setViewingChat] = useState(false);
    const [isViewingRecord, setViewingRecord] = useState(false);
    const [isEditingPoints, setEditingPoints] = useState(false);
    const [isConfirmingPoint, setConfirmingPoint] = useState(false);
    const [updatedPointTotal, setUpdatedPointTotal] = useState(record.pointTotal || 0);
    const [currentPointTotal, setCurrentPointTotal] = useState(record.pointTotal || 0);
    const [isAdjustingAvailability, setAdjustingAvailability] = useState(false);


    /**
     * Properties exclusive to the availability export.
     */
    const [availabilityContext, setAvailabilityContext] = useState(null);
    const [availabilityEndDate, setAvailabilityEndDate] = useState(dayjs().endOf('month').format('YYYY-MM-DD'));
    const [isViewingAvailability, setViewingAvailability] = useState(false);
    const [availabilityStartDate, setAvailabilityStartDate] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));


    /**
     * State parameters related to the shift export dialog.
     */
    const [shiftMarkup, setShiftMarkup] = useState('');
    const [copySuccess, setCopySuccess] = useState(false);
    const [upcomingShifts, setUpcomingShifts] = useState([]);
    const [isViewingShifts, setViewingShifts] = useState(false);
    const [hasShiftResults, setHasShiftResults] = useState(false);
    const [scheduleEndDate, setScheduleEndDate] = useState(dayjs().endOf('month').format('YYYY-MM-DD'));
    const [scheduleStartDate, setScheduleStartDate] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));


    /**
     * Synchronize the shift markup with the dates if loaded at least once.
     */
    useEffect(() => {
        if (!hasShiftResults || !scheduleStartDate || !scheduleEndDate) {
            return;
        }

        doExportShifts();
    }, [scheduleStartDate, scheduleEndDate]);


    /**
     * Synchronizes the point total from the input employee.
     */
    useEffect(() => {
        setCurrentPointTotal(record.pointTotal || 0);
        setUpdatedPointTotal(record.pointTotal || 0);
    }, [record]);


    /**
     * Synchronizes the availability context against the dates.
     */
    useEffect(() => {
        if (!availabilityContext || !availabilityStartDate || !availabilityEndDate) {
            return;
        }

        // Update the context entry on date updates.
        setAvailabilityContext({
            ...availabilityContext,
            scheduleToDate: availabilityEndDate,
            scheduleFromDate: availabilityStartDate,
        });
    }, [availabilityStartDate, availabilityEndDate]);


    /**
     * Closes the menu popover.
     */
    const handleClose = () => {
        setAnchorEl(null);
    };


    /**
     * Reveals the point confirmation dialog.
     *
     * @param points
     */
    const handlePointConfirmationOpen = (points) => {
        setPointTotal(points);
        setConfirmingPoint(true);
    };


    /**
     * Closes the point confirmation dialog.
     */
    const handlePointConfirmationClose = () => {
        setConfirmingPoint(false);
    };


    /**
     * Reveals the point confirmation dialog.
     */
    const handlePointsEditOpen = () => {
        setEditingPoints(true);
    };


    /**
     * Closes the point confirmation dialog.
     */
    const handlePointsEditClose = () => {
        setEditingPoints(false);
    };


    /**
     * Saves the input tally.
     *
     * @returns {Promise<void>}
     */
    const doTallySave = async () => {
        await API.post('tallies', {
            amount: pointTotal,
            employeeId: record.id
        });

        // Refresh the employee record within the layout.
        const employee = await API.get(`employees/${record.id}`, {
            $select: '*,pointTotal',
            $expand: 'specialty,status'
        });

        setCurrentPointTotal(employee.pointTotal);
        setUpdatedPointTotal(employee.pointTotal);
        handlePointConfirmationClose();

        if (doReload) {
            doReload(employee);
        }
    };


    /**
     * Saves the input tally.
     *
     * @returns {Promise<void>}
     */
    const doTallyTotalSave = async () => {
        await API.post('tallies', {
            amount: (updatedPointTotal || 0) - currentPointTotal,
            employeeId: record.id
        });

        // Refresh the employee record within the layout.
        const employee = await API.get(`employees/${record.id}`, {
            $select: '*,pointTotal',
            $expand: 'specialty,status'
        });

        setCurrentPointTotal(employee.pointTotal);
        setUpdatedPointTotal(employee.pointTotal);
        handlePointsEditClose();

        if (doReload) {
            doReload(employee);
        }
    };


    /**
     * Synchronizes the numeric value of the input.
     *
     * @param event
     */
    const handlePointTotalUpdate = (event) => {
        const value = event.target.value;

        if (!value) {
            return setUpdatedPointTotal(null);
        }

        setUpdatedPointTotal(parseInt(value));
    };


    /**
     * Handles toggling the availability exporter.
     */
    const handleAvailabilityOpen = () => setViewingAvailability(true);
    const handleAvailabilityClose = () => {
        setAvailabilityContext(null);
        setViewingAvailability(false)
    };


    /**
     * Retrieves the availability results.
     */
    const doGetAvailabilityResults = () => {
        if (!availabilityStartDate || !availabilityEndDate) {
            return;
        }

        setAvailabilityContext({
            scheduleToDate: availabilityEndDate,
            scheduleFromDate: availabilityStartDate,
        });
    };


    /**
     * Opens the note modal.
     */
    const handleChatOpen = () => {
        setViewingChat(true);
    };


    /**
     * Closes the note modal.
     */
    const handleChatClose = () => {
        setViewingChat(false);
    };


    /**
     * Toggles the shift export modal.
     */
    const handleShiftExportOpen = () => {
        handleClose();
        setViewingShifts(true)
    };


    /**
     * Closes the shift export modal.
     */
    const handleShiftExportClose = () => {
        setViewingShifts(false)
        setHasShiftResults(false);
    };


    /**
     * Opens the note modal.
     */
    const handleNoteOpen = () => {
        setAddingNote(true);
    };


    /**
     * Closes the note modal.
     */
    const handleNoteClose = () => {
        setAddingNote(false);
    };


    /**
     * Reveals the record dialog.
     */
    const handleViewOpen = () => {
        setViewingRecord(true);
    };


    /**
     * Closes the record dialog.
     */
    const handleViewClose = () => {
        setViewingRecord(false);
    };


    /**
     * Exports the shift markup for the client.
     *
     * @returns {Promise<void>}
     */
    const doExportShifts = async () => {
        let promises = [];
        let response = [];
        let perPageLimit = 500;

        setLoading(true);

        // Determine how many shifts meet criteria for this facility.
        const count = await API.get('events/count', {
            $top: perPageLimit,
            $filter: `startDate ge {${scheduleStartDate}} and startDate le {${scheduleEndDate}} and employee/any{id in {${record.id}}} and isBlockRequested eq {0} and isDeleted eq {0}`,
        })

        Logger.debug('[EmployeeSlotMenu] Received count response:', count);

        for (let page = 0; page < count.pages; page++) {
            promises.push(
                API.get('events', {
                    $top: perPageLimit,
                    $skip: page * perPageLimit,
                    $expand: 'client,employee($expand=specialty)',
                    $filter: `startDate ge {${scheduleStartDate}} and startDate le {${scheduleEndDate}} and employee/any{id in {${record.id}}} and isBlockRequested eq {0} and isDeleted eq {0}`,
                    $orderby: 'startDate asc'
                })
            );
        }

        try {
            const results = await Promise.all(promises);
            Logger.debug('[EmployeeSlotMenu] Aggregated responses:', results);
            response = results.flat();
        } catch (e) {
            Logger.warn('[ClientActions] Unable to export shift data.', e);
            return setLoading(false);
        }

        // Group calendar items by formatted date.
        const days = {};

        response.map(shift => {
            const dateFormatted = dayjs(shift.startDate).format('MM/DD/YYYY');

            if (!days.hasOwnProperty(dateFormatted)) {
                days[dateFormatted] = [];
            }

            days[dateFormatted].push(shift);
        });

        // Compile the markup from the groupings.
        setShiftMarkup(Object.keys(days).map(day => {
            const shifts = days[day];
            const items = shifts.map(shift => {
                const {employee} = shift || {};
                const {specialty} = employee || {};
                const endDate = dayjs(shift.endDate).format('h:mm A');
                const startDate = dayjs(shift.startDate).format('h:mm A');

                if (!employee.id) {
                    return '';
                }

                return `<li>${employee.firstName} ${employee.lastName} (${specialty.name}): ${startDate} - ${endDate}</li>`;
            }).join('');

            if (!shifts) {
                return;
            }

            return `<div><b>${day}</b><ul>${items}</ul></div>`;
        }).join(''));

        // Update the frontend.
        setLoading(false);
        setUpcomingShifts(response);
        setHasShiftResults(true);
    };


    /**
     * Indicates if shifts are currently enabled.
     *
     * @type {boolean}
     */
    const showShifts = record['@showShifts'] !== false;


    /**
     * Whether we should display availability entries.
     *
     * @type {boolean}
     */
    const showAvailability = !!record['@showAvailability'];


    /**
     * Closes the success message.
     */
    const handleCopySuccessClose = () => setCopySuccess(false);


    /**
     * Opens the success message.
     */
    const handleCopySuccessOpen = () => setCopySuccess(true);


    /**
     * Copies the markup content to the clipboard.
     */
    const doCopyMarkup = () => {
        Clipboard.doCopy(shiftMarkup, handleCopySuccessOpen, {html: true});
    };


    /**
     * Downloads the shifts as a PDF document.
     *
     * @returns {Promise<void>}
     */
    const getShiftsAsPdf = async () => {
        setLoading(true);

        // Create the PDF document.
        const specialty = {...record.specialty};
        const response = await API.post('pdf', {
            html: shiftMarkup,
            heading: `Shift Results for ${record.displayName}${specialty.id ? ` (${specialty.name})` : ''}`
        });

        // Download the document.
        await new Promise(resolve => {
            fetch(API.getFilePath(response.path), {
                method: 'GET'
            }).then(resp => resp.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = `${record.displayName}-shifts.pdf`;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    resolve();
                })
        });

        setLoading(false);
    };

    return (
        <Box
            sx={{
                position: 'relative',
                paddingBottom: '2.8em'
            }}
            className={'flex__grow'}
            key={`${record.id}-schedule-employee-menu`}
        >
            <Box>
                {(
                    hasPermissionTo('VIEW_EMPLOYEES') ||
                    hasPermissionTo('EDIT_EMPLOYEES') ||
                    hasPermissionTo('VIEW_COMMUNICATIONS')
                ) && (
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <Typography>Shortcuts</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{paddingTop: 0}}>
                            <Box className={'columns__1 columns--small'}>
                                {hasPermissionTo('VIEW_EMPLOYEES') && (
                                    <Button
                                        variant={'outlined'}
                                        onClick={handleViewOpen}
                                        children={'View Profile'}
                                        startIcon={<OpenInNewIcon/>}
                                    />
                                )}
                                {hasPermissionTo('EDIT_EMPLOYEES') && (
                                    <Button
                                        variant={'outlined'}
                                        onClick={handleNoteOpen}
                                        children={'Add Note'}
                                        startIcon={<EditNoteIcon/>}
                                    />
                                )}
                                {hasPermissionTo('VIEW_COMMUNICATIONS') && (
                                    <Button
                                        variant={'outlined'}
                                        onClick={handleChatOpen}
                                        children={'Send Message'}
                                        startIcon={<SendIcon/>}
                                    />
                                )}
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                )}

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography>Schedule</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{paddingTop: 0}}>
                        <Box className={'columns__1 columns--small'}>
                            {hasPermissionTo('EDIT_EMPLOYEES') && (
                                <Button
                                    variant={'outlined'}
                                    onClick={() => setAdjustingAvailability(true)}
                                    children={`Set Availability`}
                                    startIcon={<EditCalendarIcon/>}
                                />
                            )}
                            <Button
                                variant={'outlined'}
                                onClick={handleAvailabilityOpen}
                                children={`Export Availability`}
                                startIcon={<FileDownloadIcon/>}
                            />
                            <Button
                                variant={'outlined'}
                                onClick={handleShiftExportOpen}
                                children={`Download Shifts`}
                                startIcon={<FileDownloadIcon/>}
                            />
                        </Box>
                    </AccordionDetails>
                </Accordion>

                {hasPermissionTo('EDIT_EMPLOYEES') && (
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <Typography>Attendance ({currentPointTotal})</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{paddingTop: 0}}>
                            <Box className={'columns__1 columns--small'}>
                                <Button
                                    variant={'outlined'}
                                    startIcon={<CreateIcon/>}
                                    onClick={handlePointsEditOpen}
                                    children={`Edit Total (${currentPointTotal})`}
                                />
                                <Box className={'columns__3 columns--small'}>
                                    <Button
                                        variant={'outlined'}
                                        onClick={() => handlePointConfirmationOpen(1)}
                                        children={'+1'}
                                    />
                                    <Button
                                        variant={'outlined'}
                                        onClick={() => handlePointConfirmationOpen(2)}
                                        children={'+2'}
                                    />
                                    <Button
                                        variant={'outlined'}
                                        onClick={() => handlePointConfirmationOpen(4)}
                                        children={'+4'}
                                    />
                                </Box>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                )}

                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon/>}
                    >
                        <Typography>Filter Items</Typography>
                    </AccordionSummary>
                    <AccordionDetails sx={{paddingTop: 0}}>
                        <Box className={'columns__1 columns--small'}>
                            <FormGroup>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={showShifts}
                                        onChange={() => onChange('@showShifts', !showShifts)}
                                    />
                                } label="Shifts"/>
                                <FormControlLabel control={
                                    <Checkbox
                                        checked={showAvailability}
                                        onChange={() => onChange('@showAvailability', !showAvailability)}
                                    />
                                } label="Availability"/>
                            </FormGroup>
                        </Box>
                    </AccordionDetails>
                </Accordion>

                <Box className={'slot-menu__controls'}>
                    <Box className={'columns__2'}>
                        <Button
                            onClick={onPrevious}
                            children={'Back'}
                            disabled={!onPrevious}
                            startIcon={<NavigateBeforeIcon/>}
                        />
                        <Button
                            onClick={onNext}
                            endIcon={<NavigateNextIcon/>}
                            children={'Next'}
                            disabled={!onNext}
                        />
                    </Box>
                </Box>
            </Box>

            <Dialog
                open={isConfirmingPoint}
                scroll={'body'}
                onClose={handlePointConfirmationClose}
                maxWidth={'xs'}
                fullWidth
            >
                <DialogHeading
                    title={"Are you sure?"}
                    noMargin
                />
                <DialogContent>
                    <Box>
                        You are about to assign {pointTotal} point{pointTotal < 2 ? '' : 's'}. Would
                        you like to continue?
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={doTallySave}
                        children={'Yes'}
                    />
                    <Button
                        color={'error'}
                        onClick={handlePointConfirmationClose}
                        children={'No'}
                    />
                </DialogActions>
            </Dialog>

            <Dialog
                open={isViewingAvailability}
                scroll={'body'}
                onClose={handleAvailabilityClose}
                maxWidth={'sm'}
                fullWidth
            >
                <DialogHeading
                    title={"Export Availability"}
                    noMargin
                />
                <DialogContent>
                    <Box className={'columns__1'}>
                        <Box>
                            Please select a date range to download availability for:
                        </Box>
                        <Box className={'columns__2'}>
                            <DatePicker
                                label={'Start Date'}
                                value={availabilityStartDate ? dayjs(availabilityStartDate) : null}
                                disabled={isLoading}
                                onChange={event => setAvailabilityStartDate(event ? event.format('YYYY-MM-DD') : '')}
                            />

                            <DatePicker
                                label={'End Date'}
                                value={availabilityEndDate ? dayjs(availabilityEndDate) : null}
                                disabled={isLoading}
                                onChange={event => setAvailabilityEndDate(event ? event.format('YYYY-MM-DD') : '')}
                            />
                        </Box>
                        {!!availabilityContext && (
                            <AssignedAvailabilityNotes
                                nested
                                record={availabilityContext}
                                defaultEmployees={[record]}
                            />
                        )}
                    </Box>
                </DialogContent>

                {!availabilityContext && (
                    <DialogActions>
                        <Button
                            onClick={doGetAvailabilityResults}
                            disabled={!availabilityStartDate || !availabilityEndDate}
                            children={'Download'}
                        />
                        <Button
                            color={'error'}
                            onClick={handleAvailabilityClose}
                            children={'Cancel'}
                        />
                    </DialogActions>
                )}
            </Dialog>

            <Dialog
                open={isEditingPoints}
                scroll={'body'}
                onClose={handlePointsEditClose}
                maxWidth={'xs'}
                fullWidth
            >
                <DialogHeading
                    title={"Edit Tally Points"}
                    noMargin
                />
                <DialogContent>
                    <Box className={'columns__1'}>
                        <Box>
                            Please enter an updated point total for the current period:
                        </Box>
                        <Box>
                            <TextField
                                type={'number'}
                                value={updatedPointTotal}
                                label={'Point Total'}
                                fullWidth
                                onChange={handlePointTotalUpdate}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={doTallyTotalSave}
                        children={'Save'}
                    />
                    <Button
                        color={'error'}
                        onClick={handlePointsEditClose}
                        children={'Cancel'}
                    />
                </DialogActions>
            </Dialog>

            {isViewingRecord && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    maxWidth={'xl'}
                    onClose={handleViewClose}
                    fullWidth
                >
                    <DialogContent sx={{paddingTop: 0}}>
                        <Box className={'columns__1'}>
                            <EmployeeView
                                id={record.id}
                                onClose={() => {
                                    handleClose();
                                    handleViewClose();
                                }}
                            />
                        </Box>
                    </DialogContent>
                </Dialog>
            )}

            {isAddingNote && (
                <AddNote
                    open={true}
                    record={record}
                    onSave={() => doReload(record)}
                    modelId={record.id}
                    onClose={handleNoteClose}
                    modelType={'Employee'}
                />
            )}

            {isViewingChat && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handleChatClose}
                    maxWidth={'lg'}
                    fullWidth
                >
                    <CommunicationFeed
                        condensed
                        defaultSelection={record}
                        hideEmployeeSelect
                    />
                </Dialog>
            )}

            {isAdjustingAvailability && (
                <AvailabilityRequest
                    open={true}
                    record={record}
                    onSave={updated => doReload({
                        ...record,
                        ...updated
                    })}
                    onClose={() => setAdjustingAvailability(false)}
                />
            )}

            {isViewingShifts && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handleShiftExportClose}
                    maxWidth={'sm'}
                    fullWidth
                >
                    {isLoading && <LinearProgress/>}
                    <DialogHeading
                        title={"Export Shifts"}
                        noMargin
                    />
                    <DialogContent>
                        <Box className={'columns__1'}>
                            <Box>
                                Please select a date range to download shifts for:
                            </Box>
                            <Box className={'columns__2'}>
                                <DatePicker
                                    label={'Start Date'}
                                    value={scheduleStartDate ? dayjs(scheduleStartDate) : null}
                                    disabled={isLoading}
                                    onChange={event => setScheduleStartDate(event ? event.format('YYYY-MM-DD') : '')}
                                />

                                <DatePicker
                                    label={'End Date'}
                                    value={scheduleEndDate ? dayjs(scheduleEndDate) : null}
                                    disabled={isLoading}
                                    onChange={event => setScheduleEndDate(event ? event.format('YYYY-MM-DD') : '')}
                                />
                            </Box>
                        </Box>
                    </DialogContent>

                    {hasShiftResults && (
                        <Box sx={{paddingLeft: '1.75em', paddingRight: '1.75em'}}>
                            <TextEditor
                                value={shiftMarkup}
                                height={350}
                                onChange={value => setShiftMarkup(value)}
                            />
                        </Box>
                    )}

                    <DialogActions>
                        {!hasShiftResults ? (
                            <>
                                <Button
                                    onClick={doExportShifts}
                                    children={'Download'}
                                    disabled={isLoading || !scheduleStartDate || !scheduleEndDate}
                                />
                                <Button
                                    color={'error'}
                                    onClick={handleShiftExportClose}
                                    children={'Cancel'}
                                    disabled={isLoading}
                                />
                            </>
                        ) : (
                            <>
                                <Button
                                    onClick={getShiftsAsPdf}
                                    disabled={isLoading}
                                    children={'Save as PDF'}
                                    startIcon={<DownloadIcon/>}
                                />
                                <Button
                                    onClick={doCopyMarkup}
                                    disabled={isLoading}
                                    children={'Copy to Clipboard'}
                                    startIcon={<ContentCopyIcon/>}
                                />
                            </>
                        )}
                    </DialogActions>
                </Dialog>
            )}

            {copySuccess && (
                <Portal>
                    <Snackbar open={true} autoHideDuration={6000} onClose={handleCopySuccessClose}>
                        <Alert onClose={handleCopySuccessClose} severity={'success'}>
                            Copied successfully!
                        </Alert>
                    </Snackbar>
                </Portal>
            )}
        </Box>
    );
};

export default EmployeeSlotMenu;