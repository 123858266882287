/**
 * Creates a data URL from the output file.
 *
 * @param file
 * @returns {Promise<unknown>}
 */
const fileToDataURL = (file) => (
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result);
        reader.onerror = reject;
        reader.readAsDataURL(file);
    })
);


/**
 * Creates a Blob file from an input data URI string.
 *
 * @param dataURI
 * @returns {*}
 */
const dataURIToBlob = (dataURI) => {
    const byteString = atob(dataURI.split(',')[1]);
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);

    // Assign the bytes of the buffer to the correct values.
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    // Write the ArrayBuffer to a Blob.
    return new Blob([ab], {type: mimeString});
};

export {
    fileToDataURL,
    dataURIToBlob,
};