import React, {useState} from "react";

import Chip from "@mui/material/Chip";

/**
 * EmployeeStatusChip component.
 *
 * @constructor
 */
const EmployeeStatusChip = (props) => {
    const {
        size,       // {String} A size modifier for the chip.
        record      // {Object} The employee record to display the status of.
    } = props;

    const [statuses, setStatuses] = useState(false);

    /**
     * The appropriate size attribute for the chip.
     *
     * @type {String}
     */
    const chipSize = size || 'small';


    /**
     * The inner chip content.
     *
     * @type {React.JSX.Element}
     */
    const chipContent = record.status && record.status.name ? (
        <Chip
            sx={{
                color: '#fff',
                backgroundColor: record.status.color,
            }}
            size={chipSize}
            label={record.status.name}
        />
    ) : (
        !record.isDeleted ? (
            record.isActive ?
                <Chip
                    size={chipSize}
                    label={'Active'}
                    color={'success'}
                /> : (
                    record.isInvited ?
                        <Chip
                            size={chipSize}
                            color={'info'}
                            label={'Invited'}
                        /> :
                        <Chip
                            size={chipSize}
                            color={'warning'}
                            label={'Recruiting'}
                        />
                )
        ) : <Chip label="Inactive" size={chipSize}/>
    );

    return (
        <>{chipContent}</>
    );
};

export default EmployeeStatusChip;