import React, {useEffect, useState} from "react";

import Step from "../../../Components/Step";
import Settings from "../../../Global/Settings";
import {useAuth} from "../../../Global/Auth";
import Formatter from "../../../Global/Formatter";
import LogoHeading from "../../../Components/Typography/LogoHeading";
import StepControls from "../../../Components/StepControls";
import InputSignature from "../../../Components/Input/InputSignature";

import Box from "@mui/material/Box";

/**
 * EmployeeHandbookStep component.
 *
 * @returns {*}
 * @constructor
 */
const EmployeeHandbookStep = (props) => {
    const {
        step,                   // {Number} The current step for the user.
        index,                  // {Number} The expected step number.
        onClose,                // {Function} An optional method for closing the form if it appears in a dialog.
        employee,               // {Object} The employee object for read only context.
        readOnly,               // {Boolean} Whether the form is disabled.
        disabled,               // {Boolean} Whether the step is disabled from input.
        contentOnly,            // {Boolean} Whether to only render the content.
        onStepChange            // {Function} The on change callback to load the next step.
    } = props;

    const [isLoaded, setLoaded] = useState(false);
    const [signature, setSignature] = useState('');
    const {user, setUserSetting, getUserSettingValue, getUserSettingValueForEmployee} = useAuth();

    /**
     * Loads the required settings for this step.
     */
    useEffect(() => {
        handleSettingsLoad();
    }, []);


    /**
     * Synchronize the job description signature.
     */
    useEffect(() => {
        if (!readOnly && isLoaded) {
            setUserSetting('HP_EMPLOYEE_HANDBOOK', signature)
        }
    }, [signature]);


    /**
     * Loads each of the appropriate user settings.
     *
     * @returns {Promise<void>}
     */
    const handleSettingsLoad = async () => {
        setSignature(
            readOnly ?
                await getUserSettingValueForEmployee(employee.id, 'HP_EMPLOYEE_HANDBOOK') :
                await getUserSettingValue('HP_EMPLOYEE_HANDBOOK')
        );

        setLoaded(true);
    };


    /**
     * The disabled state of the form.
     */
    const isDisabled = readOnly || disabled;

    return (
        <Step step={index} value={step}>
            <LogoHeading
                title={'Employee Handbook'}
                onClose={onClose}
            />

            <Box className={'columns__1'}>
                <Box>
                    Instructions: Go to <a href={'www.ReliantStaffing.com'}>our website</a>, click on the
                    "Employees" tab, then click on "Forms & Information." Click on and read the employee
                    handbook.
                </Box>
                <Box>
                    <b>Communication</b>
                </Box>
                <Box>
                    By signing this form, you also acknowledge that you have read and understand our communication
                    policy, and agree to receive all communications from Reliant Staffing via email, text message,
                    phone call, mobile notification, and/or fax. These communications may include, but are not
                    limited to, employee information regarding their current schedule, availability, credentialing,
                    job openings, extra shifts, and other information related to their employment with Reliant
                    Staffing. Additionally, the information that you include within your application could be subject
                    to validation through our email and text campaigns, including reference checks and other forms
                    of employment validation. If you decide to opt out at a later date, you may do so by contacting
                    the Reliant Staffing office at {Formatter.phone(Settings.phoneNumber)}.
                </Box>
                <Box>
                    <b>Acknowledgment of Receipt</b>
                </Box>
                <Box>
                    I, {readOnly ? employee.displayName : user.displayName}, have printed a copy of the time sheet
                    online and I have received a copy of
                    Reliant Staffing LLC employee handbook ver2018. I understand that I am responsible for
                    reading the information contained within the handbook. I understand that the handbook is
                    intended to provide me with a general overview of the company’s policies and procedures. I
                    acknowledge that nothing in the handbook is to be interpreted as contract, expressed or
                    implied, or an inducement for employment, nor does it guarantee my employment for any
                    specific period.
                </Box>

                {!contentOnly && (
                    <>
                        <InputSignature
                            value={signature}
                            label={'Please sign here:'}
                            disabled={isDisabled}
                            onChange={setSignature}
                            scaleFactor={2}
                        />

                        {!readOnly && (
                            <StepControls
                                step={step}
                                disabled={isDisabled || !signature}
                                onStepChange={onStepChange}
                            />
                        )}
                    </>
                )}
            </Box>
        </Step>
    );
};

export default EmployeeHandbookStep;