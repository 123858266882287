import React from "react";

import Formatter from "../../Global/Formatter";
import App from "../../Global/App";

/**
 * PhoneLink component.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
const PhoneLink = (props) => {
    const {
        style,                  // {Object} Optional style attributes to pass to the link.
        children,               // {Element} The children to render.
        phoneNumber,            // {String} The phone number to format as a link.
    } = props;

    /**
     * Handles a click on the phone number.
     */
    const handleIOSClick = () => {
        window.open(`tel:${phoneNumber}`, '_system');
    };

    return phoneNumber ? (
        <>
            {App.isIOS() ? (
                <a onClick={handleIOSClick} style={style || {}}>
                    {children || Formatter.phoneString(phoneNumber)}
                </a>
            ) : (
                <a href={`tel: ${phoneNumber}`} style={style || {}}>
                    {children || Formatter.phoneString(phoneNumber)}
                </a>
            )}
        </>
    ) : null;
};

export default PhoneLink;