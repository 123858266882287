import React from "react";

import CallLog from "../../Models/CallLog";
import TableLayout from "../../Components/Layouts/TableLayout";

import LocalPhoneIcon from '@mui/icons-material/LocalPhone';

/**
 * CallLogs component.
 *
 * @returns {*}
 * @constructor
 */
const CallLogs = () => (
    <TableLayout
        icon={<LocalPhoneIcon/>}
        model={CallLog}
        heading={'Call Logs'}
        condensed
    />
);

export default CallLogs;