import React, {useState} from "react";

import API from "../../Global/API";
import Template from "../../Models/Template";
import TextEditor from "../../Components/Input/TextEditor";
import DialogHeading from "../../Components/Typography/DialogHeading";

import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import FormLabel from "@mui/material/FormLabel";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";

/**
 * TemplateForm component.
 *
 * @returns {*}
 * @constructor
 */
const TemplateForm = (props) => {
    const {
        open,           // {Boolean} Indicates whether or not the form is open.
        record,         // {Object} The form context object, handles pre-loading the form.
        onSave,         // {Function} An onSave callback, triggered whenever the form is saved.,
        onClose,        // {Function} An onClose callback, triggered whenever the modal is closed.
    } = props;

    const [fields, setFields] = useState(Template.getInstance(record));
    const [isLoading, setLoading] = useState(false);

    /**
     * Performs the save via the API.
     */
    const doSave = async () => {
        setLoading(true);

        const response = fields.id ?
            await API.put(`template/${fields.id}`, fields) :
            await API.post('templates', fields);

        setLoading(false);

        if (onSave) {
            onSave(response);
        }
    };


    /**
     * Updates a particular form value.
     *
     * @param key
     * @param value
     */
    const setValue = (key, value) => {
        setFields({
            ...fields,
            [key]: value
        });
    };

    return (
        <Dialog
            open={open}
            scroll={'body'}
            onClose={onClose}
            maxWidth={'md'}
            fullWidth
        >
            <DialogHeading
                title={'Template Details'}
                noMargin
            />
            <DialogContent>
                <Box className={'columns__1'}>
                    <Box className={'columns__2_3'}>
                        <TextField
                            label={'Name'}
                            value={fields.name}
                            required
                            disabled={isLoading}
                            onChange={(event) => setValue('name', event.target.value)}
                        />
                        <FormControl>
                            <InputLabel required>Record Type</InputLabel>
                            <Select
                                label="Record Type"
                                value={fields.modelType}
                                required
                                disabled={isLoading}
                                onChange={(event) => setValue('modelType', event.target.value)}
                            >
                                {['Employee', 'Client'].map((option) =>
                                    <MenuItem
                                        key={option}
                                        value={option}
                                        children={option}
                                    />
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                    <TextField
                        label={'Subject'}
                        value={fields.subject}
                        disabled={isLoading}
                        onChange={(event) => setValue('subject', event.target.value)}
                    />
                    <TextEditor
                        value={fields.content}
                        onChange={(value) => setValue('content', value)}
                    />
                    <FormControl
                        component="fieldset"
                        variant="standard"
                    >
                        <FormLabel component="legend">Which types of messages are supported?</FormLabel>
                        <FormGroup>
                            <FormControlLabel
                                label={'SMS'}
                                control={
                                    <Checkbox
                                        onChange={event => setValue('isSms', event.target.checked)}
                                        defaultChecked={fields.isSms}
                                    />
                                }
                            />
                            <FormControlLabel
                                label={'Email'}
                                control={
                                    <Checkbox
                                        defaultChecked={fields.isEmail}
                                        onChange={event => setValue('isEmail', event.target.checked)}
                                    />
                                }
                            />
                            <FormControlLabel
                                label={'Chat'}
                                control={
                                    <Checkbox
                                        defaultChecked={fields.isChat}
                                        onChange={event => setValue('isChat', event.target.checked)}
                                    />
                                }
                            />
                        </FormGroup>
                    </FormControl>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={doSave}
                    children={'Save'}
                />
                <Button
                    color={'error'}
                    onClick={onClose}
                    children={'Close'}
                />
            </DialogActions>
        </Dialog>
    );
};

export default TemplateForm;