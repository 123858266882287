import App from "./App";

/**
 * Logger object.
 *
 * @constructor
 */
class Logger {
    /**
     * A generic info log entry.
     *
     * @param params
     */
    static info = (...params) => {
        console.info(...params);
    };


    /**
     * Emits a warning message.
     *
     * @param params
     */
    static warn = (...params) => {
        console.warn(...params);
    };


    /**
     * A generic info log entry.
     *
     * @param params
     */
    static debug = (...params) => {
        if (!App.isLocal() && !window.isLoggingEnabled) {
            return;
        }

        console.info(...params);
    };
}

export default Logger;