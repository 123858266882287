import React from "react";

/**
 * ColGroup component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ColGroup = (props) => {
    const {
        model,          // {Model} The appropriate model definition.
    } = props;

    const colGroup = [];
    const layout = model.getLayoutProperties();
    const actions = model.hasActions();

    for (let i in layout) {
        if (!layout.hasOwnProperty(i)) {
            continue;
        }

        const property = layout[i];
        const key = property.getKey();

        // Handle column sizing.
        colGroup.push(
            <col key={key} width={property.width || 'initial'}/>
        );
    }

    // Append the actions column if there is one.
    if (!!actions) {
        colGroup.push(<col key={'action'} width={50}/>);
    }

    return (
        <colgroup>{colGroup}</colgroup>
    );
};

export default ColGroup;