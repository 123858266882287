import React from "react";

import API from "../../../Global/API";
import Course from "../../../Models/Course";
import TextEditor from "../../../Components/Input/TextEditor";
import FileAvatar from "../../../Components/Input/FileAvatar";
import SectionHeading from "../../../Components/Typography/SectionHeading";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import ButtonGroup from "@mui/material/ButtonGroup";
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';

/**
 * CourseOverviewForm component.
 *
 * @returns {*}
 * @constructor
 */
const CourseOverviewForm = (props) => {
    const {
        record,             // {Object} The default record from the database.
        loading,            // {Boolean} A loading indicator flag.
        onChange            // {Function} A change callback to share the record back and forth with the editor.
    } = props;

    /**
     * Emits a change event upwards throughout the editor.
     *
     * @param updated
     */
    const handleChange = (updated) => {
        if (onChange) {
            onChange(updated);
        }
    };


    /**
     * Updates a particular form value.
     *
     * @param key
     * @param value
     */
    const setValue = (key, value) => {
        record[key] = value;
        handleChange(record);
    };

    return (
        <Box className={'columns__1'}>
            <SectionHeading title={'Course Details'}/>
            <Box className={'d-flex'}>
                <FileAvatar
                    width={128}
                    model={Course}
                    field={'image'}
                    height={128}
                    record={record}
                    variant={'rounded'}
                    disabled={loading}
                    onChange={path => setValue('image', path)}
                    horizontal
                    helperText={'Featured Image'}
                >
                    <PhotoCameraIcon fontSize={'large'}/>
                </FileAvatar>

                <Box className={'flex__grow columns__1'} sx={{marginLeft: '1.5em'}}>
                    <Box className={'d-flex__justify'}>
                        <TextField
                            label="Name"
                            value={record.name || ''}
                            required
                            fullWidth
                            disabled={loading}
                            onChange={event => setValue('name', event.target.value)}
                        />
                        <ButtonGroup
                            variant="outlined"
                            disabled={loading}
                            className={'ml__3'}
                        >
                            <Button
                                variant={!record.isPublished ? 'contained' : 'outlined'}
                                onClick={() => setValue('isPublished', false)}
                            >Draft</Button>
                            <Button
                                variant={!!record.isPublished ? 'contained' : 'outlined'}
                                onClick={() => setValue('isPublished', true)}
                            >Published</Button>
                        </ButtonGroup>
                    </Box>
                    <TextField
                        rows={3}
                        label="Short Description"
                        value={record.shortDescription || ''}
                        disabled={loading}
                        onChange={event => setValue('shortDescription', event.target.value)}
                        multiline
                        fullWidth
                    />
                </Box>
            </Box>
            <TextEditor
                value={record.longDescription}
                disabled={loading}
                onChange={value => setValue('longDescription', value)}
            />
        </Box>
    );
};

export default CourseOverviewForm;