import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import Step from "../../../Components/Step";
import {useAuth} from "../../../Global/Auth";
import Formatter from "../../../Global/Formatter";
import InputPhone from "../../../Components/Input/InputPhone";
import LogoHeading from "../../../Components/Typography/LogoHeading";
import StepControls from "../../../Components/StepControls";
import DialogHeading from "../../../Components/Typography/DialogHeading";
import InputSignature from "../../../Components/Input/InputSignature";
import withSynchronizedSettings from "../Abstract/withSynchronizedSettings";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import Radio from "@mui/material/Radio";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import InputMask from 'react-input-mask';
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import GppMaybeIcon from '@mui/icons-material/GppMaybe';
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";

/**
 * EmploymentEligibilityStep component.
 *
 * @returns {*}
 * @constructor
 */
const EmploymentEligibilityStep = withSynchronizedSettings((props) => {
    const {
        step,                   // {Number} The current step for the user.
        index,                  // {Number} The expected step number.
        doSync,                 // {Function} The synchronization callback.
        employee,               // {Object} The employee record for added context
        readOnly,               // {Boolean} Whether the form is disabled.
        disabled,               // {Boolean} Whether the step is disabled from input.
        onValidate,             // {Function} The post-validation callback.
        doValidate,             // {Function} Triggers the validation event.
        getSetting,             // {Function} Retrieves a particular setting value.
        isSubmitted,            // {Boolean} Indicates whether the parent form has been submitted.
        onStepChange,           // {Function} The step change callback.
        loadedSettings,         // {Object} The previously loaded settings.
    } = props;

    const {user, isScope} = useAuth();
    const [city, setCity] = useState(getSetting('HP_EE_CITY', ''));
    const [error, setError] = useState('');
    const [state, setState] = useState(getSetting('HP_EE_STATE', ''));
    const [lastName, setLastName] = useState(getSetting('HP_EE_LAST_NAME', ''));
    const [signature, setSignature] = useState(getSetting('HP_EE_SIGNATURE', ''));
    const [firstName, setFirstName] = useState(getSetting('HP_EE_FIRST_NAME', ''));
    const [hasSsnError, setSsnError] = useState(false);
    const [isAltLoaded, setAltLoaded] = useState(false);
    const [postalCode, setPostalCode] = useState(getSetting('HP_EE_POSTAL_CODE', ''));
    const [phoneNumber, setPhoneNumber] = useState(getSetting('HP_EE_PHONE_NUMBER', ''));
    const [workExpDate, setWorkExpDate] = useState(getSetting('HP_EE_WORK_EXP_DATE', ''));
    const [workANumber, setWorkANumber] = useState(getSetting('HP_EE_WORK_A_NUMBER', ''));
    const [dateOfBirth, setDateOfBirth] = useState(getSetting('HP_EE_DATE_OF_BIRTH', ''));
    const [emailAddress, setEmailAddress] = useState(getSetting('HP_EE_EMAIL_ADDRESS', ''));
    const [middleInitial, setMiddleInitial] = useState(getSetting('HP_EE_MIDDLE_INITIAL', ''));
    const [citizenStatus, setCitizenStatus] = useState(getSetting('HP_EE_CITIZEN_STATUS', ''));
    const [streetAddress, setStreetAddress] = useState(getSetting('HP_EE_STREET_ADDRESS', ''));
    const [workI94Number, setWorkI94Number] = useState(getSetting('HP_EE_WORK_I94_NUMBER', ''));
    const [socialSecurity, setSocialSecurity] = useState(getSetting('HP_EE_SOCIAL_SECURITY', ''));
    const [apartmentNumber, setApartmentNumber] = useState(getSetting('HP_EE_APARTMENT_NUMBER', ''));
    const [altSocialSecurity, setAltSocialSecurity] = useState('');
    const [workPassportNumber, setWorkPassportNumber] = useState(getSetting('HP_EE_WORK_PASSPORT_NUMBER', ''));

    /**
     * Synchronize each of the form parameters.
     */
    useEffect(() => {
        doSync('HP_EE_CITY', city);
    }, [city]);

    useEffect(() => {
        doSync('HP_EE_STATE', state);
    }, [state]);

    useEffect(() => {
        doSync('HP_EE_LAST_NAME', lastName);
    }, [lastName]);

    useEffect(() => {
        doSync('HP_EE_SIGNATURE', signature);
    }, [signature]);

    useEffect(() => {
        doSync('HP_EE_FIRST_NAME', firstName);
    }, [firstName]);

    useEffect(() => {
        doSync('HP_EE_POSTAL_CODE', postalCode);
    }, [postalCode]);

    useEffect(() => {
        doSync('HP_EE_WORK_A_NUMBER', workANumber);
    }, [workANumber]);

    useEffect(() => {
        doSync('HP_EE_PHONE_NUMBER', phoneNumber);
    }, [phoneNumber]);

    useEffect(() => {
        doSync('HP_EE_WORK_EXP_DATE', workExpDate);
    }, [workExpDate]);

    useEffect(() => {
        doSync('HP_EE_DATE_OF_BIRTH', dateOfBirth);
    }, [dateOfBirth]);

    useEffect(() => {
        doSync('HP_EE_EMAIL_ADDRESS', emailAddress);
    }, [emailAddress]);

    useEffect(() => {
        doSync('HP_EE_MIDDLE_INITIAL', middleInitial);
    }, [middleInitial]);

    useEffect(() => {
        doSync('HP_EE_WORK_I94_NUMBER', workI94Number);
    }, [workI94Number]);

    useEffect(() => {
        doSync('HP_EE_CITIZEN_STATUS', citizenStatus);
    }, [citizenStatus]);

    useEffect(() => {
        doSync('HP_EE_STREET_ADDRESS', streetAddress);
    }, [streetAddress]);

    useEffect(() => {
        doSync('HP_EE_SOCIAL_SECURITY', socialSecurity);
    }, [socialSecurity]);

    useEffect(() => {
        doSync('HP_EE_APARTMENT_NUMBER', apartmentNumber);
    }, [apartmentNumber]);

    useEffect(() => {
        doSync('HP_EE_WORK_PASSPORT_NUMBER', workPassportNumber);
    }, [workPassportNumber]);

    useEffect(() => {
        if (!isAltLoaded) {
            return;
        }

        doSync('HP_W4_SOCIAL_SECURITY', altSocialSecurity, {
            /**
             * Need to provide an override here since this setting falls outside our
             * typical prefix-bound settings used on this form.
             */
            override: {
                ...loadedSettings,
                HP_W4_SOCIAL_SECURITY: altSocialSecurity
            }
        });
    }, [altSocialSecurity]);


    /**
     * Synchronize any default values.
     */
    useEffect(() => {
        if (disabled || readOnly) {
            return;
        }

        if (!firstName) {
            setFirstName(employee.firstName);
        }

        if (!lastName) {
            setLastName(employee.lastName);
        }

        if (!streetAddress) {
            setStreetAddress(employee.addressLine1);
        }

        if (!apartmentNumber) {
            setApartmentNumber(employee.apartmentNumber);
        }

        if (!city) {
            setCity(employee.city);
        }

        if (!state) {
            setState('PA');
        }

        if (!postalCode) {
            setPostalCode(employee.postalCode);
        }

        if (!emailAddress) {
            setEmailAddress(employee.emailAddress);
        }

        if (!dateOfBirth) {
            setDateOfBirth(Formatter.date(employee.birthDate, '', false));
        }

        if (!phoneNumber) {
            setPhoneNumber(employee.phoneNumber)
        }

        getValidationSsn();
    }, [disabled]);


    /**
     * Finds the comparative value for validation purposes.
     *
     * @returns {Promise<void>}
     */
    const getValidationSsn = async () => {
        // Ignore this lookup for non-employees, since they won't actually be filling out this form.
        if (!isScope('Employee')) {
            return;
        }

        const response = await API.get('employee-settings', {
            $top: 1,
            $filter: `key eq {HP_W4_SOCIAL_SECURITY}`,
            $orderby: 'id desc'
        });

        if (response && response.length) {
            setAltSocialSecurity(response[0].value || '');
            setAltLoaded(true);
        }
    };


    /**
     * Handles the form validation.
     */
    const handleValidation = async () => {
        if(citizenStatus === 'authorized'){
            if(!workANumber && !workI94Number && !workPassportNumber){
                return setError('Please provide either a USCIS A-Number, Form I-94 Admission Number, or Foreign Passport Number in the fields above.');
            }
        }

        if (socialSecurity !== altSocialSecurity) {
            return setSsnError(true);
        }

        setSsnError(false);

        doValidate([
            city,
            state,
            lastName,
            firstName,
            postalCode,
            dateOfBirth,
            phoneNumber,
            emailAddress,
            streetAddress,
            citizenStatus,
            socialSecurity,
        ]);

        // Synchronize the last four digits of the employee's SSN.
        if (isScope('Employee')) {
            await API.put(`employees/${user.id}`, {
                ssn: socialSecurity.slice(-4)
            });
        }
    };


    /**
     * The disabled state of each input.
     */
    const isDisabled = disabled || readOnly;


    /**
     * Closes the error dialogue.
     */
    const handleErrorClose = () => {
        setError('');
    };


    /**
     * Closes the SSN error dialogue.
     */
    const handleSsnErrorClose = () => {
        setSsnError(false);
    };

    return (
        <Step step={index}
            value={step}>
            <LogoHeading title={'Employment Eligibility Verification'}/>

            <Box className={'columns__1'}>
                <Box>
                    It is illegal to discriminate against work-authorized individuals. Employers CANNOT specify
                    which document(s) an employee may present to establish employment authorization and
                    identity. The refusal to hire or continue to employ and individual because the documentation
                    presented has a future expiration date may also constitute illegal discrimination.
                </Box>

                <FormControl error={isSubmitted && !firstName}>
                    <FormLabel
                        required
                        children={'First Name'}
                        className={'d-block mb__2'}
                    />
                    <TextField
                        value={firstName}
                        error={isSubmitted && !firstName}
                        required
                        onChange={event => setFirstName(event.target.value)}
                        disabled={isDisabled}
                        fullWidth
                        placeholder={'John'}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel className={'d-block mb__2'}>Middle Initial</FormLabel>
                    <TextField
                        value={middleInitial}
                        disabled={isDisabled}
                        onChange={event => setMiddleInitial(event.target.value)}
                        fullWidth
                    />
                </FormControl>
                <FormControl error={isSubmitted && !lastName}>
                    <FormLabel
                        required
                        children={'Last Name'}
                        className={'d-block mb__2'}
                    />
                    <TextField
                        value={lastName}
                        error={isSubmitted && !lastName}
                        required
                        disabled={isDisabled}
                        onChange={event => setLastName(event.target.value)}
                        fullWidth
                        placeholder={'Doe'}
                    />
                </FormControl>
                <FormControl error={isSubmitted && !streetAddress}>
                    <FormLabel
                        required
                        children={'Street Address'}
                        className={'d-block mb__2'}
                    />
                    <TextField
                        value={streetAddress}
                        error={isSubmitted && !streetAddress}
                        required
                        disabled={isDisabled}
                        onChange={event => setStreetAddress(event.target.value)}
                        fullWidth
                        placeholder={'1 Example Road'}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel
                        children={'Apartment Number'}
                        className={'d-block mb__2'}
                    />
                    <TextField
                        value={apartmentNumber}
                        disabled={isDisabled}
                        onChange={event => setApartmentNumber(event.target.value)}
                        fullWidth
                    />
                </FormControl>
                <FormControl error={isSubmitted && !city}>
                    <FormLabel
                        required
                        children={'City / Town'}
                        className={'d-block mb__2'}
                    />
                    <TextField
                        value={city}
                        error={isSubmitted && !city}
                        required
                        disabled={isDisabled}
                        onChange={event => setCity(event.target.value)}
                        fullWidth
                        placeholder={'Pittsburgh'}
                    />
                </FormControl>
                <FormControl error={isSubmitted && !state}>
                    <FormLabel
                        required
                        children={'State'}
                        className={'d-block mb__2'}
                    />
                    <TextField
                        value={state}
                        error={isSubmitted && !state}
                        onChange={event => setState(event.target.value)}
                        disabled={isDisabled}
                        required
                        fullWidth
                        placeholder={'PA'}
                    />
                </FormControl>
                <FormControl error={isSubmitted && !postalCode}>
                    <FormLabel
                        required
                        children={'Postal Code'}
                        className={'d-block mb__2'}
                    />
                    <TextField
                        value={postalCode}
                        error={isSubmitted && !postalCode}
                        required
                        disabled={isDisabled}
                        onChange={event => setPostalCode(event.target.value)}
                        fullWidth
                        placeholder={'XXXXX'}
                    />
                </FormControl>
                <FormControl error={isSubmitted && !emailAddress}>
                    <FormLabel
                        required
                        children={'Email Address'}
                        className={'d-block mb__2'}
                    />
                    <TextField
                        value={emailAddress}
                        error={isSubmitted && !emailAddress}
                        required
                        disabled={isDisabled}
                        onChange={event => setEmailAddress(event.target.value)}
                        fullWidth
                        placeholder={'info@example.com'}
                    />
                </FormControl>

                <FormControl error={isSubmitted && !dateOfBirth}>
                    <FormLabel
                        required
                        children={'Date of Birth'}
                        className={'d-block mb__2'}
                    />
                    <TextField
                        value={dateOfBirth}
                        error={isSubmitted && !dateOfBirth}
                        required
                        disabled={isDisabled}
                        onChange={event => setDateOfBirth(event.target.value)}
                        fullWidth
                        placeholder={'MM/DD/YYYY'}
                    />
                </FormControl>

                <FormControl error={isSubmitted && !socialSecurity}>
                    <FormLabel
                        required
                        children={'Social Security Number'}
                        className={'d-block mb__2'}
                    />

                    {/* For employees reviewing their own I9, we'll want to display only the last four here. */}
                    {readOnly && isScope('Employee') ? (
                        <TextField
                            value={Formatter.ssn(socialSecurity)}
                            disabled={true}
                            placeholder={'XXX-XX-XXXX'}
                        />
                    ) : (
                        <InputMask
                            mask="999-99-9999"
                            value={socialSecurity}
                            error={isSubmitted && !socialSecurity}
                            required
                            disabled={isDisabled}
                            onChange={event => setSocialSecurity(event.target.value)}
                            maskChar=""
                            fullWidth
                        >
                            {(inputProps) => (
                                <TextField
                                    {...inputProps}
                                    disabled={isDisabled}
                                    placeholder={'XXX-XX-XXXX'}
                                />
                            )}
                        </InputMask>
                    )}
                </FormControl>

                <FormControl error={isSubmitted && !phoneNumber}>
                    <FormLabel
                        required
                        children={'Phone Number'}
                        className={'d-block mb__2'}
                    />
                    <InputPhone
                        value={phoneNumber}
                        error={isSubmitted && !phoneNumber}
                        required
                        disabled={isDisabled}
                        onChange={event => setPhoneNumber(event.target.value)}
                        fullWidth
                        placeholder={'(412) 555-1234'}
                    />
                </FormControl>

                <Box>I am aware that federal law provides for imprisonment and/or fines for false statements or
                    use of false documents in connection with the completion of this form.</Box>

                <FormControl error={isSubmitted && !citizenStatus}>
                    <FormLabel
                        required
                        children={'I attest, under penalty of perjury, that I am one of the following:'}
                        className={'d-block mb__2'}
                    />
                    <RadioGroup
                        row
                        value={citizenStatus}
                        onChange={event => setCitizenStatus(event.target.value)}
                    >
                        <FormControlLabel
                            value="citizen"
                            label="A citizen of the United States."
                            control={<Radio disabled={isDisabled}/>}
                        />
                        <FormControlLabel
                            value="noncitizen"
                            label="A noncitizen national of the United States."
                            control={<Radio disabled={isDisabled}/>}
                        />
                        <FormControlLabel
                            value="permanent"
                            label="A lawful permanent resident."
                            control={<Radio disabled={isDisabled}/>}
                        />
                        <FormControlLabel
                            value="authorized"
                            label="A noncitizen (other than the options above), authorized to work until a certain date."
                            control={<Radio disabled={isDisabled}/>}
                        />
                    </RadioGroup>
                </FormControl>

                {citizenStatus === 'authorized' && (
                    <Box className={'well__container p__3 columns__1'}>
                        <Box>Since you've selected option 4 above, we require some additional information.</Box>

                        <FormControl>
                            <FormLabel
                                children={'Expiration Date (if any)'}
                                className={'d-block mb__2'}
                            />
                            <DatePicker
                                value={workExpDate ? dayjs(workExpDate) : null}
                                disabled={isDisabled}
                                onChange={event => setWorkExpDate(event ? event.format('YYYY-MM-DD') : '')}
                                fullWidth
                            />
                        </FormControl>

                        <Box>Additionally, you must also provide <b>at least one</b> of the following:</Box>

                        <FormControl>
                            <FormLabel
                                children={'USCIS A-Number'}
                                className={'d-block mb__2'}
                            />
                            <TextField
                                value={workANumber}
                                disabled={isDisabled}
                                onChange={event => setWorkANumber(event.target.value)}
                                fullWidth
                            />
                        </FormControl>

                        <FormControl>
                            <FormLabel
                                children={'Form I-94 Admission Number'}
                                className={'d-block mb__2'}
                            />
                            <TextField
                                value={workI94Number}
                                disabled={isDisabled}
                                onChange={event => setWorkI94Number(event.target.value)}
                                fullWidth
                            />
                        </FormControl>

                        <FormControl>
                            <FormLabel
                                children={'Foreign Passport Number and Country of Issuance'}
                                className={'d-block mb__2'}
                            />
                            <TextField
                                value={workPassportNumber}
                                disabled={isDisabled}
                                onChange={event => setWorkPassportNumber(event.target.value)}
                                fullWidth
                            />
                        </FormControl>
                    </Box>
                )}

                <InputSignature
                    value={signature}
                    label={'Please sign here:'}
                    disabled={isDisabled}
                    onChange={setSignature}
                    scaleFactor={2}
                />

                {!readOnly && (
                    <StepControls
                        step={step}
                        onBack={onStepChange}
                        onNext={!isDisabled ? handleValidation : onValidate}
                        disabled={isDisabled || !signature}
                    />
                )}
            </Box>

            <Dialog
                open={hasSsnError}
                scroll={'body'}
                onClose={handleErrorClose}
                maxWidth={'md'}
                fullWidth
            >
                <DialogHeading
                    icon={<GppMaybeIcon className={'mr__1'}/>}
                    title={'Please Review'}
                    noMargin
                />
                <DialogContent>
                    <Box className={'columns__1'}>
                        <Box>
                            The social security number that you entered does not match the one on your W4. Please review
                            your answers and try again.
                        </Box>

                        <FormControl error={!socialSecurity}>
                            <FormLabel className={'d-block mb__2'}
                                required>Social Security Number</FormLabel>
                            <InputMask
                                mask="999-99-9999"
                                value={socialSecurity}
                                error={!socialSecurity}
                                required
                                disabled={isDisabled}
                                onChange={event => setSocialSecurity(event.target.value)}
                                maskChar=""
                                fullWidth
                            >
                                {(inputProps) => (
                                    <TextField
                                        {...inputProps}
                                        disabled={isDisabled}
                                        placeholder={'XXX-XX-XXXX'}
                                    />
                                )}
                            </InputMask>
                        </FormControl>

                        <FormControl error={!altSocialSecurity}>
                            <FormLabel
                                className={'d-block mb__2'}
                                required
                            >Confirm Social Security</FormLabel>
                            <InputMask
                                mask="999-99-9999"
                                value={altSocialSecurity}
                                error={!altSocialSecurity}
                                required
                                disabled={isDisabled}
                                onChange={event => setAltSocialSecurity(event.target.value)}
                                maskChar=""
                                fullWidth
                            >
                                {(inputProps) => (
                                    <TextField
                                        {...inputProps}
                                        disabled={isDisabled}
                                        placeholder={'XXX-XX-XXXX'}
                                    />
                                )}
                            </InputMask>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={handleValidation}
                        children={'Save'}
                        disabled={!socialSecurity || !altSocialSecurity || socialSecurity !== altSocialSecurity}
                    />
                    <Button
                        color={'error'}
                        onClick={handleSsnErrorClose}
                        children={'Cancel'}
                    />
                </DialogActions>
            </Dialog>

            <Snackbar
                open={!!error}
                onClose={handleErrorClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={8000}
            >
                <Alert
                    sx={{width: '100%'}}
                    onClose={handleErrorClose}
                    children={error}
                    severity={'error'}
                />
            </Snackbar>
        </Step>
    );
});

export default EmploymentEligibilityStep;