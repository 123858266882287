import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';

/**
 * StepControls component.
 */
const StepControls = (props) => {
    const {
        step,               // {Number} The current step.
        onBack,             // {Function} A dedicated callback for the back button.
        onNext,             // {Function} A dedicated callback for the next button.
        disabled,           // {Boolean} Indicates whether the next step is disabled.
        onStepChange,       // {Function} The step change callback for the parent component.
    } = props;

    /**
     * Handles the "Next" button.
     */
    const handleNext = () => {
        const nextStep = step + 1;

        if (onNext) {
            return onNext(nextStep);
        }

        if (onStepChange) {
            return onStepChange(nextStep);
        }
    }


    /**
     * Handles the "Back" button.
     */
    const handleBack = () => {
        const lastStep = step - 1;

        if (onBack) {
            return onBack(lastStep);
        }

        if (onStepChange) {
            return onStepChange(lastStep);
        }
    };

    return (
        <Box className={step > 0 ? 'columns__2' : 'columns__1'}>
            {step > 0 && (
                <Button
                    size={'large'}
                    color={'error'}
                    variant={'outlined'}
                    onClick={handleBack}
                    children={'Back'}
                />
            )}

            <Button
                size={'large'}
                variant={'outlined'}
                onClick={handleNext}
                children={'Next'}
                disabled={disabled}
            />
        </Box>
    );
};

export default StepControls;