import React from "react";

import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";

/**
 * TabWrapper component.
 *
 * @constructor
 */
const TabHeading = (props) => {
    const {
        sx,                 // {Object} Any additional styles to apply to the tab component.
        tab,                // {Number} The current / active tab.
        actions,            // {Component} An optional actions component to include within the container.
        onChange,           // {Function} A callback function whenever the user changes tabs.
        children,           // {Component} The child components within the tabs.
        scrollable,         // {Boolean} Indicates if these tabs should be scrollable.
    } = props;

    return (
        <>
            <Box sx={{borderBottom: 1, borderColor: 'divider', display: 'flex'}}>
                {!!scrollable ? (
                    <Box sx={sx}>
                        <Tabs
                            sx={sx}
                            value={tab}
                            variant={'scrollable'}
                            onChange={onChange}
                            children={children}
                            scrollButtons
                            allowScrollButtonsMobile
                        />
                    </Box>
                ) : (
                    <Tabs
                        value={tab}
                        onChange={onChange}
                        children={children}
                    />
                )}
            </Box>

            {actions && <Box sx={{position: 'absolute', right: 5, top: 5}} children={actions}/>}
        </>
    );
};

export default TabHeading;