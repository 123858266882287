import React from "react";

import Page from "../../Components/Page";
import TimeSheetForm from "./Dashboard/TimeSheetForm";

/**
 * TimeSheet component.
 *
 * @returns {JSX.Element}
 * @constructor
 *
 * @description Provides an interface for employees to refer their friends.
 */
const TimeSheet = () => {
    return (
        <Page hideHeader>
            <TimeSheetForm addBackButton/>
        </Page>
    );
};

export default TimeSheet;