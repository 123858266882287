import React, {useState} from "react";

import Page from "../../Components/Page";
import TimeOffForm from "./TimeOff/TimeOffForm";
import ModalButton from "../../Components/ModalButton";
import LogoHeading from "../../Components/Typography/LogoHeading";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import ListItemText from "@mui/material/ListItemText";
import {useNavigate} from "react-router";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import TableContainer from "@mui/material/TableContainer";
import AssignmentIcon from '@mui/icons-material/Assignment';
import ScheduleSendIcon from '@mui/icons-material/ScheduleSend';

/**
 * TimeOff component.
 *
 * @returns {JSX.Element}
 * @constructor
 *
 * @description Provides an interface for employees to request benefits information.
 */
const TimeOff = () => {
    const navigate = useNavigate()
    const [isViewingForm, setViewingForm] = useState(false)

    /**
     * Handles opening / closing the form.
     */
    const handleFormOpen = () => setViewingForm(true);
    const handleFormClose = () => setViewingForm(false);

    return (
        <Page hideHeader>
            {isViewingForm ? (
                <TimeOffForm onBack={handleFormClose}/>
            ) : (
                <>
                    <LogoHeading
                        title={'Paid Time Off'}
                        actionSlot={
                            <Button
                                size={'small'}
                                variant={'outlined'}
                                onClick={() => navigate('/')}
                                children={'Back'}
                                className={'text__small'}
                            />
                        }
                    />

                    <Box>
                        <ListItemButton onClick={handleFormOpen}>
                            <ListItemAvatar>
                                <Avatar sx={{backgroundColor: 'var(--primary-color)'}}>
                                    <ScheduleSendIcon/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<b>Request Time Off</b>}
                                secondary={
                                    <Box className={'text__small'}>
                                        Use this interactive form to submit a time off request.
                                    </Box>
                                }
                            />
                        </ListItemButton>

                        <Box>
                            <Divider>OR</Divider>
                        </Box>

                        <ModalButton
                            size={'xs'}
                            label={(
                                <ListItemButton>
                                    <ListItemAvatar>
                                        <Avatar sx={{backgroundColor: 'var(--primary-color)'}}>
                                            <AssignmentIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<b>Review Our Policy</b>}
                                        secondary={
                                            <Box className={'text__small'}>
                                                Have a question about PTO? Start here.
                                            </Box>
                                        }
                                    />
                                </ListItemButton>
                            )}
                            doRender={() => (
                                <Box className={'columns__1'}>
                                    <h3 className={'mb__0'}>
                                        Time Off Policy
                                    </h3>

                                    <Box>
                                        PTO accrual is based on actual hours worked. One (1) hour of PTO will be accrued
                                        for every 35 hours worked. The employee may carry their days over to the
                                        following year, however, the hours worked restart at the beginning of each
                                        calendar year. The maximum amount of time that can be accumulated is forty (40)
                                        hours. Any accrued PTO time that exceeds 40 hours will be lost. PTO can be used
                                        in as few as one (1) hour increments. Employees may begin using accrued PTO
                                        beginning on the ninetieth (90) calendar day following the commencement of their
                                        employment.
                                    </Box>
                                    <Box>
                                        PTO accrued does not have to be used as actual time off; PTO is permitted to be
                                        cashed out. The maximum amount of PTO that can be used as actual time off or
                                        cashed out at one time is five (5) days within a thirty (30) day period. When
                                        PTO is being used for vacation time or being cashed out, an employee's request
                                        for PTO must be submitted to and approved at least four (4) weeks in advance by
                                        the Reliant Scheduling Team and the Payroll Department. In the event of a
                                        qualifying medical reason, the employee must reach out to the Reliant Scheduling
                                        Team to notify them of the need for time off as soon as they are aware. One (1)
                                        PTO day equals 7.5 hours.
                                    </Box>

                                    <TableContainer className={'table table--striped'}>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>
                                                        <Box>
                                                            PTO request for <b>vacation</b> must be made 4 weeks in advance. Paid at the 4th week.
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Box>
                                                            PTO request for <b>personal</b> must be made 4 weeks in advance. Paid at the 4th week.
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Box>
                                                            PTO request for <b>medical reasons</b> must be submitted with supporting medical documentation to validate the request. Once medical documentation is received, PTO will be paid in the next pay run.
                                                        </Box>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>

                                    <b>PTO rates are as follows:</b>

                                    <TableContainer className={'table table--striped'} disableGutters>
                                        <Table>
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell>
                                                        <Box>Certified Nursing Assistant: $13/hr.</Box>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Box>Licensed Practical Nurse: $22/hr.</Box>
                                                    </TableCell>
                                                </TableRow>
                                                <TableRow>
                                                    <TableCell>
                                                        <Box>Registered Nurse: $25/hr.</Box>
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Box>
                            )}
                            bodyStyle={{paddingTop: 0}}
                            component={'div'}
                            closeButton
                        />
                    </Box>
                </>
            )}
        </Page>
    );
};

export default TimeOff;