import React from "react";

import App from "../../Global/App";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

/**
 * InputSelect component.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const InputSelect = props => {
    const {
        value,              // {String} The value of the input.
        options,            // {Array} The options to render as an array of {value, label} objects.
        placeholder,        // {String} The placeholder for the input.
    } = props;

    /**
     * Indicates if we should handle as a mobile / native select input.
     *
     * @type {boolean}
     */
    const isNative = App.isIOS();

    return (
        <Select
            {...props}
            value={value || value === 0 ? value : ''}
            native={isNative}
        >
            {isNative ? (
                <>
                    <option/>
                    {options.map(option => (
                        <option
                            key={option.value}
                            value={option.value}
                            children={option.label}
                        />
                    ))}
                </>
            ) : (
                options.map(option => (
                    <MenuItem
                        key={option.value}
                        value={option.value}
                        children={option.label}
                    />
                ))
            )}
        </Select>
    );
};

export default InputSelect;