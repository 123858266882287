import React, {useEffect, useMemo, useState} from "react";

import App from "../Global/App";
import API from "../Global/API";
import Page from "../Components/Page";
import {useAuth} from "../Global/Auth";
import Formatter from "../Global/Formatter";
import AdminDashboard from "./Admin/Dashboard";
import EmployeeDashboard from "./Employee/Dashboard";

import Box from "@mui/material/Box";
import GroupIcon from '@mui/icons-material/Group';
import MapsHomeWorkIcon from '@mui/icons-material/MapsHomeWork';

/**
 * Dashboard component.
 *
 * @returns {*}
 * @constructor
 */
const Dashboard = () => {
    const [greeting] = useState(['Welcome', 'Welcome back', 'Hey there', 'What\'s up', 'Greetings', 'You\'re back'].random());
    const [clientCount, setClientCount] = useState(0);
    const [employeeCount, setEmployeeCount] = useState(0);
    const {user, isHired, isScope, isInviteCompleted, hasPermissionTo} = useAuth();

    /**
     * Load ancillary data on component mount.
     */
    useEffect(() => {
        if (!bIsAdministrator) {
            doRefreshEmployee();
            return;
        }
        else{
            doRefreshAdministrator();
        }

        getClientCount();
        getEmployeeCount();
    }, []);


    /**
     * Refreshes the authenticated employee record in the state storage.
     *
     * @returns {Promise<void>}
     */
    const doRefreshEmployee = async () => {
        const employee = await API.get(`employees/${user.id}`);

        if (!employee || !employee.id) {
            return;
        }

        window.dispatchEvent(
            new CustomEvent('userupdated', {
                detail: {
                    ...user,
                    ...employee
                }
            })
        );
    };


    /**
     * Reloads the administrative user.
     *
     * @returns {Promise<void>}
     */
    const doRefreshAdministrator = async () => {
        const record = await API.get(`users/${user.id}`, {
            $expand: 'permissions($top=250)',
        });

        if (!record || !record.id) {
            return;
        }

        window.dispatchEvent(
            new CustomEvent('userupdated', {
                detail: {
                    ...user,
                    ...record,
                    permissions: record.permissions.map(permission => permission.permission)
                }
            })
        );
    };


    /**
     * Counts employee records via the API.
     *
     * @returns {Promise<void>}
     */
    const getEmployeeCount = async () => {
        if(!hasPermissionTo('VIEW_REPORTS')){
            return;
        }

        const response = await API.get('employees/count');
        setEmployeeCount(response.count);
    };


    /**
     * Counts client records via the API.
     *
     * @returns {Promise<void>}
     */
    const getClientCount = async () => {
        if(!hasPermissionTo('VIEW_REPORTS')){
            return;
        }

        const response = await API.get('clients/count');
        setClientCount(response.count);
    };


    /**
     * Various parameters which influence what the dashboard should represent.
     *
     * @type {boolean}
     */
    const firstName = user.firstName;
    const bIsEmployee = isScope('Employee');
    const introPhrase = !!firstName ? `, ${firstName}` : '!';
    const bIsAdministrator = isScope('User');
    const bIsUnHiredEmployee = bIsEmployee && !isHired();
    const bIsInviteCompleted = bIsUnHiredEmployee && isInviteCompleted();
    const bIsGreetingQuestion = ['What\'s up', 'How are you', 'How\'s it going'].includes(greeting);
    const greetingPunctuation = bIsGreetingQuestion ? '?' : (bIsInviteCompleted ? '' : '');

    /**
     * The inner dashboard content.
     *
     * @type {unknown}
     */
    const content = useMemo(() =>
        App.isCordova() ? (
            <EmployeeDashboard/>
        ) : (
            <>
                {bIsAdministrator && <AdminDashboard/>}
                {bIsEmployee && <EmployeeDashboard/>}
            </>
        ), [user]);


    /**
     * The header content override.
     *
     * @type {unknown}
     */
    const headerContent = useMemo(() => (
        bIsInviteCompleted && (
            <Box className={'w__100 d-flex__justify'}>
                <h2>
                    {greeting}{introPhrase}{greetingPunctuation}
                </h2>
                <h2>👋</h2>
            </Box>
        )
    ), [user]);


    /**
     * The heading text content.
     *
     * @type {unknown}
     */
    const headingContent = useMemo(() => (
        !bIsInviteCompleted && (
            !bIsUnHiredEmployee && (
                <Box>{greeting}{introPhrase}{greetingPunctuation}</Box>
            )
        )
    ), [user]);

    return (
        <Page
            header={headerContent}
            heading={headingContent}
            controls={
                bIsAdministrator && hasPermissionTo('VIEW_REPORTS') ? (
                    <Box>
                        <Box className={'breadcrumbs'} sx={{padding: '0.3em'}}>
                            <div className={'crumb'}>
                                <GroupIcon
                                    fontSize={'small'}
                                    className={'mr__1'}
                                /> {Formatter.number(employeeCount)}
                            </div>
                            <div className={'divider'}/>
                            <div className={'crumb'}>
                                <MapsHomeWorkIcon
                                    fontSize={'small'}
                                    className={'mr__1'}
                                /> {Formatter.number(clientCount)}
                            </div>
                        </Box>
                    </Box>
                ) : null
            }
            children={content}
        />
    );
};

export default Dashboard;