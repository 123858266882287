import React, {useEffect, useMemo, useState} from "react";

import API from "../../Global/API";
import Page from "../Page";
import State from "../../Global/State";
import Logger from "../../Global/Logger";
import DataTable from "./Index/DataTable";
import {useAuth} from "../../Global/Auth";
import Formatter from "../../Global/Formatter";
import useDebounce from "../../Hooks/useDebounce";
import FilterDrawer from "./Index/FilterDrawer";
import QuickFilters from "./Index/QuickFilters";

import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import AddIcon from '@mui/icons-material/Add';
import Snackbar from "@mui/material/Snackbar";
import MenuIcon from '@mui/icons-material/Menu';
import TextField from "@mui/material/TextField";
import LinearProgress from "@mui/material/LinearProgress";
import {useMediaQuery, useTheme} from "@mui/material";

/**
 * TableLayout component.
 *
 * @returns {*}
 * @constructor
 */
const TableLayout = (props) => {
    const {
        icon,                           // {Component} An icon component to append to the header.
        model,                          // {Model} The respective model for the layout.
        heading,                        // {String} The text label of the represented resource.
        useLoader,                      // {Boolean} If enabled, will show a loading indicator at the top of the page.
        filterSlot,                     // {Component} An override for the default quick filter component.
        applySearch,                    // {Function} An optional search function to include.
        resultLabel,                    // {String} An optional label to display next to the count.
        hideFilters,                    // {Boolean} Whether we should hide the default filter features.
        controlSlot,                    // {Component} An optional slot for internal controls.
        filterSlotEnd,                  // {Component} An override for the default quick filter component.
        formPermission,                 // {String} The permission required to access the form, if any.
    } = props;

    const cacheKeys = model.getCacheKeys();
    const {hasPermissionTo} = useAuth();
    const [query, setQuery] = useState(model.getQuery());
    const [count, setCount] = useState(State.get(cacheKeys.lastCount) || 0);
    const [search, setSearch] = useState(null);
    const [tableKey, setTableKey] = useState(0);
    const [isLoading, setLoading] = useState(true);
    const [formOpen, setFormOpen] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [filterOpen, setFilterOpen] = useState(!!State.get(cacheKeys.filterDrawer));
    const [filterCount, setFilterCount] = useState(0);

    /**
     * Indicates if the layout is intended to be searchable.
     *
     * @type {boolean}
     */
    const searchable = !!applySearch;


    /**
     * Variables for rendering different layouts.
     *
     * @type {Theme}
     */
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    /**
     * Update the query on search.
     */
    useEffect(() => {
        if (!searchable || search === null) {
            return;
        }

        debounceSearch();
    }, [search]);


    /**
     * Load all results on component mount.
     */
    useEffect(() => {
        getTotalRecordCount();
        handleLayoutReload();
    }, [query]);


    /**
     * Handles the search debouncing.
     *
     * @type {debounced}
     */
    const debounceSearch = useDebounce(() => {
        const payload = {
            ...model.getQuery()
        };

        if (searchable && !!search) {
            payload.$filter = applySearch(search);
        }

        setQuery(payload);
    });


    /**
     * Handles the quick filter update.
     *
     * @param filter
     */
    const handleQuickFilterChange = (filter) => {
        Logger.debug('[TableLayout] Received quick filter update:', filter);
        const query = model.getQuery();

        // Determine the new default index for page refreshes. We pull the default
        // quick filter by index, so we'll need to first find the correct index for
        // the new selection before passing the value around.
        //
        if (filter && filter.label) {
            State.set(
                model.getQuickFilterCacheKey(),
                model.filters.findIndex(local => filter.label === local.label)
            );
        }

        // Apply the appropriate query. If we received an update without a
        // query, we'll assume that the query should just be the default
        // model setup.
        //
        if (!filter.query) {
            return setQuery(query);
        }

        setQuery({
            ...query,
            ...filter.query
        });
    };


    /**
     * Fired whenever a user clicks on the filter expand icon.
     */
    const handleFilterClick = () => {
        if (!filterOpen) {
            State.set(cacheKeys.filterDrawer, 'true');
            return setFilterOpen(true);
        }

        State.set(cacheKeys.filterDrawer, '');
        setFilterOpen(false);
    };


    /**
     * Reveals the associated form.
     */
    const handleFormClick = () => {
        setFormOpen(true);
    };


    /**
     * Closes the associated form.
     */
    const handleFormClose = () => {
        setFormOpen(false);
    };


    /**
     * Returns the full count of records.
     *
     * @returns {Promise<void>}
     */
    const getTotalRecordCount = async () => {
        const route = model.getRoute();

        if (!route) {
            return;
        }

        const results = await API.get(`${route}/count`, {
            ...query,
            $filter: model.getFilterString(query.$filter)
        });

        setCount(results.count);

        // Update the last row count so that the skeleton frames can properly
        // represent the layout while loading new data for the next refresh.
        //
        State.set(cacheKeys.lastCount, results.count);
        State.set(cacheKeys.lastRowCount,
            results.count > 30 ? 30 : results.count
        );
    };


    /**
     * Loads all available data.
     *
     * @returns {Promise<void>}
     */
    const handleLayoutReload = async (record, success) => {
        Logger.debug('[TableLayout] Received layout update.');

        if (!record) {
            setTableKey(tableKey + 1);
        } else if (success) {
            setSuccess(true);
        }

        let activeFilters = 0;
        const filters = State.json(model.getFiltersCacheKey());

        for (let i in filters) {
            if (!filters.hasOwnProperty(i) || !filters[i]) {
                continue;
            }

            const property = filters[i];

            if (typeof property === 'object') {
                activeFilters += Object
                    .values(property)
                    .filter(value => !!value)
                    .length;
            } else if (Array.isArray(property)) {
                activeFilters += property.length;
            }
        }

        setFilterCount(activeFilters);
        await getTotalRecordCount()
    };


    /**
     * The memoized inner table content.
     *
     * @type {Function}
     */
    const tableContent = useMemo(() => {
        return (
            <DataTable
                key={tableKey}
                model={model}
                query={query}
                actions={!!model.action}
                doReload={handleLayoutReload}
                onLoadComplete={() => setLoading(false)}
            />
        )
    }, [tableKey, model, query])

    return (
        <Page fullScreen hideHeader>
            <Box className={'d-flex flex__grow'}>
                <main
                    style={{
                        padding: 0,
                        flexGrow: 1,
                    }}
                    className={'page'}
                >
                    {useLoader && isLoading && (
                        <LinearProgress/>
                    )}

                    <Box
                        sx={{
                            padding: '0 1.5em'
                        }}
                        className={'d-flex__justify'}
                    >
                        <Box sx={{
                            marginTop: '1.2em',
                            marginBottom: '1.2em'
                        }}>
                            <div className={'index__title d-flex__start'}>
                                {icon}
                                <h2 className={'m__0'}>{heading}</h2>
                            </div>
                            <div className={'index__counter text__small text__light'}>
                                Found {Formatter.number(count)} {resultLabel || 'results'}
                            </div>
                        </Box>

                        <div className={'d-flex__center'}>
                            {searchable && !isMobile && (
                                <TextField
                                    sx={{
                                        minWidth: 200
                                    }}
                                    variant={'standard'}
                                    onChange={event => setSearch(event.target.value)}
                                    className={'mr__2'}
                                    placeholder={'Search...'}
                                />
                            )}

                            {!hideFilters && model.hasQuickFilters() && (
                                <QuickFilters
                                    model={model}
                                    onChange={handleQuickFilterChange}
                                />
                            )}

                            {filterSlot}

                            <Box className={'ml__2 white-space__pre'}>
                                {model.form && (!formPermission || hasPermissionTo(formPermission)) && (
                                    <Button
                                        variant={'link'}
                                        onClick={handleFormClick}
                                        children={<AddIcon/>}
                                        className={'layout__control'}
                                    />
                                )}

                                {controlSlot}

                                {!hideFilters && (
                                    <Button
                                        variant={'link'}
                                        onClick={handleFilterClick}
                                        children={
                                            <Badge badgeContent={filterCount} color="primary">
                                                <MenuIcon/>
                                            </Badge>
                                        }
                                        className={'layout__control'}
                                    />
                                )}
                            </Box>

                            {filterSlotEnd}
                        </div>
                    </Box>

                    {searchable && isMobile && (
                        <Box sx={{
                            paddingLeft: '1em',
                            paddingRight: '1em',
                            paddingBottom: '1em',
                        }}>
                            <TextField
                                variant={'standard'}
                                onChange={event => setSearch(event.target.value)}
                                fullWidth
                                placeholder={'Search...'}
                            />
                        </Box>
                    )}

                    <Divider/>
                    {tableContent}
                </main>

                {!hideFilters && (
                    <FilterDrawer
                        key={'filter-drawer'}
                        model={model}
                        onClose={handleFilterClick}
                        expanded={filterOpen}
                        doReload={handleLayoutReload}
                    />
                )}
            </Box>

            {formOpen && model.getForm({
                open: formOpen,
                onSave: async () => {
                    setSuccess(true);
                    handleFormClose();
                    await handleLayoutReload();
                },
                onClose: handleFormClose,
            })}

            <Snackbar
                open={isSuccess}
                onClose={() => setSuccess(false)}
                children={
                    <Alert
                        onClose={() => setSuccess(false)}
                        severity={'success'}
                        children={'Your record has been saved successfully!'}
                    />
                }
                autoHideDuration={6000}
            />
        </Page>
    );
};

export default TableLayout;