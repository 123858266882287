import React from "react";

import {useAuth} from "../../../Global/Auth";
import ActionButton from "../../../Components/ActionButton";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Skeleton from "@mui/material/Skeleton";
import ListItem from "@mui/material/ListItem";
import SchoolIcon from "@mui/icons-material/School";
import ListItemText from "@mui/material/ListItemText";
import {useNavigate} from "react-router";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import HistoryEduIcon from "@mui/icons-material/HistoryEdu";
import BrowseGalleryIcon from "@mui/icons-material/BrowseGallery";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";

/**
 * RecruitingSteps component.
 *
 * @returns {Element}
 * @constructor
 */
const RecruitingSteps = (props) => {
    const {
        disableAnimation,       // {Boolean} Indicates if the animation should be disabled.
    } = props;

    const {user} = useAuth();
    const navigate = useNavigate();

    /**
     * Indicates whether the employee record has been loaded.
     */
    const isLoaded = user && user.id;

    return !isLoaded ? (
        <List>
            {Array.from(Array(4).keys()).map(i => {
                return (
                    <ListItem key={i} disableGutters>
                        <ListItemAvatar>
                            <Skeleton variant="circular" width={40} height={40}/>
                        </ListItemAvatar>
                        <ListItemText>
                            <Box>
                                <Skeleton width={125 + Math.floor(Math.random() * 50)}/>
                            </Box>
                            <Box>
                                <Skeleton width={250 + Math.floor(Math.random() * 50)}/>
                            </Box>
                        </ListItemText>
                    </ListItem>
                );
            })}
        </List>
    ) : (
        <List>
            <ActionButton
                icon={<HistoryEduIcon/>}
                delay={0}
                onClick={() => navigate('/offer-letter')}
                heading={'Disclosures'}
                description={'Review our disclosures.'}
                completedDate={user.offerLetterDate}
                disableAnimation={disableAnimation}
            />
            <ActionButton
                icon={<BrowseGalleryIcon/>}
                delay={250}
                onClick={() => navigate('/availability')}
                heading={'Availability'}
                disabled={!user.offerLetterDate}
                description={'Provide your availability.'}
                completedDate={user.availabilityDate}
                disableAnimation={disableAnimation}
            />
            <ActionButton
                icon={<AssignmentIndIcon/>}
                delay={500}
                onClick={() => navigate('/hire-packet')}
                heading={'Hire Packet'}
                disabled={!user.availabilityDate}
                description={'Fill out your hire packet.'}
                completedDate={user.hirePacketDate}
                disableAnimation={disableAnimation}
            />
            <ActionButton
                icon={<SchoolIcon/>}
                delay={750}
                onClick={() => navigate('/employee/training')}
                heading={'Training'}
                disabled={!user.hirePacketDate}
                description={'Complete your training.'}
                completedDate={user.trainingDate}
                disableAnimation={disableAnimation}
            />
        </List>
    );
};

export default RecruitingSteps;