import React, {useState} from "react";

import API from "../../Global/API";
import User from "../../Models/User";
import Logger from "../../Global/Logger";
import Department from "../../Models/Department";
import ModelSearch from "../../Components/Input/ModelSearch";
import DialogHeading from "../../Components/Typography/DialogHeading";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

/**
 * DepartmentForm component.
 *
 * @returns {*}
 * @constructor
 */
const DepartmentForm = (props) => {
    const {
        open,               // {Boolean} Indicates whether the form is open.
        record,             // {Object} The form context object, handles pre-loading the form.
        onSave,             // {Function} An onSave callback, triggered whenever the form is saved.,
        onClose,            // {Function} An onClose callback, triggered whenever the modal is closed.
    } = props;

    const [fields, setFields] = useState(Department.getInstance(record));
    const [isLoading, setLoading] = useState(false);
    const [userSelection, setUserSelection] = useState(record && record.userDepartments && record.userDepartments.length ?
        record.userDepartments.map(userDepartment => {
            return {
                id: userDepartment.userId
            }
        }) : []);

    /**
     * Performs the save via the API.
     */
    const doSave = async () => {
        setLoading(true);

        const response = fields.id ?
            await API.put(`department/${fields.id}`, fields) :
            await API.post('departments', fields);

        // Unlink the previous user selection.
        const department = await API.get(`departments/${response.id}`, {
            $expand: 'userDepartments'
        });

        await Promise.all(
            department.userDepartments.map(async userDepartment => await API.delete(`user-departments/${userDepartment.id}`))
        );

        // Link the new selection.
        await Promise.all(
            userSelection.map(async user => await API.post('user-departments', {
                userId: user.id,
                departmentId: department.id
            }))
        );

        // Re-load once more to notify the parent layout.
        const result = await API.get(`departments/${response.id}`, {
            $expand: 'userDepartments'
        });

        setLoading(false);

        if (onSave) {
            onSave(result);
        }
    };


    /**
     * Updates a particular form value.
     *
     * @param key
     * @param value
     */
    const setValue = (key, value) => {
        setFields({
            ...fields,
            [key]: value
        });
    };


    /**
     * Processes the user selection.
     *
     * @param value
     */
    const handleUserSelection = (value) => {
        Logger.debug(`[DepartmentForm] Selected Users:`, value);
        setUserSelection(value);
    };

    return (
        <Dialog
            open={open}
            scroll={'body'}
            onClose={onClose}
            maxWidth={'sm'}
            fullWidth
        >
            <DialogHeading
                title={'Department Details'}
                noMargin
            />
            <DialogContent>
                <Box className={'columns__1'}>
                    <Box className={'columns__2'}>
                        <TextField
                            label={'Name'}
                            value={fields.name}
                            required
                            disabled={isLoading}
                            onChange={event => setValue('name', event.target.value)}
                        />

                        <FormControl>
                            <InputLabel>Icon</InputLabel>
                            <Select
                                value={fields.icon}
                                label="Icon"
                                onChange={event => setValue('icon', event.target.value)}
                            >
                                <MenuItem value="">
                                    <em>None</em>
                                </MenuItem>
                                {Department.iconOptions.map(option => (
                                    <MenuItem value={option.name}>
                                        <Box className={'d-flex__start'}>
                                            {option.icon({
                                                fontSize: 'small',
                                                className: 'mr__2'
                                            })}
                                            <Box>{option.name}</Box>
                                        </Box>
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Box>
                    <ModelSearch
                        model={User}
                        label={'User(s)'}
                        value={userSelection}
                        multiple
                        onChange={handleUserSelection}
                        helperText={'These users will be eligible for chat assignment.'}
                        renderLabel={option => !option ? '' : option.displayName}
                        filterQuery={query => `isDeleted eq {0} and displayName eq {${query}}`}
                    />
                    <TextField
                        rows={4}
                        label={'Description'}
                        value={fields.description}
                        disabled={isLoading}
                        onChange={event => setValue('description', event.target.value)}
                        fullWidth
                        multiline
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={doSave}
                    children={'Save'}
                />
                <Button
                    color={'error'}
                    onClick={onClose}
                    children={'Close'}
                />
            </DialogActions>
        </Dialog>
    );
};

export default DepartmentForm;