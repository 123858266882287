import React, {useState} from "react";

import App from "../../../Global/App";
import API from "../../../Global/API";
import File from "../../../Global/File";
import {useAuth} from "../../../Global/Auth";
import Formatter from "../../../Global/Formatter";
import TrueHelix from "../../../Integrations/TrueHelix";
import FileAvatar from "../../../Components/Input/FileAvatar";
import LogoHeading from "../../../Components/Typography/LogoHeading";
import DialogHeading from "../../../Components/Typography/DialogHeading";
import SectionHeading from "../../../Components/Typography/SectionHeading";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import Confetti from "react-confetti";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import ListAltIcon from '@mui/icons-material/ListAlt';
import ListItemText from "@mui/material/ListItemText";
import DownloadIcon from '@mui/icons-material/Download';
import {useNavigate} from "react-router-dom";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DangerousIcon from '@mui/icons-material/Dangerous';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import TableContainer from "@mui/material/TableContainer";
import {useWindowSize} from "react-use";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

/**
 * TimeSheetForm component.
 *
 * @constructor
 */
const TimeSheetForm = (props) => {
    const {
        onClose,            // {Function} An optional close handler for an outer dialog.
        addBackButton       // {Boolean} Whether or not to add a back button to the form.
    } = props;

    /**
     * Capture the window dimensions for the confetti canvas.
     */
    const {width, height} = useWindowSize();


    /**
     * The uploaded path of a new time sheet.
     */
    const [path, setPath] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isViewingMore, setViewingMore] = useState(true);
    const [isShowingConfetti, setShowConfetti] = useState(false);
    const [isReviewComplete, setReviewComplete] = useState(false);
    const [isBackConfirming, setBackConfirming] = useState(false);

    /**
     * The navigation hook.
     *
     * @type {Function}
     */
    const navigate = useNavigate();


    /**
     * Capture the authenticated user details.
     */
    const {user} = useAuth();


    /**
     * The Helix opt-in status of the user.
     */
    const {isHelixOptIn} = user || {};


    /**
     * The document / asset URL within the application.
     *
     * @type {string}
     */
    const timeSheetSrc = Formatter.url('assets/documents/timesheet.pdf');
    const timeSheetUrl = 'https://reliantstaffing.app/assets/documents/timesheet.pdf';


    /**
     * Processes the form submission and sends a message to the scheduler.
     *
     * @returns {Promise<void>}
     */
    const doDownload = async () => {
        if (App.isCordova()) {
            window.open(timeSheetUrl, '_blank', 'location=no,hidden=yes,toolbar=no');
        } else {
            File.doDownload(timeSheetSrc, 'timesheet.pdf');
        }
    };


    /**
     * Each validation rule (all need to be checked to proceed).
     *
     * @type {string[]}
     */
    const validationOptions = [
        'Your full name (first and last) is legible.',
        'The facility name is on the time sheet.',
        'All shifts are signed by your supervisor.'
    ];


    /**
     * Indicates if the uploaded file is an image.
     *
     * @type {RegExpMatchArray}
     */
    const isImage = (path || '').match(/\.(jpg|jpeg|png|gif)$/i);


    /**
     * The preview container of the uploaded document.
     *
     * @returns {Element}
     * @constructor
     */
    const previewContent = (() => {
        // Determine if this file is an image, and if so, place within an <img/> tag.
        return isImage ? (
            <Box
                sx={App.isCordova() ? {} : {
                    maxHeight: 'calc(100vh - 350px)',
                    overflowY: 'scroll'
                }}
                children={
                    <img src={API.getFilePath(path)}/>
                }
            />
        ) : (
            // Otherwise, fallback to the iframe.
            <Box className={'iframe__container'}>
                <embed
                    src={API.getFilePath(path)}
                    scrolling={'no'}
                    className={'responsive-iframe'}
                />
            </Box>
        );
    })();


    /**
     * Reveals the "Back" confirmation message from the review.
     */
    const handleReviewBackOpen = () => {
        setBackConfirming(true);
    };


    /**
     * Navigates back to the home step of this area.
     */
    const handleReviewBack = () => {
        setPath('');
        setReviewComplete(false);
        setBackConfirming(false);
    };


    /**
     * Creates the time sheet record on the backend.
     *
     * @returns {Promise<void>}
     */
    const doReviewConfirm = async () => {
        setLoading(true);

        await API.post('time-sheets', {
            file: path,
            employeeId: user.id,
            effectiveDate: dayjs()
                .format('YYYY-MM-DD HH:mm:ss'),
        });

        setLoading(false);
        setViewingMore(false);
        setShowConfetti(true);
        setReviewComplete(true);

        setTimeout(() => {
            setShowConfetti(false);
        }, 3000);
    };


    /**
     * Updates the path and gives adequate time for S3 to process the file.
     *
     * @param path
     */
    const handlePathChange = (path) => {
        setPath(path);
        setReviewComplete(false);
    };

    if (path && !isReviewComplete) {
        return (
            <Box>
                <LogoHeading
                    title={'Review'}
                    onClose={onClose}
                    actionSlot={addBackButton && (
                        <Button
                            size={'small'}
                            variant={'outlined'}
                            onClick={handleReviewBackOpen}
                            children={'Back'}
                            disabled={isLoading}
                            className={'text__small'}
                        />
                    )}
                />

                {(isImage || !App.isAndroid()) ? (
                    <>{previewContent}</>
                ) : (
                    <Alert severity={'info'} className={'mb__3'}>
                        Please confirm that the following statements are accurate!
                    </Alert>
                )}

                <Box className={'columns__1'}>
                    <TableContainer className={'table table--striped'}>
                        <Table>
                            <colgroup>
                                <col/>
                                <col width={'100%'}/>
                            </colgroup>
                            <TableBody>
                                {validationOptions.map((option, i) => (
                                    <TableRow key={i}>
                                        <TableCell padding={'checkbox'}>
                                            <span
                                                style={{
                                                    paddingLeft: '0.5em'
                                                }}
                                            >•</span>
                                        </TableCell>
                                        <TableCell>
                                            {option}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <Button
                        variant={'contained'}
                        onClick={doReviewConfirm}
                        disabled={isLoading}
                        children={'It Looks Great - Send Now'}
                    />
                </Box>

                <Dialog
                    open={isBackConfirming}
                    scroll={'body'}
                    onClose={() => setBackConfirming(false)}
                    maxWidth={'sm'}
                    fullWidth
                >
                    <DialogHeading title={'Please Confirm'}/>
                    <DialogContent>
                        Your time sheet has not been fully submitted yet, are you sure
                        you would like to continue?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleReviewBack}
                            children={'Continue'}
                        />
                        <Button
                            color={'error'}
                            onClick={() => setBackConfirming(false)}
                            children={'Cancel'}
                        />
                    </DialogActions>
                </Dialog>
            </Box>
        );
    }


    /**
     * Opens the True Helix app store link.
     */
    const handleHelixRedirect = () => {
        TrueHelix.open();
    };

    return (
        <>
            <Box sx={{
                width: '100vw',
                height: '100vh',
                position: 'fixed',
                overflow: 'hidden',
                pointerEvents: 'none',
            }}>
                <Confetti
                    width={width}
                    height={height}
                    numberOfPieces={isShowingConfetti ? 200 : 0}
                />
            </Box>

            <Box>
                <LogoHeading
                    title={isReviewComplete ? 'Thank You!' : 'My Time Sheet'}
                    onClose={onClose}
                    actionSlot={addBackButton && (
                        <Button
                            size={'small'}
                            variant={'outlined'}
                            onClick={() => navigate('/')}
                            children={'Back'}
                            className={'text__small'}
                        />
                    )}
                />

                <Box className={'columns__1'}>
                    {isReviewComplete ? (
                        <Alert severity={'success'}>
                            <b>Your time sheet has been received!</b> You may now either leave this page, or select
                            another action from the options below.
                        </Alert>
                    ) : (
                        <>
                            {!!isHelixOptIn && (
                                <Alert severity={'error'} icon={<DangerousIcon fontSize={'inherit'}/>}>
                                    <Box className={'columns__1'}>
                                        <b>You are currently enrolled to use our mobile time clock!</b>
                                        <Box>
                                            Please <b>do not</b> submit a paper time sheet unless you have been specifically instructed to
                                            do so - Instead, please punch in or out here.
                                        </Box>
                                        <Button variant={'contained'} onClick={handleHelixRedirect}>
                                            Click here to punch in / out
                                        </Button>
                                    </Box>
                                </Alert>
                            )}
                        </>
                    )}

                    <Box>
                        {isReviewComplete && (
                            <Box className={'mb__2'}>
                                <SectionHeading title={'Have another time sheet?'}/>
                            </Box>
                        )}

                        <FileAvatar
                            field={'path'}
                            record={{path}}
                            editable
                            onChange={handlePathChange}
                            component={
                                <ListItemButton onClick={() => true}>
                                    <ListItemAvatar>
                                        <Avatar sx={{backgroundColor: 'var(--primary-color)'}}>
                                            <CameraAltIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<b>Take a Photo</b>}
                                        secondary={
                                            <Box className={'text__small'}>
                                                Have a copy of your time sheet nearby? Click to take a photo of it.
                                            </Box>
                                        }
                                    />
                                </ListItemButton>
                            }
                            freeCrop
                            targetPath={`employees/${user.id}/time-sheets`}
                        />

                        <Box>
                            <Divider>OR</Divider>
                        </Box>

                        <FileAvatar
                            field={'path'}
                            record={{path}}
                            onChange={handlePathChange}
                            component={
                                <ListItemButton onClick={() => true}>
                                    <ListItemAvatar>
                                        <Avatar sx={{backgroundColor: 'var(--primary-color)'}}>
                                            <CloudUploadIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<b>Select a File</b>}
                                        secondary={
                                            <Box className={'text__small'}>
                                                If you have a copy of your time sheet on your phone, click here to select it.
                                            </Box>
                                        }
                                    />
                                </ListItemButton>
                            }
                            targetPath={`employees/${user.id}/time-sheets`}
                            allowDocuments
                        />
                    </Box>

                    {!isViewingMore && !isHelixOptIn && (
                        <Button
                            onClick={() => setViewingMore(true)}
                            children={'View More...'}
                        />
                    )}
                </Box>

                <br/>

                {isViewingMore && !isHelixOptIn && (
                    <Box sx={{
                        position: 'absolute',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        overflow: 'hidden',
                    }}>

                        <Box className={'p__3 pt__0'}>
                            <SectionHeading
                                title={'Additional Options'}
                            />
                            <ListItemButton onClick={doDownload}>
                                <ListItemAvatar>
                                    <Avatar sx={{backgroundColor: 'var(--primary-color)'}}>
                                        <DownloadIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<b>Blank Time Sheet</b>}
                                    secondary={
                                        <Box className={'text__small'}>
                                            Need a new copy of our time sheet? Click to download a blank one.
                                        </Box>
                                    }
                                />
                            </ListItemButton>

                            <ListItemButton onClick={() => navigate('/employee/time-sheets')}>
                                <ListItemAvatar>
                                    <Avatar sx={{backgroundColor: 'var(--primary-color)'}}>
                                        <ListAltIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<b>Time Sheet History</b>}
                                    secondary={
                                        <Box className={'text__small'}>
                                            View copies of your recently submitted time sheets.
                                        </Box>
                                    }
                                />
                            </ListItemButton>
                        </Box>

                    </Box>
                )}
            </Box>
        </>
    );
};

export default TimeSheetForm;