import React, {useState} from "react";

import API from "../Global/API";
import {useAuth} from "../Global/Auth";
import DialogHeading from "../Components/Typography/DialogHeading";

import Menu from "@mui/material/Menu";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

/**
 * CourseActions component.
 *
 * @returns {*}
 * @constructor
 */
const CourseActions = (props) => {
    const {
        record,         // {Object} The target record / context.
        doReload,       // {Function} Reloads the parent layout, if applicable.
    } = props;

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isEditing, setEditing] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isConfirmingDelete, setConfirmDelete] = useState(false);
    const {isScope, hasPermissionTo, hasAllPermissions, hasOneOfPermissions} = useAuth();

    /**
     * Indicates whether or not an anchor target exists for the menu.
     *
     * @type {boolean}
     */
    const open = Boolean(anchorEl);


    /**
     * Fired whenever the menu button is clicked.
     *
     * @param event
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Closes the menu popover.
     */
    const handleClose = () => {
        setAnchorEl(null);
    };


    /**
     * Reveals the employee edit form.
     */
    const handleCourseEditOpen = () => {
        handleClose();
        setEditing(true);
    };


    /**
     * Hides the employee edit form.
     */
    const handleCourseEditClose = () => {
        setEditing(false);
    };


    /**
     * Handles the employee record save.
     */
    const handleCourseSave = (record) => {
        handleCourseEditClose();

        if (doReload) {
            doReload(record);
        }
    };


    /**
     * Reveals the delete confirmation.
     */
    const handleDeleteConfirmOpen = () => {
        setConfirmDelete(true);
    };


    /**
     * Hides the delete confirmation.
     */
    const handleDeleteConfirmClose = () => {
        setConfirmDelete(false);
    };


    /**
     * Processes the delete action.
     *
     * @returns {Promise<void>}
     */
    const doDelete = async () => {
        setLoading(true);

        const response = await API.put(`courses/${record.id}`, {
            isDeleted: true
        });

        if(doReload){
            doReload(response);
        }

        setLoading(false);
        setConfirmDelete(false);
    };

    return (
        <div>
            {(
                isScope('Employee') ||
                hasOneOfPermissions(['VIEW_TRAINING', 'EDIT_TRAINING'])
            ) && (
                <IconButton onClick={handleClick}>
                    <MoreHorizIcon/>
                </IconButton>
            )}

            {isScope('User') && (
                <Menu
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                >
                    {hasPermissionTo('VIEW_TRAINING') && (
                        <MenuItem onClick={() => navigate(`/training/editor/${record.id}`)}>Edit Course</MenuItem>
                    )}

                    {hasAllPermissions(['VIEW_TRAINING', 'DELETE_TRAINING']) && (
                        <Divider/>
                    )}

                    {hasPermissionTo('DELETE_TRAINING') && (
                        <MenuItem
                            onClick={handleDeleteConfirmOpen}
                            disabled={record.isDeleted}
                            className={'menu__error'}
                        >Delete Record</MenuItem>
                    )}
                </Menu>
            )}

            {isScope('Employee') && (
                <Menu
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => navigate(`/training/course/${record.id}`)}>View Course</MenuItem>
                </Menu>
            )}

            {isConfirmingDelete && (
                <Dialog
                    open={isConfirmingDelete}
                    scroll={'body'}
                    onClose={handleDeleteConfirmClose}
                    maxWidth={'xs'}
                    fullWidth
                >
                    <DialogHeading
                        title={"Confirm Delete"}
                        noMargin
                    />
                    <DialogContent>
                        Are you sure that you'd like to delete this record?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={doDelete}
                            children={'Delete'}
                            disabled={isLoading}
                        />
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};

export default CourseActions;