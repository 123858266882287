import React, {useEffect, useState} from "react";

import App from "../../../Global/App";
import {useAuth} from "../../../Global/Auth";

import Box from "@mui/material/Box";
import * as dayjs from "dayjs";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

/**
 * TimeLabel component.
 *
 * @returns {*}
 * @constructor
 */
const TimeLabel = (props) => {
    const {
        value,                  // {String} The origin date / time string.
        appendSlot,             // {Component} An optional slot on the right end of the label itself.
        prependLabel,           // {Component} An optional slot on the front of the time label.
        secondaryLabel          // {Component} An optional slot after the label itself.
    } = props;

    const {user} = useAuth();
    const [label, setLabel] = useState('');
    const [initialized, setInitialized] = useState(false);

    /**
     * Initialize the timer loop.
     */
    useEffect(() => {
        const interval = setInterval(() => getElapsedTimeLabel(), 1000);
        return () => clearInterval(interval);
    }, []);


    /**
     * The date format to use for the chat label.
     *
     * @type {*|string}
     */
    const dateFormat = user && user.settings && user.settings['US_CHAT_DATE_FORMAT'] ?
        user.settings['US_CHAT_DATE_FORMAT'] :
        'Relative';


    /**
     * Returns the appropriate elapsed time label.
     */
    const getElapsedTimeLabel = () => {
        let output = '';

        if(!value){
            setInitialized(true);
        }

        const now = dayjs.utc();
        const comparator = dayjs.utc(value);

        // Determine each descending unit increment.
        const days = Math.floor(now.diff(comparator, 'days', true));
        const weeks = Math.floor(now.diff(comparator, 'weeks', true));
        const hours = Math.floor(now.diff(comparator, 'hours', true));
        const months = Math.floor(now.diff(comparator, 'months', true));
        const minutes = Math.floor(now.diff(comparator, 'minutes', true));
        const seconds = Math.floor(now.diff(comparator, 'seconds', true));

        if (months) {
            let weeks = now.subtract(months, 'months').diff(comparator, 'weeks');
            output += `${months} ${months > 1 ? 'months' : 'month'}`;
            output += App.ifDesktop(weeks ? `, ${weeks} ${weeks > 1 ? 'weeks' : 'week'}` : ``);
        } else if (weeks) {
            let days = now.subtract(weeks, 'weeks').diff(comparator, 'days');
            output += `${weeks} ${weeks > 1 ? 'weeks' : 'week'}`;
            output += App.ifDesktop(days ? `, ${days} ${days > 1 ? 'days' : 'day'}` : ``);
        } else if (days) {
            let hours = now.subtract(days, 'days').diff(comparator, 'hours');
            output += `${days} ${days > 1 ? 'days' : 'day'}`;
            output += App.ifDesktop(hours ? `, ${hours} ${hours > 1 ? 'hours' : 'hour'}` : ``);
        } else if (hours) {
            let minutes = now.subtract(hours, 'hours').diff(comparator, 'minutes');
            output += `${hours} ${hours > 1 ? 'hours' : 'hour'}`;
            output += App.ifDesktop(minutes ? `, ${minutes} ${minutes > 1 ? 'minutes' : 'minute'}` : ``);
        } else if (minutes) {
            let seconds = now.subtract(minutes, 'minutes').diff(comparator, 'seconds');
            output += `${minutes} ${minutes > 1 ? 'minutes' : 'minute'}`;
            output += App.ifDesktop(seconds ? `, ${seconds} ${seconds > 1 ? 'seconds' : 'second'}` : ``);
        } else {
            if(!dateFormat || dateFormat === 'Relative'){
                setLabel(`${seconds} ${seconds > 1 ? 'seconds' : 'second'} ago`);
            }
            else{
                setLabel(comparator.local().format('MM/DD/YYYY h:mma'));
            }

            return setInitialized(true);
        }

        if(!dateFormat || dateFormat === 'Relative'){
            setLabel(`${output} ago`);
        }
        else{
            setLabel(comparator.local().format('MM/DD/YYYY h:mma'));
        }

        setInitialized(true);
    };


    /**
     * If we hadn't initialized the timer, go ahead and do so otherwise we'll
     * end up with a 00:00:00 display for the first second alive.
     */
    if(!initialized){
        getElapsedTimeLabel();
    }

    return (
        <Box
            className={'notification__timestamp d-flex__justify'}
        >
            <Box className={'d-flex__start white-space__pre'}>
                <AccessTimeIcon fontSize={'small'} className={'mr__1'}/>
                {prependLabel && (
                    <>{prependLabel}</>
                )}

                {label}

                {secondaryLabel && (
                    <>{secondaryLabel}</>
                )}
            </Box>

            {appendSlot && (
                <>{appendSlot}</>
            )}
        </Box>
    );
};

export default TimeLabel;