import React, {useEffect, useRef, useState} from "react";

import API from "../../../Global/API";
import Client from "../../../Models/Client";
import Employee from "../../../Models/Employee";
import Formatter from "../../../Global/Formatter";
import ModalButton from "../../../Components/ModalButton";
import ModelSearch from "../../../Components/Input/ModelSearch";
import SmallButton from "../../../Components/Widgets/CommunicationFeed/SmallButton";
import EmployeeView from "../Employee/EmployeeView";
import DocumentType from "../../../Models/DocumentType";
import SectionHeading from "../../../Components/Typography/SectionHeading";
import GroupChatDialog from "../../../Components/Widgets/GroupChatDialog";
import EmployeeListItem from "../../../Components/Lists/EmployeeListItem";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";
import Radio from "@mui/material/Radio";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import FormLabel from "@mui/material/FormLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import {DatePicker} from "@mui/x-date-pickers";
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";
import FormControlLabel from "@mui/material/FormControlLabel";

/**
 * The number of results or page to sequentially pull data.
 *
 * @type {number}
 */
const perPageLimit = 250;


/**
 * ExpiringDocuments component.
 *
 * @returns {*}
 * @constructor
 */
const ExpiringDocuments = (props) => {
    const {
        loading,            // {Boolean} Indicates if the report is loading.
        onComplete          // {Function} A post-completion callback from whenever the report has results.
    } = props;

    const resultsRef = useRef(null);
    const [clients, setClients] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [selection, setSelection] = useState([]);
    const [employees, setEmployees] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [showResults, setShowResults] = useState(false);
    const [documentTypes, setDocumentTypes] = useState([]);
    const [employeeResults, setEmployeeResults] = useState([]);
    const [isSendingMessage, setSendingMessage] = useState(false);
    const [expirationFilter, setExpirationFilter] = useState('all');

    /**
     * Resets the selection for certain filter changes.
     */
    useEffect(() => {
        setSelection([]);
    }, [expirationFilter, documentTypes]);


    /**
     * Loads all of our employee rows.
     *
     * @returns {Promise<void>}
     */
    const getAllDocuments = async () => {
        let promises = [];
        const filters = [];

        if (employees && employees.length) {
            filters.push(`id in {${employees.map(employee => employee.id).join(',')}}`)
        }

        // Add the ability to filter by client.
        if(clients && clients.length) {
            filters.push(`employeeClients/any{clientId in {${clients.map(client => client.id).join(',')}}}`)
        }

        // Load all the clients page by page until we've reached the end.
        const count = await API.get('employees/count', {
            $top: perPageLimit,
            $filter: `isActive eq {1} and isDeleted eq {0} ${filters.length ? `and ${filters.join(' and ')}` : ''}`,
        });

        for (let page = 0; page < count.pages; page++) {
            promises.push(
                API.get('employees', {
                    $top: perPageLimit,
                    $skip: page * perPageLimit,
                    $select: '*,expiringDocuments',
                    $expand: 'specialty,state,status',
                    $filter: `isActive eq {1} and isDeleted eq {0} ${filters.length ? `and ${filters.join(' and ')}` : ''}`,
                    $orderby: `lastName asc`,
                    startDate
                })
            );
        }

        const results = await Promise.all(promises);
        setEmployeeResults(results.flat());
    };


    /**
     * Indicates whether there is a selection.
     *
     * @type {boolean}
     */
    const hasSelection = !!selection && selection.length > 0;


    /**
     * Indicates if all are selected.
     *
     * @type {boolean}
     */
    const allSelected = hasSelection && selection.length === employees.length;


    /**
     * Indicates if the selection is indeterminate.
     *
     * @type {boolean}
     */
    const isIndeterminate = hasSelection && !allSelected;


    /**
     * Handles the employee change.
     *
     * @param employee
     */
    const handleChange = (employee) => {
        let updated = [];
        let isSelected = selection.some(record => record.id === employee.id);

        // Assign the new selection.
        if (!isSelected) {
            updated = [...selection, employee];
        } else {
            updated = selection.filter(record => record.id !== employee.id);
        }

        setSelection(updated);
    };


    /**
     * Handles the global selection change (the table header checkbox).
     */
    const handleSelectAll = () => {
        let updatedSelection = [];

        if (isIndeterminate) {
            // Clear the selection.
        } else if (allSelected) {
            // Clear the selection.
        } else {
            updatedSelection = [...filteredEmployees];
        }

        setSelection(updatedSelection);
    };


    /**
     * Loads the report results from the backend.
     *
     * @returns {Promise<void>}
     */
    const getResults = async () => {
        setLoading(true);

        await Promise.all([
            getAllDocuments()
        ]);

        setShowResults(true);
        setLoading(false);

        if (onComplete) {
            onComplete(resultsRef);
        }
    };


    /**
     * The ID's of each selected document types within the filter.
     *
     * @type {any[]}
     */
    const documentTypeIds = documentTypes.map(documentType => documentType.id);


    /**
     * Returns the filtered document types.
     *
     * @param documents
     * @returns {*}
     */
    const getFilteredFrom = (documents) => {
        const filtered = documents.filter(document => {
            const expirationDateJs = dayjs.utc(document.expiration).local();
            const isExpired = expirationDateJs.isBefore(dayjs());

            switch (expirationFilter) {
                case 'all':
                    return true;

                case 'expired':
                    return isExpired;

                case 'pending':
                    return !isExpired;
            }
        });

        if (!documentTypes || !documentTypes.length) {
            return filtered;
        }

        return filtered.filter(document => {
            const {DocumentType} = document;
            const {id} = DocumentType || {};

            return documentTypeIds.includes(id);
        });
    };


    /**
     * Applies document types to the employee results.
     *
     * @type {*[]}
     */
    const filteredEmployees = employeeResults.filter(employee => {
        const expiringDocuments = getFilteredFrom(employee.expiringDocuments);

        return expiringDocuments && expiringDocuments.length;
    });


    /**
     * Closes the group message dialog.
     */
    const handleMessageClose = () => {
        setSendingMessage(false);
    };


    /**
     * Reveals the group message dialog.
     */
    const handleMessageOpen = () => {
        setSendingMessage(true);
    };


    /**
     * Indicates if the report is in progress.
     *
     * @type {boolean}
     */
    const inProgress = isLoading || loading;

    return (
        <Box className={'d-flex'}>
            <Box
                sx={{width: 400, overflowY: 'hidden'}}
                className={'border__right p__3 full__height--left-tabs'}
            >
                <Box className={'columns__1'}>
                    <DatePicker
                        label="Start Date"
                        value={startDate ? dayjs(startDate) : null}
                        disabled={inProgress}
                        onChange={event => setStartDate(event ? event.format('YYYY-MM-DD') : '')}
                        fullWidth
                    />

                    <ModelSearch
                        model={DocumentType}
                        value={documentTypes}
                        label={'Document Type(s)'}
                        multiple
                        disabled={inProgress}
                        onChange={setDocumentTypes}
                        renderLabel={option => !option ? '' : option.name}
                        filterQuery={query => `hasExpiration eq {1} and name eq {${query}}`}
                    />

                    <ModelSearch
                        model={Client}
                        value={clients}
                        label={'Client(s)'}
                        multiple
                        disabled={isLoading}
                        onChange={setClients}
                        renderLabel={option => !option ? '' : option.name}
                        filterQuery={query => `isDeleted eq {0} and name eq {${query}}`}
                        queryParams={{
                            $top: 250,
                            $orderby: 'name asc'
                        }}
                    />

                    <ModelSearch
                        model={Employee}
                        value={employees}
                        label={'Employee(s)'}
                        multiple
                        disabled={inProgress}
                        onChange={setEmployees}
                        helperText={'(Leave blank to include all.)'}
                        renderLabel={option => !option ? '' : option.displayName}
                    />

                    <FormControl>
                        <FormLabel>Which documents should be included?</FormLabel>
                        <RadioGroup
                            value={expirationFilter}
                            onChange={event => setExpirationFilter(event.target.value)}
                        >
                            <FormControlLabel
                                value={'all'}
                                label={'Show All'}
                                control={
                                    <Radio
                                        disabled={inProgress}
                                    />
                                }
                            />
                            <FormControlLabel
                                value={'expired'}
                                label={'Currently Expired'}
                                control={
                                    <Radio
                                        disabled={inProgress}
                                    />
                                }
                            />
                            <FormControlLabel
                                value={'pending'}
                                label={'Expiring Soon'}
                                control={
                                    <Radio
                                        disabled={inProgress}
                                    />
                                }
                            />
                        </RadioGroup>
                    </FormControl>

                    <Button
                        variant={'outlined'}
                        onClick={getResults}
                        children={'Apply'}
                        disabled={inProgress}
                    />
                </Box>
            </Box>

            <Box
                sx={{borderRadius: 0}}
                className={`flex__grow full__height--left-tabs ${!showResults ? 'well__container' : ''}`}
            >
                {inProgress && <LinearProgress sx={{width: '100%'}}/>}

                {!showResults && (
                    <Box className={'p__3 text__center text__disclaimer'}>Your results will display here.</Box>
                )}

                {showResults && (
                    <Box className={'p__3 columns__1'}>
                        <Box>
                            <SectionHeading
                                title={`Employees`}
                                primary
                                action={
                                    <SmallButton
                                        onClick={handleMessageOpen}
                                        children={
                                            <span
                                                style={{color: '#fff'}}
                                                children={`Send Group Message (${selection.length})`}
                                            />
                                        }
                                        disabled={!selection || !selection.length}
                                        className={'text__small text__thin'}
                                    />
                                }
                            />
                            <TableContainer className={'table table--striped result__set'}>
                                <Table size={'small'}>
                                    <colgroup>
                                        <col width={'24px'}/>
                                        <col width={'33%'}/>
                                        <col width={'35%'}/>
                                        <col/>
                                    </colgroup>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{padding: 0, paddingLeft: '0.5em'}}>
                                                <Checkbox
                                                    checked={allSelected}
                                                    onChange={handleSelectAll}
                                                    indeterminate={isIndeterminate}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                Employee
                                            </TableCell>
                                            <TableCell>
                                                Documents
                                            </TableCell>
                                            <TableCell>
                                                Expiration
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!filteredEmployees.length && (
                                            <TableRow>
                                                <TableCell colSpan={3} className={'text__disclaimer text__center'}
                                                    align={'center'}>
                                                    No results available.
                                                </TableCell>
                                            </TableRow>
                                        )}

                                        {filteredEmployees.map(employee => {
                                            const expiringDocuments = getFilteredFrom(employee.expiringDocuments);

                                            if (!expiringDocuments || !expiringDocuments.length) {
                                                return null;
                                            }

                                            return [
                                                <TableRow key={`employee-${employee.id}`}>
                                                    <TableCell sx={{paddingRight: 0, paddingLeft: '0.5em'}}
                                                        className={'v-align__top'}>
                                                        <Checkbox
                                                            checked={hasSelection && selection.some(record => record.id === employee.id)}
                                                            onChange={() => handleChange(employee)}
                                                        />
                                                    </TableCell>
                                                    <TableCell sx={{padding: 0}} className={'v-align__top'}>
                                                        <ModalButton
                                                            label={
                                                                <EmployeeListItem
                                                                    onSelect={() => true}
                                                                    employee={employee}
                                                                />
                                                            }
                                                            bodyStyle={{
                                                                paddingTop: 0
                                                            }}
                                                            children={
                                                                <EmployeeView id={employee.id}/>
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell sx={{padding: 0}} colSpan={2}>
                                                        <Table>
                                                            <colgroup>
                                                                <col width={'55%'}/>
                                                                <col width={'45%'}/>
                                                            </colgroup>
                                                            <TableBody>
                                                                {expiringDocuments.map(document => {
                                                                    const documentType = {
                                                                        ...document.DocumentType
                                                                    };

                                                                    const expirationDateJs = dayjs.utc(document.expiration).local();

                                                                    return (
                                                                        <TableRow key={`document-${document.id}`}>
                                                                            <TableCell
                                                                                sx={{background: 'none!important'}}
                                                                            >
                                                                                {documentType.name}
                                                                            </TableCell>
                                                                            <TableCell
                                                                                sx={{background: 'none!important'}}
                                                                            >
                                                                                <Chip
                                                                                    label={Formatter.date(document.expiration, '', false)}
                                                                                    color={expirationDateJs.isBefore(dayjs()) ? 'error' : 'warning'}
                                                                                />
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    );
                                                                })}
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>,
                                            ];
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                )}
            </Box>

            <GroupChatDialog
                open={isSendingMessage}
                onClose={handleMessageClose}
                selection={selection}
            />

            {/*The hidden display for PDF rendering.*/}
            <Box className={'d-hidden'}>
                <Box ref={resultsRef}>
                    <table className={'table--bordered'}>
                        <tbody>
                        <tr>
                            <td colSpan={2}>
                                <b>Documents</b>
                            </td>
                        </tr>

                        {!filteredEmployees.length && (
                            <tr>
                                <td colSpan={2}>
                                    No results available.
                                </td>
                            </tr>
                        )}

                        {filteredEmployees.map(employee => {
                            const expiringDocuments = getFilteredFrom(employee.expiringDocuments);

                            if (!expiringDocuments || !expiringDocuments.length) {
                                return null;
                            }

                            return [
                                <tr key={`employee-${employee.id}`}>
                                    <td style={{verticalAlign: 'top'}}>
                                        {employee.lastName}, {employee.firstName}
                                    </td>
                                    <td style={{padding: 0}}>
                                        <table>
                                            <tbody>
                                            {expiringDocuments.map(document => {
                                                const documentType = {
                                                    ...document.DocumentType
                                                };

                                                const expirationDateJs = dayjs.utc(document.expiration).local();

                                                return (
                                                    <tr key={`document-${document.id}`}>
                                                        <td style={{width: '55%'}}>
                                                            {documentType.name}
                                                        </td>
                                                        <td>
                                                            <div
                                                                className={expirationDateJs.isBefore(dayjs()) ? 'text__error' : 'text__warning'}>
                                                                {Formatter.date(document.expiration, '', false)}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                            </tbody>
                                        </table>
                                    </td>
                                </tr>,
                            ];
                        })}
                        </tbody>
                    </table>
                    <br/>
                </Box>
            </Box>
        </Box>
    );
};

export default ExpiringDocuments;