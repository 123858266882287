import React from "react";

import State from "../../Models/State";
import TableLayout from "../../Components/Layouts/TableLayout";

import PinDropIcon from '@mui/icons-material/PinDrop';

/**
 * States component.
 *
 * @returns {*}
 * @constructor
 */
const States = () => (
    <TableLayout
        icon={<PinDropIcon/>}
        model={State}
        heading={'States'}
        condensed
        hideFilters
    />
);

export default States;