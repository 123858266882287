import React from "react";

import Model from "../Definitions/Model";
import Property from "../Definitions/Property";
import PropertyMap from "../Definitions/PropertyMap";
import DepartmentForm from "../Actions/DepartmentActions/DepartmentForm";
import DepartmentActions from "../Actions/DepartmentActions";

import Box from "@mui/material/Box";
import QuizIcon from '@mui/icons-material/Quiz';
import BadgeIcon from '@mui/icons-material/Badge';
import GroupsIcon from '@mui/icons-material/Groups';
import SavingsIcon from '@mui/icons-material/Savings';
import LoyaltyIcon from '@mui/icons-material/Loyalty';
import ScheduleIcon from '@mui/icons-material/Schedule';
import PaymentsIcon from '@mui/icons-material/Payments';
import SettingsIcon from '@mui/icons-material/Settings';
import Diversity1Icon from '@mui/icons-material/Diversity1';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import PsychologyIcon from '@mui/icons-material/Psychology';
import EngineeringIcon from '@mui/icons-material/Engineering';
import WorkHistoryIcon from '@mui/icons-material/WorkHistory';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';

/**
 * Department model.
 *
 * @type {Object}
 */
const Department = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'department',


    /**
     * The appropriate API endpoint root.
     */
    route: 'departments',


    /**
     * Additional query parameters to share throughout the application.
     */
    query: {
        $expand: 'userDepartments($expand=user)',
        $filter: 'isDeleted eq {0}'
    },


    /**
     * The form component.
     *
     * @returns {*}
     * @param parameters
     */
    form: (parameters) => <DepartmentForm {...parameters}/>,


    /**
     * The menu action.
     *
     * @param parameters
     * @returns {*}
     */
    action: (parameters) => <DepartmentActions {...parameters}/>,


    /**
     * Various icon options for the form / mobile app.
     */
    iconOptions: [
        {
            name: 'Account Balance',
            icon: params => <AccountBalanceIcon {...params}/>
        },{
            name: 'Savings',
            icon: params => <SavingsIcon {...params}/>
        },{
            name: 'Payments',
            icon: params => <PaymentsIcon {...params}/>
        },{
            name: 'Quiz',
            icon: params => <QuizIcon {...params}/>
        },{
            name: 'Badge',
            icon: params => <BadgeIcon {...params}/>
        },{
            name: 'Groups',
            icon: params => <GroupsIcon {...params}/>
        },{
            name: 'Loyalty',
            icon: params => <LoyaltyIcon {...params}/>
        },{
            name: 'Schedule',
            icon: params => <ScheduleIcon {...params}/>
        },{
            name: 'Settings',
            icon: params => <SettingsIcon {...params}/>
        },{
            name: 'Diversity',
            icon: params => <Diversity1Icon {...params}/>
        },{
            name: 'Help Center',
            icon: params => <HelpCenterIcon {...params}/>
        },{
            name: 'Psychology',
            icon: params => <PsychologyIcon {...params}/>
        },{
            name: 'Engineering',
            icon: params => <EngineeringIcon {...params}/>
        },{
            name: 'Work History',
            icon: params => <WorkHistoryIcon {...params}/>
        },{
            name: 'Support Agent',
            icon: params => <SupportAgentIcon {...params}/>
        },{
            name: 'Calendar Month',
            icon: params => <CalendarMonthIcon {...params}/>
        },{
            name: 'Pending Actions',
            icon: params => <PendingActionsIcon {...params}/>
        },{
            name: 'Medical Information',
            icon: params => <MedicalInformationIcon {...params}/>
        }
    ].sort((a,b) => {
        if (a.name < b.name) {
            return -1;
        }

        if (a.name > b.name) {
            return 1;
        }

        return 0;
    }),


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'name',
        'description',
        'assignedUsers'
    ],


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'name',
            width: '28%',
            label: 'Name',
            render: (record) => record.name,
            sortable: true
        }),
        Property.create({
            key: 'description',
            width: '40%',
            label: 'Description',
            render: (record) => record.description,
            sortable: true
        }),
        Property.create({
            key: 'assignedUsers',
            width: '28%',
            label: 'Assigned Users',
            render: (record) => (
                <>
                    {record.userDepartments.map(userDepartment => (
                        <Box>{userDepartment.user && userDepartment.user.id ? userDepartment.user.displayName || '(no name)' : ''}</Box>
                    ))}
                </>
            ),
            sortable: true
        })
    ])
};

export default Department;