import React from "react";

import Settings from "../Global/Settings";

import Box from "@mui/material/Box";
import {useNavigate} from "react-router";

/**
 * Footer component.
 *
 * @returns {*}
 * @constructor
 */
const Footer = () => {
    const navigate = useNavigate();

    return (
        <Box className={'footer text__center'}>
            <Box className={'footer__navigation text__small'}>
                <b>© 2024 {Settings.applicationName}</b>
                <span className={'divider'}/>
                <a onClick={() => navigate('/')}>Home</a>
            </Box>
        </Box>
    )
};

export default Footer;