import React, {useEffect, useRef, useState} from "react";

import Logger from "../../Global/Logger";
import useScreenOrientation from "../../Hooks/useScreenOrientation";

import Box from "@mui/material/Box";
import FormLabel from "@mui/material/FormLabel";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import SignaturePad from "signature_pad";

/**
 * InputSignature Component
 */
const InputSignature = (props) => {
    const {
        value,              // {String} The initial / default signature content.
        label,              // {String} The form field label value.
        disabled,           // {Boolean} Indicates if the input is disabled.
        onChange,           // {Callback} The on-change callback action.
        scaleFactor,        // {Number} Indicates the width-to-height ratio.
    } = props;

    // Initialize the pad storage.
    const orientation = useScreenOrientation();
    const [key, setKey] = useState(0);
    const [data, setData] = useState(null);
    const [width, setWidth] = useState(window.innerWidth > 580 ? 570 : 320);
    const [height, setHeight] = useState(130);
    const [saving, setSaving] = useState(false);
    const [element, setElement] = useState(null);
    const [signing, setSigning] = useState(false);
    const [isInitialized, setInitialized] = useState(false);
    const [defaultValue, setDefaultValue] = useState(value || null);

    // Target specific elements.
    const signPadRef = useRef(null);
    const signPadParentRef = useRef(null);

    /**
     * Synchronizes the stored value.
     */
    useEffect(() => {
        if (!value) {
            return;
        }

        if (defaultValue === value) {
            return;
        }

        setDefaultValue(value);
    }, [value, isInitialized]);


    /**
     * Shares the default value with the signature canvas.
     */
    useEffect(() => {
        if (!element || !defaultValue) {
            return;
        }

        element.fromDataURL(defaultValue, {
            width,
            height
        });
    }, [element, defaultValue])


    /**
     * Fired on signature capture / change.
     */
    const onDrawEnd = () => {
        setData(element.toData());

        if (onChange) {
            onChange(signPadRef.current.toDataURL('image/jpeg', 1));
        }
    };


    /**
     * Called on sign end.
     */
    const onSignEnd = () => {
        setSigning(false)
    };


    /**
     * Called on sign start.
     */
    const onSignBegin = () => {
        setSigning(true)
    };


    /**
     * Used to compare size adjustments.
     */
    const useCurrentWidth = () => {
        let [width, setWidth] = useState(window.innerWidth);

        useEffect(() => {
            let timeoutId = null;

            const resizeListener = () => {
                clearTimeout(timeoutId);
                timeoutId = setTimeout(() => setWidth(window.innerWidth), 150);
            };

            window.addEventListener('resize', resizeListener);

            return () => {
                window.removeEventListener('resize', resizeListener);
            }
        }, []);

        return width;
    };

    const _width = useCurrentWidth();


    /**
     * Handles resizing by proportionally adjusting strokes.
     */
    useEffect(() => {
        setKey(key + 1);
    }, [_width, width, height, orientation]);


    /**
     * Initializes the signature pad.
     */
    useEffect(() => {
        doInitialize();
    }, [key]);


    /**
     * Resizes the signature pad.
     */
    const doResize = () => {
        let ratio = 1;
        let newWidth;
        let newHeight;

        newWidth = signPadRef.current.clientWidth;
        newHeight = newWidth / (scaleFactor || 3);
        ratio = newWidth / width;
        setWidth(newWidth);
        setHeight(newHeight);

        signPadRef.current.width = newWidth;
        signPadRef.current.height = newHeight;

        // To handle the resize, we'll need to scale the signature pad points
        // data to properly reflect the new canvas scale.
        //
        if (data) {
            const updatedPointData = data.map((line) => {
                line.points = line.points.map((point) => {
                    point.x = point.x * ratio;
                    point.y = point.y * ratio;

                    return point;
                });

                return line;
            });

            element.fromData(updatedPointData);
            setData(updatedPointData);
        }
    };


    /**
     * Initializes the signature pad.
     */
    const doInitialize = () => {
        Logger.debug(`[InputSignature] Initializing signature pad...`);

        if (!signPadRef.current) {
            return;
        }

        signPadRef.current.width = signPadRef.current.clientWidth;
        signPadRef.current.height = signPadRef.current.clientHeight;

        const defaultSignPadOptions = {
            dotSize: 1,
            throttle: 0,
            maxWidth: 2,
            penColor: 'rgb(0,0,0)',
            minDistance: 3,
            backgroundColor: 'rgb(255,255,255)'
        };

        // Initialize the signature pad.
        const options = {
            ...defaultSignPadOptions,

            width: signPadRef.current.clientWidth ,
            onEnd: onSignEnd,
            onBegin: onSignBegin,
        };

        const element = new SignaturePad(signPadRef.current, options);

        setElement(element);
        setInitialized(true);
    };


    /**
     * Captures data on each stroke.
     */
    useEffect(() => {
        if (!element) {
            return;
        }

        doResize();
        element.clear();
        element.addEventListener("endStroke", onDrawEnd);
    }, [element]);


    /**
     * Clears the signature pad content.
     */
    const handleClear = () => {
        if (!element) {
            return;
        }

        element.clear();
        setData(null);

        if (onChange) {
            onChange('');
            setDefaultValue('');
        }

        setKey(key + 1);
    };

    return (
        <Box
            key={`signature-pad-${key}`}
            className={'signature__wrapper w__100'}
        >
            <Box className={'d-flex__justify'}>
                <FormLabel sx={{height: '1.5em'}}>{label}</FormLabel>

                <Box>
                    {(data || defaultValue) && !disabled && (
                        <IconButton
                            size={'small'}
                            color={'error'}
                            onClick={handleClear}
                            children={<CloseIcon/>}
                            disabled={saving || disabled}
                        />
                    )}
                </Box>
            </Box>
            <Box
                ref={signPadParentRef}
                style={{paddingTop: '6px'}}
                className={disabled ? 'disabled' : ''}
            >
                <canvas
                    ref={signPadRef}
                    className={`signature__pad`}
                />
            </Box>
        </Box>
    )
};

export default InputSignature;