import React, {useEffect, useState} from "react";

import Step from "../../../Components/Step";
import {useAuth} from "../../../Global/Auth";
import LogoHeading from "../../../Components/Typography/LogoHeading";
import StepControls from "../../../Components/StepControls";
import InputSignature from "../../../Components/Input/InputSignature";

import Box from "@mui/material/Box";

/**
 * WorkersCompensationStep component.
 *
 * @returns {*}
 * @constructor
 */
const WorkersCompensationStep = (props) => {
    const {
        step,                   // {Number} The current step for the user.
        index,                  // {Number} The expected step number.
        onClose,                // {Function} An optional method for closing the form if it appears in a dialog.
        employee,               // {Object} The employee object for read only context.
        readOnly,               // {Boolean} Whether the form is disabled.
        disabled,               // {Boolean} Whether the step is disabled from input.
        contentOnly,            // {Boolean} Whether to only render the content.
        onStepChange            // {Function} The on change callback to load the next step.
    } = props;

    const [isLoaded, setLoaded] = useState(false);
    const [signature, setSignature] = useState('');
    const {setUserSetting, getUserSettingValue, getUserSettingValueForEmployee} = useAuth();

    /**
     * Loads the required settings for this step.
     */
    useEffect(() => {
        handleSettingsLoad();
    }, []);


    /**
     * Synchronize the job description signature.
     */
    useEffect(() => {
        if(!readOnly && isLoaded){
            setUserSetting('HP_WORKERS_COMPENSATION', signature)
        }
    }, [signature]);


    /**
     * Loads each of the appropriate user settings.
     *
     * @returns {Promise<void>}
     */
    const handleSettingsLoad = async () => {
        setSignature(
            readOnly ?
                await getUserSettingValueForEmployee(employee.id, 'HP_WORKERS_COMPENSATION') :
                await getUserSettingValue('HP_WORKERS_COMPENSATION')
        );

        setLoaded(true);
    };


    /**
     * The disabled state of the form.
     */
    const isDisabled = readOnly || disabled;

    return (
        <Step step={index} value={step}>
            <LogoHeading
                title={'Workers\' Compensation Information'}
                onClose={onClose}
            />

            <Box className={'columns__1'}>
                In Pennsylvania, the workers’ compensation law provides wage loss and medical benefits to
                employees who cannot work, or who need medical care, because of a work-related injury.

                <Box>
                    Benefits are required to be paid by your employer when self-insured, or through insurance
                    provided by your employer. Your employer is required to post the name of the company
                    responsible for paying workers’ compensation benefits at its primary place of business and
                    at sites of employment in a prominent and easily accessible place, including, without
                    limitation, areas used for the treatment of injured employees or for the administration of
                    first aid.
                </Box>
                <Box>
                    You should report immediately any injury or work-related illness to your employer. Your
                    benefits could be delayed or denied if you do not notify your employer immediately. If your
                    claim is denied by your employer, you have the right to request a hearing before a workers’
                    compensation judge.
                </Box>
                <Box>
                    The Bureau of Workers’ Compensation cannot provide legal advice; however, you may contact
                    them for additional general information at:
                </Box>
                <Box>
                    <b>Bureau of Workers’ Compensation</b>
                </Box>
                <Box>
                    <Box>
                        1171 South Cameron Street, Room 103
                    </Box>
                    <Box>
                        Harrisburg, PA 17104-2501
                    </Box>
                    <Box>
                        Toll free inside PA: 800-482-2383
                    </Box>
                    <Box>
                        Local calls and calls from outside PA: 717-772-4447
                    </Box>
                    <Box>
                        TTY 800-362-4228 (for hearing and speech impaired only)
                    </Box>
                    <Box>
                        <a href={'www.dli.pa.gov'}>www.dli.pa.gov</a>
                    </Box>
                </Box>

                {!contentOnly && (
                    <>
                        <InputSignature
                            value={signature}
                            label={'Please sign here:'}
                            disabled={isDisabled}
                            onChange={setSignature}
                            scaleFactor={2}
                        />

                        {!readOnly && (
                            <StepControls
                                step={step}
                                disabled={isDisabled || !signature}
                                onStepChange={onStepChange}
                            />
                        )}
                    </>
                )}
            </Box>
        </Step>
    );
};

export default WorkersCompensationStep;