import React from "react";

import ModalButton from "../../../../Components/ModalButton";
import EmployeeView from "../../Employee/EmployeeView";
import QuickSendWidget from "../../../../Components/Widgets/QuickSendWidget";
import EmployeeListItem from "../../../../Components/Lists/EmployeeListItem";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import ChatIcon from "@mui/icons-material/Chat";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";

/**
 * TallyOverview component.
 * 
 * @returns {Element}
 * @constructor
 */
const DisciplinaryAction = (props) => {
    const {
        loading,            // {Boolean} The loading state of the parent container.
        employees,          // {Array} An array of employees to display results for.
    } = props;

    return (
        <Box className={'event-metrics__expiring'}>
            <TableContainer className={'table table--striped client-schedule__table'}>
                <Table size={'small'}>
                    <colgroup>
                        <col/>
                        <col width={'26%'}/>
                        <col width={'15%'}/>
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                Employee
                            </TableCell>
                            <TableCell align={'center'}>
                                Point Total
                            </TableCell>
                            <TableCell align={'right'}>
                                Chat
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {employees.filter(employee => !!employee.pointTotal).sort((a, b) => {
                            return b.pointTotal - a.pointTotal;
                        }).map(employee => (
                            <TableRow key={employee.id}>
                                <TableCell sx={{padding: 0}}>
                                    <ModalButton
                                        label={
                                            <EmployeeListItem
                                                onSelect={() => true}
                                                employee={employee}
                                            />
                                        }
                                        bodyStyle={{
                                            paddingTop: 0
                                        }}
                                        children={
                                            <EmployeeView id={employee.id}/>
                                        }
                                    />
                                </TableCell>
                                <TableCell align={'center'}>
                                    {employee.pointTotal}
                                </TableCell>
                                <TableCell align={'right'}>
                                    <QuickSendWidget record={employee} icon={<ChatIcon/>}/>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {loading && <LinearProgress/>}
        </Box>
    );
};

export default DisciplinaryAction;