import React from "react";

import MassMail from "../../Models/MassMail";
import TableLayout from "../../Components/Layouts/TableLayout";

import CampaignIcon from '@mui/icons-material/Campaign';

/**
 * Marketing component.
 *
 * @returns {*}
 * @constructor
 */
const Marketing = () => (
    <TableLayout
        icon={<CampaignIcon/>}
        model={MassMail}
        heading={'Campaigns'}
        condensed
        hideFilters
        formPermission={'EDIT_COMMUNICATIONS'}
    />
);

export default Marketing;