import React, {useMemo, useRef, useState} from "react";

import API from "../../../../Global/API";
import App from "../../../../Global/App";
import State from "../../../../Global/State";
import Touch from "../../../../Global/Touch";
import Logger from "../../../../Global/Logger";
import Settings from "../../../../Global/Settings";
import {useAuth} from "../../../../Global/Auth";
import Generator from "../../../../Global/Generator";
import Formatter from "../../../../Global/Formatter";
import DialogHeading from "../../../Typography/DialogHeading";
import {dataURIToBlob, fileToDataURL} from "../../../../Global/Helpers/File";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Menu from "@mui/material/Menu";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {toPng} from "html-to-image";
import {Portal} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from '@mui/icons-material/Error';
import IconButton from "@mui/material/IconButton";
import WarningIcon from '@mui/icons-material/Warning';
import HistoryIcon from '@mui/icons-material/History';
import {DatePicker} from "@mui/x-date-pickers";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import DangerousIcon from '@mui/icons-material/Dangerous';
import LinearProgress from "@mui/material/LinearProgress";
import ListItemButton from "@mui/material/ListItemButton";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import AutoDeleteIcon from '@mui/icons-material/AutoDelete';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CollectionsIcon from '@mui/icons-material/Collections';
import {getEditorDefaults} from "@pqina/pintura";
import {PinturaEditorModal} from "@pqina/react-pintura";

/**
 * Renders an individual document type.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const FileItem = (props) => {
    const {
        file,                       // {Object} The document type record for the file,
        record,                     // {Object} An existing file record for this type.
        hasFile,                    // {Boolean} An optional bypass for the standard ID check.
        onUpload,                   // {Function} An upload complete / success callback.
        onDelete,                   // {Function} An delete complete / success callback.
        employeeId,                 // {String} The employee ID to store files against.
        refreshable,                // {Boolean} For various dynamic PDFs, optionally allows for the ability to refresh the generated content (via 'refresh' parameter).
        downloadUrl,                // {String} An optional replacement download URL for dynamic files.
        previewContent,             // {Function} The preview content to render if not the standard display.
        disableDownload,            // {Boolean} Indicates if the download button should be disabled.
    } = props;

    const [path, setPath] = useState('');
    const [index, setIndex] = useState(0);
    const [isError, setError] = useState(false);
    const [fileName, setFileName] = useState('');
    const [isEditing, setEditing] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [editTarget, setEditTarget] = useState(null);
    const [isRefreshed, setRefreshed] = useState(false);
    const [isRefreshing, setRefreshing] = useState(false);
    const [isPreviewing, setPreviewing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const {user, isScope, hasPermissionTo} = useAuth();
    const [expirationDate, setExpirationDate] = useState('');
    const [isConfirmDelete, setConfirmDelete] = useState(false);
    const [isEditingExpiration, setExpirationOpen] = useState(false);
    const [isShowingUploadMenu, setShowingUploadMenu] = useState(false);

    /**
     * The final resulting document URL.
     *
     * @type {string}
     */
    const documentUrl = downloadUrl;


    /**
     * The hidden file input element.
     *
     * @type {React.MutableRefObject<null> | React.RefObject<any> | React.MutableRefObject<undefined>}
     */
    const fileInput = useRef(null);


    /**
     * The hidden library file input element for selecting from library.
     *
     * @type {React.MutableRefObject<null>}
     */
    const libraryFileInput = useRef(null);


    /**
     * The editor instance.
     *
     * @type {React.MutableRefObject<null>}
     */
    const editor = useRef(null);


    /**
     * The inner preview content.
     *
     * @type {React.MutableRefObject<null>}
     */
    const previewContentRef = useRef(null);


    /**
     * Asserts whether the user has a file attached to the list entry.
     */
    const fileExists = !!(record.id || hasFile);


    /**
     * The upload path / directory.
     *
     * @type {string}
     */
    const uploadPath = `employees/${employeeId}/documents`;


    /**
     * Indicates if we've been provided with preview content.
     *
     * @type {boolean}
     */
    const hasPreviewContent = !!previewContent;


    /**
     * The editor configuration for Pintura.
     *
     * @type {PinturaEditorDefaultOptions}
     * @see https://pqina.nl/pintura/
     */
    const editorConfig = getEditorDefaults({
        imageReader: {
            orientImage: true
        },
        imageWriter: {
            quality: 0.4,
            mimeType: 'image/jpeg',
            store: (state, options, onprogress) =>
                new Promise(async (resolve, reject) => {
                    let file;
                    const {dest} = state;
                    const formData = new FormData();

                    /**
                     * Depending on our environment, we'll need to handle this piece a bit differently. For Android,
                     * there appears to be a bug tied into the `android-image-file-input` package that prevents us from
                     * using the traditional upload method as the output file (ie. `dest`) becomes unusable.
                     */
                    Logger.debug('[FileItem] Detected Android environment, using alternative upload method.');
                    const result = await fileToDataURL(state.dest);
                    formData.append('file', dataURIToBlob(result), fileName);

                    // If for some reason we can't continue, let's fail gracefully.
                    if (!file) {
                        resolve(state);
                    }

                    // Create the request payload.
                    setLoading(true);
                    formData.append('name', fileName);
                    formData.append('path', uploadPath);

                    // create a request object
                    const request = new XMLHttpRequest();
                    request.open('POST', `${Settings.apiPath}/upload`);
                    request.setRequestHeader('Authorization', `Bearer ${State.get('auth-token')}`);

                    // show progress in interface
                    request.upload.onprogress = onprogress;

                    // catch errors
                    request.onerror = () =>
                        reject('Unable to save, an unexpected error occurred.');

                    request.ontimeout = () =>
                        reject('Unable to save, request timed out.');

                    // handle success state
                    request.onload = () => {
                        if (request.status >= 200 && request.status < 300) {
                            state.store = request;
                            resolve(state);
                        } else {
                            reject('Unable to save.');
                        }
                    };

                    // start uploading the image
                    request.send(formData);
                })
        }
    });


    /**
     * Reveals the file upload interface.
     */
    const handleUploadClick = (event, bLibrary = false) => {
        if (
            !!event &&
            (
                // We'll show the file context menu for either employees running the
                // Cordova mobile application, or administrators running iPads.
                //
                isScope('Employee') || (
                    isScope('User') &&
                    App.isIPad()
                )
            )
        ) {
            Logger.debug('[FileItem] Revealing file context.', event);
            setMenuAnchorEl(event.currentTarget || event.target);
            setShowingUploadMenu(true);

            // Need to account for onTouchEnd callback a bit differently.
            if (App.isIOS()) {
                event.preventDefault();
            }
        } else {
            if (bLibrary === true) {
                if (!libraryFileInput.current) {
                    return;
                }

                Logger.debug('[FileItem] Triggering library file input.');
                libraryFileInput.current.click();

                // Need to handle iOS slightly differently.
                if (App.isIOS()) {
                    libraryFileInput.current.click();
                }
            } else {
                if (!fileInput.current) {
                    return;
                }

                Logger.debug('[FileItem] Triggering capture file input.');
                fileInput.current.click();

                // Need to handle iOS slightly differently.
                if (App.isIOS()) {
                    fileInput.current.click();
                }
            }

            // Close the context menu if there is one.
            handleUploadMenuClose();
        }
    };


    /**
     * Handles the image error message.
     *
     * @param error
     */
    const onUploadError = (error) => {
        setError(true);
        setSuccess(false);
        setLoading(false);
        setErrorMessage(error);
    };


    /**
     * Reveals the refresh confirmation modal.
     */
    const handleRefreshConfirmOpen = () => setRefreshing(true);


    /**
     * Closes the refresh confirmation modal.
     */
    const handleRefreshConfirmClose = () => setRefreshing(false);


    /**
     * Handles the file relation back to the root record post-upload.
     *
     * @param path
     * @param size
     * @returns {Promise<void>}
     */
    const onUploadSuccess = async (path, size) => {
        try {
            if (record.id) {
                await doDelete();
            }

            const response = await API.post(`documents`, {
                path,
                size: size || '',
                name: path.split('/').pop(),
                modelId: employeeId,
                modelType: 'Employee',
                isAccepted: isScope('User'),
                documentTypeId: file.id
            });

            setPath(path);
            setError(false);
            setSuccess(true);
            setErrorMessage('');

            if (onUpload) {
                await API.post('notes', {
                    note: `${file.name} was uploaded by ${user.displayName}.`,
                    userId: isScope('User') ? user.id : null,
                    modelId: employeeId,
                    modelType: 'Employee'
                });

                onUpload(response);
            }
        } catch (exception) {
            onUploadError('An unexpected error was encountered while updating your record.');
        }

        setLoading(false);
    };


    /**
     * Reveals the edit modal.
     *
     * @param file
     * @returns {void}
     */
    const doEditMode = file => {
        Logger.debug('[FileItem] Entering edit mode.', file);

        setEditing(true);
        setEditTarget(file);
    };


    /**
     * Processes the document upload.
     */
    const handleUpload = async () => {
        const file = fileInput.current.files[0];
        setLoading(true);

        if (isScope('Employee')) {
            setFileName(`${Generator.uuid()}-${file.name}`);
            doEditMode(file);
        } else {
            API.doFileUpload({
                file: file,
                path: uploadPath,
                types: []
            }, onUploadSuccess, onUploadError);
        }
    };


    /**
     * Performs an upload using the gallery input.
     *
     * @returns {Promise<void>}
     */
    const handleLibraryUpload = async () => {
        const file = libraryFileInput.current.files[0];
        setLoading(true);

        API.doFileUpload({
            file: file,
            path: uploadPath,
            types: []
        }, onUploadSuccess, onUploadError);
    };


    /**
     * Reveals the preview dialog.
     */
    const onPreviewOpen = (event) => {
        if (isScope('Employee')) {
            return fileExists && !record.isAccepted ?
                // Employees have the ability to preview files that aren't yet accepted.
                setPreviewing(true) :

                // Otherwise, they can only replace the file with a new one to prevent abuse.
                handleUploadClick(event);
        }

        setPreviewing(true);
    };


    /**
     * Closes the preview dialog.
     */
    const onPreviewClose = () => {
        setPreviewing(false);
    };


    /**
     * Reveals the delete confirmation.
     *
     * @returns {Promise<void>}
     */
    const handleDeleteOpen = () => {
        setConfirmDelete(true);
    };


    /**
     * Closes the delete confirmation.
     */
    const handleConfirmationClose = () => {
        setConfirmDelete(false);
    };


    /**
     * Deletes a document.
     *
     * @returns {Promise<void>}
     */
    const doDelete = async () => {
        const response = await API.delete(
            isScope('Employee') ? `employee-documents/${record.id}` : `documents/${record.id}`
        );

        if (onDelete) {
            onDelete(response);
        }

        setPreviewing(false);
        setConfirmDelete(false);
    };


    /**
     * Reveals the expiration form.
     */
    const handleExpirationOpen = (event) => {
        event.stopPropagation();
        setExpirationOpen(true);
        setExpirationDate(record.expirationDate);
    };


    /**
     * Closes the expiration form.
     */
    const handleExpirationClose = () => {
        setExpirationOpen(false);
    };


    /**
     * Allows the user to click the container.
     */
    const handleContainerClick = (event) => {
        if (fileExists) {
            onPreviewOpen(event);
        } else {
            if (isScope('User') && !hasPermissionTo('EDIT_EMPLOYEES')) {
                return;
            }

            handleUploadClick(event);
        }
    };


    /**
     * Closes the upload menu.
     */
    const handleUploadMenuClose = () => {
        setMenuAnchorEl(null);
        setShowingUploadMenu(false);
    };


    /**
     * Saves the expiration date update.
     *
     * @returns {Promise<void>}
     */
    const doSaveExpiration = async () => {
        if (!record.id) {
            return;
        }

        const response = await API.put(`documents/${record.id}`, {
            expirationDate: expirationDate || ''
        });

        if (onUpload) {
            onUpload(response);
        }

        setExpirationOpen(false);
    };


    /**
     * Closes the edit modal.
     */
    const handleEditClose = () => {
        setEditing(false);
        setLoading(false);

        setTimeout(() => {
            setIndex(index + 1);
        }, 250);
    };


    /**
     * Updates the approval status of an employee-uploaded document.
     *
     * @returns {Promise<void>}
     */
    const handleReviewAccept = async () => {
        if (!record.id) {
            return;
        }

        setLoading(true);

        const response = await API.put(`documents/${record.id}`, {
            isAccepted: true
        });

        if (onUpload) {
            await API.post('notes', {
                note: `${file.name} was approved by ${user.displayName}.`,
                userId: user.id,
                modelId: employeeId,
                modelType: 'Employee'
            });

            onUpload(response);
        }

        setLoading(false);
    };


    /**
     * Processes the form submission and sends a message to the scheduler.
     *
     * @returns {Promise<void>}
     */
    const doDownload = async () => {
        if (!record.id && hasPreviewContent) {
            if (documentUrl) {
                fetch(documentUrl)
                    .then(response => response.status === 200 ? response.blob() : Promise.reject('Failed to download.'))
                    .then(blob => {
                        const url = window.URL.createObjectURL(blob);
                        const a = document.createElement('a');
                        a.href = url;
                        a.download = file.name;
                        a.style.display = 'none';
                        document.body.appendChild(a);
                        a.click();
                        window.URL.revokeObjectURL(url);
                    }).catch(() => alert('Failed to download.'));
            } else {
                const uri = await new Promise(resolve => {
                    toPng(previewContentRef.current, {
                        cacheBust: true,
                    }).then((dataUrl) => {
                        resolve(dataUrl);
                    });
                })

                // Download the image.
                const link = document.createElement("a");
                link.download = `${file.name}.png`;
                link.href = uri;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            return;
        }

        if (!record.path) {
            return;
        }

        fetch(API.getFilePath(record.path))
            .then(response => response.status === 200 ? response.blob() : Promise.reject('Failed to download.'))
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = record.name;
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            }).catch(() => alert('Failed to download.'));
    };


    /**
     * Renders the expiration form.
     *
     * @returns {JSX.Element}
     * @constructor
     */
    const ExpirationForm = () => {
        return (
            <>
                <div className={'divider'}/>
                <a className={'d-flex__center'} onClick={handleExpirationOpen}>
                    <AutoDeleteIcon className={'mr__1'}/>
                    {record.expirationDate ? Formatter.date(record.expirationDate, '', false) : '(no expiration)'}
                </a>
            </>
        );
    };


    /**
     * Returns the inner document content.
     *
     * @returns {JSX.Element}
     * @constructor
     */
    const PreviewContent = (props) => {
        const {
            fixedHeight,            // {Boolean} Indicates if we should render as a fixed height / overscroll container.
        } = props;

        // If we're provided with preview content, render the callback.
        if (!record.id && previewContent) {
            const output = previewContent(isRefreshed);

            return fixedHeight ? (
                <Box className={'fixed-height__preview'} children={output}/>
            ) : output;
        }

        // Determine if this file is an image, and if so, place within an <img/> tag.
        const isImage = (record.path || '').match(/\.(jpg|jpeg|png|gif)$/i);

        return isImage ? (
            <Box
                sx={App.isCordova() ? {} : {
                    maxHeight: 'calc(100vh - 350px)',
                    overflowY: 'scroll'
                }}
                children={
                    <img src={API.getFilePath(record.path)}/>
                }
            />
        ) : (
            // Otherwise, fallback to the iframe.
            <Box className={'iframe__container'}>
                <embed
                    src={API.getFilePath(record.path)}
                    scrolling={'no'}
                    className={'responsive-iframe'}
                />
            </Box>
        );
    };


    /**
     * Refreshes the current document via refresh parameter.
     */
    const doRefreshDocument = () => {
        setRefreshed(true);
        handleRefreshConfirmClose();
    };


    /**
     * Indicates if this file is currently expired.
     *
     * @type {""|string|boolean}
     */
    const isExpired = record.isAccepted && record.expirationDate && dayjs
        .utc(record.expirationDate)
        .isBefore(dayjs.utc());


    /**
     * Indicates if this file is expiring soon.
     *
     * @type {""|string|boolean}
     */
    const isExpiringSoon = record.isAccepted && record.expirationDate && !isExpired && dayjs
        .utc(record.expirationDate)
        .isBefore(dayjs.utc().add(90, 'days'));


    /**
     * Prompts the user to take a photo.
     */
    const doClickTakeAPhoto = () => {
        handleUploadClick();
    };


    /**
     * Prompts the user to select from library.
     */
    const doClickSelectFromLibrary = () => {
        handleUploadClick(null, true);
    };

    return (
        <>
            <input
                ref={fileInput}
                key={`file-upload-${index}`}
                type={'file'}
                accept={App.isCordova() ? 'image/*' : 'image/*, text/plain, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .csv'}
                capture
                onChange={handleUpload}
                className={'d-hidden'}
            />

            <input
                ref={libraryFileInput}
                key={`file-library-${index}`}
                type={'file'}
                accept={'image/*, text/plain, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .csv'}
                onChange={handleLibraryUpload}
                className={'d-hidden'}
            />

            <Box
                key={file.id}
                onClick={Touch.clickable(handleContainerClick)}
                className={`file ${record.id && 'file--uploaded'}`}
                onTouchEnd={Touch.tappable(handleContainerClick)}
            >
                <Box className={'file__body'}>
                    {fileExists ? (
                        <IconButton
                            onClick={onPreviewOpen}
                            disabled
                            className={'mr__1'}
                        >
                            {isScope('User') ? (
                                <>
                                    {!record.isAccepted ? (
                                        <ErrorIcon color={'warning'}/>
                                    ) : (
                                        <CheckCircleIcon color={'success'}/>
                                    )}
                                </>
                            ) : (
                                <>
                                    {isExpiringSoon ? (
                                        <WarningIcon color={'warning'}/>
                                    ) : (
                                        isExpired ? (
                                            <DangerousIcon color={'error'}/>
                                        ) : (
                                            <CheckCircleIcon color={'success'}/>
                                        )
                                    )}
                                </>
                            )}
                        </IconButton>
                    ) : (
                        <IconButton
                            onClick={handleUploadClick}
                            className={'mr__1'}
                        >
                            <UploadFileIcon/>
                        </IconButton>
                    )}

                    <Box className={'d-flex__justify'} sx={{flexGrow: 1}}>
                        <div>
                            <div className={'d-flex__start'}>
                                <b>{file.name} {file.isRequired ?
                                    <span className={'asterisk'}>*</span> : ''}
                                </b>
                            </div>

                            {fileExists && (
                                <Box className={'d-flex__start file__attributes'}>
                                    <Box>
                                        {isScope('Employee') && (
                                            <>
                                                {isExpired && (
                                                    <Box className={'text__bold'} sx={{color: 'error.main'}}>
                                                        Expired on {Formatter.date(record.expirationDate, '', false)}
                                                    </Box>
                                                )}

                                                {isExpiringSoon && (
                                                    <Box className={'text__bold'} sx={{color: 'warning.main'}}>
                                                        Expires on {Formatter.date(record.expirationDate, '', false)}
                                                    </Box>
                                                )}
                                            </>
                                        )}

                                        <Box className={'text__light'}>
                                            {Formatter.dateTime(record.createdDate)}
                                        </Box>
                                    </Box>

                                    {isScope('User') && (
                                        <>
                                            {!!record.size && (
                                                <>
                                                    <div className={'divider'}/>
                                                    <div>{Formatter.number(record.size / 1048576)}mb</div>
                                                </>
                                            )}

                                            {file.hasExpiration && (
                                                <ExpirationForm/>
                                            )}
                                        </>
                                    )}
                                </Box>
                            )}
                        </div>
                        <Box>
                            <div className={'d-flex'}>
                                {isScope('Employee') && (
                                    <>
                                        {record.id && !record.isAccepted && (
                                            <Chip
                                                size={'small'}
                                                color={'warning'}
                                                label={'In Review'}
                                            />
                                        )}
                                    </>
                                )}

                                {isScope('User') && (
                                    <>
                                        {hasFile && !record.isAccepted && (
                                            <Chip
                                                size={'small'}
                                                color={'warning'}
                                                label={'Needs Review'}
                                            />
                                        )}
                                    </>
                                )}

                                {file.isRequired && (
                                    <Chip
                                        size={'small'}
                                        label={'Required'}
                                        className={'ml__2'}
                                    />
                                )}
                            </div>
                        </Box>
                    </Box>
                </Box>

                {isLoading && <LinearProgress sx={{width: '100%'}}/>}
            </Box>

            <Portal>
                <Menu
                    open={!!menuAnchorEl && isShowingUploadMenu}
                    onClose={handleUploadMenuClose}
                    anchorEl={menuAnchorEl}
                >
                    <ListItemButton
                        onClick={Touch.clickable(doClickTakeAPhoto)}
                        onTouchEnd={Touch.tappable(doClickTakeAPhoto)}
                    >
                        <ListItemIcon>
                            <CameraAltIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>Take a Photo</ListItemText>
                    </ListItemButton>
                    <ListItemButton
                        onClick={Touch.clickable(doClickSelectFromLibrary)}
                        onTouchEnd={Touch.tappable(doClickSelectFromLibrary)}
                    >
                        <ListItemIcon>
                            <CollectionsIcon fontSize="small"/>
                        </ListItemIcon>
                        <ListItemText>Choose from Library...</ListItemText>
                    </ListItemButton>
                </Menu>
            </Portal>

            {isConfirmDelete && (
                <Dialog
                    open={isConfirmDelete}
                    scroll={'body'}
                    onClose={handleConfirmationClose}
                    maxWidth={'xs'}
                    fullWidth
                >
                    <DialogHeading
                        title={"Delete Confirm"}
                        noMargin
                    />
                    <DialogContent>
                        Are you sure you'd like to delete this document?
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleConfirmationClose}>Cancel</Button>
                        <Button onClick={doDelete} color={'error'}>Confirm</Button>
                    </DialogActions>
                </Dialog>
            )}

            {isEditingExpiration && (
                <Dialog
                    open={isEditingExpiration}
                    scroll={'body'}
                    onClose={handleExpirationClose}
                    maxWidth={'xs'}
                    fullWidth
                >
                    <DialogHeading title={"Edit Expiration Date"} noMargin/>
                    <DialogContent>
                        <Box className={'columns__1'}>
                            <DatePicker
                                label={'Expiration Date'}
                                value={expirationDate ? dayjs.utc(expirationDate) : null}
                                disabled={isLoading || !hasPermissionTo('EDIT_EMPLOYEES')}
                                onChange={(event) => setExpirationDate(event ? event.utc().format('YYYY-MM-DD') : '')}
                                fullWidth
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        {hasPermissionTo('EDIT_EMPLOYEES') && (
                            <Button onClick={doSaveExpiration}>Save</Button>
                        )}
                        <Button onClick={handleExpirationClose} color={'error'}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            )}

            {isRefreshing && (
                <Dialog
                    open={isRefreshing}
                    scroll={'body'}
                    onClose={handleRefreshConfirmClose}
                    maxWidth={'xs'}
                    fullWidth
                >
                    <DialogHeading title={"Refresh Template"} noMargin/>
                    <DialogContent>
                        This action will re-create this document using the latest template available. <b>Please
                        confirm that you'd like to continue, as this action can't be undone.</b>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={doRefreshDocument}>Confirm</Button>
                        <Button onClick={handleRefreshConfirmClose} color={'error'}>Cancel</Button>
                    </DialogActions>
                </Dialog>
            )}

            <Snackbar
                open={isError}
                onClose={() => setError(false)}
                autoHideDuration={6000}
                children={
                    <Alert
                        onClose={() => setError(false)}
                        severity={'error'}
                        children={errorMessage}
                    />
                }
            />

            {isEditing && editTarget && (
                <PinturaEditorModal
                    {...editorConfig}

                    // Global attributes.
                    ref={editor}
                    src={editTarget}
                    utils={['crop']}
                    onHide={handleEditClose}
                    onProcess={() => onUploadSuccess(`${uploadPath}/${fileName}`)}
                    onLoaderror={() => onUploadError('Unable to load image.')}
                    onProcesserror={() => onUploadError('Unable to upload image, please try again.')}

                    // Additional attributes specific to iOS.
                    preventScrollBodyIfNeeded={false}
                    preventFooterOverlapIfNeeded={false}
                />
            )}

            {isPreviewing && fileExists && (
                <>
                    <Dialog
                        open={isPreviewing}
                        scroll={'body'}
                        onClose={onPreviewClose}
                        maxWidth={'md'}
                        fullWidth
                    >
                        {isLoading && <LinearProgress/>}

                        <DialogHeading
                            title={file.name}
                            actions={
                                <Box>
                                    {refreshable && (
                                        isRefreshed ? (
                                            <Chip
                                                size={'small'}
                                                icon={<CheckIcon/>}
                                                label={'This document has been refreshed.'}
                                                color={'success'}
                                            />
                                        ) : (
                                            <Chip
                                                size={'small'}
                                                icon={<HistoryIcon/>}
                                                label={'Need an updated copy?'}
                                                color={'primary'}
                                                variant={'outlined'}
                                                onClick={handleRefreshConfirmOpen}
                                                clickable
                                            />
                                        )
                                    )}

                                    <IconButton
                                        size={'small'}
                                        onClick={onPreviewClose}
                                        children={<CloseIcon fontSize={'small'}/>}
                                    />
                                </Box>

                            }
                            noMargin
                        />
                        <PreviewContent fixedHeight/>
                        <DialogActions>
                            <Box className={'w__100 d-flex__justify'}>
                                <Box>
                                    {isScope('User') && !record.isAccepted && (
                                        <Button
                                            color={'warning'}
                                            variant={'contained'}
                                            onClick={handleReviewAccept}
                                            disabled={isLoading}
                                            children={'Approve'}
                                            className={'mr__2'}
                                            startIcon={<CheckIcon/>}
                                        />
                                    )}
                                </Box>
                                <Box>
                                    {isScope('User') && (
                                        <>
                                            {hasPermissionTo('EDIT_EMPLOYEES') && (
                                                <Button
                                                    onClick={handleUploadClick}
                                                    disabled={isLoading}
                                                    children={'Replace'}
                                                    className={'mr__2'}
                                                />
                                            )}

                                            <Button
                                                onClick={doDownload}
                                                disabled={isLoading || disableDownload}
                                                children={'Download'}
                                                className={'mr__2'}
                                            />
                                        </>
                                    )}

                                    {(isScope('Employee') || hasPermissionTo('EDIT_EMPLOYEES')) && (
                                        <Button
                                            color={'error'}
                                            onClick={handleDeleteOpen}
                                            children={'Delete'}
                                            disabled={!record.id || isLoading}
                                        />
                                    )}
                                </Box>
                            </Box>
                        </DialogActions>
                    </Dialog>

                    {/*For file previews with provided content, we essentially create a mirror of the entire document behind the page which serves our download.*/}
                    {hasPreviewContent && (
                        <Portal>
                            <Box
                                ref={previewContentRef}
                                sx={{
                                    top: 0,
                                    left: 0,
                                    width: 640,
                                    zIndex: '-1',
                                    padding: '1em',
                                    position: 'fixed',
                                    background: '#fff',
                                }}
                            >
                                <PreviewContent/>
                            </Box>
                        </Portal>
                    )}
                </>
            )}
        </>
    );
};

export default FileItem;