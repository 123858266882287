import React, {useState} from "react";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import CommentIcon from '@mui/icons-material/Comment';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

/**
 * ViewNoteModal constructor.
 *
 * @constructor
 */
const ViewNoteModal = (props) => {
    const {
        record,             // {Object} The call log entry.
    } = props;

    const [isOpen, setOpen] = useState(false);

    /**
     * Reveals the voicemail dialog.
     */
    const handleOpen = () => {
        setOpen(true);
    };


    /**
     * Closes the voicemail dialog.
     */
    const handleClose = () => {
        setOpen(false);
    };

    return !!record.note ? (
        <Box className={'ml__2'}>
            <IconButton
                size={'small'}
                onClick={handleOpen}
                children={<CommentIcon fontSize={'small'}/>}
            />

            {isOpen && (
                <Dialog
                    open={isOpen}
                    scroll={'body'}
                    onClose={handleClose}
                    maxWidth={'sm'}
                    fullWidth
                >
                    <DialogContent>
                        <Box className={'columns__1'}>
                            <InputLabel
                                children={'Call Log Note'}
                                className={'form__heading form__heading--standalone'}
                            />
                            <Box className={'white-space__pre-line'}>
                                {record.note}
                            </Box>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleClose}
                            children={'Close'}
                        />
                    </DialogActions>
                </Dialog>
            )}
        </Box>
    ) : null;
};

export default ViewNoteModal;