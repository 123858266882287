import React, {useEffect, useState} from "react";

import Step from "../../../Components/Step";
import {useAuth} from "../../../Global/Auth";
import LogoHeading from "../../../Components/Typography/LogoHeading";
import StepControls from "../../../Components/StepControls";
import InputSignature from "../../../Components/Input/InputSignature";

import Box from "@mui/material/Box";

/**
 * ConductAgreementStep component.
 *
 * @returns {*}
 * @constructor
 */
const ConductAgreementStep = (props) => {
    const {
        step,                   // {Number} The current step for the user.
        index,                  // {Number} The expected step number.
        onClose,                // {Function} An optional method for closing the form if it appears in a dialog.
        employee,               // {Object} The employee object for read only context.
        readOnly,               // {Boolean} Whether the form is disabled.
        disabled,               // {Boolean} Whether the step is disabled from input.
        contentOnly,            // {Boolean} Whether to only render the content.
        onStepChange            // {Function} The on change callback to load the next step.
    } = props;

    const [isLoaded, setLoaded] = useState(false);
    const [signature, setSignature] = useState('');
    const {setUserSetting, getUserSettingValue, getUserSettingValueForEmployee} = useAuth();

    /**
     * Loads the required settings for this step.
     */
    useEffect(() => {
        handleSettingsLoad();
    }, []);


    /**
     * Synchronize the job description signature.
     */
    useEffect(() => {
        if(!readOnly && isLoaded){
            setUserSetting('HP_PROFESSIONAL_CONDUCT', signature)
        }
    }, [signature]);


    /**
     * Loads each of the appropriate user settings.
     *
     * @returns {Promise<void>}
     */
    const handleSettingsLoad = async () => {
        setSignature(
            readOnly ?
                await getUserSettingValueForEmployee(employee.id, 'HP_PROFESSIONAL_CONDUCT') :
                await getUserSettingValue('HP_PROFESSIONAL_CONDUCT')
        );

        setLoaded(true);
    };


    /**
     * The disabled state of the form.
     */
    const isDisabled = readOnly || disabled;

    return (
        <Step step={index} value={step}>
            <LogoHeading
                title={'Professional Conduct and Fee Agreement'}
                onClose={onClose}
            />

            <Box className={'columns__1'}>
                <Box>
                    As healthcare professionals, we expect that employees conduct themselves at a very high
                    level of professionalism. Reliant Staffing’s philosophy is to pay our staff what they are
                    worth. Like most companies, we value our reputation and expect that our employees’ conduct
                    reflects our principles and commitment to success.
                </Box>
                <Box>
                    If it’s determined that the employee conduct is not what was agreed to during the interview
                    process, then an employee should not expect to earn premium pay. Issues such as calling off
                    without a written excuse, constantly changing your schedule after you already confirm your
                    shifts, consistently not adhering to our scheduling process, not following directions,
                    showing up late for work, leaving a shift early, not turning time sheet in by the deadline,
                    telling us that you didn’t know you were supposed to work, no call no showing, not doing
                    your assigned job, not giving a two week notice prior to ending employment, refusing to do a
                    job for the client that is within the employee’s scope of practice or not communicating with
                    the office when we reach out to the employee. If any of these situations occur, then the
                    employee is not doing what was promised, and therefore not worth the pay rate that we
                    initially agreed to pay the employee.
                </Box>
                <Box>
                    Reliant Staffing makes money by charging the client more hourly that what we pay our
                    employees per hour. If poor conduct is causing us to spend extra time managing an employee,
                    our administrative costs increase, and as a result we make less money. Reliant Staffing will
                    not incur extra administrative expenses due to an employee not conducting themselves in a
                    profession way.
                </Box>
                <Box>
                    It is not Reliant Staffing’s job to train employees on the difference between what is
                    appropriate conduct and what is not. When these situations occur, management will bring this
                    to the employee’s attention as a verbal/written warning regarding our code of conduct,
                    professional expectations and attempt to remediate the issue.
                </Box>
                <Box>
                    Our process is very simple: Do a good job, be accountable, don’t make us look bad and follow
                    directions. Reliant Staffing only wants employees who represent us well at our facilities
                    and this disclosure protects us from employees who drain our resources or tarnish our
                    company image.
                </Box>
                <Box>
                    Acknowledgment: I agree to always conduct myself like a professional. I also understand that
                    Reliant Staffing has spent considerable time and money during the hiring
                    process. <span className={'text__highlight'}>If employment ends for any reason within 90 days of the
                    date of this disclosure, I agree to reimburse Reliant Staffing for any credentialing fees Reliant
                    has paid on my behalf.</span>
                </Box>

                {!contentOnly && (
                    <>
                        <InputSignature
                            value={signature}
                            label={'Please sign here:'}
                            disabled={isDisabled}
                            onChange={setSignature}
                            scaleFactor={2}
                        />

                        {!readOnly && (
                            <StepControls
                                step={step}
                                disabled={isDisabled || !signature}
                                onStepChange={onStepChange}
                            />
                        )}
                    </>
                )}
            </Box>
        </Step>
    );
};

export default ConductAgreementStep;