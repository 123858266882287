import React from "react";

import Page from "../../Components/Page";
import RequestBadgeForm from "./Dashboard/RequestBadgeForm";

/**
 * RequestBadge component.
 *
 * @returns {JSX.Element}
 * @constructor
 *
 * @description Provides an interface for employees to refer their friends.
 */
const RequestBadge = () => {
    return (
        <Page
            hideHeader
        >
            <RequestBadgeForm/>
        </Page>
    );
};

export default RequestBadge;