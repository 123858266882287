import React, {useMemo} from "react";

import Box from "@mui/material/Box";

/**
 * Step component.
 *
 * @param props
 * @returns {null}
 * @constructor
 */
const Step = (props) => {
    const {
        step,       // {Number} The step's position in sequence.
        value,      // {Number} The page's position / current step value.
        children    // {Component} The step content.
    } = props;

    return step === value ? (
        <Box>{children}</Box>
    ) : null;
};

export default Step;