import React, {useCallback, useEffect, useState, forwardRef} from "react";

import API from "../../../Global/API";
import State from "../../../Global/State";
import Logger from "../../../Global/Logger";
import ColGroup from "./DataTable/ColGroup";
import EmptyRow from "./DataTable/EmptyRow";
import Settings from "../../../Global/Settings";
import LoadingRow from "./DataTable/LoadingRow";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import {TableVirtuoso} from 'react-virtuoso';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

/**
 * DataTable component.
 *
 * @returns {*}
 * @constructor
 */
const DataTable = (props) => {
    const {
        query,                  // {Object} The query filter to apply to the endpoint.
        model,                  // {Model} The model definition to represent.
        onClick,                // {Function} An on-click handler for an individual results row.
        actions,                // {Boolean} Indicates if we should include an "actions" column.
        topAlign,               // {Boolean} Indicates if the table should be top-aligned.
        doReload,               // {Function} The data load callback to force refresh the parent layout.
        hideHeader,             // {Boolean} An optional flag to hide the table header.
        onLoadComplete,         // {Function} An optional callback to fire when the data load is complete.
    } = props;

    const cacheKeys = model.getCacheKeys();
    const [page, setPage] = useState(0);
    const [layout, setLayout] = useState({});
    const [results, setResults] = useState([]);
    const [sortKey, setSortKey] = useState(State.get(cacheKeys.tableSort) || '');
    const [isLoading, setLoading] = useState(false);
    const [sortDirection, setSortDirection] = useState(State.get(cacheKeys.sortDirection) || 'asc');
    const [hasMoreResults, setHasMoreResults] = useState(true);
    const [tableComponents, setTableComponents] = useState({});

    /**
     * Initialize the table components on mount.
     */
    useEffect(() => {
        getLayout(model);
        getTableComponents(model, actions);
        initKeyCommands();
    }, []);


    /**
     * Handles the filter update.
     */
    useEffect(() => {
        setPage(0);
        setResults([]);
        setHasMoreResults(true);
    }, [query]);


    /**
     * Load all ancillary data on mount or parameter update.
     */
    useEffect(() => {
        addRecordsForCurrentPage();
    }, [page, query, sortKey, sortDirection]);


    /**
     * The various table options for the model.
     *
     * @type {Object}
     */
    const tableOptions = {
        ...model.tableOptions
    };


    /**
     * The padding value for each table row.
     *
     * @type {string}
     */
    const rowPadding = tableOptions.rowPadding || 'normal';


    /**
     * Initializes the model property layout.
     *
     * @type {Function}
     */
    const getLayout = useCallback((model) => {
        setLayout(model.getLayoutProperties());
    }, [model]);


    /**
     * Initializes the key handling functionality.
     *
     * @type {Function}
     */
    const initKeyCommands = useCallback(() => {
        document.onkeydown = async (event) => {
            event = event || window.event;

            if (!event.key) {
                return;
            }

            Logger.debug(`[DataTable] Key "${event.key}" was detected.`);
        };
    }, []);


    /**
     * Initializes the table components for the layout based on the input parameters..
     *
     * @type {Function}
     */
    const getTableComponents = useCallback((model, actions) => {
        setTableComponents({
            EmptyPlaceholder: (props) => model && (
                <TableBody>
                    <EmptyRow {...props} model={model}/>
                </TableBody>
            ),
            FillerRow: (props) => model && <LoadingRow {...props} model={model}/>,
            Scroller: React.forwardRef((props, ref) => (
                <TableContainer
                    ref={ref}
                    className={'table table--striped'}
                    {...props}
                />
            )),
            Table: (props) => (
                <Table
                    size={'small'}
                    stickyHeader={true}
                    {...props}
                />
            ),
            TableHead: forwardRef((props, ref) => (
                <>
                    {model && (
                        <ColGroup model={model}/>
                    )}
                    {!hideHeader && (
                        <TableHead {...props} ref={ref}/>
                    )}
                </>
            )),
            TableRow: forwardRef((props, ref) => (
                <TableRow {...props} ref={ref}/>
            )),
            TableBody: forwardRef((props, ref) => (
                <TableBody {...props} ref={ref}/>
            ))
        })
    }, [model, actions]);


    /**
     * Triggers a reload to an individual result row.
     *
     * @param record
     * @param success
     * @returns {Promise<void>}
     */
    const handleRowUpdate = async (record, success) => {
        const route = model.getRoute();

        if (record && record.id && route) {
            for (let i = 0; i < results.length; i++) {
                const current = results[i];

                if (current.id !== record.id) {
                    continue;
                }

                results[i] = await API.get(`${route}/${record.id}`, query);
                results[i]['@updated'] = true;
                break;
            }

            setResults([...results]);
        }

        if (doReload) {
            doReload(record, success);
        }
    };


    /**
     * Handles column sorting.
     *
     * @param key
     * @param direction
     * @returns {Promise<void>}
     */
    const handleSortChange = (key, direction) => {
        setPage(0);
        setResults([]);
        setHasMoreResults(true);

        // Apply the sort selection.
        setSortKey(key);
        setSortDirection(direction);
    };


    /**
     * Performs the actual record load.
     *
     * @returns {Promise<void>}
     */
    const addRecordsForCurrentPage = async () => {
        const route = model.getRoute();
        const request = {
            $orderby: sortKey ? `${sortKey} ${sortDirection}` : ``,
            ...query,
            $filter: model.getFilterString(query.$filter)
        };

        // Append pagination parameters to the endpoint.
        const perPage = Settings.defaultPageLength;
        request['$top'] = perPage;
        request['$skip'] = perPage * page;

        // Ignore the process if we don't actually have an API endpoint.
        if (isLoading || !hasMoreResults || !route) {
            return;
        }

        // Fetch the current payload.
        Logger.debug(`[DataTable] Loading results for page index: ${page}`);
        setLoading(true);
        const records = await API.get(route, request);

        if (!records.length) {
            setHasMoreResults(false);
        }

        setLoading(false);
        setResults([
            ...results,
            ...records
        ]);

        if (onLoadComplete) {
            onLoadComplete(records);
        }
    };


    /**
     * Triggered whenever a user clicks on a column heading. The behavior should ultimately be that
     * the user toggles between an unselected, selected ascending, and selected descending.
     *
     * @param key
     */
    const handleColumnSort = (key) => {
        if (sortKey !== key) {
            setSortKey(key);
            setSortDirection('asc');
            handleSortChange(key, 'asc');
            State.set(cacheKeys.tableSort, key);
            State.set(cacheKeys.sortDirection, 'asc');
            return;
        }

        if (sortDirection === 'asc') {
            setSortDirection('desc');
            handleSortChange(sortKey, 'desc');
            State.set(cacheKeys.sortDirection, 'desc');
            return;
        }

        if (sortDirection === 'desc') {
            setSortKey('');
            handleSortChange('', sortDirection);
            State.set(cacheKeys.tableSort, '');
        }
    };


    /**
     * Initializes the load on the next page.
     */
    const handleNextPage = () => {
        Logger.debug('[DataTable] End reached, loading next page...');

        if (isLoading || !hasMoreResults) {
            return;
        }

        setPage(page + 1);
    };


    /**
     * The table heading.
     *
     * @returns {*}
     * @constructor
     */
    const TableHeading = () => {
        if (model.getTableOption('hideTableHeader')) {
            return null;
        }

        let tableHeading = [];

        for (let i in layout) {
            if (!layout.hasOwnProperty(i)) {
                continue;
            }

            const property = layout[i];
            const key = property.getKey();

            // Render the heading itself.
            tableHeading.push(
                <TableCell
                    sx={{padding: '0.2em'}}
                    key={`DataTable-${model.key}__heading-${key}`}
                    align={property.getAlignment()}
                >
                    <Button
                        sx={{
                            color: 'inherit',
                            fontWeight: 'bold',
                            lineHeight: 'initial',
                            textTransform: 'none',
                            "&.MuiButtonBase-root:hover": {
                                backgroundColor: "transparent"
                            }
                        }}
                        onClick={() => handleColumnSort(key)}
                        disabled={!property.isSortable()}
                    >
                        <Box className={'d-flex'}>
                            {!property.isLabelHidden() && property.getLabel()}

                            {sortKey === key && sortDirection === 'desc' ?
                                <ArrowDropUpIcon fontSize={'small'}/> : null}

                            {sortKey === key && sortDirection === 'asc' ?
                                <ArrowDropDownIcon fontSize={'small'}/> : null}
                        </Box>
                    </Button>
                </TableCell>
            );
        }

        if (!!actions) {
            tableHeading.push(
                <TableCell
                    sx={{padding: '0.2em'}}
                    key={`DataTable-${model.key}__heading-action`}
                    align={'right'}
                />
            );
        }

        return (
            <TableRow>
                {tableHeading}
            </TableRow>
        );
    };


    /**
     * Renders an individual result.
     *
     * @param props
     * @returns {*}
     * @constructor
     */
    const ResultRow = (props) => {
        const {row} = props;
        const {id} = row || {};
        const className = `${row['@updated'] ? 'row__updated' : ''} ${topAlign ? 'v-align__top' : ''}`;
        const resultRow = [];

        // Render each column within the layout.
        for (let i in layout) {
            if (!layout.hasOwnProperty(i)) {
                continue;
            }

            const property = layout[i];
            const key = property.getKey();

            resultRow.push(
                <TableCell
                    key={`DataTable-${model.key}-${id}__row-${key}`}
                    align={property.getAlignment()}
                    padding={rowPadding}
                    children={property.getRendered(row, handleRowUpdate, onClick)}
                    className={className}
                />
            );
        }

        // Append the actions column, sharing the reload callback throughout.
        if (!!actions) {
            resultRow.push(
                <TableCell
                    key={`DataTable-${model.key}-${id}__row-action`}
                    align={'right'}
                    children={model.action({record: row, doReload: handleRowUpdate})}
                    className={className} padding={rowPadding}
                />
            );
        }

        return resultRow;
    };

    return tableComponents && (
        <Box className={'data__wrapper'}>
            <TableVirtuoso
                data={results}
                overscan={8}
                endReached={handleNextPage}
                components={tableComponents}
                itemContent={(index, row) => <ResultRow key={index} row={row}/>}
                atBottomThreshold={4}
                fixedHeaderContent={() => <TableHeading/>}
            />
        </Box>
    );
};

export default DataTable;