import React from "react";

import State from "../../../../Global/State";
import Settings from "../../../../Global/Settings";

import Box from "@mui/material/Box";

/**
 * PdfEmbed component.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
const PdfEmbed = (props) => {
    const {
        path            // {String} The PDF endpoint for embedding.
    } = props;

    return path ? (
        <Box className={'iframe__container'}>
            <embed
                src={`${Settings.apiPath}/${path}&x-access-token=${State.get('auth-token')}`}
                scrolling={'no'}
                className={'responsive-iframe'}
            />
        </Box>
    ) : null;
};

export default PdfEmbed;