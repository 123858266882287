import React from "react";

import Model from "../Definitions/Model";
import Property from "../Definitions/Property";
import PropertyMap from "../Definitions/PropertyMap";
import SpecialtyForm from "../Actions/SpecialtyActions/SpecialtyForm";
import SpecialtyActions from "../Actions/SpecialtyActions";

import {faker} from '@faker-js/faker';

/**
 * Specialty model.
 *
 * @type {Object}
 */
const Specialty = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'specialty',


    /**
     * The appropriate API endpoint root.
     */
    route: 'specialties',


    /**
     * Additional query parameters to share throughout the application.
     */
    query: {
        $filter: 'isDeleted eq {0}'
    },


    /**
     * The form component.
     *
     * @returns {*}
     * @param parameters
     */
    form: (parameters) => <SpecialtyForm {...parameters}/>,


    /**
     * The menu action.
     *
     * @param parameters
     * @returns {*}
     */
    action: (parameters) => <SpecialtyActions {...parameters}/>,


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'name'
    ],


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            faker: () => faker.datatype.uuid(),
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'name',
            label: 'Name',
            faker: () => faker.name.jobType(),
            render: (record) => record.name,
            sortable: true
        })
    ])
};

export default Specialty;