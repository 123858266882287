import React, {useMemo, useState} from "react";

import {useAuth} from "../Global/Auth";
import DialogHeading from "./Typography/DialogHeading";

import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import DialogContent from "@mui/material/DialogContent";

/**
 * ModalLink component.
 *
 * @constructor
 */
const ModalButton = (props) => {
    const {
        id,                 // {String} An optional ID to include.
        size,               // {String} The dialog size (defaults to "xl").
        label,              // {Component} The button label.
        onOpen,             // {Function} An optional post-open event.
        heading,            // {Component} The modal heading.
        onClose,            // {Function} An optional post-close event.
        doRender,           // {Function} A render callback, to pass in the open/close methods.
        noMargin,           // {Boolean} Whether to disable the default margin in the header.
        children,           // {Component} The inner component children.
        className,          // {String} An optional class to add to the container.
        bodyStyle,          // {Object} Any additional styles to apply to the body.
        component,          // {String} An optional replacement component for the outer box.
        labelStyle,         // {Object} A style override for the label component.
        permission,         // {String} An optional permission to check for before approving the click handler.
        closeButton,        // {Boolean} Indicates if we should append a close button to the result modal.
    } = props;

    const [open, setOpen] = useState(false);
    const {hasPermissionTo} = useAuth();

    /**
     * Reveals the form modal.
     */
    const handleOpen = () => {
        if(permission && !hasPermissionTo(permission)){
            return;
        }

        setOpen(true);

        if(onOpen){
            onOpen();
        }
    };


    /**
     * Closes the form modal.
     */
    const handleClose = () => {
        setOpen(false);

        if(onClose){
            onClose();
        }
    };


    /**
     * The inner modal content.
     *
     * @type {unknown}
     */
    const innerContent = useMemo(() => {
        if(doRender){
            return doRender(handleClose);
        }

        return null;
    }, [doRender, handleClose]);


    /**
     * Fixes a bug related to MUI's menu component, which will try to autofocus a menu item by letter. This
     * prevents certain keys from being used in child inputs, where the menu "steals" focus from the input.
     *
     * @param event
     * @returns {*}
     */
    const handleFocusTrap = event => event.stopPropagation();

    return (
        <>
            <Box
                id={id || ''}
                sx={labelStyle || {}}
                onClick={handleOpen}
                children={label}
                component={component}
                className={className || ''}
            />

            {open && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    maxWidth={size || 'xl'}
                    onClose={handleClose}
                    fullWidth
                    onKeyDown={handleFocusTrap}
                >
                    {heading && (
                        <DialogHeading
                            title={heading}
                            noMargin={!!noMargin}
                        />
                    )}
                    <DialogContent sx={bodyStyle || {}}>
                        <Box className={'columns__1'}>
                            {children}

                            {innerContent}

                            {closeButton && (
                                <Button
                                    variant={'outlined'}
                                    onClick={handleClose}
                                    children={'Close'}
                                />
                            )}
                        </Box>
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default ModalButton;