import React, {useCallback, useEffect, useMemo, useState} from "react";

import API from "../../Global/API";
import Page from "../../Components/Page";
import State from "../../Global/State";
import Logger from "../../Global/Logger";
import Settings from "../../Global/Settings";
import {useAuth} from "../../Global/Auth";
import EventForm from "./Schedule/EventForm";
import LoadingRow from "./Schedule/LoadingRow";
import useDebounce from "../../Hooks/useDebounce";
import EventMetrics from "./Schedule/EventMetrics";
import EmployeeMenu from "./Schedule/EmployeeMenu";
import CrumbWrapper from "../../Components/CrumbWrapper";
import CalendarEvent from "./Schedule/CalendarEvent";
import DialogHeading from "../../Components/Typography/DialogHeading";
import ClientSlotMenu from "./Schedule/ClientSlotMenu";
import ClientListItem from "../../Components/Lists/ClientListItem";
import EmployeeSlotMenu from "./Schedule/EmployeeSlotMenu";
import EmployeeListItem from "../../Components/Lists/EmployeeListItem";
import AvailabilityReport from "./Schedule/AvailabilityReport";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import dayjs from "dayjs";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import Badge from "@mui/material/Badge";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import MenuIcon from '@mui/icons-material/Menu';
import TextField from "@mui/material/TextField";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import {useParams} from "react-router";
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import AssessmentIcon from '@mui/icons-material/Assessment';
import interactionPlugin from "@fullcalendar/interaction"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'

/**
 * The number of results per page.
 *
 * @type {number}
 */
const perPage = 50;


/**
 * Schedule component.
 *
 * @returns {*}
 * @constructor
 */
const Schedule = () => {
    const {
        recordId,           // {String} The ID of the pre-selected record (of the specified "recordType")
        recordType,         // {String} The key of the type of pre-selected object.
    } = useParams();

    // Capture any request parameters.
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const groupId = urlParams.get('groupId');
    const groupType = urlParams.get('groupType');

    // Detect the default tab selection.
    const defaultTab = recordType ?
        (recordType === 'employee' ? 'Employee' : 'Client') :
        State.get('scheduler-tab', 'Employee');

    // Capture the default filters.
    const defaultFilters = {
        '@showShifts': true,
        '@showAvailability': false,
        ...State.json('scheduler-filters')
    };

    const [tab, setTab] = useState(defaultTab);
    const [page, setPage] = useState(0);
    const [view, setView] = useState('month');
    const {hasPermissionTo} = useAuth();
    const [index, setIndex] = useState(0);
    const [events, setEvents] = useState([]);
    const [search, setSearch] = useState('');
    const [endDate, setEndDate] = useState(dayjs());
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isLoaded, setLoaded] = useState(false);
    const [lastDate, setLastDate] = useState(null);
    const [startDate, setStartDate] = useState(dayjs());
    const [clipboard, setClipboard] = useState({});
    const [employees, setEmployees] = useState([]);
    const [lastClick, setLastClick] = useState(null);
    const [selectedDays, setSelectedDays] = useState([]);
    const [availability, setAvailability] = useState([]);
    const [isAddingEvent, setAddingEvent] = useState(false);
    const [isLoadingNext, setLoadingNext] = useState(false);
    const [selectedShifts, setSelectedShifts] = useState([]);
    const [hasMoreResults, setHasMoreResults] = useState(true);
    const [selectedClients, setSelectedClients] = useState([]);
    const [isViewingMetrics, setViewingMetrics] = useState(false);
    const [isConfirmingDelete, setConfirmDelete] = useState('');
    const [highlightEmployee, setHighlightEmployee] = useState({});
    const [selectedEmployees, setSelectedEmployees] = useState([]);
    const [clipboardNotification, setClipboardNotification] = useState(false);
    const [isMobileDrawerExpanded, setMobileDrawerExpanded] = useState(false);

    /**
     * If the user is navigating here from a grouping (ie. the client selection), we'll
     * need to preload that selection.
     */
    useEffect(() => {
        handlePreSelection();
    }, [recordType, recordId]);


    /**
     * Handles search results.
     */
    useEffect(() => {
        debounceSearch();
    }, [search]);


    /**
     * Load all required information on component mount.
     */
    useEffect(() => {
        if (isEmployeeView) {
            getEmployees();
        } else {
            getClients();
        }
    }, [tab, page, index]);


    /**
     * Removes any clipboard entries whenever the selection changes.
     */
    useEffect(() => {
        setClipboard({});
    }, [selectedEmployees, selectedClients]);


    /**
     * Reload events whenever the employee selection changes.
     */
    useEffect(() => {
        setSelectedShifts([]);
        getEvents();
    }, [view, startDate, selectedEmployees, selectedClients]);


    /**
     * Indicates whether the selected tab is the "Employee" tab.
     *
     * @type {boolean}
     */
    const isEmployeeView = tab === 'Employee';


    /**
     * Synchronizes the highlight action if viewing clients.
     *
     * @param record
     */
    const handleEmployeeHighlight = record => {
        if (highlightEmployee && highlightEmployee.id === record.id) {
            setHighlightEmployee({});
        } else {
            setHighlightEmployee(record);
        }
    };


    /**
     * Indicates whether the scheduler is editable.
     *
     * @type {boolean}
     */
    const isEditable = isEmployeeView ? !!selectedEmployees.length : !!selectedClients.length;


    /**
     * Returns the appropriate container class for the scheduler.
     *
     * @type {string}
     */
    const containerClass = `two-column__right scheduler__wrapper ${loading ? 'scheduler--loading' : ''} ${isEditable ? 'scheduler--editable' : ''}`;


    /**
     * Maps database events to recognizable events within the scheduler.
     *
     * @type []
     */
    const calendarData = (() => {
        const availabilityDays = {};

        return [
            // The availability definitions. Only return availability entries for employees that
            // are configured to display their availability on the calendar.
            //
            ...(isEmployeeView && defaultFilters['@showAvailability'] ? availability : []).map(definition => {
                const start = dayjs(definition.startDate);

                return {
                    id: definition.id,
                    end: start.toDate(),
                    type: 'Availability',
                    start: start.toDate(),
                    record: definition,
                    heading: 'Availability',
                    endDate: start.toDate(),
                    isAllDay: true,
                    startDate: start.toDate(),
                    employeeId: definition.employeeId,
                };
            }),

            // The events themselves. For facilities, we'll only show standard, non-availability items. Otherwise,
            // we'll show either type depending on the filter options for the selected employee(s).
            //
            ...events.filter(event => {
                if (!isEmployeeView) {
                    return !event.isBlockRequested;
                }

                if (event.isBlockRequested) {
                    if(defaultFilters['@showAvailability'] !== true){
                        return false;
                    }

                    if(!availabilityDays.hasOwnProperty(event.startDate)){
                        availabilityDays[event.startDate] = [];
                    }

                    if(availabilityDays[event.startDate].includes(event.title)){
                        return false;
                    }

                    availabilityDays[event.startDate].push(event.title);
                    return event.title !== 'Not Available';
                } else {
                    return defaultFilters['@showShifts'] !== false
                }
            }).map(event => {
                const end = dayjs(event.endDate);
                const start = dayjs(event.startDate);

                const isSame = end.isSame(start);
                const isBefore = end.isBefore(start);

                return {
                    id: event.id,
                    end: isSame ? end.add(30, 'minutes').toDate() : (
                        !isBefore ? end.toDate() : (
                            view === 'month' ?
                                start.add(30, 'minutes').toDate() :
                                end.add(1, 'days').toDate()
                        )
                    ),
                    type: 'Event',
                    notes: event.notes,
                    start: start.toDate(),
                    record: event,
                    heading: event.isBlockRequested ? event.title : (
                        isEmployeeView ?
                            (event.client && event.client.id ? event.client.name : '(No client)') :
                            (event.employee && event.employee.id ? `${event.employee.lastName}, ${event.employee.firstName}` : '(No client)')
                    ),
                    endDate: end.toDate(),
                    clientId: event.clientId,
                    isAllDay: !!event.isBlockRequested,
                    startDate: start.toDate(),
                    employeeId: event.employeeId,
                    legacyData: event.legacyData,
                    isAvailability: event.isBlockRequested,
                };
            })
        ];
    })();


    /**
     * Reveals the scheduling metrics modal.
     */
    const handleMetricsOpen = () => {
        setViewingMetrics(true);
    };


    /**
     * Closes the scheduling metrics modal.
     */
    const handleMetricsClose = () => {
        setViewingMetrics(false);
    };


    /**
     * Pre-assigns the selection based on the input groupings (passed via GET parameter).
     *
     * @returns {Promise<void>}
     */
    const handlePreSelection = async () => {
        switch (groupType) {
            case 'client':
                setTab('Employee');
                setLoading(true);
                setSelectedEmployees(
                    await API.get('employees', {
                        $top: 500,
                        $expand: 'status,specialty,state',
                        $filter: `employeeClients/any{clientId in {${groupId}}}`
                    })
                );
                break;

            default:
                switch (recordType) {
                    case 'employee':
                        setTab('Employee');

                        const response = await API.get(`employees/${recordId}`, {
                            $select: '*,pointTotal',
                            $expand: 'status,specialty,state',
                        });

                        if (response.id) {
                            setSelectedEmployees([{
                                ...response,
                                ...defaultFilters
                            }]);
                        }
                        break;

                    case 'client':
                        setTab('Client');

                        setSelectedClients([
                            await API.get(`clients/${recordId}`)
                        ]);
                        break;
                }
                break;
        }
    };


    /**
     * Loads all available employees.
     *
     * @returns {Promise<void>}
     */
    const getEmployees = async () => {
        Logger.debug(`[Schedule] Loading employee results for page ${page}...`);

        const results = await API.get('employees', {
            $top: perPage,
            $skip: page * perPage,
            $select: '*,pointTotal',
            $expand: 'specialty,status,employeeClients',
            $filter: `isActive eq {1} and isDeleted eq {0} and displayName eq {${search}}`,
            $orderby: 'lastName asc',
        });

        if (!results.length || results.length < perPage) {
            setHasMoreResults(false);
        }

        setEmployees([
            ...employees,
            ...results
        ]);

        if (isEmployeeView) {
            setLoaded(true);
        }

        setLoadingNext(false);
    };


    /**
     * Loads all available clients.
     *
     * @returns {Promise<void>}
     */
    const getClients = async () => {
        Logger.debug(`[Schedule] Loading client results for page ${page}...`);

        const results = await API.get('clients', {
            $top: perPage,
            $skip: page * perPage,
            $filter: `isDeleted eq {0} and name eq {${search}}`,
            $orderby: 'name asc',
        });

        if (!results.length || results.length < perPage) {
            setHasMoreResults(false);
        }

        setClients([
            ...clients,
            ...results
        ]);

        if (!isEmployeeView) {
            setLoaded(true);
        }

        setLoadingNext(false);
    };


    /**
     * Updates a single property of one of the loaded employees.
     *
     * @param employee
     * @param key
     * @param value
     */
    const setEmployeeProperty = (employee, key, value) => {
        for (let i = 0; i < employees.length; i++) {
            const current = employees[i];

            if (current.id !== employee.id) {
                continue;
            }

            current[key] = value;
            break;
        }

        // Handle selected employees as well, if applicable.
        if (isEmployeeView) {
            for (let i = 0; i < selectedEmployees.length; i++) {
                const current = selectedEmployees[i];

                if (current.id !== employee.id) {
                    continue;
                }

                current[key] = value;
                break;
            }

            setSelectedEmployees([...selectedEmployees]);
        }

        if (['@showShifts', '@showAvailability'].includes(key)) {
            State.set('scheduler-filters', JSON.stringify({
                ...defaultFilters,
                [key]: value
            }));
        }

        setEmployees([...employees]);
    };


    /**
     * Loads all the events for the selection.
     *
     * @returns {Promise<void>}
     */
    const getEvents = async (hideLoader) => {
        Logger.debug(`[Schedule] Loading events for active selection...`);

        const recordIds = isEmployeeView ?
            selectedEmployees.map(employee => employee.id) :
            selectedClients.map(client => client.id);

        if (!recordIds.length) {
            Logger.debug(`[Schedule] No selection available, skipping event fetch.`);
            setEvents([]);
            setAvailability([]);
            return setLoading(false);
        }

        // Display the loading indicator if not configured to hide it.
        if (!hideLoader) {
            setLoading(true);
        }

        const relationKey = isEmployeeView ? 'employee' : 'client';

        // Determine the true date values to pass along to the event endpoint.
        let endDateJs = dayjs(endDate.toDate());
        let startDateJs = dayjs(startDate.toDate());

        switch (view) {
            case 'month':
                break;

            case 'week':
                endDateJs = endDateJs.add(1, 'weeks');
                startDateJs = startDateJs.subtract(1, 'weeks');
                break;

            case 'timeline':
                endDateJs = endDateJs.add(1, 'days');
                startDateJs = startDateJs.subtract(1, 'days');
                break;
        }

        await Promise.all([
            fetchEvents(startDateJs, endDateJs, relationKey, recordIds),
            fetchAvailability(startDateJs, endDateJs, recordIds)
        ]);

        setLoading(false);
    };


    /**
     * Fetches and assigns events for the current period.
     *
     * @param startDateJs
     * @param endDateJs
     * @param relationKey
     * @param recordIds
     * @returns {Promise<void>}
     */
    const fetchEvents = async (startDateJs, endDateJs, relationKey, recordIds) => {
        setEvents(
            await API.get('events', {
                $top: 10000,
                $expand: 'client,employee',
                $filter: `startDate ge {${startDateJs.format('YYYY-MM-DD')}} and startDate le {${endDateJs.format('YYYY-MM-DD')}} and ${relationKey}/any{id in {${recordIds.join(',')}}}${relationKey === 'client' ? ' and isBlockRequested eq {0} and employee/any{isDeleted eq {0}}' : ''} and isDeleted eq {0}`,
            })
        );
    };


    /**
     * Fetches and assigns availability records for the current period.
     *
     * @param startDateJs
     * @param endDateJs
     * @param recordIds
     * @returns {Promise<void>}
     */
    const fetchAvailability = async (startDateJs, endDateJs, recordIds) => {
        const availability = isEmployeeView ? await API.get('availability', {
            $top: 10000,
            $filter: `startDate ge {${startDateJs.format('YYYY-MM-DD')}} and startDate le {${endDateJs.format('YYYY-MM-DD')}} and employee/any{id in {${recordIds.join(',')}}}`
        }) : [];

        setAvailability(availability);
    };


    /**
     * Handles tab changes on the scheduler.
     *
     * @param updated
     * @param keepSelection
     * @param keepSearch
     */
    const handleTabChange = (updated, keepSelection, keepSearch) => {
        if (tab === updated && !keepSearch) {
            return;
        }

        switch (updated) {
            case 'Employee':
                setEmployees([]);

                if (!keepSelection) {
                    setSelectedEmployees([]);
                }
                break;

            case 'Client':
                setClients([]);

                if (!keepSelection) {
                    setSelectedClients([]);
                }
                break;
        }

        setPage(0);
        setIndex(index + 1);
        setLoaded(false);
        setLoadingNext(false);
        setHasMoreResults(true);
        setHighlightEmployee({});

        if (!keepSearch) {
            setEvents([]);
            setSearch('');
        }

        setTab(updated);
        State.set('scheduler-tab', updated);
    };


    /**
     * Toggles a client for selection.
     *
     * @param client
     * @param event
     */
    const handleClientSelect = (client, event = null) => {
        setLoading(true);

        let isMultiple = false; // (event && event.shiftKey) || !event;
        let isSelected = !!selectedClients.filter(comparator => client.id === comparator.id).length;
        let baseSelection = [...selectedClients];
        let updatedSelected = [];

        if (!isSelected) {
            if (!isMultiple) {
                baseSelection = [];
            }

            baseSelection.push(client);
            updatedSelected = [...baseSelection];
        } else {
            updatedSelected = [...baseSelection.filter(record => record.id !== client.id)]
        }

        setHighlightEmployee({});
        setSelectedClients(updatedSelected);
        State.set('scheduler-clients', JSON.stringify(updatedSelected));
        Logger.debug(`[Schedule] Updated client selection to: ${JSON.stringify(updatedSelected.map(selected => selected.id))}`);
    };


    /**
     * Toggles an employee for selection.
     *
     * @param employee
     */
    const handleEmployeeSelect = (employee) => {
        setLoading(true);

        let isMultiple = false; // (event && event.shiftKey) || !event;
        let isSelected = !!selectedEmployees.filter(comparator => employee.id === comparator.id).length;
        let baseSelection = [...selectedEmployees];
        let updatedSelected = [];

        if (!isSelected) {
            if (!isMultiple) {
                baseSelection = [];
            }

            baseSelection.push({
                ...employee,
                ...defaultFilters
            });
            updatedSelected = [...baseSelection];
        } else {
            updatedSelected = [...baseSelection.filter(record => record.id !== employee.id)]
        }

        setSelectedEmployees(updatedSelected);
        State.set('scheduler-employees', JSON.stringify(updatedSelected));
        Logger.debug(`[Schedule] Updated employee selection to: ${JSON.stringify(updatedSelected.map(selected => selected.id))}`);
    };


    /**
     * Handles data loading on scroll.
     *
     * @param event
     */
    const handleContainerScroll = (event) => {
        const el = event.target;
        const threshold = 600;

        if ((el.scrollTop + threshold) <= (el.scrollHeight - el.offsetHeight)) {
            return;
        }

        doLoadNext();
    };


    /**
     * Loads the next page of results.
     */
    const doLoadNext = () => {
        if(isLoadingNext){
            return;
        }

        Logger.debug('[Schedule] Attempting to load next set of results...');

        if (hasMoreResults) {
            setLoadingNext(true);
            setPage(page + 1);
        }
    };


    /**
     * Called whenever the user changes views within the scheduler.
     *
     * @param event
     */
    const handleViewChange = (event) => {
        setEndDate(dayjs(event.end));
        setStartDate(dayjs(event.start));

        setView('month');
        setLoading(true);
    };


    /**
     * Processes the date change event on the scheduler.
     *
     * @param event
     */
    const handleDateChange = (event) => {
        setEvents([]);
        setLoading(true);
        setStartDate(dayjs(event.value));
    };


    /**
     * Hides the clipboard notification.
     */
    const handleClipboardNotificationClose = (event, reason) => {
        if (!reason) {
            return;
        }

        if (reason === 'timeout') {
            setClipboardNotification(false);
        }
    };


    /**
     * Updates the left selection based on the search criteria.
     *
     * @param event
     */
    const handleSearchChange = (event) => {
        setSearch(event.target.value);
    };


    /**
     * Handles the search debouncing.
     *
     * @type {debounced}
     */
    const debounceSearch = useDebounce(() => {
        handleTabChange(tab, true, true);
    });


    /**
     * Pastes an event.
     */
    const handlePaste = async () => {
        Logger.debug(`[Schedule] Pasting event in date selection:`, selectedDays);

        // Ignore if we don't have a clipboard element.
        if (!clipboard || !clipboard.id || !selectedDays.length || !isEditable) {
            return;
        }

        await Promise.all(
            selectedDays.map(async lastDate => {
                const dateJs = dayjs(lastDate.date);
                const groupIndex = 0;

                const endDateJs = dayjs(`${dateJs.format('YYYY-MM-DD')} ${dayjs(clipboard.endDate).format('HH:mm:ss')}`);
                const startDateJs = dayjs(`${dateJs.format('YYYY-MM-DD')} ${dayjs(clipboard.startDate).format('HH:mm:ss')}`);

                // Update the clipboard payload to match the scheduler selection.
                const payload = !isEmployeeView ? {
                    ...clipboard,
                    helixId: '',
                    endDate: endDateJs.format('YYYY-MM-DD HH:mm:ss'),
                    clientId: selectedClients[groupIndex].id,
                    startDate: startDateJs.format('YYYY-MM-DD HH:mm:ss'),
                    punchInDate: '',
                    punchOutDate: ''
                } : {
                    ...clipboard,
                    helixId: '',
                    endDate: endDateJs.format('YYYY-MM-DD HH:mm:ss'),
                    startDate: startDateJs.format('YYYY-MM-DD HH:mm:ss'),
                    employeeId: selectedEmployees[groupIndex].id,
                    punchInDate: '',
                    punchOutDate: ''
                };

                // Remove the copied / cloned ID.
                if (payload.hasOwnProperty('id')) {
                    delete payload.id;
                }

                if (payload.hasOwnProperty('employee')) {
                    delete payload.employee;
                }

                if (payload.hasOwnProperty('client')) {
                    delete payload.client;
                }

                await API.post(`events`, payload);
            })
        );

        await getEvents();
    };


    /**
     * Copies an element to the clipboard.
     *
     * @param item
     */
    const handleShiftCopy = (item) => {
        setClipboard(item);
        setClipboardNotification(true);
    };


    /**
     * Refreshes the employee record data on update (ie. tally updates, etc.).
     *
     * @param record
     */
    const handleEmployeeUpdate = (record) => {
        Logger.debug(`[Schedule] Received employee update event.`, record);
        const updated = [...employees];

        for (let i = 0; i < updated.length; i++) {
            if (updated[i].id !== record.id) {
                continue;
            }

            updated[i] = record;
            break;
        }

        // Handle the active selection, if applicable.
        if (isEmployeeView) {
            for (let i = 0; i < selectedEmployees.length; i++) {
                if (selectedEmployees[i].id !== record.id) {
                    continue;
                }

                selectedEmployees[i] = record;
                break;
            }

            setSelectedEmployees([...selectedEmployees]);
        }

        setEmployees([...updated]);
    };


    /**
     * Handles a day selection with support for multiple.
     *
     * @param event
     */
    const handleDateSelect = (event) => {
        let days = [];
        let endDateJs = dayjs(event.end);
        let startDateJs = dayjs(event.start);

        if (endDateJs.isAfter(startDateJs)) {
            while (endDateJs.isAfter(startDateJs)) {
                days.push({
                    ...event,
                    date: startDateJs.toDate()
                });
                startDateJs = startDateJs.add(1, 'day');
            }
        }

        setSelectedDays(days);
    };


    /**
     * Handles the date click event.
     *
     * @param event
     */
    const handleDateClick = (event) => {
        if (!hasPermissionTo('EDIT_SCHEDULE')) {
            return;
        }

        const clickTime = Date.now();
        const isNew = !lastDate || lastDate.date.toISOString() !== event.date.toISOString();

        setLastDate(event);
        setSelectedShifts([]);

        if (!lastClick) {
            return setLastClick(clickTime);
        }

        if (clickTime - lastClick < 500 && !isAddingEvent && !isNew) {
            setAddingEvent(true);
        }

        setLastClick(clickTime);
    };


    /**
     * Handles the event selection.
     *
     * @param event
     * @param shift
     */
    const handleEventSelect = (event, shift) => {
        const record = shift.record;

        if (!record || (!record.id || record.isBlockRequested || shift.type !== 'Event')) {
            return;
        }

        let isMultiple = (event && event.shiftKey) || !event;
        let isSelected = !!selectedShifts.filter(comparator => record.id === comparator.id).length;
        let baseSelection = [...selectedShifts];
        let updatedSelected = [];

        if (!isSelected) {
            if (!isMultiple) {
                baseSelection = [];
            }

            baseSelection.push(record);
            updatedSelected = [...baseSelection];
        } else {
            updatedSelected = [...baseSelection.filter(selected => selected.id !== record.id)]
        }

        setSelectedShifts(updatedSelected);
    };


    /**
     * Closes the event form.
     */
    const handleEventFormClose = () => {
        setAddingEvent(false);
    };


    /**
     * Reloads events on save.
     */
    const handleEventSubmit = () => {
        handleEventFormClose();
        getEvents();
    };


    /**
     * Triggered whenever the user moves an event on the calendar.
     *
     * @param event
     * @returns {Promise<void>}
     */
    const handleEventChange = async (event) => {
        const props = event.event.extendedProps;
        const record = props.record;
        const updated = event.event;

        if (!record.id) {
            return;
        }

        const eventEndJs = dayjs(record.endDate);
        const eventStartJs = dayjs(record.startDate);
        const updatedEndJs = dayjs(updated.end);
        const updatedStartJs = dayjs(updated.start);

        const response = await API.put(`events/${record.id}`, {
            endDate: `${updatedEndJs.format('YYYY-MM-DD')} ${eventEndJs.format('HH:mm:ss')}`,
            startDate: `${updatedStartJs.format('YYYY-MM-DD')} ${eventStartJs.format('HH:mm:ss')}`,
        });

        setEvents(events.map(event => {
            if (event.id !== record.id) {
                return event;
            }

            return {
                ...record,
                endDate: response.endDate,
                startDate: response.startDate,
            };
        }));
    }


    /**
     * Handles various key logic.
     *
     * @type {(function(*): void)|*}
     */
    const handleDeleteConfirm = () => {
        if (!hasPermissionTo('DELETE_SCHEDULE')) {
            return;
        }

        if (!selectedShifts.length) {
            return;
        }

        setConfirmDelete(true);
    };


    /**
     * Handles the closing of the delete confirmation dialog.
     */
    const handleDeleteConfirmClose = () => {
        setConfirmDelete(false);
    };


    /**
     * Deletes each of the selected events.
     *
     * @returns {Promise<void>}
     */
    const handleDelete = async () => {
        setLoading(true);

        await Promise.all(selectedShifts.map(shift => API.delete(`events/${shift.id}`)));

        getEvents();
        setConfirmDelete(false);
        setSelectedShifts([]);
    };


    /**
     * Copies the first selected shift.
     *
     * @param event
     */
    const bindCopyKey = (event) => {
        Logger.debug(`[Schedule] Received copy key event.`, selectedShifts);

        if (!selectedShifts.length) {
            return;
        }

        if (!!event.ctrlKey) {
            handleShiftCopy(selectedShifts[0]);
        }
    };


    /**
     * Pastes the copied shift.
     *
     * @param event
     */
    const bindPasteKey = (event) => {
        Logger.debug(`[Schedule] Received paste key event.`, clipboard);

        if (!clipboard || !clipboard.id) {
            return clipboard;
        }

        if (!!event.ctrlKey) {
            handlePaste();
        }
    };


    /**
     * Returns the calendar item component.
     *
     * @param event
     * @returns {*}
     * @constructor
     */
    const renderCalendarEvent = (event) => (
        <CalendarEvent
            tab={tab}
            highlight={tab === 'Client' && highlightEmployee.id === event.event.extendedProps.record.employeeId}
            doReload={() => getEvents(true)}
            onSelect={handleEventSelect}
            dataItem={event.event.extendedProps}
            selected={!!selectedShifts.filter(shift => shift.id === event.event.extendedProps.record.id).length}
            selectedClients={selectedClients}
            selectedEmployees={selectedEmployees}
        />
    );


    /**
     * Returns the appropriate calendar form component.
     *
     * @param props
     * @returns {*}
     * @constructor
     */
    const CalendarForm = (props) => {
        return (
            <EventForm
                tab={tab}
                selectedClients={selectedClients}
                selectedEmployees={selectedEmployees}
                {...props}
            />
        );
    };


    /**
     * Displayed whenever no results are available.
     *
     * @returns {*}
     * @constructor
     */
    const NoResultsListItem = () => {
        return (
            <MenuItem>
                <Box
                    sx={{
                        width: '100%',
                        paddingTop: '0.2em',
                        paddingBottom: '0.2em'
                    }}
                    className={'text__center text__disclaimer'}
                >
                    {search ? `No results for "${search}"...` : `No results available.`}
                </Box>
            </MenuItem>
        );
    };


    /**
     * Bind various key actions against the scheduler.
     */
    const handleKeyPress = useCallback(event => {
        Logger.debug(event);

        switch (event.key) {
            case 'c':
            case 'C':
                bindCopyKey(event);
                break;

            case 'v':
            case 'V':
                bindPasteKey(event);
                break;

            case 'Delete':
                handleDeleteConfirm();
                break;
        }
    }, [selectedShifts, clipboard, selectedDays, isEditable, selectedClients, selectedEmployees]);


    /**
     * Bind key events for various shortcuts on the calendar.
     */
    useEffect(() => {
        window.addEventListener("keydown", handleKeyPress);

        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);


    /**
     * Reveals the mobile drawer.
     */
    const handleMobileDrawerOpen = () => {
        setMobileDrawerExpanded(true);
    };


    /**
     * Closes the mobile drawer.
     */
    const handleMobileDrawerClose = () => {
        setMobileDrawerExpanded(false);
    };


    /**
     * Various properties to determine next / previous buttons.
     */
    const hasEmployeeSelection = isEmployeeView && selectedEmployees.length;
    const selectedEmployee = hasEmployeeSelection ? selectedEmployees[0] : {};
    const selectedIndex = employees.findIndex(employee => employee.id === selectedEmployee.id);
    const hasNextEmployee = selectedIndex < (employees.length - 1);
    const hasPreviousEmployee = selectedIndex > 0;
    const isInvalidSelection = selectedIndex === -1;


    /**
     * Loads the next employee in the sequence.
     */
    const handleEmployeeNext = () => {
        if (!hasNextEmployee) {
            return;
        }

        // Start loading the next page if we're within n records.
        if (selectedIndex > employees.length - 5) {
            doLoadNext();
        }

        handleEmployeeSelect(employees[selectedIndex + 1]);
    };


    /**
     * Loads the previous employee in the sequence.
     */
    const handleEmployeePrevious = () => {
        if (!hasPreviousEmployee) {
            return;
        }

        handleEmployeeSelect(employees[selectedIndex - 1]);
    }


    /**
     * The selection content for either the left navigation or the mobile drawer.
     *
     * @type {Component}
     */
    const recordSelection = useMemo(() => (
        <Box sx={{width: Settings.drawerWidth}}>
            {isEditable ? (
                <>
                    {isEmployeeView ? (
                        <Box className={'two-column__left-results two-column__left-results--no-menu well__container'}
                            sx={{borderRadius: 0}}>
                            {selectedEmployees.map(employee => {
                                return (
                                    <>
                                        <Paper className={'mb__3'}>
                                            <EmployeeListItem
                                                key={`employee-${employee.id}`}
                                                employee={employee}
                                                onChange={setEmployeeProperty}
                                                imageSize={54}
                                                secondary={
                                                    <Box className={'d-flex__start mr__3'}>
                                                        <IconButton
                                                            size={'small'}
                                                            onClick={() => handleEmployeeSelect(employee)}
                                                        >
                                                            <CloseIcon fontSize={'small'}/>
                                                        </IconButton>
                                                    </Box>
                                                }
                                            />
                                        </Paper>

                                        <EmployeeSlotMenu
                                            record={employee}
                                            onNext={hasNextEmployee && !isInvalidSelection ? handleEmployeeNext : null}
                                            doReload={handleEmployeeUpdate}
                                            onChange={(key, value) => setEmployeeProperty(employee, key, value)}
                                            onPrevious={hasPreviousEmployee && !isInvalidSelection ? handleEmployeePrevious : null}
                                        />
                                    </>
                                )
                            })}
                        </Box>
                    ) : (
                        <Box className={'two-column__left-results two-column__left-results--no-menu well__container'}
                            sx={{borderRadius: 0}}>
                            {selectedClients.map(client => {
                                return (
                                    <>
                                        <Paper className={'mb__3'}>
                                            <ClientListItem
                                                key={`client-${client.id}`}
                                                client={client}
                                                imageSize={54}
                                                textWidth={180}
                                                secondary={
                                                    <Box className={'d-flex__start mr__3'}>
                                                        <IconButton
                                                            size={'small'}
                                                            onClick={() => handleClientSelect(client)}
                                                        >
                                                            <CloseIcon fontSize={'small'}/>
                                                        </IconButton>
                                                    </Box>
                                                }
                                            />
                                        </Paper>

                                        <ClientSlotMenu
                                            key={`${client.id}-schedule-client-menu`}
                                            record={client}
                                            doReload={() => true}
                                            onHighlight={handleEmployeeHighlight}
                                        />
                                    </>
                                )
                            })}
                        </Box>
                    )}
                </>
            ) : (
                <>
                    <Box className={'columns__1 p__3'}>
                        <TextField
                            value={search}
                            label={'Search...'}
                            onChange={handleSearchChange}
                            fullWidth
                        />
                    </Box>
                    <Divider/>
                    <Box
                        onScroll={handleContainerScroll}
                        className={'two-column__left-results'}
                    >
                        <List dense sx={{width: '100%'}}>
                            {/*Display loading / skeleton entries while loading.*/}
                            {!isLoaded && [...Array(10).keys()].map((item, i) => (
                                <LoadingRow key={`loading-${i}`}/>
                            ))}

                            {isLoaded && isEmployeeView && (
                                <>
                                    {employees.map(employee => {
                                        const isSelected = !!selectedEmployees.filter(comparator => comparator.id === employee.id).length;
                                        const context = {
                                            ...employee,
                                            ...defaultFilters
                                        };

                                        return (
                                            <EmployeeListItem
                                                key={`employee-${employee.id}`}
                                                employee={context}
                                                selected={isSelected}
                                                onChange={setEmployeeProperty}
                                                onSelect={handleEmployeeSelect}
                                            />
                                        );
                                    })}

                                    {!employees.length && <NoResultsListItem/>}

                                    {/*Loading buffer for additional results.*/}
                                    {hasMoreResults && [...Array(5).keys()].map((item, i) => (
                                        <LoadingRow key={`buffer-${i}`}/>
                                    ))}
                                </>
                            )}

                            {isLoaded && !isEmployeeView && (
                                <>
                                    {clients.map(client => {
                                        const isSelected = !!selectedClients.filter(comparator => comparator.id === client.id).length;

                                        return (
                                            <ClientListItem
                                                key={`client-${client.id}`}
                                                client={client}
                                                selected={isSelected}
                                                onSelect={handleClientSelect}
                                                textWidth={210}
                                                secondary={<div/>}
                                            />
                                        );
                                    })}

                                    {!clients.length && <NoResultsListItem/>}

                                    {/*Loading buffer for additional results.*/}
                                    {hasMoreResults && [...Array(5).keys()].map((item, i) => (
                                        <LoadingRow key={`buffer-${i}`}/>
                                    ))}
                                </>
                            )}
                        </List>
                    </Box>
                </>
            )}
        </Box>
    ), [search, isEmployeeView, clients, selectedClients, hasMoreResults, employees, selectedEmployees, isLoaded]);

    return (
        <Page hideHeader fullScreen>
            <Box className={'page__heading'}>
                <Box className={'index__title d-flex__start'}>
                    <CalendarMonthIcon/>
                    <h2>{isViewingMetrics ? 'Availability' : 'Schedule'}</h2>
                </Box>

                <Box className={'d-flex'}>
                    {!isViewingMetrics ? (
                        <>
                            <Box className={'d-flex__center'}>
                                <CrumbWrapper
                                    value={tab}
                                    options={[
                                        'Employee',
                                        'Client',
                                    ]}
                                    onChange={handleTabChange}
                                />
                            </Box>

                            {hasPermissionTo('VIEW_COMMUNICATIONS') && (
                                <Box className={'d-flex__center ml__2'}>
                                    <Box className={'breadcrumbs'} sx={{padding: '0.3em'}}>
                                        <a className={`crumb`} onClick={handleMetricsOpen}>
                                            <AssessmentIcon fontSize={'small'} className={'mr__1'}/>
                                            Manage Availability
                                        </a>
                                    </Box>
                                </Box>
                            )}
                        </>
                    ) : (
                        <>
                            <Box className={'d-flex__center'}>
                                <Box className={'breadcrumbs'} sx={{padding: '0.3em'}}>
                                    <a className={`crumb`} onClick={handleMetricsClose}>
                                        <NavigateBeforeIcon
                                            fontSize={'small'}
                                            className={'mr__1'}
                                        />
                                        Back to Schedule
                                    </a>
                                </Box>
                            </Box>
                        </>
                    )}

                    <Badge
                        sx={{
                            '& .MuiBadge-badge': {
                                top: 10,
                                right: 6
                            }
                        }}
                        color={'warning'}
                        variant={'dot'}
                        children={
                            <IconButton onClick={handleMobileDrawerOpen}>
                                <MenuIcon/>
                            </IconButton>
                        }
                        invisible={!isEditable}
                        className={'schedule__drawer-toggle ml__2'}
                    />
                </Box>
            </Box>
            <Divider/>

            {isViewingMetrics ? (
                <Box className={'availability__results'}>
                    <AvailabilityReport/>
                </Box>
            ) : (
                <Box className={'calendar__wrapper'}>
                    <Box
                        children={recordSelection}
                        className={'schedule__left-column two-column__border-right'}
                    />

                    <Box
                        children={
                            <FullCalendar
                                select={handleDateSelect}
                                events={calendarData}
                                plugins={[dayGridPlugin, interactionPlugin]}
                                editable={isEditable && hasPermissionTo('EDIT_SCHEDULE')}
                                datesSet={handleViewChange}
                                dateClick={isEditable ? handleDateClick : null}
                                selectable={isEditable}
                                initialView={'dayGridMonth'}
                                eventChange={handleEventChange}
                                eventContent={renderCalendarEvent}
                            />
                        }
                        className={containerClass}
                    />
                </Box>
            )}

            {isAddingEvent && lastDate && (
                <EventForm
                    tab={tab}
                    onClose={handleEventFormClose}
                    dataItem={lastDate}
                    onSubmit={handleEventSubmit}
                    onCancel={handleEventFormClose}
                    selectedClients={selectedClients}
                    selectedEmployees={selectedEmployees}
                />
            )}

            {clipboardNotification && (
                <Snackbar
                    open={clipboardNotification}
                    onClose={handleClipboardNotificationClose}
                    children={
                        <Alert severity="info" sx={{width: '100%'}}>
                            Item copied to the clipboard!
                        </Alert>
                    }
                    autoHideDuration={2000}
                />
            )}

            {isConfirmingDelete && (
                <Dialog
                    open={isConfirmingDelete}
                    scroll={'body'}
                    onClose={handleDeleteConfirmClose}
                    maxWidth={'xs'}
                    fullWidth
                >
                    <DialogHeading
                        title={"Are you sure?"}
                        noMargin
                    />
                    <DialogContent>
                        Please confirm that you'd like to delete the selected shifts. This action can't be undone.
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={handleDelete}
                            children={'Delete'}
                            disabled={loading}
                        />
                        <Button
                            color={'error'}
                            onClick={handleDeleteConfirmClose}
                            children={'Cancel'}
                            disabled={loading}
                        />
                    </DialogActions>
                </Dialog>
            )}

            <Dialog
                open={false}
                scroll={'body'}
                maxWidth={'lg'}
                fullWidth
            >
                <EventMetrics isVisible={isViewingMetrics}/>
                <DialogActions>
                    <Button
                        color={'error'}
                        onClick={handleMetricsClose}
                        children={'Cancel'}
                    />
                </DialogActions>
            </Dialog>

            {/*For mobile, display the selection in a bottom left window.*/}
            <Snackbar
                open={false}
                className={'schedule__mobile-preview'}
            >
                <Paper sx={{minWidth: 350}}>
                    {isEmployeeView ? (
                        <>
                            {selectedEmployees.map(employee => (
                                <EmployeeListItem
                                    key={`employee-${employee.id}`}
                                    employee={employee}
                                    onChange={setEmployeeProperty}
                                    secondary={
                                        <EmployeeMenu
                                            record={employee}
                                            onChange={(key, value) => setEmployeeProperty(employee, key, value)}
                                            doReload={handleEmployeeUpdate}
                                        />
                                    }
                                />
                            ))}
                        </>
                    ) : (
                        <>
                            {selectedClients.map(client => (
                                <ClientListItem
                                    key={`client-${client.id}`}
                                    client={client}
                                />
                            ))}
                        </>
                    )}
                </Paper>
            </Snackbar>

            {/*The mobile selection drawer.*/}
            <Drawer
                open={isMobileDrawerExpanded}
                anchor={'right'}
                onClose={handleMobileDrawerClose}
                children={recordSelection}
            />
        </Page>
    );
};

export default Schedule;