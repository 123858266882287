import React, {useEffect} from "react";

import App from "../Global/App";
import {_navigate, GlobalHistory} from "../Global/History";

/**
 * Download component.
 *
 * @returns {*}
 * @constructor
 */
const Download = () => {
    /**
     * Returns the appropriate download link based on the device.
     *
     * @returns {string}
     */
    const getDownloadLink = () => {
        const userAgent = navigator.userAgent || navigator.vendor || window.opera;

        // Detect android devices.
        if (/android/i.test(userAgent)) {
            return 'https://play.google.com/store/apps/details?id=com.reliantstaffing.app';
        }

        // iOS detection from: http://stackoverflow.com/a/9039885/177710
        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
            return 'https://apps.apple.com/us/app/reliant-staffing-healthcare/id6460259134';
        }

        return 'https://play.google.com/store/apps/details?id=com.reliantstaffing.app';
    }


    /**
     * Redirect appropriately on mount.
     */
    useEffect(() => {
        if(!App.isCordova()){
            window.location.href = getDownloadLink();
        }
        else{
            _navigate('/login');
        }
    }, []);

    return (
        <GlobalHistory/>
    );
};

export default Download;