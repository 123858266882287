import React from "react";

import Specialty from "../../Models/Specialty";
import TableLayout from "../../Components/Layouts/TableLayout";

import FolderSpecialIcon from '@mui/icons-material/FolderSpecial';

/**
 * Specialties component.
 *
 * @returns {*}
 * @constructor
 */
const Specialties = () => (
    <TableLayout
        icon={<FolderSpecialIcon/>}
        model={Specialty}
        heading={'Specialties'}
        condensed
        hideFilters
        formPermission={'EDIT_LIBRARIES'}
    />
);

export default Specialties;