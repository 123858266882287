import React, {useState} from "react";

import API from "../../Global/API";
import DialogHeading from "../../Components/Typography/DialogHeading";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Radio from "@mui/material/Radio";
import Rating from "@mui/material/Rating";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";

/**
 * InterviewButton component.
 *
 * @returns {*}
 * @constructor
 */
const InterviewButton = (props) => {
    const {
        record,         // {Object} The employee record.
        onSave,         // {Function} A post-save callback function.
    } = props;

    const [note, setNote] = useState('');
    const [open, setOpen] = useState(false);
    const [rating, setRating] = useState(0);
    const [outcome, setOutcome] = useState('reject');
    const [isLoading, setLoading] = useState(false);

    /**
     * Handlers for toggling the dialog.
     */
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    /**
     * Process the note save.
     *
     * @returns {Promise<void>}
     */
    const doSave = async () => {
        setLoading(true);

        const payload = {
            isHighlighted: false,
            interviewDate: dayjs().utc().format('YYYY-MM-DD HH:mm:ss'),
            interviewRating: rating
        };

        // Append any additional parameters depending on the selected outcome.
        switch(outcome){
            case 'invite':
                payload['isInvited'] = true;
                break;

            case 'reject':
                const statuses = await API.get('statuses', {
                    $top: 1,
                    $select: 'id',
                    $filter: `modelType eq {Employee} and name eq {Inactive}`
                });

                if(statuses.length){
                    payload['statusId'] = statuses[0];
                }

                payload['isDeleted'] = true;
                break;
        }

        const response = await API.put(`employee/${record.id}`, payload);

        await API.post(`notes`, {
            note: `Interviewed Applicant (${outcome === 'invite' ? 'Invited' : 'Rejected'})${note ? `: ${note}` : ``}`,
            modelId: record.id,
            modelType: 'Employee',
        });

        setNote('');
        setOpen(false);
        setLoading(false);

        if(onSave){
            onSave(response);
        }
    };

    return (
        <>
            <Button
                size={'small'}
                color={'primary'}
                onClick={handleOpen}
                variant={'contained'}
                disabled={!record.contactDate}
                children={'Interview'}
            />
            <Dialog
                open={open}
                scroll={'body'}
                onClose={handleClose}
                maxWidth={'sm'}
                fullWidth
            >
                <DialogHeading
                    title={'Record Interview'}
                    noMargin
                />
                <DialogContent>
                    <Box className={'columns__1'}>
                        <FormControl>
                            <FormLabel>How would you rate this candidate?</FormLabel>
                            <Rating
                                value={rating}
                                onChange={(event, updated) => {
                                    setRating(updated);

                                    if(updated >= 4){
                                        setOutcome('invite');
                                    }

                                    if(updated <= 2){
                                        setOutcome('reject')
                                    }
                                }}
                            />
                        </FormControl>

                        <FormControl>
                            <FormLabel>What was the outcome?</FormLabel>
                            <RadioGroup value={outcome} onChange={event => setOutcome(event.target.value)} row>
                                <FormControlLabel value="reject" control={<Radio />} label="Reject" />
                                <FormControlLabel value="invite" control={<Radio />} label="Invite" />
                            </RadioGroup>
                        </FormControl>

                        <TextField
                            rows={4}
                            value={note}
                            label={'Please provide any additional details...'}
                            disabled={isLoading}
                            onChange={event => setNote(event.target.value)}
                            multiline
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={doSave}
                        children={'Save'}
                        disabled={isLoading}
                    />
                    <Button
                        color={'error'}
                        onClick={handleClose}
                        children={'Cancel'}
                        disabled={isLoading}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default InterviewButton;