import React, {useState} from "react";

import Logger from "../../../Global/Logger";
import EventForm from "./EventForm";
import ModalButton from "../../../Components/ModalButton";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import EventNoteIcon from '@mui/icons-material/EventNote';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PunchClockIcon from '@mui/icons-material/PunchClock';

/**
 * CalendarEvent component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const CalendarEvent = (props) => {
    const {
        tab,                        // {String} The selected calendar tab.
        onCopy,                     // {Function} Called whenever the user copies an event.
        onDelete,                   // {Function} Handles event deletions.
        onSelect,                   // {Function} Handles event selections.
        dataItem,                   // {Object} The base calendar event object.
        selected,                   // {Boolean} Whether the shift is already selected.
        doReload,                   // {Function} Reloads the parent layout.
        highlight,                  // {Boolean} Indicates if we should highlight this event.
        selectedClients,            // {Array} The selected facilities from the layout.
        selectedEmployees,          // {Array} The selected employees from the layout.
    } = props;

    const [lastClick, setLastClick] = useState(null);
    const [isEditingEvent, setEditingEvent] = useState(false);
    const [isConfirmDelete, setConfirmDelete] = useState(false);
    const startDateJs = dayjs(dataItem.startDate);

    /**
     * Distinguish availability selections from their definitions.
     *
     * @type {boolean}
     */
    const isAvailabilityRecord = dataItem.type === 'Availability';
    const isAvailabilityEvent = !isAvailabilityRecord && dataItem.isAvailability;


    /**
     * Handle legacy representations.
     *
     * @type {boolean}
     */
    const record = {...dataItem.record};
    const isLegacy = !!dataItem.legacyData;
    const legacyData = isLegacy ? JSON.parse(dataItem.legacyData) : {};
    const legacyConfig = legacyData.configuration ? JSON.parse(legacyData.configuration) : {};
    const legacyValues = legacyConfig.days || [];
    const selectedDays = legacyValues.filter(day => !!day.values.filter(value => !!value.value && value.name !== 'Not Available').length);
    const firstSelectedDay = selectedDays.length ? selectedDays[0] : {};
    const isFirstSelectedDay = firstSelectedDay.date && dayjs(firstSelectedDay.date).format('YYYY-MM-DD') === startDateJs.format('YYYY-MM-DD');


    /**
     * Parse the ending time string for the item.
     *
     * @type {string}
     */
    const endTime = dayjs(dataItem.endDate).format('h:mma');


    /**
     * Parse the starting time string for the item.
     *
     * @type {string}
     */
    const startTime = startDateJs.format('h:mma');


    /**
     * Parse the punch in time for this shift.
     *
     * @type {string|string}
     */
    const punchInTime = record.punchInDate ? dayjs(record.punchInDate).format('h:mma') : '';


    /**
     * Parse the punch out time for this shift.
     *
     * @type {string|string}
     */
    const punchOutTime = record.punchOutDate ? dayjs(record.punchOutDate).format('h:mma') : '';


    /**
     * Indicates if the shift has a corresponding time entry.
     *
     * @type {boolean}
     */
    const hasTimeEntry = !!punchInTime && !!punchOutTime;


    /**
     * Returns the appropriate attribute label for the event item.
     *
     * @type {string}
     */
    const eventAttributeLabel = !dataItem.isAvailability ? [
        record.isSelfScheduled ? 'SS' : '',
        record.isClientCancelled ? 'CC' : '',
        record.isEmployeeCancelled ? 'ECO' : '',
        record.isEmployeeLate ? 'EL' : '',
        record.isEmployeeNoCall ? 'NC/NS' : '',
        record.isEmployeeLeftEarly ? 'LE' : '',
        record.isEmployeeNotOnSchedule ? 'NOS' : '',
        record.isHelixSelfScheduled ? 'HX' : '',
    ].filter(attribute => !!attribute.trim()).join(', ') : '';


    /**
     * Closes the event edit form.
     */
    const handleEditFormClose = () => {
        setEditingEvent(false);
    };


    /**
     * Closes the confirmation dialog.
     */
    const handleConfirmationClose = () => {
        setConfirmDelete(false);
    };


    /**
     * Reveals the confirmation dialog.
     */
    const handleConfirmationOpen = () => {
        setConfirmDelete(true);
    };


    /**
     * Handles the form save event.
     */
    const handleSave = () => {
        handleEditFormClose();

        if (doReload) {
            doReload();
        }
    };


    /**
     * Handles the click event.
     *
     * @param event
     */
    const handleKeyPress = (event) => {
        // Detect CTRL + C for event copying.
        if ((event.syntheticEvent.ctrlKey || event.syntheticEvent.metaKey) && event.syntheticEvent.keyCode == 67) {
            Logger.debug(`[CalendarEvent] Copied event:`, dataItem);
            onCopy(dataItem);
        }

        // Detect DELETE key for event removal.
        if (event.syntheticEvent.keyCode === 46) {
            handleConfirmationOpen();
        }

        if (props.onKeyDown) {
            props.onKeyDown(event);
        }
    };


    /**
     * Handles the click event.
     */
    const handleClick = (event) => {
        if (isAvailabilityEvent || record.isBlockRequested) {
            return;
        }

        const clickTime = Date.now();

        if (!lastClick) {
            doSelect(event);
            return setLastClick(clickTime);
        }

        if (clickTime - lastClick < 500) {
            if (!isEditingEvent) {
                setEditingEvent(true);
            }
        } else {
            doSelect(event);
        }

        setLastClick(clickTime);
    };


    /**
     * Emits the selection action.
     *
     * @param event
     */
    const doSelect = (event) => {
        if (onSelect) {
            onSelect(event, dataItem);
        }
    }

    return (
        <>
            <Box
                {...props}

                onClick={handleClick}
                className={`calendar__event ${isAvailabilityEvent ? 'availability-event' : ''} ${isAvailabilityRecord ? 'configuration-event' : ''} ${selected ? 'calendar__event--selected' : ''} ${highlight ? 'calendar__event--highlight' : ''}`}
                tabIndex={0}
            >
                {isAvailabilityRecord ? (
                    <Box>
                        <Box className={'columns__1 text__small'} sx={{gap: '0.5em', gridGap: '0.5em'}}>
                            <Box className={'event__wrap'}><b>Hours: {record.hours}</b></Box>

                            {record.comments && (
                                <ModalButton
                                    size={'xs'}
                                    label={<i style={{color: '#fff'}}>(View Comment)</i>}
                                    component={'a'}
                                    bodyStyle={{
                                        paddingTop: 0
                                    }}
                                    children={
                                        <Box className={'columns__1 columns--small pt__3'}>
                                            <h4>Comment:</h4>
                                            <Box>
                                                {record.comments}
                                            </Box>
                                        </Box>
                                    }
                                />
                            )}
                        </Box>
                    </Box>
                ) : (
                    <>
                        {!isAvailabilityEvent && (
                            <Box>
                                <Box className={'columns__1 text__small'} sx={{gap: '0.5em', gridGap: '0.5em'}}>
                                    <Box className={'event__wrap'}>
                                        <b>{dataItem.heading}</b>
                                    </Box>

                                    <Box className={'calendar-item__time d-flex__start'}>
                                        <AccessTimeIcon sx={{fontSize: '1em', marginRight: '0.2em'}}/>
                                        <div>{startTime} - {endTime}</div>
                                    </Box>

                                    {hasTimeEntry && (
                                        <Box className={'calendar-item__time d-flex__start'}>
                                            <PunchClockIcon sx={{fontSize: '1em', marginRight: '0.2em'}}/>
                                            <div>{punchInTime} - {punchOutTime}</div>
                                        </Box>
                                    )}

                                    {dataItem.notes && (
                                        <div className={'d-flex__start'}>
                                            <EventNoteIcon sx={{fontSize: '1em', marginRight: '0.2em'}}/>
                                            <div className={'event__notes'}>
                                                {dataItem.notes}
                                            </div>
                                        </div>
                                    )}

                                    {eventAttributeLabel && (
                                        <div>{eventAttributeLabel}</div>
                                    )}
                                </Box>
                            </Box>
                        )}

                        {isAvailabilityEvent && !isLegacy && (
                            <Box>
                                <Box className={'columns__1 text__small'} sx={{gap: '0.5em', gridGap: '0.5em'}}>
                                    <Box className={'event__wrap'}>{dataItem.heading}</Box>
                                </Box>
                            </Box>
                        )}

                        {isAvailabilityEvent && isLegacy && (
                            <Box>
                                <Box className={'columns__1 text__small'} sx={{gap: '0.5em', gridGap: '0.5em'}}>
                                    {legacyValues.filter(day => {
                                        const dateJs = dayjs(day.date);

                                        // Only return availability that belongs to the day.
                                        return dateJs.format('YYYY-MM-DD') === startDateJs.format('YYYY-MM-DD');
                                    }).map(day => {
                                        return day.values
                                            .filter(value => !!value.value)
                                            .map((value, j) => {
                                                return isFirstSelectedDay ? (
                                                    <Box key={j} className={'columns__1 columns--small'}>
                                                        {legacyConfig.numberOfHours} - {value.name}
                                                    </Box>
                                                ) : (
                                                    <Box key={j}>
                                                        {value.name}
                                                    </Box>
                                                );
                                            });
                                    })}

                                    {legacyConfig.comment && (
                                        <ModalButton
                                            size={'xs'}
                                            label={<i>(View Comment)</i>}
                                            component={'a'}
                                            bodyStyle={{
                                                paddingTop: 0
                                            }}
                                            children={
                                                <Box className={'columns__1 columns--small pt__3'}>
                                                    <h4>Comment:</h4>
                                                    <Box>
                                                        {legacyConfig.comment}
                                                    </Box>
                                                </Box>
                                            }
                                        />
                                    )}
                                </Box>
                            </Box>
                        )}
                    </>
                )}
            </Box>

            {isEditingEvent && (
                <EventForm
                    tab={tab}
                    onClose={handleEditFormClose}
                    dataItem={dataItem}
                    onSubmit={handleSave}
                    onCancel={handleEditFormClose}
                    selectedClients={selectedClients}
                    selectedEmployees={selectedEmployees}
                />
            )}
        </>
    );
};

export default CalendarEvent;