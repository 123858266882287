import {useState, useEffect} from 'react'

import API from "../Global/API";
import Logger from "../Global/Logger";
import Settings from "../Global/Settings";
import Formatter from "../Global/Formatter";

import {Device} from "@twilio/voice-sdk";

/**
 * Returns the active phone device.
 *
 * @returns {Device|null}
 */
const getDevice = () => Settings.activeDevice;


/**
 * Returns the active inbound call.
 *
 * @returns {Device|null}
 */
const getInbound = () => Settings.activeInbound;


/**
 * usePhoneDevice hook.
 *
 * @returns {{device: Device}}
 */
const usePhoneDevice = () => {
    const [device, setDevice] = useState(getDevice());
    const [inbound, setInbound] = useState(getInbound());

    /**
     * Synchronize window events against the hook state.
     */
    useEffect(() => {
        // Initialize the device if we don't have one.
        if (!getDevice()) {
            initDevice();
        }

        window.addEventListener(
            'phonedevicechange',
            handleUpdate
        );

        window.addEventListener(
            'phoneinboundchange',
            handleInboundUpdate
        );

        return () => {
            window.removeEventListener(
                'phonedevicechange',
                handleUpdate
            )

            window.addEventListener(
                'phoneinboundchange',
                handleInboundUpdate
            );
        }
    }, []);


    /**
     * Processes the state update for the inbound call.
     *
     * @param event
     */
    const handleInboundUpdate = event => {
        Settings.activeInbound = event.detail;
        setInbound(getInbound());
    };


    /**
     * Processes the state update for the call.
     */
    const handleUpdate = event => {
        Settings.activeDevice = event.detail;
        setDevice(getDevice());
    };


    /**
     * Initialize the phone device.
     *
     * @returns {Promise<void>}
     */
    const initDevice = async () => {
        const response = await API.post('auth/twilio');
        Logger.debug(`[usePhoneDevice] Voice token response:`, response);

        // Validate that we received an authentication token.
        if (!response || !response.token) {
            return;
        }

        const localDevice = new Device(response.token, {
            edge: 'ashburn',
            fakeLocalDTMF: true,
            closeProtection: 'A call is currently in progress, are you sure you\'d like to cancel the call?',
            codecPreferences: ['opus', 'pcmu'],
            enableRingingState: true
        });

        localDevice.on('error', error => {
            Logger.debug('[usePhoneDevice] Twilio.Device Error:', error.message);
        });

        localDevice.on('registered', device => {
            Logger.debug('[usePhoneDevice] The device is ready to receive incoming calls.')
        });

        localDevice.on('incoming', call => {
            Logger.debug('[usePhoneDevice] New inbound call:', call);

            call.on('accept', call => {
                setInbound(null);
                Logger.debug('[usePhoneDevice] Accepted call:', call);
                window.dispatchEvent(new CustomEvent('phonecallchange', {detail: Settings.activeCall}));
                window.dispatchEvent(new CustomEvent('phoneinboundchange', {detail: null}));
            });

            call.on('mute', (isMuted, call) => {
                Logger.debug('[usePhoneDevice] Muted call:', call);
                window.dispatchEvent(new CustomEvent('phonecallchange', {detail: {...Settings.activeCall, isMuted: call.isMuted()}}));
            });

            call.on('reject', () => {
                Logger.debug('[usePhoneDevice] Rejected call:', call);
                setInbound(null);

                // Disconnect if this is the call we're currently connected to.
                if (Formatter.e164(Settings.activeCall.phoneNumber) === Formatter.e164(call.parameters.From)) {
                    window.dispatchEvent(new CustomEvent('phonecallchange', {detail: {}}));
                }

                window.dispatchEvent(new CustomEvent('phoneinboundchange', {detail: null}));
            });

            call.on('cancel', () => {
                Logger.debug('[usePhoneDevice] Cancelled call:', call);
                setInbound(null);

                window.dispatchEvent(new CustomEvent('phonecallchange', {detail: {}}));
                window.dispatchEvent(new CustomEvent('phoneinboundchange', {detail: null}));
            });

            call.on('disconnect', () => {
                Logger.debug('[usePhoneDevice] Disconnected call:', call);
                setInbound(null);

                window.dispatchEvent(new CustomEvent('phonecallchange', {detail: {}}));
                window.dispatchEvent(new CustomEvent('phoneinboundchange', {detail: null}));
            });

            setInbound(call);
            window.dispatchEvent(new CustomEvent('phoneinboundchange', {detail: call}));
        });

        localDevice.on('tokenWillExpire', async () => {
            Logger.debug('[usePhoneDevice] Refreshing access token...');
            const response = await API.post('auth/twilio');
            localDevice.updateToken(response.token);

            // Broadcast the signal.
            window.dispatchEvent(new CustomEvent('phonedevicechange', {detail: localDevice}));
        });

        localDevice.register();
        window.dispatchEvent(new CustomEvent('phonedevicechange', {detail: localDevice}));
        setDevice(localDevice);
    };

    return {
        device,
        inbound,
    };
};

export default usePhoneDevice