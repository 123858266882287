import React from "react";

import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import CreateIcon from '@mui/icons-material/Create';

/**
 * DetailWrapper component.
 *
 * @param props
 * @constructor
 */
const DetailWrapper = (props) => {
    const {
        label,          // {String} The label of the property.
        value,          // {String} The output of the record's property.
        delay,          // {Number} An optional delay amount for animations.
        onEdit,         // {Function} An optional edit mechanism.
    } = props;

    return (
        <Box className={'d-flex__justify'}>
            <Box>
                <b>{label}</b>
                <Box>{value}</Box>
            </Box>

            {onEdit && (
                <IconButton>
                    <CreateIcon/>
                </IconButton>
            )}
        </Box>
    );
};

export default DetailWrapper;