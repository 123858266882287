import React, {useState} from "react";

import API from "../../Global/API";
import Specialty from "../../Models/Specialty";
import TextEditor from "../../Components/Input/TextEditor";
import DialogHeading from "../../Components/Typography/DialogHeading";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import JobDescriptionStep from "../../Pages/Employee/HirePacket/JobDescriptionStep";

/**
 * SpecialtyForm component.
 *
 * @returns {*}
 * @constructor
 */
const SpecialtyForm = (props) => {
    const {
        open,           // {Boolean} Indicates whether the form is open.
        record,         // {Object} The form context object, handles pre-loading the form.
        onSave,         // {Function} An onSave callback, triggered whenever the form is saved.,
        onClose,        // {Function} An onClose callback, triggered whenever the modal is closed.
    } = props;

    const [fields, setFields] = useState(Specialty.getInstance(record));
    const [isLoading, setLoading] = useState(false);
    const [isPreviewing, setPreview] = useState(false);

    /**
     * Performs the save via the API.
     */
    const doSave = async () => {
        setLoading(true);

        const response = fields.id ?
            await API.put(`specialty/${fields.id}`, fields) :
            await API.post('specialties', fields);

        setLoading(false);

        if (onSave) {
            onSave(response);
        }
    };


    /**
     * Opens the preview modal.
     */
    const handlePreviewOpen = () => {
        setPreview(true);
    }


    /**
     * Closes the preview modal.
     */
    const handlePreviewClose = () => {
        setPreview(false);
    }


    /**
     * Updates a particular form value.
     *
     * @param key
     * @param value
     */
    const setValue = (key, value) => {
        setFields({
            ...fields,
            [key]: value
        });
    };

    return (
        <>
            <Dialog
                open={open}
                scroll={'body'}
                onClose={onClose}
                maxWidth={'sm'}
                fullWidth
            >
                <DialogHeading title={'Specialty Details'}/>
                <DialogContent>
                    <Box className={'columns__1'}>
                        <TextField
                            required
                            disabled={isLoading}
                            onChange={event => setValue('name', event.target.value)}
                            value={fields.name}
                            label="Name"
                        />

                        <Alert severity={'info'}>
                            Please enter a job description below. This value will be prompted to employees during
                            their hire packet. <a onClick={handlePreviewOpen}>Click to preview.</a>
                        </Alert>

                        <TextEditor
                            value={fields.jobDescription}
                            onChange={value => setValue('jobDescription', value)}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={doSave}
                    >Save</Button>
                    <Button onClick={onClose} color={'error'}>Close</Button>
                </DialogActions>
            </Dialog>

            {isPreviewing && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handlePreviewClose}
                    maxWidth={'xs'}
                    fullWidth
                >
                    <DialogContent sx={{paddingTop: 0}}>
                       <JobDescriptionStep
                           specialty={fields}
                           readOnly
                       />
                    </DialogContent>
                </Dialog>
            )}
        </>
    );
};

export default SpecialtyForm;