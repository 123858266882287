import React from "react";

import {Link} from "react-router-dom";
import {useMatches} from "react-router";

/**
 * Breadcrumbs component.
 *
 * @returns {*}
 * @constructor
 */
const Breadcrumbs = () => {
    let matches = useMatches();
    let crumbs = matches
        .filter((match) => Boolean(match.handle?.crumb))
        .map((match) => match.handle.crumb(match.data));

    return crumbs.length ? (
        <div className={'breadcrumbs'}>
            <div className={'crumb'}>
                <Link to={'/'}>Home</Link>
            </div>
            <div className={'divider'}/>
            {crumbs.map((crumb, index) => (
                <div className={'crumb text__bold'} key={index}>{crumb}</div>
            ))}
        </div>
    ) : null;
};

export default Breadcrumbs;