import React, {useEffect, useState} from "react";

import Step from "../../../Components/Step";
import {useAuth} from "../../../Global/Auth";
import LogoHeading from "../../../Components/Typography/LogoHeading";
import StepControls from "../../../Components/StepControls";
import InputSignature from "../../../Components/Input/InputSignature";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

/**
 * AttendancePolicyStep component.
 *
 * @returns {*}
 * @constructor
 */
const AttendancePolicyStep = (props) => {
    const {
        step,                   // {Number} The current step for the user.
        index,                  // {Number} The expected step number.
        onClose,                // {Function} An optional method for closing the form if it appears in a dialog.
        readOnly,               // {Boolean} Whether the form is disabled.
        employee,               // {Object} The employee object for read only context.
        disabled,               // {Boolean} Whether the step is disabled from input.
        contentOnly,            // {Boolean} Whether to only render the content.
        onStepChange            // {Function} The on change callback to load the next step.
    } = props;

    const [isLoaded, setLoaded] = useState(false);
    const [signature, setSignature] = useState('');
    const {setUserSetting, getUserSettingValue, getUserSettingValueForEmployee} = useAuth();

    /**
     * Loads the required settings for this step.
     */
    useEffect(() => {
        handleSettingsLoad();
    }, []);


    /**
     * Synchronize the job description signature.
     */
    useEffect(() => {
        if(!readOnly && isLoaded){
            setUserSetting('HP_ATTENDANCE_POLICY', signature)
        }
    }, [signature]);


    /**
     * Loads each of the appropriate user settings.
     *
     * @returns {Promise<void>}
     */
    const handleSettingsLoad = async () => {
        setSignature(
            readOnly ?
                await getUserSettingValueForEmployee(employee.id, 'HP_ATTENDANCE_POLICY') :
                await getUserSettingValue('HP_ATTENDANCE_POLICY')
        );

        setLoaded(true);
    };


    /**
     * The disabled state of the form.
     */
    const isDisabled = readOnly || disabled;

    return (
        <Step step={index} value={step}>
            <LogoHeading
                title={'Attendance Policy'}
                onClose={onClose}
            />

            <Box className={'columns__1'}>
                <Box>
                    Reliant Staffing employees are expected to be present for work and on time for every shift
                    that they committed to working. Reliant’s name suggest that the company’s employees are
                    reliable, trustworthy and committed. Arriving late, being tardy, or absence from work
                    jeopardizes Reliant’s reputation with the facilities we do business with. Please honor your
                    commitments that you made when you self- scheduled.
                </Box>
                <Box>
                    <b>Calculation of attendance infractions</b>
                </Box>
                <TableContainer className={'table table--striped'}>
                    <Table>
                        <colgroup>
                            <col width={'60%'}/>
                            <col width={'40%'}/>
                        </colgroup>
                        <TableBody>
                            {[
                                {
                                    type: 'Unexcused absence with call',
                                    point: '2 points'
                                }, {
                                    type: 'Unexcused absence with no call',
                                    point: '4 points'
                                }, {
                                    type: 'Holiday call off',
                                    point: '4 points'
                                }, {
                                    type: 'Late (greater than 15 minutes from start time)',
                                    point: '1 point'
                                }, {
                                    type: 'Unexcused early departure',
                                    point: '1 point'
                                }, {
                                    type: 'Unexcused absence with call',
                                    point: '2 point'
                                },
                            ].map((row, i) => (
                                <TableRow key={i}>
                                    <TableCell>{row.type}</TableCell>
                                    <TableCell align={'right'}>{row.point}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box>
                    Unexcused absence points are reduced by 2 point for every week the employee does not have an
                    unexcused absence, late arrival or early departure.
                </Box>
                <Box>
                    <b>Overview of disciplinary action for attendance infractions.</b>
                </Box>
                <Box>
                    Attendance issues will result in progressive disciplinary action up to and including
                    termination based on the following point system:
                </Box>
                <TableContainer className={'table table--striped'}>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell>
                                    6 points: Verbal warning
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    9 points: Written warning
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    12 points: Termination
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box>
                    Excused, absences are without disciplinary action.
                </Box>
                <Box>
                    Excused absences can be granted for funerals, jury duty, bereavement, childbirth, a car
                    accident, medical appointments, and unfavorable driving (weather) conditions. In these
                    cases, employees will be contacted by email with the documentation needed and employees must
                    provide the requested documentation within 24hrs or the absences will not be excused.
                </Box>

                {!contentOnly && (
                    <>
                        <InputSignature
                            key={1}
                            value={signature}
                            label={'Please sign here:'}
                            disabled={isDisabled}
                            onChange={setSignature}
                            scaleFactor={2}
                        />

                        {!readOnly && (
                            <StepControls
                                step={step}
                                disabled={isDisabled || !signature}
                                onStepChange={onStepChange}
                            />
                        )}
                    </>
                )}
            </Box>
        </Step>
    );
};

export default AttendancePolicyStep;