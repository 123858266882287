import React from "react";

import {NumericFormat} from "react-number-format";

/**
 * PercentageFormatter component.
 *
 * @constructor
 */
const PercentageFormatter = React.forwardRef(function PercentageFormatter(
    props,
    ref,
) {
    const { onChange, value, ...other } = props;

    return (
        <NumericFormat
            {...other}

            value={parseFloat(value || '') * 100}
            suffix={'%'}
            getInputRef={ref}
            decimalScale={2}
            onValueChange={(values) => {
                onChange({
                    target: {
                        value: (values.floatValue || 0.00) / 100,
                    },
                });
            }}
            thousandSeparator
            valueIsNumericString
        />
    );
});

export default PercentageFormatter;