import React, {useEffect, useState} from "react";

import App from "../../Global/App";
import API from "../../Global/API";
import Client from "../../Models/Client";
import InputPhone from "../../Components/Input/InputPhone";
import ContactInput from "./ContactInput";
import DialogHeading from "../../Components/Typography/DialogHeading";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

/**
 * ClientForm component.
 *
 * @returns {*}
 * @constructor
 */
const ClientForm = (props) => {
    const {
        open,           // {Boolean} Indicates whether or not the form is currently opened.
        record,         // {Object} The associated database record for the form.
        onSave,         // {Function} The post-save callback for reloading layouts, tables, etc.
        onClose,        // {Function} The modal close callback to fire once the user exits the form.
    } = props;

    const [error, setError] = useState('');
    const [states, setStates] = useState([]);
    const [fields, setFields] = useState(Client.getInstance(record));
    const [isLoading, setLoading] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);
    const [helixRegions, setHelixRegions] = useState([]);

    /**
     * Indicates whether we have an active error message.
     *
     * @type {boolean}
     */
    const isError = !!error;


    /**
     * Load any additional details on mount.
     */
    useEffect(() => {
        getStates();
        getHelixRegions();
    }, []);


    /**
     * Synchronize the prop fields.
     */
    useEffect(() => {
        setFields(Client.getInstance(record));
    }, [record]);


    /**
     * Performs the save via the API.
     */
    const doSave = async () => {
        setSubmitted(true);

        // Validate required fields.
        if(
            !fields.name ||
            !fields.phoneNumber
        ){
            return setError('Missing one or more required fields.');
        }

        setLoading(true);

        const payload = {
            ...fields
        };

        const contacts = fields.contacts || [];

        // Remove relations that might interfere with the backend.
        if(payload.hasOwnProperty('contacts')){
            delete payload['contacts'];
        }

        const response = fields.id ?
            await API.put(`client/${fields.id}`, payload) :
            await API.post('clients', payload);

        // Handle error responses from the backend.
        if(response.status && response.status === 'error'){
            setLoading(false);
            return setError(response.message);
        }

        // Handle each of our related contacts.
        await Promise.all(
            contacts.map(async contact => {
                if(!contact.id && contact.isDeleted){
                    return;
                }

                return contact.id ?
                    await API.put(`contact/${contact.id}`, {
                        ...contact,
                        clientId: response.id
                    }) :
                    await API.post('contacts', {
                        ...contact,
                        clientId: response.id
                    });
            })
        );

        setLoading(false);

        if (onSave) {
            onSave(response);
        }
    };


    /**
     * Updates a particular form value.
     *
     * @param key
     * @param value
     */
    const setValue = (key, value) => {
        setFields({
            ...fields,
            [key]: value
        });
    };


    /**
     * Loads all available states from the API.
     *
     * @returns {Promise<void>}
     */
    const getStates = async () => {
        setStates(
            await API.get('states')
        );
    };


    /**
     * Loads all available Helix regions from the API.
     *
     * @returns {Promise<void>}
     */
    const getHelixRegions = async () => {
        if(!App.isModuleEnabled('trueHelix')) {
            return;
        }

        setHelixRegions(
            await API.get('helix-regions')
        );
    };

    return (
        <>
            <Dialog
                open={open}
                scroll={'body'}
                onClose={onClose}
                maxWidth={'lg'}
                fullWidth
            >
                <DialogHeading
                    title={'Client Details'}
                    noMargin
                />
                <DialogContent>
                    <Box className={'columns__1'}>
                        <InputLabel
                            children={'Contact Information'}
                            className={'form__heading'}
                        />
                        <Box className={'columns__2'}>
                            <TextField
                                label={'Name'}
                                value={fields.name}
                                error={isSubmitted && !fields.name}
                                required
                                disabled={isLoading}
                                onChange={event => setValue('name', event.target.value)}
                            />
                            <TextField
                                label={'Email Address'}
                                value={fields.emailAddress}
                                disabled={isLoading}
                                onChange={event => setValue('emailAddress', event.target.value)}
                            />
                        </Box>
                        <Box className={'columns__3'}>
                            <InputPhone
                                label={'Phone Number'}
                                value={fields.phoneNumber}
                                error={isSubmitted && !fields.phoneNumber}
                                required
                                disabled={isLoading}
                                onChange={event => setValue('phoneNumber', event.target.value)}
                            />
                            <InputPhone
                                label={'Attendant Phone #'}
                                value={fields.afterHoursPhone}
                                disabled={isLoading}
                                onChange={event => setValue('afterHoursPhone', event.target.value)}
                            />
                            <TextField
                                label={'Notification Email'}
                                value={fields.altEmailAddress}
                                disabled={isLoading}
                                placeholder={'For call-offs, late arrivals, etc.'}
                                onChange={event => setValue('altEmailAddress', event.target.value)}
                            />
                            <TextField
                                label={'Type'}
                                value={fields.type}
                                disabled={isLoading}
                                onChange={event => setValue('type', event.target.value)}
                            />
                            {App.isModuleEnabled('trueHelix') && (
                                <FormControl>
                                    <InputLabel>True-Helix Region</InputLabel>
                                    <Select
                                        label={'True-Helix Region'}
                                        value={fields.helixRegionId}
                                        disabled={isLoading}
                                        onChange={event => setValue('helixRegionId', event.target.value)}
                                        children={
                                            helixRegions.map(region =>
                                                <MenuItem
                                                    key={region.id}
                                                    value={region.helixId}
                                                    children={region.name}
                                                />
                                            )
                                        }
                                    />
                                </FormControl>
                            )}
                        </Box>
                    </Box>
                </DialogContent>
                <DialogContent>
                    <Box className={'columns__1'}>
                        <InputLabel
                            children={'Location Information'}
                            className={'form__heading'}
                        />
                        <Box className={'columns__2'}>
                            <TextField
                                label={'Address Line 1'}
                                value={fields.addressLine1}
                                disabled={isLoading}
                                onChange={event => setValue('addressLine1', event.target.value)}
                            />
                            <TextField
                                label={'Address Line 2'}
                                value={fields.addressLine2}
                                disabled={isLoading}
                                onChange={event => setValue('addressLine2', event.target.value)}
                            />
                        </Box>
                        <Box className={'columns__3'}>
                            <TextField
                                label={'City'}
                                value={fields.city}
                                disabled={isLoading}
                                onChange={event => setValue('city', event.target.value)}
                            />
                            <FormControl>
                                <InputLabel>State</InputLabel>
                                <Select
                                    label={'State'}
                                    value={fields.stateId}
                                    disabled={isLoading}
                                    onChange={event => setValue('stateId', event.target.value)}
                                    children={
                                        states.map(state =>
                                            <MenuItem
                                                key={state.id}
                                                value={state.id}
                                                children={state.name}
                                            />
                                        )
                                    }
                                />
                            </FormControl>
                            <TextField
                                label={'Postal Code'}
                                value={fields.postalCode}
                                disabled={isLoading}
                                onChange={event => setValue('postalCode', event.target.value)}
                            />
                        </Box>
                    </Box>
                </DialogContent>
                <DialogContent>
                    <ContactInput
                        record={record}
                        onChange={contacts => setValue('contacts', contacts)}
                    />
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={doSave}
                        children={'Save'}
                        disabled={isLoading}
                    />
                    <Button
                        color={'error'}
                        onClick={onClose}
                        children={'Close'}
                        disabled={isLoading}
                    />
                </DialogActions>
            </Dialog>

            <Snackbar open={isError} autoHideDuration={6000} onClose={() => setError('')}>
                <Alert onClose={() => setError('')} severity={'error'}>
                    {error}
                </Alert>
            </Snackbar>
        </>
    );
};

export default ClientForm;