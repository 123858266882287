import React from "react";

import Chip from "@mui/material/Chip";

/**
 * ClientStatusChip component.
 *
 * @constructor
 */
const ClientStatusChip = (props) => {
    const {
        size,       // {String} A size modifier for the chip.
        record      // {Object} The client record to display the status of.
    } = props;

    /**
     * The appropriate size attribute for the chip.
     *
     * @type {String}
     */
    const chipSize = size || 'small';

    return record.status && record.status.name ? (
        <Chip label={record.status.name} size={chipSize} sx={{backgroundColor: record.status.color, color: '#fff'}}/>
    ) : (
        !record.isDeleted ?
            <Chip label="Active" size={chipSize} color={'success'}/> :
            <Chip label="Inactive" size={chipSize}/>
    )
};

export default ClientStatusChip;