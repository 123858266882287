import React, {useRef} from "react";

import Settings from "../../Global/Settings";

import {Editor} from '@tinymce/tinymce-react';

/**
 * TextEditor component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const TextEditor = (props) => {
    const {
        value,          // {String} The initial value for the editor.
        height,         // {Number} An optional height to pass along to the editor.
        onChange,       // {Function} A change callback, fired whenever the user modified the content.
        disabled        // {Boolean} A disabled flag for the editor.
    } = props;

    /**
     * The reference to the editor's HTML element.
     *
     * @type {React.MutableRefObject<null> | React.RefObject<any> | React.MutableRefObject<undefined>}
     */
    const editorRef = useRef(null);

    return (
        <Editor
            value={value}
            apiKey={Settings.tinyMceApiKey}
            onInit={(event, editor) => editorRef.current = editor}
            disabled={disabled}
            onEditorChange={onChange}
            init={{
                height: height || 400,
                menubar: false,
                plugins: [
                    'code'
                ],
                toolbar: 'undo redo | formatselect | ' +
                    'bold italic backcolor link | alignleft aligncenter ' +
                    'alignright alignjustify | bullist numlist outdent indent | ' +
                    'removeformat | code',
                content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
            }}
        />
    )
};

export default TextEditor;