import React from "react";

import EmployeeShiftActions from "../../Actions/EmployeeShiftActions";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";
import AccessTimeIcon from '@mui/icons-material/AccessTime';

/**
 * EmployeeShiftListItem component.
 *
 * @param props
 * @constructor
 */
const EmployeeShiftListItem = (props) => {
    const {
        record,         // {Object} The shift record (with client included).
        preview,        // {Boolean} An optional preview flag. If enabled, will use a more condensed format.
        className,      // {String} An optional class to include around the outer container.
    } = props;

    const hasAttribute = record.isEmployeeCancelled || record.isEmployeeNoCall || record.isEmployeeLate || record.isSelfScheduled;
    const endDateFormatted = dayjs(record.endDate).format('h:mma');
    const startTimeFormatted = dayjs(record.startDate).format('h:mma');
    const startDateFormatted = dayjs(record.startDate).format('dddd, MMMM D, YYYY');

    return (
        <Box className={`${className || ''} d-flex__justify`}>
            <Box className={'d-flex__justify flex__grow employee__shift'}>
                <Box>
                    <Box>
                        {preview ? (
                            <b className={'d-inline__block m__0'}>
                                {record.client && record.client.name ? record.client.name : '(no client)'}
                            </b>
                        ) : (
                            <h3 className={'d-inline__block m__0'}>
                                {record.client && record.client.name ? record.client.name : '(no client)'}
                            </h3>
                        )}
                    </Box>

                    <Box className={'text__small text__light'}>
                        {startDateFormatted}

                        <Box className={'d-flex__start'}>
                            <AccessTimeIcon fontSize={'small'} sx={{marginRight: '0.2em', fontSize: '1em'}}/>
                            {startTimeFormatted} - {endDateFormatted}
                        </Box>
                    </Box>
                </Box>

                {!!hasAttribute && (
                    <Box align={'right'} className={'employee-shift__attributes'}>
                        {!!record.isSelfScheduled && (
                            <Chip
                                size={'small'}
                                color={'primary'}
                                label={'Self-Scheduled'}
                                className={'ml__1'}
                            />
                        )}

                        {!!record.isEmployeeCancelled && (
                            <Chip
                                size={'small'}
                                color={'error'}
                                label={'Called Off'}
                                className={'ml__1'}
                            />
                        )}

                        {!!record.isEmployeeNoCall && (
                            <Chip
                                size={'small'}
                                color={'error'}
                                label={'Never Arrived'}
                                className={'ml__1'}
                            />
                        )}

                        {!!record.isEmployeeLate && (
                            <Chip
                                size={'small'}
                                color={'warning'}
                                label={'Arrived Late'}
                                className={'ml__1'}
                            />
                        )}
                    </Box>
                )}
            </Box>

            {preview && (
                <EmployeeShiftActions record={record}/>
            )}
        </Box>
    )
};

export default EmployeeShiftListItem;