import React, {useRef, useState} from "react";

import API from "../../../Global/API";
import Employee from "../../../Models/Employee";
import Formatter from "../../../Global/Formatter";
import ModelSearch from "../../../Components/Input/ModelSearch";
import ModalButton from "../../../Components/ModalButton";
import EmployeeView from "../Employee/EmployeeView";
import SectionHeading from "../../../Components/Typography/SectionHeading";
import EmployeeListItem from "../../../Components/Lists/EmployeeListItem";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import {DateTimePicker} from "@mui/x-date-pickers";

/**
 * The number of results or page to sequentially pull data.
 *
 * @type {number}
 */
const perPageLimit = 250;


/**
 * TimeSheetReport component.
 *
 * @returns {*}
 * @constructor
 */
const TimeSheetReport = (props) => {
    const {
        loading,            // {Boolean} Indicates if the report is loading.
        onComplete          // {Function} A post-completion callback from whenever the report has results.
    } = props;

    const resultsRef = useRef(null);
    const [index, setIndex] = useState(0);
    const [endDate, setEndDate] = useState(dayjs().endOf('week').format('YYYY-MM-DD 10:00:00'));
    const [isLoading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [startDate, setStartDate] = useState(dayjs().startOf('week').format('YYYY-MM-DD 10:00:00'))
    const [showResults, setShowResults] = useState(false);
    const [employeeResults, setEmployeeResults] = useState([]);

    /**
     * Loads all of our employee rows.
     *
     * @returns {Promise<void>}
     */
    const getAllEmployees = async () => {
        let promises = [];

        // Determine the filter criteria.
        let timeSheetFilters = [`isReviewed eq {1}`];
        timeSheetFilters.push(endDate ? `effectiveDate le {${endDate}}` : ``);
        timeSheetFilters.push(startDate ? `effectiveDate ge {${startDate}}` : ``);
        timeSheetFilters = timeSheetFilters.filter(filter => !!filter.trim());

        // Filter specific employees, if applicable.
        let employeeSelection = employees && employees.length ? ` and id in {${employees.map(employee => employee.id).join(',')}}` : '';

        // Load all the employees page by page until we've reached the end.
        const count = await API.get('employees/count', {
            $top: perPageLimit,
            $filter: `timeSheets/any{${timeSheetFilters.join(' and ')}}${employeeSelection}`,
        });

        for (let page = 0; page < count.pages; page++) {
            promises.push(
                API.get('employees', {
                    $top: perPageLimit,
                    $skip: page * perPageLimit,
                    endDate,
                    $filter: `timeSheets/any{${timeSheetFilters.join(' and ')}}${employeeSelection}`,
                    $select: '*,approvedTimeSheets',
                    $expand: 'specialty,state,status',
                    $orderby: `lastName asc, firstName asc`,
                    startDate
                })
            );
        }

        const results = await Promise.all(promises);
        setEmployeeResults(results.flat());
    };


    /**
     * Loads all of our report data.
     *
     * @returns {Promise<void>}
     */
    const getResults = async () => {
        setLoading(true);

        await Promise.all([
            getAllEmployees(),
        ]);

        setShowResults(true);
        setLoading(false);
        setIndex(index + 1);

        if(onComplete){
            onComplete(resultsRef);
        }
    };


    /**
     * Indicates if the report is in progress.
     *
     * @type {boolean}
     */
    const inProgress = isLoading || loading;


    /**
     * The total number of entries.
     *
     * @type {*}
     */
    const totalCount = employeeResults.reduce((total, employee) => total + (employee.approvedTimeSheets || []).length, 0);

    return (
        <Box className={'d-flex'}>
            <Box
                sx={{width: 400, overflowY: 'hidden'}}
                className={'border__right p__3 full__height--left-tabs'}
            >
                <Box className={'columns__1'}>
                    <DateTimePicker
                        label="Start Date"
                        value={startDate ? dayjs(startDate) : null}
                        disabled={inProgress}
                        onChange={event => setStartDate(event ? event.format('YYYY-MM-DD HH:mm:ss') : '')}
                        fullWidth
                    />
                    <DateTimePicker
                        label="End Date"
                        value={endDate ? dayjs(endDate) : null}
                        disabled={inProgress}
                        onChange={event => setEndDate(event ? event.format('YYYY-MM-DD HH:mm:ss') : '')}
                        fullWidth
                    />
                    <ModelSearch
                        model={Employee}
                        value={employees}
                        label={'Employee(s)'}
                        multiple
                        disabled={inProgress}
                        onChange={setEmployees}
                        helperText={'(Leave blank to include all.)'}
                        renderLabel={option => !option ? '' : option.displayName}
                    />

                    <Button
                        variant={'outlined'}
                        onClick={getResults}
                        children={'Apply'}
                        disabled={inProgress}
                    />
                </Box>
            </Box>

            <Box
                sx={{borderRadius: 0}}
                className={`flex__grow full__height--left-tabs ${!showResults ? 'well__container' : ''}`}
            >
                {inProgress && <LinearProgress sx={{width: '100%'}}/>}

                {!showResults && (
                    <Box className={'p__3 text__center text__disclaimer'}>Your results will display here.</Box>
                )}

                {showResults && (
                    <Box className={'p__3 columns__1'}>
                        <Box>
                            <SectionHeading title={`Time Sheets (${totalCount})`} primary/>
                            <TableContainer className={'table table--striped result__set'}>
                                <Table size={'small'}>
                                    <colgroup>
                                        <col width={'25%'}/>
                                        <col width={'75%'}/>
                                    </colgroup>
                                    <TableBody>
                                        {employeeResults.map(employee => {
                                            const timeSheets = employee.approvedTimeSheets || [];

                                            if(!timeSheets || !timeSheets.length) {
                                                return null;
                                            }

                                            return (
                                                <TableRow key={employee.id}>
                                                    <TableCell className={'v-align__top'} padding={'none'}>
                                                        <ModalButton
                                                            label={
                                                                <EmployeeListItem
                                                                    onSelect={() => true}
                                                                    employee={employee}
                                                                />
                                                            }
                                                            bodyStyle={{
                                                                paddingTop: 0
                                                            }}
                                                            children={
                                                                <EmployeeView id={employee.id}/>
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell padding={'none'}>
                                                        <Table>
                                                            <colgroup>
                                                                <col width={'33%'}/>
                                                                <col width={'33%'}/>
                                                                <col width={'33%'}/>
                                                            </colgroup>
                                                            <TableBody>
                                                                {timeSheets.map(timeSheet => (
                                                                    <TableRow key={timeSheet.id}>
                                                                        <TableCell sx={{padding: '0.5em', background: 'none!important'}}>{Formatter.dateTime(timeSheet.effectiveDate, '', false)}</TableCell>
                                                                        <TableCell sx={{padding: '0.5em', background: 'none!important'}}>{timeSheet.hours}</TableCell>
                                                                        <TableCell sx={{padding: '0.5em', background: 'none!important'}}>
                                                                            <Box className={'d-flex__start'}>
                                                                                {timeSheet.clientName ?
                                                                                    <>{timeSheet.clientName}</> :
                                                                                    <span className={'text__light'}>(no client)</span>
                                                                                }

                                                                                {!!timeSheet.notes && (
                                                                                    <ModalButton
                                                                                        size={'sm'}
                                                                                        label={
                                                                                            <IconButton size={'small'} className={'ml__1'}>
                                                                                                <TextSnippetIcon fontSize={'small'}/>
                                                                                            </IconButton>
                                                                                        }
                                                                                        children={
                                                                                            <Box className={'columns__1'}>
                                                                                                <SectionHeading
                                                                                                    title={`Time Sheet Notes`}
                                                                                                    primary
                                                                                                />
                                                                                                <Box>{timeSheet.notes}</Box>
                                                                                            </Box>
                                                                                        }
                                                                                    />
                                                                                )}
                                                                            </Box>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                )}
            </Box>

            {/*The hidden display for PDF rendering.*/}
            <Box className={'d-hidden'}>
                <Box ref={resultsRef} key={`results-${index}`}>
                    <table className={'table--bordered'}>
                        <tbody>
                            <tr>
                                <td style={{width: '20%'}}>
                                    <b>Employee</b>
                                </td>
                                <td style={{width: '18%'}}>
                                    <b>Date</b>
                                </td>
                                <td style={{width: '8%'}}>
                                    <b>Hours</b>
                                </td>
                                <td style={{width: '20%'}}>
                                    <b>Client</b>
                                </td>
                                <td>
                                    <b>Notes</b>
                                </td>
                            </tr>

                            {employeeResults.map(employee => {
                                const approvedTimeSheets = employee.approvedTimeSheets || [];

                                if(!approvedTimeSheets || !approvedTimeSheets.length) {
                                    return null;
                                }

                                return approvedTimeSheets.map((timeSheet, i) => (
                                    <tr key={`${employee.id}-${timeSheet.id}`}>
                                        <td
                                            style={{
                                                border: 'none',
                                                borderTop: !i ? '1px solid #ccc' : 'none',
                                                borderLeft: '1px solid #ccc',
                                                borderBottom: i === approvedTimeSheets.length - 1 ? '1px solid #ccc' : 'none',
                                                verticalAlign: 'top',
                                            }}
                                        >
                                            {!i && (
                                                <>{employee.lastName}, {employee.firstName}</>
                                            )}
                                        </td>
                                        <td>
                                            {Formatter.dateTime(timeSheet.effectiveDate, '', false)}
                                        </td>
                                        <td>
                                            {timeSheet.hours}
                                        </td>
                                        <td>
                                            {timeSheet.clientName}
                                        </td>
                                        <td>
                                            {timeSheet.notes}
                                        </td>
                                    </tr>
                                ));
                            })}
                        </tbody>
                    </table>
                </Box>
            </Box>
        </Box>
    );
};

export default TimeSheetReport;