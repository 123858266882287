import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import Page from "../../Components/Page";
import {useAuth} from "../../Global/Auth";
import Formatter from "../../Global/Formatter";
import ImageWrapper from "../../Components/ImageWrapper";
import CourseCompletionStep from "./Course/CourseCompletionStep";

import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Chip from "@mui/material/Chip";
import Radio from "@mui/material/Radio";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import FormLabel from "@mui/material/FormLabel";
import CheckIcon from '@mui/icons-material/Check';
import RadioGroup from "@mui/material/RadioGroup";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import SchoolIcon from '@mui/icons-material/School';
import FormControl from "@mui/material/FormControl";
import SegmentIcon from '@mui/icons-material/Segment';
import ListItemText from "@mui/material/ListItemText";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
import FormControlLabel from "@mui/material/FormControlLabel";
import {useNavigate, useParams} from "react-router";

/**
 * Course component.
 *
 * @returns {*}
 * @constructor
 */
const Course = () => {
    const {id} = useParams();
    const {user} = useAuth();
    const navigate = useNavigate();
    const [course, setCourse] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [isStarted, setStarted] = useState(false);
    const [examError, setExamError] = useState('');
    const [testAnswers, setTestAnswers] = useState([]);
    const [sectionIndex, setSectionIndex] = useState(0);
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [isCourseComplete, setCourseCompleted] = useState(false);
    const [sectionCompletions, setSectionCompletions] = useState([]);

    /**
     * Indicates whether an anchor target exists for the menu.
     *
     * @type {boolean}
     */
    const menuOpen = Boolean(menuAnchorEl);


    /**
     * Load all component data on mount.
     */
    useEffect(() => {
        setStarted(false);
        setLoading(false);
        setExamError('');
        setTestAnswers([]);
        setSectionIndex(0);
        setCourseCompleted(false);

        getCourse();
        getUserDataForCourse();
    }, [id]);


    /**
     * Scroll to the top on section change.
     */
    useEffect(() => {
        handleScrollToTop();
        getTestAnswersForSection();
    }, [sectionIndex, isStarted]);


    /**
     * All non-deleted course sections.
     */
    const sections = course.sections ? course.sections.filter(section => !section.isDeleted) : [];


    /**
     * Returns the total number of sections.
     *
     * @type {number}
     */
    const totalSections = sections ? sections.length : 0;


    /**
     * The user's current section of the course.
     *
     * @type {{}}
     */
    const currentSection = sections && sections.length && sections.length > sectionIndex ? sections[sectionIndex] : {};


    /**
     * The text content for the "Complete" button at the bottom of each test / section.
     *
     * @type {string}
     */
    const buttonContent = `Complete ${currentSection.isTest ? 'Exam' : 'Section'}`;


    /**
     * All available test questions for the current section.
     */
    const testQuestions = currentSection &&
    currentSection.test &&
    currentSection.test.questions &&
    currentSection.test.questions.length ?
        currentSection.test.questions.filter(question => !question.isDeleted) : [];


    /**
     * Indicates if the user has reached the end of the course.
     *
     * @type {boolean}
     */
    const isAtEnd = sectionIndex >= sections.length;


    /**
     * Returns the index of the first available test.
     *
     * @type {number | never}
     */
    const testSectionIndex = sections.findIndex(section => !!section.isTest);


    /**
     * Scrolls the user to the top of the page.
     */
    const handleScrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };


    /**
     * Loads all user completion data for the course / sections / test / etc.
     *
     * @returns {Promise<void>}
     */
    const getUserDataForCourse = async () => {
        const courseCompletions = await API.get('course-completions', {
            $top: 1,
            $filter: `courseId in {${id}} and employeeId in {${user.id}}`
        });

        if (courseCompletions && courseCompletions.length) {
            setCourseCompleted(true);
        }

        setSectionCompletions(
            await API.get('course-section-completions', {
                $top: 100,
                $filter: `courseSection/any{courseId in {${id}}} and employeeId in {${user.id}}`
            })
        );
    };


    /**
     * Determines if the current section is completed.
     *
     * @param id
     * @returns {boolean}
     */
    const isSectionComplete = (id) => {
        return !!sectionCompletions.filter(sectionCompletion => sectionCompletion.courseSectionId === id).length;
    };


    /**
     * Returns the completion record for the current section.
     *
     * @returns {*}
     */
    const getCurrentSectionCompletion = () => {
        const filtered = sectionCompletions.filter(sectionCompletion => sectionCompletion.courseSectionId === currentSection.id);

        // Verify that we have a completion record.
        if (!filtered || !filtered.length) {
            return {};
        }

        return filtered[0];
    };


    /**
     * Retrieves the course target.
     *
     * @returns {Promise<void>}
     */
    const getCourse = async () => {
        setCourse(
            await API.get(`courses/${id}`, {
                $expand: 'sections($expand=test($expand=questions))'
            })
        );
    };


    /**
     * Fired whenever the menu button is clicked.
     *
     * @param event
     */
    const handleMenuOpen = (event) => {
        setMenuAnchorEl(event.currentTarget);
    };


    /**
     * Closes the menu popover.
     */
    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };


    /**
     * Separates the question by carriage return.
     *
     * @param question
     * @returns {Array}
     */
    const getChoicesFromQuestion = (question) => {
        if (!question || !question.options) {
            return [];
        }

        return question.options
            .split("\n")
            .filter(item => !!item.trim());
    };


    /**
     * Reveals the next section after validating the current section.
     *
     * @returns {Promise<void>}
     */
    const handleNextSection = async () => {
        let currentPercentage = null;

        if (!isSectionComplete(currentSection.id) && currentSection && currentSection.isTest && currentSection.test && currentSection.test.passingPercentage && testQuestions.length) {
            if (hasUnanswered) {
                handleScrollToTop();
                return setExamError('Please answer all of the questions.');
            }

            const correctAnswers = testAnswers.filter(testAnswer => !!testAnswer.isCorrect).length;
            currentPercentage = correctAnswers / testQuestions.length;

            if (currentPercentage < currentSection.test.passingPercentage) {
                handleScrollToTop();
                return setExamError(`This exam requires a score of ${Formatter.number(currentSection.test.passingPercentage * 100)}%, however you only scored ${Formatter.number(currentPercentage * 100)}%. Please correct the issues below and re-submit.`);
            }
        }

        if (!isSectionComplete(currentSection.id)) {
            setLoading(true);

            await API.post('course-section-completion', {
                employeeId: user.id,
                courseSectionId: currentSection.id,
                testPercentage: currentPercentage
            });

            await getUserDataForCourse();
            setLoading(false);
        }

        if (sectionIndex === sections.length - 1 && !isCourseComplete) {
            await API.post('course-completion', {
                courseId: id,
                employeeId: user.id
            });

            setCourseCompleted(true);
        }

        setExamError('');
        setSectionIndex(sectionIndex + 1);
    };


    /**
     * Loads all answers for the current section.
     *
     * @returns {Promise<void>}
     */
    const getTestAnswersForSection = async () => {
        if (!currentSection || !currentSection.test || !currentSection.test.id) {
            return;
        }

        setTestAnswers(
            await API.get('test-question-answers', {
                $top: 500,
                $filter: `testQuestion/any{testId in {${currentSection.test.id}}} and employeeId in {${user.id}}`
            })
        );
    };


    /**
     * Returns the answer value for the provided question.
     *
     * @param question
     * @returns {*}
     */
    const getAnswerForQuestion = (question) => {
        const results = testAnswers.filter(testAnswer => testAnswer.testQuestionId === question.id);

        // Return null if we don't have an answer.
        if (!results || !results.length) {
            return null;
        }

        return results[0].answer;
    };


    /**
     * Indicates if a user's answer to a question is correct.
     *
     * @param question
     * @returns {boolean}
     */
    const isAnswerIncorrect = (question) => {
        const results = testAnswers.filter(testAnswer => testAnswer.testQuestionId === question.id);

        // Return null if we don't have an answer.
        if (!results || !results.length) {
            return false;
        }

        return !results[0].isCorrect;
    };


    /**
     * Answers a particular question.
     *
     * @param question
     * @param answer
     * @returns {Promise<void>}
     */
    const handleQuestionAnswer = async (question, answer) => {
        Promise.all(
            testAnswers
                .filter(testAnswer => testAnswer.testQuestionId === question.id)
                .map(async testAnswer =>
                    await API.delete(`test-question-answers/${testAnswer.id}`)
                )
        );

        // Store the new answer.
        setTestAnswers([
            ...testAnswers.filter(testAnswer => testAnswer.testQuestionId !== question.id),
            ...[
                await API.post('test-question-answers', {
                    answer: answer,
                    isCorrect: answer === question.answer,
                    employeeId: user.id,
                    testQuestionId: question.id
                })
            ]
        ]);

        // Reload all answers.
        getTestAnswersForSection();
    };


    /**
     * Indicates if the user has unanswered questions.
     *
     * @type {boolean}
     */
    const hasUnanswered = currentSection.isTest && testQuestions.length && testQuestions.some(question => !testAnswers.some(answer => answer.testQuestionId === question.id));

    return (
        <Page
            hideHeader
            fullScreen
            children={
                <Box>
                    <Box className={'p__3 border__bottom'} sx={{
                        top: 0,
                        zIndex: 999,
                        position: 'sticky',
                        background: 'white',
                    }}>
                        <Box className={'d-flex__justify'}>
                            <Box className={'d-flex__justify w__100'}>
                                <ImageWrapper
                                    sx={{marginRight: '1em'}}
                                    src={API.getFilePath(course.image)}
                                    icon={<CameraAltIcon fontSize={'large'}/>}
                                    width={64}
                                    height={64}
                                    horizontal
                                />
                                <Box className={'d-flex__grow d-flex__justify'}>
                                    <Box>
                                        <Box className={'d-flex__start'}>
                                            <h3 className={'d-inline__block m__0'}>
                                                {!isStarted || isAtEnd ? course.name : currentSection.name}
                                            </h3>

                                            {isStarted && isSectionComplete(currentSection.id) && (
                                                <CheckIcon color={'success'} className={'ml__1'}/>
                                            )}
                                        </Box>

                                        {(!isStarted || isAtEnd) && (
                                            <Box className={'mt__1'}>
                                                {isCourseComplete || (course.id && sectionCompletions.length === totalSections) ? (
                                                    <Chip size={'small'} color={'success'} label={
                                                        <>{totalSections}/{totalSections} Sections Complete</>
                                                    }/>
                                                ) : (
                                                    <Chip size={'small'} label={
                                                        <>
                                                            {sectionCompletions.length}/{totalSections} Sections
                                                            Complete
                                                        </>
                                                    }/>
                                                )}
                                            </Box>
                                        )}

                                        {isStarted && !isAtEnd && (
                                            <Box className={'text__small'}>
                                                <Box>{course.name}</Box>
                                                <Chip
                                                    color={'info'}
                                                    size={'small'}
                                                    className={'mt__1'}
                                                    label={
                                                        <>
                                                            Section {sectionIndex + 1} of {totalSections}
                                                        </>
                                                    }
                                                />
                                            </Box>
                                        )}
                                    </Box>

                                    {!isStarted && (
                                        <Button
                                            size={'small'}
                                            variant={'outlined'}
                                            onClick={() => navigate('/employee/training')}
                                            children={'Back'}
                                            className={'text__small'}
                                        />
                                    )}
                                </Box>
                            </Box>

                            {isStarted && (
                                <>
                                    <IconButton onClick={handleMenuOpen}>
                                        <SegmentIcon/>
                                    </IconButton>
                                    <Menu
                                        open={menuOpen}
                                        anchorEl={menuAnchorEl}
                                        onClose={handleMenuClose}
                                    >
                                        <MenuItem onClick={() => {
                                            setStarted(false);
                                            setSectionIndex(0);
                                            setMenuAnchorEl(null);
                                        }}>Course Overview</MenuItem>
                                        {sections && !!sections.length && <Divider/>}
                                        {sections.map((section, i) => (
                                            <MenuItem onClick={() => {
                                                setSectionIndex(i);
                                                setMenuAnchorEl(null);
                                            }}>
                                                <ListItemText sx={{minWidth: 200}}>
                                                    {section.name}

                                                    {isCourseComplete || isSectionComplete(section.id) ? (
                                                        <Typography
                                                            className={'text__light text__small'}
                                                            color={'success'}
                                                            children={'Complete'}
                                                        />
                                                    ) : (
                                                        <Typography className={'text__light text__small'}>
                                                            Incomplete
                                                        </Typography>
                                                    )}
                                                </ListItemText>

                                                {(isCourseComplete || isSectionComplete(section.id)) && (
                                                    <CheckIcon color={'success'}/>
                                                )}
                                            </MenuItem>
                                        ))}
                                    </Menu>
                                </>
                            )}
                        </Box>
                    </Box>

                    {!isStarted ? (
                        <Box className={'p__3 pt__0'}>
                            <Box
                                sx={{whiteSpace: 'pre-line'}}
                                dangerouslySetInnerHTML={{__html: course.longDescription || '<p>(no description)</p>'}}
                            />

                            <Box className={'columns__1'}>
                                <Button
                                    variant={'contained'}
                                    onClick={() => setStarted(true)}
                                    disabled={!sections || !sections.length}
                                    children={'Start Course'}
                                />

                                {!!sections && !!sections.length && testSectionIndex > 0 && (
                                    <Button
                                        variant={'outlined'}
                                        onClick={() => {
                                            setStarted(true);
                                            setSectionIndex(testSectionIndex);
                                        }}
                                        disabled={!sections || !sections.length}
                                        children={'Skip to Test'}
                                    />
                                )}

                                <Box/>
                            </Box>
                        </Box>
                    ) : (
                        <>
                            {!isAtEnd ? (
                                <Box className={'p__3'}>
                                    {!isCourseComplete && !sectionIndex && !currentSection.isTest && testSectionIndex > 0 && (
                                        <Alert>
                                            If you feel like you already know this course content, you
                                            can <a onClick={() => setSectionIndex(testSectionIndex)}>click here</a> to
                                            skip ahead to the test.
                                        </Alert>
                                    )}

                                    <Box className={'columns__1'}>
                                        {!currentSection.isTest && (
                                            <Box>
                                                <Box
                                                    sx={{whiteSpace: 'pre-line'}}
                                                    dangerouslySetInnerHTML={{__html: currentSection.content || '<p>(no content)</p>'}}
                                                />
                                            </Box>
                                        )}

                                        {!!currentSection.isTest && (
                                            <>
                                                {examError ? (
                                                    <Alert
                                                        icon={<SchoolIcon fontSize="inherit"/>}
                                                        severity={'error'}
                                                        children={examError}
                                                    />
                                                ) : (
                                                    isSectionComplete(currentSection.id) ? (
                                                        <Alert
                                                            icon={<SchoolIcon fontSize="inherit"/>}
                                                            severity={'success'}
                                                        >
                                                            You successfully completed this exam with a passing score
                                                            of {Formatter.number(getCurrentSectionCompletion().testPercentage * 100)}%!
                                                        </Alert>
                                                    ) : (
                                                        <Alert
                                                            icon={<SchoolIcon fontSize="inherit"/>}
                                                            severity={'info'}
                                                        >
                                                            When you have completed the exam, click the button at the
                                                            bottom of the page to grade the exam. When taking a test to
                                                            complete a course, a score
                                                            of {currentSection.test && currentSection.test.passingPercentage ? (currentSection.test.passingPercentage * 100) : '80'}%
                                                            correct answers is required to attain a certificate of
                                                            completion. Should you fail, you can re-take the exam
                                                            as many times as you like.
                                                        </Alert>
                                                    )
                                                )}
                                            </>
                                        )}

                                        {!!testQuestions.length && (
                                            <>
                                                {testQuestions.map(question => {
                                                    const choices = getChoicesFromQuestion(question);

                                                    return (
                                                        <>
                                                            {choices.length ? (
                                                                <FormControl>
                                                                    <FormLabel
                                                                        error={!!examError && isAnswerIncorrect(question)}
                                                                        children={question.question}
                                                                        className={'text__small mb__2'}
                                                                    />
                                                                    <RadioGroup
                                                                        value={getAnswerForQuestion(question)}
                                                                        onChange={event => handleQuestionAnswer(question, event.target.value)}
                                                                        fullWidth
                                                                    >
                                                                        {choices.map((choice, index) => (
                                                                            <FormControlLabel
                                                                                value={index}
                                                                                label={choice}
                                                                                control={<Radio/>}
                                                                                disabled={isSectionComplete(currentSection.id)}
                                                                            />
                                                                        ))}
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            ) : null}
                                                        </>
                                                    );
                                                })}
                                            </>
                                        )}

                                        <Button
                                            variant={'outlined'}
                                            onClick={handleNextSection}
                                            children={buttonContent}
                                            disabled={isLoading || (currentSection.isTest && hasUnanswered)}
                                        />
                                        <Box/>
                                    </Box>
                                </Box>
                            ) : (
                                <CourseCompletionStep id={id}/>
                            )}
                        </>
                    )}
                </Box>
            }
        />
    );
};

export default Course;