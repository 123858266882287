import React, {useEffect, useRef, useState} from "react";

import App from "../../Global/App";
import API from "../../Global/API";
import DialogHeading from "../Typography/DialogHeading";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import Snackbar from "@mui/material/Snackbar";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FormHelperText from "@mui/material/FormHelperText";

/**
 * InputFile component.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
const InputFile = (props) => {
    const {
        value,              // {String} The file path of an existing file.
        multiple,           // {Boolean} Indicates if we should allow multiple files.
        onChange,           // {Function} An on-change callback event.
        disabled,           // {Boolean} Indicates if we should disable the input.
        uploadPath,         // {String} The base upload destination (typically the root directory of the related object)
        helperText,         // {String} Optional help text to display beneath the input.
    } = props;

    const [path, setPath] = useState(value || '');
    const [isError, setError] = useState(false);
    const [isEditing, setEditing] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [isModified, setModified] = useState(false);
    const [isPreviewing, setPreviewing] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    /**
     * Synchronize the value property for post-load update.
     */
    useEffect(() => {
        if(!!path || !value || path === value){
            return;
        }

        setPath(value);
    }, [value]);


    /**
     * The file input element.
     *
     * @type {React.MutableRefObject<null> | React.RefObject<any> | React.MutableRefObject<undefined>}
     */
    const fileInput = useRef(null);


    /**
     * Processes the image upload.
     */
    const doUpload = () => {
        const file = fileInput.current.files[0];
        setLoading(true);

        API.doFileUpload({
            file: file,
            path: uploadPath,
            types: []
        }, onUploadSuccess, onUploadError);
    };


    /**
     * Handles the file relation back to the root record post-upload.
     *
     * @param path
     * @returns {Promise<void>}
     */
    const onUploadSuccess = async (path) => {
        if (onChange) {
            onChange(path);
        }

        setPath(path);
        setLoading(false);
        setModified(true);
    };


    /**
     * Handles the image error message.
     *
     * @param error
     */
    const onUploadError = (error) => {
        setError(true);
        setSuccess(false);
        setLoading(false);
        setEditing(false);
        setErrorMessage(error);
    };


    /**
     * Closes the status popover.
     */
    const handleStatusClose = () => {
        setError(false);
        setSuccess(false);
    };


    /**
     * Removes the target file.
     */
    const handleDelete = () => {
        setPath('');

        if (onChange) {
            onChange('');
        }
    };


    /**
     * Returns the inner document content.
     *
     * @returns {JSX.Element}
     * @constructor
     */
    const PreviewContent = () => {
        const isImage = (path || '').match(/\.(jpg|jpeg|png|gif)$/i);

        return isImage ? (
            <Box
                sx={App.isCordova() ? {} : {
                    maxHeight: 'calc(100vh - 350px)',
                    overflowY: 'scroll'
                }}
                children={
                    <img
                        src={API.getFilePath(path)}
                        className={'v-align__top'}
                    />
                }
            />
        ) : (
            <Box className={'iframe__container'}>
                <embed
                    src={API.getFilePath(path)}
                    scrolling={'no'}
                    className={'responsive-iframe'}
                />
            </Box>
        );
    };


    /**
     * Reveals the attachment preview.
     */
    const handlePreviewOpen = () => {
        setPreviewing(true);
    };


    /**
     * Closes the attachment preview modal.
     */
    const handlePreviewClose = () => {
        setPreviewing(false);
    };

    return (
        <div>
            {path && (
                <Box className={'well__container p__3 d-flex__justify'}>
                    <Box className={'d-flex__start'}>
                        <AttachFileIcon className={'mr__1'}/>
                        <a
                            onClick={handlePreviewOpen}
                            children={path}
                        />
                    </Box>

                    <IconButton
                        size={'small'}
                        color={'error'}
                        onClick={handleDelete}
                    >
                        <CloseIcon/>
                    </IconButton>
                </Box>
            )}

            <Box className={path ? 'd-hidden' : ''}>
                <input
                    ref={fileInput}
                    type={'file'}
                    onChange={doUpload}
                    disabled={disabled || isLoading}
                />

                {helperText && (
                    <Box>
                        <FormHelperText className={'text__small'}>{helperText}</FormHelperText>
                    </Box>
                )}
            </Box>

            {isError && (
                <Snackbar open={isError} autoHideDuration={6000} onClose={handleStatusClose}>
                    <Alert onClose={handleStatusClose} severity="error" sx={{width: '100%'}}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            )}

            {isSuccess && (
                <Snackbar open={isSuccess} autoHideDuration={6000} onClose={handleStatusClose}>
                    <Alert onClose={handleStatusClose} severity="success" sx={{width: '100%', textAlign: 'left'}}>
                        Your file was uploaded successfully!
                    </Alert>
                </Snackbar>
            )}

            {isPreviewing && (
                <>
                    <Dialog
                        open={true}
                        scroll={'body'}
                        onClose={handlePreviewClose}
                        maxWidth={'md'}
                        fullWidth
                    >
                        <DialogHeading
                            title={"View File"}
                            actions={
                                <IconButton
                                    size={'small'}
                                    onClick={handlePreviewClose}
                                >
                                    <CloseIcon fontSize={'small'}/>
                                </IconButton>
                            }
                            noMargin
                        />
                        <PreviewContent/>
                    </Dialog>
                </>
            )}
        </div>
    );
};

export default InputFile;