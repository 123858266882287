import React from "react";

import Page from "../../Components/Page";
import BenefitsForm from "./Benefits/BenefitsForm";

/**
 * Benefits component.
 *
 * @returns {JSX.Element}
 * @constructor
 *
 * @description Provides an interface for employees to request benefits information.
 */
const Benefits = () => {
    return (
        <Page
            hideHeader
        >
            <BenefitsForm/>
        </Page>
    );
};

export default Benefits;