import React from "react";

import API from "../Global/API";
import Model from "../Definitions/Model";
import Property from "../Definitions/Property";
import Formatter from "../Global/Formatter";
import ClientView from "../Pages/Admin/Client/ClientView";
import ClientForm from "../Actions/ClientActions/ClientForm";
import ModalButton from "../Components/ModalButton";
import PropertyMap from "../Definitions/PropertyMap";
import ImageWrapper from "../Components/ImageWrapper";
import ClientActions from "../Actions/ClientActions";
import ClientStatusChip from "../Pages/Admin/Client/ClientStatusChip";

import {faker} from '@faker-js/faker';
import StoreIcon from '@mui/icons-material/Store';
import Button from "@mui/material/Button";
import Settings from "../Global/Settings";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

/**
 * Client model.
 *
 * @type {Object}
 */
const Client = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'client',


    /**
     * The appropriate API endpoint root.
     */
    route: 'clients',


    /**
     * Any additional query parameters.
     */
    query: {
        $expand: 'state, status'
    },


    /**
     * A series of quick filters to apply to the index layout.
     */
    filters: [
        {
            label: 'Active',
            query: {
                $filter: 'isDeleted eq {0}'
            },
        },{
            label: 'Inactive',
            query: {
                $filter: 'isDeleted eq {1}'
            },
        },{
            label: 'All',
            query: {
                $filter: ''
            },
        }
    ],


    /**
     * Returns the appropriate icon.
     *
     * @param parameters
     * @returns {*}
     */
    icon: (parameters) => <StoreIcon {...parameters}/>,


    /**
     * The form component.
     *
     * @returns {*}
     * @param parameters
     */
    form: (parameters) => <ClientForm {...parameters}/>,


    /**
     * The menu action.
     *
     * @param parameters
     * @returns {*}
     */
    action: (parameters) => <ClientActions {...parameters}/>,


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'image',
        'name',
        'type',
        'emailAddress',
        'phoneNumber',
        'addressLine1',
        'status'
    ],


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            faker: () => faker.datatype.uuid(),
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'image',
            label: 'Image',
            width: '60px',
            faker: () => faker.datatype.uuid(),
            render: (record) => (
                <ImageWrapper
                    src={API.getFilePath(record.image)}
                    icon={Client.icon({fontSize: 'medium'})}
                    width={48}
                    height={48}
                    horizontal
                />
            ),
            hideLabel: true
        }),
        Property.create({
            key: 'name',
            label: 'Facility',
            width: '20%',
            faker: () => faker.company.companyName(),
            render: (record) => (
                <ModalButton
                    label={record.name}
                    component={'a'}
                    bodyStyle={{
                        paddingTop: 0
                    }}
                    children={
                        <ClientView id={record.id}/>
                    }
                />
            ),
            sortable: true
        }),
        Property.create({
            key: 'type',
            label: 'Type',
            width: '17%',
            render: (record) => record.type,
            sortable: false,
            filterable: true,
        }),
        Property.create({
            key: 'emailAddress',
            label: 'Email',
            width: '17%',
            faker: () => faker.internet.email(),
            render: (record) => <a href={`mailto:${record.emailAddress}`}>{record.emailAddress}</a>,
            sortable: true
        }),
        Property.create({
            key: 'phoneNumber',
            label: 'Phone',
            width: '17%',
            faker: () => faker.phone.number(),
            render: (record) => <a href={`tel:${record.phoneNumber}`}>{Formatter.phoneString(record.phoneNumber)}</a>,
            sortable: true
        }),
        Property.create({
            key: 'addressLine1',
            label: 'Address',
            width: '20%',
            render: (record) => Formatter.address(
                record.addressLine1 || '',
                record.addressLine2 || '',
                '',
                record.city || '',
                record.state && record.state.abbreviation ?
                    record.state.abbreviation : '',
                record.postalCode || ''
            ),
            sortable: true,
            filterable: false,
        }),
        Property.create({
            key: 'status',
            align: 'center',
            label: 'Status',
            faker: () => ['DNR', 'Recruiting', 'Active'].random(),
            render: (record) => <ClientStatusChip size={'medium'} record={record}/>,
            sortable: true,
            filterable: true,
        }),
        Property.create({
            key: 'scheduleFromDate',
            label: 'Schedule Period (From)',
            render: (record) => Formatter.date(record.scheduleFromDate, '', false),
            sortable: true
        }),
        Property.create({
            key: 'scheduleToDate',
            label: 'Schedule Period (To)',
            render: (record) => Formatter.date(record.scheduleToDate, '', false),
            sortable: true
        }),
        Property.create({
            key: 'helixId',
            label: 'True Helix',
            align: 'center',
            render: record => {
                if(!record.helixId){
                    return null;
                }

                return (
                    <Button
                        href={`${Settings.helixUrl}/Clients/${record.helixId}`}
                        target={'_blank'}
                        children={'View Record'}
                        component={'a'}
                        className={'white-space__pre'}
                        startIcon={<OpenInNewIcon/>}
                    />
                )
            },
            sortable: false,
        }),
        Property.create({
            key: 'active',
            label: 'Active',
            faker: () => faker.datatype.boolean(),
            render: (record) => record.active ? 'Active' : 'Inactive',
            selectable: false,
        })
    ])
};

export default Client;