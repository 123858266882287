import React from "react";

import Status from "../../Models/Status";
import TableLayout from "../../Components/Layouts/TableLayout";

import WidgetsIcon from '@mui/icons-material/Widgets';

/**
 * Statuses component.
 *
 * @returns {*}
 * @constructor
 */
const Statuses = () => (
    <TableLayout
        icon={<WidgetsIcon/>}
        model={Status}
        heading={'Statuses'}
        condensed
        hideFilters
        formPermission={'EDIT_LIBRARIES'}
    />
);

export default Statuses;