import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import App from "../../../Global/App";
import Logger from "../../../Global/Logger";
import {useAuth} from "../../../Global/Auth";
import ChatMessage from "./ChatMessage";

import Box from "@mui/material/Box";
import {useMediaQuery, useTheme} from "@mui/material";

/**
 * The number of results per page.
 *
 * @type {number}
 */
const perPage = 100;


/**
 * MessageStream component.
 *
 * @returns {*}
 * @constructor
 */
const MessageStream = (props) => {
    const {
        record,                 // {Object} The object to stream messages for.
        hideSpacer,             // {Boolean} Indicates if we should remove the bottom spacer.
        onLoadDone,             // {Function} A callback function, called whenever results are loaded.
        messageType,            // {String} The appropriate message type to filter messages by.
        chatThreadId,           // {String} An optional chat thread ID.
        hasTemplates,           // {Boolean} Indicates if we have templates for the current tab.
    } = props;

    const {isScope} = useAuth();
    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const [isLoaded, setLoaded] = useState(false);
    const [messages, setMessages] = useState([]);
    const [isLoadingNext, setLoadingNext] = useState(false);
    const [hasMoreResults, setHasMoreResults] = useState(true);

    /**
     * Variables for rendering different layouts.
     *
     * @type {Theme}
     */
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    /**
     * Load all required information on component mount.
     */
    useEffect(() => {
        getMessages();
    }, [page, search]);


    /**
     * Loads all available messages.
     *
     * @returns {Promise<void>}
     */
    const getMessages = async () => {
        Logger.debug(`[Schedule] Loading message results for page ${page}...`);

        const results = chatThreadId ? await API.get('messages', {
            $top: perPage,
            $skip: page * perPage,
            $select: '*,recipientRecord,senderRecord',
            $expand: 'template,notes',
            $filter: `chatThread/any{id in {${chatThreadId}}} and type eq {${messageType}}`,
            $orderby: 'createdDate desc',
        }) : [];

        if (!results.length || results.length < perPage) {
            setHasMoreResults(false);
        }

        setMessages([
            ...messages,
            ...results
        ]);

        setLoaded(true);
        setLoadingNext(false);

        if (onLoadDone) {
            onLoadDone();
        }
    };


    /**
     * Refreshes a message when it's updated.
     *
     * @param message
     * @returns {Promise<void>}
     */
    const onMessageUpdate = async (message) => {
        const index = messages.findIndex(m => m.id === message.id);

        if (index === -1) {
            return;
        }

        // Retrieve the latest message data.
        const response = await API.get(`messages/${message.id}`, {
            $select: '*,recipientRecord,senderRecord',
            $expand: 'template,notes',
        });

        // Replace the message within the state.
        const updated = [...messages];
        updated[index] = response;
        setMessages(updated);
    }


    /**
     * Handles data loading on scroll.
     *
     * @param event
     */
    const handleContainerScroll = (event) => {
        const el = event.target;
        const threshold = 600;

        if (isLoadingNext) {
            return;
        }

        if ((el.scrollTop + threshold) <= (el.scrollHeight - el.offsetHeight)) {
            return;
        }

        if (hasMoreResults) {
            setLoadingNext(true);
            setPage(page + 1);
        }
    };


    /**
     * The height of the template selector (in em), if applicable.
     */
    const templateHeight = hasTemplates ? 3.5 : 0;


    /**
     * The bottom content spacer to ensure that all messages are actually visible.
     *
     * @type {React.JSX.Element}
     */
    const bottomSpacer = (
        <>
            {!hideSpacer && (
                <Box
                    sx={{
                        height: messageType === 'Email' ? (
                                !isMobile ?
                                    `${24 + templateHeight}em` :
                                    `${22 + templateHeight}em`
                            ) :

                            // Administrators have some additional controls to account for, so we need to
                            // adjust heights a bit more thoroughly.
                            //
                            (isScope('Employee') ?
                                    '8em' : (
                                        !isMobile ?
                                            `${7.5 + templateHeight}em` :
                                            `${10 + templateHeight}em`
                                    )
                            )
                    }}
                    className={`message__spacer message__spacer--${messageType} ${App.ifMobile('message__spacer--mobile')}`}
                />
            )}
        </>
    );

    return (
        <Box
            onScroll={handleContainerScroll}
            className={'stream__container'}
        >
            <Box>
                <Box className={'columns__1'}>
                    {!messages.length && (
                        <Box className={'p__3 text__center text__light text__disclaimer'}>
                            No messages available.
                        </Box>
                    )}

                    {messages.map(message => (
                        <ChatMessage
                            html={messageType === 'Email'}
                            record={message}
                            onUpdate={onMessageUpdate}
                            recipient={record}
                        />
                    ))}

                    {bottomSpacer}
                </Box>
            </Box>
        </Box>
    );
};

export default MessageStream;