import React, {useState} from "react";

import API from "../Global/API";
import TimeSheetForm from "./TimeSheetActions/TimeSheetForm";
import DialogHeading from "../Components/Typography/DialogHeading";

import Menu from "@mui/material/Menu";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

/**
 * TimeSheetActions component.
 *
 * @returns {*}
 * @constructor
 */
const TimeSheetActions = (props) => {
    const {
        record,         // {Object} The employee record / context.
        doReload,       // {Function} Reloads the parent layout, if applicable.
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);
    const [isEditing, setEditing] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isConfirmingDelete, setConfirmDelete] = useState(false);

    /**
     * Indicates whether an anchor target exists for the menu.
     *
     * @type {boolean}
     */
    const open = Boolean(anchorEl);


    /**
     * Fired whenever the menu button is clicked.
     *
     * @param event
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Closes the menu popover.
     */
    const handleClose = () => {
        setAnchorEl(null);
    };


    /**
     * Reveals the status edit form.
     */
    const handleTimeSheetEditOpen = () => {
        handleClose();
        setEditing(true);
    };


    /**
     * Hides the status edit form.
     */
    const handleTimeSheetEditClose = () => {
        setEditing(false);
    };


    /**
     * Handles the status record save.
     */
    const handleTimeSheetSave = (record) => {
        handleTimeSheetEditClose();

        if (doReload) {
            doReload(record);
        }
    };


    /**
     * Reveals the delete confirmation.
     */
    const handleDeleteConfirmOpen = () => {
        setConfirmDelete(true);
    };


    /**
     * Hides the delete confirmation.
     */
    const handleDeleteConfirmClose = () => {
        setConfirmDelete(false);
    };


    /**
     * Processes the delete action.
     *
     * @returns {Promise<void>}
     */
    const doDelete = async () => {
        setLoading(true);

        await API.delete(`time-sheets/${record.id}`);

        if(doReload){
            doReload();
        }

        setLoading(false);
        setConfirmDelete(false);
    };

    return (
        <div>
            <IconButton onClick={handleClick}>
                <MoreHorizIcon/>
            </IconButton>
            <Menu
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
            >
                <MenuItem onClick={handleTimeSheetEditOpen}>Edit Time Sheet</MenuItem>
                <Divider/>
                <MenuItem
                    onClick={handleDeleteConfirmOpen}
                    disabled={record.isDeleted}
                    className={'menu__error'}
                >Delete Record</MenuItem>
            </Menu>

            {isEditing && (
                <TimeSheetForm
                    open={true}
                    record={record}
                    onSave={handleTimeSheetSave}
                    onClose={handleTimeSheetEditClose}
                />
            )}

            {isConfirmingDelete && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handleDeleteConfirmClose}
                    maxWidth={'xs'}
                    fullWidth
                >
                    <DialogHeading
                        title={"Confirm Delete"}
                        noMargin
                    />
                    <DialogContent>
                        Are you sure that you'd like to delete this record?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={doDelete}
                            children={'Delete'}
                            disabled={isLoading}
                        />
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};

export default TimeSheetActions;