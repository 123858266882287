import React from "react";

import API from "../Global/API";
import Model from "../Definitions/Model";
import Settings from "../Global/Settings";
import Property from "../Definitions/Property";
import Formatter from "../Global/Formatter";
import PropertyMap from "../Definitions/PropertyMap";
import ImageWrapper from "../Components/ImageWrapper";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";

/**
 * Returns a formatted name.
 *
 * @param user
 * @returns {string|string}
 */
const getUserNiceName = (user) => {
    return user && user.firstName ? `${user.firstName} ${user.lastName ? `${user.lastName.charAt(0)}.` : ''}` : '(no name)';
};


/**
 * EmployeeMessage model.
 *
 * @type {Object}
 */
const EmployeeMessage = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'employee-message',


    /**
     * The appropriate API endpoint root.
     */
    route: 'messages',


    /**
     * Any additional query parameters.
     */
    query: {},


    /**
     * Returns any default query parameters.
     *
     * @returns {*|{}}
     */
    getQuery(employeeId) {
        const filter = this.getDefaultFilter();

        const query = {
            ...this.query,
            $select: '*,recipientRecord,senderRecord',
            $filter: `chatThread/any{employeeId in {${employeeId}}}`,
            $orderby: 'createdDate desc'
        };

        return filter && filter.query ? {
            ...query,
            ...filter.query
        } : query;
    },


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'sender',
        'content',
        'type'
    ],


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'sender',
            label: 'Sender',
            render: record => {
                const senderRecord = {...record.senderRecord};

                return (
                    <Box className={'d-flex__start'} sx={{paddingTop: '0.5em', paddingBottom: '0.5em'}}>
                        <ImageWrapper
                            src={API.getFilePath(senderRecord.image)}
                            width={36}
                            height={36}
                            className={'mr__2'}
                        />

                        <Box>
                            <b>{getUserNiceName(senderRecord)}</b>
                            <Box className={'notification__timestamp white-space__pre'}>
                                {Formatter.dateTime(record.createdDate)}
                            </Box>
                        </Box>
                    </Box>
                );
            },
            selectable: false
        }),
        Property.create({
            key: 'content',
            label: 'Content',
            render: record => (
                <Box sx={{paddingTop: '0.5em', paddingBottom: '0.5em'}}>
                    {record.type === 'Email' ? (
                        <Box>
                            <p><b>{record.subject || `New Message from ${Settings.applicationName}`}</b></p>
                            <Box dangerouslySetInnerHTML={{__html: record.content || ''}}/>
                        </Box>
                    ) : (
                        <Box className={'white-space__pre-line'}>
                            {record.content}
                        </Box>
                    )}
                </Box>
            ),
            selectable: false
        }),
        Property.create({
            key: 'type',
            label: 'Type',
            render: record => <Chip label={record.type} size={'small'}/>,
            sortable: true
        }),
        Property.create({
            key: 'createdDate',
            width: 200,
            label: 'Created At',
            render: record => Formatter.dateTime(record.createdDate),
            sortable: true
        })
    ])
};

export default EmployeeMessage;