import Property from "./Property";

/**
 * PropertyMap class.
 */
class PropertyMap {
    /**
     * PropertyMap constructor.
     *
     * @param properties
     */
    constructor(properties){
        this.properties = properties;
    }


    /**
     * Finds a property by key.
     *
     * @param key
     * @returns {*}
     */
    getByKey(key){
        for(let i = 0; i < this.properties.length; i++){
            const property = this.properties[i];

            if(!property instanceof Property){
                continue;
            }

            if(property.getKey() === key){
                return property;
            }
        }

        return new Property();
    }
}

export default PropertyMap;