import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import {useAuth} from "../../../Global/Auth";
import InputSelect from "../../../Components/Input/InputSelect";
import LogoHeading from "../../../Components/Typography/LogoHeading";
import DialogHeading from "../../../Components/Typography/DialogHeading";
import InputSignature from "../../../Components/Input/InputSignature";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import {DatePicker} from "@mui/x-date-pickers";
import {useNavigate} from "react-router-dom";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Formatter from "../../../Global/Formatter";

/**
 * AddShiftForm component.
 *
 * @constructor
 */
const AddShiftForm = (props) => {
    const {
        helix,              // {Boolean} Whether the form is being used within the TrueHelix app.
        forgot,             // {Boolean} Whether the form is being used to report a forgotten punch.
        onClose,            // {Function} An optional method for closing the form if it appears in a dialog.
        disabled,           // {Boolean} Whether the form inputs are disabled.
        onComplete,         // {Function} A callback function for whenever the user completes the form.
    } = props;

    const {user} = useAuth();
    const navigate = useNavigate();
    const [date, setDate] = useState(dayjs().format('YYYY-MM-DD'));
    const [notes, setNotes] = useState('');
    const [error, setError] = useState('');
    const [endTime, setEndTime] = useState(null);
    const [facility, setFacility] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [signature, setSignature] = useState('');
    const [breakTime, setBreakTime] = useState('30');
    const [startTime, setStartTime] = useState(null);
    const [supervisor, setSupervisor] = useState('');
    const [facilities, setFacilities] = useState([]);
    const [isSubmitted, setSubmitted] = useState(false);
    const [isConfirming, setConfirming] = useState(false);
    const [isSuccessful, setSuccessful] = useState(false);

    /**
     * Load all required data on mount.
     */
    useEffect(() => {
        getFacilities();
    }, []);


    /**
     * Indicates if this form is being used to send a shift into Helix.
     *
     * @type {boolean}
     */
    const bHelix = !!helix;


    /**
     * Indicates if this form is being used to report a forgotten punch.
     *
     * @type {boolean}
     */
    const bForgotPunchIn = !!forgot && !bHelix;


    /**
     * Fetches the assigned facilities for this employee.
     *
     * @returns {Promise<void>}
     */
    const getFacilities = async () => {
        const results = await API.get('clients', {
            $filter: `employeeClients/any{employeeId in {${user.id}}}`
        });

        setFacilities(results);

        // Default to the first available option.
        if (results.length) {
            setFacility(results[0].id);
        }
    };


    /**
     * Processes the form submission and sends a message to the scheduler.
     *
     * @returns {Promise<void>}
     */
    const handleSubmit = async () => {
        setError('');
        setSubmitted(true);

        const requirements = [
            facility, date, startTime, endTime
        ];

        if (!requirements.filter(requirement => !requirement).length) {
            if (bHelix) {
                await doSave();
            } else {
                handleConfirmationOpen();
            }
        } else {
            setError('Please answer all required fields.')
        }
    };


    /**
     * Opens the confirmation dialog.
     */
    const handleConfirmationOpen = () => {
        setConfirming(true);
    };


    /**
     * Closes the confirmation dialog.
     */
    const handleConfirmationClose = () => {
        setConfirming(false);
    };


    /**
     * Performs the final form submission.
     *
     * @returns {Promise<void>}
     */
    const doSave = async () => {
        setLoading(true);
        setConfirming(false);

        const response = await API.post('messages/shift-pickup', {
            date,
            notes,
            facility,
            breakTime,
            signature,
            supervisor,

            // Format our start / end times appropriately.
            type: helix ? 'Helix' : (
                bForgotPunchIn ?
                    'ForgotPunchIn' :
                    'ShiftPickup'
            ),
            endTime: endTime,
            startTime: startTime,
        });

        setLoading(false);

        if (response.status === 'error') {
            return setError(response.message);
        }

        setSuccessful(true);
    };


    /**
     * Handles the "Home" button click.
     *
     * @returns {*}
     */
    const handleComplete = () => {
        if (onComplete) {
            return onComplete();
        }

        navigate('/');
    }


    /**
     * All available time options.
     *
     * @type {Array}
     */
    const timeOptions = (() => {
        const results = [];

        for (let y = 0; y < 24; y++) {
            for (let x = 0; x < 4; x++) {
                const hours = y;
                const minutes = x * 15;

                const now = dayjs(date)
                    .set('hours', hours)
                    .set('minutes', minutes)
                    .set('seconds', 0);

                results.push({
                    value: now.format('HH:mm:ss'),
                    label: now.format('h:mmA')
                });
            }
        }

        return results;
    })();

    return (
        <Box>
            <LogoHeading
                title={bHelix ? 'Can\'t Punch In?' : (
                    bForgotPunchIn ? 'Manual Time Entry' :
                        'Shift Pick Up'
                )}
                onClose={onClose}
                actionSlot={
                    !onClose && (
                        <Button
                            size={'small'}
                            variant={'outlined'}
                            onClick={() => navigate('/')}
                            children={'Back'}
                            className={'text__small'}
                        />
                    )
                }
            />

            {!isSuccessful ? (
                <Box className={'columns__1'}>
                    {bHelix ? (
                        <Box
                            children={"If you don't see your shift within our mobile time clock, please use this form to create the shift and allow you to enter your time."}
                        />
                    ) : (
                        bForgotPunchIn ? (
                            <Box
                                children={"Use this form if you've worked a shift and forgot to clock in for it. This will notify the office of your shift, and create a manual time entry on your behalf."}
                            />
                        ) : (
                            <Box
                                children={"Use this form if you've picked up an extra shift directly from the facility. This will notify the office of your pick up, and allow you to submit time for this shift."}
                            />
                        )
                    )}

                    <FormControl error={isSubmitted && !facility}>
                        <FormLabel
                            required
                            children={bHelix ? 'What facility are you at?' : (bForgotPunchIn ?
                                'What facility did you work at?' : 'What facility did you pick up at?'
                            )}
                            className={'d-block mb__2'}
                        />

                        <InputSelect
                            value={facility}
                            error={isSubmitted && !facility}
                            options={facilities.map(option => {
                                return {
                                    label: option.name,
                                    value: option.id
                                };
                            })}
                            required
                            disabled={disabled}
                            onChange={event => setFacility(event.target.value)}
                            fullWidth
                            placeholder={'Example Facility'}
                        />
                    </FormControl>

                    <FormControl error={isSubmitted && !date}>
                        <FormLabel
                            required
                            children={bForgotPunchIn ? 'What day was the shift?' : 'What day did you pick up?'}
                            className={'d-block mb__2'}
                        />
                        <DatePicker
                            error={isSubmitted && !date}
                            value={date ? dayjs(date) : null}
                            disabled={isLoading}
                            onChange={event => setDate(event ? event.format('YYYY-MM-DD') : '')}
                        />
                    </FormControl>

                    {!!date && (
                        <>
                            <FormControl error={isSubmitted && !startTime}>
                                <FormLabel
                                    error={isSubmitted && !startTime}
                                    required
                                    children={bForgotPunchIn ?
                                        'What time did you arrive at the shift?' :
                                        'What is the shift start time?'
                                    }
                                    className={'d-block mb__2'}
                                />
                                <InputSelect
                                    value={startTime || null}
                                    error={isSubmitted && !startTime}
                                    disabled={isLoading}
                                    onChange={event => setStartTime(event.target.value)}
                                    options={timeOptions.map(option => {
                                        return {
                                            label: option.label,
                                            value: option.value
                                        };
                                    })}
                                />
                            </FormControl>

                            <FormControl error={isSubmitted && !endTime}>
                                <FormLabel
                                    error={isSubmitted && !endTime}
                                    required
                                    children={bForgotPunchIn ?
                                        'What time did you leave the facility?' :
                                        'What is the shift end time?'
                                    }
                                    className={'d-block mb__2'}
                                />
                                <InputSelect
                                    value={endTime || null}
                                    error={isSubmitted && !endTime}
                                    disabled={isLoading}
                                    onChange={event => setEndTime(event.target.value)}
                                    options={timeOptions.map(option => {
                                        return {
                                            label: option.label,
                                            value: option.value
                                        };
                                    })}
                                />
                            </FormControl>
                        </>
                    )}

                    {bForgotPunchIn && (
                        <>
                            <FormControl error={isSubmitted && !breakTime}>
                                <FormLabel
                                    error={isSubmitted && !breakTime}
                                    required
                                    children={'How long was your break?'}
                                    className={'d-block mb__2'}
                                />
                                <InputSelect
                                    value={breakTime || null}
                                    error={isSubmitted && !breakTime}
                                    disabled={isLoading}
                                    onChange={event => setBreakTime(event.target.value)}
                                    options={['0', '20', '30', '60'].map(option => {
                                        return {
                                            label: `${option}m`,
                                            value: option
                                        };
                                    })}
                                />
                            </FormControl>
                        </>
                    )}

                    <FormControl>
                        <FormLabel
                            children={'Anything else you’d like to let us know about the shift?'}
                            className={'d-block mb__2'}
                        />
                        <TextField
                            rows={4}
                            value={notes}
                            disabled={isLoading}
                            onChange={event => setNotes(event.target.value)}
                            multiline
                            fullWidth
                        />
                    </FormControl>

                    <Button
                        variant={'outlined'}
                        onClick={handleSubmit}
                        children={'Submit'}
                        disabled={isLoading}
                    />
                </Box>
            ) : (
                bHelix ? (
                    <Box className={'columns__1'}>
                        <Alert severity={'success'}>
                            Successfully received message!
                        </Alert>

                        <Box>
                            Thank you for providing your shift information - Please wait a minute or two to allow the
                            shift information to be delivered to the time clock application.
                        </Box>
                        <Box>If you do not see the shift
                            after a few minutes, please reach out to the office for assistance.
                        </Box>

                        <Button
                            variant={'outlined'}
                            onClick={handleComplete}
                            children={'Home'}
                        />
                    </Box>
                ) : (
                    <Box className={'columns__1'}>
                        <Alert severity={'success'}>
                            Successfully received message!
                        </Alert>

                        <Box>Thank you for providing your shift information. We'll reach back out soon with any
                            additional
                            questions and / or updates.</Box>

                        <Button
                            variant={'outlined'}
                            onClick={handleComplete}
                            children={'Home'}
                        />
                    </Box>
                )
            )}

            {isConfirming && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handleConfirmationClose}
                    maxWidth={'xs'}
                    fullWidth
                >
                    <DialogHeading
                        title={"Shift Confirmation"}
                        noMargin
                    />
                    <DialogContent>
                        {bForgotPunchIn ? (
                            <Box className={'columns__1'}>
                                <Box>
                                    By submitting this form, you are agreeing that you have provided all of the shift
                                    information accurately. To continue, please have your supervisor sign-off on the
                                    following information:
                                </Box>
                                <Box>
                                    <ul className={'m__0'}>
                                        <li><b>Date:</b> {Formatter.date(date, '', false)}</li>
                                        <li><b>Start Time:</b> {dayjs(startTime, 'HH:mm:ss').format('h:mmA')}</li>
                                        <li><b>End Time:</b> {dayjs(endTime, 'HH:mm:ss').format('h:mmA')}</li>
                                        <li><b>Break:</b> {breakTime}m</li>
                                    </ul>
                                </Box>
                                <FormControl>
                                    <FormLabel
                                        children={'Who is your supervisor?'}
                                        className={'d-block mb__2'}
                                    />
                                    <TextField
                                        value={supervisor}
                                        required
                                        disabled={isLoading}
                                        onChange={event => setSupervisor(event.target.value)}
                                    />
                                </FormControl>

                                <InputSignature
                                    key={0}
                                    value={signature}
                                    label={'Supervisor Signature'}
                                    disabled={isLoading}
                                    onChange={setSignature}
                                    scaleFactor={2}
                                />
                            </Box>
                        ) : (
                            <Box className={'columns__1'}>
                                <Box>
                                    By submitting this form, you are confirming that you have coordinated this shift
                                    directly with the facility. To continue, please enter the name of the supervisor you
                                    spoke with.
                                </Box>
                                <TextField
                                    label={'Supervisor'}
                                    value={supervisor}
                                    required
                                    disabled={isLoading}
                                    onChange={event => setSupervisor(event.target.value)}
                                />
                            </Box>
                        )}
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={doSave}
                            children={'Confirm'}
                            disabled={isLoading || !supervisor || (bForgotPunchIn && !signature)}
                        />
                        <Button
                            color={'error'}
                            onClick={handleConfirmationClose}
                            children={'Cancel'}
                            disabled={isLoading}
                        />
                    </DialogActions>
                </Dialog>
            )}

            <Snackbar
                open={!!error}
                onClose={() => setError('')}
                autoHideDuration={6000}
            >
                <Alert
                    onClose={() => setError('')}
                    children={error}
                    severity={'error'}
                />
            </Snackbar>
        </Box>
    );
};

export default AddShiftForm;