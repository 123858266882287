import React, {useEffect, useState} from "react";

import {useAuth} from "../../../Global/Auth";
import LogoHeading from "../../../Components/Typography/LogoHeading";
import StepControls from "../../../Components/StepControls";
import ReferenceInput from "./ReferenceInput";

import Box from "@mui/material/Box";

/**
 * ReferenceStep component.
 *
 * @constructor
 */
const ReferenceStep = (props) => {
    const {
        step,                   // {Number} The current step of the form.
        loading,                // {Boolean} Whether the form is currently loading.
        employee,               // {Object} The expected employee object for content.
        readOnly,               // {Boolean} Whether the form is read only / disabled.
        onStepChange            // {Function} Handles the step transition.
    } = props;

    const [isLoaded, setLoaded] = useState(false);
    const [referenceA, setReferenceA] = useState({});
    const [referenceB, setReferenceB] = useState({});
    const [referenceC, setReferenceC] = useState({});
    const [isSubmitted, setSubmitted] = useState(false);
    const {getUserSettingValue, setUserSetting, getUserSettingValueForEmployee} = useAuth();

    /**
     * Load all required data on initial mount.
     */
    useEffect(() => {
        handleSettingsLoad();
    }, []);


    /**
     * Synchronizes the backend setting value with reference #1.
     */
    useEffect(() => {
        if (!readOnly && isLoaded) {
            setUserSetting('JI_REFERENCE_A', JSON.stringify(referenceA));
        }
    }, [referenceA]);


    /**
     * Synchronizes the backend setting value with reference #2.
     */
    useEffect(() => {
        if (!readOnly && isLoaded) {
            setUserSetting('JI_REFERENCE_B', JSON.stringify(referenceB));
        }
    }, [referenceB]);


    /**
     * Synchronizes the backend setting value with reference #3.
     */
    useEffect(() => {
        if (!readOnly && isLoaded) {
            setUserSetting('JI_REFERENCE_C', JSON.stringify(referenceC));
        }
    }, [referenceC]);


    /**
     * Loads each of the appropriate user settings.
     *
     * @returns {Promise<void>}
     */
    const handleSettingsLoad = async () => {
        let referenceA, referenceB, referenceC;

        if(readOnly){
            referenceA = await getUserSettingValueForEmployee(employee.id, 'JI_REFERENCE_A');
            referenceB = await getUserSettingValueForEmployee(employee.id, 'JI_REFERENCE_B');
            referenceC = await getUserSettingValueForEmployee(employee.id, 'JI_REFERENCE_C');
        }
        else{
            referenceA = await getUserSettingValue('JI_REFERENCE_A');
            referenceB = await getUserSettingValue('JI_REFERENCE_B');
            referenceC = await getUserSettingValue('JI_REFERENCE_C');
        }

        setReferenceA(JSON.parse(referenceA || '{}'));
        setReferenceB(JSON.parse(referenceB || '{}'));
        setReferenceC(JSON.parse(referenceC || '{}'));

        setLoaded(true);
    };


    /**
     * Validates the reference inputs.
     *
     * @param updated
     * @returns {*}
     */
    const handleValidation = (updated) => {
        if (updated < step) {
            return onStepChange(updated);
        }

        let valid = true;
        setSubmitted(true);

        // Attempt to validate each reference.
        [referenceA, referenceB, referenceC].map(reference => {
            if (!valid) {
                return;
            }

            if (!reference.name || !reference.relationship || !reference.phoneNumber) {
                valid = false;
            }

            if (reference.relationship === 'Other' && !reference.other) {
                valid = false;
            }
        });

        // Only proceed if validation has succeeded.
        if (valid) {
            onStepChange(updated);
        }
    };

    return (
        <>
            <LogoHeading title={'References'}/>

            <Box className={'columns__1'}>
                <Box>
                    We require all of our employees to provide three personal references for our
                    recruiting team to contact on your behalf.
                </Box>

                <ReferenceInput
                    label={'Reference #1'}
                    value={referenceA}
                    readOnly={!isLoaded || readOnly}
                    validate={isSubmitted}
                    onChange={(value) => setReferenceA(value)}
                />

                <ReferenceInput
                    label={'Reference #2'}
                    value={referenceB}
                    readOnly={!isLoaded || readOnly}
                    validate={isSubmitted}
                    onChange={(value) => setReferenceB(value)}
                />

                <ReferenceInput
                    label={'Reference #3'}
                    value={referenceC}
                    readOnly={!isLoaded || readOnly}
                    validate={isSubmitted}
                    onChange={(value) => setReferenceC(value)}
                />

                {!readOnly && (
                    <StepControls
                        step={step}
                        onBack={onStepChange}
                        onNext={handleValidation}
                        disabled={loading}
                    />
                )}
            </Box>
        </>
    );
};

export default ReferenceStep;