import React from "react";

import API from "../../Global/API";
import Client from "../../Models/Client";
import ImageWrapper from "../ImageWrapper";
import ClientActions from "../../Actions/ClientActions";

import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

/**
 * ClientListItem component.
 *
 * @returns {*}
 * @constructor
 */
const ClientListItem = (props) => {
    const {
        client,                 // {Object} The client record.
        selected,               // {Boolean} Indicates whether to select the item.
        onSelect,               // {Function} A selection callback, triggered whenever the user clicks on the item.
        textWidth,              // {Number} An optional text width of the item.
        secondary,              // {Component} An optional secondary action slot.
        imageSize,              // {Number} An optional image size replacement (defaults to 36).
    } = props;

    /**
     * Processes the selection event.
     *
     * @param client
     * @param event
     */
    const handleSelect = (client, event) => {
        if (onSelect) {
            onSelect(client, event);
        }
    };

    return (
        <>
            <ListItem
                sx={{
                    paddingTop: 0,
                    paddingBottom: 0
                }}
                className={`list-item__row`}
                disableGutters
                secondaryAction={
                    secondary || <ClientActions record={client}/>
                }
            >
                <ListItemButton
                    sx={{
                        paddingTop: '0.5em',
                        paddingLeft: '1em',
                        paddingBottom: '0.5em',
                        pointerEvents: !onSelect ? 'none' : 'inherit',
                    }}
                    onClick={event => handleSelect(client, event)}
                    selected={selected}
                    className={`w__100`}
                    disableGutters
                >
                    <Box>
                        <Box className={'d-flex__justify'}>
                            <Box className={`d-flex__start`} sx={{
                                width: '100%',
                                paddingTop: '0.2em',
                                paddingBottom: '0.2em'
                            }}>
                                <ImageWrapper
                                    src={API.getFilePath(client.image)}
                                    icon={Client.icon({fontSize: 'small'})}
                                    width={imageSize || 36}
                                    height={imageSize || 36}
                                    className={'mr__2'}
                                    horizontal
                                />

                                <Box
                                    className={`d-flex__justify flex__grow`}
                                >
                                    <Box>
                                        <Box
                                            sx={textWidth ? {width: textWidth} : {}}
                                            className={textWidth ? 'text__ellipsis' : ''}
                                        >
                                            <b>{client.name}</b>
                                        </Box>

                                        <Box
                                            className={'d-flex__start text__small text__light'}
                                        >
                                            {client.type}
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </ListItemButton>
            </ListItem>
        </>
    );
};

export default ClientListItem;