import React from "react";

import Formatter from "../../Global/Formatter";

import Box from "@mui/material/Box";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";

/**
 * LogoHeading component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const LogoHeading = (props) => {
    const {
        title,              // {String} The string heading.
        onClose,            // {Function} An optional method for closing the form if it appears in a dialog.
        className,          // {String} An optional class to include on the container.
        actionSlot,         // {Component} An optional action area to append controls to.
    } = props;

    return (
        <Box className={'d-flex__justify w__100'}>
            <h2 className={`d-flex__start ${className || ''}`}>
                <img src={Formatter.url('favicon.png')} style={{width: 48}} className={'mr__1'}/>
                {title}
            </h2>

            {onClose && (
                <IconButton onClick={onClose} size={'small'}>
                    <CloseIcon/>
                </IconButton>
            )}

            {actionSlot}
        </Box>
    );
};

export default LogoHeading;