import Settings from "./Settings";

import  secureLocalStorage  from  "react-secure-storage";

/**
 * State object.
 *
 * @type {Object}
 */
const State = {
    /**
     * Attempts to assign a value to storage.
     *
     * @param key
     * @param value
     */
    set(key, value) {
        secureLocalStorage.setItem(`${Settings.cachePrefix}${key}`, value || '');
    },


    /**
     * Retrieves a value from storage.
     *
     * @param key
     * @param def
     * @returns {string | * | string}
     */
    get(key, def) {
        return secureLocalStorage.getItem(`${Settings.cachePrefix}${key}`) || def || '';
    },


    /**
     * Pulls an array from storage.
     *
     * @param key
     * @returns {Array}
     */
    array(key){
        const item = secureLocalStorage.getItem(`${Settings.cachePrefix}${key}`);

        return item ? JSON.parse(item) : [];
    },


    /**
     * Pulls a JSON object from state.
     *
     * @param key
     * @returns {{}}
     */
    json(key){
        const item = secureLocalStorage.getItem(`${Settings.cachePrefix}${key}`);

        return item ? JSON.parse(item) : {};
    }
};

export default State;