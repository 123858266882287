import React from "react";

import App from "../Global/App";
import Model from "../Definitions/Model";
import Property from "../Definitions/Property";
import TaskForm from "../Actions/TaskActions/TaskForm";
import Formatter from "../Global/Formatter";
import PropertyMap from "../Definitions/PropertyMap";
import TaskActions from "../Actions/TaskActions";
import BoundCheckbox from "../Components/Input/BoundCheckbox";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import ErrorIcon from '@mui/icons-material/Error';

/**
 * UserTask model.
 *
 * @type {Object}
 */
const UserTask = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'task',


    /**
     * The appropriate API endpoint root.
     */
    route: 'tasks',


    /**
     * Any additional query parameters.
     */
    query: {},


    /**
     * Returns any default query parameters.
     *
     * @returns {*|{}}
     */
    getQuery() {
        const user = App.getUser();
        const filter = this.getDefaultFilter();

        const query = {
            ...this.query,
            $filter: `userId in {${user.id}}`,
            $orderby: 'isCompleted asc, dueDate asc'
        };

        return filter && filter.query ? {
            ...query,
            ...filter.query
        } : query;
    },


    /**
     * The form component.
     *
     * @returns {*}
     * @param parameters
     */
    form: (parameters) => <TaskForm {...parameters}/>,


    /**
     * The menu action.
     *
     * @param parameters
     * @returns {*}
     */
    action: (parameters) => <TaskActions {...parameters}/>,


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'title',
        'dueDate',
    ],


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'dueDate',
            width: 190,
            label: 'Due Date',
            render: record => {
                const isDue = (record.isCompleted || !record.dueDate) ?
                    false : dayjs().isAfter(dayjs(record.dueDate));

                return (
                    <Box
                        className={`d-flex__start ${record.isCompleted ? 'text__strike' : ''}`}
                    >
                        {Formatter.dateTime(record.dueDate, '', false)}

                        {isDue && (
                            <ErrorIcon fontSize={'small'} color={'error'} className={'ml__2'}/>
                        )}
                    </Box>
                );
            },
            sortable: false
        }),
        Property.create({
            key: 'title',
            label: 'Title',
            render: (record, doReload) => (
                <Box className={'d-flex__start'}>
                    <BoundCheckbox
                        model={UserTask}
                        field={'isCompleted'}
                        record={record}
                        doReload={doReload}
                        className={'mr__2'}
                    />

                    <Box className={record.isCompleted ? 'text__strike' : ''}>{record.title || '(no description)'}</Box>
                </Box>
            ),
            sortable: true,
        }),
        Property.create({
            key: 'description',
            label: 'Description',
            render: record => <Box className={record.isCompleted ? 'text__strike' : ''}>{record.description}</Box>,
            sortable: true,
        }),
        Property.create({
            key: 'isCompleted',
            label: 'Status',
            render: record => <Box
                className={record.isCompleted ? 'text__strike' : ''}>{record.isCompleted ? 'Complete' : 'Incomplete'}</Box>,
            sortable: true
        })
    ])
};

export default UserTask;