import React, {useMemo, useState} from "react";

import Panel from "../Panel";
import State from "../../Global/State";
import UserTask from "../../Models/UserTask";
import DataTable from "../Layouts/Index/DataTable";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";

/**
 * UserTaskFeed component.
 *
 * @returns {*}
 * @constructor
 */
const UserTaskFeed = (props) => {
    const {
        title,          // {Component} An optional title / heading replacement component.
        className,      // {String} An optional class to apply to the outer container.
    } = props;

    const [index, setIndex] = useState(0);
    const [formOpen, setFormOpen] = useState(false);
    const [hideCompleted, setHideCompleted] = useState(!!State.get('task-hide-completed'));

    /**
     * Reveals the associated form.
     */
    const handleFormClick = () => {
        setFormOpen(true);
    };


    /**
     * Closes the associated form.
     */
    const handleFormClose = () => {
        setFormOpen(false);
    };


    /**
     * Reloads the table view.
     *
     * @returns {Promise<void>}
     */
    const handleLayoutReload = async (record) => {
        if(!record || !record.id){
            setIndex(index + 1);
        }
    }


    /**
     * The memoized task feed content.
     */
    const content = useMemo(() => (
        <DataTable
            model={UserTask}
            query={UserTask.getQuery()}
            actions={!!UserTask.action}
            doReload={handleLayoutReload}
            hideHeader
        />
    ), [index]);

    return (
        <>
            <Panel
                header={title ? (
                    <Box className={'d-flex__justify w__100'}>
                        {title}

                        <Button
                            variant={'link'}
                            onClick={handleFormClick}
                            children={<AddIcon/>}
                            className={'layout__control'}
                        />
                    </Box>
                ) : null}
                padding={0}
                heading={!title ? 'My Tasks' : ''}
                className={className}
            >
                <Box
                    key={index}
                    style={{padding: 0}}
                    className={'notifications__body'}
                >
                    {content}
                </Box>
            </Panel>

            {formOpen && UserTask.getForm({
                open: formOpen,
                onSave: async (record) => {
                    handleFormClose();
                    handleLayoutReload();
                },
                onClose: handleFormClose,
            })}
        </>
    );
};

export default UserTaskFeed;