import React, {useEffect, useRef, useState} from "react";

import API from "../../Global/API";
import State from "../../Global/State";
import Logger from "../../Global/Logger";
import Settings from "../../Global/Settings";
import Generator from "../../Global/Generator";
import {dataURIToBlob, fileToDataURL} from "../../Global/Helpers/File";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import {getEditorDefaults} from "@pqina/pintura";
import {PinturaEditorModal} from "@pqina/react-pintura";

/**
 * InputImage component.
 *
 * @returns {Element}
 * @constructor
 */
const InputImage = (props) => {
    const {
        value,              // {String} The current value.
        editable,           // {Boolean} Whether the image is editable via Pintura.
        onChange,           // {Function} The change event handler.
        children,           // {Component} The upload control display.
        className,          // {String} An optional class to add to the container.
        doLoading,          // {Function} A loading callback.
        uploadPath,         // {String} The upload path for the resulting file.
    } = props;

    const [path, setPath] = useState('');
    const [index, setIndex] = useState(0);
    const [isError, setError] = useState(false);
    const [fileName, setFileName] = useState('');
    const [isEditing, setEditing] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [isModified, setModified] = useState(false);
    const [editTarget, setEditTarget] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    /**
     * Initialize the object path on component mount.
     */
    useEffect(() => {
        if (!value) {
            return;
        }

        Logger.debug('[InputImage] Received updated value.', value);
        setPath(value);
    }, [value]);


    /**
     * Synchronizes the load state with the parent component.
     */
    useEffect(() => {
        if(doLoading){
            doLoading(isLoading);
        }
    }, [isLoading]);


    /**
     * The editor configuration for Pintura.
     *
     * @type {PinturaEditorDefaultOptions}
     * @see https://pqina.nl/pintura/
     */
    const editorConfig = getEditorDefaults({
        imageReader: {
            orientImage: true
        },
        imageWriter: {
            quality: 0.4,
            mimeType: 'image/jpeg',
            store: (state, options, onprogress) =>
                new Promise(async (resolve, reject) => {
                    let file;
                    const {dest} = state;
                    const formData = new FormData();

                    /**
                     * Depending on our environment, we'll need to handle this piece a bit differently. For Android,
                     * there appears to be a bug tied into the `android-image-file-input` package that prevents us from
                     * using the traditional upload method as the output file (ie. `dest`) becomes unusable.
                     */
                    Logger.debug('[InputImage] Detected Android environment, using alternative upload method.');
                    const result = await fileToDataURL(state.dest);
                    formData.append('file', dataURIToBlob(result), fileName);

                    // If for some reason we can't continue, let's fail gracefully.
                    if (!file) {
                        resolve(state);
                    }

                    // Create the request payload.
                    setLoading(true);
                    formData.append('name', fileName);
                    formData.append('path', uploadPath || '' || '');

                    // create a request object
                    const request = new XMLHttpRequest();
                    request.open('POST', `${Settings.apiPath}/upload`);
                    request.setRequestHeader('Authorization', `Bearer ${State.get('auth-token')}`);

                    // show progress in interface
                    request.upload.onprogress = onprogress;

                    // catch errors
                    request.onerror = () =>
                        reject('Unable to save, an unexpected error occurred.');

                    request.ontimeout = () =>
                        reject('Unable to save, request timed out.');

                    // handle success state
                    request.onload = () => {
                        if (request.status >= 200 && request.status < 300) {
                            state.store = request;
                            resolve(state);
                        } else {
                            reject('Unable to save.');
                        }
                    };

                    // start uploading the image
                    request.send(formData);
                })
        }
    });


    /**
     * The editor instance.
     *
     * @type {React.MutableRefObject<null>}
     */
    const editor = useRef(null);


    /**
     * The hidden file input element.
     *
     * @type {React.MutableRefObject<null> | React.RefObject<any> | React.MutableRefObject<undefined>}
     */
    const fileInput = useRef(null);


    /**
     * Handles click interaction with the avatar.
     */
    const handleClick = () => {
        if (!fileInput.current) {
            return;
        }

        fileInput.current.click();
    };


    /**
     * Closes the status popover.
     */
    const handleStatusClose = () => {
        setError(false);
        setSuccess(false);
    };


    /**
     * Handles the image error message.
     *
     * @param error
     */
    const onUploadError = (error) => {
        setError(true);
        setSuccess(false);
        setLoading(false);
        setEditing(false);
        setErrorMessage(error);
    };


    /**
     * Reveals the edit modal.
     *
     * @param file
     * @returns {void}
     */
    const doEditMode = file => {
        Logger.debug('[InputImage] Entering edit mode.', file);

        setEditing(true);
        setEditTarget(file);
    };


    /**
     * Closes the edit modal.
     */
    const handleEditClose = () => {
        setEditing(false);
        setLoading(false);

        setTimeout(() => {
            setIndex(index + 1);
        }, 250);
    };


    /**
     * Handles the file relation back to the root record post-upload.
     *
     * @param path
     * @returns {Promise<void>}
     */
    const onUploadSuccess = async (path) => {
        setPath(path);
        setLoading(false);
        setModified(true);

        if (onChange) {
            onChange(path);
        }
    };


    /**
     * Processes the image upload.
     */
    const doUpload = () => {
        const file = fileInput.current.files[0];
        setLoading(true);

        if (editable) {
            setFileName(`${Generator.uuid()}-${file.name}`);
            doEditMode(file);
        } else {
            API.doFileUpload({
                file: file,
                path: uploadPath || '',
                types: ['jpg', 'png', 'jpeg', 'png', 'bmp']
            }, onUploadSuccess, onUploadError);
        }
    };

    return (
        <>
            <Box
                onClick={handleClick}
                children={children}
                className={className || ''}
            />

            <input
                ref={fileInput}
                key={`file-upload-${index}`}
                type={'file'}
                accept={'image/*'}
                capture
                onChange={doUpload}
                className={'d-hidden'}
            />

            {isEditing && editTarget && (
                <PinturaEditorModal
                    {...editorConfig}

                    // Global attributes.
                    ref={editor}
                    src={editTarget}
                    utils={['crop']}
                    onHide={handleEditClose}
                    onProcess={() => onUploadSuccess(`${uploadPath || ''}/${fileName}`)}
                    onLoaderror={() => onUploadError('Unable to load image.')}
                    onProcesserror={() => onUploadError('Unable to upload image, please try again.')}

                    // Additional attributes specific to iOS.
                    preventScrollBodyIfNeeded={false}
                    preventFooterOverlapIfNeeded={false}
                />
            )}

            <Snackbar open={isError} autoHideDuration={6000} onClose={() => setError(false)}>
                <Alert onClose={() => setError(false)} severity={'error'}>
                    {errorMessage}
                </Alert>
            </Snackbar>
        </>
    );
};

export default InputImage;