import React, {useState} from "react";

import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";

/**
 * LoadingRow component.
 *
 * @returns {*}
 * @constructor
 */
const LoadingRow = (props) => {
    const {
        key         // {*} The unique component key.
    } = props;

    const [topVariance, setTopVariance] = useState(0);
    const [bottomVariance, setBottomVariance] = useState(0);

    return (
        <ListItem
            key={key}
            className={`list-item__row pt__0 pb__0`}
            disableGutters
        >
            <ListItemButton
                disabled
                className={`w__100`}
                disableGutters
                sx={{
                    paddingLeft: '1em',
                    paddingTop: '0.5em',
                    paddingBottom: '0.5em',
                }}
            >
                <Box
                    className={`d-flex__start`}
                    sx={{
                        width: '100%',
                        paddingTop: '0.2em',
                        paddingBottom: '0.2em'
                    }}
                >
                    <Skeleton
                        width={36}
                        height={36}
                        variant={'rounded'}
                        className={'mr__2'}
                    />
                    <Box sx={{flexGrow: 1}}>
                        <Skeleton width={`${70 + topVariance}%`}/>
                        <Skeleton width={`${45 + bottomVariance}%`}/>
                    </Box>
                </Box>
            </ListItemButton>
        </ListItem>
    )
};

export default LoadingRow;