import React, {useState} from "react";

import API from "../../Global/API";
import Logger from "../../Global/Logger";
import Employee from "../../Models/Employee";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Radio from "@mui/material/Radio";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import InputLabel from "@mui/material/InputLabel";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";

/**
 * PayRateForm component.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const PayRateForm = (props) => {
    const {
        open,           // {Boolean} Indicates if the dialog is currently open.
        record,         // {Object} The employee record for context.
        onSave,         // {Function} Called after saving the record.
        loading,        // {Boolean} Indicates if the form is currently loading.
        onClose,        // {Function} An on-close callback for the dialog.
    } = props;

    const [error, setError] = useState('');
    const [fields, setFields] = useState({...Employee.getInstance(record)});
    const [isLoading, setLoading] = useState(false);

    /**
     * The employee's type of employment.
     *
     * @type {string}
     */
    const employmentType = (fields.isPerDiem || fields.isContract) ? (
        fields.isPerDiem ?
            'perDiem' :
            'contract'
    ) : '';


    /**
     * Handles the save action.
     *
     * @returns {Promise<void>}
     */
    const doSave = async () => {
        setError('');
        setLoading(true);

        const response = await API.put(`employees/${record.id}`, {
            ...fields
        });

        if(onSave){
            onSave(response);
        }

        if(onClose){
            onClose();
        }
    };


    /**
     * Indicates if the form is currently loading.
     *
     * @type {boolean}
     */
    const isDisabled = loading || isLoading;


    /**
     * Updates the employment type.
     *
     * @param event
     */
    const handleEmploymentTypeChange = (event) => {
        const value = event.target.value;

        if(value === 'perDiem') {
            setFields({
                ...fields,
                isPerDiem: 1,
                isContract: 0
            });
        }
        else {
            setFields({
                ...fields,
                isPerDiem: 0,
                isContract: 1
            });
        }
    }


    /**
     * Updates a particular form value.
     *
     * @param key
     * @param value
     */
    const setValue = (key, value) => {
        Logger.debug(`[PayRateForm] Updated "${key}" to "${value}"...`);

        setFields({
            ...fields,
            [key]: value
        });
    };

    return (
        <Dialog
            open={open}
            scroll={'body'}
            onClose={onClose}
            maxWidth={'lg'}
        >
            <DialogContent>
                {!!error ? (
                    <Alert severity={'error'}>
                        {error}
                    </Alert>
                ) : (
                    <Alert severity={'info'}>
                        Changes to this form will deliver an automated notice to payroll.
                    </Alert>
                )}

                <Box className={'columns__1'}>
                    <InputLabel
                        children={'Update Pay Rates'}
                        className={'form__heading form__heading--standalone'}
                    />

                    <Box className={'columns__1'}>
                        <Box className={'d-flex__start'}>
                            <FormControl sx={{minWidth: '32%'}} className={'mr__4'}>
                                <InputLabel>Hourly Rate</InputLabel>
                                <OutlinedInput
                                    label={'Hourly Rate'}
                                    value={fields.payRate}
                                    disabled={isLoading}
                                    onChange={event => setValue('payRate', event.target.value)}
                                    startAdornment={<InputAdornment position="start">$</InputAdornment>}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Employment Type</FormLabel>
                                <RadioGroup
                                    row
                                    value={employmentType}
                                    onChange={handleEmploymentTypeChange}
                                >
                                    <FormControlLabel
                                        value={'perDiem'}
                                        label={'Per Diem'}
                                        control={
                                            <Radio disabled={isLoading}/>
                                        }
                                    />
                                    <FormControlLabel
                                        value={'contract'}
                                        label={'Contract'}
                                        control={
                                            <Radio disabled={isLoading}/>
                                        }
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Box>
                        <TextField
                            rows={5}
                            value={fields.payNotes}
                            label={'Please provide any additional details...'}
                            disabled={isLoading}
                            onChange={event => setValue('payNotes', event.target.value)}
                            multiline
                        />
                    </Box>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={doSave}
                    children={'Save'}
                    disabled={isDisabled}
                />
                <Button
                    color={'error'}
                    onClick={onClose}
                    disabled={isDisabled}
                    children={'Cancel'}
                />
            </DialogActions>
        </Dialog>
    );
};

export default PayRateForm;