import React from "react";

import Formatter from "../../../../Global/Formatter";
import ModalButton from "../../../../Components/ModalButton";
import EmployeeView from "../../Employee/EmployeeView";
import EmployeeListItem from "../../../../Components/Lists/EmployeeListItem";

import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";

/**
 * EmployeePreview component.
 *
 * @param props
 * @constructor
 */
const EmployeePreview = (props) => {
    const {
        noCache,            // {Boolean} Indicates if we should bypass the image caching mechanism.
        employee,           // {Object} The employee record.
    } = props;

    return (
        <ModalButton
            label={
                <EmployeeListItem
                    noCache={noCache}
                    onSelect={() => true}
                    employee={employee}
                />
            }
            bodyStyle={{
                paddingTop: 0
            }}
            children={
                <EmployeeView id={employee.id}/>
            }
        />
    );
};

export default EmployeePreview;