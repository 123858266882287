import React, {useEffect, useRef, useState} from "react";

import App from "../../Global/App";
import API from "../../Global/API";
import Page from "../../Components/Page";
import Settings from "../../Global/Settings";
import EmployeeActions from "../../Actions/EmployeeActions";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Alert from "@mui/material/Alert";
import Radio from "@mui/material/Radio";
import QRCode from "react-qr-code";
import Button from "@mui/material/Button";
import Slider from "@mui/material/Slider";
import Select from "@mui/material/Select";
import Switch from "@mui/material/Switch";
import {toPng} from 'html-to-image';
import Divider from "@mui/material/Divider";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import Snackbar from "@mui/material/Snackbar";
import Accordion from "@mui/material/Accordion";
import BadgeIcon from '@mui/icons-material/Badge';
import PrintIcon from '@mui/icons-material/Print';
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import {useParams} from "react-router";
import FormControl from "@mui/material/FormControl";
import {ChromePicker} from "react-color";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import FormControlLabel from "@mui/material/FormControlLabel";

/**
 * BadgeEditor component.
 *
 * @returns {*}
 * @constructor
 */
const BadgeEditor = () => {
    const {
        id
    } = useParams();

    const [zoom, setZoom] = useState(0.9);
    const [index, setIndex] = useState(0);
    const [isError, setError] = useState(false);
    const [template, setTemplate] = useState('Reliant');
    const [employee, setEmployee] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [forceFit, setForceFit] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [imageZoom, setImageZoom] = useState(1.0);
    const [outputWidth, setOutputWidth] = useState(960);
    const [fitToHeight, setFitToHeight] = useState(false);
    const [heightRatio, setHeightRatio] = useState(1.5707)
    const [primaryColor, setPrimaryColor] = useState('#0d5491');
    const [lastNameScale, setLastNameScale] = useState(1.0);
    const [firstNameScale, setFirstNameScale] = useState(1.0);
    const [specialtyScale, setSpecialtyScale] = useState(1.0);
    const [secondaryColor, setSecondaryColor] = useState('#25bc0b');
    const [primaryColorAnchorEl, setPrimaryColorAnchorEl] = React.useState(null);
    const [secondaryColorAnchorEl, setSecondaryColorAnchorEl] = React.useState(null);

    /**
     * References for the badge objects.
     *
     * @type {React.MutableRefObject<null> | React.RefObject<any> | React.MutableRefObject<undefined>}
     */
    const badgeLeft = useRef(null);
    const badgeRight = useRef(null);


    /**
     * Load all ancillary data on component mount.
     */
    useEffect(() => {
        getEmployee();
    }, []);


    /**
     * Reveals the primary color picker.
     *
     * @param event
     */
    const handlePrimaryColorClick = (event) => {
        setPrimaryColorAnchorEl(event.currentTarget);
    };


    /**
     * Closes the primary color picker.
     */
    const handlePrimaryColorClose = () => {
        setPrimaryColorAnchorEl(null);
    };


    /**
     * Reveals the secondary color picker.
     *
     * @param event
     */
    const handleSecondaryColorClick = (event) => {
        setSecondaryColorAnchorEl(event.currentTarget);
    };


    /**
     * Closes the secondary color picker.
     */
    const handleSecondaryColorClose = () => {
        setSecondaryColorAnchorEl(null);
    };


    /**
     * Pulls all the current course data via the API.
     *
     * @returns {Promise<void>}
     */
    const getEmployee = async () => {
        if (!id) {
            return;
        }

        setLoading(true);
        setEmployee(
            await API.get(`employee/${id}`, {
                $expand: 'specialty,status,state'
            })
        );
        setLoading(false);
    };


    /**
     * Downloads a data URL as a file.
     *
     * @param uri
     * @param name
     */
    const doDownload = (uri, name) => {
        const link = document.createElement("a");
        link.download = name;
        link.href = uri;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }


    const rotate90 = async (src) => {
        return new Promise(resolve => {
            const img = new Image()
            img.src = src
            img.onload = function() {
                const canvas = document.createElement('canvas')
                canvas.width = img.height
                canvas.height = img.width
                canvas.style.position = "absolute"
                const ctx = canvas.getContext("2d")
                ctx.translate(img.height, img.width / img.height)
                ctx.rotate(Math.PI / 2)
                ctx.drawImage(img, 0, 0)
                resolve(canvas.toDataURL())
            }
        })
    }


    /**
     * Processes the course update transaction.
     */
    const doPrint = async () => {
        setLoading(true);

        const images = await Promise.all([
            new Promise(resolve => {
                toPng(badgeLeft.current, {cacheBust: true,})
                    .then((dataUrl) => {
                        resolve(dataUrl);
                    });
            }),
            new Promise(resolve => {
                toPng(badgeRight.current, {cacheBust: true,})
                    .then((dataUrl) => {
                        resolve(dataUrl);
                    });
            })
        ]);

        if(bOrientation === 'horizontal'){
            images[0] = await rotate90(images[0]);
            images[1] = await rotate90(images[1]);
        }

        if (!App.isProduction()) {
            doDownload(images[0], 'badge-left.png');
        }

        // Attempt to send the image content downwards.
        await new Promise(resolve => {
            const xhr = new XMLHttpRequest();

            xhr.open("POST", "http://localhost:5000/");
            xhr.onreadystatechange = function () {
                if (xhr.readyState === XMLHttpRequest.DONE) {
                    if (xhr.status === 200) {
                        setSuccess(true);
                    } else if (xhr.status === 0) {
                        setError(true);
                    }

                    setLoading(false);
                    resolve();
                }
            }
            xhr.send(images[0]);
        });

        setLoading(false);
    };


    /**
     * The padded employee ID for display.
     */
    const paddedId = String(employee.id || '').padStart(6, '0');


    /**
     * The loaded employee's assigned specialty / discipline.
     */
    const specialty = {
        ...employee.specialty
    };


    /**
     * The base width of the badge to proportionally scale everything from.
     */
    const baseWidth = 300 * zoom;


    /**
     * Indicates whether we should show the primary color picker.
     */
    const primaryColorOpen = Boolean(primaryColorAnchorEl);


    /**
     * Indicates whether we should show the secondary color picker.
     */
    const secondaryColorOpen = Boolean(secondaryColorAnchorEl);


    /**
     * The orientation of the badge.
     *
     * @type {string}
     */
    const bOrientation = template === 'DNA' ? 'horizontal' : 'vertical';


    /**
     * The front of the badge.
     *
     * @returns {Element}
     * @constructor
     * @param baseWidth
     * @param zoom
     * @param ref
     */
    const getBadgeFrontContent = (baseWidth, zoom, ref = null) => {
        const badgeStyles = bOrientation === 'horizontal' ? {
            height: baseWidth,
            width: baseWidth * heightRatio,
        } : {
            width: baseWidth,
            height: baseWidth * heightRatio,
        }

        if (template === 'DNA') {
            return (
                <Paper
                    ref={ref}
                    style={{
                        ...badgeStyles,
                        paddingTop: '3em',
                    }}
                    className={'employee__badge'}
                >
                    <div className={'secondary__banner'}/>
                    <div className={'secondary__banner--inner'}/>

                    <div
                        style={{
                            top: '40%',
                            left: 0,
                            width: 140 * zoom * imageZoom,
                            height: 140 * zoom * imageZoom,
                            position: 'absolute',
                            borderWidth: 4 * zoom,
                            marginBottom: '1.2em',
                        }}
                        children={
                            !!employee.image ?
                                <img src={API.getFilePath(employee.image)}/> :
                                null
                        }
                        className={`badge__image ${forceFit ? 'badge__image--force' : (fitToHeight ? 'badge__image--height' : '')}`}
                    />

                    <div
                        style={{
                            top: '40%',
                            right: 0,
                            width: '70%',
                            position: 'absolute',
                        }}
                        className={'text__center'}
                    >
                        <div
                            style={{
                                color: '#cc0100',
                                display: 'inline-block',
                                fontSize: `${2.7 * firstNameScale}em`,
                                fontWeight: 'bold',
                                marginBottom: '0.3em',
                                letterSpacing: 0,
                                textTransform: 'uppercase',
                            }}
                            children={employee.firstName}
                        />
                        <div
                            style={{
                                fontSize: `${1.2 * lastNameScale}em`,
                                letterSpacing: 0,
                            }}
                            children={specialty.name}
                        />
                    </div>

                    <Box
                        sx={{
                            fontSize: `${1.1 * specialtyScale}em`,
                            letterSpacing: 0,
                        }}
                        children={'Managed Service Provider (MSP)'}
                        className={'specialty__banner'}
                    />

                    <div
                        style={{
                            right: '5%',
                            width: '45.5%',
                            bottom: 0,
                            position: 'absolute',
                        }}
                        children={<img src={'/assets/images/dna.jpeg'}/>}
                    />
                </Paper>
            );
        }

        return (
            <Paper
                ref={ref}
                style={{
                    ...badgeStyles,
                    paddingTop: '3em',
                }}
                className={'employee__badge'}
            >
                <div
                    style={{background: primaryColor}}
                    className={'secondary__banner'}
                />

                <div
                    style={{position: 'relative'}}
                    className={'text__center'}
                >
                    <div
                        style={{
                            width: 160 * zoom * imageZoom,
                            height: 160 * zoom * imageZoom,
                            borderWidth: 4 * zoom,
                            marginBottom: '1.2em'
                        }}
                        children={
                            !!employee.image ?
                                <img src={API.getFilePath(employee.image)}/> :
                                null
                        }
                        className={`badge__image ${forceFit ? 'badge__image--force' : (fitToHeight ? 'badge__image--height' : '')}`}
                    />
                    <div
                        style={{
                            fontSize: `${2.3 * firstNameScale}em`,
                            fontWeight: 'bold',
                            marginBottom: '0.3em',
                            letterSpacing: 2 * zoom,
                            textTransform: 'uppercase',
                        }}
                        children={employee.firstName}
                    />
                    <div
                        style={{
                            fontSize: `${1.4 * lastNameScale}em`,
                            letterSpacing: 2 * zoom
                        }}
                        children={employee.lastName}
                    />
                </div>

                <div className={'badge__bottom'}>
                    <div className={'d-flex__center mb__2'}>
                        <div style={{width: '35%'}}>
                            <img src={'/favicon.png'}/>
                        </div>
                        <div className={'flex__grow'}>
                            <div
                                style={{
                                    fontSize: '1.2em',
                                    marginBottom: '0.2em'
                                }}
                                children={<b>{Settings.applicationName}</b>}
                            />
                            <div
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '0.8em',
                                    opacity: 0.8
                                }}
                                children={Settings.phoneNumber}
                            />
                            <div
                                style={{
                                    fontWeight: 'bold',
                                    fontSize: '0.8em',
                                    opacity: 0.8
                                }}
                                children={`ID: #${paddedId}`}
                            />
                        </div>
                    </div>

                    <Box
                        sx={{
                            background: secondaryColor,
                            fontSize: `${2.5 * specialtyScale}em`,
                        }}
                        children={specialty.name}
                        className={'specialty__banner'}
                    />
                </div>
            </Paper>
        );
    };


    /**
     * The back of the badge.
     *
     * @param baseWidth
     * @param zoom
     * @param ref
     * @returns {Element}
     * @constructor
     */
    const getBadgeBackContent = (baseWidth, zoom, ref = null) => {
        const badgeStyles = bOrientation === 'horizontal' ? {
            height: baseWidth,
            width: baseWidth * heightRatio,
        } : {
            width: baseWidth,
            height: baseWidth * heightRatio,
        }

        if (template === 'DNA') {
            return (
                <Paper
                    ref={ref}
                    style={{
                        ...badgeStyles,
                        paddingTop: '3em',
                    }}
                    className={'employee__badge'}
                >
                    <Box className={'secondary__banner'}/>
                    <Box className={'secondary__banner--inner'}/>

                    <Box sx={{
                        top: '19%',
                        right: '13%',
                        position: 'absolute',
                    }}>
                        <Box className={'badge__image'} sx={{
                            width: 180 * zoom,
                            height: 180 * zoom,
                            borderWidth: 4 * zoom,
                            marginBottom: '1.2em'
                        }}>
                            <QRCode
                                size={512}
                                value={'qrco.de/bejZod'}
                                style={{height: "auto", maxWidth: "100%", width: "100%"}}
                                viewBox={`0 0 512 512`}
                            />
                        </Box>
                    </Box>
                </Paper>
            );
        }

        return (
            <Paper
                ref={ref}
                style={{
                    ...badgeStyles,
                    paddingTop: '3em',
                }}
                className={'employee__badge'}
            >
                <Box
                    sx={{background: primaryColor}}
                    className={'secondary__banner'}
                />

                <Box className={'text__center'} sx={{position: 'relative'}}>
                    <Box className={'badge__image'} sx={{
                        width: 205 * zoom,
                        height: 205 * zoom,
                        borderWidth: 4 * zoom,
                        marginBottom: '1.2em'
                    }}>
                        <QRCode
                            size={512}
                            value={'https://reliantstaffing.app/login'}
                            style={{height: "auto", maxWidth: "100%", width: "100%"}}
                            viewBox={`0 0 512 512`}
                        />
                    </Box>
                    <Box
                        sx={{
                            fontSize: '1.2em',
                            fontWeight: 'bold',
                            marginBottom: '0.3em',
                        }}
                        children={'Access Your Profile'}
                    />
                    <Box
                        sx={{
                            opacity: 0.6,
                            fontSize: '1.1em',
                            marginBottom: '1.75em'
                        }}
                        children={'Scan Using Your Phone'}
                    />

                    <Box
                        sx={{
                            fontSize: '1.1em',
                            fontWeight: 'bold',
                            lineHeight: '1.75em',
                        }}
                        children={'Employee Profile'}
                    />
                    <Box
                        sx={{
                            fontSize: '1.1em',
                            fontWeight: 'bold',
                            lineHeight: '1.75em',
                        }}
                        children={'Availability Form'}
                    />
                    <Box
                        sx={{
                            fontSize: '1em',
                            fontWeight: 'bold',
                            lineHeight: '1.75em',
                        }}
                        children={'Time Sheet'}
                    />
                </Box>
            </Paper>
        );
    };


    /**
     * Returns the high-resolution preview used for ultimately printing the badge.
     *
     * @returns {Element}
     */
    const getOutputPreview = () => {
        const zoom = outputWidth / 300;
        const baseWidth = 300 * zoom;

        return (
            <div
                className={`badge__wrapper  badge__wrapper--${template}`}
                style={{
                    position: 'relative',
                    fontSize: 16 * zoom,
                    lineHeight: '1.3em',
                    letterSpacing: zoom,
                }}
            >
                {/*Left / Front Preview*/}
                {getBadgeFrontContent(baseWidth, zoom, badgeLeft)}

                {/*Right / Back Preview*/}
                {getBadgeBackContent(baseWidth, zoom, badgeRight)}
            </div>
        );
    };

    return (
        <Page hideHeader fullScreen>
            <Box className={'page__heading'}>
                <Box className={'index__title d-flex__start'}>
                    <BadgeIcon/>
                    <h2>Badge Designer</h2>
                </Box>
                <Box className={'d-flex__start'}>
                    <FormControl sx={{width: 150}} className={'mr__2'}>
                        <InputLabel>Template</InputLabel>
                        <Select
                            size={'small'}
                            value={template}
                            label="Template"
                            onChange={event => setTemplate(event.target.value)}
                            fullWidth
                        >
                            <MenuItem value={"Reliant"}>Reliant</MenuItem>
                            <MenuItem value={"DNA"}>DNA</MenuItem>
                        </Select>
                    </FormControl>

                    <IconButton
                        onClick={doPrint}
                        disabled={isLoading}
                        children={<PrintIcon/>}
                    />

                    <EmployeeActions
                        record={employee}
                        doReload={getEmployee}
                    />
                </Box>
            </Box>
            <Divider/>
            <Box className={'d-flex'}>
                <Box className={'flex__grow p__4 badge__workspace'}>
                    <div
                        className={`badge__wrapper badge__wrapper--${template}`}
                        style={{
                            position: 'relative',
                            fontSize: 16 * zoom,
                            lineHeight: '1.3em',
                            letterSpacing: zoom,
                        }}
                    >
                        {/*Left / Front Preview*/}
                        {getBadgeFrontContent(baseWidth, zoom)}

                        {/*Right / Back Preview*/}
                        {getBadgeBackContent(baseWidth, zoom)}
                    </div>
                </Box>
                <Box
                    sx={{
                        width: Settings.drawerWidth,
                        fontSize: '14px',
                        overflowY: 'scroll',
                        overflowX: 'hidden',
                        transition: '0.2s ease-in-out',
                    }}
                    className={'filter__drawer full__height--left'}
                >
                    <Accordion defaultExpanded>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <InputLabel>Background</InputLabel>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box className={'columns__1 columns--small'}>
                                {template === 'Reliant' ? (
                                    <>
                                        <FormControl>
                                            <Typography gutterBottom>
                                                Primary Color
                                            </Typography>

                                            <Button
                                                variant={'outlined'}
                                                onClick={handlePrimaryColorClick}
                                                disabled={isLoading}
                                                startIcon={
                                                    <Box
                                                        sx={{
                                                            width: '1em',
                                                            height: '1em',
                                                            background: primaryColor,
                                                            borderRadius: '3px'
                                                        }}
                                                    />
                                                }
                                            >
                                                {primaryColor}
                                            </Button>
                                            <Popover
                                                open={primaryColorOpen}
                                                onClose={handlePrimaryColorClose}
                                                anchorEl={primaryColorAnchorEl}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <ChromePicker
                                                    width={'100%'}
                                                    color={primaryColor || ''}
                                                    onChange={color => setPrimaryColor(color ? color.hex : '')}
                                                />
                                            </Popover>
                                        </FormControl>

                                        <FormControl>
                                            <Typography gutterBottom>
                                                Secondary Color
                                            </Typography>

                                            <Button
                                                variant={'outlined'}
                                                onClick={handleSecondaryColorClick}
                                                disabled={isLoading}
                                                startIcon={
                                                    <Box
                                                        sx={{
                                                            width: '1em',
                                                            height: '1em',
                                                            background: secondaryColor,
                                                            borderRadius: '3px'
                                                        }}
                                                    />
                                                }
                                            >
                                                {secondaryColor}
                                            </Button>
                                            <Popover
                                                open={secondaryColorOpen}
                                                onClose={handleSecondaryColorClose}
                                                anchorEl={secondaryColorAnchorEl}
                                                anchorOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'left',
                                                }}
                                            >
                                                <ChromePicker
                                                    width={'100%'}
                                                    color={secondaryColor || ''}
                                                    onChange={color => setSecondaryColor(color ? color.hex : '')}
                                                />
                                            </Popover>
                                        </FormControl>
                                    </>
                                ) : null}

                                <FormControl>
                                    <Typography gutterBottom>
                                        Zoom
                                    </Typography>
                                    <Slider
                                        min={0.6}
                                        max={1.25}
                                        step={0.01}
                                        value={zoom}
                                        disabled={isLoading}
                                        onChange={(event, value) => setZoom(value)}
                                    />
                                </FormControl>

                                <FormControl>
                                    <Box className={'d-flex__justify'}>
                                        <Typography gutterBottom>
                                            Resolution
                                        </Typography>
                                        <Chip label={'Experimental'} size={'small'} color={'warning'}/>
                                    </Box>
                                    <RadioGroup
                                        value={outputWidth}
                                        onChange={event => setOutputWidth(event.target.value)}
                                    >
                                        {[
                                            320,
                                            640,
                                            960,
                                        ].map((option, i) => (
                                            <FormControlLabel
                                                key={`resolution-${i}`}
                                                value={option}
                                                label={option}
                                                control={<Radio/>}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl>

                                <FormControl>
                                    <Box className={'d-flex__justify'}>
                                        <Typography gutterBottom>
                                            Height Ratio
                                        </Typography>
                                    </Box>
                                    <RadioGroup
                                        value={heightRatio}
                                        onChange={event => setHeightRatio(event.target.value)}
                                    >
                                        {[
                                            1.52,
                                            1.5707,
                                            1.62,
                                        ].map((option, i) => (
                                            <FormControlLabel
                                                key={`ratio-${i}`}
                                                value={option}
                                                label={option}
                                                control={<Radio/>}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <InputLabel>Image</InputLabel>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box className={'columns__1 columns--small'}>
                                <FormControl>
                                    <Typography gutterBottom>
                                        Fit to image height?
                                    </Typography>
                                    <Switch
                                        checked={fitToHeight}
                                        disabled={isLoading}
                                        onChange={event => setFitToHeight(event.target.checked)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <Typography gutterBottom>
                                        Force size to fit?
                                    </Typography>
                                    <Switch
                                        checked={forceFit}
                                        disabled={isLoading}
                                        onChange={event => setForceFit(event.target.checked)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <Typography gutterBottom>
                                        Size
                                    </Typography>
                                    <Slider
                                        min={0.5}
                                        max={1.5}
                                        step={0.01}
                                        value={imageZoom}
                                        disabled={isLoading}
                                        onChange={(event, value) => setImageZoom(value)}
                                    />
                                </FormControl>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon/>}
                        >
                            <InputLabel>Text</InputLabel>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Box className={'columns__1 columns--small'}>
                                <FormControl>
                                    <Typography gutterBottom>
                                        First Name
                                    </Typography>

                                    <Slider
                                        min={0.5}
                                        max={1.5}
                                        step={0.01}
                                        value={firstNameScale}
                                        disabled={isLoading}
                                        onChange={(event, value) => setFirstNameScale(value)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <Typography gutterBottom>
                                        {template === 'DNA' ? 'Specialty' : 'Last Name'}
                                    </Typography>
                                    <Slider
                                        min={0.5}
                                        max={1.5}
                                        step={0.01}
                                        value={lastNameScale}
                                        disabled={isLoading}
                                        onChange={(event, value) => setLastNameScale(value)}
                                    />
                                </FormControl>
                                <FormControl>
                                    <Typography gutterBottom>
                                        {template === 'DNA' ? 'MSP Banner' : 'Specialty'}
                                    </Typography>
                                    <Slider
                                        min={0.5}
                                        max={1.5}
                                        step={0.01}
                                        value={specialtyScale}
                                        disabled={isLoading}
                                        onChange={(event, value) => setSpecialtyScale(value)}
                                    />
                                </FormControl>
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Box>

            <Box
                sx={{
                    zIndex: '-1',
                    width: 1980,
                    height: 1024,
                    position: 'fixed'
                }}
                key={`BadgeEditor-preview-${index}-${template}`}
                children={getOutputPreview()}
            />

            <Snackbar open={isError} autoHideDuration={6000} onClose={() => setError(false)}>
                <Alert onClose={() => setError(false)} severity={'error'}>
                    Unable to communicate with the printer, please try again.
                </Alert>
            </Snackbar>

            <Snackbar
                open={isSuccess}
                onClose={() => setSuccess(false)}
                children={
                    <Alert onClose={() => setSuccess(false)} severity={'success'}>
                        Successfully sent badge images!
                    </Alert>
                }
                autoHideDuration={6000}
            />
        </Page>
    );
};

export default BadgeEditor;