import React, {useState} from "react";

import API from "../../../Global/API";
import Formatter from "../../../Global/Formatter";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import BadgeIcon from '@mui/icons-material/Badge';
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import SendToMobileIcon from '@mui/icons-material/SendToMobile';

/**
 * RecruitingTab component.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const RecruitingTab = (props) => {
    const {
        loading,            // {Boolean} Indicates if the outer form is loading.
        employee,           // {Object} The employee record.
        doReload,           // {Function} A callback to reload the employee record.
        readOnly,           // {Boolean} Indicates if the form is read-only.
    } = props;

    const [isLoading, setLoading] = useState(false);

    /**
     * Performs an update against the employee and emits a reload event.
     *
     * @param params
     * @returns {Promise<void>}
     */
    const doUpdate = async (params) => {
        setLoading(true);
        await API.put(`employees/${employee.id}`, params);
        setLoading(false);

        if(doReload){
            doReload();
        }
    }


    /**
     * Indicates if the inputs are disabled.
     *
     * @type {boolean}
     */
    const isDisabled = readOnly || loading || isLoading;

    return (
        <Box className={'columns__1 document__list'}>
            <Box>
                <Alert severity={'info'} icon={<BadgeIcon fontSize={'inherit'}/>}>
                    {!readOnly ? (
                        <Box className={'columns__1 columns--small'}>
                            <Box>
                                During the recruiting process, employees will move through the various statuses displayed
                                below. To streamline support for candidates, you may use this form to manually
                                move employees throughout the pipeline.
                            </Box>
                            <Box>
                                <b>Remember</b>: Steps marked with
                                a <SendToMobileIcon fontSize={'inherit'}/> icon will also trigger a text message, so please be aware
                                while making changes!
                            </Box>
                        </Box>
                    ) : (
                        <Box className={'columns__1 columns--small'}>
                            <Box>
                                During the recruiting process, employees will move through the various statuses displayed
                                below on their way towards employment.
                            </Box>
                            <Box>
                                <b>Notice</b>: Steps marked with
                                a <SendToMobileIcon fontSize={'inherit'}/> icon will also trigger a text message!
                            </Box>
                        </Box>
                    )}
                </Alert>

                <TableContainer className={'table table--striped'}>
                    <Table stickyHeader>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <h3 className={'m__0'}>Invited</h3>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell padding={'checkbox'}>
                                    <Checkbox
                                        onClick={() => doUpdate({
                                            isInvited: !employee.isInvited,
                                            interviewDate: employee.interviewDate ?
                                                employee.interviewDate :
                                                dayjs().utc().format('YYYY-MM-DD HH:mm:ss')
                                        })}
                                        checked={!!employee.isInvited}
                                        disabled={isDisabled}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Box className={'d-flex__justify'}>
                                        <Box>Employee Invited</Box>
                                        <SendToMobileIcon
                                            fontSize={'small'}
                                            className={'text__light'}
                                        />
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell padding={'checkbox'}>
                                    <Checkbox
                                        onClick={() => doUpdate({
                                            lastLoginDate: !!employee.lastLoginDate ? '' : dayjs().utc().format('YYYY-MM-DD HH:mm:ss')
                                        })}
                                        checked={!!employee.lastLoginDate}
                                        disabled={isDisabled}
                                    />
                                </TableCell>
                                <TableCell>
                                    Employee Logged In
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell padding={'checkbox'}>
                                    <Checkbox
                                        onClick={() => doUpdate({
                                            inviteCompletedDate: !!employee.inviteCompletedDate ? '' : dayjs().utc().format('YYYY-MM-DD HH:mm:ss')
                                        })}
                                        checked={!!employee.inviteCompletedDate}
                                        disabled={isDisabled}
                                    />
                                </TableCell>
                                <TableCell>
                                    Invite Completed
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <h3 className={'m__0'}>In Progress</h3>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell padding={'checkbox'}>
                                    <Checkbox
                                        onClick={() => doUpdate({
                                            isInviteAccepted: !employee.isInviteAccepted
                                        })}
                                        checked={!!employee.isInviteAccepted}
                                        disabled={isDisabled}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Box className={'d-flex__justify'}>
                                        <Box>Invite Accepted</Box>
                                        <SendToMobileIcon
                                            fontSize={'small'}
                                            className={'text__light'}
                                        />
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell padding={'checkbox'}>
                                    <Checkbox
                                        onClick={() => doUpdate({
                                            offerLetterDate: !!employee.offerLetterDate ? '' : dayjs().utc().format('YYYY-MM-DD HH:mm:ss')
                                        })}
                                        checked={!!employee.offerLetterDate}
                                        disabled={isDisabled}
                                    />
                                </TableCell>
                                <TableCell>
                                    Disclosures Signed
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell padding={'checkbox'}>
                                    <Checkbox
                                        onClick={() => doUpdate({
                                            availabilityDate: !!employee.availabilityDate ? '' : dayjs().utc().format('YYYY-MM-DD HH:mm:ss')
                                        })}
                                        checked={!!employee.availabilityDate}
                                        disabled={isDisabled}
                                    />
                                </TableCell>
                                <TableCell>
                                    Availability Submitted
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell padding={'checkbox'}>
                                    <Checkbox
                                        onClick={() => doUpdate({
                                            hirePacketDate: !!employee.hirePacketDate ? '' : dayjs().utc().format('YYYY-MM-DD HH:mm:ss')
                                        })}
                                        checked={!!employee.hirePacketDate}
                                        disabled={isDisabled}
                                    />
                                </TableCell>
                                <TableCell>
                                    Hire Packet Completed
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell padding={'checkbox'}>
                                    <Checkbox
                                        onClick={() => doUpdate({
                                            trainingDate: !!employee.trainingDate ? '' : dayjs().utc().format('YYYY-MM-DD HH:mm:ss')
                                        })}
                                        checked={!!employee.trainingDate}
                                        disabled={isDisabled}
                                    />
                                </TableCell>
                                <TableCell>
                                    <Box className={'d-flex__justify'}>
                                        <Box>Training Completed</Box>

                                        {false && !!employee.trainingDate && (
                                            <Chip
                                                size={'small'}
                                                label={Formatter.dateTime(employee.trainingDate)}
                                                color={'info'}
                                            />
                                        )}
                                    </Box>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell padding={'checkbox'}>
                                    <Checkbox
                                        onClick={() => doUpdate({
                                            drugScreenDate: !!employee.drugScreenDate ? '' : dayjs().utc().format('YYYY-MM-DD HH:mm:ss')
                                        })}
                                        checked={!!employee.drugScreenDate}
                                        disabled={isDisabled}
                                    />
                                </TableCell>
                                <TableCell>
                                    Drug Screen Ordered
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell padding={'checkbox'}>
                                    <Checkbox
                                        onClick={() => doUpdate({
                                            drugScreenReceivedDate: !!employee.drugScreenReceivedDate ? '' : dayjs().utc().format('YYYY-MM-DD HH:mm:ss')
                                        })}
                                        checked={!!employee.drugScreenReceivedDate}
                                        disabled={isDisabled}
                                    />
                                </TableCell>
                                <TableCell>
                                    Drug Screen Received
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell padding={'checkbox'}>
                                    <Checkbox
                                        onClick={() => doUpdate({
                                            seenConfetti: !employee.seenConfetti
                                        })}
                                        checked={!!employee.seenConfetti}
                                        disabled={isDisabled}
                                    />
                                </TableCell>
                                <TableCell>
                                    Confetti Seen
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <h3 className={'m__0'}>Hired</h3>
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell padding={'checkbox'}>
                                    <Checkbox
                                        onClick={() => doUpdate({
                                            isActive: !employee.isActive,
                                        })}
                                        checked={!!employee.isActive}
                                        disabled={isDisabled}
                                    />
                                </TableCell>
                                <TableCell>
                                    Employee Activated
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell padding={'checkbox'}>
                                    <Checkbox
                                        onClick={() => doUpdate({
                                            hasDirectDeposit: !employee.hasDirectDeposit,
                                        })}
                                        checked={!!employee.hasDirectDeposit}
                                        disabled={isDisabled}
                                    />
                                </TableCell>
                                <TableCell>
                                    Direct Deposit Submitted
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>
        </Box>
    );
};

export default RecruitingTab;