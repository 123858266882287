import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import Page from "../../Components/Page";
import Status from "../../Models/Status";
import Settings from "../../Global/Settings";
import PinnedMap from "./Map/PinnedMap";
import Specialty from "../../Models/Specialty";
import useDebounce from "../../Hooks/useDebounce";
import ModalButton from "../../Components/ModalButton";
import ModelSearch from "../../Components/Input/ModelSearch";
import BannerAlert from "../../Components/Typography/BannerAlert";
import EmployeeView from "./Employee/EmployeeView";
import DialogHeading from "../../Components/Typography/DialogHeading";
import GroupChatDialog from "../../Components/Widgets/GroupChatDialog";
import EmployeeListItem from "../../Components/Lists/EmployeeListItem";
import EmployeeSelection from "../../Components/Input/EmployeeSelection";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import MapIcon from '@mui/icons-material/Map';
import MenuItem from "@mui/material/MenuItem";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import InputLabel from "@mui/material/InputLabel";
import SearchIcon from "@mui/icons-material/Search";
import {useParams} from "react-router";
import FormControl from "@mui/material/FormControl";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";
import FilterListIcon from '@mui/icons-material/FilterList';
import CircularProgress from "@mui/material/CircularProgress";
import Chip from "@mui/material/Chip";

/**
 * The various shift types available.
 *
 * @type {string[]}
 */
const shiftTypes = [
    'Morning',
    'Afternoon',
    'Evening',
    'Weekend'
];


/**
 * Map component.
 *
 * @returns {*}
 * @constructor
 */
const Map = () => {
    const {latitude, longitude} = useParams();
    const [address, setAddress] = useState('');
    const [statuses, setStatuses] = useState([]);
    const [distance, setDistance] = useState(10);
    const [isLoading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [isFiltering, setFiltering] = useState(false);
    const [preferences, setPreferences] = useState([]);
    const [specialties, setSpecialties] = useState([]);
    const [filterResults, setFilterResults] = useState([]);
    const [sendingMessage, setSendingMessage] = useState(false);
    const [filterSelection, setFilterSelection] = useState([]);
    const [defaultLatitude, setDefaultLatitude] = useState(latitude);
    const [defaultEmployee, setDefaultEmployee] = useState({});
    const [defaultLongitude, setDefaultLongitude] = useState(longitude);
    const [filterCoordinates, setFilterCoordinates] = useState({});

    /**
     * Loads any additional data on mount.
     */
    useEffect(() => {
        getDefaultEmployee();
    }, []);


    /**
     * Fetches employees by address on filter input.
     */
    useEffect(() => {
        debounceFilterResults();
    }, [address, distance])


    /**
     * Closes the group message dialog.
     */
    const handleMessageClose = () => {
        setSendingMessage(false);
    };


    /**
     * Reveals the group message dialog.
     */
    const handleMessageOpen = () => {
        setSendingMessage(true);
    };


    /**
     * Fetches employees by distance on filter input.
     *
     * @returns {Promise<void>}
     */
    const getEmployeesByAddress = async () => {
        if (!address) {
            setFilterResults([])
            return;
        }

        setLoading(true);
        const response = await API.post('reports/employees-by-distance', {address, distance})
        setFilterResults(response.results)
        setFilterSelection([]);
        setFilterCoordinates(response.coordinates)
        setLoading(false);
    };


    const filteredResults = filterResults.filter(employee => {
        let match = true;

        const {status, specialty, shiftPreferences} = employee;

        const statusJson = status || {}
        const specialtyJson = specialty || {}
        const shiftPreferenceJson = JSON.parse(shiftPreferences || '{}');

        if (specialties.length) {
            match = specialties.some(selected => selected.id === specialtyJson.id);
        }

        if (match && statuses.length) {
            match = statuses.some(selected => selected.id === statusJson.id);
        }

        if (match && preferences.length) {
            match = preferences.some(selected => shiftPreferenceJson[selected]);
        }

        return match;
    })


    /**
     * Changes the employee selection.
     *
     * @param employees
     * @param action
     */
    const handleEmployeeSelect = (employees, action) => {
        setEmployees(employees);

        if (action === 'single-add' && employees && employees.length) {
            const employee = employees[0];

            setDefaultLatitude(employee.latitude);
            setDefaultLongitude(employee.longitude);
        }
    };


    /**
     * Loads the default employee record ID.
     *
     * @returns {Promise<void>}
     */
    const defaultEmployeeId = (() => {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        return urlParams.get('employeeId');
    })();


    /**
     * Handles open / closing the filter modal.
     */
    const handleFilterOpen = () => setFiltering(true)
    const handleFilterClose = () => setFiltering(false)


    /**
     * Fetches the context employee if navigating from a menu, profile, etc.
     *
     * @returns {Promise<void>}
     */
    const getDefaultEmployee = async () => {
        if (!defaultEmployeeId) {
            return;
        }

        setDefaultEmployee(
            await API.get(`employees/${defaultEmployeeId}`)
        );
    };


    /**
     * Indicates whether there is a selection.
     *
     * @type {boolean}
     */
    const hasSelection = !!filterSelection && filterSelection.length > 0;


    /**
     * Indicates if all are selected.
     *
     * @type {boolean}
     */
    const allSelected = hasSelection && filterSelection.length === employees.length;


    /**
     * Indicates if the selection is indeterminate.
     *
     * @type {boolean}
     */
    const isIndeterminate = hasSelection && !allSelected;


    /**
     * Handles the employee change.
     *
     * @param employee
     */
    const handleFilterSelectionChange = (employee) => {
        let updated = [];
        let isSelected = filterSelection.some(record => record.id === employee.id);

        // Assign the new selection.
        if (!isSelected) {
            updated = [...filterSelection, employee];
        } else {
            updated = filterSelection.filter(record => record.id !== employee.id);
        }

        setFilterSelection(updated);
    };


    /**
     * Handles the global selection change (the table header checkbox).
     */
    const handleFilterSelectAll = () => {
        let updatedSelection = [];

        if (isIndeterminate) {
            // Clear the selection.
        } else if (allSelected) {
            // Clear the selection.
        } else {
            updatedSelection = [...filteredResults];
        }

        setFilterSelection(updatedSelection);
    };


    /**
     * Handles search suggestion debounce.
     */
    const debounceFilterResults = useDebounce(async () => {
        getEmployeesByAddress();
    });


    /**
     * Handles the view on map action.
     */
    const handleViewOnMap = () => {
        setEmployees(filterSelection);

        if (filterCoordinates.latitude && filterCoordinates.longitude) {
            setDefaultLatitude(filterCoordinates.latitude);
            setDefaultLongitude(filterCoordinates.longitude);
        }

        setFiltering(false);
    };

    const handlePreferenceChange = (event) => {
        const {
            target: { value },
        } = event;

        setPreferences(
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        );
    };

    return (
        <Page hideHeader fullScreen>
            <Box className={'page__heading'}>
                <Box className={'index__title d-flex__justify w__100'}>
                    <Box className={'d-flex__start'}>
                        <MapIcon/>
                        <h2>Map</h2>
                    </Box>
                    <Box className={'d-flex__start'}>
                        <BannerAlert severity={'info'} className={'text__small'}>
                            Only records with valid addresses are displayed.
                        </BannerAlert>
                        <Button
                            sx={{
                                '& svg': {
                                    marginRight: 0
                                }
                            }}
                            variant={'link'}
                            onClick={handleFilterOpen}
                            children={<FilterListIcon/>}
                            className={'layout__control'}
                        />
                    </Box>
                </Box>
            </Box>
            <Divider/>
            <Box className={'d-flex'}>
                <Box className={'two-column__border-right'}>
                    <Box sx={{width: Settings.drawerWidth}}>
                        <EmployeeSelection
                            filter={search => `latitude ne {} and isDeleted eq {0} and displayName eq {${search}}`}
                            onChange={handleEmployeeSelect}
                            selection={defaultEmployeeId ? [defaultEmployeeId] : []}
                            defaultSearch={defaultEmployee.displayName || ''}
                        />
                    </Box>
                </Box>

                <Box className={`two-column__right`}>
                    <PinnedMap
                        latitude={defaultLatitude ? parseFloat(defaultLatitude) : null}
                        longitude={defaultLongitude ? parseFloat(defaultLongitude) : null}
                        mapElement={<div style={{height: `100%`}}/>}
                        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${Settings.googleApiKey}&v=3.exp&libraries=geometry,drawing,places`}
                        loadingElement={<div style={{height: `100%`}}/>}
                        filterEmployees={employees}
                        containerElement={<div style={{height: `100%`}}/>}
                    />
                </Box>
            </Box>

            {isFiltering && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handleFilterClose}
                    maxWidth={'lg'}
                    fullWidth
                >
                    <DialogHeading
                        title={!filteredResults.length ? 'Filter by Location' : `Filter by Location (${filteredResults.length})`}
                        noMargin
                    />
                    <DialogContent>
                        <Box className={'columns__1'}>
                            <Box className={'columns__2'} sx={{gridTemplateColumns: '0.75fr 0.25fr'}}>
                                <Box className={'search__wrapper'}>
                                    <TextField
                                        value={address}
                                        label={'Address'}
                                        required
                                        fullWidth
                                        onChange={event => setAddress(event.target.value)}
                                    />

                                    <Box className={'search__icon'} sx={{marginTop: '0.5em', marginRight: '0.4em'}}>
                                        {isLoading ? (
                                            <CircularProgress
                                                color={'inherit'}
                                            />
                                        ) : <SearchIcon/>}
                                    </Box>
                                </Box>

                                <TextField
                                    type={'number'}
                                    value={distance}
                                    label={'Distance (mi.)'}
                                    required
                                    fullWidth
                                    onChange={event => setDistance(event.target.value)}
                                />
                            </Box>
                            <Box className={'columns__3'}>
                                <ModelSearch
                                    model={Specialty}
                                    value={specialties}
                                    label={'Specialties'}
                                    multiple
                                    disabled={isLoading}
                                    onChange={setSpecialties}
                                    renderLabel={option => !option ? '' : option.name}
                                    filterQuery={query => `isDeleted eq {0} and name eq {${query}}`}
                                />

                                <ModelSearch
                                    model={Status}
                                    value={statuses}
                                    label={'Statuses'}
                                    multiple
                                    disabled={isLoading}
                                    onChange={setStatuses}
                                    renderLabel={option => !option ? '' : option.name}
                                    filterQuery={query => `isDeleted eq {0} and name eq {${query}}`}
                                />

                                <FormControl>
                                    <InputLabel>Shift Preferences</InputLabel>
                                    <Select
                                        value={preferences}
                                        label={'Shift Preferences'}
                                        disabled={isLoading}
                                        multiple
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                                {selected.map((value) => (
                                                    <Chip key={value} label={value} />
                                                ))}
                                            </Box>
                                        )}
                                        sx={!!preferences.length ? {
                                            '& .MuiSelect-select': {
                                                padding: '1em'
                                            }
                                        } : {}}
                                        onChange={handlePreferenceChange}
                                    >
                                        {shiftTypes.map((option) =>
                                            <MenuItem
                                                key={option}
                                                value={option}
                                                children={option}
                                            />
                                        )}
                                    </Select>
                                </FormControl>
                            </Box>
                        </Box>
                    </DialogContent>
                    {isLoading ? <LinearProgress/> : null}

                    {(!!filteredResults.length || (!!address && !isLoading)) && (
                        <>
                            {!isLoading ? <Divider/> : null}

                            <TableContainer className={'table table--striped result__set'} sx={{maxHeight: 500, overflowY: 'scroll'}}>
                                <Table size={'small'}>
                                    <colgroup>
                                        <col width={'24px'}/>
                                        <col/>
                                        <col width={250}/>
                                    </colgroup>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell sx={{padding: 0, paddingLeft: '0.5em'}}>
                                                <Checkbox
                                                    checked={allSelected}
                                                    onChange={handleFilterSelectAll}
                                                    indeterminate={isIndeterminate}
                                                />
                                            </TableCell>
                                            <TableCell>
                                                Employee
                                            </TableCell>
                                            <TableCell>
                                                Distance
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {!filteredResults.length && (
                                            <TableRow>
                                                <TableCell
                                                    align={'center'}
                                                    colSpan={3}
                                                    className={'text__disclaimer text__center'}
                                                >
                                                    {isLoading ? 'Loading, please wait...' : 'No results available.'}
                                                </TableCell>
                                            </TableRow>
                                        )}

                                        {filteredResults.map(employee => {
                                            return [
                                                <TableRow key={`employee-${employee.id}`}>
                                                    <TableCell sx={{paddingRight: 0, paddingLeft: '0.5em'}}
                                                        className={'v-align__top'}>
                                                        <Checkbox
                                                            checked={hasSelection && filterSelection.some(record => record.id === employee.id)}
                                                            onChange={() => handleFilterSelectionChange(employee)}
                                                        />
                                                    </TableCell>
                                                    <TableCell sx={{padding: 0}} className={'v-align__top'}>
                                                        <ModalButton
                                                            label={
                                                                <EmployeeListItem
                                                                    onSelect={() => true}
                                                                    employee={employee}
                                                                />
                                                            }
                                                            bodyStyle={{
                                                                paddingTop: 0
                                                            }}
                                                            children={
                                                                <EmployeeView id={employee.id}/>
                                                            }
                                                        />
                                                    </TableCell>
                                                    <TableCell>
                                                        {employee.distance.toFixed(2)} mi.
                                                    </TableCell>
                                                </TableRow>,
                                            ];
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Divider/>
                            <DialogActions>
                                <Button
                                    onClick={handleViewOnMap}
                                    children={'Compare on Map'}
                                    disabled={isLoading || !filterSelection.length}
                                />
                                <Button
                                    onClick={handleMessageOpen}
                                    disabled={isLoading || !filterSelection.length}
                                    children={`Send Message (${filterSelection.length})`}
                                />
                            </DialogActions>
                        </>
                    )}
                </Dialog>
            )}

            <GroupChatDialog
                open={sendingMessage}
                onClose={handleMessageClose}
                selection={filterSelection}
            />
        </Page>
    );
};

export default Map;