import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import Settings from "../../../Global/Settings";
import Formatter from "../../../Global/Formatter";
import ImageWrapper from "../../../Components/ImageWrapper";

import Box from "@mui/material/Box";

import {
    Marker,
    GoogleMap,
    InfoWindow,
    withScriptjs,
    withGoogleMap,
} from "react-google-maps";

/**
 * The number of results or page to sequentially pull data.
 *
 * @type {number}
 */
const perPageLimit = 100;


/**
 * PinnedMap component.
 *
 * @returns {*}
 * @constructor
 */
const PinnedMap = withScriptjs(withGoogleMap((props) => {
    const {
        latitude,                   // {Float} The default latitude of the center.
        longitude,                  // {Float} The default longitude of the center.
        filterEmployees             // {Array} An array of employee ID's to filter pins by.
    } = props;

    const [index, setIndex] = useState(0);
    const [target, setTarget] = useState({});
    const [clients, setClients] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [showInfoWindow, setShowInfoWindow] = useState(false);

    const hasFilterSelection = filterEmployees && filterEmployees.length;
    const filterEmployeeIds = hasFilterSelection ? filterEmployees.map(employee => employee.id) : [];
    const filteredEmployees = !hasFilterSelection ? employees : employees.filter(employee => filterEmployeeIds.includes(employee.id));
    const calculatedLatitude = hasFilterSelection && filterEmployees.length === 1 ? parseFloat(filterEmployees[0].latitude) : (latitude || Settings.officeLocation.latitude);
    const calculatedLongitude = hasFilterSelection && filterEmployees.length === 1 ? parseFloat(filterEmployees[0].longitude) : (longitude || Settings.officeLocation.longitude);

    const [defaultLatitude, setDefaultLatitude] = useState(calculatedLatitude);
    const [defaultLongitude, setDefaultLongitude] = useState(calculatedLongitude);

    /**
     * Load all required data on mount.
     */
    useEffect(() => {
        getResults();
    }, []);


    /**
     * Synchronize the selection to the center point.
     */
    useEffect(() => {
        setIndex(index + 1);
        setDefaultLatitude(calculatedLatitude);
        setDefaultLongitude(calculatedLongitude);
    }, [calculatedLatitude, calculatedLongitude]);


    /**
     * Loads all of the client rows.
     *
     * @returns {Promise<void>}
     */
    const getAllClients = async () => {
        let promises = [];

        // Load all the clients page by page until we've reached the end.
        const count = await API.get('clients/count', {
            $top: perPageLimit,
            $filter: 'isDeleted eq {0} and latitude ne {}'
        });

        for (let page = 0; page < count.pages; page++) {
            promises.push(
                API.get('clients', {
                    $top: perPageLimit,
                    $skip: page * perPageLimit,
                    $expand: 'state',
                    $filter: 'isDeleted eq {0} and latitude ne {}'
                })
            );
        }

        const results = await Promise.all(promises);
        setClients(results
            .flat()
            .filter(result => result.latitude && result.longitude)
            .map(result => {
                return {
                    ...result,
                    latitude: parseFloat(result.latitude),
                    longitude: parseFloat(result.longitude),
                }
            })
        );
    };


    /**
     * Loads all of the employee rows.
     *
     * @returns {Promise<void>}
     */
    const getAllEmployees = async () => {
        let promises = [];

        // Load all the clients page by page until we've reached the end.
        const count = await API.get('employees/count', {
            $top: perPageLimit,
            $filter: 'isDeleted eq {0} and latitude ne {}'
        });

        for (let page = 0; page < count.pages; page++) {
            promises.push(
                API.get('employees', {
                    $top: perPageLimit,
                    $skip: page * perPageLimit,
                    $expand: 'state',
                    $filter: 'isDeleted eq {0} and latitude ne {}'
                })
            );
        }

        const results = await Promise.all(promises);
        setEmployees(results
            .flat()
            .filter(result => result.latitude && result.longitude)
            .map(result => {
                return {
                    ...result,
                    latitude: parseFloat(result.latitude),
                    longitude: parseFloat(result.longitude),
                }
            })
        );
    };


    /**
     * Loads all of the report data.
     *
     * @returns {Promise<void>}
     */
    const getResults = async () => {
        setLoading(true);

        await Promise.all([
            getAllClients(),
            getAllEmployees(),
        ]);

        setLoading(false);
    };


    /**
     * Displays the tooltip on hover.
     *
     * @param event
     * @param target
     */
    const handleMouseOver = (event, target) => {
        setTarget(target);
        setShowInfoWindow(true);
    };


    /**
     * Closes the tooltip on exit.
     *
     * @param event
     */
    const handleMouseExit = event => {
        setTarget({});
        setShowInfoWindow(false);
    };


    /**
     * The center point of the map.
     *
     * @type {{lng: any, lat: any}}
     */
    const defaultCenter = {
        lat: defaultLatitude,
        lng: defaultLongitude
    };

    return (
        <GoogleMap
            key={`google-maps-${index}`}
            defaultZoom={13}
            defaultCenter={defaultCenter}
        >
            {clients.map(client => (
                <Marker
                    icon={{
                        url: '/assets/markers/location_green.png',
                        scaledSize: new window.google.maps.Size(27.5, 44.5)
                    }}
                    position={{
                        lat: client.latitude,
                        lng: client.longitude
                    }}
                    onMouseOut={handleMouseExit}
                    onMouseOver={event => handleMouseOver(event, {
                        id: client.id,
                        type: 'Client',
                    })}
                >
                    {showInfoWindow && target.id === client.id && target.type === 'Client' && (
                        <InfoWindow>
                            <Box className={'d-flex__start'}>
                                <ImageWrapper
                                    src={API.getFilePath(client.image)}
                                    width={32}
                                    height={32}
                                    className={'mr__2'}
                                    horizontal
                                />
                                <Box>
                                    <h3 className={'m__0 mb__1'}>
                                        {client.name || '(no name)'}
                                    </h3>
                                    <Box className={'text__small text__light'}>
                                        {Formatter.address(
                                            client.addressLine1 || '',
                                            client.addressLine2 || '',
                                            '',
                                            client.city || '',
                                            client.state && client.state.abbreviation ? client.state.abbreviation : '',
                                            client.postalCode || ''
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </InfoWindow>
                    )}
                </Marker>
            ))}

            {filteredEmployees.map(employee => (
                <Marker
                    icon={{
                        url: '/assets/markers/location_red.png',
                        scaledSize: new window.google.maps.Size(27.5, 44.5)
                    }}
                    position={{
                        lat: employee.latitude,
                        lng: employee.longitude
                    }}
                    onMouseOut={handleMouseExit}
                    onMouseOver={event => handleMouseOver(event, {
                        id: employee.id,
                        type: 'Employee',
                    })}
                >
                    {showInfoWindow && target.id === employee.id && target.type === 'Employee' && (
                        <InfoWindow>
                            <Box className={'d-flex__start'}>
                                <ImageWrapper
                                    src={API.getFilePath(employee.image)}
                                    width={32}
                                    height={32}
                                    className={'mr__2'}
                                />
                                <Box>
                                    <h3 className={'m__0 mb__1'}>
                                        {employee.displayName || '(no name)'}
                                    </h3>
                                    <Box className={'text__small text__light'}>
                                        {Formatter.address(
                                            employee.addressLine1 || '',
                                            employee.addressLine2 || '',
                                            '',
                                            employee.city || '',
                                            employee.state && employee.state.abbreviation ? employee.state.abbreviation : '',
                                            employee.postalCode || ''
                                        )}
                                    </Box>
                                </Box>
                            </Box>
                        </InfoWindow>
                    )}
                </Marker>
            ))}
        </GoogleMap>
    );
}));

export default PinnedMap;