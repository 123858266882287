import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import User from "../../Models/User";
import Logger from "../../Global/Logger";
import InputPhone from "../../Components/Input/InputPhone";
import FileAvatar from "../../Components/Input/FileAvatar";
import ModelSearch from "../../Components/Input/ModelSearch";
import SmallButton from "../../Components/Widgets/CommunicationFeed/SmallButton";
import DialogHeading from "../../Components/Typography/DialogHeading";
import SectionHeading from "../../Components/Typography/SectionHeading";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import TextField from "@mui/material/TextField";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TableContainer from "@mui/material/TableContainer";
import FormControlLabel from "@mui/material/FormControlLabel";

/**
 * UserForm component.
 *
 * @returns {*}
 * @constructor
 */
const UserForm = (props) => {
    const {
        open,           // {Boolean} Indicates whether the form is open.
        record,         // {Object} The form context object, handles preloading the form.
        onSave,         // {Function} An onSave callback, triggered whenever the form is saved.,
        onClose,        // {Function} An onClose callback, triggered whenever the modal is closed.
    } = props;

    const [userId, setUserId] = useState('');
    const [fields, setFields] = useState(User.getInstance(record));
    const [isCopying, setCopying] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [permissions, setPermissions] = useState({});

    /**
     * Loads ancillary data for the form.
     */
    useEffect(() => {
        getPermissions();
    }, []);


    /**
     * All permissions available to the user.
     *
     * @type {Array<Object>}
     */
    const allPermissions = [
        {
            key: 'VIEW_CLIENTS',
            label: 'Ability to view clients.',
            children: [
                {
                    key: 'EDIT_CLIENTS',
                    label: 'Ability to create / edit clients.'
                }, {
                    key: 'DELETE_CLIENTS',
                    label: 'Ability to delete clients.'
                }, {
                    key: 'VIEW_CLIENT_FILES',
                    label: 'Ability to view client files.'
                }, {
                    key: 'VIEW_CLIENT_RATES',
                    label: 'Ability to view client rates.'
                }, {
                    key: 'EDIT_CLIENT_RATES',
                    label: 'Ability to edit client rates.'
                }
            ]
        }, {
            key: 'VIEW_EMPLOYEES',
            label: 'Ability to view employees.',
            children: [
                {
                    key: 'EDIT_EMPLOYEES',
                    label: 'Ability to create / edit employees.'
                }, {
                    key: 'DELETE_EMPLOYEES',
                    label: 'Ability to delete employees.'
                }, {
                    key: 'VIEW_EMPLOYEE_FILES',
                    label: 'Ability to view employee files.'
                }, {
                    key: 'VIEW_EMPLOYEE_RATES',
                    label: 'Ability to view employee rates.'
                }, {
                    key: 'EDIT_EMPLOYEE_RATES',
                    label: 'Ability to edit employee rates.'
                }
            ]
        }, {
            key: 'VIEW_SCHEDULE',
            label: 'Ability to view the schedule.',
            children: [
                {
                    key: 'EDIT_SCHEDULE',
                    label: 'Ability to create / edit shifts.'
                }, {
                    key: 'DELETE_SCHEDULE',
                    label: 'Ability to delete shifts.'
                }, {
                    key: 'VIEW_RECRUITING_BASIC',
                    label: 'Ability to view a read-only recruiting summary.'
                }
            ]
        }, {
            key: 'VIEW_RECRUITING',
            label: 'Ability to view the recruiting pipeline.',
            children: [
                {
                    key: 'VIEW_ALL_CANDIDATES',
                    label: 'Ability to view all recruiting candidates.'
                }
            ]
        }, {
            key: 'VIEW_LIBRARIES',
            label: 'Ability to view libraries (documents, specialties, etc.).',
            children: [
                {
                    key: 'EDIT_LIBRARIES',
                    label: 'Ability to create / edit records.'
                }, {
                    key: 'DELETE_LIBRARIES',
                    label: 'Ability to delete records.'
                }
            ]
        }, {
            key: 'VIEW_TRAINING',
            label: 'Ability to view training features.',
            children: [
                {
                    key: 'EDIT_TRAINING',
                    label: 'Ability to create / edit records.'
                }, {
                    key: 'DELETE_TRAINING',
                    label: 'Ability to delete records.'
                }
            ]
        }, {
            key: 'VIEW_COMMUNICATIONS',
            label: 'Ability to view communications (templates, campaigns, etc.).',
            children: [
                {
                    key: 'EDIT_COMMUNICATIONS',
                    label: 'Ability to create / edit records.'
                }, {
                    key: 'DELETE_COMMUNICATIONS',
                    label: 'Ability to delete records.'
                }
            ]
        }, {
            key: 'VIEW_REPORTS',
            label: 'Ability to view administrative reports (includes time sheets).',
            children: []
        }, {
            key: 'VIEW_SETTINGS',
            label: 'Ability to manage application settings.',
            children: []
        },
    ];


    /**
     * Loads all permissions for the current user.
     *
     * @returns {Promise<void>}
     */
    const getPermissions = async () => {
        if (!fields || !fields.id) {
            return;
        }

        const output = {};
        const permissions = await API.get('user-permissions', {
            $top: 250,
            $filter: `userId in {${fields.id}}`
        });

        permissions.map(permission => output[permission.permission] = permission);
        Logger.debug(`[UserForm] Loaded permissions for user "${fields.id}".`, output)
        setPermissions(output);
    };


    /**
     * Performs the save via the API.
     */
    const doSave = async () => {
        setLoading(true);

        const response = fields.id ?
            await API.put(`user/${fields.id}`, fields) :
            await API.post('users', fields);

        // Fetch the pre-existing permissions for this record.
        const existing = await API.get('user-permissions', {
            $top: 250,
            $filter: `userId in {${fields.id}}`
        });

        // Delete any permissions that are no longer present.
        for (const permission of existing) {
            if (!permissions.hasOwnProperty(permission.permission)) {
                await API.delete(`user-permission/${permission.id}`);
            }
        }

        // Save any new permissions (without an ID).
        for (const permission of Object.values(permissions)) {
            if (!permission.hasOwnProperty('id')) {
                await API.post('user-permissions', {
                    ...permission,
                    userId: response.id
                });
            }
        }

        setLoading(false);

        if (onSave) {
            onSave(response);
        }
    };


    /**
     * Updates a particular form value.
     *
     * @param key
     * @param value
     */
    const setValue = (key, value) => {
        setFields({
            ...fields,
            [key]: value
        });
    };


    /**
     * Assigns a particular permission.
     *
     * @param permission
     * @param value
     */
    const setPermission = (permission, value) => {
        const {
            key,                // {String} The slug for the permission.
            children            // {Array<Object>} Any child / related permissions.
        } = permission;

        const output = {...permissions};

        if (!value && output.hasOwnProperty(key)) {
            delete output[key];

            if (children && children.length) {
                children.map(child => delete output[child.key]);
            }
        } else if (value && !output.hasOwnProperty(key)) {
            output[key] = {
                userId: fields.id,
                permission: key
            };
        }

        Logger.debug(`[UserForm] Setting permission "${key}" to "${value}".`, output);
        setPermissions(output);
    };


    /**
     * Closes the copy modal.
     */
    const onCopyClose = () => {
        setCopying(false);
    };


    /**
     * Handles the copy process for permissions.
     */
    const doCopy = async () => {
        setLoading(true);
        const record = {
            ...await API.get(`users/${userId}`, {
                $expand: 'permissions($top=250)',
            })
        };

        const output = {};

        if(record.permissions && record.permissions.length){
            record.permissions.map(permission => output[permission.permission] = {
                userId: fields.id,
                permission: permission.permission
            })
        }

        setPermissions(output);
        setLoading(false);
        onCopyClose();
    };

    return (
        <>
            <Dialog
                open={open}
                scroll={'body'}
                onClose={onClose}
                maxWidth={'lg'}
                fullWidth
            >
                <DialogHeading
                    title={'User Details'}
                    noMargin
                />
                <DialogContent>
                    <Box className={'columns__1'}>
                        <SectionHeading title={'Contact Information'}/>

                        <Box className={'d-flex__justify'}>
                            <FileAvatar
                                width={128}
                                model={User}
                                field={'image'}
                                height={128}
                                record={fields}
                                variant={'rounded'}
                                editable
                                onChange={path => setValue('image', path)}
                                helperText={'Profile Image'}
                            />

                            <Box className={'flex__grow'} sx={{marginLeft: '1.5em'}}>
                                <Box className={'columns__1'}>
                                    <Box className={'columns__2'}>
                                        <TextField
                                            label="First Name"
                                            value={fields.firstName}
                                            required
                                            disabled={isLoading}
                                            onChange={event => setValue('firstName', event.target.value)}
                                        />
                                        <TextField
                                            label="Last Name"
                                            value={fields.lastName}
                                            required
                                            disabled={isLoading}
                                            onChange={event => setValue('lastName', event.target.value)}
                                        />
                                    </Box>

                                    <Box className={'columns__3'}>
                                        <TextField
                                            label="Email Address"
                                            value={fields.emailAddress}
                                            required
                                            disabled={isLoading}
                                            onChange={event => setValue('emailAddress', event.target.value)}
                                        />
                                        <InputPhone
                                            label="Phone Number"
                                            value={fields.phoneNumber}
                                            disabled={isLoading}
                                            onChange={event => setValue('phoneNumber', event.target.value)}
                                        />
                                        <TextField
                                            label="Job Title"
                                            value={fields.jobTitle}
                                            disabled={isLoading}
                                            onChange={event => setValue('jobTitle', event.target.value)}
                                        />
                                    </Box>
                                </Box>
                            </Box>
                        </Box>

                        <Box>
                            <Box className={'mb__2'}>
                                <SectionHeading
                                    title={'Grant Permissions'}
                                    action={
                                        <SmallButton
                                            onClick={() => setCopying(true)}
                                            children={
                                                <span style={{color: '#fff'}}>
                                                Clone from...
                                            </span>
                                            }
                                            className={'text__small text__thin'}
                                        />
                                    }
                                />
                            </Box>

                            <Box>
                                <TableContainer className={'table table--striped'}>
                                    <Table>
                                        <TableBody>
                                            {allPermissions.map(permission => {
                                                const {
                                                    children        // {Array<Object>} The related permissions.
                                                } = permission;

                                                const bIsSelected = permissions.hasOwnProperty(permission.key);
                                                const bAllSelected = !children.some(child => !permissions.hasOwnProperty(child.key));

                                                const output = [
                                                    <FormControlLabel
                                                        key={permission.key}
                                                        label={permission.label}
                                                        control={
                                                            <Checkbox
                                                                checked={bIsSelected}
                                                                disabled={isLoading}
                                                                onChange={event => setPermission(permission, event.target.checked)}
                                                                indeterminate={bIsSelected && !bAllSelected}
                                                            />
                                                        }
                                                    />
                                                ];

                                                if (children && bIsSelected) {
                                                    children.map(child => {
                                                        output.push(
                                                            <FormControlLabel
                                                                key={child.key}
                                                                label={child.label}
                                                                control={
                                                                    <Checkbox
                                                                        checked={permissions.hasOwnProperty(child.key)}
                                                                        disabled={isLoading}
                                                                        onChange={event => setPermission(child, event.target.checked)}
                                                                    />
                                                                }
                                                                className={'ml__3'}
                                                            />
                                                        );
                                                    });
                                                }

                                                return (
                                                    <TableRow>
                                                        <TableCell
                                                            padding={'checkbox'}
                                                        >
                                                            <Box className={'pl__2 pr__2'}>
                                                                <FormGroup>
                                                                    {output}
                                                                </FormGroup>
                                                            </Box>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Box>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={doSave}
                        children={'Save'}
                        disabled={isLoading}
                    />
                    <Button
                        color={'error'}
                        onClick={onClose}
                        children={'Close'}
                        disabled={isLoading}
                    />
                </DialogActions>
            </Dialog>

            <Dialog
                open={isCopying}
                scroll={'body'}
                onClose={onCopyClose}
                maxWidth={'sm'}
                fullWidth
            >
                <DialogContent>
                    <Box className={'columns__1'}>
                        <SectionHeading title={'Clone Permissions'}/>
                        <Box>Please select a user below to copy permissions from...</Box>
                        <ModelSearch
                            model={User}
                            label={'User'}
                            value={userId}
                            onChange={value => setUserId(value)}
                            renderLabel={option => !option ? '' : option.displayName}
                            filterQuery={query => `isDeleted eq {0} and displayName eq {${query}}`}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={doCopy}
                        children={'Apply'}
                        disabled={!userId || isLoading}
                    />
                    <Button
                        color={'error'}
                        onClick={onCopyClose}
                        children={'Cancel'}
                        disabled={isLoading}
                    />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default UserForm;