import React from "react";

import Employee from "../../Models/Employee";
import TableLayout from "../../Components/Layouts/TableLayout";

import GroupIcon from '@mui/icons-material/Group';

/**
 * Employees component.
 *
 * @returns {*}
 * @constructor
 */
const Employees = () => (
    <TableLayout
        icon={<GroupIcon/>}
        model={Employee}
        heading={'Employees'}
        applySearch={search => {
            const filter = Employee.getQuery().$filter;
            return `${filter ? `${filter} and ` : ''}displayName eq {${search}} or addressLine1 eq {${search}} or emailAddress eq {${search}} or helixId eq {${search}}`
        }}
        formPermission={'EDIT_EMPLOYEES'}
    />
);

export default Employees;