import React, {useEffect, useRef, useState} from "react";

import API from "../../Global/API";
import State from "../../Global/State";
import Touch from "../../Global/Touch";
import Logger from "../../Global/Logger";
import Settings from "../../Global/Settings";
import Generator from "../../Global/Generator";
import ImageWrapper from "../ImageWrapper";
import {dataURIToBlob, fileToDataURL} from "../../Global/Helpers/File";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import FormHelperText from "@mui/material/FormHelperText";
import {getEditorDefaults} from '@pqina/pintura';
import {PinturaEditorModal} from "@pqina/react-pintura";

import '@pqina/pintura/pintura.css';

/**
 * FileAvatar component.
 *
 * @returns {*}
 * @constructor
 */
const FileAvatar = (props) => {
    const {
        icon,                       // {Component} An optional icon to pass along to the avatar.
        model,                      // {Model} The target model definition.
        field,                      // {String} The field key to pull image values from.
        width,                      // {Number} The width of the container.
        ratio,                      // {Number} The aspect ratio of the image, otherwise defaults to 1:1.
        height,                     // {Number} The height of the container.
        record,                     // {Object} The underlying record context.
        center,                     // {Boolean} If enabled, will attempt to center the container.
        disabled,                   // {Boolean} A disabled flag.
        editable,                   // {Boolean} If enabled, will prompt the user to edit the final image first.
        freeCrop,                   // {Boolean} If enabled, will allow any aspect ratio.
        onChange,                   // {Function} An optional change callback, triggered after uploads are processed.
        component,                  // {Component} An optional override component to use instead of the default.
        helperText,                 // {String} An optional helper line of text to include.
        targetPath,                 // {String} An optional replacement for the upload path.
        allowDocuments,             // {Boolean} If enabled, will also allow PDF, etc.
    } = props;

    const [path, setPath] = useState('');
    const [index, setIndex] = useState(0);
    const [isError, setError] = useState(false);
    const [fileName, setFileName] = useState('');
    const [isEditing, setEditing] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [isModified, setModified] = useState(false);
    const [editTarget, setEditTarget] = useState(null);
    const [errorMessage, setErrorMessage] = useState('');

    /**
     * Initialize the object path on component mount.
     */
    useEffect(() => {
        if (!record || !record[field]) {
            return;
        }

        Logger.debug('[FileAvatar] Received updated record.', record);
        setPath(record[field]);
    }, [record]);


    /**
     * The upload path / directory.
     *
     * @type {string}
     */
    const uploadPath = targetPath || `${model ? model.getRoute() : ''}${record && record.id ? `/${record.id}` : ``}`;


    /**
     * The editor configuration for Pintura.
     *
     * @type {PinturaEditorDefaultOptions}
     * @see https://pqina.nl/pintura/
     */
    const editorConfig = getEditorDefaults({
        imageReader: {
            orientImage: true
        },
        imageWriter: {
            quality: 0.2,
            mimeType: 'image/jpeg',
            store: (state, options, onprogress) =>
                new Promise(async (resolve, reject) => {
                    let file;
                    const {dest} = state;
                    const formData = new FormData();

                    /**
                     * Depending on our environment, we'll need to handle this piece a bit differently. For Android,
                     * there appears to be a bug tied into the `android-image-file-input` package that prevents us from
                     * using the traditional upload method as the output file (ie. `dest`) becomes unusable.
                     */
                    Logger.debug('[FileAvatar] Detected Android environment, using alternative upload method.');
                    const result = await fileToDataURL(state.dest);
                    formData.append('file', dataURIToBlob(result), fileName);

                    // If for some reason we can't continue, let's fail gracefully.
                    if (!formData.get('file')){
                        resolve(state);
                        return;
                    }

                    // Create the request payload.
                    setLoading(true);
                    formData.append('name', fileName);
                    formData.append('path', uploadPath);

                    // create a request object
                    const request = new XMLHttpRequest();
                    request.open('POST', `${Settings.apiPath}/upload`);
                    request.setRequestHeader('Authorization', `Bearer ${State.get('auth-token')}`);

                    // show progress in interface
                    request.upload.onprogress = onprogress;

                    // catch errors
                    request.onerror = () =>
                        reject('Unable to save, an unexpected error occurred.');

                    request.ontimeout = () =>
                        reject('Unable to save, request timed out.');

                    // handle success state
                    request.onload = () => {
                        if (request.status >= 200 && request.status < 300) {
                            state.store = request;

                            resolve(state);
                        } else {
                            reject('Unable to save.');
                        }
                    };

                    // start uploading the image
                    request.send(formData);
                })
        }
    });


    /**
     * The editor instance.
     *
     * @type {React.MutableRefObject<null>}
     */
    const editor = useRef(null);


    /**
     * The hidden file input element.
     *
     * @type {React.MutableRefObject<null> | React.RefObject<any> | React.MutableRefObject<undefined>}
     */
    const fileInput = useRef(null);


    /**
     * Handles click interaction with the avatar.
     */
    const handleClick = () => {
        if(disabled){
            return;
        }

        if (!fileInput.current) {
            return;
        }

        fileInput.current.click();
    };


    /**
     * Closes the status popover.
     */
    const handleStatusClose = () => {
        setError(false);
        setSuccess(false);
    };


    /**
     * Handles the image error message.
     *
     * @param error
     */
    const onUploadError = (error) => {
        setError(true);
        setSuccess(false);
        setLoading(false);
        setEditing(false);
        setErrorMessage(error);
    };


    /**
     * Reveals the edit modal.
     *
     * @param file
     * @returns {void}
     */
    const doEditMode = file => {
        Logger.debug('[FileAvatar] Entering edit mode.', file);

        setEditing(true);
        setEditTarget(file);
    };


    /**
     * Closes the edit modal.
     */
    const handleEditClose = () => {
        setEditing(false);
        setLoading(false);

        setTimeout(() => {
            setIndex(index + 1);
        }, 250);
    };


    /**
     * Handles the file relation back to the root record post-upload.
     *
     * @param path
     * @returns {Promise<void>}
     */
    const onUploadSuccess = async (path) => {
        if (!record || !record.id) {
            if (onChange) {
                onChange(path);
            }

            setPath(path);
            setLoading(false);
            setModified(true);
            return;
        }

        try {
            await API.put(`${model.getRoute()}/${record.id}`, {
                [field]: path
            });

            setError(false);
            setSuccess(true);
            setErrorMessage('');
        } catch (e) {
            onUploadError('Your file may not have been uploaded properly.');
        }

        if (onChange) {
            onChange(path);
        }

        setPath(path);
        setLoading(false);
        setModified(true);
    };


    /**
     * Processes the image upload.
     */
    const doUpload = () => {
        if(!uploadPath){
            return onUploadError('No upload path was specified.');
        }

        const file = fileInput.current.files[0];
        setLoading(true);

        if (editable) {
            setFileName(`${Generator.uuid()}-${file.name}`);
            doEditMode(file);
        } else {
            API.doFileUpload({
                file: file,
                path: uploadPath,
                types: !allowDocuments ? ['jpg', 'png', 'jpeg', 'png', 'bmp'] : []
            }, onUploadSuccess, onUploadError);
        }
    };

    return (
        <>
            {!!component ? (
                <a
                    style={{color: 'inherit'}}
                    onClick={Touch.clickable(handleClick)}
                    children={component}
                    onTouchEnd={Touch.tappable(handleClick)}
                />
            ) : (
                <Box className={center ? 'text__center' : ''}>
                    <a
                        style={isLoading || disabled ? {
                            pointerEvents: 'none',
                        } : {}}
                        onClick={Touch.clickable(handleClick)}
                        className={`file__avatar ${center ? 'upload__center' : ''}`}
                        onTouchEnd={Touch.tappable(handleClick)}
                    >
                        <ImageWrapper
                            {...props}

                            src={API.getFilePath(path)}
                            icon={icon}
                            width={width}
                            retry={isModified}
                            height={height}
                            loading={isLoading}
                            children={icon}
                        />
                    </a>

                    {helperText && (
                        <Box>
                            <FormHelperText sx={{textAlign: 'center'}}>{helperText}</FormHelperText>
                        </Box>
                    )}
                </Box>
            )}

            <input
                ref={fileInput}
                key={`file-upload-${index}`}
                type={'file'}
                accept={allowDocuments ?
                    'image/*, text/plain, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, .csv' :
                    'image/*'
                }
                capture={!allowDocuments}
                onChange={doUpload}
                className={'d-hidden'}
            />

            {isEditing && editTarget && (
                <PinturaEditorModal
                    {...editorConfig}

                    // Global attributes.
                    ref={editor}
                    src={editTarget}
                    utils={['crop']}
                    onHide={handleEditClose}
                    onProcess={() => onUploadSuccess(`${uploadPath}/${fileName}`)}
                    onLoaderror={() => onUploadError('Unable to load image.')}
                    onProcesserror={() => onUploadError('Unable to upload image, please try again.')}
                    imageCropAspectRatio={freeCrop ? undefined : (ratio || 1)}

                    // Additional attributes specific to iOS.
                    preventScrollBodyIfNeeded={false}
                    preventFooterOverlapIfNeeded={false}
                />
            )}

            {isError && (
                <Snackbar open={isError} autoHideDuration={6000} onClose={handleStatusClose}>
                    <Alert onClose={handleStatusClose} severity="error" sx={{width: '100%'}}>
                        {errorMessage}
                    </Alert>
                </Snackbar>
            )}

            {isSuccess && (
                <Snackbar open={isSuccess} autoHideDuration={6000} onClose={handleStatusClose}>
                    <Alert onClose={handleStatusClose} severity="success" sx={{width: '100%', textAlign: 'left'}}>
                        Your file was uploaded successfully!
                    </Alert>
                </Snackbar>
            )}
        </>
    )
};

export default FileAvatar;