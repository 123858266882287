import React from "react";

import {useAuth} from "../../../Global/Auth";
import FileAvatar from "../../Input/FileAvatar";

import Box from "@mui/material/Box";

/**
 * ProfileBadge component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ProfileBadge = (props) => {
    const {
        icon,                   // {Component} An optional avatar icon to pass along.
        model,                  // {Model} The target model definition.
        record,                 // {Object} The underlying record context.
        heading,                // {String} The title of the record (typically the name property).
        syncUser,               // {Boolean} Indicates whether we should sync user fields to fields on this component.
        decorator,              // {Component} An optional decorator, status indicator, etc.
        imageField,             // {String} The field key to pull image values from.
        horizontal,             // {Boolean} Whether the image is horizontal or vertical.
        subheading,             // {Component} Any additional details to apply beneath the heading.
        actionSlot,             // {Component} An optional action area to append controls to.
    } = props;

    const {user} = useAuth();

    /**
     * Synchronizes the user's profile image against the uploader.
     *
     * @param path
     */
    const handleImageChange = (path) => {
        if(!syncUser){
            return;
        }

        window.dispatchEvent(
            new CustomEvent('userupdated', {
                detail: {
                    ...user,
                    image: path
                }
            })
        );
    }

    return (
        <Box className={'d-flex__center w__100'} sx={{
            paddingTop: '1.5em',
            paddingBottom: '1.5em'
        }}>
            <FileAvatar
                sx={{marginRight: '0.5em'}}
                icon={icon}
                model={model}
                field={imageField}
                width={64}
                height={64}
                record={record}
                variant={'rounded'}
                editable
                onChange={handleImageChange}
                horizontal={horizontal}
            />
            <div className={'flex__grow d-flex__center'}>
                <Box className={'profile__badge flex__grow'}>
                    <Box className={'d-flex__start'}>
                        <h2 className={'mt__0 mb__0'}>{heading}</h2>
                        {decorator}
                    </Box>
                    <div className={'text__light'}>{subheading}</div>
                </Box>

                {actionSlot}
            </div>
        </Box>
    );
};

export default ProfileBadge;