import React, {useState} from "react";

import API from "../../Global/API";
import AvailabilityRequest from "../../Actions/EmployeeActions/AvailabilityRequest";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import LockOpenIcon from '@mui/icons-material/LockOpen';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

/**
 * InvitationActions component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const InvitationActions = (props) => {
    const {
        record,         // {Object} The target record to base values from.
        doReload,       // {Function} The reload callback for the table.
    } = props;

    const [isLoading, setLoading] = useState(false);
    const [isComplete, setComplete] = useState(!record.isInvited);
    const [isRejected, setRejected] = useState(!!record.isDeleted);
    const [isUnlocked, setUnlocked] = useState(!!record.isInviteAccepted);
    const [isUnlocking, setUnlocking] = useState(false);

    /**
     * Approves the employee for hire.
     *
     * @returns {Promise<void>}
     */
    const handleApprove = async () => {
        setLoading(true);

        const active = await API.get('statuses', {
            $filter: 'name in {Active} and modelType in {Employee}'
        });

        // Verify that we've found an active status.
        if (active && active.length) {
            const result = await API.put(`employees/${record.id}`, {
                statusId: active[0].id,
                isInvited: false,
            });

            if (doReload) {
                doReload(result);
            }
        }

        setLoading(false);
        setComplete(true);
    };


    /**
     * Unlocks the user profile for the next phase of the process.
     *
     * @returns {Promise<void>}
     */
    const handleUnlock = async () => {
        setLoading(true);

        const result = await API.put(`employees/${record.id}`, {
            isInviteAccepted: true
        });

        if (doReload) {
            doReload(result);
        }

        setLoading(false);
    };


    /**
     * Rejects the employee for hire.
     *
     * @returns {Promise<void>}
     */
    const handleReject = async () => {
        setLoading(true);

        const inactive = await API.get('statuses', {
            $filter: 'name in {Inactive} and modelType in {Employee}'
        });

        // Verify that we've found an inactive status.
        if (inactive && inactive.length) {
            const result = await API.put(`employees/${record.id}`, {
                statusId: inactive[0].id,
                isInvited: false,
            });

            if (doReload) {
                doReload(result);
            }
        }

        setLoading(false);
        setComplete(true);
        setRejected(true);
    };


    /**
     * Reveals the availability assignment on "Unlock".
     */
    const handleUnlockOpen = () => {
        setUnlocking(true);
    };


    /**
     * Hides the availability assignment on "Unlock".
     */
    const handleUnlockClose = () => {
        setUnlocking(false);
    };

    return (
        <Box className={'white-space__pre'}>
            {!isUnlocked ? (
                <Button
                    size={'small'}
                    onClick={handleUnlockOpen}
                    variant={!isComplete ? 'contained' : (!isRejected ? 'contained' : 'outlined')}
                    disabled={isLoading || isComplete || !record.inviteCompletedDate}
                    children={'Unlock'}
                    className={'mr__3'}
                    startIcon={<LockOpenIcon />}
                />
            ) : (
                <Button
                    size={'small'}
                    onClick={handleApprove}
                    variant={!isComplete ? 'contained' : (!isRejected ? 'contained' : 'outlined')}
                    disabled={isLoading || isComplete || (!record.offerLetterDate || !record.availabilityDate || !record.trainingDate || !record.hirePacketDate || !record.drugScreenDate)}
                    children={'Activate'}
                    className={'mr__3'}
                    startIcon={<CheckCircleOutlineIcon />}
                />
            )}

            <Button
                size={'small'}
                color={'error'}
                onClick={handleReject}
                variant={!isComplete ? 'contained' : (isRejected ? 'contained' : 'outlined')}
                children={'Deactivate'}
                disabled={isLoading || isComplete}
            />

            {isUnlocking && (
                <AvailabilityRequest
                    open={true}
                    record={record}
                    onSave={handleUnlock}
                    onClose={handleUnlockClose}
                />
            )}
        </Box>
    )
};

export default InvitationActions;