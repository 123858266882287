import React, {useEffect, useRef, useState} from "react";

import App from "../../../Global/App";
import API from "../../../Global/API";
import Settings from "../../../Global/Settings";
import TimeLabel from "./TimeLabel";
import {useAuth} from "../../../Global/Auth";
import Formatter from "../../../Global/Formatter";
import useOnScreen from "../../../Hooks/useOnScreen";
import ImageWrapper from "../../ImageWrapper";
import DialogHeading from "../../Typography/DialogHeading";
import MessageActions from "./MessageActions";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

/**
 * ChatMessage component.
 *
 * @returns {*}
 * @constructor
 */
const ChatMessage = (props) => {
    const {
        html,           // {Boolean} Whether to assign as the innerHTML rather than child.
        record,         // {Object} The message record to pull context from.
        onUpdate,       // {Function} A reload function for the parent component.
    } = props;

    const ref = useRef();
    const {user, isScope} = useAuth();
    const [seen, setSeen] = useState(false);
    const [isPreviewing, setPreviewing] = useState(false);
    const onScreen = useOnScreen(ref, "200px");

    /**
     * Triggers the "Read" receipt for visible messages.
     */
    useEffect(() => {
        if (seen) {
            doRead();
        }
    }, [seen]);


    /**
     * Handle the message seen flag.
     *
     * @returns {Promise<void>}
     */
    const doRead = async () => {
        await API.put(`messages/${record.id}`, {
            isRead: true
        });
    };


    /**
     * Determines the appropriate recipient and sender records.
     *
     * @type {Object}
     */
    const senderRecord = {...record.senderRecord};


    /**
     * Indicates whether this message should be displayed as a response.
     *
     * @type {boolean}
     */
    const isReply =
        record.senderId !== user.id ||
        record.senderType !== user['@model'];


    /**
     * Indicates if this message is currently pinned.
     */
    const isPinned = isScope('User') && record && record.isPinned;


    /**
     * Returns the class name of the indicator icon.
     *
     * @type {string}
     */
    const indicatorClass = `notification__indicator ${!record.isRead ? `notification__indicator--highlight` : ``}`;


    /**
     * Returns the class name of the message box.
     *
     * @type {string}
     */
    const notificationClass = `notification__wrapper ${isReply ? 'notification__wrapper--response' : 'notification__wrapper--reply'} ${isPinned ? 'notification__wrapper--pinned' : ''}`;


    /**
     * Indicates if we should display the avatar within each message.
     *
     * @type {boolean}
     */
    const bShowAvatar = !App.isCordova();


    /**
     * Emit the "Message Read" event if applicable.
     */
    if (onScreen && isReply && !record.isRead && !seen) {
        setSeen(true);
    }


    /**
     * Returns the nice name of a user.
     *
     * @param user
     * @returns {string}
     */
    const getUserNiceName = (user) => {
        return user && user.firstName ? `${user.firstName} ${user.lastName ? `${user.lastName.charAt(0)}.` : ''}` : '(no name)';
    };


    /**
     * Returns the inner document content.
     *
     * @returns {JSX.Element}
     * @constructor
     */
    const PreviewContent = () => {
        const isImage = (record.attachment || '').match(/\.(jpg|jpeg|png|gif)$/i);

        return isImage ? (
            <Box
                sx={App.isCordova() ? {} : {
                    maxHeight: 'calc(100vh - 350px)',
                    overflowY: 'scroll'
                }}
                children={
                    <img
                        src={API.getFilePath(record.attachment)}
                        className={'v-align__top'}
                    />
                }
            />
        ) : (
            <Box className={'iframe__container'}>
                <embed
                    src={API.getFilePath(record.attachment)}
                    scrolling={'no'}
                    className={'responsive-iframe'}
                />
            </Box>
        );
    };


    /**
     * Reveals the attachment preview.
     */
    const handlePreviewOpen = () => {
        setPreviewing(true);
    };


    /**
     * Closes the attachment preview modal.
     */
    const handlePreviewClose = () => {
        setPreviewing(false);
    };

    return (
        <div ref={ref} className={'d-flex notification__entry'}>
            {isReply && (
                <Box>
                    <a className={indicatorClass}/>
                </Box>
            )}

            <Box className={notificationClass}>
                <Box className={'notification__body'}>
                    {bShowAvatar && (
                        <ImageWrapper
                            src={API.getFilePath(senderRecord.image)}
                            width={32}
                            height={32}
                            className={'v-align__top'}
                        />
                    )}

                    {/* Hiding content that's not visible to avoid stretching the container unnecessarily. */}
                    {onScreen && (
                        <Box sx={{flexGrow: 1}} className={bShowAvatar ? 'ml__2' : ''}>
                            {!html ? (
                                <Box className={'white-space__pre-line'}>{record.content}</Box>
                            ) : (
                                <Box>
                                    <Box><b>{record.subject || `New Message from ${Settings.applicationName}`}</b></Box>
                                    <Box dangerouslySetInnerHTML={{__html: record.content || ''}}/>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
                <Box className={'d-flex__justify'}>
                    <TimeLabel
                        value={record.createdDate}
                        prependLabel={
                            isReply ? (
                                <Box className={'mr__2 white-space__pre'}>
                                    <b>{getUserNiceName(senderRecord) || '(n/a)'}</b>
                                </Box>
                            ) : null
                        }
                        appendSlot={
                            !isReply ? (
                                <Box className={'d-flex__start'}>
                                    {isScope('User') && (
                                        <>
                                            {record.template && record.template.id && (
                                                <Chip
                                                    size={'small'}
                                                    label={
                                                        <>
                                                            <b>Template:</b> {record.template.name || '(no name)'}
                                                        </>
                                                    }
                                                    className={'ml__2 text__small'}
                                                />
                                            )}

                                            {(record.type === 'Email' || record.type === 'SMS') && record.isError && (
                                                <Chip
                                                    size={'small'}
                                                    color={'error'}
                                                    label={'Delivery Failed'}
                                                    className={'ml__2 text__small'}
                                                />
                                            )}

                                            {record.type === 'Chat' && (
                                                <Box
                                                    sx={{fontStyle: 'italic'}}
                                                    children={record.isRead ? 'Seen' : 'Not seen'}
                                                    className={'ml__2'}
                                                />
                                            )}
                                        </>
                                    )}
                                </Box>
                            ) : null
                        }
                    />

                    <Box className={'d-flex__center'}>
                        <MessageActions
                            message={record}
                            onUpdate={onUpdate}
                        />

                        {!!record.attachment && (
                            <Box
                                onClick={handlePreviewOpen}
                                className={'cursor__pointer'}
                            >
                                <AttachFileIcon
                                    sx={{opacity: 0.8}}
                                    fontSize={'small'}
                                />
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>

            {isPreviewing && (
                <>
                    <Dialog
                        open={true}
                        scroll={'body'}
                        onClose={handlePreviewClose}
                        maxWidth={'md'}
                        fullWidth
                    >
                        <DialogHeading
                            title={"View Attachment"}
                            actions={
                                <IconButton
                                    size={'small'}
                                    onClick={handlePreviewClose}
                                >
                                    <CloseIcon fontSize={'small'}/>
                                </IconButton>
                            }
                            noMargin
                        />
                        <PreviewContent/>
                        <Box align={'right'} className={'d-flex__start p__3 text__small text__light'}>
                            <AccessTimeIcon className={'mr__1'}/>
                            Uploaded on {Formatter.dateTime(record.createdDate)}
                        </Box>
                    </Dialog>
                </>
            )}
        </div>
    );
};

export default ChatMessage;