import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import Logger from "../../../Global/Logger";
import SmallButton from "../../../Components/Widgets/CommunicationFeed/SmallButton";
import DetailWrapper from "../../../Components/Layouts/Profile/DetailWrapper";
import SectionHeading from "../../../Components/Typography/SectionHeading";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import AddIcon from '@mui/icons-material/Add';
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableHead from "@mui/material/TableHead";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import TableContainer from "@mui/material/TableContainer";

/**
 * ClientPayDetails component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ClientPayDetails = (props) => {
    const {
        record      // {Object} The record in which to reference.
    } = props;

    const [isEditable, setEditable] = useState(false);
    const [specialties, setSpecialties] = useState([]);
    const [isViewingPayRates, setViewingPayRates] = useState(false);

    /**
     * Load all ancillary data on mount.
     */
    useEffect(() => {
        getSpecialties();
    }, []);


    /**
     * Loads all specialties from the API.
     *
     * @returns {Promise<void>}
     */
    const getSpecialties = async () => {
        const specialties = await API.get('specialties', {
            $top: 250,
            $select: 'id,name',
            $filter: 'isDeleted eq {0}'
        });

        Logger.debug('[ClientPayDetails] Loaded specialties.', specialties);
        setSpecialties(specialties);
    };


    /**
     * All available day options for each working week.
     *
     * @type {Array<String>}
     */
    const days = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
    ];


    /**
     * The stored shifts for this client.
     *
     * @type {Array<Object>}
     */
    const shifts = [
        {
            id: 1,
            label: 'Morning (WD)',
            fromDay: 'Monday',
            fromTime: '06:00:00',
            toDay: 'Friday',
            toTime: '14:00:00',
        }, {
            id: 2,
            label: 'Evening (WD)',
            fromDay: 'Monday',
            fromTime: '14:00:00',
            toDay: 'Friday',
            toTime: '20:00:00',
        }, {
            id: 3,
            label: 'Night (WD)',
            fromDay: 'Monday',
            fromTime: '20:00:00',
            toDay: 'Friday',
            toTime: '06:00:00',
        }, {
            id: 4,
            label: 'Morning (WE)',
            fromDay: 'Saturday',
            fromTime: '06:00:00',
            toDay: 'Sunday',
            toTime: '14:00:00',
        }, {
            id: 5,
            label: 'Evening (WE)',
            fromDay: 'Saturday',
            fromTime: '14:00:00',
            toDay: 'Sunday',
            toTime: '20:00:00',
        }, {
            id: 6,
            label: 'Night (WE)',
            fromDay: 'Saturday',
            fromTime: '20:00:00',
            toDay: 'Sunday',
            toTime: '06:00:00',
        },
    ];


    /**
     * The available bill rates for this facility.
     *
     * @type {Array<Object>}
     */
    const billRates = [
        {
            value: 80,
            shiftId: 1,
            specialtyId: 1,
        }, {
            value: 80,
            shiftId: 2,
            specialtyId: 1,
        }, {
            value: 80,
            shiftId: 3,
            specialtyId: 1,
        }
    ];


    /**
     * The available pay rates for this facility.
     *
     * @type {Array<Object>}
     */
    const payRates = [
        {
            value: 26,
            shiftId: 1,
            specialtyId: 1,
        }, {
            value: 25,
            shiftId: 2,
            specialtyId: 1,
        }, {
            value: 23,
            shiftId: 3,
            specialtyId: 1,
        }
    ];


    /**
     *
     * @type {Object}
     */
    const clientSpecialties = [
        {
            specialtyId: 1,
        }, {
            specialtyId: 2,
        }, {
            specialtyId: 3,
        }
    ];


    /**
     * All available time options.
     *
     * @type {Array}
     */
    const timeOptions = (() => {
        const results = [];

        for (let y = 0; y < 24; y++) {
            for (let x = 0; x < 4; x++) {
                const hours = y;
                const minutes = x * 15;

                const now = dayjs()
                    .set('hours', hours)
                    .set('minutes', minutes)
                    .set('seconds', 0);

                results.push({
                    value: now.format('HH:mm:ss'),
                    label: now.format('h:mmA')
                });
            }
        }

        return results;
    })();


    /**
     * Calculates the column width for the rate table.
     *
     * @type {string}
     */
    const rateWidth = `${100 / (shifts.length + 1)}%`;


    /**
     * Various options for the table inputs.
     *
     * @type {Object}
     */
    const tableInputProps = { disableUnderline: true };


    /**
     * Reveals editable fields across the layout.
     */
    const handleEditOpen = () => {
        setEditable(true);
    };


    /**
     * Closes the form for editing.
     */
    const handleEditClose = () => {
        setEditable(false);
    };


    /**
     * Switches the view between pay and bill rates.
     */
    const handlePayRateToggle = () => {
        setViewingPayRates(!isViewingPayRates);
    };

    return (
        <Box className={'p__3'}>
            <Box className={'columns__1'}>
                <SectionHeading title={'Pay Settings'} primary/>
                {!isEditable ? (
                    <Box className={'columns__3'}>
                        <DetailWrapper label={'Minimum Break'} value={`${record.minimumBreak || '0'}m`}/>
                        <DetailWrapper label={'Overtime Multiplier'} value={`${record.overtimeRate || '1'}x`}/>
                        <DetailWrapper label={'Holiday Multiplier'} value={`${record.holidayRate || '1'}x`}/>
                    </Box>
                ) : (
                    <Box className={'columns__3'}>
                        <TextField
                            label={'Minimum Break (m)'}
                            onChange={event => true}
                        />
                        <TextField
                            label={'Overtime Multiplier'}
                            onChange={event => true}
                        />
                        <TextField
                            label={'Holiday Multiplier'}
                            onChange={event => true}
                        />
                    </Box>
                )}
                <Box>
                    <SectionHeading title={'Shift Options'} primary/>
                    <TableContainer className={`table table--striped ${isEditable ? 'rate-table__container--editable' : 'rate-table__container--default'}`}>
                        <Table>
                            <colgroup>
                                <col width={'20%'}/>
                                <col width={'20%'}/>
                                <col width={'20%'}/>
                                <col width={'20%'}/>
                                <col width={'20%'}/>

                                {isEditable && <col width={35}/>}
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Name</TableCell>
                                    <TableCell>From Day</TableCell>
                                    <TableCell>To Day</TableCell>
                                    <TableCell>Start Time</TableCell>
                                    <TableCell>End Time</TableCell>

                                    {isEditable && <TableCell/>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {shifts.map(shift => (
                                    <TableRow>
                                        <TableCell>
                                            {!isEditable ? (
                                                <>{shift.label}</>
                                            ) : (
                                                <TextField
                                                    size={'small'}
                                                    value={shift.label}
                                                    variant={'standard'}
                                                    onChange={event => true}
                                                    fullWidth
                                                    InputProps={tableInputProps}
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {!isEditable ? (
                                                <>{shift.fromDay || ''}</>
                                            ) : (
                                                <FormControl variant={'standard'} fullWidth>
                                                    <Select
                                                        size={'small'}
                                                        value={shift.fromDay || ''}
                                                        onChange={event => true}
                                                        {...tableInputProps}
                                                    >
                                                        {days.map((day, i) =>
                                                            <MenuItem
                                                                key={i}
                                                                value={day}
                                                                children={day}
                                                            />
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {!isEditable ? (
                                                <>{shift.toDay || ''}</>
                                            ) : (
                                                <FormControl variant={'standard'} fullWidth>
                                                    <Select
                                                        size={'small'}
                                                        value={shift.toDay || ''}
                                                        onChange={event => true}
                                                        maxWidth
                                                        {...tableInputProps}
                                                    >
                                                        {days.map((day, i) =>
                                                            <MenuItem
                                                                key={i}
                                                                value={day}
                                                                children={day}
                                                            />
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {!isEditable ? (
                                                <>{dayjs(shift.fromTime, 'HH:mm:ss').format('h:mmA')}</>
                                            ) : (
                                                <FormControl variant={'standard'} fullWidth>
                                                    <Select
                                                        size={'small'}
                                                        value={shift.fromTime}
                                                        onChange={event => true}
                                                        {...tableInputProps}
                                                    >
                                                        {timeOptions.map((option, i) => (
                                                            <MenuItem
                                                                key={i}
                                                                value={option.value}
                                                                children={option.label}
                                                            />
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            )}
                                        </TableCell>
                                        <TableCell>
                                            {!isEditable ? (
                                                <>{dayjs(shift.toTime, 'HH:mm:ss').format('h:mmA')}</>
                                            ) : (
                                                <FormControl variant={'standard'} fullWidth>
                                                    <Select
                                                        size={'small'}
                                                        value={shift.toTime}
                                                        onChange={event => true}
                                                        {...tableInputProps}
                                                    >
                                                        {timeOptions.map((option, i) => (
                                                            <MenuItem
                                                                key={i}
                                                                value={option.value}
                                                                children={option.label}
                                                            />
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            )}
                                        </TableCell>

                                        {isEditable && (
                                            <TableCell padding={'none'}>
                                                <IconButton color={'error'} size={'small'}>
                                                    <CloseIcon fontSize={'small'}/>
                                                </IconButton>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                {isEditable && (
                    <Button variant={'outlined'} startIcon={<AddIcon/>}>
                        Add Shift
                    </Button>
                )}

                <Box>
                    <SectionHeading
                        title={isViewingPayRates ? 'Pay Rates' : 'Bill Rates'}
                        primary
                        action={
                            <SmallButton
                                onClick={handlePayRateToggle}
                                children={
                                    <span style={{color: '#fff'}}>
                                        {isViewingPayRates ?
                                            'View Bill Rates' :
                                            'View Pay Rates'
                                        }
                                    </span>
                                }
                                className={'text__small text__thin'}
                            />
                        }
                    />
                    <TableContainer className={`table table--striped ${isEditable ? 'rate-table__container--editable' : 'rate-table__container--default'}`}>
                        <Table>
                            <colgroup>
                                <col width={rateWidth}/>
                                {shifts.map(() => <col width={rateWidth}/>)}

                                {isEditable && <col width={35}/>}
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell>
                                        Specialty
                                    </TableCell>

                                    {shifts.map(shift => (
                                        <TableCell>
                                            {shift.label}
                                        </TableCell>
                                    ))}

                                    {isEditable && <TableCell/>}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {clientSpecialties.map(clientSpecialty => {
                                    const specialty = specialties.find(specialty => specialty.id === clientSpecialty.specialtyId) || {};

                                    return (
                                        <TableRow>
                                            <TableCell>
                                                {!isEditable ? (
                                                    <>{specialty.name || '(not selected)'}</>
                                                ) : (
                                                    <FormControl variant={'standard'} fullWidth>
                                                        <Select
                                                            size={'small'}
                                                            value={specialty.id}
                                                            onChange={event => true}
                                                            {...tableInputProps}
                                                        >
                                                            {specialties.map((specialty, i) => (
                                                                <MenuItem
                                                                    key={i}
                                                                    value={specialty.id}
                                                                    children={specialty.name}
                                                                />
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                )}
                                            </TableCell>

                                            {shifts.map(shift => {
                                                const rates = isViewingPayRates ? payRates : billRates;
                                                const rate = rates.find(rate => rate.shiftId === shift.id && rate.specialtyId === specialty.id);
                                                const {value} = rate || {};

                                                if (!isEditable) {
                                                    return (
                                                        <TableCell>
                                                            ${value || 0.00}
                                                        </TableCell>
                                                    );
                                                }

                                                return (
                                                    <TableCell>
                                                        <TextField
                                                            size={'small'}
                                                            value={value}
                                                            variant={'standard'}
                                                            onChange={event => true}
                                                            fullWidth
                                                            InputProps={tableInputProps}
                                                        />
                                                    </TableCell>
                                                );
                                            })}

                                            {isEditable && (
                                                <TableCell padding={'none'}>
                                                    <IconButton color={'error'} size={'small'}>
                                                        <CloseIcon fontSize={'small'}/>
                                                    </IconButton>
                                                </TableCell>
                                            )}
                                        </TableRow>
                                    );
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Box>

                {isEditable && (
                    <Button variant={'outlined'} startIcon={<AddIcon/>}>
                        Add Rate
                    </Button>
                )}

                <Box align={'right'}>
                    {!isEditable ? (
                        <Button className={'mr__2'} onClick={handleEditOpen}>Edit</Button>
                    ) : (
                        <>
                            <Button className={'mr__2'}>Save</Button>
                            <Button color={'error'} onClick={handleEditClose}>Cancel</Button>
                        </>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default ClientPayDetails;