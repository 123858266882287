import React, {useEffect, useMemo, useState} from "react";

import App from "../../Global/App";
import API from "../../Global/API";
import File from "../../Global/File";
import Client from "../Client";
import Logger from "../../Global/Logger";
import {useAuth} from "../../Global/Auth";
import Formatter from "../../Global/Formatter";
import InputSelect from "../../Components/Input/InputSelect";
import ModelSearch from "../../Components/Input/ModelSearch";
import DialogHeading from "../../Components/Typography/DialogHeading";
import SectionHeading from "../../Components/Typography/SectionHeading";

import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import TableBody from "@mui/material/TableBody";
import TextField from "@mui/material/TextField";
import FormLabel from "@mui/material/FormLabel";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from "@mui/icons-material/Close";
import ErrorIcon from '@mui/icons-material/Error';
import IconButton from "@mui/material/IconButton";
import SaveAsIcon from '@mui/icons-material/SaveAs';
import ReplayIcon from '@mui/icons-material/Replay';
import FormControl from "@mui/material/FormControl";
import RegionSelect from 'react-region-select';
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import TableContainer from "@mui/material/TableContainer";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import FormControlLabel from "@mui/material/FormControlLabel";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import {DateTimePicker, TimePicker} from "@mui/x-date-pickers";

/**
 * TimeSheetReview component.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
const TimeSheetReview = (props) => {
    const {
        record,             // {Object} The time sheet record for review.
        variant,            // {String} An optional variant override for the button.
        doReload,           // {Function} A post-save callback function to reload the layout.
        className,          // {String} An optional class to include on the button.
    } = props;

    /**
     * The path of the download file.
     */
    const {file: path, isReviewed, isRejected, employee, employeeId} = record || {};


    /**
     * The related facilities for this employee.
     */
    const {employeeClients} = employee;


    /**
     * Returns whether the employee is assigned to just one facility.
     *
     * @type {boolean}
     */
    const hasSingleFacility = employeeClients && employeeClients.length === 1;


    /**
     * Returns the default facility ID.
     *
     * @type {unknown}
     */
    const defaultClientId = record.clientId || (hasSingleFacility ? employeeClients[0].clientId : '');


    /**
     * Various state parameters for the review component.
     */
    const {user} = useAuth();
    const [notes, setNotes] = useState(record.notes);
    const [hours, setHours] = useState(record.hours)
    const [regions, setRegions] = useState([]);
    const [isLoaded, setLoaded] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [clientId, setClientId] = useState(defaultClientId);
    const [isReviewing, setReviewing] = useState(false);
    const [isRejecting, setRejecting] = useState(false);
    const [otherReason, setOtherReason] = useState('');
    const [sendMessage, setSendMessage] = useState(false);
    const [isPreviewing, setPreviewing] = useState(false);
    const [rejectReason, setRejectReason] = useState(record.rejectReason || '');
    const [relatedShifts, setRelatedShifts] = useState([]);
    const [effectiveDate, setEffectiveDate] = useState(record.effectiveDate || '');

    /**
     * State storage related to shift manipulation, editing, deleting, etc.
     */
    const [isDeleting, setDeleting] = useState(false);
    const [shiftContext, setShiftContext] = useState({});


    /**
     * The date boundaries for this time sheet (based on the effective date).
     *
     * @type {string}
     */
    const weekEndDate = dayjs(effectiveDate)
        .endOf('week')
        .subtract(1, 'week')
        .format('YYYY-MM-DD 23:59:59');
    const weekStartDate = dayjs(effectiveDate)
        .startOf('week')
        .subtract(1, 'week')
        .format('YYYY-MM-DD 00:00:00');


    /**
     * Determines each date object for the week.
     *
     * @type {Array}
     */
    const weekDays = (() => {
        const startDateOfWeek = dayjs(weekStartDate);
        const dates = [startDateOfWeek];

        for (let i = 1; i < 7; i++) {
            dates.push(startDateOfWeek.add(i, 'days'));
        }

        return dates;
    })();


    /**
     * Tie in any additional functionality for whenever the review window is opened.
     */
    useEffect(() => {
        if (!isReviewing) {
            return;
        }

        getShiftsForPeriod();
    }, [isReviewing, effectiveDate]);


    /**
     * Calculate total hours whenever the related shifts are updated.
     */
    useEffect(() => {
        getCalculatedHoursForShifts();
    }, [relatedShifts]);


    /**
     * Retrieves the related shifts for this time sheet.
     *
     * @returns {Promise<void>}
     */
    const getShiftsForPeriod = async () => {
        const shifts = await API.get(`events`, {
            $filter: `startDate ge {${weekStartDate}} and endDate lt {${weekEndDate}} and employeeId in {${employeeId}} and isBlockRequested eq {0} and isEmployeeCancelled eq {0} and isClientCancelled eq {0} and isEmployeeNoCall eq {0} and isDeleted eq {0}`,
            $orderby: 'startDate asc'
        });

        Logger.debug(`[TimeSheetReview] Retrieved ${shifts.length} shifts for employee ${employeeId}, between: ${weekStartDate} - ${weekEndDate}`, shifts);

        setRelatedShifts(shifts.map(shift => {
            return {
                ...shift,
                break: shift.break || 0,
                punchInDate: shift.punchInDate || shift.startDate,
                punchOutDate: shift.punchOutDate || shift.endDate,
            };
        }));

        setLoaded(true);
    };


    /**
     * Calculates the total hours for the related shifts.
     */
    const getCalculatedHoursForShifts = () => {
        const calculatedHours = relatedShifts.filter(shift => !isShiftInvalid(shift)).reduce((total, shift) => total + getCalculatedHoursByShift(shift), 0);

        setHours(calculatedHours.toFixed(2));
    };


    /**
     * Indicates is the time sheet is rejected.
     */
    const bIsRejected = !!isRejected;


    /**
     * The label of the button.
     *
     * @type {string}
     */
    const label = bIsRejected ? 'Rejected' : (isReviewed ? `View File` : 'Review');


    /**
     * The color of the button.
     *
     * @type {string}
     */
    const color = bIsRejected ? 'error' : (!isReviewed ? 'warning' : 'primary');


    /**
     * The icon to use within the button.
     *
     * @type {React.JSX.Element}
     */
    const icon = bIsRejected ? <ClearIcon/> : (!isReviewed ? null : <AttachFileIcon/>);


    /**
     * Indicates if the time sheet file is an image.
     *
     * @type {RegExpMatchArray}
     */
    const isImage = (path || '').match(/\.(jpg|jpeg|png|gif)$/i);


    /**
     * Returns the inner document content.
     *
     * @returns {JSX.Element}
     * @constructor
     */
    const previewContent = useMemo(() => {
        const styles = {
            height: 'calc(100vh - 350px)',
            minHeight: 768,
            overflowY: 'scroll',
            background: '#f0f0f0'
        };

        const regionStyle = {
            background: 'rgba(255, 0, 0, 0.5)'
        };

        const changeRegionData = (index, event) => {
            const region = regions[index];

            let color;

            switch (event.target.value) {
                case '1':
                    color = 'rgba(0, 255, 0, 0.5)';
                    break;

                case '2':
                    color = 'rgba(0, 0, 255, 0.5)';
                    break;

                case '3':
                    color = 'rgba(255, 0, 0, 0.5)';
                    break;

                default:
                    color = 'rgba(0, 0, 0, 0.5)';
            }

            region.data.regionStyle = {
                background: color
            };

            handleRegionChange([
                ...regions.slice(0, index),
                Object.assign({}, region, {
                    data: Object.assign({}, region.data, {dataType: event.target.value})
                }),
                ...regions.slice(index + 1)
            ]);
        }

        const bRegions = false;

        return isImage ? (
            <Box
                sx={App.isCordova() ? {} : styles}
                children={
                    bRegions ? (
                        <RegionSelect
                            regions={regions}
                            onChange={handleRegionChange}
                            maxRegions={3}
                            regionStyle={regionStyle}
                            regionRenderer={props => {
                                if (!props.isChanging) {
                                    return (
                                        <div style={{position: 'absolute', right: 0, bottom: '-1.5em'}}>
                                            <select onChange={(event) => changeRegionData(props.index, event)}
                                                value={props.data.dataType}>
                                                <option value='1'>Green</option>
                                                <option value='2'>Blue</option>
                                                <option value='3'>Red</option>
                                            </select>
                                        </div>
                                    );
                                }
                            }}
                        >
                            <img
                                src={API.getFilePath(path)}
                                className={'v-align__top'}
                            />
                        </RegionSelect>
                    ) : (
                        <img
                            src={API.getFilePath(path)}
                            className={'v-align__top'}
                        />
                    )
                }
            />
        ) : (
            <Box
                sx={styles}
                className={'iframe__container'}
            >
                <embed
                    src={API.getFilePath(path)}
                    scrolling={'no'}
                    className={'responsive-iframe'}
                />
            </Box>
        );
    }, [path]);


    /**
     * Reveals the attachment preview.
     */
    const handlePreviewOpen = () => {
        const isOtherSelected = record.rejectReason && !rejectOptions.includes(record.rejectReason);

        // Assign any default values.
        setHours(record.hours);
        setOtherReason(isOtherSelected ? record.rejectReason : '');
        setRejectReason(isOtherSelected ? 'Other...' : record.rejectReason);

        // Reveal the modal.
        setPreviewing(true);
    };


    /**
     * Closes the attachment preview modal.
     */
    const handlePreviewClose = () => {
        setPreviewing(false);
    };


    /**
     * Reveals the reject modal.
     */
    const handleRejectOpen = () => {
        setRejecting(true);
    };


    /**
     * Closes the reject modal.
     */
    const handleRejectClose = () => {
        setRejecting(false);
    };


    /**
     * Updates the stored regions.
     *
     * @param regions
     */
    const handleRegionChange = regions => {
        setRegions(regions.map(region => {
            const {new: isNew} = region;

            if (isNew) {
                return {
                    ...region,
                    width: 25,
                    height: 25,
                };
            }

            return region;
        }));
    };


    /**
     * Returns a random string ID.
     *
     * @returns {string}
     */
    const getRandomId = () => {
        return (Math.random() + 1).toString(36).substring(7);
    };


    /**
     * Registers a new shift for a particular date.
     *
     * @param date
     */
    const addShift = (date) => {
        setRelatedShifts([
            ...relatedShifts,
            {
                '@isNew': true,
                id: getRandomId(),
                break: 0,
                clientId,
                endDate: date.format('YYYY-MM-DD 00:00:00'),
                startDate: date.format('YYYY-MM-DD 00:00:00'),
                employeeId: employeeId,
                punchInDate: date.format('YYYY-MM-DD 00:00:00'),
                punchOutDate: date.format('YYYY-MM-DD 00:00:00'),
                isBlockRequested: 0,
            }
        ]);
    };


    /**
     * Performs the download action.
     */
    const doDownload = () => {
        File.doDownload(API.getFilePath(path), 'time-sheet');
    };


    /**
     * Processes the rejection notice.
     *
     * @returns {Promise<void>}
     */
    const doApprove = async () => {
        setLoading(true);

        const timeSheet = await API.put(`time-sheets/${record.id}`, {
            hours,
            notes,
            clientId: clientId || null,
            isReviewed: !!hours,
            isRejected: false,
            rejectReason: ''
        });

        await Promise.all(relatedShifts.map(async shift => {
            const payload = {
                ...shift,
                clientId,
                employeeId: employeeId,
            };

            if(shift['@isNew']){
                delete payload['id'];
                return await API.post('events', {
                    ...payload,
                    endDate: payload.punchOutDate,
                    startDate: payload.punchInDate,
                });
            }

            return await API.put(`events/${payload.id}`, payload);
        }));

        await getShiftsForPeriod();

        if (doReload) {
            doReload(timeSheet);
        }

        setLoading(false);
        setReviewing(false);
    };


    /**
     * Processes the rejection notice.
     *
     * @returns {Promise<void>}
     */
    const doUndo = async () => {
        setLoading(true);

        const response = await API.put(`time-sheets/${record.id}`, {
            isRejected: false,
            isReviewed: false,
            rejectReason: ''
        });

        if (doReload) {
            doReload(response);
        }

        handleRejectClose();
        setLoading(false);
    }


    /**
     * Processes the rejection notice.
     *
     * @returns {Promise<void>}
     */
    const doReject = async () => {
        setLoading(true);

        const reason = rejectReason === 'Other...' ?
            otherReason :
            rejectReason;

        const response = await API.put(`time-sheets/${record.id}`, {
            hours: 0.00,
            isRejected: true,
            isReviewed: true,
            rejectReason: reason
        });

        if (sendMessage && rejectReason) {
            await API.post('messages', {
                type: 'SMS',
                content: `Time Sheet REJECTED!\n\nThis is a notice from Reliant Staffing. Your time sheet from ${Formatter.dateTime(record.createdDate)} has been rejected for the following reason:\n\n${reason}\n\nReview your time sheet, fix the issue, and resubmit ASAP!`,
                senderId: user.id,
                senderType: 'User',
                recipientId: employeeId,
                recipientType: 'Employee',
            });
        }

        if (doReload) {
            doReload(response);
        }

        handleRejectClose();
        setLoading(false);
        setReviewing(false);
    };


    /**
     * Reveals the review form fields.
     */
    const handleReviewStart = () => {
        setReviewing(true);
    };


    /**
     * Closes the review form.
     */
    const handleReviewClose = () => {
        setReviewing(false);
    };


    /**
     * Returns the shifts for the given date.
     *
     * @param date
     * @returns {*[]}
     */
    const getShiftsForDay = date => relatedShifts.filter(shift => dayjs(shift.punchInDate).isSame(date, 'day'));


    /**
     * Indicates if the shift is out of bounds in the context of the time sheet.
     *
     * @param shift
     * @returns {boolean|boolean}
     */
    const isShiftOutOfBounds = shift => !shift.punchInDate || !shift.punchOutDate || dayjs(shift.punchInDate).isBefore(dayjs(weekStartDate)) || dayjs(shift.punchInDate).isAfter(dayjs(weekEndDate));


    /**
     * Returns whether the shift is invalid (will be flagged if so).
     *
     * @param shift
     * @returns {boolean}
     */
    const isShiftInvalid = shift => !!shift.isDeleted || !!shift.isEmployeeNoCall || !!shift.isClientCancelled || !!shift.isEmployeeCancelled || isShiftOutOfBounds(shift);


    /**
     * Additional properties for the fields within the shift chip.
     *
     * @type {Object}
     */
    const eventEntryProps = {
        sx: {
            width: 32,
            marginTop: '0.3em',
            '& input': {
                fontSize: 'var(--ancillary-font-size) !important',
            },
        },
        size: 'small',
        variant: 'standard',
        InputProps: {
            disableUnderline: true
        }
    };


    /**
     * Properties for the break entry fields.
     *
     * @type {Object}
     */
    const breakEntryProps = {
        ...eventEntryProps,
        sx: {
            ...eventEntryProps.sx,
            width: 40
        }
    };


    const rejectOptions = [
        'Printed first and last name is missing or not legible',
        'Missing facility name',
        'Missing supervisor signature(s)',
        'Poor image quality (forgot to crop, blurry, bad angle, or the image is cut off)',
        'Missing dates, missing clock in/out times',
        'Other...',
    ];


    const isOtherReason = rejectReason && !rejectOptions.includes(rejectReason);


    /**
     * Returns the calculated hours for the given shift.
     *
     * @param shift
     * @returns {number}
     */
    const getCalculatedHoursByShift = shift => {
        let end = dayjs(shift.punchOutDate);
        let start = dayjs(shift.punchInDate);
        let breakHours = (shift.break || 0) / 60;

        if (end < start) {
            end = end.add(1, 'day');
        }

        const hours = Math.abs(start.diff(end, 'hours', true));
        Logger.debug(`[TimeSheetReview] Calculated ${hours} hours for shift ${shift.id}`, shift);

        return hours - breakHours;
    }


    /**
     * Updates a particular field within the shift.
     *
     * @param shift
     * @param key
     * @param value
     */
    const setShiftValue = (shift, key, value) => {
        // Attempt to find the shift in question.
        const index = relatedShifts.findIndex(s => s.id === shift.id);

        if (index === -1) {
            return;
        }

        relatedShifts[index] = {
            ...shift,
            [key]: value
        };

        setRelatedShifts([...relatedShifts]);
    }

    return path ? (
        <>
            <Button
                size={'small'}
                color={color}
                variant={variant || 'contained'}
                onClick={handlePreviewOpen}
                children={label || 'View File'}
                className={className || ''}
                startIcon={icon}
            />

            {isPreviewing && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handlePreviewClose}
                    maxWidth={isReviewing ? 'xl' : 'md'}
                    fullWidth
                >
                    <DialogHeading
                        title={"Review File"}
                        actions={
                            <IconButton
                                size={'small'}
                                onClick={handlePreviewClose}
                                children={<CloseIcon fontSize={'small'}/>}
                            />
                        }
                        noMargin
                    />
                    <Box className={'time-sheet__wrapper'}>
                        <Box className={isReviewing ? 'columns__2' : 'columns__1'}>
                            {previewContent}

                            {isReviewing && (
                                <Box>
                                    <Box className={'columns__1 pr__3'}>
                                        <SectionHeading title={'Time Sheet Details'} primary/>
                                        <Box className={'columns__3'}>
                                            <ModelSearch
                                                model={Client}
                                                label={'Client'}
                                                value={clientId}
                                                disabled={isLoading}
                                                onChange={value => setClientId(value)}
                                                renderLabel={option => !option ? '' : option.name}
                                                queryParams={{
                                                    $top: 250,
                                                    $orderby: 'name asc'
                                                }}
                                            />
                                            <DateTimePicker
                                                label={'Effective Date'}
                                                value={effectiveDate ? dayjs(effectiveDate) : null}
                                                disabled={isLoading}
                                                onChange={event => setEffectiveDate(event ? event.format('YYYY-MM-DD HH:mm:ss') : '')}
                                            />
                                            <TextField
                                                type={'number'}
                                                value={hours || ''}
                                                label={'# / Hours'}
                                                onChange={event => setHours(event.target.value)}
                                            />
                                        </Box>
                                        <SectionHeading title={'Shifts'} primary/>
                                        <TableContainer className={'table table--striped'}>
                                            <Table size={'small'}>
                                                <colgroup>
                                                    <col width={'25%'}/>
                                                    <col width={'25%'}/>
                                                    <col width={'25%'}/>
                                                    <col width={'25%'}/>
                                                    <col/>
                                                </colgroup>
                                                <TableHead>
                                                    <TableRow>
                                                        <TableCell>Date</TableCell>
                                                        <TableCell align={'center'}>Time In / Out</TableCell>
                                                        <TableCell align={'center'}>Break (m)</TableCell>
                                                        <TableCell align={'center'}>Hours</TableCell>
                                                        <TableCell/>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {weekDays.map((date, i) => (
                                                        <TableRow key={`weekday-${i}`}>
                                                            <TableCell className={'text__small'}>
                                                                <Box>
                                                                    <Box sx={{
                                                                        paddingTop: '0.3em',
                                                                        paddingBottom: '0.3em'
                                                                    }}>
                                                                        <Box>
                                                                            {date.toDate().toLocaleString('en-us', {weekday: 'long'})}
                                                                        </Box>
                                                                        <Box className={'text__light'}>
                                                                            {date.format('M/D')}
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell colSpan={3}>
                                                                <Box
                                                                    className={'columns__1 columns--small'}
                                                                >
                                                                    {getShiftsForDay(date).map((shift, j) => {
                                                                        const isInvalid = !!shift.isDeleted;

                                                                        return (
                                                                            <Chip
                                                                                sx={{
                                                                                    '& .MuiChip-label': {
                                                                                        width: '100%'
                                                                                    }
                                                                                }}
                                                                                size={'small'}
                                                                                icon={
                                                                                    !isInvalid ? (
                                                                                        <CalendarMonthIcon
                                                                                            fontSize={'small'}
                                                                                        />
                                                                                    ) : (
                                                                                        <ErrorIcon
                                                                                            fontSize={'small'}
                                                                                        />
                                                                                    )
                                                                                }
                                                                                color={isInvalid ? 'warning' : 'default'}
                                                                                label={
                                                                                    isInvalid ? (
                                                                                        <Box className={'columns__2'}>
                                                                                            <Box>
                                                                                                {dayjs(shift.punchInDate).format('HH:mm')} - {dayjs(shift.punchOutDate).format('HH:mm')}
                                                                                            </Box>
                                                                                            <Box align={'right'}>
                                                                                                This shift will be ignored.
                                                                                            </Box>
                                                                                        </Box>
                                                                                    ) : (
                                                                                        <Box className={'columns__2'}>
                                                                                            <Box
                                                                                                className={'d-flex__start mr__3'}
                                                                                            >
                                                                                                <TimePicker
                                                                                                    ampm={false}
                                                                                                    value={shift.punchInDate ? dayjs(shift.punchInDate) : null}
                                                                                                    disabled={isLoading}
                                                                                                    onChange={event => setShiftValue(shift, 'punchInDate', event ? event.format('YYYY-MM-DD HH:mm:ss') : '')}
                                                                                                    slotProps={{textField: eventEntryProps}}
                                                                                                    disableOpenPicker
                                                                                                />
                                                                                                <Box
                                                                                                    children={'-'}
                                                                                                    className={'pl__2 pr__2'}
                                                                                                />
                                                                                                <TimePicker
                                                                                                    ampm={false}
                                                                                                    value={shift.punchOutDate ? dayjs(shift.punchOutDate) : null}
                                                                                                    disabled={isLoading}
                                                                                                    onChange={event => setShiftValue(shift, 'punchOutDate', event ? event.format('YYYY-MM-DD HH:mm:ss') : '')}
                                                                                                    slotProps={{textField: eventEntryProps}}
                                                                                                    disableOpenPicker
                                                                                                />
                                                                                            </Box>
                                                                                            <Box
                                                                                                className={'columns__2'}
                                                                                            >
                                                                                                <TextField
                                                                                                    type={'number'}
                                                                                                    value={shift.break || shift.break === 0 ? shift.break : ''}
                                                                                                    onChange={event => setShiftValue(shift, 'break', event.target.value)}
                                                                                                    disabled={isLoading}
                                                                                                    {...breakEntryProps}
                                                                                                />
                                                                                                <Box
                                                                                                    sx={{marginTop: '0.38em'}}
                                                                                                    className={'text__light text__center'}
                                                                                                    children={
                                                                                                        <>{getCalculatedHoursByShift(shift).toFixed(2)}h</>
                                                                                                    }
                                                                                                />
                                                                                            </Box>
                                                                                        </Box>
                                                                                    )
                                                                                }
                                                                                onDelete={() => setShiftValue(shift, 'isDeleted', !isInvalid)}
                                                                                deleteIcon={isInvalid ? <ReplayIcon /> : null}
                                                                            />
                                                                        );
                                                                    })}
                                                                </Box>
                                                            </TableCell>
                                                            <TableCell align={'right'}>
                                                                <IconButton size={'small'}
                                                                    onClick={() => addShift(date)}>
                                                                    <AddCircleOutlineIcon
                                                                        fontSize={'small'}
                                                                    />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    ))}
                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                        <TextField
                                            rows={3}
                                            value={notes}
                                            label={'Notes'}
                                            disabled={isLoading}
                                            onChange={event => setNotes(event.target.value)}
                                            multiline
                                            fullWidth
                                        />

                                        {/*reg ot hol m ot e wk ovt*/}
                                        <Box>
                                            <Box className={'columns__1 columns--small'}>
                                                <Box className={'columns__2 columns--small'}>
                                                    <Button
                                                        onClick={doApprove}
                                                        variant={'outlined'}
                                                        children={'Save'}
                                                        disabled={isLoading}
                                                    />
                                                    <Button
                                                        color={'error'}
                                                        onClick={handleRejectOpen}
                                                        variant={'outlined'}
                                                        children={'Reject'}
                                                        disabled={isLoading}
                                                    />
                                                </Box>
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                            )}
                        </Box>

                        {!isRejected && (
                            <>
                                {!isReviewing && (
                                    <Fab
                                        sx={{
                                            left: '1em',
                                            bottom: '1em',
                                            position: 'absolute',
                                        }}
                                        color={!!isReviewed ? 'primary' : 'warning'}
                                        onClick={handleReviewStart}
                                        children={<SaveAsIcon/>}
                                    />
                                )}
                            </>
                        )}
                    </Box>

                    <DialogActions>
                        <Box className={'w__100 d-flex__justify'}>
                            <Box className={'ml__2 d-flex__center'}/>
                            <Box className={'d-flex__center'}>
                                {bIsRejected && (
                                    <Button
                                        color={'error'}
                                        onClick={handleRejectOpen}
                                        children={isRejected ? `Rejected: ${record.rejectReason}` : 'Reject'}
                                        className={'ml__2'}
                                        startIcon={isRejected ? <CloseIcon/> : null}
                                    />
                                )}
                                <Button
                                    onClick={doDownload}
                                    children={'Download'}
                                    className={'ml__2'}
                                />
                                {isReviewing && (
                                    <Button
                                        color={'error'}
                                        onClick={handleReviewClose}
                                        children={'Cancel'}
                                        className={'ml__2'}
                                    />
                                )}
                            </Box>
                        </Box>
                    </DialogActions>
                </Dialog>
            )}

            {isRejecting && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handleRejectClose}
                    maxWidth={'xs'}
                    fullWidth
                >
                    <DialogHeading
                        title={"Reject File"}
                        actions={
                            <IconButton
                                size={'small'}
                                onClick={handleRejectClose}
                                children={<CloseIcon fontSize={'small'}/>}
                            />
                        }
                        noMargin
                    />
                    <DialogContent>
                        <Box className={'columns__1 columns--small'}>
                            {bIsRejected && (
                                <Alert severity={'info'}>
                                    You've already reviewed this time sheet. <a disabled={isLoading}
                                    onClick={doUndo}>Undo?</a>
                                </Alert>
                            )}
                            <FormControl fullWidth>
                                <FormLabel
                                    required
                                    children={'What is the reason for rejection?'}
                                    className={'d-block mb__3'}
                                />
                                <InputSelect
                                    value={rejectReason}
                                    options={rejectOptions.map(option => {
                                        return {
                                            label: option,
                                            value: option
                                        };
                                    })}
                                    required
                                    disabled={isLoading || isRejected}
                                    onChange={event => setRejectReason(event.target.value)}
                                    fullWidth
                                    placeholder={'Reject Reason'}
                                />
                            </FormControl>

                            {rejectReason === 'Other...' && (
                                <TextField
                                    rows={4}
                                    value={otherReason}
                                    label={'Please enter a reason for rejection...'}
                                    disabled={isLoading || isRejected}
                                    onChange={event => setOtherReason(event.target.value)}
                                    multiline
                                    fullWidth
                                />
                            )}

                            <FormControlLabel
                                label={'Send notice to employee?'}
                                control={
                                    <Checkbox
                                        checked={sendMessage}
                                        disabled={isLoading || isRejected}
                                        onChange={event => setSendMessage(event.target.checked)}
                                    />
                                }
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        {!isRejected && (
                            <Button
                                onClick={doReject}
                                children={'Save'}
                                disabled={isLoading || !rejectReason}
                            />
                        )}
                        <Button
                            color={'error'}
                            onClick={handleRejectClose}
                            disabled={isLoading}
                            children={'Cancel'}
                        />
                    </DialogActions>
                </Dialog>
            )}
        </>
    ) : null;
};

export default TimeSheetReview;