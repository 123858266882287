import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import Logger from "../../../Global/Logger";
import Formatter from "../../../Global/Formatter";
import InputPhone from "../../../Components/Input/InputPhone";
import FileAvatar from "../../../Components/Input/FileAvatar";
import InputSelect from "../../../Components/Input/InputSelect";
import EmployeeModel from "../../../Models/Employee";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {DatePicker} from "@mui/x-date-pickers";

/**
 * ProfileForm component.
 *
 * @returns {*}
 * @constructor
 */
const ProfileForm = (props) => {
    const {
        unlock,                 // {Boolean} Whether we should unlock the protected fields.
        onSave,                 // {Function} A post-save callback after the initial save.,
        employee,               // {Object} The employee record for field context.
        readOnly,               // {Boolean} If true, will render the form without the ability to edit.
        saveLabel,              // {String} An optional replacement label for the save button.
        profileImage,           // {Boolean} If true, will include a profile image upload.
    } = props;

    const [error, setError] = useState('');
    const [states, setStates] = useState([]);
    const [record, setRecord] = useState(EmployeeModel.getInstance({...employee}));
    const [isLoading, setLoading] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);

    /**
     * Load any additional details on component mount.
     */
    useEffect(() => {
        getStates();
    }, []);


    /**
     * Synchronize the current employee with the form.
     */
    useEffect(() => {
        setRecord(EmployeeModel.getInstance(employee));
    }, [employee]);


    /**
     * Asserts whether fields are disabled or not.
     *
     * @type {boolean}
     */
    const isDisabled = readOnly || isLoading;


    /**
     * Loads all available states from the API.
     *
     * @returns {Promise<void>}
     */
    const getStates = async () => {
        setStates(
            await API.get('states')
        );
    };


    /**
     * Closes the error dialogue.
     */
    const handleErrorClose = () => {
        setError('');
    };


    /**
     * Updates a particular form value.
     *
     * @param key
     * @param value
     */
    const setValue = (key, value) => {
        Logger.debug(`[ProfileForm] Updated "${key}" to "${value}"...`);

        setRecord({
            ...record,
            [key]: value
        });
    };


    /**
     * Handles form validation.
     *
     * @returns {Promise<void>}
     */
    const doValidate = async () => {
        setSubmitted(true);

        const required = ['firstName', 'lastName', 'phoneNumber', 'birthDate', 'addressLine1', 'city', 'stateId', 'postalCode'];

        const invalid = Object.keys(record)
            .filter(key => required.includes(key) && !record[key]);

        if (!invalid.length) {
            if(profileImage && !record.image){
                setError('Please upload a profile image.')
                return;
            }

            handleSave();
        } else {
            setError('Please answer all required fields.')
        }
    };


    /**
     * Saves the employee updates.
     */
    const handleSave = async () => {
        setLoading(true);

        const payload = {
            ...record
        };

        Logger.debug('[ProfileForm] Saving employee profile...', payload);

        // Remove relations that might interfere with the backend.
        if (payload.hasOwnProperty('status')) {
            delete payload['status'];
        }

        if (payload.hasOwnProperty('specialty')) {
            delete payload['specialty'];
        }

        const response = await API.put(`employee/${record.id}`, payload);
        setLoading(false);

        // Handle the post-save callback.
        if (onSave) {
            onSave(response);
        }
    };


    /**
     * Handles the profile image upload and synchronizes the user.
     *
     * @param value
     */
    const handleImageChange = (value) => {
        setValue('image', value);
    }

    return (
        <>
            <Box className={'columns__1'} key={employee.id}>
                {profileImage ? (
                    <Box className={'d-flex__justify'}>
                        <Box className={'mr__4 text__center'}>
                            <FileAvatar
                                model={EmployeeModel}
                                field={'image'}
                                width={96}
                                height={96}
                                center
                                record={record}
                                editable
                                onChange={handleImageChange}
                            />

                            <Box className={'text__small text__light'}>
                                Add Photo
                            </Box>
                        </Box>

                        <Box className={'columns__1'}>
                            <TextField
                                label={'First Name'}
                                value={record.firstName || ''}
                                error={isSubmitted && !record.firstName}
                                required
                                disabled={isDisabled}
                                onChange={event => setValue('firstName', event.target.value)}
                            />
                            <TextField
                                label={'Last Name'}
                                value={record.lastName || ''}
                                error={isSubmitted && !record.lastName}
                                required
                                disabled={isDisabled}
                                onChange={event => setValue('lastName', event.target.value)}
                            />
                        </Box>
                    </Box>
                ) : (
                    <>
                        <TextField
                            label={'First Name'}
                            value={record.firstName || ''}
                            error={isSubmitted && !record.firstName}
                            required
                            disabled={isDisabled}
                            onChange={event => setValue('firstName', event.target.value)}
                        />
                        <TextField
                            label={'Last Name'}
                            value={record.lastName || ''}
                            error={isSubmitted && !record.lastName}
                            required
                            disabled={isDisabled}
                            onChange={event => setValue('lastName', event.target.value)}
                        />
                    </>
                )}

                <InputPhone
                    label={'Phone Number'}
                    value={record.phoneNumber || ''}
                    error={isSubmitted && !record.phoneNumber}
                    required
                    disabled={isDisabled}
                    onChange={event => setValue('phoneNumber', event.target.value)}
                />
                <DatePicker
                    label={'Birth Date *'}
                    value={record.birthDate ? dayjs(record.birthDate) : null}
                    required
                    disabled={isDisabled}
                    slotProps={{
                        textField: {
                            error: isSubmitted && !record.birthDate,
                        },
                    }}
                    onChange={event => setValue('birthDate', event ? event.format('YYYY-MM-DD') : '')}
                />
                <TextField
                    label={'Email Address'}
                    value={record.emailAddress || ''}
                    required
                    disabled={true}
                />

                {/* Only display the last four here if we're viewing as a locked preview. */}
                {!unlock && (
                    <TextField
                        value={Formatter.ssn(record.ssn)}
                        label={'Social Security Number'}
                        disabled={true}
                        placeholder={'XXX-XX-XXXX'}
                    />
                )}

                <Box className={'columns__1'}>
                    <InputLabel className={'form__heading'}>Address Information</InputLabel>
                    <Box className={'columns__1'}>
                        <TextField
                            label={'Address Line 1'}
                            value={record.addressLine1 || ''}
                            error={isSubmitted && !record.addressLine1}
                            required
                            disabled={isDisabled}
                            onChange={event => setValue('addressLine1', event.target.value)}
                        />
                        <Box className={'columns__2'}>
                            <TextField
                                label={'Address Line 2'}
                                value={record.addressLine2 || ''}
                                disabled={isDisabled}
                                onChange={event => setValue('addressLine2', event.target.value)}
                            />
                            <TextField
                                label={'Apartment #'}
                                value={record.apartmentNumber || ''}
                                disabled={isDisabled}
                                onChange={event => setValue('apartmentNumber', event.target.value)}
                            />
                        </Box>
                    </Box>
                    <Box className={'columns__1'}>
                        <TextField
                            label={'City'}
                            value={record.city || ''}
                            error={isSubmitted && !record.city}
                            required
                            disabled={isDisabled}
                            onChange={event => setValue('city', event.target.value)}
                        />
                        <Box className={'columns__1'}>
                            <FormControl>
                                <InputLabel
                                    error={isSubmitted && !record.stateId}
                                    children={'State'}
                                    required
                                />
                                <InputSelect
                                    label={'State'}
                                    value={record.stateId || ''}
                                    error={isSubmitted && !record.stateId}
                                    options={states.map(state => {
                                        return {
                                            value: state.id,
                                            label: state.name,
                                        };
                                    })}
                                    required
                                    disabled={isDisabled}
                                    onChange={event => setValue('stateId', event.target.value)}
                                />
                            </FormControl>
                            <TextField
                                label={'Postal Code'}
                                value={record.postalCode || ''}
                                error={isSubmitted && !record.postalCode}
                                required
                                disabled={isDisabled}
                                onChange={event => setValue('postalCode', event.target.value)}
                            />
                        </Box>
                    </Box>
                </Box>

                {!readOnly && (
                    <Button
                        variant={'outlined'}
                        onClick={doValidate}
                        children={saveLabel || 'Save'}
                    />
                )}
            </Box>

            <Snackbar
                open={!!error}
                onClose={handleErrorClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                autoHideDuration={4000}
            >
                <Alert
                    sx={{width: '100%'}}
                    onClose={handleErrorClose}
                    severity="error"
                >
                    {error}
                </Alert>
            </Snackbar>
        </>
    );
};

export default ProfileForm;