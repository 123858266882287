import React, {useState} from "react";

import API from "../../Global/API";
import {useAuth} from "../../Global/Auth";

import Switch from "@mui/material/Switch";

/**
 * BoundSwitch component.
 *
 * @param props
 * @returns {*}
 * @constructor
 *
 * @description A dynamically bound switch component.
 */
const BoundSwitch = (props) => {
    const {
        model,          // {Model} The input model to reference for API calls, etc.
        field,          // {String} The field name to reference.,
        record,         // {Object} The target record to base values from.
        doReload,       // {Function} A reload callback to refresh the parent layout.
        permission,     // {String} An optional permission assertion.
    } = props;

    const {hasPermissionTo} = useAuth();
    const [binding, setBinding] = useState(record && typeof record === 'object' && record.hasOwnProperty(field) ? record[field] : false);
    const [isLoading, setLoading] = useState(false);

    /**
     * Handles the record update via the API.
     *
     * @param value
     * @returns {Promise<void>}
     */
    const handleChange = async (value) => {
        if(permission && !hasPermissionTo(permission)){
            return;
        }

        setLoading(true);
        setBinding(value);

        const response = await API.put(`${model.getRoute()}/${record.id}`, {
            [field]: value
        });

        if(doReload){
            doReload(response, true);
        }

        setLoading(false);
    };

    return (
        <Switch
            onChange={event => handleChange(event.target.checked)}
            disabled={isLoading || (permission && !hasPermissionTo(permission))}
            defaultChecked={!!binding}
        />
    )
};

export default BoundSwitch;