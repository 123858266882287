import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import Page from "../../Components/Page";
import Panel from "../../Components/Panel";
import {useAuth} from "../../Global/Auth";
import BannerAlert from "../../Components/Typography/BannerAlert";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import Select from "@mui/material/Select";
import Divider from "@mui/material/Divider";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Accordion from "@mui/material/Accordion";
import TableHead from "@mui/material/TableHead";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import BedtimeIcon from '@mui/icons-material/Bedtime';
import LightModeIcon from '@mui/icons-material/LightMode';
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import PhonelinkSetupIcon from '@mui/icons-material/PhonelinkSetup';

/**
 * CallSettings component.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const CallSettings = () => {
    const {hasPermissionTo} = useAuth();
    const [users, setUsers] = useState([]);
    const [routing, setRouting] = useState({});
    const [variant, setVariant] = useState('');
    const [isLoaded, setLoaded] = useState(false);
    const [schedule, setSchedule] = useState({});
    const [settings, setSettings] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [extensions, setExtensions] = useState({});
    const [isInitialized, setInitialized] = useState(false);

    /**
     * Indicates whether the user has permission to edit communications.
     *
     * @type {Boolean}
     */
    const bEditable = hasPermissionTo('EDIT_COMMUNICATIONS');


    /**
     * Indicates if the fields are disabled.
     *
     * @type {boolean}
     */
    const isDisabled = !bEditable || isLoading;


    /**
     * Loads all ancillary data on mount.
     */
    useEffect(() => {
        getUsers();
        getSettings();
    }, []);


    /**
     * Synchronize the active caller.
     */
    useEffect(() => {
        sync('CS_VARIANT', variant);
    }, [variant]);


    /**
     * Synchronize the route mapping.
     */
    useEffect(() => {
        sync('CS_ROUTING', JSON.stringify(routing));
    }, [routing]);


    /**
     * Synchronize the extensions mapping.
     */
    useEffect(() => {
        sync('CS_EXTENSIONS', JSON.stringify(extensions));
    }, [extensions]);


    /**
     * Synchronize the evening schedule mapping.
     */
    useEffect(() => {
        sync('CS_SCHEDULE', JSON.stringify(schedule));
    }, [schedule]);


    /**
     * Synchronize setting values to the various state parameters.
     */
    useEffect(() => {
        if (!isLoaded || isInitialized) {
            return;
        }

        setVariant(setting('CS_VARIANT', ''));
        setRouting(JSON.parse(setting('CS_ROUTING', '{}')));
        setSchedule(JSON.parse(setting('CS_SCHEDULE', '{}')));
        setExtensions(JSON.parse(setting('CS_EXTENSIONS', '{}')));

        setTimeout(() => {
            setInitialized(true);
        }, 500);
    }, [isLoaded]);


    /**
     * Properties for the daylight / evening toggle icon.
     *
     * @type {{sx: {marginRight: string, opacity: number}, fontSize: string}}
     */
    const iconProps = {
        fontSize: 'small',
        sx: {
            opacity: 0.8,
            marginRight: '0.3em'
        }
    };


    /**
     * All of the available extension options.
     *
     * @type {number[]}
     */
    const extensionOptions = [811, 822, 833, 844, 855, 866, 877, 888, 899, 911, 922, 933, 944];


    /**
     * The available day options within the schedule.
     *
     * @type {string[]}
     */
    const dayOptions = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];


    /**
     * The available week options within the evening schedule.
     *
     * @type {*[]}
     */
    const weekOptions = (() => {
        const options = [];
        const todayJs = dayjs().startOf('week').add(1, 'day');

        for (let i = 0; i < 4; i++) {
            options.push(todayJs.add(i, 'week'));
        }

        return options;
    })();


    /**
     * The calculated width of each column within the evening schedule.
     *
     * @type {number}
     */
    const scheduleHeadingWidth = 100 / dayOptions.length;


    /**
     * Returns a setting value.
     *
     * @param key
     * @param fallback
     * @returns {*}
     */
    const setting = (key, fallback) => {
        return settings.hasOwnProperty(key) ?
            settings[key].value : fallback;
    };


    /**
     * Retrieves the current user list for various auto-complete fields on the form.
     *
     * @returns {Promise<void>}
     */
    const getUsers = async () => {
        setUsers(
            await API.get('users', {
                $filter: 'displayName ne {}'
            })
        );
    };


    /**
     * Assigns a particular route mapping value.
     *
     * @param key
     * @param value
     */
    const setRoutingValue = (key, value) => {
        setRouting({...routing, [key]: value});
    }


    /**
     * Assigns a particular extension mapping value.
     *
     * @param key
     * @param value
     */
    const setExtensionValue = (key, value) => {
        setExtensions({...extensions, [key]: value});
    }


    /**
     * Assigns a particular schedule mapping value.
     *
     * @param key
     * @param value
     */
    const setScheduleValue = (key, value) => {
        const condensedSchedule = {};
        const startingDateJs = weekOptions[0];

        // To avoid growing this list indefinitely, we'll remove anything that
        // occurs before the first week's option.
        for (let i in schedule) {
            if (!schedule.hasOwnProperty(i)) {
                continue;
            }

            const scheduleDayJs = dayjs(i);

            if (scheduleDayJs.isBefore(startingDateJs)) {
                continue;
            }

            condensedSchedule[i] = schedule[i];
        }

        setSchedule({...condensedSchedule, [key]: value});
    }


    /**
     * Loads all setting values.
     *
     * @returns {Promise<void>}
     */
    const getSettings = async () => {
        setLoading(true);

        const response = await API.get('settings', {
            $top: 1000,
            $filter: `modelType eq {Office} and modelId in {1} and key eq {"CS_}`,
            $orderby: 'id desc'
        });

        const formatted = {};

        response.map(setting => {
            if (!formatted.hasOwnProperty(setting.key)) {
                formatted[setting.key] = setting
            }
        });

        setLoaded(true);
        setLoading(false);
        setSettings({
            ...formatted
        });
    };


    /**
     * Synchronizes a setting key.
     *
     * @param key
     * @param value
     * @returns {Promise<void>}
     */
    const sync = async (key, value) => {
        if (!isLoaded || !isInitialized) {
            return;
        }

        let existing = {};

        if (settings.hasOwnProperty(key)) {
            existing = settings[key];
        }

        // Bind the setting storage.
        const response = existing && existing.id ?
            await API.put(`settings/${existing.id}`, {
                value
            }) : await API.post(`settings`, {
                key,
                value,
                modelId: 1,
                modelType: 'Office'
            });

        // Update the settings to include the new value.
        setSettings({
            ...settings,
            [key]: response
        });
    };


    /**
     * UserSearch component.
     *
     * @param props
     * @returns {JSX.Element}
     * @constructor
     */
    const UserSearch = (props) => {
        const {
            label,                  // {String} The label of the input field.
            value,                  // {String} The current value of the input field.
            inline,                 // {Boolean} An optional flag to indicate an inline label.
            onChange,               // {Function} The callback function to invoke when the value changes.
        } = props;

        return (
            <FormControl>
                {!inline ? (
                    <Typography gutterBottom>
                        {label}
                    </Typography>
                ) : (
                    <InputLabel>
                        {label}
                    </InputLabel>
                )}
                <Select
                    value={value}
                    label={inline ? label : ''}
                    disabled={isDisabled}
                    onChange={event => onChange(event.target.value)}
                    children={
                        users.map(user =>
                            <MenuItem
                                key={user.id}
                                value={user.id}
                                children={user.displayName}
                            />
                        )
                    }
                />
            </FormControl>
        );
    }

    return (
        <Page hideHeader fullScreen flex>
            <Box className={'page__heading'}>
                <Box className={'d-flex__justify w__100'}>
                    <Box className={'index__title d-flex__start flex__grow'}>
                        <PhonelinkSetupIcon/>
                        <h2>Call Settings</h2>
                    </Box>

                    <Box className={'d-flex__start'}>
                        <FormControl
                            variant={'standard'}
                            className={'mr__3'}
                        >
                            <Select
                                sx={{width: 150}}
                                value={variant}
                                disabled={isDisabled}
                                onChange={event => setVariant(event.target.value)}

                            >
                                <MenuItem value={'Daylight'}>
                                    <Box className={'d-flex__start'}>
                                        <LightModeIcon {...iconProps}/>
                                        <Box>Daylight</Box>
                                    </Box>
                                </MenuItem>
                                <MenuItem value={'Evening'}>
                                    <Box className={'d-flex__start'}>
                                        <BedtimeIcon {...iconProps}/>
                                        <Box>Evening</Box>
                                    </Box>
                                </MenuItem>
                            </Select>
                        </FormControl>
                    </Box>

                    <Box className={'d-flex__start'}>
                        <BannerAlert
                            severity={'info'}
                            children={'All updates are applied automatically.'}
                        />
                    </Box>
                </Box>
            </Box>
            <Divider/>
            <Box className={'flex__grow p__4 badge__workspace'}>
                <Box className={'columns__1'} sx={{gridTemplateColumns: '0.25fr 0.75fr'}}>
                    <Box className={'columns__1'}>
                        <Panel heading={'Daylight Routing'} padding={0}>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                >
                                    <Box className={'d-flex__start'}>
                                        <InputLabel>Default Prompts</InputLabel>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box className={'columns__1'}>
                                        <UserSearch
                                            value={routing.healthcareFacilityCancelPromptId}
                                            label={'"If you are a healthcare facility and need to cancel..."'}
                                            onChange={value => setRoutingValue('healthcareFacilityCancelPromptId', value)}
                                        />
                                        <UserSearch
                                            value={routing.employeeRunningLatePromptId}
                                            label={'"If you are an employee and running late or need to call off..."'}
                                            onChange={value => setRoutingValue('employeeRunningLatePromptId', value)}
                                        />
                                        <UserSearch
                                            value={routing.assistanceWithBenefitsPromptId}
                                            label={'"For assistance with benefits, payroll, viewing paystubs, password access or any technology related issue..."'}
                                            onChange={value => setRoutingValue('assistanceWithBenefitsPromptId', value)}
                                        />
                                        <UserSearch
                                            value={routing.recruiterPromptId}
                                            label={'"To speak with a recruiter regarding employment or the hiring process..."'}
                                            onChange={value => setRoutingValue('recruiterPromptId', value)}
                                        />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                >
                                    <Box className={'d-flex__start'}>
                                        <InputLabel>Scheduler Options</InputLabel>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box className={'columns__1'}>
                                        <UserSearch
                                            value={routing.schedulerOptionOnePromptId}
                                            label={'"For Dana, press ONE"'}
                                            onChange={value => setRoutingValue('schedulerOptionOnePromptId', value)}
                                        />

                                        <UserSearch
                                            value={routing.schedulerOptionTwoPromptId}
                                            label={'"For Stephanie, press TWO"'}
                                            onChange={value => setRoutingValue('schedulerOptionTwoPromptId', value)}
                                        />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>

                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon/>}
                                >
                                    <Box className={'d-flex__start'}>
                                        <InputLabel>Recruiter Options</InputLabel>
                                    </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <Box className={'columns__1'}>
                                        <UserSearch
                                            value={routing.recruiterOptionOnePromptId}
                                            label={'"For Theresa, press ONE"'}
                                            onChange={value => setRoutingValue('recruiterOptionOnePromptId', value)}
                                        />

                                        <UserSearch
                                            value={routing.recruiterOptionTwoPromptId}
                                            label={'"For Tanya, press TWO"'}
                                            onChange={value => setRoutingValue('recruiterOptionTwoPromptId', value)}
                                        />

                                        <UserSearch
                                            value={routing.recruiterOptionThreePromptId}
                                            label={'"For Terry, press THREE"'}
                                            onChange={value => setRoutingValue('recruiterOptionThreePromptId', value)}
                                        />
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                        </Panel>
                        <Panel heading={'Extensions'} padding={0}>
                            <Box className={'columns__1 columns--small p__3'} sx={{maxHeight: 350, overflowY: 'scroll'}}>
                                {extensionOptions.map(extension => (
                                    <UserSearch
                                        key={extension}
                                        value={extensions[extension]}
                                        label={extension}
                                        inline
                                        onChange={value => setExtensionValue(extension, value)}
                                    />
                                ))}
                            </Box>
                        </Panel>
                    </Box>
                    <Box>
                        <Panel heading={'Evening Schedule'}>
                            <Table size={'small'}>
                                <colgroup>
                                    {dayOptions.map((option, i) => (
                                        <col
                                            key={i}
                                            width={`${scheduleHeadingWidth}%`}
                                        />
                                    ))}
                                </colgroup>
                                <TableHead>
                                    <TableRow>
                                        {dayOptions.map(option => (
                                            <TableCell
                                                key={option}
                                                children={<b>{option}</b>}
                                            />
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {weekOptions.map((week, i) => {
                                        return (
                                            <TableRow key={i}>
                                                {dayOptions.map((option, j) => {
                                                    const dayValue = week.add(j, 'days').format('MM/DD/YYYY');

                                                    return (
                                                        <TableCell key={option}
                                                            sx={{padding: '0.5em', paddingTop: !i ? '1em' : '0.5em'}}>
                                                            <Box className={'columns__1'}>
                                                                <UserSearch
                                                                    key={option}
                                                                    label={dayValue}
                                                                    value={schedule[dayValue]}
                                                                    inline
                                                                    onChange={value => setScheduleValue(dayValue, value)}
                                                                />
                                                            </Box>
                                                        </TableCell>
                                                    )
                                                })}
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        </Panel>
                    </Box>
                </Box>
            </Box>
        </Page>
    );
};

export default CallSettings;