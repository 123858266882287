import React, {useState} from "react";

import DialogHeading from "../../Components/Typography/DialogHeading";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import VoicemailIcon from '@mui/icons-material/Voicemail';

/**
 * VoiceMailModal constructor.
 *
 * @constructor
 */
const VoiceMailModal = (props) => {
    const {
        record,             // {Object} The call log entry.
        recipient,          // {Object} The recipient entry.
    } = props;

    const [isOpen, setOpen] = useState(false);

    /**
     * Build out the call label.
     *
     * @type {*}
     */
    const vmDuration = parseInt(record.vmDuration || 0);
    const vmMinutes = Math.floor(vmDuration / 60).toString().padStart(2, '0')
    const vmSeconds = (vmDuration - vmMinutes * 60).toString().padStart(2, '0');
    const vmLabel = `${vmMinutes}:${vmSeconds}s`;

    /**
     * Reveals the voicemail dialog.
     */
    const handleOpen = () => {
        setOpen(true);
    };


    /**
     * Closes the voicemail dialog.
     */
    const handleClose = () => {
        setOpen(false);
    };

    return record.voicemail ? (
        <Box className={'ml__2'}>
            <Chip
                size={'small'}
                icon={<VoicemailIcon/>}
                label={vmLabel}
                onClick={handleOpen}
                clickable
            />

            {isOpen && (
                <Dialog
                    open={isOpen}
                    scroll={'body'}
                    onClose={handleClose}
                    maxWidth={'sm'}
                    fullWidth
                >
                    <DialogHeading
                        title={`Voicemail from ${recipient.name || recipient.displayName || '(no name)'}`}
                        noMargin
                    />
                    <DialogContent>
                        <Box className={'columns__1'}>
                            <audio controls style={{width: '100%'}}>
                                <source src={record.voicemail} type="audio/mpeg"/>
                            </audio>

                            <Box className={'well__container transcription__block p__3'}>
                                <b>Transcription:</b> {record.transcription || 'No transcription available.'}
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
            )}
        </Box>
    ) : null;
};

export default VoiceMailModal;