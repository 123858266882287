import React, {useEffect, useState} from "react";

import App from "../Global/App";
import Page from "../Components/Page";
import State from "../Global/State";
import Panel from "../Components/Panel";
import {useAuth} from '../Global/Auth';
import Formatter from "../Global/Formatter";
import CopyrightDisclaimer from "./Login/CopyrightDisclaimer";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CampaignIcon from '@mui/icons-material/Campaign';
import {useLocation, useNavigate} from "react-router";

/**
 * Login component.
 *
 * @returns {*}
 * @constructor
 */
const Login = () => {
    const {state} = useLocation();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const {authenticated, login} = useAuth();
    const [password, setPassword] = useState(State.get('login-last-password') || '');
    const [isLoading, setLoading] = useState(false);
    const [emailAddress, setEmailAddress] = useState(State.get('login-last-email') || '');

    /**
     * If the user is already authenticated, redirect to the dashboard.
     */
    useEffect(() => {
        if (authenticated) {
            navigate('/');
        }
    }, []);


    /**
     * Attempt to log in using the provided account credentials.
     */
    const doLogin = () => {
        setLoading(true);

        login(emailAddress, password)
            .then(() => {
                // Need to do a hard load here to refresh the top navigation, otherwise
                // the user will be directed elsewhere in the site without menu items.
                //
                navigate( state && state.path ? state.path : "/")
            })
            .catch((message) => {
                setLoading(false);
                setError(message);
            });
    };


    /**
     * Allow login on enter key.
     *
     * @param event
     */
    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
            doLogin();
        }
    };

    return (
        <Page hideHeader>
            <Box className={'columns__1'}>
                <Panel
                    header={
                        <Box
                            className={'text__center'}
                            children={
                                <img
                                    alt={'Login logo'}
                                    src={Formatter.url('assets/images/logo.png')}
                                    style={{width: '70%'}}
                                />
                            }
                        />
                    }
                    padding={0}
                    className={'login__block'}
                    hideDivider
                >
                    <form autoComplete="on">
                        <Box className={'columns__1'} sx={{padding: '1.5em', paddingTop: 0}}>
                            {App.isCordova() && (
                                <Alert
                                    icon={<CampaignIcon fontSize={'inherit'}/>}
                                    onClick={() => navigate('/register')}
                                    severity={'warning'}
                                    className={'text__small registration__banner cursor__pointer'}
                                >
                                    <b>Invited to apply?</b> Click here to register.
                                </Alert>
                            )}

                            {error && <Alert color={'error'} children={error}/>}

                            <TextField
                                name={'email'}
                                value={emailAddress}
                                label={'Email Address'}
                                required
                                disabled={isLoading}
                                onChange={event => setEmailAddress(event.target.value)}
                                onKeyDown={handleKeyDown}
                                fullWidth
                                autoComplete={'email'}
                            />

                            <TextField
                                name={'password'}
                                type={'password'}
                                value={password}
                                label={'Password'}
                                required
                                disabled={isLoading}
                                onChange={event => setPassword(event.target.value)}
                                onKeyDown={handleKeyDown}
                                autoComplete={'current-password'}
                            />
                            <div className={'columns__2'}>
                                <Button
                                    sx={{padding: '1em', textTransform: 'capitalize'}}
                                    onClick={doLogin}
                                    variant={'contained'}
                                    children={'Login'}
                                    disabled={isLoading}
                                />
                                <Button
                                    sx={{padding: '1em', textTransform: 'capitalize'}}
                                    variant={'outlined'}
                                    onClick={() => navigate('/forgot-password')}
                                    disabled={isLoading}
                                    children={'Forgot Password?'}
                                />
                            </div>

                            <CopyrightDisclaimer/>
                        </Box>
                    </form>
                </Panel>
            </Box>
        </Page>
    );
};

export default Login;