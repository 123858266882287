import React, {useState} from "react";

import CommunicationFeed from "./CommunicationFeed";

import Fab from "@mui/material/Fab";
import Dialog from "@mui/material/Dialog";
import SendIcon from '@mui/icons-material/Send';
import IconButton from "@mui/material/IconButton";

/**
 * QuickSendWidget component.
 *
 * @constructor
 */
const QuickSendWidget = (props) => {
    const {
        icon,
        record
    } = props;

    const [anchorEl, setAnchorEl] = useState(null);

    /**
     * Indicates whether the results window is open.
     *
     * @type {boolean}
     */
    const open = Boolean(anchorEl);


    /**
     * Reveals the search window.
     *
     * @param event
     */
    const handleOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Closes the search window.
     */
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            {icon ? (
                <IconButton color="primary" onClick={handleOpen} variant={'contained'}>
                    {icon}
                </IconButton>
            ) : (
                <Fab color="primary" onClick={handleOpen}>
                    <SendIcon/>
                </Fab>
            )}

            {open && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handleClose}
                    maxWidth={'lg'}
                    fullWidth
                >
                    <CommunicationFeed
                        onClose={handleClose}
                        condensed
                        defaultSelection={record}
                        hideEmployeeSelect
                    />
                </Dialog>
            )}
        </>
    );
};

export default QuickSendWidget;