import React from "react";

import Page from "../Components/Page";

/**
 * NotFound component.
 *
 * @returns {*}
 * @constructor
 */
const NotFound = () => {
    return (
        <Page ignoreMobileRestriction>
            <div className={'not__found text__disclaimer'}>
                Sorry, this page could not be found.
            </div>
        </Page>
    );
};

export default NotFound;