import React, {useState} from "react";

import API from "../Global/API";
import Page from "../Components/Page";
import Panel from "../Components/Panel";
import Formatter from "../Global/Formatter";
import CopyrightDisclaimer from "./Login/CopyrightDisclaimer";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { useNavigate} from "react-router";

/**
 * ForgotPassword component.
 *
 * @returns {*}
 * @constructor
 */
const ForgotPassword = () => {
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');

    /**
     * Attempt to process the password reset.
     */
    const doReset = async () => {
        setError('');

        if(!emailAddress) {
            return setError('The email address is required.');
        }

        setLoading(true);

        await API.post('password-reset', {
            emailAddress
        });

        setSubmitted(true);
        setLoading(false);
    };


    /**
     * Allow login on enter key.
     *
     * @param event
     */
    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
            doReset();
        }
    };

    return (
        <Page hideHeader>
            <Box className={'columns__1'}>
                <Panel
                    header={
                        <Box
                            className={'text__center'}
                            children={
                                <img
                                    alt={'Login logo'}
                                    src={Formatter.url('assets/images/logo.png')}
                                    style={{width: '70%'}}
                                />
                            }
                        />
                    }
                    padding={0}
                    className={'login__block'}
                    hideDivider
                >
                    <Box className={'columns__1'} sx={{padding: '1.5em', paddingTop: 0}}>
                        {!isSubmitted ? (
                            <>
                                {error && <Alert color={'error'} children={error}/>}

                                <Box>Please enter your email address below to receive reset instructions.</Box>

                                <TextField
                                    value={emailAddress}
                                    label={'Email Address'}
                                    required
                                    disabled={isLoading}
                                    onChange={event => setEmailAddress(event.target.value)}
                                    onKeyDown={handleKeyDown}
                                />

                                <div className={'columns__2'}>
                                    <Button
                                        sx={{padding: '1em', textTransform: 'capitalize'}}
                                        onClick={doReset}
                                        variant={'contained'}
                                        disabled={isLoading}
                                        children={'Send Reset Email'}
                                    />

                                    <Button
                                        sx={{padding: '1em', textTransform: 'capitalize'}}
                                        variant={'outlined'}
                                        onClick={() => navigate('/login')}
                                        disabled={isLoading}
                                        children={'Back'}
                                    />
                                </div>

                                <CopyrightDisclaimer/>
                            </>
                        ) : (
                            <>
                                <Alert severity={'success'}>Successfully sent instructions!</Alert>

                                <Box>
                                    If we found a match in our system for the email address you provided, you
                                    should be receiving instructions shortly.
                                </Box>

                                <Button
                                    sx={{padding: '1em', textTransform: 'capitalize'}}
                                    onClick={() => navigate('/login')}
                                    variant={'outlined'}
                                    disabled={isLoading}
                                    children={'Home'}
                                />
                            </>
                        )}
                    </Box>
                </Panel>
            </Box>
        </Page>
    );
};

export default ForgotPassword;