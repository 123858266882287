import React from "react";

import Map from "../Pages/Admin/Map";
import Login from "../Pages/Login";
import Users from "../Pages/Admin/Users";
import States from "../Pages/Admin/States";
import Client from "../Pages/Admin/Client";
import Course from "../Pages/Employee/Course";
import Clients from "../Pages/Admin/Clients";
import Reports from "../Pages/Admin/Reports";
import Recruit from "../Pages/Admin/Recruit";
import Profile from "../Pages/Employee/Profile";
import TimeOff from "../Pages/Employee/TimeOff";
import Register from "../Pages/Register";
import NotFound from "../Pages/NotFound";
import Download from "../Pages/Download";
import Training from "../Pages/Admin/Training";
import Employee from "../Pages/Admin/Employee";
import Statuses from "../Pages/Admin/Statuses";
import CallLogs from "../Pages/Admin/CallLogs";
import Schedule from "../Pages/Admin/Schedule";
import Benefits from "../Pages/Employee/Benefits";
import AddShift from "../Pages/Employee/AddShift";
import Settings from "../Pages/Admin/SystemSettings";
import Dashboard from "../Pages/Dashboard";
import Employees from "../Pages/Admin/Employees";
import Templates from "../Pages/Admin/Templates";
import Marketing from "../Pages/Admin/Marketing";
import Documents from "../Pages/Employee/Documents";
import TimeSheet from "../Pages/Employee/TimeSheet";
import Recruiting from "../Pages/Admin/Recruiting";
import TimeSheets from "../Pages/Admin/TimeSheets";
import Invitation from "../Pages/Employee/Invitation";
import HirePacket from "../Pages/Employee/HirePacket";
import BadgeEditor from "../Pages/Admin/BadgeEditor";
import Specialties from "../Pages/Admin/Specialties";
import Departments from "../Pages/Admin/Departments";
import OfferLetter from "../Pages/Employee/OfferLetter";
import ReferFriend from "../Pages/Employee/ReferFriend";
import Disclosures from "../Pages/Employee/Disclosures";
import AdminProfile from "../Pages/Admin/Profile";
import CallSettings from "../Pages/Admin/CallSettings";
import CourseEditor from "../Pages/Admin/CourseEditor";
import Availability from "../Pages/Employee/Availability";
import RequestBadge from "../Pages/Employee/RequestBadge";
import {RequireAuth} from "./Auth";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import ResetPassword from "../Pages/ResetPassword";
import DocumentTypes from "../Pages/Admin/DocumentTypes";
import Communication from "../Pages/Admin/Communication";
import DirectDeposit from "../Pages/Employee/DirectDeposit";
import AddShiftHelix from "../Pages/Employee/AddShiftHelix";
import ForgotPassword from "../Pages/ForgotPassword";
import EmployeeTraining from "../Pages/Employee/Training";
import EmployeeSchedule from "../Pages/Employee/Schedule";
import EmployeeTimeSheets from "../Pages/Employee/TimeSheets";

import {createBrowserRouter, createHashRouter, Link} from "react-router-dom";

/**
 * Define each of the URL endpoints.
 *
 * @type {Array<Object>}
 */
const RoutesList = [
    {
        path: "/",
        element: <RequireAuth children={<Dashboard/>}/>,
        errorElement: <NotFound/>
    }, {
        path: "/map",
        element: <RequireAuth children={<Map/>} scope={'User'} permission={'VIEW_EMPLOYEES'}/>,
        handle: {
            crumb: () => <Link to="/map">Map</Link>,
        }
    }, {
        path: "/map/:latitude/:longitude",
        element: <RequireAuth children={<Map/>} scope={'User'} permission={'VIEW_EMPLOYEES'}/>,
        handle: {
            crumb: () => <Link to="/map">Map</Link>,
        }
    }, {
        path: "/clients",
        element: <RequireAuth children={<Clients/>} scope={'User'} permission={'VIEW_CLIENTS'}/>,
        handle: {
            crumb: () => <Link to="/clients">Clients</Link>,
        }
    }, {
        path: "/clients/:id",
        element: <RequireAuth children={<Client/>} scope={'User'} permission={'VIEW_CLIENTS'}/>,
        handle: {
            crumb: () => <Link to="/clients/">View Client</Link>,
        }
    }, {
        path: "/employees",
        element: <RequireAuth children={<Employees/>} scope={'User'} permission={'VIEW_EMPLOYEES'}/>,
        handle: {
            crumb: () => <Link to="/employees">Employees</Link>,
        }
    }, {
        path: "/employees/:id",
        element: <RequireAuth children={<Employee/>} scope={'User'} permission={'VIEW_EMPLOYEES'}/>,
        handle: {
            crumb: () => <Link to="/employees">View Employee</Link>,
        }
    }, {
        path: "/employees/:id/badge",
        element: <RequireAuth children={<BadgeEditor/>} scope={'User'} permission={'EDIT_EMPLOYEES'}/>,
        handle: {
            crumb: () => <Link to="/employee/">View Employee</Link>,
        }
    }, {
        path: "/recruiting",
        element: <RequireAuth children={<Recruiting/>} scope={'User'} permission={'VIEW_RECRUITING'}/>,
        handle: {
            crumb: () => <Link to="/recruiting">Recruiting</Link>,
        }
    }, {
        path: "/recruiting/:id",
        element: <RequireAuth children={<Recruit/>} scope={'User'} permission={'VIEW_RECRUITING'}/>,
        handle: {
            crumb: () => <Link to="/recruiting">View Recruit</Link>,
        }
    }, {
        path: "/document-types",
        element: <RequireAuth children={<DocumentTypes/>} scope={'User'} permission={'VIEW_LIBRARIES'}/>,
        handle: {
            crumb: () => <Link to="/specialties">Document Types</Link>,
        }
    }, {
        path: "/specialties",
        element: <RequireAuth children={<Specialties/>} scope={'User'} permission={'VIEW_LIBRARIES'}/>,
        handle: {
            crumb: () => <Link to="/specialties">Specialties</Link>,
        }
    }, {
        path: "/statuses",
        element: <RequireAuth children={<Statuses/>} scope={'User'} permission={'VIEW_LIBRARIES'}/>,
        handle: {
            crumb: () => <Link to="/statuses">Statuses</Link>,
        }
    }, {
        path: "/departments",
        element: <RequireAuth children={<Departments/>} scope={'User'} permission={'VIEW_LIBRARIES'}/>,
        handle: {
            crumb: () => <Link to="/departments">Departments</Link>,
        }
    }, {
        path: "/time-sheets",
        element: <RequireAuth children={<TimeSheets/>} scope={'User'} permission={'VIEW_REPORTS'}/>,
        handle: {
            crumb: () => <Link to="/time-sheets">Time Sheets</Link>,
        }
    }, {
        path: "/states",
        element: <RequireAuth children={<States/>} scope={'User'} permission={'VIEW_LIBRARIES'}/>,
        handle: {
            crumb: () => <Link to="/states">States</Link>,
        }
    }, {
        path: "/schedule",
        element: <RequireAuth children={<Schedule/>} scope={'User'} permission={'VIEW_SCHEDULE'}/>,
        handle: {
            crumb: () => <Link to="/schedule">Schedule</Link>,
        }
    }, {
        path: "/schedule/:recordType/:recordId",
        element: <RequireAuth children={<Schedule/>} scope={'User'} permission={'VIEW_SCHEDULE'}/>,
        handle: {
            crumb: () => <Link to="/schedule">Schedule</Link>,
        }
    }, {
        path: "/users",
        element: <RequireAuth children={<Users/>} scope={'User'} permission={'VIEW_LIBRARIES'}/>,
        handle: {
            crumb: () => <Link to="/users">Users</Link>,
        }
    }, {
        path: "/communication",
        element: <RequireAuth children={<Communication/>} scope={'User'} permission={'VIEW_COMMUNICATIONS'}/>,
        handle: {
            crumb: () => <Link to="/communication">Communication</Link>,
        }
    }, {
        path: "/templates",
        element: <RequireAuth children={<Templates/>} scope={'User'} permission={'VIEW_COMMUNICATIONS'}/>,
        handle: {
            crumb: () => <Link to="/templates">Templates</Link>,
        }
    }, {
        path: "/marketing",
        element: <RequireAuth children={<Marketing/>} scope={'User'} permission={'VIEW_COMMUNICATIONS'}/>,
        handle: {
            crumb: () => <Link to="/marketing">Marketing</Link>,
        }
    }, {
        path: "/training",
        element: <RequireAuth children={<Training/>} scope={'User'} permission={'VIEW_TRAINING'}/>,
        handle: {
            crumb: () => <Link to="/training">Training</Link>,
        }
    }, {
        path: "/training/editor/:id?",
        element: <RequireAuth children={<CourseEditor/>} scope={'User'} permission={'VIEW_TRAINING'}/>,
        handle: {
            crumb: () => <Link to="/training">Training</Link>,
        }
    }, {
        path: "/login",
        element: <Login/>,
        handle: {
            crumb: () => <Link to="/login">Login</Link>,
        }
    }, {
        path: "/download",
        element: <Download/>,
        handle: {
            crumb: () => <Link to="/download">Download</Link>,
        }
    }, {
        path: "/register",
        element: <Register/>,
        handle: {
            crumb: () => <Link to="/register">Register</Link>,
        }
    }, {
        path: "/privacy-policy",
        element: <PrivacyPolicy/>,
        handle: {
            crumb: () => <Link to="/privacy-policy">Privacy Policy</Link>,
        }
    }, {
        path: "/forgot-password",
        element: <ForgotPassword/>,
        handle: {
            crumb: () => <Link to="/forgot-password">Forgot Password</Link>,
        }
    }, {
        path: "/reset-password/:token?",
        element: <ResetPassword/>,
        handle: {
            crumb: () => <Link to="/reset-password">Reset Password</Link>,
        }
    }, {
        path: "/profile",
        element: <RequireAuth children={<Profile/>} scope={'Employee'}/>,
        handle: {
            crumb: () => <Link to="/profile">My Profile</Link>,
        }
    }, {
        path: "/settings",
        element: <RequireAuth children={<AdminProfile/>} scope={'User'}/>,
        handle: {
            crumb: () => <Link to="/settings">My Profile</Link>,
        }
    }, {
        path: "/system-settings",
        element: <RequireAuth children={<Settings/>} scope={'User'} permission={'VIEW_SETTINGS'}/>,
        handle: {
            crumb: () => <Link to="/system-settings">Settings</Link>,
        }
    }, {
        path: "/hire-packet",
        element: <RequireAuth children={<HirePacket/>} scope={'Employee'}/>,
        handle: {
            crumb: () => <Link to="/hire-packet">Hire Packet</Link>,
        }
    }, {
        path: "/offer-letter",
        element: <RequireAuth children={<OfferLetter/>} scope={'Employee'}/>,
        handle: {
            crumb: () => <Link to="/offer-letter">Disclosures</Link>,
        }
    }, {
        path: "/invitation",
        element: <RequireAuth children={<Invitation/>} scope={'Employee'}/>,
        handle: {
            crumb: () => <Link to="/invitation">Invitation</Link>,
        }
    }, {
        path: "/availability",
        element: <RequireAuth children={<Availability/>} scope={'Employee'}/>,
        handle: {
            crumb: () => <Link to="/availability">Availability</Link>,
        }
    }, {
        path: "/employee/training",
        element: <RequireAuth children={<EmployeeTraining/>} scope={'Employee'}/>,
        handle: {
            crumb: () => <Link to="/employee/training">Training</Link>,
        }
    }, {
        path: "/employee/documents",
        element: <RequireAuth children={<Documents/>} scope={'Employee'}/>,
        handle: {
            crumb: () => <Link to="/employee/documents">Documents</Link>,
        }
    }, {
        path: "/employee/schedule",
        element: <RequireAuth children={<EmployeeSchedule/>} scope={'Employee'}/>,
        handle: {
            crumb: () => <Link to="/employee/schedule">Schedule</Link>,
        }
    }, {
        path: "/employee/time-sheets",
        element: <RequireAuth children={<EmployeeTimeSheets/>} scope={'Employee'}/>,
        handle: {
            crumb: () => <Link to="/employee/time-sheets">Time Sheets</Link>,
        }
    }, {
        path: "/employee/direct-deposit",
        element: <RequireAuth children={<DirectDeposit/>} scope={'Employee'}/>,
        handle: {
            crumb: () => <Link to="/employee/direct-deposit">Direct Deposit</Link>,
        }
    }, {
        path: "/employee/add-shift",
        element: <RequireAuth children={<AddShift/>} scope={'Employee'}/>,
        handle: {
            crumb: () => <Link to="/employee/add-shift">Shift Pick Up</Link>,
        }
    }, {
        path: "/employee/add-shift-helix",
        element: <RequireAuth children={<AddShiftHelix/>} scope={'Employee'}/>,
        handle: {
            crumb: () => <Link to="/employee/add-shift-helix">Add Shift to Helix</Link>,
        }
    }, {
        path: "/employee/badge-request",
        element: <RequireAuth children={<RequestBadge/>} scope={'Employee'}/>,
        handle: {
            crumb: () => <Link to="/employee/badge-request">Request a Badge</Link>,
        }
    }, {
        path: "/employee/benefits",
        element: <RequireAuth children={<Benefits/>} scope={'Employee'}/>,
        handle: {
            crumb: () => <Link to="/employee/benefits">Benefits</Link>,
        }
    }, {
        path: "/employee/time-off-request",
        element: <RequireAuth children={<TimeOff/>} scope={'Employee'}/>,
        handle: {
            crumb: () => <Link to="/employee/time-off-request">Time Off Request</Link>,
        }
    }, {
        path: "/employee/time-sheet",
        element: <RequireAuth children={<TimeSheet/>} scope={'Employee'}/>,
        handle: {
            crumb: () => <Link to="/employee/time-sheet">Time Sheet</Link>,
        }
    }, {
        path: "/employee/disclosures",
        element: <RequireAuth children={<Disclosures/>} scope={'Employee'}/>,
        handle: {
            crumb: () => <Link to="/employee/disclosures">Disclosures</Link>,
        }
    }, {
        path: "/refer-friend",
        element: <RequireAuth children={<ReferFriend/>} scope={'Employee'}/>,
        handle: {
            crumb: () => <Link to="/refer-friend">Refer a Friend</Link>,
        }
    }, {
        path: "/training/course/:id?",
        element: <RequireAuth children={<Course/>} scope={'Employee'}/>,
        handle: {
            crumb: () => <Link to="/training">Training</Link>,
        }
    }, {
        path: "/reports",
        element: <RequireAuth children={<Reports/>} scope={'User'} permission={'VIEW_REPORTS'}/>,
        handle: {
            crumb: () => <Link to="/reports">Reports</Link>,
        }
    }, {
        path: "/call-logs",
        element: <RequireAuth children={<CallLogs/>} scope={'User'} permission={'VIEW_COMMUNICATIONS'}/>,
        handle: {
            crumb: () => <Link to="/call-logs">Call Logs</Link>,
        }
    }, {
        path: "/call-settings",
        element: <RequireAuth children={<CallSettings/>} scope={'User'} permission={'VIEW_COMMUNICATIONS'}/>,
        handle: {
            crumb: () => <Link to="/call-settings">Call Settings</Link>,
        }
    }
];


/**
 * HashRouter instance.
 *
 * @type {Object}
 *
 * @description Used for Cordova (Android, iOS, etc.) builds.
 */
const HashRouter = createHashRouter(RoutesList);


/**
 * BrowserRouter instance.
 *
 * @type {Object}
 *
 * @description Used for Cordova (Android, iOS, etc.) builds.
 */
const BrowserRouter = createBrowserRouter(RoutesList);

export {
    HashRouter,
    BrowserRouter,
};