import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import Logger from "../../Global/Logger";
import Clipboard from "../../Global/Clipboard";
import TextEditor from "../../Components/Input/TextEditor";
import DialogHeading from "../../Components/Typography/DialogHeading";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import {Portal} from "@mui/material";
import Snackbar from "@mui/material/Snackbar";
import {DatePicker} from "@mui/x-date-pickers";
import DownloadIcon from "@mui/icons-material/Download";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LinearProgress from "@mui/material/LinearProgress";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

/**
 * ExportShiftsDialog component.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
const ExportShiftsDialog = (props) => {
    const {
        open,           // {Boolean} Indicates whether the dialog is open.
        record,         // {Object} The client record for context.
        onClose,        // {Function} The close handler for the dialog.
    } = props;

    /**
     * State parameters related to the shift export dialog.
     */
    const [isLoading, setLoading] = useState(false);
    const [shiftMarkup, setShiftMarkup] = useState('');
    const [copySuccess, setCopySuccess] = useState(false);
    const [upcomingShifts, setUpcomingShifts] = useState([]);
    const [isViewingShifts, setViewingShifts] = useState(false);
    const [hasShiftResults, setHasShiftResults] = useState(false);
    const [scheduleEndDate, setScheduleEndDate] = useState(dayjs().endOf('month').format('YYYY-MM-DD'));
    const [scheduleStartDate, setScheduleStartDate] = useState(dayjs().startOf('month').format('YYYY-MM-DD'));

    /**
     * Synchronize the shift markup with the dates if loaded at least once.
     */
    useEffect(() => {
        if (!hasShiftResults || !scheduleStartDate || !scheduleEndDate) {
            return;
        }

        doExportShifts();
    }, [scheduleStartDate, scheduleEndDate]);



    /**
     * Exports the shift markup for the client.
     *
     * @returns {Promise<void>}
     */
    const doExportShifts = async () => {
        let promises = [];
        let response = [];
        let perPageLimit = 500;

        setLoading(true);

        // Determine how many shifts meet criteria for this facility.
        const count = await API.get('events/count', {
            $top: perPageLimit,
            $filter: `startDate ge {${scheduleStartDate}} and startDate le {${scheduleEndDate}} and client/any{id in {${record.id}}} and isBlockRequested eq {0} and isDeleted eq {0}`,
        })

        Logger.debug('[ExportShiftDialog] Received count response:', count);

        for (let page = 0; page < count.pages; page++) {
            promises.push(
                API.get('events', {
                    $top: perPageLimit,
                    $skip: page * perPageLimit,
                    $expand: 'client,employee($expand=specialty)',
                    $filter: `startDate ge {${scheduleStartDate}} and startDate le {${scheduleEndDate}} and client/any{id in {${record.id}}} and employee/any{isDeleted eq {0}} and isBlockRequested eq {0} and isDeleted eq {0}`,
                    $orderby: 'startDate asc'
                })
            );
        }

        try {
            const results = await Promise.all(promises);
            Logger.debug('[ExportShiftDialog] Aggregated responses:', results);
            response = results.flat();
        } catch (e) {
            Logger.warn('[ExportShiftDialog] Unable to export shift data.', e);
            return setLoading(false);
        }

        // Group calendar items by formatted date.
        const days = {};

        response.map(shift => {
            const dateFormatted = dayjs(shift.startDate).format('MM/DD/YYYY');

            if (!days.hasOwnProperty(dateFormatted)) {
                days[dateFormatted] = [];
            }

            days[dateFormatted].push(shift);
        });

        // Compile the markup from the groupings.
        const content = Object.keys(days).map(day => {
            const shifts = days[day];
            const items = shifts.map(shift => {
                const {employee} = shift || {};
                const {specialty} = employee || {};
                const endDate = dayjs(shift.endDate).format('h:mm A');
                const startDate = dayjs(shift.startDate).format('h:mm A');

                if (!employee.id) {
                    return '';
                }

                return `<li>${employee.firstName} ${employee.lastName} (${specialty.name}): ${startDate} - ${endDate}</li>`;
            }).join('');

            if (!shifts) {
                return;
            }

            return `<div><b>${day}</b><ul>${items}</ul></div>`;
        }).join('');
        setShiftMarkup(`<p>Hello ${record.name},</p><p>Your schedule from ${dayjs(scheduleStartDate).format('MM/DD/YYYY')} to ${dayjs(scheduleEndDate).format('MM/DD/YYYY')} is outlined ahead.</p><br/>${content}`);

        // Update the frontend.
        setLoading(false);
        setUpcomingShifts(response);
        setHasShiftResults(true);
    };


    /**
     * Closes the shift export modal.
     */
    const handleShiftExportClose = () => {
        setViewingShifts(false)
        setHasShiftResults(false);

        if(onClose){
            onClose();
        }
    };


    /**
     * Closes the success message.
     */
    const handleCopySuccessClose = () => setCopySuccess(false);


    /**
     * Opens the success message.
     */
    const handleCopySuccessOpen = () => setCopySuccess(true);


    /**
     * Copies the markup content to the clipboard.
     */
    const doCopyMarkup = () => {
        Clipboard.doCopy(shiftMarkup, handleCopySuccessOpen, {html: true});
    };


    /**
     * Downloads the shifts as a PDF document.
     *
     * @returns {Promise<void>}
     */
    const getShiftsAsPdf = async () => {
        setLoading(true);

        // Create the PDF document.
        const response = await API.post('pdf', {
            html: shiftMarkup,
            heading: `Shift Results for ${record.name}`
        });

        // Download the document.
        await new Promise(resolve => {
            fetch(API.getFilePath(response.path), {
                method: 'GET'
            }).then(resp => resp.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = `${record.name}-shifts.pdf`;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    resolve();
                })
        });

        setLoading(false);
    };

    return (
        <>
            <Dialog
                open={open}
                scroll={'body'}
                onClose={onClose}
                maxWidth={'sm'}
                fullWidth
            >
                {isLoading && <LinearProgress/>}
                <DialogHeading
                    title={"Export Shifts"}
                    noMargin
                />
                <DialogContent>
                    <Box className={'columns__1'}>
                        <Box>
                            Please select a date range to download shifts for:
                        </Box>
                        <Box className={'columns__2'}>
                            <DatePicker
                                label={'Start Date'}
                                value={scheduleStartDate ? dayjs(scheduleStartDate) : null}
                                disabled={isLoading}
                                onChange={event => setScheduleStartDate(event ? event.format('YYYY-MM-DD') : '')}
                            />

                            <DatePicker
                                label={'End Date'}
                                value={scheduleEndDate ? dayjs(scheduleEndDate) : null}
                                disabled={isLoading}
                                onChange={event => setScheduleEndDate(event ? event.format('YYYY-MM-DD') : '')}
                            />
                        </Box>
                    </Box>
                </DialogContent>

                {hasShiftResults && (
                    <Box sx={{paddingLeft: '1.75em', paddingRight: '1.75em'}}>
                        <TextEditor
                            value={shiftMarkup}
                            height={350}
                            onChange={value => setShiftMarkup(value)}
                        />
                    </Box>
                )}

                <DialogActions>
                    {!hasShiftResults ? (
                        <>
                            <Button
                                onClick={doExportShifts}
                                children={'Download'}
                                disabled={isLoading || !scheduleStartDate || !scheduleEndDate}
                            />
                            <Button
                                color={'error'}
                                onClick={handleShiftExportClose}
                                children={'Cancel'}
                                disabled={isLoading}
                            />
                        </>
                    ) : (
                        <>
                            <Button
                                onClick={getShiftsAsPdf}
                                disabled={isLoading}
                                children={'Save as PDF'}
                                startIcon={<DownloadIcon/>}
                            />
                            <Button
                                onClick={doCopyMarkup}
                                disabled={isLoading}
                                children={'Copy to Clipboard'}
                                startIcon={<ContentCopyIcon/>}
                            />
                        </>

                    )}
                </DialogActions>
            </Dialog>

            {copySuccess && (
                <Portal>
                    <Snackbar open={true} autoHideDuration={6000} onClose={handleCopySuccessClose}>
                        <Alert onClose={handleCopySuccessClose} severity={'success'}>
                            Copied successfully!
                        </Alert>
                    </Snackbar>
                </Portal>
            )}
        </>
    );
};

export default ExportShiftsDialog;