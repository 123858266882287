import React, {useEffect, useRef, useState} from "react";

import App from "../Global/App";
import API from "../Global/API";
import State from "../Global/State";
import Logger from "../Global/Logger";
import Formatter from "../Global/Formatter";
import ImageWrapper from "./ImageWrapper";
import DialogHeading from "./Typography/DialogHeading";

import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import Alert from "@mui/material/Alert";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import MenuItem from "@mui/material/MenuItem";
import AppsIcon from '@mui/icons-material/Apps';
import LockIcon from '@mui/icons-material/Lock';
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import FolderIcon from '@mui/icons-material/Folder';
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import TableRowsIcon from '@mui/icons-material/TableRows';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import LinearProgress from "@mui/material/LinearProgress";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

/**
 * FileBrowser component.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const FileBrowser = props => {
    const {
        basePath,           // {String} The base path in S3 to pull files for.
        selectable,         // {Boolean} Indicates if this is a folder selection.
        onPathChange,       // {Function} The callback to invoke when a file is selected (if selectable is true).
    } = props;

    const [index, setIndex] = useState(0);
    const [paths, setPaths] = useState([]);
    const [files, setFiles] = useState([]);
    const [rename, setRename] = useState('');
    const [folder, setFolder] = useState({});
    const [context, setContext] = useState({});
    const [isMoving, setMoving] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [selection, setSelection] = useState([]);
    const [isRenaming, setRenaming] = useState(false);
    const [isGridView, setGridView] = useState(State.get('file-view-preference') === 'list' ? false : true);
    const [isPreviewing, setPreviewing] = useState(false);
    const [selectedPath, setSelectedPath] = useState('');
    const [menuAnchorEl, setMenuAnchorEl] = useState(null);
    const [isAddingFiles, setAddingFiles] = useState(false);
    const [isAddingFolder, setAddingFolder] = useState(false);
    const [uploadProgress, setUploadProgress] = useState([]);
    const [isConfirmingDelete, setConfirmingDelete] = useState(false);
    const [isProgressCollapsed, setProgressCollapsed] = useState(false);
    const [isCurrentlyUploading, setCurrentlyUploading] = useState(false);

    /**
     * The current path to the model's files.
     *
     * @type {string}
     */
    const path = paths.join('/');


    /**
     * Returns the last path component.
     *
     * @type {*|string}
     */
    const lastPath = paths.length ? paths[paths.length - 1] : '';


    /**
     * The current upload target (basically the joined path).
     *
     * @type {string}
     */
    const uploadPath = `${basePath}${path ? `/${path}` : ''}`;


    /**
     * The uploader input element.
     *
     * @type {React.MutableRefObject<null>}
     */
    const fileRef = useRef(null);


    /**
     * Returns the appropriate label for the action context.
     *
     * @type {string}
     */
    const contextLabel = context.folder ? 'folder' : 'file';


    /**
     * Returns an array of incomplete uploads.
     *
     * @type {*[]}
     */
    const incompleteUploads = uploadProgress.filter(upload => upload.status === 'In Progress');


    /**
     * Synchronize window events against the hook state.
     */
    useEffect(() => {
        window.addEventListener(
            'uploadprogress',
            handleProgressUpload
        );

        return () => {
            window.removeEventListener(
                'uploadprogress',
                handleProgressUpload
            )
        }
    }, [uploadProgress]);


    /**
     * Loads documents on initial mount.
     */
    useEffect(() => {
        getFiles();
    }, [path]);


    /**
     * Stores the last view selection in local storage.
     */
    useEffect(() => {
        State.set('file-view-preference', isGridView ? 'grid' : 'list');
    }, [isGridView]);


    /**
     * Synchronize the upload path for selections (move dialogs, etc.).
     */
    useEffect(() => {
        if (onPathChange) {
            onPathChange(uploadPath);
        }
    }, [uploadPath]);


    /**
     * Reveals the delete confirmation dialog.
     */
    const handleDeleteConfirmOpen = () => {
        handleMenuClose();
        setConfirmingDelete(true);
    };


    /**
     * Closes the delete confirmation dialog.
     */
    const handleDeleteConfirmClose = () => {
        if (isLoading) {
            return;
        }

        setConfirmingDelete(false);
    };


    /**
     * Returns the filename without extension.
     *
     * @param key
     * @returns {*}
     */
    const getNameWithoutExtension = (key) => {
        return getName(key).replace(/\.[^/.]+$/, "");
    };


    /**
     * Reveals the rename dialog.
     */
    const handleRenameOpen = () => {
        handleMenuClose();

        // Ignore the file extension.
        setRename(getNameWithoutExtension(context.key));
        setRenaming(true);
    };


    /**
     * Closes the rename dialog.
     */
    const handleRenameClose = () => {
        if (isLoading) {
            return;
        }

        setRenaming(false);
    };


    /**
     * Processes the user update event.
     */
    const handleProgressUpload = async (event) => {
        const {index, status} = event.detail;

        setUploadProgress((uploadProgress) => {
            const updates = [...uploadProgress];
            Logger.debug(`[FileBrowser] Received progress event:`, event.detail, updates[index]);

            updates[index] = {
                ...updates[index],
                ...event.detail
            };

            if (updates[index].progress === 100) {
                updates[index].status = 'Success';
            }

            return updates;
        });

        if (status === 'Success') {
            await getFilesQuiet();
        }
    };


    /**
     * Loads files via the API.
     *
     * @returns {Promise<void>}
     */
    const getFiles = async (bKeepSelection = false) => {
        setLoading(true);
        setFiles(await API.get(`files?path=${uploadPath}`));

        if (bKeepSelection !== true) {
            setSelection([]);
        }

        setLoading(false);
    };


    /**
     * Quietly reloads the files.
     *
     * @returns {Promise<void>}
     */
    const getFilesQuiet = async () => {
        Logger.debug('[FileBrowser] Reloading files...');
        setFiles(await API.get(`files?path=${uploadPath}`));
    };


    /**
     * Returns the last component of a key path.
     *
     * @param key
     */
    const getName = (key) => {
        const parts = (key.split('/') || []).filter(part => !!part.trim());
        return parts[parts.length - 1];
    };


    /**
     * Assigns a folder property.
     *
     * @param key
     * @param value
     */
    const setFolderProperty = (key, value) => {
        setFolder({
            ...folder,
            [key]: value
        });
    }


    /**
     * Returns the files sorted by folder first, then alphabetically.
     */
    const sortedFiles = files.sort((a, b) => {
        if (a.folder && !b.folder) {
            return -1;
        } else if (!a.folder && b.folder) {
            return 1;
        } else {
            return getName(a.key).localeCompare(getName(b.key));
        }
    });


    /**
     * Returns the files filtered by folder.
     *
     * @type {*[]}
     */
    const filteredFiles = sortedFiles;


    /**
     * Various properties to include against each of the icons.
     *
     * @type {{fontSize: string}}
     */
    const iconParams = {
        fontSize: 'large'
    };


    /**
     * Updates the path iterator to include the new path segment.
     *
     * @param path
     */
    const getFilesForPath = (path) => {
        if (path === '..') {
            setPaths(paths.slice(0, paths.length - 1));
        } else {
            setPaths([...paths, path]);
        }
    };


    /**
     * Handles navigation via breadcrumb index.
     *
     * @param index
     */
    const handleBreadcrumbClick = (index) => {
        if (!index) {
            setPaths([]);
            return;
        }

        setPaths(paths.slice(0, index));
    };


    /**
     * Returns the appropriate thumbnail for the file.
     *
     * @param key
     * @returns {Element}
     */
    const getThumbnailForFile = (key) => {
        const fileName = getName(key);
        const isImage = (key || '').match(/\.(jpg|jpeg|png|gif)$/i);

        if (isImage) {
            return (
                <ImageWrapper
                    sx={{
                        textAlign: 'center',
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        marginBottom: '0.5em'
                    }}
                    src={`${API.getFilePath(key)}&noThumbnail=true`}
                    width={36}
                    height={36}
                    horizontal
                />
            );
        }

        return (
            <InsertDriveFileIcon {...iconParams}/>
        );
    };


    /**
     * Handles file / folder selection.
     *
     * @param event
     * @param file
     * @param fileName
     */
    const handleSelect = (event, file, fileName) => {
        const isSelected = selection.includes(file.key);
        const isMultiple = !selectable && ((event && event.shiftKey) || !event);

        if (event.detail === 1) {
            if (!isMultiple) {
                setSelection([file.key])
            } else {
                if (!isSelected) {
                    setSelection([...selection, file.key]);
                } else {
                    setSelection([...selection.filter(selected => selected !== file.key)])
                }
            }
        } else {
            if (isSelected && file.folder) {
                getFilesForPath(fileName);
            }

            if (isSelected && !file.folder) {
                onPreviewOpen(file);
            }
        }
    };


    /**
     * Returns the inner document content.
     *
     * @returns {JSX.Element}
     * @constructor
     */
    const PreviewContent = () => {

        // Determine if this file is an image, and if so, place within an <img/> tag.
        const fileName = getName(context.key);
        const isImage = (fileName || '').match(/\.(jpg|jpeg|png|gif)$/i);

        return isImage ? (
            <Box
                sx={App.isCordova() ? {} : {
                    maxHeight: 'calc(100vh - 350px)',
                    overflowY: 'scroll'
                }}
                children={
                    <img src={API.getFilePath(context.key)}/>
                }
            />
        ) : (
            // Otherwise, fallback to the iframe.
            <Box className={'iframe__container'}>
                <embed
                    src={API.getFilePath(context.key)}
                    scrolling={'no'}
                    className={'responsive-iframe'}
                />
            </Box>
        );
    };


    /**
     * Closes the file preview window.
     */
    const onPreviewClose = () => {
        handleMenuClose();
        setPreviewing(false);
    };


    /**
     * Reveals the content preview window.
     *
     * @param record
     */
    const onPreviewOpen = (record) => {
        setContext(record);
        setPreviewing(true);
    };


    /**
     * Indicates if there is a folder selected (certain bulk actions don't allow for folders).
     *
     * @type {boolean}
     */
    const hasFolderSelected = files
        .filter(file => selection.includes(file.key))
        .some(file => !!file.folder);


    /**
     * Indicates if a protected folder is selected.
     *
     * @type {boolean}
     */
    const hasProtectedFolderSelected = files
        .filter(file => selection.includes(file.key))
        .some(file => !!file.folder && !!file.protected);


    /**
     * Performs the file download.
     */
    const doDownload = () => {
        fetch(API.getFilePath(context.key))
            .then(response => response.status === 200 ? response.blob() : Promise.reject('Failed to download.'))
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = getName(context.key);
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            }).catch(() => alert('Failed to download.'));
    };


    /**
     * Processes the move request for files.
     *
     * @returns {Promise<void>}
     */
    const doMove = async () => {
        setLoading(true);

        const responses = await Promise.all(selection.map(key => (
            API.post(`files/rename`, {
                path: key,
                name: getNameWithoutExtension(key),
                targetPath: selectedPath,
            })
        )))

        Logger.debug(`[FileBrowser] Moved selection:`, responses, selectedPath);
        await getFiles();

        setMoving(false);
        setLoading(false);
    };


    /**
     * Processes the rename request for files.
     *
     * @returns {Promise<void>}
     */
    const doRename = async () => {
        setLoading(true);

        const response = await API.post(`files/rename`, {
            path: context.key,
            name: rename,
        });

        Logger.debug(`[FileBrowser] Renamed file:`, response);
        await getFiles();

        setContext({});
        setLoading(false);
        setRenaming(false);
    };


    /**
     * Processes the delete request for files / folders.
     *
     * @returns {Promise<void>}
     */
    const doDelete = async () => {
        setLoading(true);

        const responses = await Promise.all(
            selection.map(key => (
                API.delete(`file?path=${key}`)
            ))
        );

        Logger.debug(`[FileBrowser] Deleted selection:`, responses);
        await getFiles();

        setContext({});
        setPreviewing(false);
        setConfirmingDelete(false);
        setLoading(false);
    };


    /**
     * Reveals the move dialog.
     */
    const handleMoveOpen = () => {
        handleMenuClose();
        setMoving(true);
    };


    /**
     * Closes the move dialog.
     */
    const handleMoveClose = () => {
        if (isLoading) {
            return;
        }

        setMoving(false);
    };


    /**
     * Reveals the "Add Files" dialog.
     */
    const handleAddFilesOpen = () => {
        setIndex(index + 1);
        setAddingFiles(true);
    };


    /**
     * Closes the "Add Files" dialog.
     */
    const handleAddFilesClose = () => {
        setAddingFiles(false);
    };


    /**
     * Reveals the "Add Folder" dialog.
     */
    const handleAddFolderOpen = () => {
        setFolder({});
        setAddingFolder(true);
    };


    /**
     * Closes the "Add Folder" dialog.
     */
    const handleAddFolderClose = () => {
        setAddingFolder(false);
    };


    /**
     * Performs a save to the folder context.
     */
    const doAddFolder = async () => {
        setLoading(true);

        const response = await API.post(`files/folder`, {
            ...folder,
            path: uploadPath,
        })

        await getFiles(true)
        handleAddFolderClose();
    };


    /**
     * Handles the file upload.
     */
    const handleFilesUpload = () => {
        setCurrentlyUploading(true);
        const files = fileRef.current.files;

        setUploadProgress([
            ...uploadProgress,
            ...[...files].map((file, i) => {
                const {name} = file;
                const index = uploadProgress.length + i;

                API.doFileUpload({
                    file: file,
                    path: uploadPath,
                    types: []
                }, () => {
                    window.dispatchEvent(
                        new CustomEvent('uploadprogress', {
                            detail: {
                                index,
                                status: 'Success'
                            }
                        })
                    );
                }, () => {
                    window.dispatchEvent(
                        new CustomEvent('uploadprogress', {
                            detail: {
                                index,
                                status: 'Error'
                            }
                        })
                    );
                }, (progress) => {
                    window.dispatchEvent(
                        new CustomEvent('uploadprogress', {
                            detail: {
                                index,
                                progress,
                            }
                        })
                    );
                });

                return {
                    file,
                    name,
                    index,
                    status: 'In Progress',
                    progress: 0
                };
            })
        ]);

        handleAddFilesClose();
    };


    /**
     * Reveals the file context menu.
     *
     * @param event
     * @param file
     */
    const handleFileMenuClick = (event, file) => {
        setContext(file);
        setMenuAnchorEl(event.currentTarget);
    };


    /**
     * Closes the file context menu.
     */
    const handleMenuClose = () => {
        setMenuAnchorEl(null);
    };


    /**
     * Toggles the progress list expand / collapse.
     */
    const toggleUploadProgressExpand = () => {
        setProgressCollapsed(!isProgressCollapsed);
    };


    /**
     * Removes any progress activity from the queue.
     */
    const clearUploadProgressQueue = () => {
        setUploadProgress([]);
        setProgressCollapsed(false);
        setCurrentlyUploading(false);
    };


    /**
     * Reveals the preview modal.
     */
    const handlePreviewOpen = () => {
        setPreviewing(true);
        handleMenuClose();
    };

    return (
        <Box className={`document__list ${isGridView ? 'document-list__grid' : 'document-list__table'}`}>
            {isLoading && <LinearProgress/>}

            {selection.length > 1 && (
                <Box className={'document-list__bulk-actions'}>
                    <Box className={'d-flex__justify w__100'}>
                        <Box>
                            You have {selection.length} files selected.
                        </Box>
                        <Box className={'d-flex__start'}>
                            <Button
                                onClick={handleMoveOpen}
                                children={'Move To...'}
                                disabled={hasFolderSelected}
                            />
                            <Button
                                color={'error'}
                                onClick={handleDeleteConfirmOpen}
                                children={'Delete'}
                            />
                        </Box>
                    </Box>
                </Box>
            )}

            <Box className={'p__3 columns__1 columns--small'}>
                <Box>
                    <Box className={'breadcrumbs'}>
                        <Box className={'w__100 d-flex__justify'}>
                            <Box className={'d-flex__start'}>
                                {['Home', ...paths].map((path, i) => {
                                    const isLast = i === paths.length;

                                    return (
                                        <>
                                            <Box className={`crumb ${isLast ? 'text__bold' : ''}`}>
                                                <a onClick={() => handleBreadcrumbClick(i)}>{path}</a>
                                            </Box>

                                            {!isLast && (
                                                <Box className={'divider'}/>
                                            )}
                                        </>
                                    )
                                })}
                            </Box>

                            <Box className={'d-flex__start'}>
                                <a className={`crumb`} onClick={handleAddFilesOpen}>
                                    <Box>Add Files</Box>
                                </a>
                                <Box className={'divider'}/>
                                <a className={`crumb`} onClick={handleAddFolderOpen}>
                                    <Box>Add Folder</Box>
                                </a>
                                <a className={`crumb`}>
                                    {!isGridView ? (
                                        <AppsIcon
                                            sx={{fontSize: '1.3em'}}
                                            onClick={() => setGridView(true)}
                                        />
                                    ) : (
                                        <TableRowsIcon
                                            sx={{fontSize: '1.3em'}}
                                            onClick={() => setGridView(false)}
                                        />
                                    )}
                                </a>
                            </Box>
                        </Box>
                    </Box>
                </Box>

                {!filteredFiles.length ? (
                    <Box className={'text__center text__disclaimer'}>
                        No files available.
                    </Box>
                ) : (
                    <Box className={`${isGridView ? 'columns__4 columns--small files__container' : ''}`}>
                        {filteredFiles.map(file => {
                            let fileName = getName(file.key);

                            // fileName = lastPath === fileName ? '..' : fileName;
                            let isSelected = selection.includes(file.key);
                            let className = `file__wrapper ${isLoading ? 'file__wrapper--disabled' : ''} ${isSelected ? 'file__wrapper--selected' : ''}`;

                            return file.folder ? (
                                <a
                                    onClick={event => handleSelect(event, file, fileName)}
                                    className={className}
                                >
                                    <Box className={'file__thumbnail'}>
                                        {file.protected ? (
                                            <FolderSharedIcon {...iconParams}/>
                                        ) : (
                                            <FolderIcon {...iconParams}/>
                                        )}
                                    </Box>

                                    <Box className={'file__details'}>
                                        <Box className={'text__bold file__label'}>
                                            {fileName}/
                                        </Box>

                                        <IconButton
                                            size={'small'}
                                            onClick={event => handleFileMenuClick(event, file)}
                                            className={'file__menu'}
                                        >
                                            <MoreHorizIcon fontSize={'small'}/>
                                        </IconButton>
                                    </Box>
                                </a>
                            ) : (
                                <a
                                    className={`${className} ${selectable ? 'file__non-selectable' : ''}`}
                                    onClick={event => !selectable ? handleSelect(event, file, fileName) : null}
                                >
                                    <Box className={'file__thumbnail'}>
                                        {getThumbnailForFile(file.key)}
                                    </Box>

                                    <Box className={'file__details'}>
                                        <Box className={'text__bold file__label'}>
                                            {fileName}
                                        </Box>

                                        <Box className={'text__small text__light'}>
                                            {Formatter.dateTime(file.lastModified)}
                                        </Box>

                                        <IconButton
                                            size={'small'}
                                            onClick={event => handleFileMenuClick(event, file)}
                                            className={'file__menu'}
                                        >
                                            <MoreHorizIcon fontSize={'small'}/>
                                        </IconButton>
                                    </Box>
                                </a>
                            )
                        })}

                        <Menu
                            open={!!menuAnchorEl}
                            onClose={handleMenuClose}
                            anchorEl={menuAnchorEl}
                        >
                            {context.folder ? [
                                <MenuItem
                                    key={'file-menu__folder-delete'}
                                    onClick={handleDeleteConfirmOpen}
                                    children={context.protected ? `Unable to Delete (Locked)` : 'Delete Folder'}
                                    disabled={!!context.protected}
                                    className={'menu__error'}
                                />
                            ] : [
                                <MenuItem
                                    key={'file-menu__file-move'}
                                    onClick={handlePreviewOpen}
                                    children={'Preview File'}
                                />,
                                <Divider key={'file-menu__file-divider'}/>,
                                <MenuItem
                                    key={'file-menu__file-move'}
                                    onClick={handleMoveOpen}
                                    children={'Move To...'}
                                />,
                                <MenuItem
                                    key={'file-menu__file-download'}
                                    onClick={doDownload}
                                    children={'Download'}
                                />,
                                <MenuItem
                                    key={'file-menu__file-rename'}
                                    onClick={handleRenameOpen}
                                    children={'Rename File'}
                                />,
                                <Divider key={'file-menu__file-divider'}/>,
                                <MenuItem
                                    key={'file-menu__file-delete'}
                                    children={'Delete File'}
                                    onClick={handleDeleteConfirmOpen}
                                    className={'menu__error'}
                                />
                            ]}
                        </Menu>
                    </Box>
                )}
            </Box>

            <Snackbar
                open={isCurrentlyUploading && uploadProgress.length}
                message={
                    <Box
                        sx={{minWidth: 275}}
                        className={'columns__1 columns--small'}
                    >
                        <Box className={`w__100 d-flex__justify ${!isProgressCollapsed ? 'mb__2' : ''}`}>
                            {incompleteUploads.length ? (
                                <b>Upload Progress ({incompleteUploads.length})</b>
                            ) : (
                                <b>{uploadProgress.length === 1 ? 'Upload' : 'Uploads'} Complete!</b>
                            )}

                            <Box className={'d-flex__start'}>
                                <IconButton size={'small'} onClick={toggleUploadProgressExpand}>
                                    {isProgressCollapsed ? (
                                        <ExpandMoreIcon sx={{color: '#fff'}}/>
                                    ) : (
                                        <ExpandLessIcon sx={{color: '#fff'}}/>
                                    )}
                                </IconButton>

                                {!incompleteUploads.length && (
                                    <IconButton size={'small'} onClick={clearUploadProgressQueue}>
                                        <CloseIcon sx={{color: '#fff'}} fontSize={'small'}/>
                                    </IconButton>
                                )}
                            </Box>
                        </Box>

                        {!isProgressCollapsed && (
                            <>
                                {uploadProgress.map((file, i) => (
                                    <Box key={`progress-${i}`}>
                                        <Box>
                                            {file.name}
                                        </Box>

                                        <LinearProgress
                                            color={
                                                file.status === 'Success' ? 'success' : (
                                                    file.status === 'Error' ?
                                                        'error' :
                                                        'primary'
                                                )
                                            }
                                            value={file.status !== 'In Progress' ? 100 : file.progress}
                                            variant={'determinate'}
                                        />
                                    </Box>
                                ))}
                            </>
                        )}
                    </Box>
                }
            />

            {isPreviewing && (
                <Dialog
                    open={isPreviewing}
                    scroll={'body'}
                    onClose={onPreviewClose}
                    maxWidth={'md'}
                    fullWidth
                >
                    <DialogHeading
                        title={getName(context.key)}
                        actions={
                            <IconButton
                                size={'small'}
                                onClick={onPreviewClose}
                            >
                                <CloseIcon fontSize={'small'}/>
                            </IconButton>
                        }
                        noMargin
                    />
                    <PreviewContent/>
                    <DialogActions>
                        <Box className={'w__100 d-flex__justify'}>
                            <Box/>
                            <Box>
                                <Button
                                    onClick={doDownload}
                                    disabled={isLoading}
                                    children={'Download'}
                                    className={'mr__2'}
                                />
                                <Button
                                    color={'error'}
                                    onClick={handleDeleteConfirmOpen}
                                    children={'Delete'}
                                    disabled={isLoading}
                                />
                            </Box>
                        </Box>
                    </DialogActions>
                </Dialog>
            )}

            <Dialog
                open={isAddingFiles}
                scroll={'body'}
                onClose={handleAddFilesClose}
                maxWidth={'xs'}
                fullWidth
            >
                <DialogContent>
                    <Box className={'columns__1'}>
                        <InputLabel
                            children={'Add Files'}
                            className={'form__heading form__heading--standalone'}
                        />
                        <input
                            ref={fileRef}
                            key={`file-${index}`}
                            type={'file'}
                            onChange={handleFilesUpload}
                            multiple
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        color={'error'}
                        onClick={handleAddFilesClose}
                        children={'Cancel'}
                        disabled={isLoading}
                    />
                </DialogActions>
            </Dialog>

            <Dialog
                open={isConfirmingDelete}
                scroll={'body'}
                onClose={handleDeleteConfirmClose}
                maxWidth={'xs'}
                fullWidth
            >
                <DialogHeading
                    title={'Are you sure?'}
                    noMargin
                />
                <DialogContent>
                    <Box className={'columns__1'}>
                        {hasProtectedFolderSelected && (
                            <Alert severity={'warning'} icon={<LockIcon fontSize={'inherit'}/>}>
                                One or more protected folders is selected, and will be ignored from deletion.
                            </Alert>
                        )}

                        <Box>
                            Please confirm that you'd like to delete the
                            selected {contextLabel}{selection.length > 1 ? 's' : ''}. This action can't be
                            undone.
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={doDelete}
                        children={'Delete'}
                        disabled={isLoading}
                    />
                    <Button
                        color={'error'}
                        onClick={handleDeleteConfirmClose}
                        children={'Cancel'}
                        disabled={isLoading}
                    />
                </DialogActions>
            </Dialog>

            <Dialog
                open={isRenaming}
                scroll={'body'}
                onClose={handleRenameClose}
                maxWidth={'xs'}
                fullWidth
            >
                <DialogContent>
                    <Box className={'columns__1'}>
                        <InputLabel
                            children={'Rename File'}
                            className={'form__heading form__heading--standalone'}
                        />
                        <TextField
                            label={'Name'}
                            value={rename || ''}
                            required
                            disabled={isLoading}
                            onChange={event => setRename(event.target.value)}
                        />
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={doRename}
                        children={'Save'}
                        disabled={isLoading || !rename}
                    />
                    <Button
                        color={'error'}
                        onClick={handleRenameClose}
                        children={'Cancel'}
                        disabled={isLoading}
                    />
                </DialogActions>
            </Dialog>

            {isMoving && (
                <Dialog
                    open={isMoving}
                    scroll={'body'}
                    onClose={handleAddFolderClose}
                    maxWidth={'lg'}
                    fullWidth
                >
                    <DialogHeading
                        title={'Move to...'}
                        noMargin
                    />
                    <DialogContent>
                        <FileBrowser
                            basePath={basePath}
                            selectable
                            onPathChange={setSelectedPath}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={doMove}
                            children={'Move Here'}
                            disabled={isLoading}
                        />
                        <Button
                            color={'error'}
                            onClick={handleMoveClose}
                            children={'Cancel'}
                            disabled={isLoading}
                        />
                    </DialogActions>
                </Dialog>
            )}

            {isAddingFolder && (
                <Dialog
                    open={isAddingFolder}
                    scroll={'body'}
                    onClose={handleAddFolderClose}
                    maxWidth={'xs'}
                    fullWidth
                >
                    <DialogContent>
                        <Box className={'columns__1'}>
                            <InputLabel
                                children={'Add Folder'}
                                className={'form__heading form__heading--standalone'}
                            />
                            <TextField
                                label={'Name'}
                                value={folder.name || ''}
                                error={folder.hasOwnProperty('name') && !folder.name}
                                required
                                disabled={isLoading}
                                onChange={event => setFolderProperty('name', event.target.value)}
                            />
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={doAddFolder}
                            children={'Save'}
                            disabled={isLoading || !folder.name}
                        />
                        <Button
                            color={'error'}
                            onClick={handleAddFolderClose}
                            children={'Cancel'}
                            disabled={isLoading}
                        />
                    </DialogActions>
                </Dialog>
            )}
        </Box>
    );
};

export default FileBrowser;