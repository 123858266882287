import React, {useState} from "react";

import API from "../../Global/API";

import Checkbox from "@mui/material/Checkbox";

/**
 * BoundCheckbox component.
 *
 * @param props
 * @returns {*}
 * @constructor
 *
 * @description A dynamically bound switch component.
 */
const BoundCheckbox = (props) => {
    const {
        model,          // {Model} The input model to reference for API calls, etc.
        field,          // {String} The field name to reference.,
        record,         // {Object} The target record to base values from.
        doReload,       // {Function} A reload callback to refresh the parent layout.
        className       // {String} An optional class name to apply.
    } = props;

    const [binding, setBinding] = useState(record && typeof record === 'object' && record.hasOwnProperty(field) ? record[field] : false);
    const [isLoading, setLoading] = useState(false);

    /**
     * Handles the record update via the API.
     *
     * @param value
     * @returns {Promise<void>}
     */
    const handleChange = async (value) => {
        setLoading(true);
        setBinding(value);

        const response = await API.put(`${model.getRoute()}/${record.id}`, {
            [field]: value
        });

        if(doReload){
            doReload(response, true);
        }

        setLoading(false);
    };

    return (
        <Checkbox
            disabled={isLoading}
            onChange={event => handleChange(event.target.checked)}
            className={className}
            defaultChecked={!!binding}
        />
    )
};

export default BoundCheckbox;