import React from "react";

import App from "../Global/App";
import Model from "../Definitions/Model";
import Property from "../Definitions/Property";
import Formatter from "../Global/Formatter";
import PropertyMap from "../Definitions/PropertyMap";

import Box from "@mui/material/Box";
import JsonFormatter from "react-json-formatter";
import CollapsibleJsonBlock from "../Components/Typography/CollapsibleJsonBlock";

/**
 * The style guide for our JSON output.
 *
 * @type {{stringStyle: {color: string}, propertyStyle: {color: string}, numberStyle: {color: string}}}
 */
const jsonStyle = {
    propertyStyle: { color: 'red' },
    stringStyle: { color: 'green' },
    numberStyle: { color: 'darkorange' }
};


/**
 * HelixQueue model.
 *
 * @type {Object}
 */
const HelixQueue = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'helix-queue',


    /**
     * The appropriate API endpoint root.
     */
    route: 'helix-queues',


    /**
     * Any additional query parameters.
     */
    query: {},


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'objectType',
        'method',
        'payload',
        'response',
        'createdDate',
        'syncDate',
        'duration',
        'isFailed',
    ],


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'createdDate',
            label: 'Created At',
            render: record => Formatter.dateTime(record.createdDate),
            sortable: true
        }),
        Property.create({
            key: 'method',
            label: 'Method',
            render: record => (
                !record.shouldDelete ? 'Update' : 'Delete'
            ),
            sortable: false
        }),
        Property.create({
            key: 'syncDate',
            label: 'Sync Date',
            render: record => Formatter.dateTime(record.syncDate),
            sortable: true
        }),
        Property.create({
            key: 'duration',
            label: 'Duration',
            render: record => record.duration ? `${record.duration}s` : '',
            sortable: true
        }),
        Property.create({
            key: 'objectType',
            label: 'Object Type',
            render: record => (
                <Box>
                    {record.objectType} (ID: {record.objectId})
                </Box>
            ),
        }),
        Property.create({
            key: 'payload',
            label: 'Payload',
            render: record => (
                <code className={'well__container text__small'}>
                    <JsonFormatter json={record.payload} tabWith={4} jsonStyle={jsonStyle} />
                </code>
            ),
            sortable: true,
        }),
        Property.create({
            key: 'response',
            label: 'Response',
            render: record => (
                <code className={'well__container text__small'}>
                    <JsonFormatter json={record.response} tabWith={4} jsonStyle={jsonStyle} />
                </code>
            ),
            sortable: true,
        }),
        Property.create({
            key: 'isFailed',
            label: 'Failed',
            render: record => record.isFailed ? 'Yes' : 'No',
            sortable: true
        }),
    ])
};

export default HelixQueue;