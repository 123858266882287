import React from "react";

import Settings from "../../Global/Settings";

import Box from "@mui/material/Box";

/**
 * CopyrightDisclaimer component.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const CopyrightDisclaimer = () => {
    return (
        <Box
            sx={{opacity: 0.5}}
            children={
                <Box>
                    <b>© 2024 {Settings.applicationName}</b> - All rights reserved
                </Box>
            }
            className={'text__small text__center'}
        />
    );
}

export default CopyrightDisclaimer;