import React from 'react';
import ReactDOM from 'react-dom/client';

import Theme from "./Global/Theme";
import Logger from "./Global/Logger";
import Settings from "./Global/Settings";
import {_navigate} from "./Global/History";
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, HashRouter} from "./Global/Router";

import * as Sentry from "@sentry/react";
import ThemeProvider from "@mui/material/styles/ThemeProvider";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {RouterProvider} from "react-router-dom";
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';

import * as utc from 'dayjs/plugin/utc'
import * as dayjs from "dayjs";
import * as timezone from 'dayjs/plugin/timezone';

import './index.css';

// Initialize DayJS.
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("America/New_York");

/**
 * Indicates that we're building for Cordova.
 *
 * @type {boolean}
 */
window.isCordova = false;


/**
 * Indicates if we're running in a development environment.
 *
 * @type {boolean}
 */
window.isDevelopment = process.env.NODE_ENV !== 'production';


/**
 * An optional override for production logging (which is otherwise always enabled locally).
 *
 * @type {boolean}
 */
window.isLoggingEnabled = false;


/**
 * Returns a random element from the array in context.
 *
 * @returns {*}
 */
Array.prototype.random = function () {
    return this[Math.floor((Math.random() * this.length))];
};


/**
 * Groups an array of objects by a particular key.
 *
 * @param k
 * @returns {*}
 */
Array.prototype.groupBy = function(k) {
    return this.reduce((acc, item) => ((acc[item[k]] = [...(acc[item[k]] || []), item]), acc),{});
};


/**
 * Create the application instance.
 */
const doRender = () => {
    const routerInstance = window.isCordova ? HashRouter : BrowserRouter;

    /**
     * Only initialize Sentry for the mobile application. Interestingly enough, Sentry
     * appears to break the traditional XHR request within the badge editor due to the lack
     * of a standard CORS header.
     */
    if(window.isCordova){
        Sentry.init({
            dsn: "https://e33d9baccb7a7a8b8169ee4046a24059@o4505862082592768.ingest.sentry.io/4505862724059136",
            integrations: [
                new Sentry.BrowserTracing(),
                new Sentry.Replay(),
            ],

            // Performance Monitoring
            tracesSampleRate: window.isDevelopment ? 1.0 : 0.1,     // Capture 100% of the transactions, reduce in production!

            // Session Replay
            replaysSessionSampleRate: 0.1,                          // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
            replaysOnErrorSampleRate: 1.0,                          // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
        });
    }

    ReactDOM.createRoot(document.getElementById('root')).render(
        <>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <ThemeProvider theme={Theme}>
                    <RouterProvider router={routerInstance}/>
                </ThemeProvider>
            </LocalizationProvider>
        </>
    );
};

// Render the content after initializing Cordova.
if (window.isCordova) {
    /**
     * Correct file issues for Cordova and Pintura.
     *
     * @type {Object}
     * @private
     */
    window.__pqina_webapi__ = {
        File,
        FileReader,
        ProgressEvent,
    };


    /**
     * Initializes the application.
     */
    const launchApp = () => {
        Logger.debug("[index.js] Cordova ready.");
        doRender();
        bindUniversalLinks();
    };


    /**
     * Binds any deep-linking functionality against the application.
     */
    const bindUniversalLinks = () => {
        if (!window.universalLinks) {
            Logger.debug("[index.js] Universal links not available.");
            return;
        }

        // Redirect to the appropriate path once received.
        window.universalLinks.subscribe(null, (eventData) => {
            Logger.debug("[index.js] Universal link event received.", eventData);
            Settings.universalLinks = eventData;
            _navigate(eventData.path);
        });
    };

    document.addEventListener('deviceready', launchApp, false);

    // If we're testing locally (in the browser), we won't actually receive this event, so
    // we need to throw an instance of it ourselves to enable the application.
    //
    if (window.isDevelopment) {
        launchApp();
    }
} else {
    doRender();
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//
reportWebVitals();
