import React, {useEffect, useMemo} from "react";

import App from "../Global/App";
import Footer from "./Footer";
import PageBody from "./Page/PageBody";
import {useAuth} from "../Global/Auth";
import Navigation from "./Navigation";
import ChatButton from "./Widgets/ChatButton";
import MobileLanding from "./Page/MobileLanding";
import {GlobalHistory} from "../Global/History";

import AOS from "aos";
import Box from "@mui/material/Box";

import 'aos/dist/aos.css';

/**
 * Page component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const Page = (props) => {
    const {
        icon,                           // {Component} An optional icon component to include within the default header.
        flex,                           // {Boolean} An optional flex attribute for full page layouts.
        header,                         // {Component} The header slot, used to override the default behavior.
        heading,                        // {String} A standard page title, placed within an H2 component if provided.
        controls,                       // {Component} An optional controls slot for the page.
        children,                       // {Component} The inner page content.
        hideHeader,                     // {Boolean} Hides the default page header container.
        pageBefore,                     // {Component} A slot placed before the page content.
        fullScreen,                     // {Boolean} Switches between full screen and padded layouts.
        ignoreMobileRestriction         // {Boolean} Ignores the mobile restriction for employee users.
    } = props;

    const {scope, isScope, authenticated} = useAuth();

    /**
     * Initialize any additional dependencies.
     */
    useEffect(() => {
        AOS.init({
            once: true,
            delay: 50,
            offset: 0,
            easing: 'ease-in-out',
            duration: 500,
        });
    }, []);


    /**
     * Generate the appropriate page styles / classes.
     */
    const pageStyle = (() => {
        let response = {};

        if (fullScreen) {
            response['padding'] = 0;
        }

        if (App.isCordova()) {
            response['paddingBottom'] = '1.5em';
        }

        return response;
    })();


    /**
     * The CSS class list for the page wrapper.
     *
     * @type {string}
     */
    const pageClass = `page ${!authenticated ? 'page--unauthenticated' : ''} ${!!flex ? 'page--flex' : ''} ${hideHeader ? 'page--no-header' : ''}`;


    /**
     * Show the application download content if we're an employee attempting to access from the browser.
     */
    const showMobileLanding =
        !App.isLocal() &&
        authenticated &&
        !ignoreMobileRestriction &&
        isScope('Employee') &&
        !App.isCordova();

    return (
        <Box className={`app ${scope && !showMobileLanding ? `scope--${App.isCordova() ? 'Employee' : 'User'}` : ''}`}>
            <GlobalHistory/>
            <Navigation/>

            {showMobileLanding ? (
                <Box className={'page page--plain'}>
                    <MobileLanding/>
                </Box>
            ) : (
                <>
                    {pageBefore}

                    <Box
                        sx={pageStyle}
                        className={pageClass}
                    >
                        <PageBody
                            icon={icon}
                            header={header}
                            heading={heading}
                            controls={controls}
                            children={children}
                            hideHeader={hideHeader}
                        />
                    </Box>
                </>
            )}

            <Footer/>

            {authenticated && (
                <ChatButton/>
            )}
        </Box>
    );
};

export default Page;