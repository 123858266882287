import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import Logger from "../../../Global/Logger";
import {useAuth} from "../../../Global/Auth";
import ClientForm from "../../../Actions/ClientActions/ClientForm";
import ContactDetails from "./ContactsBlock/ContactDetails";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';

/**
 * ContactsBlock component.
 *
 * @returns {*}
 * @constructor
 */
const ContactsBlock = (props) => {
    const {
        record,             // {Object} The related record.
        doReload            // {Function} A callback to reload the client.
    } = props;

    const {hasPermissionTo} = useAuth();
    const [contacts, setContacts] = useState([]);
    const [isAddingContact, setAddingContact] = useState(false);

    /**
     * Initialize data on component mount.
     */
    useEffect(() => {
        getContacts()
    }, [record]);


    /**
     * Loads all related contact records via the API.
     *
     * @returns {Promise<void>}
     */
    const getContacts = async () => {
        if (!record || !record.id) {
            return;
        }

        Logger.debug(`[ContactsBlock] Loading related client results...`);

        setContacts(
            await API.get('contacts', {
                $filter: `clientId in {${record.id}} and isDeleted eq {0}`
            })
        );
    };


    /**
     * Reveals the "Add a Note" form.
     */
    const handleContactsOpen = () => {
        setAddingContact(true);
    };


    /**
     * Hides the "Add a Note" form.
     */
    const handleContactsClose = () => {
        setAddingContact(false);
    };


    /**
     * Handles the post-save action.
     */
    const onClientSave = () => {
        setAddingContact(false);

        if(doReload){
            doReload();
        }
    };

    return (
        <Paper>
            <Box
                className={'d-flex__justify'}
                sx={{
                    paddingLeft: '1em',
                    paddingRight: '0.5em',
                    paddingTop: '0.35em',
                    paddingBottom: '0.35em'
                }}
            >
                <h3 className={'m__0'}>Contacts</h3>

                <IconButton onClick={handleContactsOpen} sx={!hasPermissionTo('EDIT_CLIENTS') ? {opacity: 0, pointerEvents: 'none'} : {}}>
                    <PersonAddAlt1Icon/>
                </IconButton>
            </Box>
            <Divider/>
            <div className={'columns__1 p__3'}>
                {contacts.map(contact => (
                    <ContactDetails
                        name={contact.displayName}
                        title={contact.type}
                        phone={contact.phoneNumber}
                        email={contact.emailAddress}
                    />
                ))}

                {!contacts.length && (
                    <Box className={'text__center text__disclaimer'}>
                        No contacts available.
                    </Box>
                )}
            </div>

            <ClientForm
                open={isAddingContact}
                record={record}
                onSave={onClientSave}
                onClose={handleContactsClose}
            />
        </Paper>
    );
};

export default ContactsBlock;