import React from "react";

import App from "../Global/App";
import CallLog from "./CallLog";
import Property from "../Definitions/Property";
import Formatter from "../Global/Formatter";
import PropertyMap from "../Definitions/PropertyMap";
import RecipientColumn from "./CallLog/RecipientColumn";

/**
 * UserCallLog model.
 *
 * @type {Object}
 */
const UserCallLog = {
    ...CallLog,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'user-call-log',


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'recipient'
    ],


    /**
     * Returns any default query parameters.
     *
     * @returns {*|{}}
     */
    getQuery() {
        const filter = this.getDefaultFilter();

        const query = {
            ...this.query,
            showAll: '',
            $orderby: 'createdDate desc'
        };

        // Filter `createdDate` to the last week.
        const createdDte = new Date();
        createdDte.setDate(createdDte.getDate() - 7);
        query.$filter = `createdDate ge {${Formatter.dateTime(createdDte)}}`;

        return filter && filter.query ? {
            ...query,
            ...filter.query
        } : query;
    },


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'recipient',
            width: '100%',
            label: 'Caller / Recipient',
            render: (record) => <RecipientColumn record={record} condensed/>,
            sortable: false
        }),
        Property.create({
            key: 'direction',
            width: '33%',
            label: 'Direction',
            render: (record) => Formatter.capitalize(record.direction),
            sortable: true,
            filterable: true
        }),
        Property.create({
            key: 'createdDate',
            width: '33%',
            label: 'Date / Time',
            render: (record) => Formatter.dateTime(record.createdDate),
            sortable: true
        })
    ])
};

export default UserCallLog;