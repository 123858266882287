import React, {useState} from "react";

import API from "../../Global/API";
import Status from "../../Models/Status";
import DialogHeading from "../../Components/Typography/DialogHeading";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import FormGroup from "@mui/material/FormGroup";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { ChromePicker } from 'react-color';
import FormControlLabel from "@mui/material/FormControlLabel";

/**
 * StatusForm component.
 *
 * @returns {*}
 * @constructor
 */
const StatusForm = (props) => {
    const {
        open,           // {Boolean} Indicates whether or not the form is open.
        record,         // {Object} The form context object, handles pre-loading the form.
        onSave,         // {Function} An onSave callback, triggered whenever the form is saved.,
        onClose,        // {Function} An onClose callback, triggered whenever the modal is closed.
    } = props;

    const [fields, setFields] = useState(Status.getInstance(record));
    const [isLoading, setLoading] = useState(false);

    /**
     * Performs the save via the API.
     */
    const doSave = async () => {
        setLoading(true);

        const response = fields.id ?
            await API.put(`status/${fields.id}`, fields) :
            await API.post('statuses', fields);

        setLoading(false);

        if (onSave) {
            onSave(response);
        }
    };


    /**
     * Updates a particular form value.
     *
     * @param key
     * @param value
     */
    const setValue = (key, value) => {
        setFields({
            ...fields,
            [key]: value
        });
    };

    return (
        <Dialog
            open={open}
            scroll={'body'}
            onClose={onClose}
            maxWidth={'md'}
            fullWidth
        >
            <DialogHeading
                title={'Status Details'}
                noMargin
            />
            <DialogContent>
                <Box className={'columns__1'}>
                    <Box className={'columns__2_3'}>
                        <Box>
                            <Box className={'columns__1'}>
                                <TextField
                                    label="Name"
                                    value={fields.name}
                                    required
                                    disabled={isLoading}
                                    onChange={event => setValue('name', event.target.value)}
                                />

                                <FormControl>
                                    <InputLabel required>Record Type</InputLabel>
                                    <Select
                                        label="Record Type"
                                        value={fields.modelType}
                                        required
                                        disabled={isLoading}
                                        onChange={event => setValue('modelType', event.target.value)}
                                    >
                                        {['Client', 'Employee'].map((option, i) => (
                                            <MenuItem
                                                key={i}
                                                value={option}
                                                children={option}
                                            />
                                        ))}
                                    </Select>
                                </FormControl>

                                <FormGroup>
                                    <FormControlLabel
                                        label={'Use as the default for this type?'}
                                        control={
                                            <Checkbox
                                                defaultChecked={fields.isDefault}
                                                onChange={event => setValue('isDefault', event.target.checked)}
                                            />
                                        }
                                    />
                                    {fields.modelType === 'Employee' && (
                                        <FormControlLabel
                                            label={'Assign to the recruiting process?'}
                                            control={
                                                <Checkbox
                                                    defaultChecked={fields.isRecruiting}
                                                    onChange={event => setValue('isRecruiting', event.target.checked)}
                                                />
                                            }
                                        />
                                    )}
                                    <FormControlLabel
                                        label={'Deactivate on assignment?'}
                                        control={
                                            <Checkbox
                                                defaultChecked={fields.shouldDeactivate}
                                                onChange={event => setValue('shouldDeactivate', event.target.checked)}
                                            />
                                        }
                                    />
                                    <FormControlLabel
                                        label={'Trigger internal notification?'}
                                        control={
                                            <Checkbox
                                                defaultChecked={fields.shouldNotify}
                                                onChange={event => setValue('shouldNotify', event.target.checked)}
                                            />
                                        }
                                    />
                                </FormGroup>
                            </Box>
                        </Box>

                        <Box align={'right'}>
                            <ChromePicker
                                width={'100%'}
                                color={fields.color || ''}
                                onChange={color => setValue('color', color ? color.hex : '')}
                            />
                        </Box>
                    </Box>

                    {fields.shouldNotify && (
                        <TextField
                            rows={4}
                            label="Notification"
                            value={fields.notification}
                            disabled={isLoading}
                            onChange={event => setValue('notification', event.target.value)}
                            multiline
                        />
                    )}
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={doSave}>Save</Button>
                <Button onClick={onClose} color={'error'}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default StatusForm;