import React, {useEffect, useState} from "react";

import Step from "../../../Components/Step";
import {useAuth} from "../../../Global/Auth";
import LogoHeading from "../../../Components/Typography/LogoHeading";
import StepControls from "../../../Components/StepControls";
import InputSignature from "../../../Components/Input/InputSignature";

import Box from "@mui/material/Box";

/**
 * JobDescriptionStep component.
 *
 * @returns {*}
 * @constructor
 */
const JobDescriptionStep = (props) => {
    const {
        step,               // {Number} The current step for the user.
        index,              // {Number} The expected step number.
        readOnly,           // {Boolean} Whether the form should be read only.
        employee,           // {Object} The employee record for read only context.
        disabled,           // {Boolean} Whether the step is disabled from input.
        specialty,          // {Object} The user's assigned specialty.
        onStepChange        // {Function} The on change callback to load the next step.
    } = props;

    const [isLoaded, setLoaded] = useState(false);
    const [signature, setSignature] = useState('');

    /**
     * Various authentication utilities.
     */
    const {
        getUserSettingValue,
        getUserSettingValueForEmployee
    } = useAuth();


    /**
     * Indicates whether we have an employee record.
     */
    const hasEmployee = employee && employee.id;


    /**
     * Loads the required settings for this step.
     */
    useEffect(() => {
        if (!hasEmployee) {
            return;
        }

        handleSettingsLoad();
    }, []);


    /**
     * Loads each of the appropriate user settings.
     *
     * @returns {Promise<void>}
     */
    const handleSettingsLoad = async () => {
        setSignature(
            readOnly ?
                await getUserSettingValueForEmployee(employee.id, 'OFFER_LETTER_SIGNATURE') :
                await getUserSettingValue('OFFER_LETTER_SIGNATURE')
        );

        setLoaded(true);
    };


    /**
     * The disabled state of the form.
     */
    const isDisabled = readOnly || disabled;

    return (
        <Step
            step={index}
            value={step}
        >
            <LogoHeading
                title={'Job Description'}
                className={'mb__0'}
            />

            <Box dangerouslySetInnerHTML={{__html: specialty.jobDescription || '<p>(no description)</p>'}}/>

            <Box className={'columns__1'}>
                <InputSignature
                    key={0}
                    value={signature}
                    label={'Please sign here:'}
                    disabled={isDisabled}
                    onChange={setSignature}
                    scaleFactor={2}
                />

                {!readOnly && (
                    <StepControls
                        step={step}
                        disabled={isDisabled || !signature}
                        onStepChange={onStepChange}
                    />
                )}
            </Box>
        </Step>
    )
};

export default JobDescriptionStep;