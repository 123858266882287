import State from "./State";

import {createTheme} from "@mui/material";

/**
 * Theme object.
 *
 * @type {Theme}
 */
const Theme = createTheme({
    typography: {
        allVariants: {
            fontSize: State.get('auth-scope') === 'Employee' ? 16 : 12,
            fontFamily: `'Quicksand', sans-serif`,
            // fontFamily: `'Questrial', sans-serif`,
            letterSpacing: 0,
            fontWeightLight: 300,
            fontWeightMedium: 500,
            fontWeightRegular: 400,
        },
    },
    components: {
        MuiButton: {
            defaultProps: {
                sx: {
                    textTransform: "capitalize",
                },
            },
        },
        MuiTypography: {
            defaultProps: {
                sx: {
                    // fontSize: "14px",
                },
            },
        },
        MuiMenuItem: {
            defaultProps: {
                sx: {
                    // fontSize: "14px",
                },
            },
        },
        MuiInputLabel: {
            defaultProps: {
                sx: {
                    // fontSize: "14px",
                },
            },
        },
        MuiOutlinedInput: {
            defaultProps: {
                sx: {
                    // fontSize: "14px",
                }
            }
        }
    },
});

export default Theme;