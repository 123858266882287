import React from "react";

import API from "../../Global/API";

import Link from "@mui/material/Link";

/**
 * HighlightIndicator component.
 *
 * @returns {*}
 * @constructor
 */
const HighlightIndicator = (props) => {
    const {
        record,         // {Object} The employee record in which the highlight represents.
        doReload,       // {Function} A reload callback for the parent page.
    } = props;


    /**
     * Handles the toggle action for the highlight indicator.
     *
     * @returns {Promise<void>}
     */
    const handleClick = async () => {
        const response = await API.put(`employees/${record.id}`, {
            isHighlighted: !record.isHighlighted
        });

        if(doReload){
            doReload(response);
        }
    };

    return (
        <Link
            className={`notification__indicator ${record.isHighlighted ? 'notification__indicator--highlight' : ''}`}
            onClick={handleClick}
        />
    );
};

export default HighlightIndicator;