import React from "react";

import App from "../Global/App";
import Model from "../Definitions/Model";
import Property from "../Definitions/Property";
import Formatter from "../Global/Formatter";
import PropertyMap from "../Definitions/PropertyMap";

import Button from "@mui/material/Button";
import LaunchIcon from '@mui/icons-material/Launch';

/**
 * UserNote model.
 *
 * @type {Object}
 */
const UserNote = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'user-note',


    /**
     * The appropriate API endpoint root.
     */
    route: 'notes',


    /**
     * Any additional query parameters.
     */
    query: {},


    /**
     * Returns any default query parameters.
     *
     * @returns {*|{}}
     */
    getQuery() {
        const user = App.getUser();
        const filter = this.getDefaultFilter();

        const query = {
            ...this.query,
            $filter: `userId in {${user.id}}`,
            $orderby: 'createdDate desc'
        };

        return filter && filter.query ? {
            ...query,
            ...filter.query
        } : query;
    },


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'createdDate',
        'note',
        'viewButton'
    ],


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'createdDate',
            label: 'Created At',
            width: 200,
            render: record => Formatter.dateTime(record.createdDate),
            sortable: false
        }),
        Property.create({
            key: 'note',
            label: 'Note',
            render: record => record.note,
            sortable: true,
        }),
        Property.create({
            key: 'viewButton',
            label: 'Description',
            width: 200,
            render: record => {
                return (
                    <Button
                        startIcon={<LaunchIcon/>}
                    >View Record</Button>
                );
            },
            sortable: true,
        })
    ])
};

export default UserNote;