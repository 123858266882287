import App from "./App";
import Logger from "./Logger";

/**
 * File object.
 *
 * @type {Object}
 *
 * @description Provides a platform-agnostic file download solution.
 */
const File = {
    /**
     * Downloads a file from a given source.
     *
     * @param src
     * @param filename
     */
    doDownload(src, filename){
        if(App.isCordova()){
            File.doDownloadMobile(src, filename);
        }
        else{
            File.doDownloadWeb(src, filename);
        }
    },


    /**
     * Handles a file download on Cordova.
     *
     * @param src
     * @param filename
     *
     * @see https://forum.quasar-framework.org/topic/3375/solved-v1-download-a-file-in-cordova-app/2
     */
    doDownloadMobile(src, filename){
        function saveBlob2File (fileName, blob) {
            const folder = window.cordova.file.externalRootDirectory + 'Download'

            window.resolveLocalFileSystemURL(folder, function (dirEntry) {
                Logger.debug('[File] File system open: ' + dirEntry.name)
                createFile(dirEntry, fileName, blob)
            }, onErrorLoadFs)
        }

        function createFile (dirEntry, fileName, blob) {
            dirEntry.getFile(fileName, { create: true, exclusive: false }, function (fileEntry) {
                writeFile(fileEntry, blob)
            }, onErrorCreateFile)
        }

        function writeFile (fileEntry, dataObj) {
            fileEntry.createWriter(function (fileWriter) {
                fileWriter.onwriteend = function () {
                    Logger.debug('[File] Successful file write...')
                }

                fileWriter.onerror = function (error) {
                    Logger.debug('[File] Failed file write: ' + error)
                }
                fileWriter.write(dataObj)
            })
        }

        function onErrorLoadFs (error) {
            Logger.debug('[File] Encountered error while loading file:', error)
        }

        function onErrorCreateFile (error) {
            Logger.debug('[File] Encountered error while creating file:', error)
        }

        fetch(src)
            .then(response => response.status === 200 ? response.blob() : Promise.reject('Failed to download.'))
            .then(blob => {
                saveBlob2File(filename, blob)
            }) .catch(() => alert('Failed to download.'));
    },


    /**
     * Handles a file download on the web.
     *
     * @param src
     * @param filename
     */
    doDownloadWeb(src, filename){
        fetch(src)
            .then(response => response.status === 200 ? response.blob() : Promise.reject('Failed to download.'))
            .then(blob => {
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = filename;
                a.style.display = 'none';
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
            }) .catch(() => alert('Failed to download.'));
    }
};

export default File;