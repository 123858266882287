import React from "react";

import User from "../../Models/User";
import TableLayout from "../../Components/Layouts/TableLayout";

import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';

/**
 * Users component.
 *
 * @returns {*}
 * @constructor
 */
const Users = () => (
    <TableLayout
        icon={<SupervisedUserCircleIcon/>}
        model={User}
        heading={'Users'}
        condensed
        hideFilters
        formPermission={'EDIT_LIBRARIES'}
    />
);

export default Users;