import React from "react";

import Page from "../../Components/Page";
import {useAuth} from "../../Global/Auth";
import ProfileView from "./Profile/ProfileView";

/**
 * Profile component.
 *
 * @returns {*}
 * @constructor
 */
const Profile = () => {
    const {user} = useAuth();

    return (
        <Page
            hideHeader
            fullScreen
            children={<ProfileView id={user.id}/>}
        />
    );
};

export default Profile;