import React from "react";

import Generator from "../../../Global/Generator";
import TextEditor from "../../../Components/Input/TextEditor";
import SectionHeading from "../../../Components/Typography/SectionHeading";
import PercentageFormatter from "../../../Components/Numbers/PercentageFormatter";

import Box from "@mui/material/Box";
import Radio from "@mui/material/Radio";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TextField from "@mui/material/TextField";
import TableBody from "@mui/material/TableBody";
import FormLabel from "@mui/material/FormLabel";
import CloseIcon from '@mui/icons-material/Close';
import IconButton from "@mui/material/IconButton";
import RadioGroup from "@mui/material/RadioGroup";
import ButtonGroup from "@mui/material/ButtonGroup";
import FormControl from "@mui/material/FormControl";
import TableContainer from "@mui/material/TableContainer";
import FormControlLabel from "@mui/material/FormControlLabel";

/**
 * SectionForm component.
 *
 * @returns {*}
 * @constructor
 */
const CourseSectionForm = (props) => {
    const {
        record,         // {Object} The default record from the database.
        loading,        // {Boolean} A loading indicator flag.
        onChange        // {Function} A change callback to share the record back and forth with the editor.
    } = props;

    /**
     * All of our questions for the record.
     */
    const questions = record && record.test && record.test.questions ? record.test.questions : [];
    const activeQuestions = questions.filter(question => !question.isDeleted);


    /**
     * Emits a change event upwards throughout the editor.
     *
     * @param updated
     */
    const handleChange = (updated) => {
        if (onChange) {
            onChange(updated);
        }
    };


    /**
     * Updates a particular form value.
     *
     * @param key
     * @param value
     */
    const setValue = (key, value) => {
        record[key] = value;
        handleChange(record);
    };


    /**
     * Registers a new question.
     */
    const addQuestion = () => {
        if (!record.test) {
            record.test = {
                questions: [],
                passingPercentage: 0.8,
            }
        }

        record.test.questions = [
            ...record.test.questions,
            {
                testId: '',
                answer: 0,
                options: '',
                question: ''
            }
        ];

        handleChange(record);
    };


    /**
     * Updates a test parameter.
     *
     * @param key
     * @param value
     */
    const setTestValue = (key, value) => {
        if (!record.test) {
            record.test = {
                questions: [],
                passingPercentage: 0.8,
            }
        }

        record.test = {
            ...record.test,
            [key]: value
        };

        handleChange(record);
    };


    /**
     * Assigns the value of a particular question.
     *
     * @param index
     * @param key
     * @param value
     */
    const setQuestionValue = (index, key, value) => {
        record.test.questions[index][key] = value;

        record.test = {
            ...record.test,
            questions: [...questions]
        };

        handleChange(record);
    };


    /**
     * Separates the question by carriage return.
     *
     * @param question
     * @returns {Array}
     */
    const getChoicesFromQuestion = (question) => {
        if (!question || !question.options) {
            return [];
        }

        return question.options
            .split("\n")
            .filter(item => !!item.trim());
    };

    return (
        <Box className={'columns__1'}>
            <SectionHeading title={'Section Details'}/>
            <Box className={'d-flex__justify'}>
                <Box className={'flex__grow'} sx={{marginRight: '1em'}}>
                    {record.isTest ? (
                        <Box className={'columns__2'}>
                            <TextField
                                label={'Name'}
                                value={record.name}
                                required
                                fullWidth
                                disabled={loading}
                                onChange={event => setValue('name', event.target.value)}
                            />
                            <TextField
                                label={'Passing Percentage'}
                                value={record.test && record.test.passingPercentage ? record.test.passingPercentage : ''}
                                required
                                disabled={loading}
                                onChange={event => setTestValue('passingPercentage', event.target.value)}
                                fullWidth
                                InputProps={{
                                    inputComponent: PercentageFormatter,
                                }}
                            />
                        </Box>
                    ) : (
                        <Box className={'columns__1'}>
                            <TextField
                                label={'Name'}
                                value={record.name}
                                required
                                fullWidth
                                disabled={loading}
                                onChange={event => setValue('name', event.target.value)}
                            />
                        </Box>
                    )}
                </Box>
                <ButtonGroup variant="outlined" disabled={loading}>
                    <Button
                        variant={!record.isTest ? 'contained' : 'outlined'}
                        onClick={() => setValue('isTest', false)}
                    >Section</Button>
                    <Button
                        variant={!!record.isTest ? 'contained' : 'outlined'}
                        onClick={() => setValue('isTest', true)}
                    >Quiz</Button>
                </ButtonGroup>
            </Box>

            <TextField
                rows={3}
                label="Short Description"
                value={record.description}
                disabled={loading}
                onChange={event => setValue('description', event.target.value)}
                multiline
                fullWidth
            />

            {!record.isTest ? (
                <TextEditor
                    value={record.content}
                    disabled={loading}
                    onChange={(value) => setValue('content', value)}
                />
            ) : (
                <>
                    <TableContainer className={'table table--striped'}>
                        <Table stickyHeader>
                            <colgroup>
                                <col width={'33%'}/>
                                <col width={'33%'}/>
                                <col width={'33%'}/>
                                <col width={'5%'}/>
                            </colgroup>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Question</TableCell>
                                    <TableCell>Choices</TableCell>
                                    <TableCell>Answer</TableCell>
                                    <TableCell sx={{padding: 0}}>
                                        <IconButton onClick={addQuestion}>
                                            <AddIcon/>
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {questions.map((question, index) => {
                                    if(question.isDeleted){
                                        return null;
                                    }

                                    // Generate a random question placeholder if we haven't yet.
                                    if (!question.question && !question.placeholder) {
                                        setQuestionValue(index, 'placeholder', Generator.getRandomQuestion())
                                    }

                                    // Split the choices from the question input.
                                    const choices = getChoicesFromQuestion(question);
                                    const inputStyle = {
                                        '& textarea': {
                                            fontSize: '14px'
                                        },
                                        '& .MuiInputBase-root::before': {
                                            borderBottom: 'none'
                                        }
                                    };

                                    return (
                                        <TableRow key={index}>
                                            <TableCell sx={{verticalAlign: 'top'}}>
                                                <TextField
                                                    sx={inputStyle}
                                                    value={question.question}
                                                    variant={'standard'}
                                                    disabled={loading}
                                                    onChange={event => {
                                                        if (event.target.value) {
                                                            setQuestionValue(index, 'placeholder', '');
                                                        }

                                                        setQuestionValue(index, 'question', event.target.value)
                                                    }}
                                                    fullWidth
                                                    multiline
                                                    placeholder={question.placeholder || ''}
                                                />
                                            </TableCell>
                                            <TableCell sx={{verticalAlign: 'top'}}>
                                                <TextField
                                                    sx={inputStyle}
                                                    value={question.options}
                                                    variant={'standard'}
                                                    disabled={loading}
                                                    onChange={event => {
                                                        if (!question.question && question.placeholder) {
                                                            setQuestionValue(index, 'question', question.placeholder);
                                                        }

                                                        setQuestionValue(index, 'options', event.target.value)
                                                    }}
                                                    placeholder={'Separate choices in new lines...'}
                                                    multiline
                                                    fullWidth
                                                />
                                            </TableCell>
                                            <TableCell sx={{verticalAlign: 'top'}}>
                                                {choices.length ? (
                                                    <FormControl>
                                                        <FormLabel
                                                            className={'text__small'}
                                                        >{question.question}</FormLabel>
                                                        <RadioGroup
                                                            value={question.answer}
                                                            onChange={event => setQuestionValue(index, 'answer', event.target.value)}
                                                            fullWidth
                                                        >
                                                            {choices.map((choice, index) => (
                                                                <FormControlLabel
                                                                    value={index}
                                                                    control={<Radio disabled={loading}/>}
                                                                    label={choice}
                                                                />
                                                            ))}
                                                        </RadioGroup>
                                                    </FormControl>
                                                ) : null}

                                                {!choices.length ? (
                                                    <TextField
                                                        variant={'standard'}
                                                        sx={{
                                                            ...inputStyle,
                                                            '& .MuiInputBase-root::before': {
                                                                borderBottomStyle: 'initial',
                                                                borderBottom: 'none'
                                                            }
                                                        }}
                                                        disabled
                                                        fullWidth
                                                        multiline
                                                        placeholder={'No choices have been added yet.'}
                                                    />
                                                ) : null}
                                            </TableCell>
                                            <TableCell align={'center'} sx={{padding: 0}}>
                                                <IconButton
                                                    color={'error'}
                                                    onClick={() => setQuestionValue(index, 'isDeleted', true)}
                                                    disabled={loading}
                                                >
                                                    <CloseIcon/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}

                                {!activeQuestions.length && (
                                    <TableRow>
                                        <TableCell colSpan={4} align={'center'}>
                                            <Box className={'text__disclaimer'}>
                                                No questions have been created yet.
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </>
            )}
        </Box>
    )
};

export default CourseSectionForm;