import App from "./App";

/**
 * Settings object.
 *
 * @type {Object}
 */
const Settings = {
    /**
     * The expected version of the application (should align with the backend "APP_VERSION").
     */
    version: '1.2.12',


    /**
     * Returns the current tenant key for the application.
     */
    tenantKey: 'reliant',


    /**
     * {String} The application name for various page components, copyright notices, policies, etc.
     */
    applicationName: 'Reliant Staffing',


    /**
     * {String} The application phone number for various contact areas, pages, etc.
     */
    phoneNumber: '412-398-9112',


    /**
     * {Object} The office location for geographical tracking.
     */
    officeLocation: {
        latitude: 40.4406,
        longitude: -79.9959
    },


    /**
     * The base path for the backend API.
     */
    apiPath: App.isStaging() ?
        'https://api.stg.reliantstaffing.app' :
        (App.isProduction() ?
            'https://api.reliantstaffing.app' :
            'http://api.reliant.lan'
        ),


    /**
     * {String} A generic cache prefix for local storage, redis, etc.
     */
    cachePrefix: `rs-${App.isStaging() ? 's' : (App.isProduction() ? 'p' : 'd')}-`,


    /**
     * {Boolean} The demo indicator flag. If enabled, will provide a less secure, but more feature-rich variant.
     */
    isDemoMode: !App.isProduction(),


    /**
     * {String} The label for empty values.
     */
    emptyContentLabel: '(none)',


    /**
     * The default page length for tables, index layouts, etc.
     */
    defaultPageLength: 100,


    /**
     * The application key for Google's API.
     */
    googleApiKey: 'AIzaSyD0a7PUbCnF930PDuUTtseDlTOtaJDwWpk',


    /**
     * The application key for TinyMCE.
     */
    tinyMceApiKey: 'w5f9pa2p0f2pxk8gekqockltvq4mra1l2qy2339iwjfxo3hu',


    /**
     * The default sidebar width.
     */
    drawerWidth: 325,


    /**
     * The active phone device, if available.
     */
    activeDevice: null,


    /**
     * Details about the current inbound call.
     */
    activeInbound: null,


    /**
     * Details about the current browser / outbound call.
     */
    activeCall: {},


    /**
     * Emits an update about the current call.
     */
    onCallChange: null,


    /**
     * A series of previously-loaded images.
     */
    loadedImages: {},


    /**
     * The current user's location.
     */
    universalLinks: {},


    /**
     * The integrated "True Helix" base URL.
     */
    helixUrl: 'https://reliant.true-helix.net'
};

export default Settings;