import {useState, useEffect} from 'react'

/**
 * Returns the window orientation.
 *
 * @returns {OrientationType}
 */
const getOrientation = () => window.screen && window.screen.orientation && window.screen.orientation.type ?
    window.screen.orientation.type :
    'portrait-primary';


/**
 * useScreenOrientation hook.
 *
 * @returns {"portrait-primary" | "portrait-secondary" | "landscape-primary" | "landscape-secondary"}
 */
const useScreenOrientation = () => {
    const [orientation, setOrientation] =
        useState(getOrientation());

    const updateOrientation = event => {
        setOrientation(getOrientation())
    };

    useEffect(() => {
        window.addEventListener(
            'orientationchange',
            updateOrientation
        );

        return () => {
            window.removeEventListener(
                'orientationchange',
                updateOrientation
            )
        }
    }, []);

    return orientation;
};

export default useScreenOrientation