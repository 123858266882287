import React, {useState} from "react";

import JsonFormatter from "react-json-formatter";

/**
 * The style guide for our JSON output.
 *
 * @type {{stringStyle: {color: string}, propertyStyle: {color: string}, numberStyle: {color: string}}}
 */
const jsonStyle = {
    propertyStyle: { color: 'red' },
    stringStyle: { color: 'green' },
    numberStyle: { color: 'darkorange' }
};


/**
 * CollapsibleJsonBlock component.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
const CollapsibleJsonBlock = props => {
    const {
        json,           // {String} The JSON output to display.
    } = props;

    const [isCollapsed, setCollapsed] = useState(true);

    return (
        <code className={'d-block text__small p__3'} style={isCollapsed ? {maxHeight: 30, overflowY: 'scroll'} : {}}>
            <JsonFormatter json={json || ''} tabWith={4} jsonStyle={jsonStyle}/>
        </code>
    );
};

export default CollapsibleJsonBlock;