import React from "react";

import TableLayout from "../../Components/Layouts/TableLayout";
import EmployeeTimeSheet from "../../Models/EmployeeTimeSheet";

import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

/**
 * TimeSheets component.
 *
 * @returns {*}
 * @constructor
 */
const TimeSheets = () => {
    const navigate = useNavigate();

    return (
        <TableLayout
            icon={<CalendarMonthIcon/>}
            model={EmployeeTimeSheet}
            heading={'Time Sheets'}
            useLoader
            condensed
            resultLabel={'documents'}
            hideFilters
            filterSlotEnd={
                <Button
                    size={'small'}
                    variant={'outlined'}
                    onClick={() => navigate('/employee/time-sheet')}
                    children={'Back'}
                    className={'text__small'}
                />
            }
        />
    )
};

export default TimeSheets;