import App from "../Global/App";

/**
 * TrueHelix object.
 *
 * @type {Object}
 */
const TrueHelix = {
    /**
     * Various distribution links.
     */
    links: {
        iOS: 'https://apps.apple.com/tt/app/true-helix/id1593742693',
        Android: 'https://play.google.com/store/apps/details?id=com.true_helix.truehelix&hl=en_US&gl=US'
    },


    /**
     * Opens the TrueHelix app store listing based on the platform.
     */
    open(){
        if(App.isIOS()){
            window.open(TrueHelix.links.iOS, '_blank', 'location=no,hidden=yes,toolbar=no');
        }
        else{
            window.open(TrueHelix.links.Android, '_blank', 'location=no,hidden=yes,toolbar=no');
        }
    }
};

export default TrueHelix;