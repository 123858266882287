import React, {useEffect, useState} from "react";

import InputPhone from "../../../Components/Input/InputPhone";
import InputSelect from "../../../Components/Input/InputSelect";
import SectionHeading from "../../../Components/Typography/SectionHeading";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";

/**
 * A collection of reference fields.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ReferenceInput = (props) => {
    const {
        label,              // {String} The label of the input.
        value,              // {Object} The stored JSON reference object.
        loading,            // {Boolean} Whether the field is in a loading state.
        readOnly,           // {Boolean} Whether the input is read only / disabled.
        validate,           // {Boolean} Whether to highlight for validation.
        onChange,           // {Function} A post-change callback event to synchronize values.
    } = props;

    const [name, setName] = useState(value ? value.name : '');
    const [other, setOther] = useState(value ? value.other : '');
    const [phoneNumber, setPhoneNumber] = useState(value ? value.phoneNumber : '');
    const [relationship, setRelationship] = useState(value ? value.relationship : '');

    /**
     * Asserts whether the input is disabled.
     */
    const isDisabled = loading || readOnly;


    /**
     * Synchronize the value property.
     */
    useEffect(() => {
        if (value && typeof value === 'object') {
            setName(value.name || '');
            setOther(value.other || '');
            setPhoneNumber(value.phoneNumber || '');
            setRelationship(value.relationship || '');
        }
    }, [value]);


    /**
     * Handle any change actions / events.
     */
    useEffect(() => {
        if (!onChange) {
            return;
        }

        onChange({
            name,
            other,
            phoneNumber,
            relationship
        });
    }, [name, other, phoneNumber, relationship]);

    return (
        <Box className={'columns__1'}>
            <SectionHeading title={label}/>

            <TextField
                value={name || ''}
                label={'Name'}
                error={validate && !name}
                required
                disabled={isDisabled}
                onChange={event => setName(event.target.value)}
            />

            <FormControl>
                <InputLabel required error={validate && !relationship}>Relationship</InputLabel>
                <InputSelect
                    label={'Relationship'}
                    value={relationship || ''}
                    error={validate && !relationship}
                    options={['Family', 'Friend', 'Co-worker', 'Boss / Manager', 'Other'].map(option => {
                        return {
                            label: option,
                            value: option
                        };
                    })}
                    required
                    disabled={isDisabled}
                    onChange={event => setRelationship(event.target.value)}
                    fullWidth
                />
            </FormControl>

            {relationship === 'Other' && (
                <TextField
                    label={'Please explain...'}
                    value={other || ''}
                    error={validate && !other}
                    required
                    disabled={isDisabled}
                    onChange={event => setOther(event.target.value)}
                />
            )}

            <InputPhone
                label={'Phone Number'}
                value={phoneNumber || ''}
                error={validate && !phoneNumber}
                required
                disabled={isDisabled}
                onChange={event => setPhoneNumber(event.target.value)}
            />
        </Box>
    )
};

export default ReferenceInput;