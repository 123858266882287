import React from "react";

import Formatter from "../../../../Global/Formatter";
import ImageWrapper from "../../../ImageWrapper";

import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

/**
 * ContactDetails component.
 *
 * @returns {*}
 * @constructor
 */
const ContactDetails = (props) => {
    const {
        src,                // {String} An image path for the avatar.
        name,               // {String} The name of the contact record.
        icon,               // {Component} An optional empty icon to be used on the avatar.
        title,              // {String} The title / description of the contact.
        label,              // {Component} An optional label / title replacement slot.
        phone,              // {String} A phone number component.
        email,              // {String} An email address component.
        actions,            // {Component} An optional actions area, useful for controls, etc.
        minWidth,           // {Integer} An optional width to specify.,
        avatarSize,         // {Number} The pixel size for the avatar display.,
        horizontal,         // {Boolean} Whether or not to display the avatar horizontally.
        description,        // {Component} An optional description slot.
    } = props;

    /**
     * Returns the string abbreviation of a name.
     *
     * @returns {string}
     */
    const getAbbreviation = () => {
        const parts = (name || '')
            .split(' ')
            .filter(part => !!part.trim());

        if (parts.length < 2) {
            return '';
        }

        return `${parts[0][0]}${parts[1][0]}`
    };

    return (
        <Box className={'d-flex__start'} sx={{minWidth: minWidth || 'auto', paddingTop: '0.2em', paddingBottom: '0.2em'}}>
            {src ? (
                <ImageWrapper
                    sx={{marginRight: '0.5em'}}
                    src={src || ''}
                    icon={icon}
                    width={avatarSize || 48}
                    height={avatarSize || 48}
                    horizontal={horizontal}
                />
            ) : (
                <Avatar
                    sx={{width: avatarSize || 48, height: avatarSize || 48, marginRight: '0.5em'}}
                    src={src || ''}
                    variant={'rounded'}
                    children={icon || getAbbreviation()}
                />
            )}
            <Box sx={{flexGrow: 1}}>
                <div className={'d-flex__justify'}>
                    <div>
                        {label ? label : (
                            <h4>{name}</h4>
                        )}

                        {description && (
                            <div className={'text__small text__light'}>
                                {description}
                            </div>
                        )}
                    </div>

                    {title && (
                        <div className={'text__light text__small'}>
                            {title}
                        </div>
                    )}

                    {actions && (
                        <>
                            {actions}
                        </>
                    )}
                </div>

                {phone && (
                    <div>
                        <a href={'#'} className={'d-flex__start'}>
                            <PhoneIcon fontSize={'small'} sx={{fontSize: '1em', marginRight: '0.2em'}}/>
                            <span>{Formatter.phoneString(phone)}</span>
                        </a>
                    </div>
                )}

                {email && (
                    <div>
                        <a href={'#'} className={'d-flex__start'}>
                            <EmailIcon fontSize={'small'} sx={{fontSize: '1em', marginRight: '0.2em'}}/>
                            <span>{email}</span>
                        </a>
                    </div>
                )}
            </Box>
        </Box>
    );
};

export default ContactDetails;