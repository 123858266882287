import React, {useState} from "react";

import API from "../../../Global/API";
import {_navigate} from "../../../Global/History";
import LogoHeading from "../../../Components/Typography/LogoHeading";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import {useNavigate} from "react-router-dom";

/**
 * RequestBadgeForm component.
 *
 * @constructor
 */
const RequestBadgeForm = () => {
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);
    const [isSuccessful, setSuccessful] = useState(false);

    /**
     * Processes the form submission and sends a message to the scheduler.
     *
     * @returns {Promise<void>}
     */
    const handleSubmit = async () => {
        setError('');
        setSubmitted(true);

        const requirements = [
            message
        ];

        if (!requirements.filter(requirement => !requirement).length) {
            setLoading(true);

            await API.post('messages/badge-request', {
                message,
            });

            setLoading(false);
            setSuccessful(true);
        } else {
            setError('Please answer all required fields.')
        }
    };

    return (
        <Box>
            <LogoHeading
                title={'Request a Badge'}
                actionSlot={
                    <Button
                        size={'small'}
                        variant={'outlined'}
                        onClick={() => navigate('/')}
                        children={'Back'}
                        className={'text__small'}
                    />
                }
            />

            {!isSuccessful ? (
                <Box className={'columns__1'}>
                    <Box>
                        Use this form to request an updated employee badge in the event that you lost or misplaced yours. Please
                        explain the situation in detail in the message box below.
                    </Box>

                    <FormControl error={isSubmitted && !message}>
                        <FormLabel className={'d-block mb__2'} required>Why do you need a new badge?</FormLabel>
                        <TextField
                            rows={4}
                            value={message}
                            error={isSubmitted && !message}
                            required
                            disabled={isLoading}
                            onChange={event => setMessage(event.target.value)}
                            multiline
                            fullWidth
                        />
                    </FormControl>

                    <Button
                        variant={'outlined'}
                        onClick={handleSubmit}
                        children={'Submit'}
                        disabled={isLoading}
                    />
                </Box>
            ) : (
                <Box className={'columns__1'}>
                    <Alert severity={'success'}>
                        Successfully received message!
                    </Alert>

                    <Box>Thank you for submitting this request, we'll reach out soon with any additional updates!</Box>

                    <Button
                        variant={'outlined'}
                        onClick={() => _navigate('/')}
                        children={'Home'}
                    />
                </Box>
            )}
            <Snackbar
                open={!!error}
                onClose={() => setError('')}
                autoHideDuration={6000}
            >
                <Alert
                    onClose={() => setError('')}
                    children={error}
                    severity={'error'}
                />
            </Snackbar>
        </Box>
    );
};

export default RequestBadgeForm;