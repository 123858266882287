import React, {useState} from "react";

import API from "../../../Global/API";
import InputPhone from "../../../Components/Input/InputPhone";
import LogoHeading from "../../../Components/Typography/LogoHeading";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import {useNavigate} from "react-router-dom";

/**
 * ReferFriendForm component.
 *
 * @constructor
 */
const ReferFriendForm = (props) => {
    const {
        onClose,            // {Function} An optional close handler if this appears in a dialog.
        disabled,           // {Boolean} Whether the form inputs are disabled.
        onComplete,         // {Function} A callback function for whenever the user completes the form.
    } = props;

    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [error, setError] = useState('');
    const [contact, setContact] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [specialty, setSpecialty] = useState('');
    const [isSubmitted, setSubmitted] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isSuccessful, setSuccessful] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');

    /**
     * Processes the form submission and sends a message to the scheduler.
     *
     * @returns {Promise<void>}
     */
    const handleSubmit = async () => {
        setError('');
        setSubmitted(true);

        const requirements = [
            name, phoneNumber, specialty
        ];

        if (!requirements.filter(requirement => !requirement).length) {
            setLoading(true);

            await API.post('messages/referral', {
                name,
                message,
                specialty,
                phoneNumber,
                emailAddress,
            });

            setLoading(false);
            setSuccessful(true);
        } else {
            setError('Please answer all required fields.')
        }
    };


    /**
     * Handles the "Home" button click.
     *
     * @returns {*}
     */
    const handleComplete = () => {
        if(onComplete){
            return onComplete();
        }

        navigate('/');
    }

    return (
        <Box>
            <LogoHeading
                title={'Refer a Friend'}
                onClose={onClose}
                actionSlot={
                    !onClose && (
                        <Button
                            size={'small'}
                            variant={'outlined'}
                            onClick={() => navigate('/')}
                            children={'Back'}
                            className={'text__small'}
                        />
                    )
                }
            />

            {!isSuccessful ? (
                <Box className={'columns__1'}>
                    <Box
                        children={"Know somebody that might be a great fit for the team? Let us know, and we'll reach out to them!"}
                    />

                    <FormControl error={isSubmitted && !name}>
                        <FormLabel className={'d-block mb__2'} required>What is their name?</FormLabel>
                        <TextField
                            value={name}
                            error={isSubmitted && !name}
                            required
                            disabled={disabled}
                            onChange={event => setName(event.target.value)}
                            fullWidth
                            placeholder={'Jane Doe'}
                        />
                    </FormControl>

                    <FormControl error={isSubmitted && !specialty}>
                        <FormLabel className={'d-block mb__2'} required>What is their specialty?</FormLabel>
                        <TextField
                            value={specialty}
                            error={isSubmitted && !specialty}
                            required
                            disabled={disabled}
                            onChange={event => setSpecialty(event.target.value)}
                            fullWidth
                            placeholder={'RN, CNA, etc.'}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel className={'d-block mb__2'}>What is their email address?</FormLabel>
                        <TextField
                            value={emailAddress}
                            disabled={isLoading}
                            onChange={event => setEmailAddress(event.target.value)}
                            placeholder={'jane@example.com'}
                        />
                    </FormControl>

                    <FormControl error={isSubmitted && !phoneNumber}>
                        <FormLabel className={'d-block mb__2'} required>What is their phone number?</FormLabel>
                        <InputPhone
                            value={phoneNumber}
                            error={isSubmitted && !phoneNumber}
                            required
                            disabled={isLoading}
                            onChange={event => setPhoneNumber(event.target.value)}
                            placeholder={'(412) 555-1234)'}
                        />
                    </FormControl>

                    <FormControl>
                        <FormLabel className={'d-block mb__2'}>Anything else that might be helpful?</FormLabel>
                        <TextField
                            rows={4}
                            value={message}
                            disabled={isLoading}
                            onChange={event => setMessage(event.target.value)}
                            multiline
                            fullWidth
                        />
                    </FormControl>

                    <Button
                        variant={'outlined'}
                        onClick={handleSubmit}
                        children={'Submit'}
                        disabled={isLoading}
                    />
                </Box>
            ) : (
                <Box className={'columns__1'}>
                    <Alert severity={'success'}>
                        Successfully received message!
                    </Alert>

                    <Box>Thank you for submitting this referral. We'll reach back out if we have any other questions!</Box>

                    <Button
                        variant={'outlined'}
                        onClick={handleComplete}
                        children={'Home'}
                    />
                </Box>
            )}
            <Snackbar
                open={!!error}
                onClose={() => setError('')}
                autoHideDuration={6000}
            >
                <Alert
                    onClose={() => setError('')}
                    children={error}
                    severity={'error'}
                />
            </Snackbar>
        </Box>
    );
};

export default ReferFriendForm;