import React, {createContext, useState, useContext, useEffect} from "react";

import App from "./App";
import API from "./API";
import State from "./State";
import Logger from "./Logger";

import {Navigate, useLocation} from "react-router-dom";

// The React context for authentication.
const authContext = createContext();

export function useAuth() {
    const [user, setUser] = useState(App.getUser());
    const [scope, setScope] = useState(State.get('auth-scope'));
    const [userSettings, setUserSettings] = useState({});
    const [authenticated, setAuthenticated] = useState(!!State.get('auth-token'));

    /**
     * Synchronize window events against the hook state.
     */
    useEffect(() => {
        window.addEventListener(
            'userupdated',
            handleUserUpdate
        );

        return () => {
            window.removeEventListener(
                'userupdated',
                handleUserUpdate
            )
        }
    }, []);


    /**
     * Processes the user update event.
     */
    const handleUserUpdate = (event) => {
        State.set('auth-user', JSON.stringify(event.detail));
        setUser(App.getUser());
        Logger.debug('[Auth] Received user update event.', event.detail);
    };


    /**
     * Indicates whether the scope is valid.
     *
     * @param comparison
     * @returns {boolean}
     */
    const isScope = (comparison) => {
        return authenticated && scope === comparison;
    };


    /**
     * A basic permission check.
     *
     * @param key
     * @returns {Boolean}
     */
    const hasPermissionTo = (key) => {
        return !!(
            authenticated &&
            user &&
            user.permissions &&
            user.permissions.length &&
            user.permissions.includes(key)
        );
    };


    /**
     * Indicates whether the user is in the recruiting / on-boarding phase.
     *
     * @returns {boolean}
     */
    const isHired = () => {
        return authenticated && isScope('Employee') && user.isActive;
    };


    /**
     * Indicates whether the user is in the recruiting / on-boarding phase.
     *
     * @returns {boolean}
     */
    const isInviteCompleted = () => {
        return authenticated && isScope('Employee') && (
            user.isActive || (!user.isActive && !!user.inviteCompletedDate && user.isInviteAccepted)
        );
    };


    /**
     * Returns the value of a setting key.
     *
     * @param employeeId
     * @param key
     * @param fallback
     * @returns {*}
     */
    const getUserSettingValueForEmployee = async (employeeId, key, fallback = '') => {
        if (!isScope('User')) {
            return fallback;
        }

        const response = await API.get('settings', {
            $filter: `modelType in {Employee} and modelId in {${employeeId}} and key in {${key}}`,
            $top: 1,
            $orderby: 'id desc'
        });

        if (response && response.length) {
            return response[0].value;
        }

        return fallback;
    };


    /**
     * Returns the value of a setting key.
     *
     * @param key
     * @param fallback
     * @returns {*}
     */
    const getUserSettingValue = async (key, fallback = '') => {
        const response = isScope('Employee') ?
            await API.get('employee-settings', {
                $filter: `key in {${key}}`,
                $top: 1,
                $orderby: 'id desc'
            }) :
            await API.get('settings', {
                $filter: `modelType in {${user['@model']}} and modelId in {${user.id}} and key in {${key}}`,
                $top: 1,
                $orderby: 'id desc'
            });

        if (response && response.length) {
            return response[0].value;
        }

        return fallback;
    };


    /**
     * Assigns a user setting value.
     *
     * @param key
     * @param value
     * @returns {Promise<void>}
     */
    const setUserSetting = async (key, value) => {
        const settings = userSettings || {};
        const endpoint = isScope('Employee') ?
            'employee-settings' :
            'settings';

        let setting;

        if (userSettings.hasOwnProperty(key) && userSettings[key].id) {

            // Update a pre-existing setting for this user to avoid a subsequent load.
            setting = {
                ...userSettings[key],
                key,
                value,
                modelId: user.id,
                modelType: user['@model'],
            };
        } else {

            // If we haven't yet "touched" this setting key, load the latest setting value
            // from the API and bind it to the setting object for storage / update.
            const results = isScope('Employee') ?
                await API.get(endpoint, {
                    $top: 1,
                    $filter: `key in {${key}}`,
                    $orderby: 'id desc'
                }) : await API.get(endpoint, {
                    $top: 1,
                    $filter: `modelType in {${user['@model']}} and modelId in {${user.id}} and key in {${key}}`,
                    $orderby: 'id desc'
                });

            // Bind the new parameters.
            setting = {
                ...(results.length ? results[0] : {}),
                key,
                value,
                modelId: user.id,
                modelType: user['@model'],
            };
        }

        // Handle the create / update request appropriately.
        settings[key] = setting.id ?
            await API.put(`${endpoint}/${setting.id}`, {
                ...setting
            }) : await API.post(endpoint, {
                ...setting
            });

        // Refresh the state.
        setUserSettings({
            ...settings
        });
    };


    /**
     * Indicates if the user has any of the provided permissions.
     *
     * @param permissions
     * @returns {boolean}
     */
    const hasOneOfPermissions = (permissions) => {
        if (!permissions || !permissions.length) {
            return false;
        }

        return permissions.some(permission => hasPermissionTo(permission));
    };


    /**
     * Returns true if the user has every listed permission key.
     *
     * @param permissions
     * @returns {*|boolean}
     */
    const hasAllPermissions = (permissions) => {
        if (!permissions || !permissions.length) {
            return false;
        }

        return permissions.every(permission => hasPermissionTo(permission));
    };

    return {
        user,
        scope,
        isScope,
        isHired,
        setUser,
        authenticated,
        setUserSetting,
        hasPermissionTo,
        hasAllPermissions,
        isInviteCompleted,
        getUserSettingValue,
        hasOneOfPermissions,
        getUserSettingValueForEmployee,
        onAuthUpdate(params) {
            window.dispatchEvent(
                new CustomEvent('userupdated', {
                    detail: {
                        ...user,
                        ...params
                    }
                })
            );
        },
        login(username, password) {
            return new Promise(async (success, reject) => {
                if (!username || !password) {
                    return reject('The email address and password are required.');
                }

                // Attempt to authenticate against the credentials.
                const response = await API.post('auth', {
                    client_id: username,
                    grant_type: 'client_credentials',
                    client_secret: password
                });

                // Verify that we received a response.
                if (!response) {
                    return reject('An unexpected error occurred.');
                }

                // Handle errors appropriately.
                if (response && response.status === 'error') {
                    return reject(response.message);
                }

                // Share user storage throughout the application.
                if (App.isCordova()) {
                    State.set('login-last-email', username);
                    State.set('login-last-password', password);
                }

                State.set('auth-user', JSON.stringify(response.record));
                State.set('auth-scope', response.record['@model']);
                State.set('auth-token', response.access_token);
                State.set('auth-refresh', response.refresh_token);

                // Share any state parameters.
                setUser(response.record);
                setScope(response.record['@model']);
                setAuthenticated(true);
                success();
            });
        },
        refresh() {
            return new Promise(async (success, reject) => {
                const refreshToken = State.get('auth-refresh');

                if (!user || !user.id) {
                    return reject('You are not logged in.');
                }

                if (!refreshToken) {
                    return reject('No refresh token available.');
                }

                // Attempt to authenticate against the credentials.
                const response = await API.post('auth', {
                    client_id: user.id,
                    grant_type: 'refresh_token',
                    client_secret: refreshToken
                });

                // Verify that we received a response.
                if (!response) {
                    return reject('An unexpected error occurred.');
                }

                // Handle errors appropriately.
                if (response && response.status === 'error') {
                    return reject(response.message);
                }

                // Share user storage throughout the application.
                State.set('auth-user', JSON.stringify(response.record));
                State.set('auth-scope', response.record['@model']);
                State.set('auth-token', response.access_token);
                State.set('auth-refresh', response.refresh_token);

                // Share any state parameters.
                setUser(response.record);
                setScope(response.record['@model']);
                setAuthenticated(true);
                success();
            });
        },
        logout() {
            return new Promise((response) => {
                State.set('auth-user', '');
                State.set('auth-scope', '');
                State.set('auth-token', '');
                State.set('auth-refresh', '');
                State.set('last-chat-thread', '');
                State.set('employee-score-total', '');
                State.set('employee-score-results', '');

                setAuthenticated(false);
                response();
            });
        },
    };
}

export function AuthProvider({children}) {
    const authenticated = useAuth();

    return <authContext.Provider value={authenticated}>{children}</authContext.Provider>;
}

export function RequireAuth({scope, children, permission}) {
    const {authenticated, isScope, hasPermissionTo} = useAuth();
    const location = useLocation();

    if (!authenticated) {
        return <Navigate to="/login" replace state={{path: location.pathname}}/>;
    }

    if (scope && !isScope(scope)) {
        return <Navigate to="/unauthorized" replace state={{path: location.pathname}}/>;
    }

    if (permission && !hasPermissionTo(permission)) {
        return <Navigate to="/unauthorized" replace state={{path: location.pathname}}/>;
    }

    return children;
}

export default function AuthConsumer() {
    return useContext(authContext);
}