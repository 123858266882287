import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import State from "../../Global/State";
import Logger from "../../Global/Logger";
import LoadingRow from "../../Pages/Admin/Schedule/LoadingRow";
import EmployeeListItem from "../Lists/EmployeeListItem";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";

/**
 * The number of results per page.
 *
 * @type {number}
 */
const perPage = 25;


/**
 * EmployeeSelection component.
 *
 * @returns {*}
 * @constructor
 */
const EmployeeSelection = (props) => {
    const {
        filter,             // {Function} An optional filter callback to utilize. This should return a string.
        onChange,           // {Function} Called whenever the selection changes.
        checkbox,           // {Boolean} Whether or not to utilize a checkbox select.
        selection,          // {Array} A series of previously-selected ID's,
    } = props;

    const [page, setPage] = useState(0);
    const [search, setSearch] = useState('');
    const [isReady, setReady] = useState(false);
    const [isLoaded, setLoaded] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
    const [lastAction, setLastAction] = useState('');
    const [isLoadingNext, setLoadingNext] = useState(false);
    const [hasMoreResults, setHasMoreResults] = useState(true);
    const [selectedEmployees, setSelectedEmployees] = useState([]);

    /**
     * Loads all required data on mount.
     */
    useEffect(() => {
        if (isReady) {
            return;
        }

        getExistingSelection();
    }, [selection]);


    /**
     * Handle employee loading / searching, etc.
     */
    useEffect(() => {
        getEmployees();
    }, [page, search]);


    /**
     * Processes the change event for the selection.
     */
    useEffect(() => {
        if (!isReady) {
            return;
        }

        if (onChange) {
            onChange(selectedEmployees, lastAction);
        }
    }, [selectedEmployees]);


    /**
     * Loads the existing selection.
     *
     * @returns {Promise<void>}
     */
    const getExistingSelection = async () => {
        if (!selection || !selection.length) {
            return setReady(true);
        }

        setLoading(true);
        setSelectedEmployees(
            await API.get('employees', {
                $top: 1000,
                $filter: `id in {${selection.join(',')}}`
            })
        );
        setReady(true);
        setLoading(false);
    };


    /**
     * Loads all available employees.
     *
     * @returns {Promise<void>}
     */
    const getEmployees = async () => {
        Logger.debug(`[EmployeeSelection] Loading employee results for page ${page}...`);

        const results = await API.get('employees', {
            $top: perPage,
            $skip: page * perPage,
            $filter: filter ? filter(search) : `isDeleted eq {0} and displayName eq {${search}}`,
            $expand: 'specialty,status',
            $orderby: 'lastName asc'
        });

        if (!results.length || results.length < perPage) {
            setHasMoreResults(false);
        }

        setEmployees([
            ...employees,
            ...results
        ]);

        setLoaded(true);
        setLoadingNext(false);
    };


    /**
     * Updates the left selection based on the search criteria.
     *
     * @param event
     */
    const handleEmployeeSearch = (event) => {
        setSearch(event.target.value);
        handleEmployeeRefresh(true, true);
    };


    /**
     * Handles tab changes on the scheduler.
     *
     * @param keepSelection
     * @param keepSearch
     */
    const handleEmployeeRefresh = (keepSelection, keepSearch) => {
        setPage(0);
        setLoaded(false);
        setLoadingNext(false);
        setHasMoreResults(true);

        if (!keepSearch) {
            setSearch('');
        }

        setEmployees([]);
    };


    /**
     * Handles data loading on scroll.
     *
     * @param event
     */
    const handleContainerScroll = (event) => {
        const el = event.target;
        const threshold = 2500;

        if (isLoadingNext) {
            return;
        }

        if ((el.scrollTop + threshold) <= (el.scrollHeight - el.offsetHeight)) {
            return;
        }

        if (hasMoreResults) {
            setLoadingNext(true);
            setPage(page + 1);
        }
    };


    /**
     * Toggles an employee for selection.
     *
     * @param employee
     * @param event
     */
    const handleEmployeeSelect = (employee, event = null) => {
        setLoading(true);

        let isMultiple = !!checkbox || (event && event.ctrlKey) || !event;
        let isSelected = !!selectedEmployees.filter(comparator => employee.id === comparator.id).length;
        let baseSelection = [...selectedEmployees];
        let updatedSelected = [];

        if (!isSelected) {
            if (!isMultiple) {
                baseSelection = [];
                setLastAction('single-add');
            }
            else{
                setLastAction('multiple-add');
            }

            baseSelection.push(employee);
            updatedSelected = [...baseSelection];
        } else {
            setLastAction('multiple-remove');
            updatedSelected = [...baseSelection.filter(record => record.id !== employee.id)]
        }

        setSelectedEmployees(updatedSelected);
        State.set('scheduler-employees', JSON.stringify(updatedSelected));
        Logger.debug(`[EmployeeSelection] Updated employee selection to: ${JSON.stringify(updatedSelected.map(selected => selected.id))}`);
    };


    /**
     * Updates a single property of one of the loaded employees.
     *
     * @param employee
     * @param key
     * @param value
     */
    const setEmployeeProperty = (employee, key, value) => {
        for (let i = 0; i < employees.length; i++) {
            const current = employees[i];

            if (current.id !== employee.id) {
                continue;
            }

            current[key] = value;
            break;
        }

        setEmployees([...employees]);
    };


    /**
     * Displayed whenever no results are available.
     *
     * @returns {*}
     * @constructor
     */
    const NoResultsListItem = () => {
        return (
            <MenuItem>
                <Box
                    sx={{
                        width: '100%',
                        paddingTop: '0.2em',
                        paddingBottom: '0.2em'
                    }}
                    className={'d-flex__start'}
                >
                    {search ? `No results for "${search}"...` : `No results available.`}
                </Box>
            </MenuItem>
        );
    };

    return (
        <>
            <Box className={'columns__1 p__3'}>
                <TextField
                    fullWidth
                    value={search}
                    label={'Search...'}
                    onChange={handleEmployeeSearch}
                />
            </Box>
            <Divider/>
            <Box
                className={'two-column__left-results'}
                onScroll={handleContainerScroll}
            >
                <List dense sx={{width: '100%'}}>
                    {!isLoaded && [...Array(10).keys()].map((item, i) => {
                        return (
                            <LoadingRow key={i}/>
                        );
                    })}

                    {isLoaded && (
                        <>
                            {employees.map(employee => {
                                const isSelected = !!selectedEmployees.filter(comparator => comparator.id === employee.id).length;

                                return (
                                    <EmployeeListItem
                                        employee={employee}
                                        selected={isSelected}
                                        onSelect={handleEmployeeSelect}
                                        onChange={setEmployeeProperty}
                                        actionSlot={
                                            !checkbox ? null : <Checkbox checked={isSelected}/>
                                        }
                                    />
                                );
                            })}

                            {!employees.length && <NoResultsListItem/>}

                            {/*Loading buffer for additional results.*/}
                            {hasMoreResults && [...Array(8).keys()].map((item, i) => {
                                return (
                                    <LoadingRow key={i}/>
                                );
                            })}
                        </>
                    )}
                </List>
            </Box>
        </>
    );
};

export default EmployeeSelection;