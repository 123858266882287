import React from "react";

import Page from "../../Components/Page";
import ReferFriendForm from "./Dashboard/ReferFriendForm";

/**
 * ReferFriend component.
 *
 * @returns {JSX.Element}
 * @constructor
 *
 * @description Provides an interface for employees to refer their friends.
 */
const ReferFriend = () => {
    return (
        <Page
            hideHeader
        >
            <ReferFriendForm/>
        </Page>
    );
};

export default ReferFriend;