import React from "react";

import AssignedEmployeesTable from "../../Client/ClientSchedule/AssignedEmployeesTable";

import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

/**
 * ExpiringAvailability component.
 *
 * @returns {Element}
 * @constructor
 */
const ExpiringAvailability = (props) => {
    const {
        endDate,            // {String} The end date for comparison.
        loading,            // {Boolean} Indicates if we're currently loading.
        employees,          // {Array} The list of employees to display.
        startDate,          // {String} The start date for comparison.
    } = props;

    return (
        <Box className={'event-metrics__expiring'}>
            <AssignedEmployeesTable
                endDate={endDate}
                employees={employees}
                startDate={startDate}
            />

            {loading && <LinearProgress/>}
        </Box>
    );
};

export default ExpiringAvailability;