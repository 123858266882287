import React, {useEffect, useState} from "react";

import Step from "../../../Components/Step";
import {useAuth} from "../../../Global/Auth";
import LogoHeading from "../../../Components/Typography/LogoHeading";
import StepControls from "../../../Components/StepControls";
import InputSignature from "../../../Components/Input/InputSignature";

import Box from "@mui/material/Box";

/**
 * CovidDisclosureStep component.
 *
 * @returns {*}
 * @constructor
 */
const CovidDisclosureStep = (props) => {
    const {
        step,               // {Number} The current step for the user.
        index,              // {Number} The expected step number.
        onClose,            // {Function} An optional method for closing the form if it appears in a dialog.
        employee,           // {Object} The employee record for read only context.
        readOnly,           // {Boolean} Whether the form should be read only.
        disabled,           // {Boolean} Whether the step is disabled from input.
        contentOnly,        // {Boolean} Whether to only render the content.
        onStepChange        // {Function} The on change callback to load the next step.
    } = props;

    const [isLoaded, setLoaded] = useState(false);
    const [signature, setSignature] = useState('');
    const {setUserSetting, getUserSettingValue, getUserSettingValueForEmployee} = useAuth();

    /**
     * Loads the required settings for this step.
     */
    useEffect(() => {
        handleSettingsLoad();
    }, []);


    /**
     * Synchronize the job description signature.
     */
    useEffect(() => {
        if(!readOnly && isLoaded){
            setUserSetting('HP_COVID_DISCLOSURE', signature)
        }
    }, [signature]);


    /**
     * Loads each of the appropriate user settings.
     *
     * @returns {Promise<void>}
     */
    const handleSettingsLoad = async () => {
        setSignature(
            readOnly ?
                await getUserSettingValueForEmployee(employee.id, 'HP_COVID_DISCLOSURE') :
                await getUserSettingValue('HP_COVID_DISCLOSURE')
        );

        setLoaded(true);
    };


    /**
     * The disabled state of the form.
     */
    const isDisabled = readOnly || disabled;

    return (
        <Step step={index} value={step}>
            <LogoHeading
                title={'COVID-19 Disclosure'}
                onClose={onClose}
            />

            <Box className={'columns__1'}>
                <Box>
                    The novel coronavirus, COVID-19, has been declared a worldwide pandemic by the World Health
                    Organization. The COVID-19 virus is a highly contagious illness that primarily attacks the
                    upper respiratory system.
                </Box>

                <Box>
                    Reliant will follow orders and precautions issued by the federal, state, and local
                    governments, the Center for Disease Control, and the Pennsylvania Department of Health.
                    However, Reliant cannot guarantee that you will not become infected with COVID-19 in the
                    course and scope of your employment.
                </Box>

                <Box>
                    Reliant’s employees shall take all reasonable steps to inhibit the spread of the virus. To
                    that end, you must wear personal protective equipment and practice appropriate hygiene when
                    caring for patients and residents. However, should you contract the COVID-19 virus in the
                    scope and course of your employment with Reliant, your sole and exclusive remedy is Workers'
                    Compensation. You may not file a civil lawsuit against Reliant for COVID-19 injuries and
                    exposures.
                </Box>

                <Box>
                    You must promptly report to Reliant all work-related COVID-19 exposures or illnesses.
                    Failure to report such illnesses and exposures may affect your eligibility for Workers'
                    Compensation benefits. You understand that the risk of becoming exposed to or infected by
                    COVID-19 may result from the actions, omissions, or negligence of Reliant’s clients.
                    However, because Workers' Compensation provides you with a full and complete remedy for
                    exposure to COVID-19, you agree not to file COVID-19 civil lawsuits against Reliant’s
                    clients and agree to hold harmless the employees, agents, and representatives of Reliant’s
                    clients from all liabilities, claims, actions, damages, costs or expenses of any kind
                    arising out of or relating to COVID-19. In turn, Reliant's healthcare employees are
                    protected from COVID-19 related civil lawsuits by an executive order signed by Governor Tom
                    Wolf.
                </Box>

                {!contentOnly && (
                    <>
                        <InputSignature
                            key={1}
                            value={signature}
                            label={'Please sign here:'}
                            disabled={isDisabled}
                            onChange={setSignature}
                            scaleFactor={2}
                        />

                        {!readOnly && (
                            <StepControls
                                step={step}
                                disabled={isDisabled || !signature}
                                onStepChange={onStepChange}
                            />
                        )}
                    </>
                )}
            </Box>
        </Step>
    );
};

export default CovidDisclosureStep;