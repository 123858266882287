import React, {useEffect, useState} from "react";

import App from "../../../Global/App";
import API from "../../../Global/API";
import Step from "../../../Components/Step";
import State from "../../../Global/State";
import Logger from "../../../Global/Logger";
import Settings from "../../../Global/Settings";
import ChatFeed from "../../../Components/Widgets/ChatFeed";
import FileItem from "../../../Components/Layouts/Profile/DocumentList/FileItem";
import {useAuth} from "../../../Global/Auth";
import Formatter from "../../../Global/Formatter";
import TrueHelix from "../../../Integrations/TrueHelix";
import PhoneLink from "../../../Components/Navigation/PhoneLink";
import Department from "../../../Models/Department";
import LogoHeading from "../../../Components/Typography/LogoHeading";
import AddShiftForm from "./AddShiftForm";
import ImageWrapper from "../../../Components/ImageWrapper";
import TimeSheetForm from "./TimeSheetForm";
import DialogHeading from "../../../Components/Typography/DialogHeading";
import SectionHeading from "../../../Components/Typography/SectionHeading";
import ReferFriendForm from "./ReferFriendForm";
import DirectDepositStep from "../HirePacket/DirectDepositStep";

import Box from "@mui/material/Box";
import List from "@mui/material/List";
import dayjs from "dayjs";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import Dialog from "@mui/material/Dialog";
import Divider from "@mui/material/Divider";
import LockIcon from '@mui/icons-material/Lock';
import CloseIcon from "@mui/icons-material/Close";
import ForumIcon from '@mui/icons-material/Forum';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import AlarmOnIcon from '@mui/icons-material/AlarmOn';
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import PaymentsIcon from '@mui/icons-material/Payments';
import {useNavigate} from "react-router";
import DialogContent from "@mui/material/DialogContent";
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EventRepeatIcon from '@mui/icons-material/EventRepeat';
import MapsHomeWorkIcon from "@mui/icons-material/MapsHomeWork";
import EditCalendarIcon from '@mui/icons-material/EditCalendar';
import {compareVersions} from "compare-versions";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import MedicalInformationIcon from '@mui/icons-material/MedicalInformation';

/**
 * HiredEmployeeExperience component.
 *
 * @returns {*}
 * @constructor
 */
const HiredEmployeeExperience = (props) => {
    const {
        employee,           // {Object} The employee object for additional context.
        doReload,           // {Function} A page reload callback to retrieve the employee record.
    } = props;

    const {user} = useAuth();
    const navigate = useNavigate();
    const [shifts, setShifts] = useState([]);
    const [version, setVersion] = useState('');
    const [courses, setCourses] = useState([]);
    const [isLoaded, setLoaded] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [firstShift, setFirstShift] = useState(State.json('first-shift'));
    const [isViewingChat, setViewingChat] = useState(false);
    const [isAddingShift, setAddingShift] = useState(false);
    const [isAddingHelix, setAddingHelix] = useState(false);
    const [scheduleToDate, setScheduleToDate] = useState('');
    const [phoneExtensions, setPhoneExtensions] = useState([]);
    const [isReferringFriend, setReferringFriend] = useState(false);
    const [directDepositStep, setDirectDepositStep] = useState(0);
    const [expiringDocuments, setExpiringDocuments] = useState([]);
    const [courseCompletions, setCourseCompletions] = useState([]);
    const [isViewingTimeSheet, setViewingTimeSheet] = useState(false);
    const [directDepositClosed, setDirectDepositClosed] = useState(false);
    const [isViewingCallOptions, setViewingCallOptions] = useState(false);
    const [availabilityIncomplete, setAvailabilityIncomplete] = useState(false);
    const [isExpirationsAcknowledged, setExpirationsAcknowledged] = useState(false);

    /**
     * State parameters related to optional functionality.
     */
    const [isPayrollChatEnabled, setPayrollChatEnabled] = useState(true);


    /**
     * Indicates if we're currently enrolled in the GPS time clock feature.
     */
    const {isHelixOptIn} = user || {};


    /**
     * Loads all relevant information on mount.
     */
    useEffect(() => {
        getAllData();
    }, []);


    /**
     * Loads all the dashboard data.
     *
     * @returns {Promise<void>}
     */
    const getAllData = async () => {
        Logger.debug(`[HiredEmployeeExperience] Loading dashboard data for ${user.id}.`);
        setLoading(true);

        await Promise.all([
            getShifts(),
            getVersion(),
            getCourses(),
            getFirstShift(),
            getCourseCompletions(),
            getAvailabilityStatus(),
            getExpiringCredentials()
        ]);

        setLoaded(true);
        setLoading(false);
        Logger.debug(`[HiredEmployeeExperience] Dashboard data loaded for ${user.id}.`);
    };


    /**
     * Determines whether to show the incomplete training notification.
     *
     * @type {boolean}
     */
    const trainingIncomplete = !!courses.length && !!courseCompletions.length && courseCompletions.length < courses.length;


    /**
     * Indicates whether the application needs updated.
     *
     * @type {boolean}
     */
    const bNeedsUpdated = !!version && compareVersions(version, Settings.version) > 0;


    /**
     * Indicates whether we can display the payroll link or not. Since payroll runs on Wednesdays, we need
     * to basically ensure that the first shift occurred within the previous week of the current period.
     *
     * @type {boolean}
     */
    const bShowPayrollLink = !!firstShift && dayjs(firstShift.startDate).endOf('week').add(4, 'days').isBefore(dayjs());


    /**
     * Indicates if we have expiring documents.
     *
     * @type {boolean}
     */
    const hasExpiringDocuments = !!expiringDocuments.length;


    /**
     * Indicates if we have any documents that are currently expired.
     *
     * @type {boolean}
     */
    const hasCurrentlyExpiredDocument = !!expiringDocuments.some(document => {
        return dayjs(document.expirationDate).isBefore(dayjs());
    });


    /**
     * Indicates if certain pertinent documents are currently expired / trigger lockout.
     *
     * @type {boolean}
     */
    const hasCurrentlyExpiredCredential = !!expiringDocuments.filter(document => {
        const {type} = document;

        return [
            'Professional License',
            'License Verification'
        ].includes(type.name || '');
    }).some(document => {
        return dayjs(document.expirationDate).isBefore(dayjs());
    });


    /**
     * Reveals the call options dialogue to select specific recipients.
     */
    const handleCallOptionsOpen = () => {
        setViewingCallOptions(true);
    };


    /**
     * Closes the call options dialog.
     */
    const handleCallOptionsClose = () => {
        setViewingCallOptions(false);
    };


    /**
     * Finds all expiring document credentials for this employee.
     *
     * @returns {Promise<void>}
     */
    const getExpiringCredentials = async () => {
        const deadline = dayjs().add(30, 'days').format('YYYY-MM-DD');

        const response = await API.get('employee-documents', {
            $top: 250,
            $expand: 'type',
            $filter: `expirationDate le {${deadline}} and isAccepted eq {1} and type/any{isDeleted eq {0} and hasExpiration eq {1}}`,
            $orderby: 'createdDate desc',
        });

        Logger.debug('[HiredEmployeeExperience] Found employee documents:', response);
        setExpiringDocuments(response);
    };


    /**
     * Retrieves the current version of the application to handle updates, etc.
     *
     * @returns {Promise<void>}
     */
    const getVersion = async () => {
        const response = await API.get('version');
        Logger.debug('[HiredEmployeeExperience] Loaded application settings:', response);

        if (response.status === 'success') {
            const {settings} = response;

            setVersion(response.version);
            setPhoneExtensions(settings.phoneExtensions);
            setPayrollChatEnabled(settings.isPayrollChatEnabled);
        }
    };


    /**
     * Loads the number of course completions for the current user.
     *
     * @returns {Promise<void>}
     */
    const getCourseCompletions = async () => {
        setCourseCompletions(
            await API.get('course-completions', {
                $top: 100,
                $filter: `employeeId in {${user.id}}`
            })
        );
    };


    /**
     * Loads the employee's first available shift / scheduled item.
     *
     * @returns {Promise<void>}
     */
    const getFirstShift = async () => {
        const firstShifts = await API.get('events', {
            $top: 1,
            $filter: `isBlockRequested eq {0} and employeeId in {${user.id}}`,
            $orderby: 'startDate asc'
        });

        // Determine the first available shift to decide whether we can display the ADP link. For
        // added context here, we can only link the payroll account if we'd actually set the user
        // up over there. This typically happens after the first pay run, so we'll give a couple
        // week buffer here to give adequate setup time before displaying the link.
        //
        if (firstShifts && firstShifts.length) {
            const firstShift = firstShifts[0];
            State.set('first-shift', JSON.stringify(firstShift));
            setFirstShift(firstShift);
        }
    };


    /**
     * Finds relevant courses for the employee dashboard preview.
     *
     * @returns {Promise<void>}
     */
    const getShifts = async () => {
        const today = dayjs().format('YYYY-MM-DD');

        setShifts(
            await API.get('events', {
                $top: 4,
                $expand: 'client',
                $filter: `isBlockRequested eq {0} and employeeId in {${user.id}} and startDate ge {${today}}`,
                $orderby: 'startDate asc'
            })
        );
    };


    /**
     * Finds all assigned clients to prompt messaging accordingly.
     *
     * @returns {Promise<void>}
     */
    const getAvailabilityStatus = async () => {
        let scheduleToDate = user.availabilityEndDate || null;

        if (!scheduleToDate) {
            const clients = await API.get('clients', {
                $top: 1,
                $filter: `employeeClients/any{employeeId in {${user.id}}}`,
                $orderby: 'scheduleToDate desc'
            });

            // Verify that we have availability to cover each of the schedules that we have pending.
            if (clients && clients.length && !!clients[0].scheduleToDate) {
                scheduleToDate = clients[0].scheduleToDate;
            }
        }

        if (scheduleToDate) {
            const availability = await API.get('availability', {
                $top: 1,
                $filter: `startDate ge {${dayjs(scheduleToDate).startOf('week').format('YYYY-MM-DD')}} and employeeId in {${user.id}}`
            });

            if (!availability || !availability.length) {
                setScheduleToDate(scheduleToDate);
                setAvailabilityIncomplete(true);
            }
        }
    };


    /**
     * Finds relevant courses for the employee dashboard preview.
     *
     * @returns {Promise<void>}
     */
    const getCourses = async () => {
        setCourses(
            await API.get('courses', {
                $top: 100,
                $filter: 'isPublished eq {1} and isDeleted eq {0}'
            })
        );
    };


    /**
     * Handles the direct deposit dialog close action (can only be bypassed locally).
     */
    const handleDirectDepositClose = () => {
        if (!App.isProduction()) {
            setDirectDepositClosed(true);
        }
    };


    /**
     * Reveals the "Add Shift" dialog for True Helix.
     */
    const handleShiftAddOpenHelix = () => {
        setAddingHelix(true);
        setAddingShift(true);
    };


    /**
     * Reveals the "Add Shift" dialog.
     */
    const handleShiftAddOpen = () => {
        setAddingHelix(false);
        setAddingShift(true);
    };


    /**
     * Closes the "Add Shift" dialog.
     */
    const handleShiftAddClose = () => {
        setAddingShift(false);
    };


    /**
     * Reveals the "View Chat" dialog.
     */
    const handleChatOpen = () => {
        setViewingChat(true);
    };


    /**
     * Closes the "View Chat" dialog.
     */
    const handleChatClose = () => {
        setViewingChat(false);
    };


    /**
     * Reveals the "Refer a Friend" dialog.
     */
    const handleReferralOpen = () => {
        setReferringFriend(true);
    }


    /**
     * Closes the "Refer a Friend" dialog.
     */
    const handleReferralClose = () => {
        setReferringFriend(false);
    };


    /**
     * Reveals the "Time Sheet" dialog.
     */
    const handleTimeSheetOpen = () => {
        navigate('/employee/time-sheet')
    }


    /**
     * Closes the "Time Sheet" dialog.
     */
    const handleTimeSheetClose = () => {
        setViewingTimeSheet(false);
    };


    /**
     * Opens the user's browser and redirects them to their HR profile.
     */
    const handlePaycheckRedirect = () => {
        const siteUrl = 'https://online.adp.com/signin/v1/?APPID=WFNPortal&productId=80e309c3-7085-bae1-e053-3505430b5495&returnURL=https://workforcenow.adp.com/';

        if (App.isCordova()) {
            window.open(siteUrl, '_blank', 'location=no,hidden=yes,toolbar=no');
        } else {
            window.open(siteUrl, '_blank');
        }
    };


    /**
     * Closes the acknowledgement dialog for expiring documents.
     */
    const handleExpirationAcknowledgementClose = async () => {
        if (hasCurrentlyExpiredCredential) {
            return;
        }

        setExpirationsAcknowledged(true);
    };


    /**
     * Intercepts the department select to display a contact message if payroll is disabled.
     *
     * @param department
     * @returns {boolean}
     */
    const handleDepartmentSelectInChat = department => {
        Logger.debug('[HiredEmployeeExperience] Payroll chat is disabled, showing message.');

        if (isPayrollChatEnabled) {
            return true;
        }

        if (department.name === 'Payroll') {
            return false;
        }

        return true;
    };


    /**
     * The mobile download link for updates.
     *
     * @type {string}
     */
    const downloadUrl = App.getDownloadLink();


    /**
     * The grouped contact options.
     *
     * @type {string}
     */
    const groupedContactOptions = phoneExtensions.groupBy('department');


    /**
     * Returns the nice name of a user.
     *
     * @param user
     * @returns {string}
     */
    const getUserNiceName = (user) => {
        return user && user.firstName ? `${user.firstName} ${user.lastName ? `${user.lastName.charAt(0)}.` : ''}` : '(no name)';
    };


    /**
     * Returns the appropriate icon for a department based on a series of mappings.
     *
     * @param department
     * @returns {*|React.JSX.Element}
     */
    const getIconForDepartment = department => {
        const params = {
            fontSize: 'small',
            className: 'text__light mr__2'
        };

        const users = groupedContactOptions[department];

        if (!users || !users.length) {
            return <MapsHomeWorkIcon {...params}/>;
        }

        const details = users[0];
        const selection = Department.iconOptions.find(option => option.name === details.icon);

        if (selection) {
            return selection.icon(params);
        }

        return <MapsHomeWorkIcon {...params}/>;
    };

    return (
        <Box className={'columns__1'}>
            {bNeedsUpdated && (
                <Alert severity={'error'}>
                    This version of the application is out of date. Please <a href={downloadUrl} target={'_blank'}>click
                    here</a> to upgrade to the latest version.
                </Alert>
            )}

            {/*Only show notifications after we're fully loaded.*/}
            {isLoaded && (
                <>
                    {/*Prioritize the availability entry message over others.*/}
                    {availabilityIncomplete ? (
                        <Alert severity={'info'}>
                            You haven't <a onClick={() => navigate('/availability')}>completed your availability</a> for
                            this scheduling period through {Formatter.date(scheduleToDate, '', false)}.
                        </Alert>
                    ) : (
                        <>
                            {/*Only show the course warning if they've at least started taking some courses.*/}
                            {trainingIncomplete && (
                                <Alert severity={'info'}>
                                    Looks like there might be some more training left to finish up, <a
                                    onClick={() => navigate('/employee/training')}>ready to get started</a>?
                                </Alert>
                            )}
                        </>
                    )}
                </>
            )}

            <Box>
                <SectionHeading title={'How Can We Help?'}/>

                <Box className={'columns__1'}>
                    <List>
                        <ListItemButton onClick={() => navigate('/employee/schedule')}>
                            <ListItemAvatar>
                                <Avatar sx={{backgroundColor: 'var(--primary-color)'}}>
                                    <CalendarMonthIcon/>
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<b>My Schedule</b>}
                                secondary={
                                    <Box className={'text__small'}>
                                        View all of your upcoming shifts.
                                    </Box>
                                }
                            />
                        </ListItemButton>

                        {!!isHelixOptIn ? (
                            <ListItemButton onClick={TrueHelix.open}>
                                <ListItemAvatar>
                                    <Avatar sx={{backgroundColor: 'var(--primary-color)'}}>
                                        <AlarmOnIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<b>Time Clock</b>}
                                    secondary={
                                        <Box className={'text__small'}>
                                            Click here to punch in or out for a shift.
                                        </Box>
                                    }
                                />
                            </ListItemButton>
                        ) : (
                            <ListItemButton onClick={handleTimeSheetOpen}>
                                <ListItemAvatar>
                                    <Avatar sx={{backgroundColor: 'var(--primary-color)'}}>
                                        <AlarmOnIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<b>Submit Time Sheet</b>}
                                    secondary={
                                        <Box className={'text__small'}>
                                            Click here to send in your time sheet.
                                        </Box>
                                    }
                                />
                            </ListItemButton>
                        )}

                        {!isHelixOptIn && (
                            <ListItemButton onClick={handleShiftAddOpen}>
                                <ListItemAvatar>
                                    <Avatar sx={{backgroundColor: 'var(--primary-color)'}}>
                                        <EditCalendarIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<b>Notify Office - Shift Pick Up</b>}
                                    secondary={
                                        <Box className={'text__small'}>
                                            Pick up an extra shift? Click here.
                                        </Box>
                                    }
                                />
                            </ListItemButton>
                        )}

                        {bShowPayrollLink && !isHelixOptIn && (
                            <ListItemButton onClick={handlePaycheckRedirect}>
                                <ListItemAvatar>
                                    <Avatar sx={{backgroundColor: 'var(--primary-color)'}}>
                                        <PaymentsIcon/>
                                    </Avatar>
                                </ListItemAvatar>
                                <ListItemText
                                    primary={<b>View My Paycheck</b>}
                                    secondary={
                                        <Box className={'text__small'}>
                                            View pay stubs and other details.
                                        </Box>
                                    }
                                />
                            </ListItemButton>
                        )}
                    </List>
                </Box>

                {!!isHelixOptIn && (
                    <>
                        <br/>
                        <>
                            <SectionHeading
                                title={'Time Clock Solutions'}
                            />
                            <List>
                                <ListItemButton onClick={handleShiftAddOpenHelix}>
                                    <ListItemAvatar>
                                        <Avatar sx={{backgroundColor: 'var(--primary-color)'}}>
                                            <EventRepeatIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<b>Can't Punch In?</b>}
                                        secondary={
                                            <Box className={'text__small'}>
                                                Click here to create a shift in the time clock.
                                            </Box>
                                        }
                                    />
                                </ListItemButton>
                                <ListItemButton onClick={handleShiftAddOpen}>
                                    <ListItemAvatar>
                                        <Avatar sx={{backgroundColor: 'var(--primary-color)'}}>
                                            <EditCalendarIcon/>
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<b>Forgot to Punch In?</b>}
                                        secondary={
                                            <Box className={'text__small'}>
                                                Click here to create a manual time entry.
                                            </Box>
                                        }
                                    />
                                </ListItemButton>
                            </List>
                        </>
                    </>
                )}

                <br/>
                <>
                    <SectionHeading
                        title={'Additional Options'}
                    />
                    <List>
                        <ListItemButton onClick={handleChatOpen}>
                            <ListItemIcon>
                                <ForumIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={<b>Send a Message</b>}
                            />
                        </ListItemButton>

                        <ListItemButton onClick={handleCallOptionsOpen}>
                            <ListItemIcon>
                                <LocalPhoneIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={<b>Call the Office</b>}
                            />
                        </ListItemButton>

                        {bShowPayrollLink && !!isHelixOptIn && (
                            <ListItemButton onClick={handlePaycheckRedirect}>
                                <ListItemIcon>
                                    <PaymentsIcon/>
                                </ListItemIcon>
                                <ListItemText
                                    primary={<b>My Pay Stubs</b>}
                                />
                            </ListItemButton>
                        )}

                        <ListItemButton onClick={handleReferralOpen}>
                            <ListItemIcon>
                                <PeopleAltIcon/>
                            </ListItemIcon>
                            <ListItemText
                                primary={<b>Refer a Friend</b>}
                            />
                        </ListItemButton>
                    </List>
                </>
            </Box>

            {isAddingShift && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handleShiftAddClose}
                    maxWidth={'sm'}
                    fullWidth
                >
                    <DialogContent sx={{paddingTop: 0}}>
                        <AddShiftForm
                            helix={isAddingHelix}
                            forgot={isHelixOptIn}
                            onClose={handleShiftAddClose}
                            onComplete={handleShiftAddClose}
                        />
                    </DialogContent>
                </Dialog>
            )}

            {isViewingTimeSheet && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handleTimeSheetClose}
                    maxWidth={'sm'}
                    fullWidth
                >
                    <DialogContent sx={{paddingTop: 0}}>
                        <TimeSheetForm
                            onClose={handleTimeSheetClose}
                        />
                    </DialogContent>
                </Dialog>
            )}

            {hasExpiringDocuments && (
                <Dialog
                    open={!isExpirationsAcknowledged}
                    scroll={'body'}
                    onClose={handleExpirationAcknowledgementClose}
                    maxWidth={'md'}
                    fullWidth
                >
                    <DialogHeading
                        title={hasCurrentlyExpiredDocument ?
                            'Expired Credentials' :
                            'Expiring Credentials'
                        }
                        noMargin
                    />
                    <DialogContent>
                        <Box className={'columns__1'}>
                            {hasCurrentlyExpiredDocument ? (
                                <Alert severity={'error'} icon={
                                    <LockIcon
                                        fontSize={'inherit'}
                                        onClick={getExpiringCredentials}
                                    />
                                }>
                                    You currently have one or more <b>expired {hasCurrentlyExpiredCredential ? 'credentials' : 'documents'}</b>.
                                </Alert>
                            ) : (
                                <Alert
                                    severity={'warning'}
                                    icon={<MedicalInformationIcon fontSize={'inherit'}/>}>
                                    You currently have one or more documents <b>expiring soon</b>.
                                </Alert>
                            )}

                            {hasCurrentlyExpiredCredential ? (
                                <Box className={'columns__1'}>
                                    <Box>
                                        Unfortunately, your credentials have expired and you are unable to work until
                                        you correct the issue.
                                    </Box>
                                </Box>
                            ) : (
                                <Box>
                                    Please click to upload an updated copy of the following document(s) before they
                                    expire, otherwise we may need to remove you from the floor.
                                </Box>
                            )}

                            <Box className={'file__list'}>
                                {expiringDocuments.map(record => {
                                    const {type} = record;

                                    return (
                                        <FileItem
                                            key={record.id}
                                            file={type || {}}
                                            mobile
                                            record={record}
                                            onUpload={getExpiringCredentials}
                                            onDelete={getExpiringCredentials}
                                            employeeId={user.id}
                                        />
                                    );
                                })}
                            </Box>

                            {!hasCurrentlyExpiredCredential ? (
                                <>
                                    <Button
                                        variant={'outlined'}
                                        onClick={handleExpirationAcknowledgementClose}
                                    >
                                        Okay, I Understand.
                                    </Button>

                                    <Box className={'text__light text__small text__center'}>
                                        This message will repeat until new credentials are provided.
                                    </Box>
                                </>
                            ) : (
                                <Box className={'text__light text__small text__center'}>
                                    If you have any questions or issues with this notification, <PhoneLink phoneNumber={Settings.phoneNumber}>call the office</PhoneLink>.
                                </Box>
                            )}
                        </Box>
                    </DialogContent>
                </Dialog>
            )}

            {isViewingCallOptions && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handleCallOptionsClose}
                    maxWidth={'sm'}
                    fullWidth
                >
                    <DialogContent>
                        <Box className={'d-flex__justify mb__3'}>
                            <h3 className={'m__0'}>Call the Office</h3>

                            <IconButton onClick={handleCallOptionsClose} size={'small'}>
                                <CloseIcon/>
                            </IconButton>
                        </Box>

                        <Box className={'columns__1 columns--small'}>
                            {Object.keys(groupedContactOptions).map((department, i) => {
                                const users = groupedContactOptions[department];

                                return (
                                    <Box key={`contact-option__${i}`}>
                                        <Divider>
                                            <Box className={'d-flex__start'}>
                                                {getIconForDepartment(department)}
                                                <b>{department}</b>
                                            </Box>
                                        </Divider>
                                        <List>
                                            {users.map((user, j) => (
                                                <PhoneLink
                                                    key={`contact-user__${j}`}
                                                    phoneNumber={`${Settings.phoneNumber},${user.extension}`}
                                                >
                                                    <ListItemButton disableGutters>
                                                        <ListItemAvatar>
                                                            <ImageWrapper
                                                                src={API.getFilePath(user.image || '')}
                                                                width={32}
                                                                height={32}
                                                            />
                                                        </ListItemAvatar>
                                                        <ListItemText>
                                                            <span className={'mr__1'} style={{color: 'initial'}}>
                                                                {getUserNiceName(user)}
                                                            </span>
                                                            <Typography
                                                                sx={{display: 'inline'}}
                                                                color="text.primary"
                                                                variant="body2"
                                                                component="span"
                                                                className={'text__light'}
                                                            >
                                                                {` — `}
                                                                {`Ext. ${user.extension}`}
                                                            </Typography>
                                                        </ListItemText>
                                                    </ListItemButton>
                                                </PhoneLink>
                                            ))}
                                        </List>
                                    </Box>
                                );
                            })}

                            <Box className={'well__container p__3'}>
                                Not sure who to talk to? Call the office at any time
                                at {Formatter.phone(Settings.phoneNumber)}.
                            </Box>
                        </Box>
                    </DialogContent>
                </Dialog>
            )}

            {isReferringFriend && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handleReferralClose}
                    maxWidth={'sm'}
                    fullWidth
                >
                    <DialogContent sx={{paddingTop: 0}}>
                        <ReferFriendForm
                            onClose={handleReferralClose}
                            onComplete={handleReferralClose}
                        />
                    </DialogContent>
                </Dialog>
            )}

            {employee && employee.id && !employee.hasDirectDeposit && !directDepositClosed && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handleDirectDepositClose}
                    maxWidth={'sm'}
                    fullWidth
                >
                    <DialogContent sx={{paddingTop: 0}}>
                        <DirectDepositStep
                            step={directDepositStep}
                            index={0}
                            onValidate={() => setDirectDepositStep(1)}
                            description={
                                <>
                                    We're requiring all of our active employees to provide updated direct deposit
                                    information. Your profile will be unlocked after you complete this step!
                                </>
                            }
                            onStepChange={setDirectDepositStep}
                            settingsPrefix={'HP_DD_'}
                        />
                        <Step step={1} value={directDepositStep}>
                            <LogoHeading title={'Thank You!'}/>

                            <Box className={'columns__1'}>
                                <Box>
                                    Your direct deposit information has been updated successfully! You can now access
                                    your profile.
                                </Box>
                                <Button
                                    onClick={doReload}
                                    variant={'outlined'}
                                    children={'Close'}
                                />
                            </Box>
                        </Step>
                    </DialogContent>
                </Dialog>
            )}

            {isViewingChat && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handleChatClose}
                >
                    <ChatFeed
                        onClose={handleChatClose}
                    />
                </Dialog>
            )}
        </Box>
    );
};

export default HiredEmployeeExperience;