import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import {useAuth} from "../../../Global/Auth";

import Box from "@mui/material/Box";
import TaskAltIcon from '@mui/icons-material/TaskAlt';

/**
 * SectionCount component.
 *
 * @returns {*}
 * @constructor
 */
const SectionCount = (props) => {
    const {
        courseId
    } = props;

    const {user} = useAuth();
    const [isLoaded, setLoaded] = useState(false);
    const [sectionCount, setSectionCount] = useState(0);
    const [completedCount, setCompletedCount] = useState(0);
    const [courseCompleted, setCourseCompleted] = useState(false);

    /**
     * Load all required data on mount.
     */
    useEffect(() => {
        getCompletionsForUser();
    }, []);


    /**
     * The number of completed sections to display.
     *
     * @type {number}
     */
    const completedCountDisplay = courseCompleted ? sectionCount : completedCount;


    /**
     * Loads all the course / section completion data for the current employee user.
     *
     * @returns {Promise<void>}
     */
    const getCompletionsForUser = async () => {
        const courseCompletions = await API.get('course-completions/count', {
            $top: 1,
            $filter: `courseId in {${courseId}} and employeeId in {${user.id}}`
        });

        if (courseCompletions && courseCompletions.count) {
            setCourseCompleted(true);
        }

        setSectionCount(
            (await API.get('course-sections/count', {
                $filter: `courseId in {${courseId}}} and isDeleted eq {0}`
            })).count
        );

        setCompletedCount(
            (await API.get('course-section-completions/count', {
                $filter: `courseSection/any{courseId in {${courseId}}} and employeeId in {${user.id}}`
            })).count
        );

        setLoaded(true);
    };

    return isLoaded ? (
        <Box className={'text__small d-flex__start'} color={courseCompleted ? 'success' : ''}>
            <Box>
                {completedCountDisplay}/{sectionCount} Sections Complete
            </Box>

            {courseCompleted && <TaskAltIcon color={'success'} fontSize={'small'} className={'ml__1'}/>}
        </Box>
    ) : (
        <Box className={'text__small'}>Loading...</Box>
    );
};

export default SectionCount;