import React, {useState} from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import IconButton from "@mui/material/IconButton";

/**
 * ColorSwatch component.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const ColorSwatch = (props) => {
    const {
        value,              // {Number} The current color index.
        colors,             // {Array} The colors to display.
        disabled,           // {Boolean} Indicates if the click handler is disabled.
        children,           // {Component} The children component.
        onChange,           // {Function} The function to call when the color changes.
    } = props;

    /**
     * The anchor for the menu.
     */
    const [anchorEl, setAnchorEl] = useState(null);


    /**
     * Reveals the menu.
     *
     * @param event
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Closes the menu.
     */
    const handleClose = () => {
        setAnchorEl(null);
    };


    /**
     * Selects a new color.
     *
     * @param color
     */
    const handleChange = color => {
        handleClose();

        if (onChange) {
            onChange(color);
        }
    };


    /**
     * Indicates whether the menu is open.
     * @type {boolean}
     */
    const open = Boolean(anchorEl);

    return (
        <>
            <a onClick={!disabled ? handleClick : null}>
                {children}
            </a>
            <Popover
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Box sx={{maxWidth: 240}}>
                    <Box className={'columns__1'} sx={{gap: 0, gridGap: 0}}>
                        <Box>
                            {colors.map((color, index) => (
                                <IconButton onClick={() => handleChange(index)}>
                                    <Box
                                        sx={{
                                            width: '1em',
                                            height: '1em',
                                            padding: '0.2em',
                                            outline: value === index ? `4px solid ${color.backgroundColor}` : '',
                                            borderRadius: '50%',
                                            backgroundColor: color.previewColor,
                                        }}
                                        key={`color-${index}`}
                                    />
                                </IconButton>
                            ))}
                        </Box>
                        <Button onClick={() => handleChange(null)}>Reset...</Button>
                    </Box>
                </Box>
            </Popover>
        </>
    );
};

export default ColorSwatch;