/**
 * Various sound effects used by the application.
 *
 * @type {Object}
 */
const SFX = {
    smwPowerUp: {
        label: 'Super Mario - Power Up',
        audio: new Audio('/assets/audio/smw_power-up.wav')
    },
    smwCoin: {
        label: 'Super Mario - Coin',
        audio: new Audio('/assets/audio/smw_coin.wav')
    },
    smwCapeRise: {
        label: 'Super Mario - Cape Rise',
        audio: new Audio('/assets/audio/smw_cape_rise.wav')
    },
    smwSaveMenu: {
        label: 'Super Mario - Save Menu',
        audio: new Audio('/assets/audio/smw_save_menu.wav')
    }
};

export default SFX;