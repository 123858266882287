import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import State from "../../Global/State";
import {useAuth} from "../../Global/Auth";
import HiredEmployeeExperience from "./Dashboard/HiredEmployeeExperience";
import PipelineEmployeeInviteIncomplete from "./Dashboard/PipelineEmployeeInviteIncomplete";
import PipelineEmployeeExperienceComplete from "./Dashboard/PipelineEmployeeExperienceComplete";
import PipelineEmployeeExperienceIncomplete from "./Dashboard/PipelineEmployeeExperienceIncomplete";

import Box from "@mui/material/Box";
import {Stack} from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

/**
 * Dashboard component.
 *
 * @returns {*}
 * @constructor
 */
const Dashboard = () => {
    const {user, isHired} = useAuth();
    const [employee, setEmployee] = useState(State.json('dashboard-employee'));

    /**
     * Loads all relevant information on mount.
     */
    useEffect(() => {
        getEmployee();
    }, []);


    /**
     * Indicates whether the employee record has been loaded.
     */
    const isLoaded = employee && employee.id;


    /**
     * Indicates whether the pipeline candidate is complete.
     *
     * @type {boolean}
     */
    const isPipelineComplete = employee &&
        !!employee.trainingDate &&
        !!employee.hirePacketDate &&
        !!employee.offerLetterDate &&
        !!employee.availabilityDate;


    /**
     * Indicates whether the invite has been completed.
     *
     * @type {{}|boolean}
     */
    const isInviteCompleted = employee && !!employee.inviteCompletedDate && employee.isInviteAccepted;


    /**
     * Retrieves the appropriate employee record for the user.
     *
     * @returns {Promise<void>}
     */
    const getEmployee = async () => {
        const response = await API.get(`employees/${user.id}`);
        setEmployee(response);

        // Pass along the loaded employee as a small optimization.
        State.set('dashboard-employee', JSON.stringify(response));
    };

    return isLoaded ? (
        isHired() ? (
            <HiredEmployeeExperience
                employee={employee}
                doReload={getEmployee}
            />
        ) : (
            !isInviteCompleted ?
                <PipelineEmployeeInviteIncomplete employee={employee}/>: (
                !isPipelineComplete ?
                    <PipelineEmployeeExperienceIncomplete employee={employee}/> :
                    <PipelineEmployeeExperienceComplete employee={employee} doReload={getEmployee}/>
            )
        )
    ) : (
        <Stack spacing={1}>
            <Skeleton height={40}/>
            {Array.from(Array(3).keys()).map(i => {
                return (
                    <Box className={'d-flex__justify'} key={i}>
                        <Box width={40} height={40} className={'mr__3'}>
                            <Skeleton variant={'circular'} height={40} width={40}/>
                        </Box>
                        <Skeleton width={'100%'} height={90}/>
                    </Box>
                );
            })}
            <Skeleton height={40}/>
            {Array.from(Array(2).keys()).map(i => {
                return (
                    <Box className={'d-flex__justify'} key={i}>
                        <Box width={40} height={40} className={'mr__3'}>
                            <Skeleton variant={'circular'} height={40} width={40}/>
                        </Box>
                        <Skeleton width={'100%'} height={90}/>
                    </Box>
                );
            })}
        </Stack>
    );
};

export default Dashboard;