import React from "react";

import Logger from '../Global/Logger';

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";

/**
 * ErrorBoundary component.
 */
class ErrorBoundary extends React.Component {
    /**
     * ErrorBoundary constructor.
     *
     * @param props
     */
    constructor(props) {
        super(props);
        this.state = {hasError: false};
    }


    /**
     * Parses the error contents and updates the state to reflect.
     *
     * @param error
     * @returns {{hasError: boolean}}
     */
    static getDerivedStateFromError(error) {
        Logger.debug(`[ErrorBoundary] Unexpected error encountered, details attached.`, error);

        // Update state so the next render will show the fallback UI.
        return {hasError: true};
    }


    /**
     * Handle any post-catch events (ie. logging, etc.)
     *
     * @param error
     * @param errorInfo
     */
    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        //
        // logErrorToMyService(error, errorInfo);
        //
    }


    /**
     * Renders the component content.
     *
     * @returns {*}
     */
    render() {
        if (this.state.hasError) {
            return (
                <Box className={'text__center'}>
                    <Box className={'error__block'}>
                        <h2 className={'mt__0'}>🤔 Well, this is awkward...</h2>
                        <Box className={'columns__1'}>
                            <Box>
                                <Box>
                                    Something unexpected happened, and we're checking it out
                                    as quickly as we can. In the meantime, it might be helpful to
                                    give one of these a shot:
                                </Box>
                                <ul className={'mb__0'}>
                                    <li>Refresh the page.</li>
                                    <li>Restart the browser.</li>
                                    <li>Sign out and sign back in.</li>
                                </ul>
                            </Box>
                            <Button
                                children={'Home'}
                                variant={'contained'}
                                onClick={() => window.location.href = '/'}
                            />
                        </Box>
                    </Box>
                </Box>
            );
        }

        return this.props.children;
    }
}

export default ErrorBoundary;