import {useEffect, useMemo, useRef, useState} from "react";

import State from "../Global/State";
import Logger from "../Global/Logger";
import Settings from "../Global/Settings";
import useOnScreen from "../Hooks/useOnScreen";

import Box from "@mui/material/Box";
import PersonIcon from '@mui/icons-material/Person';
import LinearProgress from "@mui/material/LinearProgress";

/**
 * Image component.
 *
 * @returns {JSX.Element}
 * @constructor
 *
 * @description Support dynamic resizing based on the size of the parent element.
 */
const ImageWrapper = (props) => {
    const {
        sx,                 // {Object} An optional style override on the container.
        src,                // {String} The image source from the backend.
        icon,               // {Component} An optional icon to display if the image is not available.
        retry,              // {Boolean} An optional retry flag for certain operations that might not be consistent.
        width,              // {Number} The expected width of the parent container.
        height,             // {Number} The expected height of the parent container.
        loading,            // {Boolean} Indicates if we're in a loading state due to an upload.
        noCache,            // {Boolean} Indicates if we should bypass the caching mechanism (for image printing, etc.).
        iconSize,           // {String} An optional icon size to override the default.
        className,          // {String} An optional class to include on the outer component.
        horizontal,         // {Boolean} Whether we should expect mostly horizontal images.
    } = props;

    const calculatedWidth = width || 40;
    const calculatedHeight = height || 40;
    const calculatedIconSize = iconSize || (calculatedWidth > 36 ? 'large' : 'medium');
    const calculatedSource = src ? `${src}&width=${calculatedWidth}&height=${calculatedHeight}${retry ? '&retry=true' : ''}` : '';
    const iconComponent = icon || <PersonIcon fontSize={calculatedIconSize}/>;

    const ref = useRef();
    const onScreen = useOnScreen(ref, "20px");

    const [blob, setBlob] = useState({});
    const [signal, setSignal] = useState(null);
    const [controller, setController] = useState(null);
    const [imageSourceUrl, setImageSourceUrl] = useState(Settings.loadedImages.hasOwnProperty(src) ? Settings.loadedImages[src] : '');

    /**
     * Load the image on mount.
     */
    useEffect(() => {
        if (!calculatedSource) {
            return;
        }

        if (!onScreen && controller) {
            doRefresh();
            return;
        }

        // Optionally, bypass the caching mechanism by directly assigning the image source. The traditional
        // mechanism will instead fetch the image contents separately and store the result in a Blob format
        // locally to avoid the need to re-fetch the image contents.
        //
        if (noCache) {
            return setImageSourceUrl(calculatedSource);
        }

        if (Settings.loadedImages.hasOwnProperty(calculatedSource)) {
            setImageSourceUrl(Settings.loadedImages[calculatedSource]);
            return;
        }

        if (!controller) {
            doFetch();
        }
    }, [calculatedSource, onScreen]);


    /**
     * Cancels the image load.
     */
    const doRefresh = (bFlush = false) => {
        if (controller) {
            controller.abort();
        }

        setSignal(null);
        setController(null);

        if (bFlush) {
            setBlob({});
            setImageSourceUrl('');

            if (Settings.loadedImages.hasOwnProperty(calculatedSource)) {
                delete Settings.loadedImages[calculatedSource];
            }
        }
    }


    /**
     * Loads the image contents.
     *
     * @returns {Promise<Blob>}
     */
    const doFetch = () => {
        if (!calculatedSource) {
            return;
        }

        Logger.debug("[ImageWrapper] Fetching image contents.", calculatedSource);
        const controller = new AbortController()
        const localSignal = controller.signal;
        setSignal(localSignal);
        setController(controller);

        fetch(calculatedSource, {
            method: 'GET',
            signal: localSignal,
            headers: {
                "Authorization": `Bearer ${State.get('auth-token')}`,
            },
        }).then(response => response.blob())
            .then(image => {
                Logger.debug("[ImageWrapper] Successfully loaded image contents.", calculatedSource, image);
                doRefresh();

                if (!image || !image.size || !image.type || image.type === 'application/json') {
                    return;
                }

                // Debug mysterious errors on the backend (need to remove the condition above first).
                if (image.type === 'application/json') {
                    const fr = new FileReader();

                    fr.addEventListener("load", e => {
                        Logger.debug(e.target.result, JSON.parse(fr.result))
                    });

                    fr.readAsText(image);
                    return;
                }

                const url = URL.createObjectURL(image);
                Settings.loadedImages[calculatedSource] = url;

                setBlob(image);
                setImageSourceUrl(url);
            }).catch(e => {
            console.warn(e);
        });
    }


    /**
     * Handles unexpected errors on the backend.
     */
    const handleError = () => {
        Logger.debug("[ImageWrapper] Re-fetching in error.", calculatedSource);
        doFetch();
    }


    /**
     * The memoized content (for improved performance).
     *
     * @type {unknown}
     */
    const content = useMemo(() => (
        <Box
            sx={{
                ...sx,
                width: calculatedWidth,
                height: calculatedHeight,
                flexShrink: 0
            }}
            ref={ref}
            className={`image__container ${className || ''}`}
        >
            <>{iconComponent}</>

            {!!imageSourceUrl && (
                <img
                    src={imageSourceUrl}
                    style={loading ? {
                        filter: 'grayscale(1)',
                    } : {}}
                    className={`image__reference ${horizontal ? 'horizontal' : ''}`}
                />
            )}

            {!!loading && (
                <LinearProgress
                    size={calculatedWidth}
                    className={'file__loader'}
                />
            )}
        </Box>
    ), [loading, imageSourceUrl]);

    return (
        <>{content}</>
    );
};

export default ImageWrapper;