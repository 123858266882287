import {useNavigate} from "react-router-dom";

export let _navigate;

/**
 * History object.
 */
export const GlobalHistory = () => {
    _navigate = useNavigate();

    return null;
};