import React, {useState} from "react";

import API from "../Global/API";
import App from "../Global/App";
import State from "../Global/State";
import UserForm from "./UserActions/UserForm";
import {useAuth} from "../Global/Auth";
import DialogHeading from "../Components/Typography/DialogHeading";

import Menu from "@mui/material/Menu";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

/**
 * UserActions component.
 *
 * @returns {*}
 * @constructor
 */
const UserActions = (props) => {
    const {
        record,         // {Object} The employee record / context.
        doReload,       // {Function} Reloads the parent layout, if applicable.
    } = props;

    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isEditing, setEditing] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isConfirmingDelete, setConfirmDelete] = useState(false);
    const {hasPermissionTo, hasOneOfPermissions, hasAllPermissions} = useAuth();

    /**
     * Indicates whether an anchor target exists for the menu.
     *
     * @type {boolean}
     */
    const open = Boolean(anchorEl);

    /**
     * Login as the user record.
     */
    const handleUserImpersonate = () => {
        State.set('auth-user', JSON.stringify({
            ...record,
            ['@model']: 'User'
        }));
        State.set('auth-scope', 'User');
        navigate('/');
    };


    /**
     * Fired whenever the menu button is clicked.
     *
     * @param event
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Closes the menu popover.
     */
    const handleClose = () => {
        setAnchorEl(null);
    };


    /**
     * Reveals the status edit form.
     */
    const handleUserEditOpen = () => {
        handleClose();
        setEditing(true);
    };


    /**
     * Hides the status edit form.
     */
    const handleUserEditClose = () => {
        setEditing(false);
    };


    /**
     * Handles the status record save.
     */
    const handleUserSave = (record) => {
        handleUserEditClose();

        if (doReload) {
            doReload(record);
        }
    };


    /**
     * Reveals the delete confirmation.
     */
    const handleDeleteConfirmOpen = () => {
        setConfirmDelete(true);
    };


    /**
     * Hides the delete confirmation.
     */
    const handleDeleteConfirmClose = () => {
        setConfirmDelete(false);
    };


    /**
     * Processes the delete action.
     *
     * @returns {Promise<void>}
     */
    const doDelete = async () => {
        setLoading(true);

        const response = await API.put(`users/${record.id}`, {
            isDeleted: true
        });

        if(doReload){
            doReload(response);
        }

        setLoading(false);
        setConfirmDelete(false);
    };

    return (
        <div>
            {hasOneOfPermissions(['EDIT_LIBRARIES', 'DELETE_LIBRARIES']) && (
                <IconButton onClick={handleClick}>
                    <MoreHorizIcon/>
                </IconButton>
            )}

            <Menu
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                {hasPermissionTo('EDIT_LIBRARIES') && (
                    <MenuItem onClick={handleUserEditOpen}>Edit User</MenuItem>
                )}

                {hasAllPermissions(['EDIT_LIBRARIES', 'DELETE_LIBRARIES']) && (
                    <Divider/>
                )}

                {hasPermissionTo('VIEW_SETTINGS') && (
                    <MenuItem
                        disabled={App.isProduction()}
                        onClick={handleUserImpersonate}
                    >Login as "{record.firstName}"</MenuItem>
                )}

                {hasPermissionTo('DELETE_LIBRARIES') && (
                    <MenuItem
                        onClick={handleDeleteConfirmOpen}
                        disabled={record.isDeleted}
                        className={'menu__error'}
                    >Delete Record</MenuItem>
                )}
            </Menu>

            {isEditing && (
                <UserForm
                    open={true}
                    record={record}
                    onSave={handleUserSave}
                    onClose={handleUserEditClose}
                />
            )}

            {isConfirmingDelete && (
                <Dialog
                    open={true}
                    scroll={'body'}
                    onClose={handleDeleteConfirmClose}
                    maxWidth={'xs'}
                    fullWidth
                >
                    <DialogHeading
                        title={"Confirm Delete"}
                        noMargin
                    />
                    <DialogContent>
                        Are you sure that you'd like to delete this record?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={doDelete}
                            children={'Delete'}
                            disabled={isLoading}
                        >
                            Delete
                        </Button>
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};

export default UserActions;