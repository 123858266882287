import React, {useState} from "react";

import App from "../Global/App";
import API from "../Global/API";
import AddNote from "./NoteForm";
import Settings from "../Global/Settings";
import ClientForm from "./ClientActions/ClientForm";
import DialogHeading from "../Components/Typography/DialogHeading";
import ExportShiftsDialog from "./ClientActions/ExportShiftsDialog";

import Menu from "@mui/material/Menu";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import {useAuth} from "../Global/Auth";
import IconButton from "@mui/material/IconButton";
import {useNavigate} from "react-router";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from '@mui/material/DialogActions';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

/**
 * ClientActions component.
 *
 * @returns {*}
 * @constructor
 */
const ClientActions = (props) => {
    const {
        record,         // {Object} The employee record / context.
        onClose,        // {Function} An optional close handler for the parent layout.
        doReload,       // {Function} Reloads the parent layout, if applicable.
    } = props;

    const navigate = useNavigate();
    const {hasPermissionTo} = useAuth();
    const [anchorEl, setAnchorEl] = useState(null);
    const [isEditing, setEditing] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [isAddingNote, setAddingNote] = useState(false);
    const [isViewingShifts, setViewingShifts] = useState(false);
    const [isConfirmingDelete, setConfirmDelete] = useState(false);

    /**
     * Indicates whether we have an anchor target.
     *
     * @type {boolean}
     */
    const open = Boolean(anchorEl);


    /**
     * Fired whenever the menu button is clicked.
     *
     * @param event
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Closes the menu popover.
     */
    const handleClose = () => {
        setAnchorEl(null);
    };


    /**
     * Reveals the "Add a Note" form.
     */
    const handleAddNoteOpen = () => {
        handleClose();
        setAddingNote(true);
    };


    /**
     * Hides the "Add a Note" form.
     */
    const handleAddNoteClose = () => {
        setAddingNote(false);
    };


    /**
     * Reveals the employee edit form.
     */
    const handleClientEditOpen = () => {
        handleClose();
        setEditing(true);
    };


    /**
     * Hides the employee edit form.
     */
    const handleClientEditClose = () => {
        setEditing(false);
    };


    /**
     * Handles the employee record save.
     */
    const handleClientSave = (record) => {
        handleClientEditClose();

        if (doReload) {
            doReload(record, true);
        }
    };


    /**
     * Reveals the delete confirmation.
     */
    const handleDeleteConfirmOpen = () => {
        setConfirmDelete(true);
    };


    /**
     * Hides the delete confirmation.
     */
    const handleDeleteConfirmClose = () => {
        setConfirmDelete(false);
    };


    /**
     * Reveals the Helix redirect in a new URL.
     */
    const handleHelixRedirect = () => {
        window.open(`${Settings.helixUrl}/Clients/${record.helixId}`, '_blank');
    };


    /**
     * Processes the delete action.
     *
     * @returns {Promise<void>}
     */
    const doDelete = async () => {
        setLoading(true);

        const response = await API.put(`clients/${record.id}`, {
            isDeleted: true
        });

        if (doReload) {
            doReload(response);
        }

        setLoading(false);
        setConfirmDelete(false);
    };


    /**
     * Processes the restore action.
     *
     * @returns {Promise<void>}
     */
    const doRestore = async () => {
        setLoading(true);

        const response = await API.put(`clients/${record.id}`, {
            isDeleted: false
        });

        if (doReload) {
            doReload(response);
        }

        setLoading(false);
        setConfirmDelete(false);
    };


    /**
     * Toggles the shift export modal.
     */
    const handleShiftExportOpen = () => {
        handleClose();
        setViewingShifts(true)
    };


    /**
     * Closes the shift export dialog.
     */
    const handleShiftExportClose = () => {
        setViewingShifts(false);
    };


    /**
     * Redirects to the user on the schedule.
     */
    const handleScheduleRedirect = () => {
        navigate(`/schedule/client/${record.id}`);

        if (onClose) {
            onClose();
        }
    };


    /**
     * Redirects to the record on the map.
     */
    const handleMapRedirect = () => {
        navigate(`/map/${record.latitude}/${record.longitude}`);

        if (onClose) {
            onClose();
        }
    };

    return (
        <div key={record.id}>
            <IconButton onClick={handleClick}>
                <MoreHorizIcon/>
            </IconButton>
            <Menu
                open={open}
                onClose={handleClose}
                anchorEl={anchorEl}
            >
                <MenuItem onClick={() => navigate(`/clients/${record.id}`)}>View Profile</MenuItem>

                {hasPermissionTo('EDIT_CLIENTS') && [
                    <Divider key={`ClientActions-${record.id}__edit-divider`}/>,
                    <MenuItem
                        key={`ClientActions-${record.id}__edit-client`}
                        onClick={handleClientEditOpen}
                        children={'Edit Client'}
                    />,
                    <MenuItem
                        key={`ClientActions-${record.id}__add-note`}
                        onClick={handleAddNoteOpen}
                        children={'Add a Note'}
                    />
                ]}

                {(hasPermissionTo('VIEW_SCHEDULE') || hasPermissionTo('VIEW_EMPLOYEES')) && [
                    <Divider key={`ClientActions-${record.id}__view-divider`}/>,

                    hasPermissionTo('VIEW_SCHEDULE') ? [
                        <MenuItem
                            key={`ClientActions-${record.id}__view-schedule`}
                            onClick={handleScheduleRedirect}
                            children={'Manage Schedule'}
                        />,
                        <MenuItem
                            key={`ClientActions-${record.id}__export-shifts`}
                            onClick={handleShiftExportOpen}
                            children={'Export Shifts'}
                        />
                    ] : null,

                    hasPermissionTo('VIEW_EMPLOYEES') ? (
                        <MenuItem
                            key={`ClientActions-${record.id}__view-map`}
                            onClick={handleMapRedirect}
                            children={'View on Map'}
                            disabled={!record.latitude || !record.longitude}
                        />
                    ) : null
                ]}

                {App.isInstance('reliant') && [
                    <MenuItem
                        key={`ClientActions-${record.id}__view-helix`}
                        onClick={() => handleHelixRedirect()}
                        disabled={!record.helixId}
                        children={'View Helix Profile'}
                    />
                ]}

                {hasPermissionTo('DELETE_CLIENTS') && [
                    <Divider key={`ClientActions-${record.id}__delete-divider`}/>,
                    record.isDeleted ? (
                        <MenuItem
                            key={`ClientActions-${record.id}__restore-record`}
                            color={'warning'}
                            onClick={doRestore}
                            children={'Restore Record'}
                            className={'menu__restore'}
                        />
                    ) : (
                        <MenuItem
                            key={`ClientActions-${record.id}__delete-record`}
                            onClick={handleDeleteConfirmOpen}
                            children={'Deactivate Record'}
                            className={'menu__error'}
                        />
                    )
                ]}
            </Menu>

            {isAddingNote && (
                <AddNote
                    open={true}
                    record={record}
                    onSave={() => doReload(record)}
                    modelId={record.id}
                    onClose={handleAddNoteClose}
                    modelType={'Client'}
                />
            )}

            {isEditing && (
                <ClientForm
                    open={true}
                    record={record}
                    onSave={handleClientSave}
                    onClose={handleClientEditClose}
                />
            )}

            {isViewingShifts && (
                <ExportShiftsDialog
                    open={true}
                    record={record}
                    onClose={handleShiftExportClose}
                />
            )}

            {isConfirmingDelete && (
                <Dialog
                    open={isConfirmingDelete}
                    scroll={'body'}
                    onClose={handleDeleteConfirmClose}
                    maxWidth={'xs'}
                    fullWidth
                >
                    <DialogHeading
                        title={"Confirm Delete"}
                        noMargin
                    />
                    <DialogContent>
                        Are you sure that you'd like to delete this record?
                    </DialogContent>
                    <DialogActions>
                        <Button
                            onClick={doDelete}
                            children={'Delete'}
                            disabled={isLoading}
                        />
                    </DialogActions>
                </Dialog>
            )}
        </div>
    );
};

export default ClientActions;