import React from "react";

import Box from "@mui/material/Box";

/**
 * TabPanel component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const TabPanel = (props) => {
    const {
        value,          // {Number} The current tab index.
        index,          // {Number|String} This tab's expected value to be displayed.
        children,       // {Component} The inner content of the tab.
        lazyLoad,       // {Boolean} Indicates if the tab should load when it is viewed rather than initially.
        fullWidth,      // {Boolean} Indicates if the tab should be full width.
        ...other
    } = props;

    const isHidden = value !== index;

    if(lazyLoad && isHidden){
        return null;
    }

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            {...other}
        >
            <Box
                sx={isHidden ? {
                    display: 'none'
                } : {}}
                children={children}
                className={!fullWidth ? 'p__3' : ''}
            />
        </div>
    );
};

export default TabPanel;