import {useState, useEffect} from 'react'

import Logger from "../Global/Logger";
import Settings from "../Global/Settings";

/**
 * Returns the call recipient.
 *
 * @returns {Object}
 */
const getRecipient = () => Settings.activeCall.recipient || {};


/**
 * Returns the muted status of the phone call.
 *
 * @returns {Boolean}
 */
const getMuted = () => !!Settings.activeCall.isMuted;


/**
 * Returns the current phone number.
 *
 * @returns {String}
 */
const getPhoneNumber = () => Settings.activeCall.phoneNumber || '';


/**
 * Returns the current phone call.
 *
 * @returns {Call|null}
 */
const getPhoneCall = () => Settings.activeCall.call || null;


/**
 * useScreenOrientation hook.
 *
 * @returns {{phoneNumber: String, isMuted: Boolean, phoneCall: Call}}
 */
const usePhoneCall = () => {
    const [isMuted, setMuted] = useState(getMuted());
    const [recipient, setRecipient] = useState(getRecipient());
    const [phoneCall, setPhoneCall] = useState(getPhoneCall());
    const [phoneNumber, setPhoneNumber] = useState(getPhoneNumber());

    /**
     * Processes the state update for the call.
     */
    const handleUpdate = event => {
        Logger.debug('[usePhoneCall] Received call update:', event.detail);
        Settings.activeCall = event.detail;
        setMuted(getMuted());
        setRecipient(getRecipient());
        setPhoneCall(getPhoneCall());
        setPhoneNumber(getPhoneNumber());
    };


    /**
     * Synchronize window events against the hook state.
     */
    useEffect(() => {
        window.addEventListener(
            'phonecallchange',
            handleUpdate
        );

        return () => {
            window.removeEventListener(
                'phonecallchange',
                handleUpdate
            )
        }
    }, []);

    return {
        isMuted,
        phoneCall,
        recipient,
        phoneNumber
    };
};

export default usePhoneCall