import React from "react";

import Page from "../../Components/Page";
import ClientView from "./Client/ClientView";

import {useParams} from "react-router";

/**
 * Client component.
 *
 * @returns {*}
 * @constructor
 */
const Client = () => {
    const {id} = useParams();

    return (
        <Page hideHeader children={<ClientView id={id}/>}/>
    );
};

export default Client;