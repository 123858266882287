import React from "react";

import TableLayout from "../../Components/Layouts/TableLayout";
import EmployeeShift from "../../Models/EmployeeShift";

import Button from "@mui/material/Button";
import {useNavigate} from "react-router-dom";
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

/**
 * Schedule component.
 *
 * @returns {*}
 * @constructor
 */
const Schedule = () => {
    const navigate = useNavigate();

    return (
        <TableLayout
            icon={<CalendarMonthIcon/>}
            model={EmployeeShift}
            heading={'Schedule'}
            useLoader
            condensed
            resultLabel={'shifts'}
            hideFilters
            filterSlotEnd={
                <Button
                    size={'small'}
                    variant={'outlined'}
                    onClick={() => navigate('/')}
                    children={'Back'}
                    className={'text__small'}
                />
            }
        />
    )
};

export default Schedule;