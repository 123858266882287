import React, {useEffect, useState} from "react";

import API from "../../Global/API";
import State from "../../Global/State";
import Logger from "../../Global/Logger";
import Client from '../../Models/Client';
import {useAuth} from "../../Global/Auth";
import ClientView from "../../Pages/Admin/Client/ClientView";
import ClientForm from "../../Actions/ClientActions/ClientForm";
import ModalButton from "../ModalButton";
import useDebounce from "../../Hooks/useDebounce";
import EmployeeView from "../../Pages/Admin/Employee/EmployeeView";
import EmployeeForm from "../../Actions/EmployeeActions/EmployeeForm";
import ContactDetails from "../Layouts/Profile/ContactsBlock/ContactDetails";
import ClientStatusIndicator from "../../Pages/Admin/Client/ClientStatusIndicator";
import EmployeeStatusIndicator from "../../Pages/Admin/Employee/EmployeeStatusIndicator";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from "@mui/material/CircularProgress";

/**
 * QuickSearch component.
 *
 * @returns {*}
 * @constructor
 */
const QuickSearch = () => {
    const {hasPermissionTo} = useAuth();
    const [search, setSearch] = useState('');
    const [clients, setClients] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [employees, setEmployees] = useState([]);
    const [isInactive, setInactive] = useState(false);
    const [isClientFormOpen, setClientFormOpen] = useState(false);
    const [isEmployeeFormOpen, setEmployeeFormOpen] = useState(false);

    /**
     * Load search suggestions as the search parameter is updated.
     */
    useEffect(() => {
        Logger.debug(`[QuickSearch] Finding results for "${search}"...`);
        debounceSuggestions();
    }, [search, isInactive]);


    /**
     * Indicates whether the search parameter is a phone number.
     *
     * @type {boolean}
     */
    const isPhone = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(search || '');


    /**
     * Indicates whether to display results.
     *
     * @type {boolean}
     */
    const open = Boolean(anchorEl && search);


    /**
     * Merges the results into something more organized and sortable.
     *
     * @returns {Array}
     */
    const allResults = (() => {
        return employees.map(employee => {
            employee['name'] = `${employee.lastName}, ${employee.firstName}`;
            employee['type'] = 'employee';

            return employee;
        }).concat(clients.map(client => {
            client['type'] = 'client';

            return client;
        })).sort((a, b) => {
            if (a.name < b.name) {
                return -1;
            }

            if (a.name > b.name) {
                return 1;
            }

            return 0;
        });
    })();


    /**
     * Attempt to load any employee matches via the API.
     *
     * @returns {Promise<void>}
     */
    const getEmployees = async () => {
        if(!hasPermissionTo('VIEW_EMPLOYEES')){
            return;
        }

        const employees = await API.get('employees-by-phone-number', {
            $top: 10,
            $filter: isPhone ? '' : search,
            $expand: 'status, specialty',
            phoneNumber: isPhone ? search : '',
        });

        if (!employees || employees.error) {
            return setEmployees([]);
        }

        setEmployees(employees);
    };


    /**
     * Attempt to load any client matches via the API.
     *
     * @returns {Promise<void>}
     */
    const getClients = async () => {
        if(!hasPermissionTo('VIEW_CLIENTS')){
            return;
        }

        const clients = await API.get('clients-by-phone-number', {
            $top: 10,
            $filter: isPhone ? '' : search,
            phoneNumber: isPhone ? search : '',
        });

        if (!clients || clients.error) {
            return setClients([]);
        }

        setClients(clients);
    };


    /**
     * Attempts to load all quick search suggestions via the API.
     *
     * @returns {Promise<void>}
     */
    const getSuggestions = async () => {
        await Promise.all([
            getClients(),
            getEmployees(),
        ]);
        setLoading(false);
    };


    /**
     * Handles search value inputs.
     *
     * @param event
     */
    const handleSearch = (event) => {
        setAnchorEl(event.target);
        setSearch(event.target.value);
        State.set('global-search', event.target.value);
    };


    /**
     * Returns the default client record.
     *
     * @returns {Object}
     */
    const getDefaultClient = () => {
        return {
            name: search.charAt(0).toUpperCase() + search.slice(1)
        };
    };


    /**
     * Reveals the client form.
     */
    const handleClientFormOpen = () => {
        setClientFormOpen(true);
    };


    /**
     * Closes the client form.
     */
    const handleClientFormClose = () => {
        setClientFormOpen(false);
    };


    /**
     * Returns a default employee record with the quick add control.
     *
     * @returns {Object}
     */
    const getDefaultEmployee = () => {
        if (!search) {
            return {};
        }

        const parts = search
            .split(' ')
            .filter(part => !!part.trim())
            .map(part => part.charAt(0).toUpperCase() + part.slice(1));

        if (parts.length < 1) {
            return {};
        }

        if (parts.length < 2) {
            return {
                firstName: parts[0]
            };
        }

        return {
            firstName: parts[0],
            lastName: parts[1]
        }
    };


    /**
     * Reveals the employee form.
     */
    const handleEmployeeFormOpen = () => {
        setEmployeeFormOpen(true);
    };


    /**
     * Closes the employee form.
     */
    const handleEmployeeFormClose = () => {
        setEmployeeFormOpen(false);
    };


    /**
     * Handles search suggestion debounce.
     */
    const debounceSuggestions = useDebounce(async () => {
        getSuggestions();
    });


    /**
     * Closes the results popover.
     */
    const handleResultsClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={'search__wrapper'}>
            <input
                type={'text'}
                value={search}
                onChange={handleSearch}
                className={'search'}
                onKeyDown={(event) => {
                    setLoading(true);

                    if (event.key === 'Escape' && open) {
                        setAnchorEl(null);
                    } else if (event.key === 'Enter' && allResults.length) {
                        const result = allResults[0];
                        const menuItemEl = result.type === 'employee' ?
                            document.querySelector(`#employee-${result.id}`) :
                            document.querySelector(`#client-${result.id}`);

                        if (!menuItemEl) {
                            return;
                        }

                        menuItemEl.click();
                    }
                }}
                placeholder={'Type to search...'}
            />
            <Box className={'search__icon'}>
                {isLoading ? (
                    <CircularProgress
                        color={'inherit'}
                    />
                ) : <SearchIcon/>}
            </Box>
            <Popover
                open={open}
                onClose={handleResultsClose}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                disableAutoFocus={true}
                disableEnforceFocus={true}
            >
                <MenuList sx={{maxHeight: 500}} className={'quick-search__results'}>
                    {allResults.map(record => {
                        switch (record.type) {
                            case 'employee':
                                return (
                                    <ModalButton
                                        id={`employee-${record.id}`}
                                        key={`employee-${record.id}`}
                                        className={'quick-search__result'}
                                        bodyStyle={{
                                            paddingTop: 0
                                        }}
                                        label={
                                            <MenuItem>
                                                <ContactDetails
                                                    src={API.getFilePath(record.image)}
                                                    name={record.name}
                                                    actions={
                                                        <EmployeeStatusIndicator
                                                            record={record}
                                                            className={'mr__2'}
                                                        />
                                                    }
                                                    minWidth={500}
                                                    description={'Employee'}
                                                />
                                            </MenuItem>
                                        }
                                        doRender={handleClose => (
                                            <EmployeeView
                                                id={record.id}
                                                onClose={() => {
                                                    handleClose();
                                                    handleResultsClose();
                                                }}
                                            />
                                        )}
                                    />
                                );

                            case 'client':
                                return (
                                    <ModalButton
                                        id={`client-${record.id}`}
                                        key={`client-${record.id}`}
                                        className={'quick-search__result'}
                                        bodyStyle={{
                                            paddingTop: 0
                                        }}
                                        label={
                                            <MenuItem>
                                                <ContactDetails
                                                    src={API.getFilePath(record.image)}
                                                    name={record.name}
                                                    icon={Client.icon()}
                                                    actions={
                                                        <ClientStatusIndicator
                                                            record={record}
                                                            className={'mr__2'}
                                                        />
                                                    }
                                                    minWidth={500}
                                                    horizontal
                                                    description={'Client'}
                                                />
                                            </MenuItem>
                                        }
                                        doRender={handleClose => (
                                            <ClientView
                                                id={record.id}
                                                onClose={() => {
                                                    handleClose();
                                                    handleResultsClose();
                                                }}
                                            />
                                        )}
                                    />
                                );

                            default:
                                return null;
                        }
                    })}
                </MenuList>

                {!employees.length && !clients.length && (
                    <Box
                        sx={{minWidth: 500}}
                        className={'p__3 columns__1'}
                    >
                        <div className={'d-flex__justify'}>
                            <span>
                                No results for "{search}"... 🥺
                            </span>
                        </div>
                        <div className={'columns__2'}>
                            {hasPermissionTo('EDIT_EMPLOYEES') && (
                                <Button
                                    variant={'outlined'}
                                    onClick={handleEmployeeFormOpen}
                                    children={'Add an Employee'}
                                />
                            )}

                            {hasPermissionTo('EDIT_CLIENTS') && (
                                <Button
                                    variant={'outlined'}
                                    onClick={handleClientFormOpen}
                                    children={'Add a Client'}
                                />
                            )}
                        </div>
                    </Box>
                )}
            </Popover>

            {isEmployeeFormOpen && (
                <EmployeeForm
                    key={`${search}-employee-form`}
                    open={true}
                    onSave={record => {
                        setEmployeeFormOpen(false);
                        setSearch(record.displayName);
                    }}
                    record={getDefaultEmployee()}
                    onClose={handleEmployeeFormClose}
                />
            )}

            {isClientFormOpen && (
                <ClientForm
                    key={`${search}-client-form`}
                    open={true}
                    onSave={record => {
                        setClientFormOpen(false);
                        setSearch(record.name);
                    }}
                    record={getDefaultClient()}
                    onClose={handleClientFormClose}
                />
            )}
        </div>
    );
};

export default QuickSearch;