import React from "react";

import Formatter from "../Global/Formatter";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import ListItem from "@mui/material/ListItem";
import CheckIcon from "@mui/icons-material/TaskAlt";
import ListItemText from "@mui/material/ListItemText";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";

/**
 * ActionButton component.
 *
 * @param props
 * @constructor
 */
const ActionButton = (props) => {
    const {
        icon,                   // {Component} An icon component to include within the left bubble.
        delay,                  // {Number} A delay offset for animations.
        heading,                // {String} The heading text content.
        onClick,                // {Function} A click handler for the button.
        disabled,               // {Boolean} Indicates whether we should disable this input.
        description,            // {Component} An optional incomplete description.
        completedDate,          // {String} The date the action was completed.
        disableAnimation,       // {Boolean} Indicates whether the animation should be disabled.
    } = props;

    /**
     * Indicates whether the action is completed.
     *
     * @type {boolean}
     */
    const isCompleted = !!completedDate;


    /**
     * The animation types for the action.
     *
     * @type {string}
     */
    const animationType = !disableAnimation ? 'fade-right' : '';
    const iconAnimationType = !disableAnimation ? 'fade' : '';


    /**
     * The amount of time to delay the animation.
     *
     * @type {number}
     */
    const animationDelay = delay || 0;

    return (
        <ListItemButton
            sx={{paddingTop: 0, paddingBottom: 0}}
            onClick={onClick}
            disabled={disabled}
            disableGutters
        >
            <ListItem
                data-aos={animationType}
                data-aos-delay={animationDelay}

                disableGutters
                secondaryAction={
                    <Box data-aos={iconAnimationType} data-aos-delay={500 + animationDelay}>
                        {isCompleted ? (
                            <CheckIcon color={'success'}/>
                        ) : (
                            <Chip
                                size={'small'}
                                color={'warning'}
                                label={'Incomplete'}
                            />
                        )}
                    </Box>
                }
            >
                <ListItemAvatar>
                    <Avatar sx={isCompleted ? {backgroundColor: 'var(--primary-color)'} : {}}>
                        {icon}
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={
                        <b>{heading}</b>
                    }
                    secondary={
                        isCompleted ? (
                            <Box className={'text__small'}>
                                <span>Completed {Formatter.dateTime(completedDate)}</span>
                            </Box>
                        ) : (
                            <Box
                                children={!!description ? description : '-'}
                                className={'text__small'}
                            />
                        )
                    }
                />
            </ListItem>
        </ListItemButton>
    );
};

export default ActionButton;