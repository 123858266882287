import React, {useRef, useState} from "react";

import API from "../../../Global/API";
import Client from "../../../Models/Client";
import Formatter from "../../../Global/Formatter";
import ModelSearch from "../../../Components/Input/ModelSearch";
import ImageWrapper from "../../../Components/ImageWrapper";
import SectionHeading from "../../../Components/Typography/SectionHeading";

import Box from "@mui/material/Box";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import {DatePicker} from "@mui/x-date-pickers";
import TableContainer from "@mui/material/TableContainer";
import LinearProgress from "@mui/material/LinearProgress";

/**
 * The number of results or page to sequentially pull data.
 *
 * @type {number}
 */
const perPageLimit = 250;


/**
 * EmployeesByFacility component.
 *
 * @returns {*}
 * @constructor
 */
const EmployeesByFacility = (props) => {
    const {
        loading,            // {Boolean} Indicates if the report is loading.
        onComplete          // {Function} A post-completion callback from whenever the report has results.
    } = props;

    const resultsRef = useRef(null);
    const [clients, setClients] = useState([]);
    const [endDate, setEndDate] = useState(dayjs().endOf('month').format('YYYY-MM-DD'));
    const [isLoading, setLoading] = useState(false);
    const [startDate, setStartDate] = useState(dayjs().startOf('month').format('YYYY-MM-DD'))
    const [nameFormat, setNameFormat] = useState('Last, First');
    const [showResults, setShowResults] = useState(false);
    const [clientResults, setClientResults] = useState([]);
    const [sortDirection, setSortDirection] = useState('Ascending');

    /**
     * Loads all of our client rows.
     *
     * @returns {Promise<void>}
     */
    const getAllClients = async () => {
        let promises = [];

        // Determine the filter criteria.
        let eventFilters = ['isBlockRequested eq {0}'];
        eventFilters.push(endDate ? `startDate lt {${endDate}}` : ``);
        eventFilters.push(startDate ? `startDate gt {${startDate}}` : ``);
        eventFilters = eventFilters.filter(filter => !!filter.trim());

        // Filter specific clients, if applicable.
        let clientSelection = clients && clients.length ? ` and id in {${clients.map(client => client.id).join(',')}}` : '';

        // Load all the clients page by page until we've reached the end.
        const count = await API.get('clients/count', {
            $top: perPageLimit,
            $filter: `events/any{${eventFilters.join(' and ')}}${clientSelection}`,
        });

        for (let page = 0; page < count.pages; page++) {
            promises.push(
                API.get('clients', {
                    $top: perPageLimit,
                    $skip: page * perPageLimit,
                    endDate,
                    $filter: `events/any{${eventFilters.join(' and ')}}${clientSelection}`,
                    $select: '*,employeesOnSchedule',
                    $orderby: `name asc`,
                    startDate,
                    nameFormat,
                    sortDirection,
                })
            );
        }

        const results = await Promise.all(promises);
        setClientResults(results.flat());
    };


    /**
     * Loads all of our report data.
     *
     * @returns {Promise<void>}
     */
    const getResults = async () => {
        setLoading(true);

        await Promise.all([
            getAllClients(),
        ]);

        setShowResults(true);
        setLoading(false);

        if(onComplete){
            onComplete(resultsRef);
        }
    };


    /**
     * Indicates if the report is in progress.
     *
     * @type {boolean}
     */
    const inProgress = isLoading || loading;

    return (
        <Box className={'d-flex'}>
            <Box
                sx={{width: 400, overflowY: 'hidden'}}
                className={'border__right p__3 full__height--left-tabs'}
            >
                <Box className={'columns__1'}>
                    <Box className={'columns__2'}>
                        <DatePicker
                            label="Start Date"
                            value={startDate ? dayjs(startDate) : null}
                            disabled={inProgress}
                            onChange={event => setStartDate(event ? event.format('YYYY-MM-DD') : '')}
                            fullWidth
                        />

                        <DatePicker
                            label="End Date"
                            value={endDate ? dayjs(endDate) : null}
                            disabled={inProgress}
                            onChange={event => setEndDate(event ? event.format('YYYY-MM-DD') : '')}
                            fullWidth
                        />
                    </Box>

                    <ModelSearch
                        model={Client}
                        value={clients}
                        label={'Client(s)'}
                        multiple
                        disabled={inProgress}
                        onChange={setClients}
                        helperText={'(Leave blank to include all.)'}
                        renderLabel={option => !option ? '' : option.name}
                        filterQuery={query => `isDeleted eq {0} and name eq {${query}}`}
                        queryParams={{
                            $top: 250,
                            $orderby: 'name asc'
                        }}
                    />

                    <FormControl>
                        <InputLabel>Sort Direction</InputLabel>
                        <Select
                            value={sortDirection}
                            label={'Sort Direction'}
                            disabled={inProgress}
                            onChange={event => setSortDirection(event.target.value)}
                        >
                            {['Ascending', 'Descending'].map((option) =>
                                <MenuItem
                                    key={option}
                                    value={option}
                                    children={option}
                                />
                            )}
                        </Select>
                    </FormControl>

                    <FormControl>
                        <InputLabel>Name Format</InputLabel>
                        <Select
                            value={nameFormat}
                            label={'Name Format'}
                            disabled={inProgress}
                            onChange={event => setNameFormat(event.target.value)}
                        >
                            {['Last, First', 'First Last'].map((option) =>
                                <MenuItem
                                    key={option}
                                    value={option}
                                    children={option}
                                />
                            )}
                        </Select>
                    </FormControl>

                    <Button
                        variant={'outlined'}
                        onClick={getResults}
                        children={'Apply'}
                        disabled={inProgress}
                    />
                </Box>
            </Box>

            <Box
                sx={{borderRadius: 0}}
                className={`flex__grow full__height--left-tabs ${!showResults ? 'well__container' : ''}`}
            >
                {inProgress && <LinearProgress sx={{width: '100%'}}/>}

                {!showResults && (
                    <Box className={'p__3 text__center text__disclaimer'}>Your results will display here.</Box>
                )}

                {showResults && (
                    <Box className={'p__3 columns__1'}>
                        <Box>
                            <SectionHeading title={'Clients'} primary/>
                            <TableContainer className={'table table--striped result__set'}>
                                <Table size={'small'}>
                                    <colgroup>
                                        <col width={'20%'}/>
                                        <col width={'80%'}/>
                                    </colgroup>
                                    <TableBody>
                                        {clientResults.map(client => {
                                            const employeesOnSchedule = client.employeesOnSchedule || [];

                                            if(!employeesOnSchedule || !employeesOnSchedule.length) {
                                                return null;
                                            }

                                            return (
                                                <TableRow key={client.id}>
                                                    <TableCell className={'v-align__top'}>
                                                        <Box
                                                            sx={{
                                                                width: '100%',
                                                                paddingTop: '0.2em',
                                                                paddingBottom: '0.2em'
                                                            }}
                                                            className={'d-flex__start'}
                                                        >
                                                            <ImageWrapper
                                                                src={API.getFilePath(client.image)}
                                                                width={36}
                                                                height={36}
                                                                noCache
                                                                className={'mr__2'}
                                                                horizontal
                                                            />
                                                            <b>{client.name}</b>
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell padding={'none'}>
                                                        <Table>
                                                            <colgroup>
                                                                <col width={'25%'}/>
                                                                <col width={'25%'}/>
                                                                <col width={'25%'}/>
                                                                <col width={'25%'}/>
                                                            </colgroup>
                                                            <TableBody>
                                                                {employeesOnSchedule.map(employee => (
                                                                    <TableRow key={employee.id}>
                                                                        <TableCell sx={{padding: '0.5em', background: 'none!important'}}>
                                                                            {nameFormat === 'First Last' ?
                                                                                `${employee.firstName} ${employee.lastName}` :
                                                                                `${employee.lastName}, ${employee.firstName}`
                                                                            }
                                                                        </TableCell>
                                                                        <TableCell sx={{padding: '0.5em', background: 'none!important'}}>{employee.specialty}</TableCell>
                                                                        <TableCell sx={{padding: '0.5em', background: 'none!important'}}>{Formatter.email(employee.emailAddress)}</TableCell>
                                                                        <TableCell sx={{padding: '0.5em', background: 'none!important'}}>{Formatter.phone(employee.phoneNumber)}</TableCell>
                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableCell>
                                                </TableRow>
                                            )
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Box>
                    </Box>
                )}
            </Box>

            {/*The hidden display for PDF rendering.*/}
            <Box className={'d-hidden'}>
                <Box ref={resultsRef}>
                    <table className={'table--bordered'}>
                        <tbody>
                            <tr>
                                <td style={{width: '20%'}}>
                                    <b>Client</b>
                                </td>
                                <td style={{width: '20%'}}>
                                    <b>Employee</b>
                                </td>
                                <td style={{width: '12%'}}>
                                    <b>Specialty</b>
                                </td>
                                <td>
                                    <b>Email</b>
                                </td>
                                <td style={{width: '20%'}}>
                                    <b>Phone</b>
                                </td>
                            </tr>

                            {clientResults.map(client => {
                                const employeesOnSchedule = client.employeesOnSchedule || [];

                                if(!employeesOnSchedule || !employeesOnSchedule.length) {
                                    return null;
                                }

                                return employeesOnSchedule.map((employee, i) => (
                                    <tr key={`${client.id}-${employee.id}`}>
                                        <td
                                            style={{
                                                verticalAlign: 'top',
                                                border: 'none',
                                                borderLeft: '1px solid #ccc',
                                                borderTop: !i ? '1px solid #ccc' : 'none',
                                                borderBottom: i === employeesOnSchedule.length - 1 ? '1px solid #ccc' : 'none'
                                            }}
                                        >
                                            {!i && (
                                                <>{client.name}</>
                                            )}
                                        </td>
                                        <td>
                                            {nameFormat === 'First Last' ?
                                                `${employee.firstName} ${employee.lastName}` :
                                                `${employee.lastName}, ${employee.firstName}`
                                            }
                                        </td>
                                        <td>
                                            {employee.specialty}
                                        </td>
                                        <td>
                                            {Formatter.email(employee.emailAddress)}
                                        </td>
                                        <td>
                                            {Formatter.phone(employee.phoneNumber)}
                                        </td>
                                    </tr>
                                ));
                            })}
                        </tbody>
                    </table>
                </Box>
            </Box>
        </Box>
    );
};

export default EmployeesByFacility;