import React, {useEffect, useState} from "react";

import API from "../Global/API";
import Page from "../Components/Page";
import Panel from "../Components/Panel";
import {useAuth} from '../Global/Auth';
import Formatter from "../Global/Formatter";
import CopyrightDisclaimer from "./Login/CopyrightDisclaimer";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import {useNavigate} from "react-router";

/**
 * Register component.
 *
 * @returns {*}
 * @constructor
 */
const Register = () => {
    const navigate = useNavigate();
    const [id, setId] = useState('');
    const [error, setError] = useState('');
    const {authenticated, login} = useAuth();
    const [isLoading, setLoading] = useState(false);
    const [emailAddress, setEmailAddress] = useState('');

    /**
     * If the user is already authenticated, redirect to the dashboard.
     */
    useEffect(() => {
        if (authenticated) {
            navigate('/');
        }
    }, []);


    /**
     * Attempt to login using the provided account credentials.
     */
    const doLookup = async () => {
        setError('');
        setLoading(true);

        const response = await API.get('auth/find', {
            emailAddress
        });

        setLoading(false);

        if(response.status === 'error') {
            return setError(response.message);
        }

        // Validate that we received a password reset token.
        if(response.token){
            return navigate(`/reset-password/${response.token}`);
        }

        setError('Unable to find a matching account.');
    };


    /**
     * Allow login on enter key.
     *
     * @param event
     */
    const handleKeyDown = event => {
        if (event.key === 'Enter') {
            event.preventDefault();
            doLookup();
        }
    };

    return (
        <Page hideHeader>
            <Box className={'columns__1'}>
                <Panel
                    header={
                        <Box
                            className={'text__center'}
                            children={
                                <img
                                    src={Formatter.url('assets/images/logo.png')}
                                    style={{width: '70%'}}
                                />
                            }
                        />
                    }
                    padding={0}
                    className={'login__block'}
                    hideDivider
                >
                    <Box className={'columns__1'} sx={{padding: '1.5em', paddingTop: 0}}>
                        {error && <Alert color={'error'} children={error}/>}

                        <Box>Please enter the email address that you'd given to your recruiter. After we find your account, you can set a secure password.</Box>

                        <TextField
                            value={emailAddress}
                            label={'Email Address'}
                            required
                            disabled={isLoading}
                            onChange={event => setEmailAddress(event.target.value)}
                            onKeyDown={handleKeyDown}
                            fullWidth
                        />

                        <div className={'columns__2'}>
                            <Button
                                sx={{padding: '1em', textTransform: 'capitalize'}}
                                onClick={doLookup}
                                variant={'contained'}
                                children={'Find My Account'}
                                disabled={isLoading}
                            />

                            <Button
                                sx={{padding: '1em', textTransform: 'capitalize'}}
                                variant={'outlined'}
                                onClick={() => navigate('/login')}
                                disabled={isLoading}
                                children={'Back'}
                            />
                        </div>

                        <CopyrightDisclaimer/>
                    </Box>
                </Panel>
            </Box>
        </Page>
    );
};

export default Register;