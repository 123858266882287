import React from "react";

import Template from "../../Models/Template";
import TableLayout from "../../Components/Layouts/TableLayout";

import FeedIcon from '@mui/icons-material/Feed';

/**
 * Templates component.
 *
 * @returns {*}
 * @constructor
 */
const Templates = () => (
    <TableLayout
        icon={<FeedIcon/>}
        model={Template}
        heading={'Templates'}
        condensed
        hideFilters
        formPermission={'EDIT_COMMUNICATIONS'}
    />
);

export default Templates;