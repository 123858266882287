import React from "react";

import App from "../Global/App";
import Model from "../Definitions/Model";
import Property from "../Definitions/Property";
import PropertyMap from "../Definitions/PropertyMap";
import EmployeeShiftActions from "../Actions/EmployeeShiftActions";
import EmployeeShiftListItem from "../Components/Lists/EmployeeShiftListItem";

import dayjs from "dayjs";

/**
 * UpcomingShift model.
 *
 * @type {Object}
 */
const EmployeeShift = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'event',


    /**
     * The appropriate API endpoint root.
     */
    route: 'events',


    /**
     * The menu action for this model.
     *
     * @param parameters
     * @returns {*}
     */
    action: (parameters) => <EmployeeShiftActions {...parameters}/>,


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'startDate',
    ],


    /**
     * Returns any default query parameters.
     *
     * @returns {*|{}}
     */
    getQuery() {
        const user = App.getUser();
        const startDate = dayjs().format('YYYY-MM-DD');
        const filter = this.getDefaultFilter();

        const query = {
            $filter: `employeeId in {${user.id}} and startDate gt {${startDate}} and isBlockRequested eq {0}`,
            $expand: 'client($expand=state)',
            $orderby: 'startDate asc'
        };

        return filter && filter.query ? {
            ...query,
            ...filter.query
        } : query;
    },


    /**
     * Adjust the index layout accordingly.
     */
    tableOptions: {
        ...Model.tableOptions,
        hideTableHeader: true
    },


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'startDate',
            label: 'Shift',
            render: (record) => <EmployeeShiftListItem record={record} className={'p__3'}/>,
            sortable: true
        })
    ])
};

export default EmployeeShift;