import React from "react";

import App from "../Global/App";
import Model from "../Definitions/Model";
import Property from "../Definitions/Property";
import Formatter from "../Global/Formatter";
import PropertyMap from "../Definitions/PropertyMap";

import Button from "@mui/material/Button";
import LaunchIcon from '@mui/icons-material/Launch';
import JsonFormatter from "react-json-formatter";

/**
 * The style guide for our JSON output.
 *
 * @type {{stringStyle: {color: string}, propertyStyle: {color: string}, numberStyle: {color: string}}}
 */
const jsonStyle = {
    propertyStyle: { color: 'red' },
    stringStyle: { color: 'green' },
    numberStyle: { color: 'darkorange' }
};


/**
 * History model.
 *
 * @type {Object}
 */
const History = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'history',


    /**
     * The appropriate API endpoint root.
     */
    route: 'history',


    /**
     * Any additional query parameters.
     */
    query: {},


    /**
     * Returns any default query parameters.
     *
     * @returns {*|{}}
     */
    getQuery() {
        const user = App.getUser();
        const filter = this.getDefaultFilter();

        const query = {
            ...this.query,
            $filter: `modelId in {${user.id}} and modelType in {${user['@model']}}`,
            $orderby: 'createdDate desc'
        };

        return filter && filter.query ? {
            ...query,
            ...filter.query
        } : query;
    },


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'createdDate',
        'method',
        'objectClass',
        'payload',
        'viewButton'
    ],


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'createdDate',
            label: 'Created At',
            render: record => Formatter.dateTime(record.createdDate),
            sortable: false
        }),
        Property.create({
            key: 'method',
            label: 'Method',
            render: record => Formatter.capitalize(record.method),
            sortable: true,
        }),
        Property.create({
            key: 'payload',
            label: 'Payload',
            render: record => (
                <code className={'well__container text__small'}>
                    <JsonFormatter json={record.payload} tabWith={4} jsonStyle={jsonStyle} />
                </code>
            ),
            sortable: true,
        }),
        Property.create({
            key: 'objectClass',
            label: 'Object',
            render: record => record.objectClass,
            sortable: true,
        }),
        Property.create({
            key: 'endpoint',
            label: 'Endpoint',
            render: record => record.endpoint,
            sortable: true,
        }),
        Property.create({
            key: 'viewButton',
            width: 200,
            label: 'View Record',
            render: record => {
                return (
                    <Button
                        startIcon={<LaunchIcon/>}
                    >View Record</Button>
                );
            },
            sortable: true,
        })
    ])
};

export default History;