import React from "react";

import Page from "../../Components/Page";
import EmployeeView from "./Employee/EmployeeView";

import {useParams} from "react-router";

/**
 * Employee component.
 *
 * @returns {*}
 * @constructor
 */
const Employee = () => {
    const {id} = useParams();

    return (
        <Page
            hideHeader
            children={<EmployeeView id={id}/>}
        />
    );
};

export default Employee;