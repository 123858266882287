import React from "react";

import API from "../Global/API";
import Model from "../Definitions/Model";
import Property from "../Definitions/Property";
import Formatter from "../Global/Formatter";
import ModalButton from "../Components/ModalButton";
import PropertyMap from "../Definitions/PropertyMap";
import EmployeeView from "../Pages/Admin/Employee/EmployeeView";
import TimeSheetForm from "../Actions/TimeSheetActions/TimeSheetForm";
import TimeSheetReview from "./TimeSheet/TimeSheetReview";
import TimeSheetActions from "../Actions/TimeSheetActions";
import EmployeeListItem from "../Components/Lists/EmployeeListItem";

import Chip from "@mui/material/Chip";
import DownloadIcon from '@mui/icons-material/Download';

/**
 * Department model.
 *
 * @type {Object}
 */
const Department = {
    ...Model,

    /**
     * A unique key for local storage prefixes, cache keys, etc.
     */
    key: 'time-sheet-v4',


    /**
     * The appropriate API endpoint root.
     */
    route: 'time-sheets',


    /**
     * Additional query parameters to share throughout the application.
     */
    query: {
        $expand: 'employee($expand=status,specialty,employeeClients),client',
    },


    /**
     * Adjust the index layout accordingly.
     */
    tableOptions: {
        ...Model.tableOptions,
        rowPadding: 'none'
    },


    /**
     * The form component.
     *
     * @returns {*}
     * @param parameters
     */
    form: (parameters) => <TimeSheetForm {...parameters}/>,


    /**
     * The menu action.
     *
     * @param parameters
     * @returns {*}
     */
    action: (parameters) => <TimeSheetActions {...parameters}/>,


    /**
     * The default table layout for the frontend.
     */
    layout: [
        'employeeId',
        'effectiveDate',
        'createdDate',
        'downloadDate',
        'hours',
        'clientId',
        'actions',
    ],


    /**
     * All available model properties / columns.
     */
    properties: new PropertyMap([
        Property.create({
            key: 'id',
            label: 'ID',
            render: null,
            selectable: false
        }),
        Property.create({
            key: 'employeeId',
            label: 'Employee',
            render: record => {
                const employee = {
                    ...record.employee
                };

                return (
                    <ModalButton
                        label={
                            <EmployeeListItem
                                employee={employee}
                                onSelect={() => true}
                            />
                        }
                        bodyStyle={{
                            paddingTop: 0
                        }}
                        children={
                            <EmployeeView id={employee.id}/>
                        }
                    />

                );
            },
            sortable: false,
            filterable: true,
            renderLabel: option => option.displayName,
            filterIsEqual: (option, value) => option.id === value.id,
            filterResults: async (value) => {
                return await API.get('employees', {
                    $select: 'id,displayName',
                    $filter: value ? `displayName eq {${value}}` : '',
                    $orderby: `displayName asc`
                });
            },
            defaultFilterOptions: async (value) => {
                return await API.get('employees', {
                    $filter: `id in {${value.join(',')}}`
                })
            }
        }),
        Property.create({
            key: 'effectiveDate',
            label: 'Effective Date',
            render: record => Formatter.dateTime(record.effectiveDate, '', false),
            sortable: true,
            filterable: true,
            filterType: 'dateTime'
        }),
        Property.create({
            key: 'createdDate',
            label: 'Created At',
            render: record => Formatter.dateTime(record.createdDate),
            sortable: true,
        }),
        Property.create({
            key: 'clientId',
            label: 'Client',
            render: record => {
                const client = {
                    ...record.client
                };

                return client.name || '';
            },
            sortable: false,
        }),
        Property.create({
            key: 'hours',
            label: 'Hours',
            render: record => record.hours,
            sortable: true,
        }),
        Property.create({
            key: 'isReviewed',
            label: 'Reviewed',
            render: record => !record.isReviewed ? 'No' : 'Yes',
            sortable: true,
            selectable: true,
            filterable: true,
            renderLabel: option => !!option ? 'Yes' : 'No',
        }),
        Property.create({
            key: 'isRejected',
            label: 'Rejected',
            render: record => !record.isRejected ? 'No' : 'Yes',
            sortable: true,
            selectable: true,
            filterable: true,
            renderLabel: option => !!option ? 'Yes' : 'No',
        }),
        Property.create({
            key: 'isDownloaded',
            label: 'Downloaded',
            render: record => !record.isDownloaded ? 'No' : 'Yes',
            sortable: true,
            selectable: true,
            filterable: true,
            renderLabel: option => !!option ? 'Yes' : 'No',
        }),
        Property.create({
            key: 'downloadDate',
            label: 'Download Date',
            render: record => !record.isDownloaded ? '' : (
                <Chip
                    size={'small'}
                    icon={<DownloadIcon/>}
                    color={'primary'}
                    label={Formatter.dateTime(record.downloadDate)}
                />
            ),
            sortable: true,
            selectable: true,
        }),
        Property.create({
            key: 'actions',
            label: 'Action',
            align: 'center',
            render: (record, doReload) => <TimeSheetReview record={record} doReload={doReload}/>,
            sortable: false
        })
    ])
};

export default Department;