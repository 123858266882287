import React from "react";

import TableRow from "@mui/material/TableRow";
import Skeleton from "@mui/material/Skeleton";
import TableCell from "@mui/material/TableCell";

/**
 * LoadingRow component.
 *
 * @param props
 * @returns {*}
 * @constructor
 *
 * @description Displayed while a particular result / row is loading.
 */
const LoadingRow = (props) => {
    const {
        model,      // {Model} The target model to reference.
    } = props;

    const layout = model.getLayoutProperties();
    const actions = model.hasActions();
    const indicators = [];

    for (let i = 0; i < layout.length; i++) {
        const property = layout[i];
        const loader = property.getLoader();

        if (loader) {
            indicators.push(<TableCell key={i} children={loader}/>);
            continue;
        }

        const offset = Math.floor(Math.random() * 30);

        indicators.push(
            <TableCell key={`loading-cell-${model.key}-${i}`}>
                <Skeleton
                    width={`${100 - offset}%`}
                    variant={'text'}
                />
            </TableCell>
        )
    }

    if (actions) {
        indicators.push(
            <TableCell key={`loading-cell-${model.key}-${layout.length}`}/>
        );
    }

    return [
        <TableRow
            {...props}
            key={`loading-row-${model.key}`}
            children={indicators}
            className={'loading__row'}
        />
    ];
};

export default LoadingRow;