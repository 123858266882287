import React, {useState} from "react";

import API from "../Global/API";
import {useAuth} from "../Global/Auth";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

/**
 * NoteForm component.
 *
 * @returns {*}
 * @constructor
 */
const NoteForm = (props) => {
    const {
        open,               // {Boolean} Indicates if the modal is open.
        record,             // {Object} The record in which the note context belongs to.
        onSave,             // {Function} An onSave callback, triggered whenever a note is saved.
        onClose,            // {Function} An onClose callback, triggered whenever the modal is closed.
        modelId,            // {Integer} The context object's ID.
        existing,           // {Object} An existing note to edit.
        modelType,          // {String} The model type key to reference.
    } = props;

    const {user} = useAuth();
    const [note, setNote] = useState(existing ? existing.note : '');
    const [isLoading, setLoading] = useState(false);

    /**
     * Indicates if we're editing the note.
     */
    const isEditing = existing && existing.id;


    /**
     * Process the note save.
     *
     * @returns {Promise<void>}
     */
    const doSave = async () => {
        setLoading(true);

        const response = isEditing ?
            await API.put(`notes/${existing.id}`, {
                note
            }) :
            await API.post('notes', {
                note,
                userId: user.id,
                modelId: modelId || record.id,
                modelType,
            });

        setNote('');
        setLoading(false);

        if (onSave) {
            onSave(response);
        }

        if (onClose) {
            onClose();
        }
    };


    /**
     * Handles the message update event.
     *
     * @param event
     */
    const handleChange = (event) => {
        setNote(event.target.value);
    };

    return (
        <Dialog
            open={open}
            scroll={'body'}
            onClose={onClose}
            maxWidth={'sm'}
            fullWidth
        >
            <DialogContent>
                <Box className={'columns__1'}>
                    <InputLabel
                        children={isEditing ? 'Edit Note' : 'Add a Note'}
                        className={'form__heading form__heading--standalone'}
                    />
                    <TextField
                        rows={4}
                        value={note}
                        label={'Enter your note...'}
                        disabled={isLoading}
                        onChange={handleChange}
                        multiline
                    />
                </Box>
            </DialogContent>
            <DialogActions>
                <Button disabled={isLoading} onClick={doSave}>Save</Button>
                <Button disabled={isLoading} onClick={onClose} color={'error'}>Close</Button>
            </DialogActions>
        </Dialog>
    );
};

export default NoteForm;