import React from "react";

import Breadcrumbs from "../Breadcrumbs";
import ErrorBoundary from "../ErrorBoundary";

import Box from "@mui/material/Box";
import * as Sentry from "@sentry/react";

/**
 * PageBody component.
 *
 * @returns {JSX.Element}
 * @constructor
 */
const PageBody = (props) => {
    const {
        icon,               // {Component} An optional icon component to include within the default header.
        header,             // {Component} The header slot, used to override the default behavior.
        heading,            // {String} A standard page title, placed within an H2 component if provided.
        controls,           // {Component} An optional controls slot for the page.
        children,           // {Component} The inner page content.
        hideHeader,         // {Boolean} Hides the default page header container.
    } = props;

    return (
        <>
            {!hideHeader && (
                <Box className={'d-flex__justify page__header'}>
                    {heading && (
                        <Box className={'d-flex__start'}>
                            {icon && (
                                <Box className={'mr__2 d-flex__start'}>
                                    {icon}
                                </Box>
                            )}

                            <h2>{heading}</h2>
                        </Box>
                    )}
                    {header}

                    <Box className={'d-flex__center'}>
                        <Breadcrumbs/>

                        {controls}
                    </Box>
                </Box>
            )}

            <Sentry.ErrorBoundary fallback={<ErrorBoundary/>}>
                {children}
            </Sentry.ErrorBoundary>
        </>
    );
};

export default PageBody;