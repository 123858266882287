import React, {useEffect, useMemo, useState} from "react";

import API from "../../Global/API";
import SFX from "../../Global/SFX";
import User from "../../Models/User";
import Page from "../../Components/Page";
import Logger from "../../Global/Logger";
import History from "../../Models/History";
import UserNote from "../../Models/UserNote";
import TabPanel from "../../Components/TabPanel";
import {useAuth} from "../../Global/Auth";
import DataTable from "../../Components/Layouts/Index/DataTable";
import FileAvatar from "../../Components/Input/FileAvatar";
import InputPhone from "../../Components/Input/InputPhone";
import TabHeading from "../../Components/Layouts/Profile/TabHeading";
import InputSelect from "../../Components/Input/InputSelect";
import SectionHeading from "../../Components/Typography/SectionHeading";

import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Alert from "@mui/material/Alert";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Snackbar from "@mui/material/Snackbar";
import Checkbox from "@mui/material/Checkbox";
import SaveIcon from '@mui/icons-material/Save';
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import FormHelperText from "@mui/material/FormHelperText";
import FormControlLabel from "@mui/material/FormControlLabel";
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';

/**
 * Profile component.
 *
 * @returns {*}
 * @constructor
 */
const Profile = (props) => {
    const {
        onSave,         // {Function} An onSave callback, triggered whenever the form is saved.,
    } = props;

    /**
     * Various authentication utilities / helpers.
     */
    const {user, onAuthUpdate, hasPermissionTo} = useAuth();


    /**
     * Various state variables.
     */
    const [tab, setTab] = useState('Details');
    const [fields, setFields] = useState(User.getInstance(user));
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);


    /**
     * Various settings related to the user.
     */
    const [settings, setSettings] = useState({
        'US_NOTIFICATION_SFX': {
            key: 'US_NOTIFICATION_SFX',
            value: 'powerUp'
        },
        'US_CHAT_DATE_FORMAT': {
            key: 'US_CHAT_DATE_FORMAT',
            value: 'Relative'
        }
    });


    /**
     * Load all ancillary data on mount.
     */
    useEffect(() => {
        getSettings();
    }, []);


    /**
     * Loads all applicable settings for the user.
     *
     * @returns {Promise<void>}
     */
    const getSettings = async () => {
        const response = await API.get('settings', {
            $top: 1000,
            $filter: `modelType eq {User} and modelId in {${user.id}} and key eq {"US_}`,
            $orderby: 'id desc'
        });

        const formatted = {};

        response.map(setting => {
            if (!formatted.hasOwnProperty(setting.key)) {
                formatted[setting.key] = setting
            }
        });

        setSettings({
            ...settings,
            ...formatted
        });
    };


    /**
     * Performs the save via the API.
     */
    const doSave = async () => {
        setLoading(true);

        const updates = {...settings};
        const response = fields.id ?
            await API.put(`user/${fields.id}`, fields) :
            await API.post('users', fields);

        // Save each of the configured settings.
        await Promise.all(
            Object.keys(settings).map(async key => {
                const setting = updates[key];
                Logger.debug(`[Profile] Updating settings value "${key}"`, setting)

                updates[key] = setting.id ?
                    await API.put(`settings/${setting.id}`, setting) :
                    await API.post('settings', {
                        ...setting,
                        modelId: user.id,
                        modelType: 'User'
                    });
            })
        );

        const mapped = {};
        Object.keys(updates).map(key => mapped[key] = updates[key].value)
        Logger.debug('[Profile] Updated settings', updates);

        setSuccess(true);
        setLoading(false);
        setSettings({...updates});

        // Trigger an update to the authenticated user object.
        onAuthUpdate({
            ...response,
            settings: {
                ...user.settings,
                ...mapped
            }
        });

        if (onSave) {
            onSave(response);
        }
    };


    /**
     * Updates a particular form value.
     *
     * @param key
     * @param value
     */
    const setValue = (key, value) => {
        setFields({
            ...fields,
            [key]: value
        });
    };


    /**
     * The "Recent Notes" content on the form.
     *
     * @type {Component}
     */
    const recentNotes = useMemo(() => (
        <Box sx={{height: 'calc(100vh - 400px)'}}>
            <DataTable
                model={UserNote}
                query={UserNote.getQuery()}
                actions={false}
                doReload={null}
                hideHeader
            />
        </Box>
    ), []);


    /**
     * The "Recent Changes" content on the form.
     *
     * @type {Component}
     */
    const recentChanges = useMemo(() => (
        <Box sx={{height: 'calc(100vh - 400px)'}}
            className={'v-align__top'}>
            <DataTable
                model={History}
                query={History.getQuery()}
                actions={false}
                topAlign
                doReload={null}
                hideHeader
            />
        </Box>
    ), []);


    /**
     * Handles the user tab selection.
     *
     * @param event
     * @param value
     */
    const handleTabChange = (event, value) => {
        setTab(value);
    };


    /**
     * Assigns a specific settings key.
     *
     * @param key
     * @param value
     */
    const setSettingValue = (key, value) => {
        if (!settings.hasOwnProperty(key)) {
            settings[key] = {
                key,
                value
            }
        } else {
            settings[key].value = value;
        }

        setSettings({
            ...settings,
        });
    }


    /**
     * Returns an individual settings key value.
     *
     * @param key
     * @param defaultValue
     * @returns {string}
     */
    const setting = (key, defaultValue = '') => {
        if (settings.hasOwnProperty(key)) {
            return settings[key].value || '';
        }

        return defaultValue;
    }


    /**
     * Plays the sound effect.
     */
    const doSoundPreview = () => {
        const selectedAudio = setting('US_NOTIFICATION_SFX', '');

        if (!selectedAudio) {
            return;
        }

        const soundEffect = SFX[selectedAudio];

        if (!soundEffect || !soundEffect.audio) {
            return;
        }

        soundEffect.audio.play();
    };

    return (
        <Page
            icon={<ManageAccountsIcon/>}
            heading={'My Profile'}
            children={
                <Paper sx={{
                    position: 'relative',
                }}>
                    <TabHeading
                        tab={tab}
                        actions={
                            !tab ? (
                                <IconButton
                                    onClick={doSave}
                                    disabled={isLoading}
                                    children={<SaveIcon/>}
                                    className={'ml__1'}
                                />
                            ) : null
                        }
                        onChange={handleTabChange}
                    >
                        <Tab label={'Details'} value={'Details'}/>

                        {hasPermissionTo('VIEW_SETTINGS') && (
                            <>
                                <Tab label={'Notes'} value={'Notes'}/>
                                <Tab label={'History'} value={'History'}/>
                            </>
                        )}
                    </TabHeading>
                    <TabPanel
                        value={tab}
                        index={'Details'}
                        fullWidth
                    >
                        <Box className={'columns__1 p__3'}>
                            <SectionHeading
                                title={'Contact Information'}
                            />

                            <Box className={'d-flex__justify'}>
                                <FileAvatar
                                    width={128}
                                    model={User}
                                    field={'image'}
                                    height={128}
                                    record={fields}
                                    variant={'rounded'}
                                    editable
                                    onChange={path => setValue('image', path)}
                                    helperText={'Profile Image'}
                                />

                                <Box className={'flex__grow'}
                                    sx={{marginLeft: '1.5em'}}>
                                    <Box className={'columns__1'}>
                                        <Box className={'columns__2'}>
                                            <TextField
                                                label="First Name"
                                                value={fields.firstName}
                                                required
                                                disabled={isLoading}
                                                onChange={event => setValue('firstName', event.target.value)}
                                            />
                                            <TextField
                                                label="Last Name"
                                                value={fields.lastName}
                                                required
                                                disabled={isLoading}
                                                onChange={event => setValue('lastName', event.target.value)}
                                            />
                                        </Box>

                                        <Box className={'columns__3'}>
                                            <TextField
                                                label="Email Address"
                                                value={fields.emailAddress}
                                                required
                                                disabled={true}
                                                onChange={event => setValue('emailAddress', event.target.value)}
                                            />
                                            <InputPhone
                                                label="Phone Number"
                                                value={fields.phoneNumber}
                                                disabled={isLoading}
                                                onChange={event => setValue('phoneNumber', event.target.value)}
                                            />
                                            <TextField
                                                label="Job Title"
                                                value={fields.jobTitle}
                                                disabled={isLoading}
                                                onChange={event => setValue('jobTitle', event.target.value)}
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Box>

                            <SectionHeading
                                title={'Notification Sounds'}
                            />

                            <Box className={'d-flex__start'}>
                                <FormControl className={'mr__4'}>
                                    <FormControlLabel
                                        label={'Enable notification sound effects?'}
                                        control={
                                            <Checkbox
                                                checked={!!setting('US_NOTIFICATION_ENABLED')}
                                                onChange={event => setSettingValue('US_NOTIFICATION_ENABLED', !!event.target.checked)}
                                            />
                                        }
                                    />
                                    <FormHelperText className={'text__small'}>If enabled, will play the selected sound
                                        effect for new messages.</FormHelperText>
                                </FormControl>

                                <FormControl sx={{minWidth: 400}} className={'mr__2'}>
                                    <InputLabel>Notification Sound Effect</InputLabel>
                                    <InputSelect
                                        label={'Notification Sound Effect'}
                                        value={setting('US_NOTIFICATION_SFX', '')}
                                        options={Object.keys(SFX).map(key => {
                                            return {
                                                ...SFX[key],
                                                value: key
                                            };
                                        })}
                                        required
                                        disabled={isLoading || !setting('US_NOTIFICATION_ENABLED')}
                                        onChange={event => setSettingValue('US_NOTIFICATION_SFX', event.target.value)}
                                        fullWidth
                                    />
                                </FormControl>
                                <IconButton
                                    onClick={doSoundPreview}
                                    disabled={!setting('US_NOTIFICATION_SFX', '') || !setting('US_NOTIFICATION_ENABLED')}
                                    children={<VolumeUpIcon/>}
                                />
                            </Box>

                            <SectionHeading
                                title={'Communication Settings'}
                            />

                            <Alert severity={'info'}>
                                If no message is provided, the default response is: <i>"Thank you for your message! This is an automated reply. Our office is currently closed, but I'll get back to you as soon as I can!"</i>
                            </Alert>

                            <TextField
                                rows={3}
                                label={'Out-of-Office Response'}
                                value={setting('US_OUT_OF_OFFICE_MESSAGE', '')}
                                disabled={isLoading}
                                onChange={event => setSettingValue('US_OUT_OF_OFFICE_MESSAGE', event.target.value)}
                                multiline
                                fullWidth
                            />

                            <Box className={'d-flex__start'}>
                                <FormControl sx={{minWidth: 400}} className={'mr__2'}>
                                    <InputLabel>Chat Date Format</InputLabel>
                                    <InputSelect
                                        label={'Chat Date Format'}
                                        value={setting('US_CHAT_DATE_FORMAT', '')}
                                        options={[
                                            {
                                                label: 'Relative',
                                                value: 'Relative'
                                            },{
                                                label: 'Absolute',
                                                value: 'Absolute'
                                            }
                                        ]}
                                        required
                                        disabled={isLoading}
                                        onChange={event => setSettingValue('US_CHAT_DATE_FORMAT', event.target.value)}
                                        fullWidth
                                        inputHelpe
                                    />

                                    {setting('US_CHAT_DATE_FORMAT', '') === 'Absolute' ? (
                                        <FormHelperText className={'text__small'}>ie. "12/25/2023 5:32pm"</FormHelperText>
                                    ) : (
                                        <FormHelperText className={'text__small'}>ie. "3 days, 2 hours ago"</FormHelperText>
                                    )}
                                </FormControl>
                            </Box>

                        </Box>
                        <Divider/>
                        <Box
                            align={'right'}
                            className={'p__3'}
                        >
                            <Button
                                onClick={doSave}
                                variant={'outlined'}
                                children={'Save'}
                                disabled={isLoading}
                            />
                        </Box>
                    </TabPanel>

                    <TabPanel
                        value={tab}
                        index={'Notes'}
                    >
                        <Box className={'columns__1'}>
                            <SectionHeading title={'Recent Notes'}/>

                            {recentNotes}
                        </Box>
                    </TabPanel>

                    <TabPanel
                        value={tab}
                        index={'History'}
                    >
                        <Box className={'columns__1'}>
                            <SectionHeading title={'Recent Changes'}/>

                            {recentChanges}
                        </Box>
                    </TabPanel>

                    {isSuccess && (
                        <Snackbar open={isSuccess}
                            autoHideDuration={6000}
                            onClose={() => setSuccess(false)}>
                            <Alert
                                sx={{width: '100%', textAlign: 'left'}}
                                onClose={() => setSuccess(false)}
                                severity={'success'}
                            >
                                Your changes have been saved successfully!
                            </Alert>
                        </Snackbar>
                    )}
                </Paper>
            }
        />
    );
};

export default Profile;