import App from "./App";
import State from "./State";
import Logger from "./Logger";
import Settings from "./Settings";
import {_navigate} from "./History";

/**
 * API object.
 *
 * @type {Object}
 */
const API = {
    /**
     * Various cached requests under the current page load.
     */
    cache: {},


    /**
     * Indicates whether the API is retrying an authentication attempt.
     */
    isRetrying: false,


    /**
     * Indicates whether the post-authentication retry was successful.
     */
    isRetrySuccessful: true,


    /**
     * Sleeps for a certain duration.
     *
     * @param ms
     * @returns {Promise<any>}
     */
    sleep: (ms) => {
        return new Promise(resolve => setTimeout(resolve, ms));
    },


    /**
     * Executes a general request.
     *
     * @param route
     * @param parameters
     * @param method
     * @param retry
     */
    async request(route, parameters, method, retry) {
        const payload = {
            ...(parameters || {})
        };

        let url = new URL(`${Settings.apiPath}/${route}`);
        let bCacheable = false;
        let request = {
            method,
            headers: {
                "X-Requested-By": App.isCordova() ? 'mobile' : 'web',
                "Authorization": `Bearer ${State.get('auth-token')}`,
                "Content-Type": "application/json",
            }
        };

        // Handle any additional / private functionality.
        if (parameters) {
            if (parameters['@signal']) {
                request['signal'] = parameters['@signal'];
                delete payload['@signal'];
            }

            if (parameters['@cache']) {
                bCacheable = true;
                delete payload['@cache'];
            }
        }

        // Build out our cache key.
        let cacheKey = url.toString();

        switch (method) {
            case 'GET':
                Object.keys(payload).map((key) => url.searchParams.set(key, payload[key]));
                cacheKey = url.toString();

                // Determine if we have a cached response.
                if (API.cache[cacheKey] && bCacheable) {
                    Logger.debug('[API] Returning cached response for:', cacheKey, API.cache[cacheKey]);
                    return API.cache[cacheKey];
                }
                break;

            default:
                request['body'] = JSON.stringify(payload);
                break;
        }

        const response = await fetch(decodeURIComponent(url.toString()), request);

        // Handle specific status codes.
        if (!response.ok) {
            bCacheable = false;

            switch (response.status) {
                case 401:
                    const userRecord = App.getUser();
                    const refreshToken = State.get('auth-refresh');

                    // Attempt to re-authenticate against the refresh token if we have one.
                    if (!API.isRetrying && !retry) {
                        API.isRetrying = true;
                        Logger.debug('[API] Received unauthorized response, attempting to re-authenticate using the refresh token...');

                        const authentication = await API.post('auth', {
                            client_id: userRecord.id,
                            grant_type: 'refresh_token',
                            client_secret: refreshToken
                        });

                        Logger.debug('[API] Received authentication response:', authentication);

                        // If we were successful, update the authorization credentials and retry the API call.
                        if (authentication && authentication.status !== 'error') {
                            State.set('auth-user', JSON.stringify(authentication.record));
                            State.set('auth-token', authentication.access_token);
                            State.set('auth-scope', authentication.record['@model']);
                            State.set('auth-refresh', authentication.refresh_token);
                            API.isRetrySuccessful = true;
                        } else {
                            State.set('auth-user', '');
                            State.set('auth-scope', '');
                            State.set('auth-token', '');
                            State.set('auth-refresh', '');
                            API.isRetrySuccessful = false;
                        }

                        API.isRetrying = false;
                        return await API.request(route, parameters, method, true);
                    } else {
                        if (retry) {
                            return [];
                        }

                        while (API.isRetrying) {
                            await API.sleep(500);
                        }

                        if (!API.isRetrySuccessful) {
                            Logger.debug('[API] Unable to re-authenticate, redirecting to login...');
                            _navigate('/login');
                            return [];
                        } else {
                            return await API.request(route, parameters, method, true);
                        }
                    }
            }
        }

        const json = await response.json();

        // Short-term solution for forbidden responses.
        if (json.error === "This user is not authorized to perform this action.") {
            return [];
        }

        // Append to cache if able to do so.
        if (bCacheable && json) {
            Logger.debug('[API] Caching response for:', cacheKey, json);
            API.cache[cacheKey] = json;
        }

        return json;
    },


    /**
     * Executes a GET request.
     *
     * @param route
     * @param parameters
     * @returns {Promise<*>}
     */
    async get(route, parameters = {}) {
        return await API.request(route, parameters, 'GET');
    },


    /**
     * Executes a POST request.
     *
     * @param route
     * @param parameters
     * @returns {Promise<*>}
     */
    async post(route, parameters) {
        return await API.request(route, parameters, 'POST');
    },


    /**
     * Executes a PUT request.
     *
     * @param route
     * @param parameters
     * @returns {Promise<*>}
     */
    async put(route, parameters) {
        return await API.request(route, parameters, 'PUT');
    },


    /**
     * Executes a DELETE request.
     *
     * @param route
     * @param parameters
     * @returns {Promise<*>}
     */
    async delete(route, parameters) {
        return await API.request(route, parameters, 'DELETE');
    },


    /**
     * Performs a generic file upload.
     *
     * @param parameters
     * @param onSuccess
     * @param onError
     * @param onProgress
     */
    doFileUpload(parameters, onSuccess, onError, onProgress) {
        const {
            name,           // {String} An optional name override.
            file,           // {File} The file instance from an input, new File(), etc.
            path,           // {String} The upload path for this file (ie. "users/1234").
            types           // {Array} A series of extensions to validate against (ie. ["bmp", "jpg", "jpeg", "png"]).
        } = parameters;

        // Handles the success callback.
        const handleSuccess = (path, size) => {
            if (onSuccess) {
                onSuccess(path, size);
            }
        };

        // Handles the error callback.
        const handleError = (error) => {
            if (onError) {
                onError(error);
            }
        };

        // Handles the progress callback.
        const handleProgress = (progress) => {
            if (onProgress) {
                onProgress(progress);
            }
        };

        if (!file) {
            return handleError('No file was provided.');
        }

        // Build out the request payload.
        const payload = new FormData();
        payload.append('file', file);
        payload.append('name', name || '');
        payload.append('path', path || '');
        payload.append('types', JSON.stringify(types || []));

        const xhr = new XMLHttpRequest();
        xhr.onreadystatechange = function () {
            if (4 === this.readyState) {
                const response = JSON.parse(xhr.response);

                if (!response.status || !response.message) {
                    return handleError('An unexpected error was encountered during upload.');
                }

                switch (response.status) {
                    case 'error':
                        return handleError(response.message);

                    case 'success':
                    default:
                        return response.filename ?
                            handleSuccess(response.filename, response.size) :
                            handleError('This file could not be processed.');
                }
            }
        };
        if (onProgress && xhr.upload) {
            xhr.upload.onprogress = function (e) {
                const percentage = Math.ceil((e.loaded / e.total) * 100);
                handleProgress(percentage);
            };
        }
        xhr.open('POST', `${Settings.apiPath}/upload`, true);
        xhr.setRequestHeader('Authorization', `Bearer ${State.get('auth-token')}`);
        xhr.send(payload);
    },


    /**
     * Returns the appropriate file path for retrieving backend files.
     *
     * @param path
     * @param bHideAuth
     * @returns {string}
     */
    getFilePath(path, bHideAuth = false) {
        if (!path) {
            return '';
        }

        if (bHideAuth) {
            return `${Settings.apiPath}/file?path=${path}`;
        }

        return `${Settings.apiPath}/file?path=${path}&x-access-token=${State.get('auth-token')}`;
    }
};

export default API;