import React from "react";

import {useAuth} from "../../Global/Auth";
import CallLogFeed from "../../Components/Widgets/CallLogFeed";
import UserTaskFeed from "../../Components/Widgets/UserTaskFeed";
import NotificationFeed from "../../Components/Widgets/NotificationFeed";

import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

/**
 * Dashboard component.
 *
 * @returns {*}
 * @constructor
 */
const Dashboard = () => {
    const {hasPermissionTo} = useAuth();

    return (
        <Box className={'columns__3 admin__dashboard'}>
            {hasPermissionTo('VIEW_COMMUNICATIONS') && (
                <Box>
                    <CallLogFeed
                        title={<h3>Recent Calls</h3>}
                        className={'dashboard__call-log-feed'}
                    />
                </Box>
            )}

            {hasPermissionTo('VIEW_EMPLOYEES') && (
                <Paper>
                    <NotificationFeed
                        title={<h3>Notifications</h3>}
                    />
                </Paper>
            )}

            <Box>
                <UserTaskFeed
                    title={<h3>My Tasks</h3>}
                    className={'dashboard__user-task-feed'}
                />
            </Box>
        </Box>
    );
};

export default Dashboard;