import React from "react";

import Settings from "../../../Global/Settings";
import Formatter from "../../../Global/Formatter";
import DetailWrapper from "../../../Components/Layouts/Profile/DetailWrapper";

/**
 * ClientDetails component.
 *
 * @param props
 * @returns {*}
 * @constructor
 */
const ClientDetails = (props) => {
    const {
        record      // {Object} The record in which to reference.
    } = props;

    // Combine the address fields for display purposes.
    const formattedAddress = Formatter.address(
        record.addressLine1 || '',
        record.addressLine2 || '',
        '',
        record.city || '',
        record.state && record.state.abbreviation ? record.state.abbreviation : '',
        record.postalCode || ''
    );

    return (
        <div className={'columns__1'}>
            <div className={'columns__3'}>
                <DetailWrapper label={'Name'} value={record.name}/>
                <DetailWrapper label={'Type'} value={record.type}/>
                <DetailWrapper label={'Phone'} value={<a href={`tel:${record.phoneNumber}`}>{Formatter.phoneString(record.phoneNumber)}</a>}/>
                <DetailWrapper label={'Email Address'} value={<a href={`mailto:${record.emailAddress}`}>{record.emailAddress}</a>}/>
                <DetailWrapper
                    label={'Address'}
                    value={formattedAddress}
                />
            </div>

            {/*Display the client's address on a map embed.*/}
            {formattedAddress ? <iframe
                src={`https://www.google.com/maps/embed/v1/place?key=${Settings.googleApiKey}&q=${formattedAddress}`}
                style={{border: 0}}
                width={'100%'}
                height={'250'}
                loading={'lazy'}
                referrerPolicy={'no-referrer-when-downgrade'}
                allowFullScreen
            /> : null}
        </div>
    );
};

export default ClientDetails;