import React, {useState} from "react";

import TabPanel from "../../Components/TabPanel";
import Generator from "../../Global/Generator";
import NotesBlock from "../../Components/Layouts/Profile/NotesBlock";
import TabHeading from "../../Components/Layouts/Profile/TabHeading";
import RecruitModel from "../../Models/RecruitNew";
import DocumentList from "../../Components/Layouts/Profile/DocumentList";
import ProfileBadge from "../../Components/Layouts/Profile/ProfileBadge";
import EmployeeActions from "../../Actions/EmployeeActions";
import EmployeeDetails from "./Employee/EmployeeDetails";
import TwoColumnProfile from "../../Components/Layouts/TwoColumnProfile";

import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";

/**
 * Recruit component.
 *
 * @returns {*}
 * @constructor
 */
const Recruit = () => {
    const [tab, setTab] = useState(0);
    const [record] = useState(Generator.getFakeRecord(RecruitModel));

    /**
     * Fired whenever a user clicks into a tab.
     *
     * @param event
     * @param tab
     */
    const handleTabChange = (event, tab) => {
        setTab(tab);
    };

    return (
        <TwoColumnProfile
            header={
                <ProfileBadge
                    heading={`${record.first_name} ${record.last_name}`}
                    subheading={`${record.specialty} • Since 03/01/2023`}
                    decorator={
                        <Box sx={{marginLeft: '0.5em'}}>
                            {record.active ?
                                <Chip label="Active" size={'small'} color={'success'}/> :
                                <Chip label="Inactive" size={'small'}/>
                            }
                        </Box>
                    }
                />
            }
            leftColumn={
                <Paper>
                    <TabHeading tab={tab} onChange={handleTabChange} actions={<EmployeeActions record={record}/>}>
                        <Tab label="Details"/>
                        <Tab label="Documents"/>
                        <Tab label="Availability"/>
                    </TabHeading>
                    <TabPanel value={tab} index={0}>
                        <EmployeeDetails record={record}/>
                    </TabPanel>
                    <TabPanel value={tab} index={1} fullWidth>
                        <DocumentList/>
                    </TabPanel>
                    <TabPanel value={tab} index={2}/>
                </Paper>
            }
            rightColumn={
                <NotesBlock record={record}/>
            }
        />
    );
};

export default Recruit;