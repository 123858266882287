import React from "react";

import Page from "../Page";

import Box from "@mui/material/Box";

/**
 * TwoColumnProfile component.
 *
 * @constructor
 */
const TwoColumnProfile = (props) => {
    const {header, leftColumn, rightColumn} = props;

    return (
        <Page header={header}>
            <div className={'d-flex'}>
                <Box sx={{flexGrow: 1, position: 'relative'}} children={leftColumn}/>
                <Box sx={{marginLeft: '1em', width: '450px'}} children={rightColumn}/>
            </div>
        </Page>
    );
};

export default TwoColumnProfile;