import React, {useEffect, useState} from "react";

import API from "../../../Global/API";
import Formatter from "../../../Global/Formatter";

import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableContainer from "@mui/material/TableContainer";

/**
 * TrainingOverview component.
 *
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
const TrainingOverview = (props) => {
    const {
        employee,           // {Object} The employee record for context.
    } = props;

    const [courses, setCourses] = useState([]);
    const [sections, setSections] = useState([]);
    const [completions, setCompletions] = useState([]);
    const [sectionCompletions, setSectionCompletions] = useState([]);

    /**
     * The employee's specialty.
     */
    const specialty = {...employee.specialty};


    /**
     * Load all required data on mount.
     */
    useEffect(() => {
        getData();
    }, []);


    /**
     * Loads all required data for the view.
     *
     * @returns {Promise<void>}
     */
    const getData = async () => {
        await Promise.all([
            getCourses(),
            getSections(),
            getCompletions(),
            getSectionCompletions()
        ]);
    };


    /**
     * Loads all courses for the employee's specialty.
     *
     * @returns {Promise<void>}
     */
    const getCourses = async () => {
        setCourses(
            await API.get('courses', {
                $top: 100,
                $filter: 'isDeleted eq {0} and isPublished eq {1}'
            })
        );
    };


    /**
     * Loads all courses for the employee's specialty.
     *
     * @returns {Promise<void>}
     */
    const getSections = async () => {
        setSections(
            await API.get('course-sections', {
                $top: 1000,
                $filter: 'isTest eq {1}'
            })
        );
    };


    /**
     * Loads all course completions for the employee.
     *
     * @returns {Promise<void>}
     */
    const getCompletions = async () => {
        setCompletions(
            await API.get('course-completions', {
                $top: 100,
                $filter: `employeeId in {${employee.id}}`
            })
        );
    };


    /**
     * Loads all test scores and sections for the course.
     *
     * @returns {Promise<void>}
     */
    const getSectionCompletions = async () => {
        setSectionCompletions(
            await API.get('course-section-completions', {
                $top: 100,
                $filter: `employeeId in {${employee.id}}`
            })
        );
    };

    return (
        <Box>
            <h3 className={'mt__0'}>{employee.displayName} - {specialty.name}</h3>

            <p>Your transcript results are outlined ahead.</p>

            <TableContainer className={'table table--striped'}>
                <Table size={'small'}>
                    <colgroup>
                        <col width={'20%'}/>
                        <col width={'20%'}/>
                        <col width={'20%'}/>
                        <col width={'20%'}/>
                        <col width={'20%'}/>
                    </colgroup>
                    <TableHead>
                        <TableRow>
                            <TableCell>Course ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Date Completed</TableCell>
                            <TableCell>Grade</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>

                    {courses.map((course, index) => {
                        const {
                            id,
                            name,
                            abbreviation
                        } = course;

                        // The course completion result.
                        const completion = {
                            ...completions.find(completion => completion.courseId === id)
                        };

                        // The test section for the course.
                        const testSection = {
                            ...sections.find(courseSection => courseSection.courseId === id && !!courseSection.testId)
                        };

                        // The results for the current course.
                        const sectionCompletion = {
                            ...sectionCompletions.find(sectionCompletion => sectionCompletion.courseSectionId === testSection.id)
                        };

                        // The formatted name of the course.
                        const formattedName = `${name}${abbreviation ? ` (${abbreviation})` : ''}`;

                        // Indicates whether the course is completed.
                        const isCompleted = !!completion.id && sectionCompletion.testPercentage;

                        // For legacy dates, we'll randomize the date somewhat as we won't have the exact completion time.
                        const isLegacy = !completion.createdDate;
                        const handleLegacyDate = (dateDt) => {
                            if(!isLegacy){
                                return dateDt;
                            }

                            return dateDt.add(index * 10 + Math.floor(Math.random() * 10), 'minute');
                        };

                        return (
                            <TableRow key={index}>
                                <TableCell>RS-{String(id).padStart(5, '0')}</TableCell>
                                <TableCell>{formattedName}</TableCell>
                                <TableCell>{Formatter.dateTime(completion.createdDate || employee.trainingDate, true, handleLegacyDate)}</TableCell>
                                {isCompleted ? (
                                    <TableCell>{(sectionCompletion.testPercentage || 1) * 100}%</TableCell>
                                ) : (
                                    <TableCell/>
                                )}
                                <TableCell>{isCompleted ? 'Completed' : '-'}</TableCell>
                            </TableRow>
                        );
                    })}
                </Table>
            </TableContainer>
        </Box>
    );
};

export default TrainingOverview;