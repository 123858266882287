import React, {useState} from "react";

import API from "../../Global/API";
import File from "../../Global/File";
import TimeSheet from "../../Models/TimeSheet";
import TableLayout from "../../Components/Layouts/TableLayout";

import Box from "@mui/material/Box";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import Snackbar from "@mui/material/Snackbar";
import InputLabel from "@mui/material/InputLabel";
import DownloadIcon from '@mui/icons-material/Download';
import {useNavigate} from "react-router";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import LinearProgress from "@mui/material/LinearProgress";
import AssessmentIcon from "@mui/icons-material/Assessment";
import PendingActionsIcon from '@mui/icons-material/PendingActions';

/**
 * TimeSheets component.
 *
 * @returns {*}
 * @constructor
 */
const TimeSheets = () => {
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [isLoading, setLoading] = useState(false);
    const [isSuccess, setSuccess] = useState(false);
    const [isDownloading, setDownloading] = useState(false);

    /**
     * Downloads the batch time sheet results.
     *
     * @returns {Promise<void>}
     */
    const doDownload = async () => {
        setLoading(true);

        // Determine the filter criteria.
        let promises = [];
        let perPageLimit = 250;
        let filterString = TimeSheet.getFilterString(TimeSheet.getQuery().$filter);

        // Load all the clients page by page until we've reached the end.
        const count = await API.get('time-sheets/count', {
            $top: perPageLimit,
            $filter: filterString,
        });

        for (let page = 0; page < count.pages; page++) {
            promises.push(
                API.get('time-sheets', {
                    $top: perPageLimit,
                    $skip: page * perPageLimit,
                    $filter: filterString,
                    $select: 'id',
                })
            );
        }

        // Gather the results together in an archive.
        const results = await Promise.all(promises);
        const downloadUrl = await API.post('download/time-sheets', {
            records: results.flat()
        });

        setLoading(false);

        if(downloadUrl.status === 'error'){
            setError(downloadUrl.message);
            handleDownloadClose();
            return;
        }

        // Initialize the download from the link in the response.
        File.doDownload(
            `${API.getFilePath(downloadUrl.message)}&noCache=1`,
            'time-sheets'
        );

        setSuccess(true);
        handleDownloadClose();
    };


    /**
     * Handle the open of the download dialog.
     */
    const handleDownloadOpen = () => {
        setDownloading(true);
    };


    /**
     * Handle the close of the download dialog.
     */
    const handleDownloadClose = () => {
        setDownloading(false);
    };


    /**
     * Closes the status popover.
     */
    const handleStatusClose = () => {
        setError('');
        setSuccess(false);
    };

    return (
        <>
            <TableLayout
                icon={<PendingActionsIcon/>}
                model={TimeSheet}
                heading={'Time Sheets'}
                condensed
                filterSlot={
                    <Box className={'d-flex__center ml__2'}>
                        <Box className={'breadcrumbs'} sx={{padding: '0.3em'}}>
                            <a className={`crumb`} onClick={() => navigate('/reports?tab=Time Sheets')}>
                                <AssessmentIcon fontSize={'small'} className={'mr__1'}/>
                                View Report
                            </a>
                        </Box>
                    </Box>
                }
                controlSlot={
                    <Button
                        variant={'link'}
                        onClick={handleDownloadOpen}
                        children={<DownloadIcon/>}
                        className={'layout__control'}
                    />
                }
            />

            <Dialog
                open={isDownloading}
                scroll={'body'}
                onClose={!isLoading ? handleDownloadClose : null}
                maxWidth={'sm'}
                fullWidth
            >
                {isLoading && (
                    <LinearProgress/>
                )}
                <DialogContent>
                    <Box className={'columns__1'}>
                        <InputLabel
                            children={'Download Time Sheets'}
                            className={'form__heading form__heading--standalone'}
                        />
                        {isLoading ? (
                            <Alert severity={'warning'} icon={<DownloadIcon fontSize={'inherit'}/>}>
                                Your download is being processed, please be patient as this may take a few minutes...
                            </Alert>
                        ) : (
                            <Alert severity={'info'}>
                                This utility is used to batch download your time sheet results. Before downloading, first
                                verify that your filter criteria is correct as you see it in the table behind this
                                dialog.
                            </Alert>
                        )}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button
                        onClick={doDownload}
                        children={'Download'}
                        disabled={isLoading}
                    />
                    <Button
                        color={'error'}
                        onClick={handleDownloadClose}
                        children={'Close'}
                        disabled={isLoading}
                    />
                </DialogActions>
            </Dialog>

            {!!error && (
                <Snackbar open={!!error} autoHideDuration={6000} onClose={handleStatusClose}>
                    <Alert onClose={handleStatusClose} severity="error" sx={{width: '100%', textAlign: 'left'}}>
                        {error}
                    </Alert>
                </Snackbar>
            )}

            {isSuccess && (
                <Snackbar open={isSuccess} autoHideDuration={6000} onClose={handleStatusClose}>
                    <Alert onClose={handleStatusClose} severity="success" sx={{width: '100%', textAlign: 'left'}}>
                        Your file was downloaded successfully!
                    </Alert>
                </Snackbar>
            )}
        </>
    );
};

export default TimeSheets;