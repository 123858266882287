import React, {useEffect, useState} from "react";

import Logger from "../../../Global/Logger";

import Box from "@mui/material/Box";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {useMediaQuery, useTheme} from "@mui/material";

/**
 * QuickFilters component.
 *
 * @returns {*}
 * @constructor
 */
const QuickFilters = (props) => {
    const {
        model,          // {Model} The target model to compile filters for.
        onChange        // {Function} The callback function to reload data on filter selection.
    } = props;

    const [filter, setFilter] = useState(model.getDefaultFilter());
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    /**
     * Handle any additional functionality on mount.
     */
    useEffect(() => {
        Logger.debug(`[QuickFilters] Assigned default filter:`, filter);
    }, []);


    /**
     * Variables for rendering different layouts.
     *
     * @type {Theme}
     */
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));


    /**
     * Fired whenever the menu button is clicked.
     *
     * @param event
     */
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };


    /**
     * Closes the menu popover.
     */
    const handleClose = () => {
        setAnchorEl(null);
    };


    /**
     * Handle a filter selection.
     *
     * @param value
     */
    const handleFilterChange = value => {
        handleClose();
        setFilter(value);

        if(onChange){
            onChange(value);
        }
    };


    /**
     * The label for the current filter.
     *
     * @type {string}
     */
    const filterLabel = filter && filter.label ? filter.label : '(default)';

    return (
        <Box className={'breadcrumbs quick__filters'} sx={{padding: '0.3em'}}>
            <Box
                sx={{
                    paddingLeft: '0.5em',
                }}
                className={'crumb'}
            >
                <a className={'d-flex__start'} onClick={handleClick}>
                    {isMobile ? (
                        <FilterAltIcon fontSize={'small'} className={'mr__1'}/>
                    ) : (
                        <b className={'mr__2 ml__2'}>Filter:</b>
                    )}
                    {filterLabel}

                    <ArrowDropDownIcon fontSize={'small'}/>
                </a>
                <Menu
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                >
                    {model.filters.map(target => (
                        <MenuItem
                            key={target.label}
                            onClick={() => handleFilterChange(target)}
                            children={target.label}
                            selected={filter.label === target.label}
                        />
                    ))}
                </Menu>
            </Box>
        </Box>
    );
};

export default QuickFilters;