import React, {useEffect, useMemo, useRef, useState} from "react";

import API from "../../../Global/API";
import Client from "../../../Models/Client";
import Logger from "../../../Global/Logger";
import Settings from "../../../Global/Settings";
import {useAuth} from "../../../Global/Auth";
import Specialty from "../../../Models/Specialty";
import Formatter from "../../../Global/Formatter";
import ModalButton from "../../../Components/ModalButton";
import ColorSwatch from "./AvailabilityReport/ColorSwatch";
import ModelSearch from "../../../Components/Input/ModelSearch";
import InputSelect from "../../../Components/Input/InputSelect";
import EmployeeView from "../Employee/EmployeeView";
import GroupChatDialog from "../../../Components/Widgets/GroupChatDialog";
import QuickSendWidget from "../../../Components/Widgets/QuickSendWidget";
import EmployeeListItem from "../../../Components/Lists/EmployeeListItem";

import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import Button from "@mui/material/Button";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import ChatIcon from "@mui/icons-material/Chat";
import SendIcon from '@mui/icons-material/Send';
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import Accordion from "@mui/material/Accordion";
import PrintIcon from '@mui/icons-material/Print';
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LinearProgress from "@mui/material/LinearProgress";
import TableContainer from "@mui/material/TableContainer";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import FormControlLabel from "@mui/material/FormControlLabel";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';

/**
 * The color map for our palette.
 *
 * @type {Array<Object>}
 */
const colorSwatch = [
    {
        name: 'Blue',
        previewColor: '#619ED6',
        backgroundColor: 'rgba(97,158,214,0.3)',
    }, {
        name: 'Green',
        previewColor: '#6BA547',
        backgroundColor: 'rgba(107,165,71,0.3)',
    }, {
        name: 'Yellow',
        previewColor: '#F7D027',
        backgroundColor: 'rgba(247,208,39,0.3)',
    }, {
        name: 'Orange',
        previewColor: '#E48F1B',
        backgroundColor: 'rgba(228,143,27,0.3)',
    }, {
        name: 'Purple',
        previewColor: '#B77EA3',
        backgroundColor: 'rgba(183,126,163,0.3)',
    }, {
        name: 'Red',
        previewColor: '#E64345',
        backgroundColor: 'rgba(230,67,69,0.3)',
    }, {
        name: 'Light Blue',
        previewColor: '#60CEED',
        backgroundColor: 'rgba(96,206,237,0.3)',
    }, {
        name: 'Light Green',
        previewColor: '#9CF168',
        backgroundColor: 'rgba(156,241,104,0.3)',
    }, {
        name: 'Light Yellow',
        previewColor: '#F7EA4A',
        backgroundColor: 'rgba(247,234,74,0.3)',
    }, {
        name: 'Light Orange',
        previewColor: '#FBC543',
        backgroundColor: 'rgba(251,197,67,0.3)',
    }, {
        name: 'Pink',
        previewColor: '#FFC9ED',
        backgroundColor: 'rgba(255,201,237,0.3)',
    }, {
        name: 'Light Red',
        previewColor: '#E6696E',
        backgroundColor: 'rgba(230,105,110,0.3)',
    }
];


/**
 * AvailabilityReport component.
 *
 * @param props
 * @returns {Element}
 * @constructor
 */
const AvailabilityReport = (props) => {
    const {
        onChange,
        defaultSelection
    } = props;

    const selectable = true;

    /**
     * Various state properties for this layout.
     */
    const {user} = useAuth();
    const [color, setColor] = useState(null);
    const [clients, setClients] = useState([]);
    const [results, setResults] = useState([]);
    const [colorMap, setColorMap] = useState({});
    const [isLoading, setLoading] = useState(false);
    const [selection, setSelection] = useState(defaultSelection || []);
    const [specialties, setSpecialties] = useState([]);
    const [expiredOnly, setExpiredOnly] = useState(false);
    const [settingsLoaded, setSettingsLoaded] = useState(false);
    const [isSendingMessage, setSendingMessage] = useState(false);

    /**
     * The decoded color values.
     *
     * @type {{}}
     */
    const colorValues = colorMap.value || {};


    /**
     * Retrieve the results from the API.
     */
    useEffect(() => {
        getData();
    }, []);


    /**
     * Stores the map palette.
     */
    useEffect(() => {
        if (!settingsLoaded) {
            return;
        }

        syncColorMap();
    }, [colorMap]);


    /**
     * Remove the selection has filter options change.
     */
    useEffect(() => {
        setSelection([]);
    }, [clients, specialties, expiredOnly]);


    /**
     * Loads all the required data for the view.
     *
     * @returns {Promise<void>}
     */
    const getData = async () => {
        setLoading(true);

        await Promise.all([
            getSettings(),
            getResults(),
        ]);

        setLoading(false);
    };


    /**
     * The print preview element.
     *
     * @type {React.MutableRefObject<null>}
     */
    const printPreview = useRef(null);


    /**
     * Loads the user settings for this page.
     *
     * @returns {Promise<void>}
     */
    const getSettings = async () => {
        const response = await API.get('settings', {
            $top: 1,
            $filter: `modelType in {User} and modelId in {${user.id}} and key in {AV_COLOR_MAP}`,
            $orderby: 'id desc'
        });

        if (response && response.length) {
            setColorMap({
                ...response[0],
                value: response[0].value ? JSON.parse(response[0].value) : {}
            });
        }

        setSettingsLoaded(true);
    };


    /**
     * Synchronize the stored colors.
     *
     * @returns {Promise<void>}
     */
    const syncColorMap = async () => {
        const response = colorMap.id ?
            await API.put(`settings/${colorMap.id}`, {
                value: JSON.stringify(colorMap.value)
            }) : await API.post(`settings`, {
                key: 'AV_COLOR_MAP',
                value: JSON.stringify(colorMap.value),
                modelId: user.id,
                modelType: 'User'
            });

        if (!colorMap.id) {
            setColorMap({
                ...colorMap,
                id: response.id
            });
        }
    };


    /**
     * Loads the results.
     *
     * @returns {Promise<void>}
     */
    const getResults = async () => {
        const results = await API.post('reports/availability', {
            '@cache': true
        });

        Logger.debug('[AvailabilityReport] Received results...', results);

        setResults(results.map(result => {
            const updated = {
                ...result,

                employee: {
                    id: result.employee_id,
                    image: result.image,
                    lastName: result.last_name,
                    firstName: result.first_name,
                    displayName: result.employee_name,
                    lastShiftDate: result.recent_shift,

                    // Since we're estimating the latest date (accounting for legacy data), we may not
                    // pull a selection that actually lands on the start of the availability week. To
                    // account for this, we'll map this to the same constraints as the av. form.
                    lastAvailabilityDate: result.recent_availability ?
                        dayjs(result.recent_availability)
                            .startOf('weeks')
                            .add(1, 'weeks')
                            .subtract(1, 'days')
                            .format('MM/DD/YYYY') :
                        '',
                    specialty: {
                        id: result.specialty_id,
                        name: result.specialty_name
                    }
                },
                toDate: getAvailabilityToDate(result),
                facilities: (result.assigned_clients || '').split(',').map(facility => {
                    const parts = (facility || '').split(':');

                    if (!parts[0]) {
                        return;
                    }

                    return (
                        <Chip
                            sx={{
                                margin: '0.2em'
                            }}
                            key={parts[1]}
                            size={'small'}
                            label={parts[0]}
                        />
                    );
                })
            };

            // Force the end date into the same weekly format as the availability form.
            updated.endDate = updated.toDate ?
                dayjs(updated.toDate)
                    .startOf('weeks')
                    .add(1, 'weeks')
                    .subtract(1, 'days')
                    .format('MM/DD/YYYY') :
                '';

            return updated;
        }));
    };


    /**
     * Handles the employee change.
     *
     * @param employee
     */
    const handleChange = (employee) => {
        let updated = [];
        let isSelected = selection.some(record => record.employee_id === employee.employee_id);

        // Assign the new selection.
        if (!isSelected) {
            updated = [...selection, employee];
        } else {
            updated = selection.filter(record => record.employee_id !== employee.employee_id);
        }

        setSelection(updated);

        // Execute the change callback.
        if (onChange) {
            onChange(updated);
        }
    };


    /**
     * Handles the global selection change (the table header checkbox).
     */
    const handleSelectAll = () => {
        let updatedSelection = [];

        if (isIndeterminate) {
            // Clear the selection.
        } else if (allSelected) {
            // Clear the selection.
        } else {
            updatedSelection = [...filteredResults];
        }

        setSelection(updatedSelection);

        if (onChange) {
            onChange(updatedSelection);
        }
    };


    /**
     * Indicates whether a date occurs before another.
     *
     * @param date
     * @param comparison
     * @returns {boolean}
     */
    const isBefore = (date, comparison) => {
        if (!date || !comparison) {
            return false;
        }

        return dayjs(date).isBefore(dayjs(comparison));
    };


    /**
     * Indicates whether there is a selection.
     *
     * @type {boolean}
     */
    const hasSelection = !!selection && selection.length > 0;


    /**
     * Indicates if all are selected.
     *
     * @type {boolean}
     */
    const allSelected = hasSelection && selection.length === results.length;


    /**
     * Indicates if the selection is indeterminate.
     *
     * @type {boolean}
     */
    const isIndeterminate = hasSelection && !allSelected;


    /**
     * Returns the appropriate end date for a particular result.
     *
     * @param result
     * @returns {*|string}
     */
    const getAvailabilityToDate = result => {
        if (result.manual_end_dte) {
            return result.manual_end_dte;
        }

        if (result.auto_end_dte) {
            return result.auto_end_dte;
        }

        return '';
    };


    /**
     * Reveals the message dialog.
     */
    const handleMessageOpen = () => {
        setSendingMessage(true);
    };


    /**
     * Closes the message dialog.
     */
    const handleMessageClose = () => {
        setSendingMessage(false);
    };


    /**
     * Returns the filtered results based on the input criteria.
     *
     * @type {*[]}
     */
    const filteredResults = useMemo(() => results.filter(result => {
        const criteria = [];

        if (expiredOnly) {
            criteria.push(isBefore(result.employee.lastAvailabilityDate, result.endDate));
        }

        if(color !== null){
            criteria.push(colorValues[result.employee.id] === color);
        }

        if (clients && clients.length) {
            criteria.push(clients.some(client => (result.assigned_clients || '').includes(`${client.name}:${client.id}`)))
        }

        if (specialties && specialties.length) {
            criteria.push(specialties.some(specialty => specialty.id === result.specialty_id));
        }

        return !criteria.some(criterion => !criterion);
    }), [color, results, clients, specialties, expiredOnly]);


    /**
     * The heading label for the "Send Now" button.
     *
     * @type {string}
     */
    const sendMessageHeading = `Send Message ${hasSelection ? `(${selection.length})` : ''}`;


    /**
     * Saves the results as a PDF.
     */
    const doSavePdf = async () => {
        setLoading(true);

        // Create the PDF document.
        const response = await API.post('pdf', {
            html: printPreview.current.innerHTML,
            heading: `Availability Results`
        });

        // Download the document.
        await new Promise(resolve => {
            fetch(API.getFilePath(response.path), {
                method: 'GET'
            }).then(resp => resp.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.style.display = 'none';
                    a.href = url;
                    a.download = `availability-results.pdf`;
                    document.body.appendChild(a);
                    a.click();
                    window.URL.revokeObjectURL(url);
                    resolve();
                })
        });

        setLoading(false);
    };


    /**
     * Updates the table row color.
     *
     * @param employee
     * @param index
     */
    const handleColorChange = (employee, index) => {
        setColorMap({
            ...colorMap,
            value: {
                ...colorMap.value,
                [employee.id]: colorValues[employee.id] === index ? null : index
            }
        });
    };


    /**
     * Mass-assigns the color assignment.
     *
     * @param index
     */
    const handleBulkColorChange = (index) => {
        const updatedValues = {};

        selection.map(result => updatedValues[result.employee_id] = index);

        setColorMap({
            ...colorMap,
            value: {
                ...colorMap.value,
                ...updatedValues
            }
        });
    };

    return (
        <Box className={'calendar__wrapper'}>
            <Box
                sx={{
                    width: Settings.drawerWidth
                }}
                className={'schedule__left-column two-column__border-right'}
            >
                <Box className={'two-column__left-results two-column__left-results--no-menu well__container'}>
                    <Accordion defaultExpanded>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography>Actions</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{paddingTop: 0}}>
                            <Box className={'columns__1 columns--small'}>
                                <Button
                                    variant={'outlined'}
                                    onClick={handleMessageOpen}
                                    disabled={!hasSelection}
                                    children={sendMessageHeading}
                                    startIcon={<SendIcon/>}
                                />
                                <ColorSwatch
                                    colors={colorSwatch}
                                    disabled={isLoading}
                                    onChange={handleBulkColorChange}
                                >
                                    <Button
                                        variant={'outlined'}
                                        disabled={isLoading}
                                        children={'Assign Color'}
                                        className={'w__100'}
                                        startIcon={<BookmarkBorderIcon/>}
                                    />
                                </ColorSwatch>
                                <Button
                                    variant={'outlined'}
                                    onClick={doSavePdf}
                                    disabled={isLoading}
                                    children={'Save PDF'}
                                    startIcon={<PrintIcon/>}
                                />
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded className={'mb__3'}>
                        <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                            <Typography>Filters</Typography>
                        </AccordionSummary>
                        <AccordionDetails sx={{paddingTop: 0}}>
                            <Box className={'columns__1'}>
                                <ModelSearch
                                    model={Client}
                                    value={clients}
                                    label={'Clients'}
                                    multiple
                                    disabled={isLoading}
                                    onChange={setClients}
                                    renderLabel={option => !option ? '' : option.name}
                                    filterQuery={query => `isDeleted eq {0} and name eq {${query}}`}
                                    queryParams={{
                                        $top: 250,
                                        $orderby: 'name asc'
                                    }}
                                />
                                <ModelSearch
                                    model={Specialty}
                                    value={specialties}
                                    label={'Specialties'}
                                    multiple
                                    disabled={isLoading}
                                    onChange={setSpecialties}
                                    renderLabel={option => !option ? '' : option.name}
                                    filterQuery={query => `isDeleted eq {0} and name eq {${query}}`}
                                />
                                <FormControl fullWidth>
                                    <InputLabel>Color</InputLabel>
                                    <InputSelect
                                        value={color}
                                        options={[
                                            {
                                                label: 'Please select...',
                                                value: null,
                                            }, ...colorSwatch.map((option, index) => {
                                                return {
                                                    label: (
                                                        <Box className={'d-flex__start'}>
                                                            <Box
                                                                sx={{
                                                                    width: '1.5em',
                                                                    height: '1.5em',
                                                                    padding: '0.2em',
                                                                    borderRadius: '50%',
                                                                    backgroundColor: option.previewColor,
                                                                }}
                                                                key={`color-${index}`}
                                                                className={'mr__2'}
                                                            />

                                                            {option.name}
                                                        </Box>
                                                    ),
                                                    value: index
                                                };
                                            })
                                        ]}
                                        disabled={isLoading}
                                        onChange={event => setColor(event.target.value)}
                                        fullWidth
                                        label={'Color'}
                                    />
                                </FormControl>
                                <FormControlLabel
                                    label={'Show expired only.'}
                                    control={
                                        <Checkbox
                                            checked={expiredOnly}
                                            disabled={isLoading}
                                            onChange={event => setExpiredOnly(event.target.checked)}
                                        />
                                    }
                                />
                            </Box>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Box>
            <Box
                sx={{borderRadius: 0, background: '#fff'}}
                className={`flex__grow full__height--left ${!results ? 'well__container' : ''}`}
            >
                {isLoading && <LinearProgress/>}

                <TableContainer className={'table table--striped'}>
                    <Table size={'small'} stickyHeader>
                        <TableHead>
                            <TableRow>
                                {selectable && (
                                    <TableCell sx={{padding: 0, paddingLeft: '0.5em'}}>
                                        <Checkbox
                                            checked={allSelected}
                                            onChange={handleSelectAll}
                                            indeterminate={isIndeterminate}
                                        />
                                    </TableCell>
                                )}
                                <TableCell width={250}>
                                    Employee
                                </TableCell>
                                <TableCell align={'center'}>
                                    Recent Shift
                                </TableCell>
                                <TableCell align={'center'}>
                                    Latest Availability
                                </TableCell>
                                <TableCell align={'center'}>
                                    Availability Period
                                </TableCell>
                                <TableCell width={200}>
                                    Assigned Facilities
                                </TableCell>
                                <TableCell width={85}/>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {filteredResults.map(result => {
                                const {
                                    endDate,
                                    employee,
                                    facilities,
                                } = result;

                                const colorIndex = colorValues[employee.id] || colorValues[employee.id] === 0 ? colorValues[employee.id] : null;
                                const cellStyle = colorIndex !== null ? {
                                    backgroundColor: `${colorSwatch[colorIndex].backgroundColor}!important;`
                                } : {};

                                return (
                                    <TableRow key={employee.id}>
                                        {selectable && (
                                            <TableCell padding={'checkbox'} sx={cellStyle}>
                                                <Checkbox
                                                    checked={hasSelection && selection.some(record => record.employee_id === employee.id)}
                                                    onChange={() => handleChange(result)}
                                                />
                                            </TableCell>
                                        )}
                                        <TableCell sx={{...cellStyle, padding: 0}}>
                                            <ModalButton
                                                label={
                                                    <EmployeeListItem
                                                        onSelect={() => true}
                                                        employee={employee}
                                                    />
                                                }
                                                bodyStyle={{
                                                    paddingTop: 0
                                                }}
                                                children={
                                                    <EmployeeView id={employee.id}/>
                                                }
                                            />
                                        </TableCell>
                                        <TableCell align={'center'} sx={cellStyle}>
                                            {!employee.lastShiftDate && (
                                                <Box className={'text__light'}>
                                                    (not available)
                                                </Box>
                                            )}

                                            {Formatter.date(employee.lastShiftDate)}
                                        </TableCell>
                                        <TableCell align={'center'} sx={cellStyle}>
                                            <Box className={'text__center p__3'}>
                                                {!employee.lastAvailabilityDate ? (
                                                    <Box className={'text__light'}>
                                                        (not available)
                                                    </Box>
                                                ) : (
                                                    <Box className={'d-flex__start'} sx={{justifyContent: 'center'}}>
                                                        {isBefore(employee.lastAvailabilityDate, endDate) ? (
                                                            <>
                                                                <ErrorOutlineIcon
                                                                    color={'error'}
                                                                    className={'mr__1'}
                                                                />

                                                                <Box sx={{color: 'error.main'}}>
                                                                    {Formatter.date(employee.lastAvailabilityDate, '', false)}
                                                                </Box>
                                                            </>
                                                        ) : (
                                                            <>
                                                                {Formatter.date(employee.lastAvailabilityDate, '', false)}
                                                            </>
                                                        )}
                                                    </Box>
                                                )}
                                            </Box>
                                        </TableCell>
                                        <TableCell align={'center'} sx={cellStyle}>
                                            {endDate ? Formatter.date(endDate) : (
                                                <Box className={'d-inline__block text__light'}>
                                                    (not available)
                                                </Box>
                                            )}

                                            <ModalButton
                                                className={'d-inline__block'}
                                                label={
                                                    <IconButton color={'primary'} className={'ml__1'}>
                                                        <OpenInNewIcon fontSize={'small'}/>
                                                    </IconButton>
                                                }
                                                bodyStyle={{
                                                    paddingTop: 0
                                                }}
                                                children={
                                                    <EmployeeView
                                                        id={employee.id}
                                                        defaultTab={'Availability'}
                                                    />
                                                }
                                            />
                                        </TableCell>
                                        <TableCell sx={cellStyle}>
                                            {facilities}
                                        </TableCell>
                                        <TableCell align={'center'} className={'white-space__pre'} sx={cellStyle}>
                                            <ColorSwatch
                                                value={colorValues[employee.id]}
                                                colors={colorSwatch}
                                                onChange={index => handleColorChange(employee, index)}
                                            >
                                                <IconButton>
                                                    <BookmarkBorderIcon/>
                                                </IconButton>
                                            </ColorSwatch>
                                            <QuickSendWidget
                                                record={employee}
                                                icon={<ChatIcon/>}
                                            />
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Box>

            <Box className={'d-hidden'}>
                <Box ref={printPreview}>
                    <table className={'table--bordered'}>
                        <tbody>
                        <tr>
                            <td>
                                <b>Employee</b>
                            </td>
                            <td>
                                <b>Recent Shift</b>
                            </td>
                            <td>
                                <b>Latest Availability</b>
                            </td>
                            <td>
                                <b>Availability Period</b>
                            </td>
                            <td>
                                <b>Assigned Facilities</b>
                            </td>
                        </tr>
                        {filteredResults.map(result => {
                            const {
                                endDate,
                                employee,
                                facilities,
                            } = result;

                            return (
                                <tr key={employee.id}>
                                    <td>
                                        {employee.lastName}, {employee.firstName}
                                    </td>
                                    <td align={'center'}>
                                        {!employee.lastShiftDate && (
                                            <Box className={'text__light'}>
                                                (not available)
                                            </Box>
                                        )}

                                        {Formatter.date(employee.lastShiftDate)}
                                    </td>
                                    <td align={'center'}>
                                        <Box className={'text__center'}>
                                            {!employee.lastAvailabilityDate ? (
                                                <Box className={'text__light'}>
                                                    (not available)
                                                </Box>
                                            ) : (
                                                <Box>
                                                    {isBefore(employee.lastAvailabilityDate, endDate) ? (
                                                        <>
                                                            <div style={{color: 'red'}}>
                                                                {Formatter.date(employee.lastAvailabilityDate, '', false)}
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            {Formatter.date(employee.lastAvailabilityDate, '', false)}
                                                        </>
                                                    )}
                                                </Box>
                                            )}
                                        </Box>
                                    </td>
                                    <td align={'center'}>
                                        {endDate ? Formatter.date(endDate) : (
                                            <Box className={'d-inline__block text__light'}>
                                                (not available)
                                            </Box>
                                        )}
                                    </td>
                                    <td>
                                        {facilities}
                                    </td>
                                </tr>
                            );
                        })}
                        </tbody>
                    </table>
                </Box>
            </Box>

            <GroupChatDialog
                open={isSendingMessage}
                onClose={handleMessageClose}
                selection={selection.map(result => {
                    return {
                        ...result.employee
                    };
                })}
            />
        </Box>
    );
};

export default AvailabilityReport;